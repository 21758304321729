// @flow

// Necessary Evil
import React from "react";
import {withTranslation} from "react-i18next";
import {Col, Grid, Row} from "react-flexbox-grid";
import {Link} from "react-router-dom";
// Import component CSS
import "./Footer.scss";
import moment from "moment";

/**
 * @class Footer
 */
class Footer extends React.Component {

	/**
	 * Final Render
	 * @returns {*}
	 */
	render() {

		const {t} = this.props;

		return <div className="footer">
			<Grid>
				<Row>
					<Col xs={12} sm md lg>
						<ul>
							<li><Link to={"/sales/contact"}>{t("navigation.Contacts")}</Link></li>
							<li><Link to={"/support/downloads"}>{t("navigation.Downloads")}</Link></li>
							<li><a href={"/download/7d239781-638e-477e-b26b-8b6475199c4f"} target={"_self"} rel="nofollow noreferrer noopener">{t("navigation.Pricelist")}</a></li>
							<li><a href={"https://eshop.iqrf.org"} target={"_self"}>E-shop</a></li>
						</ul>
					</Col>
					<Col xs={12} lg={4}>
						<div className={"mission"}>
							<a className={"mission__alliance"} href={"https://iqrfalliance.org/"}
							   target="_blank"
							   rel="noreferrer noopener"
							   title="IQRF Alliance is an association  of companies developing interoperable wireless devices based on IQRF technology and related gateways, clouds, mobile apps, integration platforms etc.">
								IQRF Alliance
								{/*<img src={IQRFAllianceLogo} alt={"IQRF Alliance member"}/>*/}
							</a>
							<a className={"mission__alliance"} href={"https://open.iqrf.org/"}
							   target="_blank"
							   rel="noreferrer noopener"
							   title="IQRF Open Standard ">
								IQRF Open Standard
							</a>
							<ul>
								<li><a href={"http://eepurl.com/gvvWST"} target={"_blank"} rel="noreferrer noopener">Subscribe Newsletter</a></li>
							</ul>
						</div>
					</Col>

					<Col xs={12} sm md lg>
						<ul>
							<li><Link to={"/sales/company"}>{t("navigation.CompanyProfile")}</Link></li>
							<li><Link to={"/privacy-policy"}>{t("navigation.PrivacyPolicy")}</Link></li>
							<li><Link to={"/terms-of-use"}>{t("navigation.TermsOfUse")}</Link></li>
							<li><Link to="/vop/VOC-IQRF-Tech.pdf" target="_blank" rel="noopener noreferrer">{t("navigation.VOP")}</Link></li>
							<li><Link to={"/patents"}>{t("navigation.Patents")}</Link></li>
							<li><Link to={"/trademarks"}>{t("navigation.Trademarks")}</Link></li>
						</ul>
					</Col>
					<Col xs={12} sm md lg className={"text--center"}>

						<ul className="text--center">
							<li><Link to={"/career"}>{t("navigation.Career")}</Link></li>
						</ul>
						<Row style={{alignSelf: "flex-end"}}>
							<Col xs={12} lg className={"social-bar"}>
								<a href={"https://www.facebook.com/IQRF-Smarter-Wireless-Simply-1068810909803758/"}
									 title={"IQRF Facebook"}
									 target="_blank"
								   rel="nofollow noreferrer noopener">
									<i className="icon-facebook-logo"/>
								</a>
								<a href={"https://www.twitter.com/IQRF"}
									 title={"IQRF Twitter"}
								   target="_blank"
								   rel="nofollow noreferrer noopener">
									<i className="icon-twitter-logo"/>
								</a>
								<a href={"https://www.youtube.com/user/iqrf"}
								   title={"IQRF short videos"}
									 target="_blank"
								   rel="nofollow noreferrer noopener">
									<i className="icon-youtube-logo"/>
								</a>
								<a href={" https://www.linkedin.com/company/iqrf-tech"}
								   title={"IQRF LinkedIn"}
									 target="_blank"
								   rel="nofollow noreferrer noopener">
									<i className="icon-linkedin-logo"/>
								</a>
								<a href={"https://play.google.com/store/apps/details?id=org.iqrfalliance.demo"}
								   title={"IQRF Network Manager for Android"}
								   target="_blank"
								   rel="nofollow noreferrer noopener">
									<i className="icon-playstore-logo"/>
								</a>
							</Col>
						</Row>
					</Col>
				</Row>
				<Row>
					<Col xs={12} lg className={"copyright"}>
						&copy; 2004 - {moment().year()} IQRF Tech s.r.o. All rights reserved.
					</Col>

				</Row>
			</Grid>
		</div>;
	}
}

export default withTranslation()(Footer);
