// @flow

// Necessary Evil
import React from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import LoadingComponent from "../Components/LoadingComponent";
import BackendRequest from "../Models/REST/BackendRequest";
import FormGroup from "../Components/Form/FormGroup";
import { connect } from "react-redux";
import ErrorMessage from "../Components/Form/ErrorMessage/ErrorMessage";
import { Col, Row } from "react-flexbox-grid";
import moment from "moment";
import InstantAction from "../Models/Utils/InstantAction";
import { REMOVE_PRODUCT_PRICE } from "../Models/Models/ProductPrice";

/**
 * @class ProductPriceForm
 *
 */
class ProductPriceForm extends React.Component {

	/**
	 *
	 * @type {{price: shim, afterSuccess: shim}}
	 */
	static propTypes = {
		price: PropTypes.any,
		product: PropTypes.object,
	};

	/**
	 * Default Props
	 * @type {{afterSuccess: ProductPriceForm.defaultProps.afterSuccess}}
	 */
	static defaultProps = {};

	/**
	 * State
	 * @type {{formData: {}, expectingResponse: boolean, errorMessage: null}}
	 */
	state = {
		expectingResponse: false,
		errorMessage: null,
		// Properties
		id: null,
		product: null,
		valid_from: "", // Date
		price_1: null,
		price_5: null,
		price_20: null,
		price_100: null,
		price_1000: null,
	};

	/**
	 *
	 * @param props
	 * @param state
	 */
	static getDerivedStateFromProps (props, state) {

		if (props.price !== null) {
			if (props.price.id !== state.id) {
				return {
					...props.price._fields,
				};
			}
		}

		return null;
	}

	/**
	 * Handle Input Changes
	 * @param event
	 */
	handleInputChange = (event) => {

		const target = event.target;
		const value = target.type === "checkbox" ? target.checked : target.value;
		const name = target.name;

		this.setState({
			[name]: value
		});

	};

	/**
	 * Handle Submit
	 * @param e
	 */
	handleSubmit = (e) => {

		e.preventDefault();

		const { product } = this.props;

		/**
		 * Payload
		 * @type {{image: null, name: string, title: string, content: string}}
		 */
		const payload = {
			product: product.id,
			valid_from: moment(this.state.valid_from).format("YYYY-MM-DD"),
			price_1: this.state.price_1,
			price_5: this.state.price_5,
			price_20: this.state.price_20,
			price_100: this.state.price_100,
			price_1000: this.state.price_1000,
		};

		let method = this.state.id === null ? "post" : "put";
		let endpoint = this.state.id === null ? "price-item" : "price-item/" + this.state.id;

		BackendRequest({
			endpoint: endpoint,
			method: method,
			payload: payload,
			self: this,
			afterSuccess: this.props.afterSuccess
		});
	};

	/**
	 * Component will mount
	 */
	componentDidMount () {

		if (this.props.price !== null) {
			this.setState({
				...this.props.price._fields,
			});
		}
	}

	/**
	 * Delete item
	 */
	handleDelete = () => {

		BackendRequest({
			endpoint: "price-item/" + this.state.id,
			method: "delete",
			self: this,
			afterSuccess: () => {

				InstantAction.dispatch({
					type: REMOVE_PRODUCT_PRICE,
					payload: this.state.id,
				});
			}
		});

	};

	/**
	 * Final Render
	 * @returns {*}
	 */
	render () {

		/**
		 * @info Translation function, className
		 */
		const {
			t,
		} = this.props;

		/**
		 * @info Error variable
		 */
		let error = "";

		/**
		 * @info Handling Error Message
		 */
		if (this.state.errorMessage !== null) {
			error = <ErrorMessage>{t("formError." + this.state.errorMessage)}</ErrorMessage>;
		}

		/**
		 * Final Output
		 * @type {{}}
		 */
		return <form className={"form"}>
			{error}
			<Row>
				<Col xs={12} lg>
					<FormGroup>
						<label htmlFor="valid_from">{t("label.ValidFrom")}</label>
						<input onChange={this.handleInputChange}
							   value={this.state.valid_from}
							   type="date"
							   name="valid_from" id="valid_from"
							   placeholder={t("label.ValidFrom")}
						/>
					</FormGroup>
				</Col>
				<Col xs={12} lg>
					<FormGroup>
						<label htmlFor="price_1">{t("label.Price1")} [€]</label>
						<input onChange={this.handleInputChange}
							   value={this.state.price_1}
							   type="number"
							   name="price_1"
							   id="price_1"
							   placeholder={t("label.OnRequest")}
						/>
					</FormGroup>
				</Col>
				<Col xs={12} lg>
					<FormGroup>
						<label htmlFor="price_5">{t("label.Price5")} [€]</label>
						<input onChange={this.handleInputChange}
							   value={this.state.price_5}
							   type="number"
							   name="price_5"
							   id="price_5"
							   placeholder={t("label.OnRequest")}
						/>
					</FormGroup>
				</Col>
				<Col xs={12} lg>
					<FormGroup>
						<label htmlFor="price_20">{t("label.Price20")} [€]</label>
						<input onChange={this.handleInputChange}
							   value={this.state.price_20}
							   type="number"
							   name="price_20"
							   id="price_20"
							   placeholder={t("label.OnRequest")}
						/>
					</FormGroup>
				</Col>
				<Col xs={12} lg>
					<FormGroup>
						<label htmlFor="price_100">{t("label.Price100")} [€]</label>
						<input onChange={this.handleInputChange}
							   value={this.state.price_100}
							   type="number"
							   name="price_100"
							   id="price_100"
							   placeholder={t("label.OnRequest")}
						/>
					</FormGroup>
				</Col>
				<Col xs={12} lg>
					<FormGroup>
						<label htmlFor="price_1000">{t("label.Price1000")} [€]</label>
						<input onChange={this.handleInputChange}
							   value={this.state.price_1000}
							   type="number"
							   name="price_1000"
							   id="price_1000"
							   placeholder={t("label.OnRequest")}
						/>
					</FormGroup>
				</Col>
				<Col xs={12} lg style={{ alignItems: "center", display: "flex", justifyContent: "center" }}>
					<FormGroup className={"text--center"}>
						{!this.state.expectingResponse ? <div>
							<button
								type="submit"
								tabIndex={2}
								className="btn btn--animated"
								onClick={this.handleSubmit}>
								{this.state.id === null ? t("button.AddPrice") : t("button.Update")}
							</button>
							{(this.state.id === null) ? "" :
								<button
									className="btn btn--danger"
									onClick={this.handleDelete}>
									<i className="icon-delete"/>
								</button>
							}</div> : <LoadingComponent/>}
					</FormGroup>
				</Col>
			</Row>
		</form>;
	}
}

/**
 * State to Props
 * @param state
 * @return {{entities: {PhotoSection: (*|PhotoSection)}}}
 */
const mapStateToProps = state => (
	{
		entities: {},
	});

export default (connect(mapStateToProps)(withTranslation()(ProductPriceForm)));