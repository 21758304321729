// @flow
import React from "react";
import {withTranslation} from "react-i18next";
import {Col, Grid, Row} from "react-flexbox-grid";
import "../../Components/Technology/Technology.scss";
import {Helmet} from "react-helmet";
import {Link} from "react-router-dom";

/**
 * @class TechnologyIndustrialReliabilityScene
 */
class TechnologyIndustrialReliabilityScene extends React.Component {

  /**
   * Constructor
   * @param props
   */
  constructor(props) {
    super(props);

    this.state = {
      showContentSupportedGUI: false,
      showContentGUI: false,
      showContentCommand: false,
      supportedCommand: false,
    };
  }

  /**
   * Final Render
   * @returns {*}
   */
  render() {

    return (
      <Grid className={"page"}>
        <Helmet>
          <title>Industrial reliability - IQRF</title>
        </Helmet>
        <Row>
          <Col xs={12}>
            <h1>Industrial reliability</h1>
            <p>With <strong>IQRF</strong> wireless technology, even distant and <strong>problematic locations</strong> in complex buildings,
      cellars, production halls, warehouses, etc. can reliably be covered. It is thus suitable for applications in industry,
      smart buildings, cities, and so on. This is ensured thanks to unique (and often patented) features like sophisticated <strong>coding</strong>, routing based on <Link to="/technology/iqmesh/unicast"><strong>directional flooding</strong></Link>, robust and secure communication <strong>protocols</strong>,
    Fast Response Command (<strong>FRC</strong>), etc.</p>
            <p>Up to <strong>240 routing devices</strong> and up to 240 hops per packet in IQMESH network enable reliable communication covering up
    to many square kilometers. All IQRF transceivers are <strong>bidirectional</strong> which brings much higher performance, reliability, and
    security.</p>
            <p><Link to="/technology/iqmesh/frc"><strong>FRC</strong></Link> enables the <strong>fastest</strong> network <strong>control</strong>, management, and <strong>data collection</strong>. It is a patented method of how to <strong>send a command</strong> from the Coordinator to all or selected Nodes and <strong>receive responses</strong> including <strong>data collected</strong> from
    individual Nodes in an outstandingly short time. Typically, this approach is used in the lighting industry,
      whether indoors, street lighting, or emergency lighting. You can collect values about the status of the lights in bulk,
      remotely switch them on/off, change their intensity or color, etc. <Link to="/technology/iqmesh/local-frc"><strong>Local FRC</strong></Link> can asynchronously be sent from a Node to control selected Nodes in direct RF range</p>
            <p>It is also possible to <Link to="/technology/iqmesh/offline-frc"><strong>aggregate</strong></Link> data from battery-operated <strong>„Beaming“ sensors</strong> (sleeping almost all the time) with very low power consumption.</p>
            <div className="table--wrapper">
              <table className="table--with-links">
                <tbody>
                  <tr>
                    <td><a href="https://youtu.be/vm5yoApEWMs"
      rel="noopener noreferrer" target="_blank"><i className="icon-youtube-logo"/>  IQRF routing and Fast Respond Command </a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <p>A great advantage of IQRF is also its <strong>local autonomy</strong>. IQRF network can operate <strong>standalone,
      without</strong> a dependency on <strong>external providers</strong>. You can connect the IQRF network wherever you want,
      either <strong>locally</strong> to your local control system or (of course) <strong>remotely</strong>, typically via the Internet, often to a cloud.</p>
            <div className="table--wrapper">
              <table className="table--with-links">
                <tbody>
                  <tr>
                    <td><a href="https://youtu.be/CuWGUmSo7QI"
      rel="noopener noreferrer" target="_blank"><i className="icon-youtube-logo"/>  IQRF – How it works</a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
          </Col>
        </Row>
      </Grid>
);
}
}

/**
 * Exporting part of the React.Component file
 */
export default withTranslation()(TechnologyIndustrialReliabilityScene);
