// @flow
import React from "react";
import {withTranslation} from "react-i18next";
import {Col, Grid, Row} from "react-flexbox-grid";
import {Helmet} from "react-helmet";

/**
 * @class CareerFAEEngineerScene
 */
class CareerFAEEngineerScene extends React.Component {

	/**
	 * Final Render
	 * @returns {*}
	 */
	render() {
		return (
			<Grid className={"page"}>
				<Helmet>
					<title>FAE engineer wanted</title>
				</Helmet>
				<Row>
					<Col xs={12} className="page__section--bottom-padding">
                        <h1>FAE engineer wanted</h1>
						<h3>Internal projects</h3> 
                        <ul>
                            <li>Leading of company-internal HW and SW projects</li>
                            <li>Pilot projects</li>
                        </ul>
                        <h3>Required</h3> 
                        <ul>
                            <li>Education in electronics</li>
                            <li>Experience in electronic design and HW realization</li>
                            <li>Experience in microprocessor technique</li>
                            <li>C and C++ programming</li>
                            <li>Technical English</li>
                            <li>Constructive approach</li>
                            <li>Self-activity</li>
                        </ul>
                        <h3>Welcomed</h3> 
                        <ul>
                            <li>Practical experience in Microchip PIC microcontrollers</li>
                            <li>Basic RF experience. Knowledge of IQRF is not necessary.</li>
                            <li>Good English</li>
                        </ul>
                        <h3>We offer</h3> 
                        <ul>
                            <li>Interesting self-reliant work in friendly team awarded the Česká Hlava in 2014</li>
                            <li>Home office</li>
                            <li>Employees' amenities</li>
                        </ul>
                        <h3>Contact</h3> 
                        <p>Send the CV and motivation letter to: Iveta Stefanova,  [cv@microrisc.com](mailto:cv@microrisc.com).<br/>  
MICRORISC s.r.o., Jicin, tel. +420 493 538 125.</p>
                    </Col>
				</Row>
			</Grid>
		);
	}

}

/**
 * Exporting part of the React.Component file
 */
export default withTranslation()(CareerFAEEngineerScene);
