// @flow
import React from "react";
import {withTranslation} from "react-i18next";
import {Col, Grid, Row} from "react-flexbox-grid";
import {HashLink} from "react-router-hash-link";
import BackendRequest from "../../Models/REST";
import MasterGetter from "../../Models/Utils/MasterGetter";
import Markdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import rehypeSanitize from "rehype-sanitize";
import remarkGfm from "remark-gfm";
import {Helmet} from "react-helmet";

/**
 * @class TechnologyOSFunctionsScene
 */
class TechnologyOSFunctionsScene extends React.Component {

	/**
	 * State
	 * @type {{}}
	 */
	state = {
		expectingResponse: true,
	};

	/**
	 * Fetch data
	 */
	fetchData = () => {
		BackendRequest({
			endpoint: "os-functions",
			self: this,
		});
	};

	/**
	 * Component did mount
	 */
	componentDidMount(): void {
		this.fetchData();
	}

	/**
	 * Final Render
	 * @returns {*}
	 */
	render() {

		const {entities} = this.props;

		const session = MasterGetter.getSession(entities);
		const functionSections = session.OSFunctionSection.all().orderBy(["ordering", "asc"]);
		const functions = session.OSFunction.all();

		return (
			<Grid className={"page"}>
				<Helmet>
					<title>IQRF OS functions - IQRF</title>
				</Helmet>
				<Row className="page__section--bottom-padding">
					<Col xs={12}>
						<h1>IQRF OS functions</h1>
						<p>This list is intended just for a quick insight in IQRF OS capabilities. The only exact
							information is in the <strong>IQRF OS Reference guide</strong> for given
							<HashLink to={"/technology/os#os-versions"}> IQRF OS version</HashLink>.
							<br/>
							<em>Unless otherwise stated, all functions are the void type and all their parameters
								are the uns8 type.</em>
						</p>

						<table className="table--functions">
							<tbody>
							{functionSections.toModelArray().map(sectionItem => {
								return [<tr key={sectionItem.id}>
									<th colSpan={2}>{sectionItem.name}</th>
								</tr>,
									functions.filter(item => item.section === sectionItem.id).toModelArray().map((item, index) => {
										return <tr key={index}>
											<td>
												<Markdown
													rehypePlugins={[rehypeRaw, rehypeSanitize]}
													remarkPlugins={[remarkGfm]}
													children={item.name}
												/>
											</td>
											<td>
												<Markdown
													rehypePlugins={[rehypeRaw, rehypeSanitize]}
													remarkPlugins={[remarkGfm]}
													children={item.description}
												/>
											</td>
										</tr>;
									})
								];
							})}
							</tbody>
						</table>

						{/*<table className="table--functions">*/}
						{/*	<tbody>*/}
						{/*	<tr>*/}
						{/*		<th colSpan={2}><strong>Control</strong></th>*/}
						{/*	</tr>*/}
						{/*	<tr>*/}
						{/*		<td><strong>iqrfSleep</strong>()</td>*/}
						{/*		<td>Set the TR module in power saving mode (Sleep)</td>*/}
						{/*	</tr>*/}
						{/*	<tr>*/}
						{/*		<td><strong>iqrfDeepSleep</strong>()</td>*/}
						{/*		<td>Set the TR module in extremely power saving mode (Deep sleep)</td>*/}
						{/*	</tr>*/}
						{/*	<tr>*/}
						{/*		<td><strong>setRFsleep</strong>()</td>*/}
						{/*		<td>Set the RF IC in power saving mode (Sleep)</td>*/}
						{/*	</tr>*/}
						{/*	<tr>*/}
						{/*		<td><strong>setRFready</strong>()</td>*/}
						{/*		<td>Set the RF IC in ready mode (wake-up from Sleep)</td>*/}
						{/*	</tr>*/}
						{/*	<tr>*/}
						{/*		<td><strong>debug</strong>()</td>*/}
						{/*		<td>Enter the debug mode</td>*/}
						{/*	</tr>*/}
						{/*	<tr>*/}
						{/*		<td>uns8 <strong>getSupplyVoltage</strong>()</td>*/}
						{/*		<td>Get voltage level for battery check</td>*/}
						{/*	</tr>*/}
						{/*	<tr>*/}
						{/*		<td>int8 <strong>getTemperature</strong>()</td>*/}
						{/*		<td>Temperature measurement</td>*/}
						{/*	</tr>*/}
						{/*	<tr>*/}
						{/*		<th colSpan={2}><strong>Active (blocking) waiting</strong></th>*/}
						{/*	</tr>*/}
						{/*	<tr>*/}
						{/*		<td><strong>waitMS</strong>(ms)</td>*/}
						{/*		<td>Active waiting (time in ms)</td>*/}
						{/*	</tr>*/}
						{/*	<tr>*/}
						{/*		<td><strong>waitDelay</strong>(ticks)</td>*/}
						{/*		<td>Active waiting (time in ticks)</td>*/}
						{/*	</tr>*/}
						{/*	<tr>*/}
						{/*		<td><strong>waitNewTick</strong>()</td>*/}
						{/*		<td>Wait for a new tick</td>*/}
						{/*	</tr>*/}
						{/*	<tr>*/}
						{/*		<th colSpan={2}><strong>Timing on background</strong></th>*/}
						{/*	</tr>*/}
						{/*	<tr>*/}
						{/*		<td><strong>startCapture</strong>()</td>*/}
						{/*		<td>Resets counter of ticks</td>*/}
						{/*	</tr>*/}
						{/*	<tr>*/}
						{/*		<td><strong>captureTicks</strong>()</td>*/}
						{/*		<td>Get number of ticks counted</td>*/}
						{/*	</tr>*/}
						{/*	<tr>*/}
						{/*		<td><strong>startDelay</strong>(ticks)</td>*/}
						{/*		<td>Start waiting (time in ticks)</td>*/}
						{/*	</tr>*/}
						{/*	<tr>*/}
						{/*		<td><strong>startLongDelay</strong>(ticks)</td>*/}
						{/*		<td>Start long waiting (time in ticks)</td>*/}
						{/*	</tr>*/}
						{/*	<tr>*/}
						{/*		<td>bit <strong>isDelay</strong>()</td>*/}
						{/*		<td>Still waiting</td>*/}
						{/*	</tr>*/}
						{/*	<tr>*/}
						{/*		<th colSpan={2}><strong>LED indication</strong></th>*/}
						{/*	</tr>*/}
						{/*	<tr>*/}
						{/*		<td><strong>setOnPulsingLED</strong>(ticks)</td>*/}
						{/*		<td>LEDR and LEDG On times setting (for blinking)</td>*/}
						{/*	</tr>*/}
						{/*	<tr>*/}
						{/*		<td><strong>setOffPulsingLED</strong>(ticks)</td>*/}
						{/*		<td>LEDR and LEDG Off times setting (for blinking)</td>*/}
						{/*	</tr>*/}
						{/*	<tr>*/}
						{/*		<td><strong>pulsingLEDR</strong>()</td>*/}
						{/*		<td>Red LED activation (blinking on background)</td>*/}
						{/*	</tr>*/}
						{/*	<tr>*/}
						{/*		<td><strong>pulseLEDR</strong>()</td>*/}
						{/*		<td>Single red LED pulse (one flash on background)</td>*/}
						{/*	</tr>*/}
						{/*	<tr>*/}
						{/*		<td><strong>setLEDR</strong>()</td>*/}
						{/*		<td>Red LED on</td>*/}
						{/*	</tr>*/}
						{/*	<tr>*/}
						{/*		<td><strong>stopLEDR</strong>()</td>*/}
						{/*		<td>Red LED off, blinking stopped</td>*/}
						{/*	</tr>*/}
						{/*	<tr>*/}
						{/*		<td><strong>pulsingLEDG</strong>()</td>*/}
						{/*		<td>Green LED activation (blinking on background)</td>*/}
						{/*	</tr>*/}
						{/*	<tr>*/}
						{/*		<td><strong>pulseLEDG</strong>()</td>*/}
						{/*		<td>Single green LED pulse (one flash on background)</td>*/}
						{/*	</tr>*/}
						{/*	<tr>*/}
						{/*		<td><strong>setLEDG</strong>()</td>*/}
						{/*		<td>Green LED on</td>*/}
						{/*	</tr>*/}
						{/*	<tr>*/}
						{/*		<td><strong>stopLEDG</strong>()</td>*/}
						{/*		<td>Green LED off, blinking stopped</td>*/}
						{/*	</tr>*/}
						{/*	<tr>*/}
						{/*		<th colSpan={2}><strong>MCU EEPROM</strong></th>*/}
						{/*	</tr>*/}
						{/*	<tr>*/}
						{/*		<td>uns8 <strong>eeReadByte</strong>(addr)</td>*/}
						{/*		<td>Read one byte</td>*/}
						{/*	</tr>*/}
						{/*	<tr>*/}
						{/*		<td><strong>eeReadData</strong>(addr,length)</td>*/}
						{/*		<td>Read a block</td>*/}
						{/*	</tr>*/}
						{/*	<tr>*/}
						{/*		<td><strong>eeWriteByte</strong>(addr, data)</td>*/}
						{/*		<td>Write one byte</td>*/}
						{/*	</tr>*/}
						{/*	<tr>*/}
						{/*		<td><strong>eeWriteData</strong>(addr, length)</td>*/}
						{/*		<td>Write a block</td>*/}
						{/*	</tr>*/}
						{/*	<tr>*/}
						{/*		<th colSpan={2}><strong>Serial EEPROM</strong></th>*/}
						{/*	</tr>*/}
						{/*	<tr>*/}
						{/*		<td>bit <strong>eeeReadData</strong>(addr)</td>*/}
						{/*		<td>Read a 16 B block from serial EEPROM to bufferINFO</td>*/}
						{/*	</tr>*/}
						{/*	<tr>*/}
						{/*		<td>bit <strong>eeeWriteData</strong>(addr)</td>*/}
						{/*		<td>Write a 16 B block from bufferINFO to EEPROM</td>*/}
						{/*	</tr>*/}
						{/*	<tr>*/}
						{/*		<th colSpan={2}><strong>RAM</strong></th>*/}
						{/*	</tr>*/}
						{/*	<tr>*/}
						{/*		<td>uns8 <strong>readFromRAM</strong>(address)</td>*/}
						{/*		<td>Read one byte</td>*/}
						{/*	</tr>*/}
						{/*	<tr>*/}
						{/*		<td><strong>writeToRAM</strong>(address, data)</td>*/}
						{/*		<td>Write one byte</td>*/}
						{/*	</tr>*/}
						{/*	<tr>*/}
						{/*		<td>void<strong>setINDF0</strong>(value)</td>*/}
						{/*		<td>Indirect write via virtual INDF0 register</td>*/}
						{/*	</tr>*/}
						{/*	<tr>*/}
						{/*		<td>void<strong>setINDF1</strong>(value)</td>*/}
						{/*		<td>Indirect write via virtual INDF1 register</td>*/}
						{/*	</tr>*/}
						{/*	<tr>*/}
						{/*		<th colSpan={2}><strong>Buffers</strong></th>*/}
						{/*	</tr>*/}
						{/*	<tr>*/}
						{/*		<td><strong>clearBufferINFO</strong>()</td>*/}
						{/*		<td>bufferINFO clearing</td>*/}
						{/*	</tr>*/}
						{/*	<tr>*/}
						{/*		<td><strong>clearBufferRF</strong>()</td>*/}
						{/*		<td>bufferRF clearing</td>*/}
						{/*	</tr>*/}
						{/*	<tr>*/}
						{/*		<td><strong>copyBufferINFO2COM</strong>()</td>*/}
						{/*		<td>Copy bufferINFO to bufferCOM</td>*/}
						{/*	</tr>*/}
						{/*	<tr>*/}
						{/*		<td><strong>copyBufferINFO2RF</strong>()</td>*/}
						{/*		<td>Copy bufferINFO to bufferRF</td>*/}
						{/*	</tr>*/}
						{/*	<tr>*/}
						{/*		<td><strong>copyBufferRF2COM</strong>()</td>*/}
						{/*		<td>Copy bufferRF to bufferCOM</td>*/}
						{/*	</tr>*/}
						{/*	<tr>*/}
						{/*		<td><strong>copyBufferRF2INFO</strong>()</td>*/}
						{/*		<td>Copy bufferRF to bufferINFO</td>*/}
						{/*	</tr>*/}
						{/*	<tr>*/}
						{/*		<td><strong>copyBufferCOM2RF</strong>()</td>*/}
						{/*		<td>Copy bufferCOM to bufferRF</td>*/}
						{/*	</tr>*/}
						{/*	<tr>*/}
						{/*		<td><strong>copyBufferCOM2INFO</strong>()</td>*/}
						{/*		<td>Copy bufferCOM to bufferINFO</td>*/}
						{/*	</tr>*/}
						{/*	<tr>*/}
						{/*		<td>bit <strong>compareBufferINFO2RF</strong>(length)</td>*/}
						{/*		<td>Comparison of bufferINFO and bufferRF</td>*/}
						{/*	</tr>*/}
						{/*	<tr>*/}
						{/*		<td>void <strong>swapBufferINFO</strong>()</td>*/}
						{/*		<td>Swap bufferINFO and bufferAUX</td>*/}
						{/*	</tr>*/}
						{/*	<tr>*/}
						{/*		<th colSpan={2}><strong>Data blocks</strong></th>*/}
						{/*	</tr>*/}
						{/*	<tr>*/}
						{/*		<td><strong>copyMemoryBlock</strong>(uns16 from, uns16 to, uns8 length)</td>*/}
						{/*		<td>Copy any data block to any position</td>*/}
						{/*	</tr>*/}
						{/*	<tr>*/}
						{/*		<td><strong>moduleInfo</strong>()</td>*/}
						{/*		<td>Get info about transceiver module and OS</td>*/}
						{/*	</tr>*/}
						{/*	<tr>*/}
						{/*		<td><strong>appInfo</strong>()</td>*/}
						{/*		<td>Copy info about application from EEPROM to bufferINFO</td>*/}
						{/*	</tr>*/}
						{/*	<tr>*/}
						{/*		<th colSpan={2}><strong>SPI</strong></th>*/}
						{/*	</tr>*/}
						{/*	<tr>*/}
						{/*		<td><strong>enableSPI</strong>()</td>*/}
						{/*		<td>SPI communication line activation</td>*/}
						{/*	</tr>*/}
						{/*	<tr>*/}
						{/*		<td><strong>disableSPI</strong>()</td>*/}
						{/*		<td>SPI communication line deactivation</td>*/}
						{/*	</tr>*/}
						{/*	<tr>*/}
						{/*		<td><strong>startSPI</strong>(length)</td>*/}
						{/*		<td>SPI packet transmission</td>*/}
						{/*	</tr>*/}
						{/*	<tr>*/}
						{/*		<td><strong>stopSPI</strong>()</td>*/}
						{/*		<td>SPI stopping</td>*/}
						{/*	</tr>*/}
						{/*	<tr>*/}
						{/*		<td><strong>restartSPI</strong>()</td>*/}
						{/*		<td>SPI continuing</td>*/}
						{/*	</tr>*/}
						{/*	<tr>*/}
						{/*		<td>bit <strong>getStatusSPI</strong>()</td>*/}
						{/*		<td>SPI status, update SPI flags</td>*/}
						{/*	</tr>*/}
						{/*	<tr>*/}
						{/*		<th colSpan={2}><strong>RF</strong></th>*/}
						{/*	</tr>*/}
						{/*	<tr>*/}
						{/*		<td><strong>setTXpower</strong>(level)</td>*/}
						{/*		<td>RF power setting (7 levels)</td>*/}
						{/*	</tr>*/}
						{/*	<tr>*/}
						{/*		<td><strong>setRFchannel</strong>(channel)</td>*/}
						{/*		<td>Select RF channel</td>*/}
						{/*	</tr>*/}
						{/*	<tr>*/}
						{/*		<td><strong>setRFmode</strong>(mode)</td>*/}
						{/*		<td>Select RF power management mode</td>*/}
						{/*	</tr>*/}
						{/*	<tr>*/}
						{/*		<td><strong>checkRF</strong>(level)</td>*/}
						{/*		<td>Detect incoming RF signal</td>*/}
						{/*	</tr>*/}
						{/*	<tr>*/}
						{/*		<td><strong>getRSSI</strong>()</td>*/}
						{/*		<td>Get RSSI value of incoming RF signal</td>*/}
						{/*	</tr>*/}
						{/*	<tr>*/}
						{/*		<td><strong>RFTXpacket</strong>()</td>*/}
						{/*		<td>Send a packet from bufferRF via RF</td>*/}
						{/*	</tr>*/}
						{/*	<tr>*/}
						{/*		<td>bit <strong>RFRXpacket</strong>()</td>*/}
						{/*		<td>Receive a packet via RF to bufferRF</td>*/}
						{/*	</tr>*/}
						{/*	<tr>*/}
						{/*		<th colSpan={2}><strong>Networking</strong></th>*/}
						{/*	</tr>*/}
						{/*	<tr>*/}
						{/*		<td><strong>setCoordinatorMode</strong>()</td>*/}
						{/*		<td>Device is the Coordinator</td>*/}
						{/*	</tr>*/}
						{/*	<tr>*/}
						{/*		<td><strong>setNodeMode</strong>()</td>*/}
						{/*		<td>Device is a Node</td>*/}
						{/*	</tr>*/}
						{/*	<tr>*/}
						{/*		<td><strong>setNonetMode</strong>()</td>*/}
						{/*		<td>Networking disabled</td>*/}
						{/*	</tr>*/}
						{/*	<tr>*/}
						{/*		<td><strong>setNetworkFilteringOn</strong>()</td>*/}
						{/*		<td>Packets accepted from current network only</td>*/}
						{/*	</tr>*/}
						{/*	<tr>*/}
						{/*		<td><strong>setNetworkFilteringOff</strong>()</td>*/}
						{/*		<td>Packets accepted from both networks</td>*/}
						{/*	</tr>*/}
						{/*	<tr>*/}
						{/*		<td>uns8 <strong>getNetworkParams</strong>()</td>*/}
						{/*		<td>Get information about the network</td>*/}
						{/*	</tr>*/}
						{/*	<tr>*/}
						{/*		<td>void <strong>sendFRC</strong>(cmd)</td>*/}
						{/*		<td>Request for Fast Response Command</td>*/}
						{/*	</tr>*/}
						{/*	<tr>*/}
						{/*		<td>void <strong>responseFRC</strong>()</td>*/}
						{/*		<td>Answer to Fast Response Command</td>*/}
						{/*	</tr>*/}
						{/*	<tr>*/}
						{/*		<td>bit <strong>amIRecipientfOfFRC</strong>()</td>*/}
						{/*		<td>Evaluate whether the FRC conmmand is intended for given Node</td>*/}
						{/*	</tr>*/}
						{/*	<tr>*/}
						{/*		<th colSpan={2}><strong>Routing</strong></th>*/}
						{/*	</tr>*/}
						{/*	<tr>*/}
						{/*		<td><strong>setRoutingOn</strong>()</td>*/}
						{/*		<td>Outgoing packets routed via other devices on background</td>*/}
						{/*	</tr>*/}
						{/*	<tr>*/}
						{/*		<td><strong>setRoutingOff</strong>()</td>*/}
						{/*		<td>No routing for outgoing packets</td>*/}
						{/*	</tr>*/}
						{/*	<tr>*/}
						{/*		<td>uns8 <strong>discovery</strong>(MaxNodeNumber)</td>*/}
						{/*		<td>Discover Nodes for routing</td>*/}
						{/*	</tr>*/}
						{/*	<tr>*/}
						{/*		<td><strong>answerSystemPacket</strong>()</td>*/}
						{/*		<td>Enable response to Coordinator for Discovery and Node authorization</td>*/}
						{/*	</tr>*/}
						{/*	<tr>*/}
						{/*		<td>bit <strong>isDiscoveredNode</strong>(N)</td>*/}
						{/*		<td>Check for being discovered</td>*/}
						{/*	</tr>*/}
						{/*	<tr>*/}
						{/*		<td>bit <strong>wasRouted</strong>()</td>*/}
						{/*		<td>Indicate incoming packet routing</td>*/}
						{/*	</tr>*/}
						{/*	<tr>*/}
						{/*		<td><strong>optimizeHops</strong>(method)</td>*/}
						{/*		<td>Optimize number of hops for given Node</td>*/}
						{/*	</tr>*/}
						{/*	<tr>*/}
						{/*		<th colSpan={2}><strong>Bonding - Node</strong></th>*/}
						{/*	</tr>*/}
						{/*	<tr>*/}
						{/*		<td>bit <strong>bondRequestAdvanced</strong>()</td>*/}
						{/*		<td>Request for bonding (local or remote)</td>*/}
						{/*	</tr>*/}
						{/*	<tr>*/}
						{/*		<td>bit <strong>amIBonded</strong>()</td>*/}
						{/*		<td>Is the Node bonded?</td>*/}
						{/*	</tr>*/}
						{/*	<tr>*/}
						{/*		<td><strong>removeBondAddress</strong>()</td>*/}
						{/*		<td>Changing Node address to universal address (0xFE)</td>*/}
						{/*	</tr>*/}
						{/*	<tr>*/}
						{/*		<td><strong>removeBond</strong>()</td>*/}
						{/*		<td>Unbonding</td>*/}
						{/*	</tr>*/}
						{/*	<tr>*/}
						{/*		<td><strong>prebondNodeAtNode</strong>()</td>*/}
						{/*		<td>Preparing Node for remote bonding via another Node</td>*/}
						{/*	</tr>*/}
						{/*	<tr>*/}
						{/*		<td><strong>setServiceChannel</strong>(W)</td>*/}
						{/*		<td>Select service RF channel</td>*/}
						{/*	</tr>*/}
						{/*	<tr>*/}
						{/*		<th colSpan={2}><strong>Bonding - Coordinator</strong></th>*/}
						{/*	</tr>*/}
						{/*	<tr>*/}
						{/*		<td>bit <strong>bondNewNode</strong>(address)</td>*/}
						{/*		<td>Local bonding a Node</td>*/}
						{/*	</tr>*/}
						{/*	<tr>*/}
						{/*		<td>bit <strong>prebondNodeAtCoordinator</strong>(address)</td>*/}
						{/*		<td>Preparing Node for remote bonding via Coordinator</td>*/}
						{/*	</tr>*/}
						{/*	<tr>*/}
						{/*		<td><strong>nodeAuthorization</strong>()</td>*/}
						{/*		<td>Remote bonding of prebonded Node</td>*/}
						{/*	</tr>*/}
						{/*	<tr>*/}
						{/*		<td><strong>smartConnect</strong>()</td>*/}
						{/*		<td>Bonding based on IBK</td>*/}
						{/*	</tr>*/}
						{/*	<tr>*/}
						{/*		<td>bit <strong>isBondedNode</strong>(N)</td>*/}
						{/*		<td>Is the Node bonded?</td>*/}
						{/*	</tr>*/}
						{/*	<tr>*/}
						{/*		<td><strong>removeBondedNode</strong>(N)</td>*/}
						{/*		<td>Unbonding a Node</td>*/}
						{/*	</tr>*/}
						{/*	<tr>*/}
						{/*		<td>bit <strong>rebondNode</strong>(N)</td>*/}
						{/*		<td>Rebonding a Node</td>*/}
						{/*	</tr>*/}
						{/*	<tr>*/}
						{/*		<td><strong>clearAllBonds</strong>()</td>*/}
						{/*		<td>Clearing of all bonds</td>*/}
						{/*	</tr>*/}
						{/*	<tr>*/}
						{/*		<th colSpan={2}><strong>Encryption</strong></th>*/}
						{/*	</tr>*/}
						{/*	<tr>*/}
						{/*		<td><strong>setAccessPassword</strong>()</td>*/}
						{/*		<td>Set Access password</td>*/}
						{/*	</tr>*/}
						{/*	<tr>*/}
						{/*		<td><strong>setUserKey</strong>()</td>*/}
						{/*		<td>Set the key for user encryption and decryption</td>*/}
						{/*	</tr>*/}
						{/*	<tr>*/}
						{/*		<td><strong>encryptBufferRF</strong>(W)</td>*/}
						{/*		<td>Encrypt bufferRF</td>*/}
						{/*	</tr>*/}
						{/*	<tr>*/}
						{/*		<td><strong>decryptBufferRF</strong>(x)</td>*/}
						{/*		<td>Decrypt bufferRF</td>*/}
						{/*	</tr>*/}
						{/*	<tr>*/}
						{/*		<th colSpan={2}><strong>RFPGM</strong></th>*/}
						{/*	</tr>*/}
						{/*	<tr>*/}
						{/*		<td><strong>enableRFPGM</strong>()</td>*/}
						{/*		<td>Set to switch to RFPGM mode after reset</td>*/}
						{/*	</tr>*/}
						{/*	<tr>*/}
						{/*		<td><strong>disableRFPGM</strong>()</td>*/}
						{/*		<td>Set not to switch to RFPGM mode after reset</td>*/}
						{/*	</tr>*/}
						{/*	<tr>*/}
						{/*		<td><strong>runRFPGM</strong>()</td>*/}
						{/*		<td>Switch to RFPGM mode</td>*/}
						{/*	</tr>*/}
						{/*	<tr>*/}
						{/*		<td><strong>setupRFPGM</strong>(x)</td>*/}
						{/*		<td>Setup RFPGM parameters</td>*/}
						{/*	</tr>*/}
						{/*	</tbody>*/}
						{/*</table>*/}
					</Col>
				</Row>
			</Grid>

		);
	}

}

/**
 * Exporting part of the React.Component file
 */
export default withTranslation()(TechnologyOSFunctionsScene);
