// @flow
import React from "react";
import {withTranslation} from "react-i18next";
import {Col, Grid, Row} from "react-flexbox-grid";
import {Helmet} from "react-helmet";

/**
 * @class ContactsScene
 */
class ContactsScene extends React.Component {


    /**
     * Final Render
     * @returns {*}
     */
    render() {

        const {t} = this.props;

        return (
			<Grid className={"page"}>
				<Helmet>
					<title>{t("title.Contacts")} - IQRF</title>
				</Helmet>
				<Row>
					<Col xs={12}>
						<h1>{t("title.Contacts")}</h1>
						<ul style={{width: "150px"}}>
							<li>Level 1</li>
							<li>Level 1 Lorem ipsum dorlo</li>
							<li>Level 1
								<ul>
									<li>Level 2</li>
									<li>Level 2 Lorem ipsum dorlo</li>
									<li>Level 2
										<ul>
											<li>Level 3</li>
											<li>Level 3 Lorem ipsum</li>
										</ul>
									</li>
								</ul>
							</li>
						</ul>
						Text pod
                    </Col>
                </Row>
            </Grid>
        );
    }

}

/**
 * Exporting part of the React.Component file
 */
export default withTranslation()(ContactsScene);