// @flow
import React from "react";
import {withTranslation} from "react-i18next";
import {Col, Grid, Row} from "react-flexbox-grid";
import BackendRequest from "../../Models/REST";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import MasterGetter from "../../Models/Utils/MasterGetter";
import LoadingComponent from "../../Components/LoadingComponent";
import {FileForm} from "../../Forms";
import InstantAction from "../../Models/Utils/InstantAction";
import {REMOVE_FILE} from "../../Models/Models/File";
import DeleteModal from "../../Components/DeleteModal";
import MyDropZone from "../../Components/MyDropzone";

/**
 * @class FileItemScene
 */
class FileItemScene extends React.Component {

	/**
	 * State
	 * @type {{expectingResponse: boolean}}
	 */
	state = {
		expectingResponse: true,
		modalIsOpen: false,
	};

	/**
	 * Fetch data
	 */
	fetchData = () => {

		const {match} = this.props;
		const itemId = match.params.id;

		BackendRequest({
			endpoint: "file-item/" + itemId,
			self: this,
		});

	};

	/**
	 * Component did mount
	 */
	componentDidMount() {
		this.fetchData();
	}

	/**
	 * Clone
	 */
	clone = () => {
		const {
			match
		} = this.props;

		const fileId = match.params.id;

		BackendRequest({
			endpoint: "file-item/" + fileId + "/copy",
			method: "post",
			self: this,
			afterSuccess: (response) => {
				InstantAction.redirect("/editor/file/" + response.data.file.id);
				this.forceUpdate();
			}
		});
	};


	/**
	 * Handle Delete
	 */
	handleDelete = () => {

		const {
			match
		} = this.props;
		const itemId = match.params.id;

		BackendRequest({
			endpoint: "file-item/" + itemId,
			method: "delete",
			afterSuccess: () => {

				InstantAction.dispatch({
					type: REMOVE_FILE,
					payload: itemId
				});

				InstantAction.redirect("/editor/files");
			}
		});

	};

	/**
	 *
	 * @param acceptedFiles
	 */
	onDrop = (acceptedFiles) => {

		const {match} = this.props;

		acceptedFiles.forEach(file => {

			let filenameSpliced = file.name.split(".");
			let fileType = filenameSpliced[filenameSpliced.length - 1];

			/**
			 * Payload
			 * @type {{file: *, filename: *, section: number, type: (*|string)}}
			 */
			const data = {
				file: file,
				filename: file.name,
				type: fileType,
				file_id: match.params.id,
			};

			BackendRequest({
				method: "post",
				endpoint: "file-upload",
				payload: data,
				self: this,
			});
		});
	};

	/**
	 * Final Render
	 * @returns {*}
	 */
	render() {

		const {
			t,
			entities,
			match,
		} = this.props;

		const itemId = match.params.id;
		const session = MasterGetter.getSession(entities);
		const file = session.File.withId(itemId);
		const isNew = file === null;

		if (this.state.expectingResponse) {
			return <LoadingComponent/>;
		}

		return (
			<Grid fluid>
				<Row>
					<Col xs={12} lg={6}>
						<h1>File: {isNew ? "[new]" : "[edit]"}</h1>
					</Col>
					<Col xs={12} lg={6} className="text--right">
						<br/>
						<button className="btn" onClick={this.clone}>Clone <i className="icon-copy"/></button>
						&nbsp;
						<button className={"btn btn--danger"} onClick={() => InstantAction.redirect("/editor/files")}>Go Back</button>
						&nbsp;
						<button className={"btn btn--danger"} onClick={() => {
							this.setState({
								modalIsOpen: true,
							});
						}}>{t("button.Delete")}</button>
					</Col>
					<Col xs={12}>
						<hr/>
					</Col>
					<Col xs={12}>
						<h2>Upload file</h2>
						<MyDropZone onDrop={this.onDrop} />
					</Col>
					<Col xs={12} className="page__section--bottom-padding">
						<FileForm file={file}/>
					</Col>
				</Row>
				<DeleteModal parent={this} handleDelete={this.handleDelete} isOpen={this.state.modalIsOpen} />
			</Grid>
		);
	}

}

/**
 * Map state to props
 * @param state
 * @return {{entities: {CaseStudy: (*|CaseStudy), Photo: (*|Photo)}}}
 */
const mapStateToProps = state => (
	{
		entities: {
			File: state.entities.File,
		},
	});

/**
 * Exporting part of the React.Component file
 */
export default withRouter(connect(mapStateToProps)(withTranslation()(FileItemScene)));