// @flow

// Necessary Evil
import React from "react";
import {Col, Grid, Row} from "react-flexbox-grid";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import {Link, withRouter} from "react-router-dom";
import BackendRequest from "../Models/REST";
import {LoadingComponent} from "../Components";

/**
 * @class Error404Scene
 */
class Error404Scene extends React.Component {

	/**
	 *
	 * @type {{expectingResponse: boolean}}
	 */
	state = {
		expectingResponse: true,
		notFound: true,
	};

	/**
	 * Try redirect
	 */
	tryRedirect = () => {

		let skipPages = [
			"/download",
			"/product-detail",
		];

		if (!skipPages.includes(window.location.pathname + window.location.search)) {
			BackendRequest({
				endpoint: "check-redirect",
				method: "post",
				payload: {
					url: window.location.pathname + window.location.search,
				},
				self: this,
				afterSuccess: (response) => {
					/**
					 *
				 */
				if (response.data.redirect) {
					window.location.replace(response.data.redirect);
					this.setState({
						expectingResponse: false,
						notFound: false,
					});
				} else {
					this.setState({
						expectingResponse: false,
						notFound: true,
					});
				}
				},
			});
		}else{
			this.setState({
				expectingResponse: false,
				notFound: true,
			});
		}
	};

	/**
	 *
	 */
	componentDidMount(): void {

		this.tryRedirect();

	}

	/**
	 * Final Render
	 * @returns {*}
	 */
	render() {

		if (this.state.expectingResponse) {
			return <LoadingComponent/>;
		} else {
			return (
				<Grid>
					<Row>
						{this.state.notFound ?
							<Col>
								<h1>Error 404</h1>
								<p><i>Requested page not found</i></p>
								<p>We are sorry that you didn't find what you were looking for. <strong>IQRF website has completely been redesigned</strong>, that is why some older links may not be accessible anymore.</p>
								<p>Please, go to the IQRF Home page <Link to={"/"}>www.iqrf.org</Link> and try to follow the menu structure.</p>
								<p>Thanks for understanding.</p>
								<p>Your IQRF team.</p>
							</Col> :
							null
						}
					</Row>
				</Grid>

			);
		}
	}

}

/**
 * This function maps the state to a
 * prop called `state`.
 *
 * In larger apps it is often good
 * to be more selective and only
 * map the part of the state tree
 * that is necessary.
 */
const mapStateToProps = state => (
	{
		app: state.app,
	});

/**
 * Exporting part of the React.Component file
 */
export default withRouter(connect(mapStateToProps)(withTranslation()(Error404Scene)));