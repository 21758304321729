// @flow

import PropTypes from "prop-types";
import {attr, fk} from "redux-orm";

import CRUDModel from "../ORM/CRUDModel";
import slugify from "slugify";


export default class ProductSubcategorySection extends CRUDModel {

    friendly_url = () => {
        return slugify(this.name).toLowerCase();
    }
}

ProductSubcategorySection.modelName = "ProductSubcategorySection";

ProductSubcategorySection.fields = {
    id: attr(), // non-relational field for any value; optional but highly recommended
    subcategory: fk("ProductSubcategory"),
    products_count: attr()
};

ProductSubcategorySection.propTypes = {
    name: PropTypes.string,
};

export const ADD_PRODUCT_SUBCATEGORY_SECTION = "ADD_PRODUCT_SUBCATEGORY_SECTION";
export const REMOVE_PRODUCT_SUBCATEGORY_SECTION = "REMOVE_PRODUCT_SUBCATEGORY_SECTION";
export const UPDATE_PRODUCT_SUBCATEGORY_SECTION = "UPDATE_PRODUCT_SUBCATEGORY_SECTION";