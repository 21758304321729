// @flow

import {attr, fk} from "redux-orm";

import CRUDModel from "../ORM/CRUDModel";

export default class Trademark extends CRUDModel {

}

Trademark.modelName = "Trademark";

Trademark.fields = {
    id: attr(),
    status: fk("TrademarkType"),
    country: fk("TrademarkCountry"),
    figure: fk("Photo"),
    filling_date: attr(),
    trademark: attr(),
    registration: attr(),
};

Trademark.propTypes = {};

export const ADD_TRADEMARK = "ADD_TRADEMARK";
export const REMOVE_TRADEMARK = "REMOVE_TRADEMARK";
export const UPDATE_TRADEMARK = "UPDATE_TRADEMARK";