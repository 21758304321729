// @flow
import React from "react";
import {withTranslation} from "react-i18next";
import {Col, Grid, Row} from "react-flexbox-grid";
import CopyToClipboard from "react-copy-to-clipboard";
import microrisc_hq from "../../App/images/content/budovaMR.png";
import logo_iqrf from "../../App/images/logo/iqrf-logo-blue.png";
import {Helmet} from "react-helmet";
import {Link} from "react-router-dom";

/**
 * @class ContactSalesScene
 */
class ContactSalesScene extends React.Component {

	/**
	 * Constructor
	 * @param props
	 */
	constructor(props) {
		super(props);

		this.state = {
			showCompanyIdentification: false,
			showBankAccounts: false,
		};
	}

	/**
	 * Final Render
	 * @returns {*}
	 */
	render() {

		const {t} = this.props;

		return (

			<Grid className={"page"}>
				<Helmet>
					<title>Corporate office - IQRF</title>
				</Helmet>
				<Row>
					<Col xs={12}>
						<h1>Corporate office</h1>
					</Col>
				</Row>
				<Row>
					<Col xs={12} xl={4}>
						<h2>IQRF Tech s.r.o.</h2>
						<p>Prumyslova 1275<br/>
							506 01 Jicin<br/>
							Czech Republic, EU<br/>
							Tel.: +420 493 538 125<br/>
							GSM: +420 777 573 357<br/>
							E-mail: <a href="mailto:sales@iqrf.org">sales@iqrf.org</a><br/>
							Web: <a href="https://www.iqrf.org/"
									rel="noopener noreferrer"
									target="_self">www.iqrf.org </a>
						</p>
						<div className={"link"} onClick={() => this.setState({showCompanyIdentification: !this.state.showCompanyIdentification})}>
							» Company identification
						</div>
						{this.state.showCompanyIdentification && (
							<div>
								<hr/>
								<p>Company ID: 06317375<br/>
									VAT: CZ06317375<br/>
									Registered by the Regional Court in Hradec Králové Section C, Insert 40027</p>
								<hr/>
							</div>
						)}
						<div className={"link"} onClick={() => this.setState({showBankAccounts: !this.state.showBankAccounts})}>
							» Bank accounts
						</div>
						{this.state.showBankAccounts && (
							<div>
								<hr/>
								<p>Komerční banka, a.s.<br/>
									SWIFT (BIC):
									<CopyToClipboard text="KOMBCZPPXXX">
                                             <span className={"bank-info"}> KOMBCZPPXXX &nbsp;
												 <i className="icon-copy"/>
                                             </span>
									</CopyToClipboard>
								</p>
								<p><b>Account nr. (CZK):</b>
									<CopyToClipboard text="115-5065760257/0100">
                                            <span className={"bank-info"}> 115-5065760257/0100 &nbsp;
												<i className="icon-copy"/>
                                            </span>
									</CopyToClipboard>
									<br/>
									IBAN:
									<CopyToClipboard text="CZ7601000001155065760257">
                                            <span className={"bank-info"}> CZ7601000001155065760257 &nbsp;
												<i className="icon-copy"/>
                                            </span>
									</CopyToClipboard>
								</p>
								<p><b>Account nr. (EUR):</b>
									<CopyToClipboard text="115-5065810277/0100">
                                            <span className={"bank-info"}> 115-5065810277/0100 &nbsp;
												<i className="icon-copy"/>
                                            </span>
									</CopyToClipboard>
									<br/>
									IBAN:
									<CopyToClipboard text="CZ6701000001155065810277">
                                            <span className={"bank-info"}> CZ6701000001155065810277 &nbsp;
												<i className="icon-copy"/>
                                            </span>
									</CopyToClipboard>
								</p>
								<p><b>Account nr. (USD):</b>
									<CopyToClipboard text="115-5065790237/0100">
                                            <span className={"bank-info"}> 115-5065790237/0100 &nbsp;
												<i className="icon-copy"/>
                                            </span>
									</CopyToClipboard>
									<br/>
									IBAN:
									<CopyToClipboard text="CZ8101000001155065790237">
                                            <span className={"bank-info"}> CZ8101000001155065790237 &nbsp;
												<i className="icon-copy"/>
                                            </span>
									</CopyToClipboard>
								</p>
							</div>
						)}

					</Col>
					<Col xs={12} xl={4}>
						<img width="340" src={logo_iqrf} alt={"IQRF Tech s.r.o."}/><br/>
						<img width="340" src={microrisc_hq} alt={"Research and Technology Center MICRORISC"}/><br/>
						Research and Technology Center MICRORISC,<br/> the IQRF Tech headquarters
					</Col>
					<Col xs={12} xl={4}>
						<iframe
							title={"map-to"}
							src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2541.709101955985!2d15.367491415913822!3d50.427891296870904!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x470ea3f256013c39%3A0x78bb99b7ab5777aa!2sIQRF!5e0!3m2!1scs!2scz!4v1569331417658!5m2!1scs!2scz"
							width="390" height="270" frameBorder="0" allowFullScreen=""/>
						<br/>
						<div className="text--center">
							GPS: 50.4279447N, 15.3696769E<br/>
							<a className="text--red" href="https://www.google.com/maps/place/IQRF/@50.4252594,15.3691877,17z/data=!4m5!3m4!1s0x470ea3f256013c39:0x78bb99b7ab5777aa!8m2!3d50.4278879!4d15.3696801?hl=cs"
							   rel="noopener noreferrer"
							   target="_blank">Enlarge</a>
						</div>
					</Col>
				</Row>
				<Row>
					<Col xs={12}>
						<hr/>
						<h1 id="ContactSales">{t("title.ContactSales")}</h1>
					</Col>
					<Col xs={12} className="page__section--bottom-padding">
						<p><a href="mailto:sales@iqrf.org">Contact IQRF office</a> for commercial or sales matters. For technical questions go to <a href="https://www.iqrf.org/support/contact-support">Contact Support</a>.</p>
					</Col>
				</Row>
			</Grid>

		);
	}

}

/**
 * Exporting part of the React.Component file
 */


export default withTranslation()(ContactSalesScene);

