// @flow
import React from "react";
import {withTranslation} from "react-i18next";
import {Col, Grid, Row} from "react-flexbox-grid";
import discovery from "../../Components/Technology/video/Discovery.mp4";
import {Helmet} from "react-helmet";
import {Link} from "react-router-dom";

/**
 * @class TechnologyFRCScene
 */
class TechnologyDiscoveryScene extends React.Component {


  /**
   * Final Render
   * @returns {*}
   */
  render() {

    return (
      <div className={"page"}>
      <Helmet>
      <title>Discovery - IQRF</title>
    </Helmet>
    <Grid>
    <Row className="page__section--bottom-padding">
      <Col xs={12}>
        <h1>Discovery</h1>
        <h2>Automated creating a routing structure</h2>
        <p><strong>Discovery</strong> is an initial process (invoked once the network devices are placed)
    which automatically establishes the <strong>routing structure</strong>. This structure is created from selected
    (or possibly all) Nodes already included (bonded) in the network. They can either be <strong>dedicated</strong> <Link to="/products/repeaters">repeaters</Link> (with no other functionality except of routing) or devices (typically supplied from mains) with given functionality
    (e.g. a remotely controlled switch) additionally <Link to="/unicast">routing</Link> <strong>in background</strong>.
      The routing structure must be <strong>fixed</strong>, that is why <strong>after changes</strong> (adding,
        removing or relocating a routing Node or a change in obstacles), the Discovery must be  <strong>reinvoked. Non-routing Nodes</strong> may be <strong>relocated</strong> without Discovery. </p>
        <p>In other words, Discovery <strong>assings</strong> a <strong>VRN</strong> (Virtual Routing Number) to each Node
      (with routing enabled in <a href="https://doc.iqrf.org/IQMESH-Network-deployment/pages/preparation-of-trs.html" target="_blank" rel="noreferrer noopener">TR configuration</a>) to be <strong>internally</strong> used for <strong>addressing</strong> and <strong>timing</strong> in IQMESH communication. <strong>Not discovered Nodes do not route</strong> at all.</p>
        <p>See chapter <a  href="https://doc.iqrf.org/IQMESH-Network-deployment/pages/discovery-creating-the-routing-structure.html" rel="noreferrer noopener" target="_blank">Discovery</a> in <Link to="/download/2b4e3da9-f9f5-4853-87b2-4733dcd5022f">IQMESH Network deployment</Link> technical guide.</p>
      </Col>
    </Row>
    </Grid>
    <Grid>
    <Row >
      <Col xs={12}>
        <div className="text--center">
          <h3 id="discovery-animation"></h3>
          <video width="560" controls>
          <source src={discovery}/>
          <p>Your browser does not support the video tag.</p>
          </video>
        </div>
      </Col>
    </Row>
    </Grid>
    <Grid>
    <Row>
    <Col xs={12}>
      <p>&nbsp;</p>
    </Col>
    </Row>
    </Grid>
    </div>

  );
  }

}

/**
 * Exporting part of the React.Component file
 */
export default withTranslation()(TechnologyDiscoveryScene);
