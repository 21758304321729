// @flow

// Necessary Evil
import React from "react";
import PropTypes from "prop-types";
import {Col, Row} from "react-flexbox-grid";
// Import component CSS
import "./OrderingBox.scss";
import ProductStatus from "../ProductStatus/ProductStatus";
import Markdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import rehypeSanitize from "rehype-sanitize";
import remarkGfm from "remark-gfm";
import MasterGetter from "../../../Models/Utils/MasterGetter";
import {connect} from "react-redux";
import BackendRequest from "../../../Models/REST";

/**
 * @class OrderingBox
 */
class OrderingBox extends React.Component {

    /**
     * PropTypes
     * @type {{children: shim}}
     */
    static propTypes = {
        productVariants: PropTypes.object,
        seriesId: PropTypes.number.isRequired,
    };

    state = {
        fetchedData: false,
        seriesId: null,
    };

    fetchData = (seriesId = null) => {

        if(seriesId == null){
            seriesId = this.props.seriesId;
        }

        BackendRequest({
            endpoint: "product-series/" + seriesId + "/variants",
            beforeRequest: () => {
                this.setState({
                    fetchedData: false
                });
            }
        });
    };

    static getDerivedStateFromProps(nextProps, state) {

        if (nextProps.seriesId !== state.seriesId) {
            return {
                fetchedData: true,
                seriesId: nextProps.seriesId
            };
        }

        return {};

    }

    shouldComponentUpdate(nextProps: Readonly<P>, nextState: Readonly<S>, nextContext: any): boolean {

        if(nextState.fetchedData){
            this.fetchData(nextState.seriesId);
        }

        return true;

    }

    /**
     *
     * @type {{}}
     */
    static defaultProps = {};

    /**
     * Final Render
     * @returns {*}
     */
    render() {

        const {productVariants} = this.props;

        if (productVariants.count() === 0) {
            return null;
        }


        return <Col xs={12} className="ordering-box">
            <div>
                <h2>Ordering</h2>
            </div>
            <div className="ordering-box__header">
                <Row>
                    <Col xs={12} lg={4} className={"content"}>
                        <div className="ordering-box__white-right-line">Product</div>
                    </Col>
                    <Col xs={12} lg={6} className={"content"}>
                        <div className="ordering-box__white-right-line">Description</div>
                    </Col>
                    <Col xs={12} lg={2} className={"content"}/>
                </Row>
            </div>
            <div>
                {productVariants.orderBy(["ordering"], ["desc"]).toModelArray().map(item => {

                    return <div className="ordering-box__item" key={item.name}>
                        <Row>
                            <Col xs={12} md={3} lg={4} className={"ordering-box__product-name"}>
                                {item.name}
                                <div className={"ordering-box__image"}>
                                    {item.image__url === null ? <div className="ordering-box__no-image">No Photo</div> :
                                        <img src={process.env.REACT_APP_BACKEND_SERVER + item.image__url}
                                             alt={item.name}/>}
                                </div>
                            </Col>
                            <Col xs={12} md={6} className={"ordering-box__product-description"}>
                                <ul>
                                    {item.description &&
                                    <li>
                                        <Markdown
                                          rehypePlugins={[rehypeRaw, rehypeSanitize]}
                                          remarkPlugins={[remarkGfm]}
                                          children={item.description}
                                        />
                                    </li>}
                                    {item.description_2 &&
                                    <li>
                                        <Markdown
                                          rehypePlugins={[rehypeRaw, rehypeSanitize]}
                                          remarkPlugins={[remarkGfm]}
                                          children={item.description_2}/>
                                    </li>}
                                </ul>
                                <div className="ordering-box__product-status">
                                    <strong><ProductStatus status={item.status}/><br/><ProductStatus status={item.status_2}/>  </strong>
                                </div>
                            </Col>
                            <Col xs={12} md={3} lg={2} className="ordering-box__options">
                                {item.is_online &&
                                <a href={item.eshop_url ? item.eshop_url : "https://eshop.iqrf.org/"} className="btn" target="_blank" rel="nofollow noreferrer">Go to E-shop</a>}
                                {!item.is_online &&
                                <a href="/sales/contact#ContactSales" className="btn ordering-box__disablebtn" rel="nofollow noreferrer" title="Contact our sales@iqrf.org for availability">Ask for availability</a>}

                        </Col>
                        </Row>
                    </div>;
                })}
            </div>
        </Col>;
    }
}

const mapStateToProps = (state, props) => {

    const session = MasterGetter.getSession(state.entities);
    const seriesId = props.seriesId;

    const productVariants = session.Product.filter(item => {
        return ((item.series === seriesId) && (item.is_available));
    });

    return {
        productVariants: productVariants,
    };
};


export default connect(mapStateToProps)(OrderingBox);
