// @flow
import React from "react";
import {withTranslation} from "react-i18next";
import {Col, Grid, Row} from "react-flexbox-grid";
import {Link} from "react-router-dom";
import {Helmet} from "react-helmet";

/**
 * @class ProductCrossTableScene
 */
class ProductCrossTableScene extends React.Component {

	/**
	 * Final Render
	 * @returns {*}
	 */
	render() {

		return (
			<Grid className={"page"}>
				<Helmet>
					<title>Cross table - TR series - IQRF</title>
				</Helmet>
				<Row className="page__section--bottom-padding">
					<Col xs={12}>
						<h1>Cross table - TR series</h1>
						<table className="table--crosstable">
							<tbody>
							<tr>
								<th colSpan={3}>Type</th>
								<th><Link to={"/product-detail/tr-72d"}>TR-72D</Link></th>
								<th><Link to={"/product-detail/tr-75d"}>TR-75D</Link></th>
								<th><Link to={"/product-detail/tr-76d"}>TR-76D</Link></th>
								<th><Link to={"/product-detail/tr-77d"}>TR-77D</Link></th>
								<th><Link to={"/product-detail/tr-72g"}>TR-72G</Link></th>
								<th><Link to={"/product-detail/tr-75g"}>TR-75G</Link></th>
								<th><Link to={"/product-detail/tr-76g"}>TR-76G</Link></th>
							</tr>
							<tr>
								<td colSpan={3}>Mounting</td>
								<td>SIM</td>
								<td>Vertical</td>
								<td>SMT</td>
								<td>SMT</td>
								<td>SIM</td>
								<td>Vertical</td>
								<td>SMT</td>
							</tr>
							<tr>
								<td colSpan={3}>Number of pins</td>
								<td>8</td>
								<td>12</td>
								<td>18</td>
								<td>18</td>
								<td>8</td>
								<td>12</td>
								<td>18</td>
							</tr>
							<tr>
								<td colSpan={3}>I/O</td>
								<td>6</td>
								<td>10</td>
								<td>12</td>
								<td>12</td>
								<td>6</td>
								<td>10</td>
								<td>12</td>
							</tr>
							<tr>
								<td colSpan={3}>MCU</td>
								<td colSpan={4}><Link to={"/download/2f8f225b-6a02-4e93-a7df-40367768aad7"}>PIC16LF1938</Link></td>
								<td colSpan={3}><Link to={"/download/57abca55-e2eb-49e2-9bf8-afee07a01d7d"}>PIC16LF18877</Link></td>
							</tr>
							<tr>
								<td rowSpan={3}>MCU internal memories</td>
								<td colSpan={2}>Flash</td>
								<td colSpan={4}>16 K x 14 b</td>
								<td colSpan={3}>32 K x 14 b</td>
							</tr>
							<tr>
								<td colSpan={2}>RAM</td>
								<td colSpan={4}>1024 B</td>
								<td colSpan={3}>4096 B</td>
							</tr>
							<tr>
								<td colSpan={2}>EEPROM</td>
								<td colSpan={4}>256 B</td>
								<td colSpan={3}>256 B</td>
							</tr>
							<tr>
								<td colSpan={3}>External serial EEPROM</td>
								<td colSpan={4}>32 KB</td>
								<td colSpan={3}>32 KB</td>
							</tr>
							<tr>
								<td colSpan={3}>RF IC</td>
								<td colSpan={4}><Link to={"/download/66337472-c88d-4a57-a843-1bbae47c77e3"}>SPIRIT1</Link></td>
								<td colSpan={3}><Link to={"/download/66337472-c88d-4a57-a843-1bbae47c77e3"}>SPIRIT1</Link></td>
							</tr>
							<tr>
								<td colSpan={3}>RF band [MHz]</td>
								<td> 868/916/433</td>
								<td> 868/916</td>
								<td> 868/916/433</td>
								<td> 868/916</td>
								<td> 868/916</td>
								<td> 868/916</td>
								<td> 868/916</td>
							</tr>
							<tr>
								<td colSpan={3}>RF bit rate</td>
								<td colSpan={4}> 19.8 kb/s</td>
								<td colSpan={3}> 19.8 kb/s</td>
							</tr>
							<tr>
								<td colSpan={3}>RF power <sup>1</sup></td>
								<td colSpan={4}> 10 mW</td>
								<td colSpan={3}> 10 mW</td>
							</tr>
							<tr>
								<td colSpan={3}>RF range <sup>2 3</sup></td>
								<td>500 m</td>
								<td>500 m</td>
								<td>500 m</td>
								<td>230 m</td>
								<td>500 m</td>
								<td>500 m</td>
								<td>500 m</td>
							</tr>
							<tr>
								<td rowSpan={6}>Typical suppply current</td>
								<td colSpan={2}>Deep sleep</td>
								<td>1.7 µA</td>
								<td>56 nA</td>
								<td>56 nA</td>
								<td>56 nA</td>
								<td>1.7 µA</td>
								<td>&lt; 100 nA</td>
								<td>&lt; 100 nA</td>
							</tr>
							<tr>
								<td colSpan={2}>Sleep</td>
								<td>2.3 µA</td>
								<td>610 nA</td>
								<td>610 nA</td>
								<td>610 nA</td>
								<td>2.6 µA</td>
								<td>&lt; 1 µA</td>
								<td>&lt; 1 µA</td>
							</tr>
							<tr>
								<td rowSpan={3}>RX</td>
								<td>STD</td>
								<td>12.1 mA</td>
								<td>11.8 mA</td>
								<td>11.8 mA</td>
								<td>11.8 mA</td>
								<td>13 mA</td>
								<td>13 mA</td>
								<td>13 mA</td>
							</tr>
							<tr>
								<td>LP</td>
								<td>260 µA</td>
								<td>250 µA</td>
								<td>250 µA</td>
								<td>250 µA</td>
								<td>260 µA</td>
								<td>260 µA</td>
								<td>260 µA</td>
							</tr>
							<tr>
								<td>XLP</td>
								<td>18.5 µA</td>
								<td>16.3 µA</td>
								<td>16.3 µA</td>
								<td>16.3 µA</td>
								<td>18 µA</td>
								<td>17 µA</td>
								<td>17 µA</td>
							</tr>
							<tr>
								<td colSpan={2}>TX</td>
								<td colSpan={4}>8.3 - 25 mA</td>
								<td colSpan={3}>8.3 - 25 mA</td>
							</tr>
							<tr>
								<td colSpan={3}>Temperature sensor IC&nbsp;<sup>4</sup></td>
								<td><Link to={"/download/4d3badde-897e-401c-85dd-9527125be30f"}>MCP9808</Link></td>
								<td>–</td>
								<td>–</td>
								<td>–</td>
								<td><Link to={"/download/4d3badde-897e-401c-85dd-9527125be30f"}>MCP9808</Link></td>
								<td>–</td>
								<td>–</td>
							</tr>
							<tr>
								<td colSpan={3}>LDO voltage regulator</td>
								<td><Link to={"/download/ad917340-5a59-4fb4-b12f-dde64286632f"}>MCP1700</Link></td>
								<td>–</td>
								<td>–</td>
								<td>–</td>
								<td><Link to={"/download/9159cfe4-1249-4576-b6fe-40a224644e6a"}>XC6218</Link></td>
								<td>–</td>
								<td>–</td>
							</tr>
							<tr>
								<td colSpan={3}>Supply voltage [V]</td>
								<td>3.1 – 5.3</td>
								<td>3.0 – 3.4</td>
								<td>3.0 – 3.4</td>
								<td>3.0 – 3.4</td>
								<td>3.1 – 5.3</td>
								<td>3.0 – 3.4</td>
								<td>3.0 – 3.4</td>
							</tr>
							<tr>
								<td colSpan={3}>LEDs</td>
								<td>2</td>
								<td>2</td>
								<td>–</td>
								<td>2</td>
								<td>2</td>
								<td>2</td>
								<td>–</td>
							</tr>
							<tr>
								<td colSpan={3}>A/D inputs</td>
								<td>2</td>
								<td>3</td>
								<td>3</td>
								<td>3</td>
								<td>2</td>
								<td>3</td>
								<td>3</td>
							</tr>
							<tr>
								<td colSpan={3}>SAW filter</td>
								<td>–</td>
								<td>–</td>
								<td>–</td>
								<td>Yes</td>
								<td>–</td>
								<td>–</td>
								<td>–</td>
							</tr>
							<tr>
								<td rowSpan={2}>Dimensions<br/>[mm]</td>
								<td colSpan={2}>Standard</td>
								<td>25.1 x 14.9</td>
								<td>–</td>
								<td>15.2 x 14.9</td>
								<td>25.1 x 14.9</td>
								<td>25.1 x 14.9</td>
								<td>–</td>
								<td>15.2 x 14.9</td>
							</tr>
							<tr>
								<td colSpan={2}>TR-xxx<strong>A</strong> <sup>3</sup></td>
								<td>31.8 x 14.9</td>
								<td>27.5 x 14.9</td>
								<td>23.3 x 14.9</td>
								<td>31.8 x 14.9</td>
								<td>31.8 x 14.9</td>
								<td>27.5 x 14.9</td>
								<td>23.3 x 14.9</td>
							</tr>
							<tr>
								<td colSpan={3}>Latest OS version</td>
								<td>4.06D</td>
								<td>4.06D</td>
								<td>4.06D</td>
								<td>4.06D</td>
								<td>4.06G</td>
								<td>4.06G</td>
								<td>4.06G</td>
							</tr>
							<tr>
								<td colSpan={3}>Status</td>
								<td>Standard</td>
								<td>Standard</td>
								<td>Standard</td>
								<td>Standard</td>
								<td>New</td>
								<td>New</td>
								<td>New</td>
							</tr>
							</tbody>
						</table>
						<p>&nbsp;</p>
						<table className="table--functions">
							<tbody>
							<tr>
								<td><sup>*</sup></td>
								<td>Preliminary information</td>
							</tr>

							<tr>
								<td><sup>1</sup></td>
								<td>RF output power programmable up to specified value</td>
							</tr>
							<tr>
								<td><sup>2</sup></td>
								<td>Typical RF range up to specified value, max. RF power, in free space</td>
							</tr>
							<tr>
								<td><sup>3</sup></td>
								<td>With built-in PCB antenna</td>
							</tr>
							<tr>
								<td><sup>4</sup></td>
								<td>Optional</td>
							</tr>
							<tr>
								<td><sup>5</sup></td>
								<td>When designed with <Link to="/product-detail/rng-ext-01">antenna counterpoise</Link></td>
							</tr>
							<tr>
								<td>TBD</td>
								<td>To be defined</td>
							</tr>
							</tbody>
						</table>
					</Col>
				</Row>
			</Grid>

		);
	}

}

/**
 * Exporting part of the React.Component file
 */
export default withTranslation()(ProductCrossTableScene);
