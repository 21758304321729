// @flow
import React from "react";
import {withTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import {Col, Grid, Row} from "react-flexbox-grid";
import wireless_upload from "../../Components/Technology/img/wireless-upload.jpg";
import wired_upload from "../../Components/Technology/img/wired-upload.jpg";
import "../../Components/Technology/Technology.scss";
import {Helmet} from "react-helmet";

/**
 * @class TechnologyRFPGMScene
 */
class TechnologyRFPGMScene extends React.Component {

	/**
	 * Constructor
	 * @param props
	 */
	constructor(props) {
		super(props);

		this.state = {
			showContentSupportedGUI: false,
			showContentGUI: false,
			showContentCommand: false,
			supportedCommand: false,
		};
	}

	/**
	 * Final Render
	 * @returns {*}
	 */
	render() {

		return (
			<Grid className={"page"}>
				<Helmet>
					<title>RFPGM – RF Programming - IQRF</title>
				</Helmet>
				<Row className="page__section--bottom-padding">
					<Col xs={12}>
						<h1>RFPGM – RF Programming<sup>TM</sup></h1>
						<h2>Wireless upload</h2>
						<p>Upload = writing an application code into a TR transceiver.</p>
						<h3>Two upload methods</h3>
					</Col>
					<Col xs={6}>
						<img src={wired_upload} alt={"IQRF RF"}/>
						<p><strong>Standard</strong></p>
						<p>TR is plugged <strong>in a programmer</strong> (via SIM connector) or populated in end equipment (in-circuit) and connected with the programmer <strong>by wires</strong>.</p>
					</Col>
					<Col xs={6}>
						<img src={wireless_upload} alt={"IQRF RF"}/>
						<p><strong>Unique, introduced by IQRF</strong></p>
						<p>TR is populated <b>in end equipment</b> and uploaded <strong>over the air</strong>. For network development such a <strong>mass upload</strong> (into all network nodes in one stroke) is a must.</p>
					</Col>
					<Col xs={12}>
						<ul>
							<li>RFPGM works in direct RF range only</li>
							<li>HEX, IQRF as well as TR configuration files can be uploaded</li>
							<li>For IQRF OS v3.04D or higher RFPGM support is completely included in OS. IQRF IDE CATS is intended as a programmer.</li>
						</ul>
						<div className="table--wrapper">
							<table className="table--with-links">
								<tbody>
								<tr>
									<td><Link to={"/technology/rfpgm/rfpgmhistory"}>» Product history</Link> - Release notes</td>
								</tr>
								</tbody>
							</table>
						</div>
					</Col>
				</Row>
			</Grid>
		);
	}
}

/**
 * Exporting part of the React.Component file
 */
export default withTranslation()(TechnologyRFPGMScene);