// @flow
import React from "react";
import {withTranslation} from "react-i18next";
import {Col, Grid, Row} from "react-flexbox-grid";
import BackendRequest from "../../Models/REST";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import MasterGetter from "../../Models/Utils/MasterGetter";
import LoadingComponent from "../../Components/LoadingComponent";
import {NewsForm} from "../../Forms";
import InstantAction from "../../Models/Utils/InstantAction";
import {REMOVE_NEWS} from "../../Models/Models/News";
import DeleteModal from "../../Components/DeleteModal";

/**
 * @class NewsItemScene
 */
class NewsItemScene extends React.Component {

    /**
     * State
     * @type {{expectingResponse: boolean}}
     */
    state = {
        expectingResponse: true,
        modalIsOpen: false,
    };

    /**
     * Fetch data
     */
    fetchData = () => {

        const {match} = this.props;
        const itemId = match.params.id;

        if (itemId) {
            BackendRequest({
                endpoint: "news-item/" + itemId,
                self: this,
            });
        }else{
            this.setState({
                expectingResponse: false,
            });
        }
    };

    /**
     * Component did mount
     */
    componentDidMount() {
        this.fetchData();
    }

    /**
     * Handle Delete
     */
    handleDelete = () => {

        const {
            match
        } = this.props;
        const itemId = match.params.id;

        BackendRequest({
            endpoint: "news-item/" + itemId,
            method: "delete",
            afterSuccess: () => {

                InstantAction.dispatch({
                    type: REMOVE_NEWS,
                    payload: itemId
                });

                InstantAction.redirect("/editor/news");
            }
        });

    };

    /**
     * Final Render
     * @returns {*}
     */
    render() {

        const {
            t,
            entities,
            match,
        } = this.props;

        const itemId = match.params.id;
        const session = MasterGetter.getSession(entities);
        const news = session.News.withId(itemId);

        const isNew = news === null;


        if (this.state.expectingResponse) {
            return <LoadingComponent/>;
        }

        return (
            <Grid fluid>
                <Row>
                    <Col xs={12} lg={6}>
                        <h1>{t("title.News")}: {isNew ? "[new]" : "[edit]"}</h1>
                    </Col>
                    <Col xs={12} lg={6} className="text--right">
                        <br/>
                        <button className="btn" onClick={() => window.history.back()}>Cancel <i
                            className="icon-back-arrow"/></button>
                        &nbsp;
                        <button className={"btn btn--danger"} onClick={() => InstantAction.redirect("/editor/news")}>Go
                            Back
                        </button>
                        &nbsp;
                        {!isNew && <button className={"btn btn--danger"} onClick={(e) => {
                            e.preventDefault();
                            this.setState({
                                modalIsOpen: true,
                            });
                        }}>{t("button.Delete")}</button>}
                    </Col>
                    <Col xs={12}>
                        <hr/>
                    </Col>
                    <Col xs={12} className="page__section--bottom-padding">
                        <NewsForm news={news}/>
                    </Col>
                </Row>
                <DeleteModal parent={this} handleDelete={this.handleDelete} isOpen={this.state.modalIsOpen}/>
            </Grid>
        );
    }

}

/**
 * Map state to props
 * @param state
 * @return {{entities: {CaseStudy: (*|CaseStudy), Photo: (*|Photo)}}}
 */
const mapStateToProps = state => (
    {
        entities: {
            Photo: state.entities.Photo,
            News: state.entities.News,
        },
    });

/**
 * Exporting part of the React.Component file
 */
export default withRouter(connect(mapStateToProps)(withTranslation()(NewsItemScene)));