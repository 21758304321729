// @flow

import {attr, fk} from "redux-orm";

import CRUDModel from "../ORM/CRUDModel";


export default class Photo extends CRUDModel {}

Photo.modelName = "Photo";

Photo.fields = {
	id: attr(), // non-relational field for any value; optional but highly recommended
	section: fk("PhotoSection"),
	url: attr(),
	uploaded: attr(),
};

Photo.propTypes = {
	// name: PropTypes.string.isRequired,
};


export const ADD_PHOTO = "ADD_PHOTO";
export const REMOVE_PHOTO = "REMOVE_PHOTO";
export const UPDATE_PHOTO = "UPDATE_PHOTO";