// Necessary Evil
import React from "react";
import {withTranslation} from "react-i18next";
import {Col, Grid, Row} from "react-flexbox-grid";
import {Helmet} from "react-helmet";
// Import component CSS
import "../../Components/Distributors/Distributors.scss";
import "../../Components/Distributors/DistributorCountryItem/DistributorCountryItem.scss";

/**
 * @class DesignHousesScene
 */
class DesignHousesScene extends React.Component {

  state = {
    show1: false,
    show2: false,
    show3: false,
    show4: false,
  };


  /**
   *
   */
  handleClick1 = () => {
    const {show1} = this.state;

    this.setState({
      show1: !show1
    });
  };

  handleClick2 = () => {
    const {show2} = this.state;

    this.setState({
      show2: !show2
    });
  };

  handleClick3 = () => {
    const {show3} = this.state;

    this.setState({
      show3: !show3
    });
  };

  handleClick4 = () => {
    const {show4} = this.state;

    this.setState({
      show4: !show4
    });
  };

  /**
   * Final Render
   * @returns {*}
   */
  render() {
    const showClass1 = !this.state.show1 ? "hidden" : "";
    const showClass2 = !this.state.show2 ? "hidden" : "";
    const showClass3 = !this.state.show3 ? "hidden" : "";
    const showClass4 = !this.state.show4 ? "hidden" : "";

    return <Grid className={"page"}>
      <Helmet>
      <title>IQRF - Design houses</title>
    </Helmet>
    <Row className="page page__section--bottom-padding">
      <Col xs={12}>
      <h1>Design houses</h1>
      <p>You can get a complete turn-key application done by a local Design house.</p>
      <p>&nbsp;</p>

      <div className="distributor__clickable" title={"Click for more"} onClick={this.handleClick1}>
        <img className={"distributor__flag"} src={process.env.REACT_APP_BACKEND_SERVER + "static/media/img/country/hr.png"} alt="Croatia"/>
        <strong> Croatia (1) </strong>
      </div>
      <div className={"distributor__list " + showClass1}>
        <Row>
          <Col xs={12} lg={6}>
            <div className={"distributor__item"}>
              <Row>
                <Col xs={12} lg={8}>
                  <h3>HIWE SQS - Croatia</h3>
                  <div>Hiwe SQS office Croatia</div>
                  <div>Josipa Bana Jelacica 22c, 40000 Čakovec, Croatia</div>
                  <div>Tel.: +385 98 19 88 666</div>
                  <div>E-mail: <a href={"mailto: info@hiwe-sqs.com.hr"}>info@hiwe-sqs.com.hr</a></div>
                  <div>Website: <a target="_blank" href="http://www.hiwe-it.com"	rel="noopener noreferrer">www.hiwe-it.com</a></div>
                </Col>
              </Row>
              <hr/>
            </div>
          </Col>;
        </Row>
      </div>
      <p>&nbsp;</p>
      <div className="distributor__clickable" title={"Click for more"} onClick={this.handleClick2}>
        <img className={"distributor__flag"} src={process.env.REACT_APP_BACKEND_SERVER + "static/media/img/country/dk.png"} alt="Denmark"/>
        <strong> Denmark (1) </strong>
      </div>
      <div className={"distributor__list " + showClass2}>
        <Row>
          <Col xs={12} lg={6}>
            <div className={"distributor__item"}>
              <Row>
                <Col xs={12} lg={8}>
                  <h3>KPJ Supply AS</h3>
                  <div>Rosenparken 49</div>
                  <div>9240 Nibe</div>
                  <div>Tel.: 45 20942968</div>
                  <div>E-mail: <a href={"mailto: kpj@kpjsupply.dk"}>kpj@kpjsupply.dk</a></div>
                  <div>Website: <a target="_blank" href="http://www.kpjsupply.dk"	rel="noopener noreferrer">http://www.kpjsupply.dk</a></div>
                </Col>
              </Row>
              <hr/>
            </div>
          </Col>;
        </Row>
      </div>
      <p>&nbsp;</p>
      <div className="distributor__clickable" title={"Click for more"} onClick={this.handleClick3}>
        <img className={"distributor__flag"} src={process.env.REACT_APP_BACKEND_SERVER + "static/media/img/country/pl.png"} alt="Poland"/>
        <strong> Poland (1) </strong>
      </div>
      <div className={"distributor__list " + showClass3}>
        <Row>
          <Col xs={12} lg={6}>
            <div className={"distributor__item"}>
            <Row>
              <Col xs={12} lg={8}>
                <h3>FIRMA INŻYNIERSKA MICHAŁ SMOLNIK</h3>
                <div>ul. Brzezińska 40</div>
                <div>44-203 Rybnik</div>
                <div>Tel.: +48 604666970</div>
                <div>Fax.: +48 327390150</div>
                <div>E-mail: <a href={"mailto: fims@fims.pl"}>fims@fims.pl</a></div>
                <div>Website: <a target="_blank" href="http://www.fims.pl"	rel="noopener noreferrer">www.fims.pl</a></div>
              </Col>
            </Row>
            <hr/>
          </div>
        </Col>;
      </Row>
      </div>
      <p>&nbsp;</p>
      <div className="distributor__clickable" title={"Click for more"} onClick={this.handleClick4}>
        <img className={"distributor__flag"} src={process.env.REACT_APP_BACKEND_SERVER + "static/media/img/country/es.png"} alt="Spain"/>
        <strong> Spain (1) </strong>
      </div>
      <div className={"distributor__list " + showClass4}>
        <Row>
          <Col xs={12} lg={6}>
            <div className={"distributor__item"}>
              <Row>
                <Col xs={12} lg={8}>
                  <h3>HIWE SQS  - Spain</h3>
                  <div>Hiwe SQS</div>
                  <div>Sangroniz 6, Edificio 5L, 48150 Sondika (Bizkaia) España</div>
                  <div>Tel.: +34 696 59 34 05</div>
                  <div>E-mail: <a href={"mailto: info@hiwe-sqs.com.hr"}>info@hiwe-sqs.com.hr</a></div>
                  <div>Website: <a target="_blank" href="http://www.hiwe-it.com"	rel="noopener noreferrer">www.hiwe-it.com</a></div>
                </Col>
              </Row>
              <hr/>
            </div>
          </Col>
        </Row>
      </div>
   </Col>
   </Row>
  </Grid>;
  }
}

/**
 * Exporting part of the React.Component file
 */
export default withTranslation()(DesignHousesScene);
