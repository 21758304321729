// @flow

// Necessary Evil
import React from "react";
import PropTypes from "prop-types";
import {withTranslation} from "react-i18next";
// Import component CSS
// import "./ReadFileWithMarkdown.scss";
import Markdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import rehypeSanitize from "rehype-sanitize";
import remarkGfm from "remark-gfm";


/**
 * @class ReadFileWithMarkdown
 */
class ReadFileWithMarkdown extends React.Component {

	/**
	 * PropTypes
	 * @type {{children: shim}}
	 */
	static propTypes = {
		children: PropTypes.any,
		url: PropTypes.string,
	};

	/**
	 *
	 * @type {{content: string}}
	 */
	state = {
		content: "",
	};

	/**
	 *
	 * @param file
	 */
	readMDFile = (file) => {

		let rawFile = new XMLHttpRequest();
		const self = this;

		rawFile.open("GET", file, false);
		rawFile.onreadystatechange = function () {
			if (rawFile.readyState === 4) {
				if (rawFile.status === 200 || rawFile.status === 0) {

					self.setState({
						content: rawFile.responseText
					});

				}
			}
		};

		rawFile.send(null);
	};

	/**
	 *
	 */
	componentDidMount(): * {
		const {url} = this.props;
		this.readMDFile(url);
	}

	/**
	 * Final Render
	 * @returns {*}
	 */
	render() {

		return <div className="read-file-with-md">
			<Markdown
				rehypePlugins={[rehypeRaw, rehypeSanitize]}
				remarkPlugins={[remarkGfm]}
				children={this.state.content}
			/>
		</div>;

		// let reader = new FileReader();
		//
		// reader.onload = function (event) {
		//
		// 	let content = event.target.result;
		//
		// 	/**
		// 	 * Key content
		// 	 * @type {{test: string, key: *}}
		// 	 */
		//
		//
		// };
		//
		//
		//
		// return reader.readAsText(this.readMDFile(url));


	}
}

export default withTranslation()(ReadFileWithMarkdown);
