// @flow
import React from "react";
import {withTranslation} from "react-i18next";
import {Col, Grid, Row} from "react-flexbox-grid";
import {withRouter} from "react-router";
import BackendRequest from "../../Models/REST";
import {Helmet} from "react-helmet";

/**
 * @class DownloadScene
 */
class DownloadScene extends React.Component {

	/**
	 * State
	 * @type {{filename: string}}
	 */
	state = {
		filename: "",
		link: "",
		error: false,
	};

	/**
	 * Download File
	 */
	downloadFile = () => {

		const {match} = this.props;

		BackendRequest({
			endpoint: "download/" + match.params.id,
			afterSuccess: (response) => {
				this.setState({
					filename: response.data.filename,
					link: response.data.link,
				});
				let downloadWindow = window.open(response.data.link, "_blank");
				if(!downloadWindow || downloadWindow.closed || typeof downloadWindow.closed=="undefined")
				{
					//POPUP BLOCKED
				}else{
					setTimeout(function() {
						window.close();
					}, 100);
				}
			},
			afterError: () => {
				this.setState({
					error: true,
					errorMessage: "ERROR: File not found"
				});
			}
		});
	};

	/**
	 * Component did mount
	 */
	componentDidMount(): void {
		this.downloadFile();
	};

	/**
	 * Final Render
	 * @returns {*}
	 */
	render() {

		return (
			<Grid className={"page"}>
				<Helmet>
					<title>Downloading file - {this.state.filename} - IQRF</title>
				</Helmet>
				<Row>
					{!this.state.error ?
						<Col xs={12}>
							<h1>Downloading file - {this.state.filename}</h1>
							<h2><a style={{
								textDecoration: "underline",
							}} href={this.state.link}>(Click here to download)</a></h2>
						</Col>
						:
						<Col xs={12}>
							<h1>Error - File not found</h1>
						</Col>
					}
				</Row>
			</Grid>
		);
	}

}

/**
 * Exporting part of the React.Component file
 */
export default withRouter(withTranslation()(DownloadScene));