// @flow
import React from "react";
import {withTranslation} from "react-i18next";
import {Col, Grid, Row} from "react-flexbox-grid";
import BackendRequest from "../../Models/REST";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import MasterGetter from "../../Models/Utils/MasterGetter";
import LoadingComponent from "../../Components/LoadingComponent";
import InstantAction from "../../Models/Utils/InstantAction";
import {StartupPackageHistoryForm} from "../../Forms";
import {REMOVE_STARTUP_PACKAGE_HISTORY} from "../../Models/Models/StartupPackageHistory";
import DeleteModal from "../../Components/DeleteModal";


/**
 * @class StartupPackageHistoryItemScene
 */
class StartupPackageHistoryItemScene extends React.Component {

	/**
	 * State
	 * @type {{expectingResponse: boolean}}
	 */
	state = {
		expectingResponse: true,
		modalIsOpen: false,
	};

	/**
	 * Fetch data
	 */
	fetchData = () => {

		const {match} = this.props;
		const itemId = match.params.id;

		BackendRequest({
			endpoint: "startup-package-history/" + itemId,
			self: this,
		});

	};

	/**
	 * Component did mount
	 */
	componentDidMount() {
		this.fetchData();
	}

	/**
	 * Handle Delete
	 */
	handleDelete = () => {

		const {
			match
		} = this.props;
		const itemId = match.params.id;

		BackendRequest({
			endpoint: "startup-package-history/" + itemId,
			method: "delete",
			afterSuccess: () => {

				InstantAction.dispatch({
					type: REMOVE_STARTUP_PACKAGE_HISTORY,
					payload: itemId
				});

				InstantAction.redirect("/editor/startup-package-history");
			}
		});
	};

	/**
	 * Final Render
	 * @returns {*}
	 */
	render() {

		const {
			t,
			entities,
			match,
		} = this.props;

		const itemId = match.params.id;
		const session = MasterGetter.getSession(entities);
		const record = session.StartupPackageHistory.withId(itemId);

		if (this.state.expectingResponse) {
			return <LoadingComponent/>;
		}

		return (
			<Grid fluid>
				<Row>
					<Col xs={12} lg={6}>
						<h1>Startup Package History Editor</h1>
					</Col>
					<Col xs={12} lg={6} className="text--right">
						<br/>
						<button className="btn" onClick={() => window.history.back()}>Cancel <i className="icon-back-arrow"/></button>
						&nbsp;
						<button className={"btn btn--danger"} onClick={() => InstantAction.redirect("/editor/startup-package-history")}>Go Back</button>
						&nbsp;
						<button className={"btn btn--danger"} onClick={(e) => {
							e.preventDefault();
							this.setState({
								modalIsOpen: true,
							});
						}}>{t("button.Delete")}</button>
					</Col>
					<Col xs={12}>
						<hr/>
					</Col>
					<Col xs={12} className="page__section--bottom-padding">
						<StartupPackageHistoryForm item={record}/>
					</Col>
				</Row>
				<DeleteModal
					isOpen={this.state.modalIsOpen}
					handleDelete={this.handleDelete}
					parent={this}
				/>
			</Grid>
		);
	}

}

/**
 * Map state to props
 * @param state
 * @return {{entities: {CaseStudy: (*|CaseStudy), Photo: (*|Photo)}}}
 */
const mapStateToProps = state => (
	{
		entities: state.entities,
	});

/**
 * Exporting part of the React.Component file
 */
export default withRouter(connect(mapStateToProps)(withTranslation()(StartupPackageHistoryItemScene)));