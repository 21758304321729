// @flow
import React from "react";
import {Col, Grid, Row} from "react-flexbox-grid";
import BackendRequest from "../../Models/REST";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import MasterGetter from "../../Models/Utils/MasterGetter";
import OrderingBox from "../../Components/Products/OrderingBox/OrderingBox";
import ProductGallery from "../../Components/Products/ProductGallery/ProductGallery";
import RelatedDownloads from "../../Components/Products/RelatedDownloads/RelatedDownloads";
import ProductStatus from "../../Components/Products/ProductStatus/ProductStatus";
import InstantAction from "../../Models/Utils/InstantAction";
import Markdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import rehypeSanitize from "rehype-sanitize";
import remarkGfm from "remark-gfm";
import {Tag} from "../../Components/Tag";
import "../../Components/Products/Product.scss";
import {ProductOptionalBox} from "../../Components/Products";
import {Helmet} from "react-helmet";

/**
 * @class ProductScene
 */
class ProductScene extends React.Component {

    /**
     * State
     * @type {{expectingResponse: boolean}}
     */
    state = {
        expectingResponse: true,
        updateData: false,
        fetchedData: true,
        currentProduct: null,
        seriesId: null,
    };

    fetchData = (seriesId = null) => {

        if(seriesId == null){
            seriesId = this.props.seriesId;
        }

        BackendRequest({
            endpoint: "product-series/" + seriesId,
            beforeRequest: () => {
                this.setState({
                    fetchedData: false
                });
            }
        });
    };

    static getDerivedStateFromProps(nextProps, state) {

        if (nextProps.seriesId !== state.seriesId) {
            return {
                fetchedData: true,
                seriesId: nextProps.seriesId
            };
        }

        return {};
    }

    componentDidMount(): void {
        this.fetchData();
    }

    shouldComponentUpdate(nextProps: Readonly<P>, nextState: Readonly<S>, nextContext: any): boolean {

        if(nextState.fetchedData){
            this.fetchData(nextState.seriesId);
        }

        return true;

    }

    /**
     * Final Render
     * @returns {*}
     */
    render() {

    	const {
    		series
		} = this.props;

    	if(!series){
    	    return null;
        }

        return (
            <Grid className={"page product"}>
                <Helmet>
                    <title>{series.name} {series.is_archived ? "Archived " : ""}- IQRF</title>
                </Helmet>
                <Row>
                    <Col xs={12}>
                        <Row>
                            <Col xs={12} md={6}>
                                <h1>{series.name} {series.is_archived && <Tag className={"red"}>Archived</Tag>}</h1>
                                {series.title_2 && <h2>{series.title_2}</h2>}
                                {series.title_3 && <h3>{series.title_3}</h3>}
                                <div>
                                    Product status: <ProductStatus status={series.status}/><br/>
                                    <ProductStatus status={series.status_2}/>
                                </div>
                                <br/>
                                <div>
                                    <Markdown
                                      rehypePlugins={[rehypeRaw, rehypeSanitize]}
                                      remarkPlugins={[remarkGfm]}
                                      children={series.top_page_left}
                                    />
                                </div>
                            </Col>
                            <Col xs={12} md={6}>
                                {series.image__url && <img className="product__main-image"
                                                      src={process.env.REACT_APP_BACKEND_SERVER + series.image__url}
                                                      alt={series.name}/>}
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={12}>
                        {series.top_page_bottom && <Markdown
                          rehypePlugins={[rehypeRaw, rehypeSanitize]}
                          remarkPlugins={[remarkGfm]}
                          children={series.top_page_bottom}
                        />}
                    </Col>
                    {/*<Col xs={12}>*/}
                    {/*	<hr/>*/}
                    {/*</Col>*/}
                    <ProductOptionalBox boxNumber={1} product={series}/>
                    <ProductOptionalBox boxNumber={2} product={series}/>
                    <ProductOptionalBox boxNumber={3} product={series}/>
                    <ProductOptionalBox boxNumber={4} product={series}/>
                    <ProductOptionalBox boxNumber={5} product={series}/>
                    <ProductOptionalBox boxNumber={6} product={series}/>
                    <ProductOptionalBox boxNumber={7} product={series}/>
                    <OrderingBox seriesId={series.id}/>
                    <RelatedDownloads seriesId={series.id}/>
                    <ProductGallery seriesId={series.id} photos={series.photos} />
                </Row>
            </Grid>

        );
    }

}

/**
 *
 * @param state
 * @return {{app: *, entities: *}}
 */
const mapStateToProps = (state, props) => {

	const session = MasterGetter.getSession(state.entities);
	const seriesSlug = props.match.params.series_url;
	const seriesQuery = session.ProductSeries.filter(item => item.friendly_url.toLowerCase() === seriesSlug.toLowerCase());

	if (seriesQuery.count() === 0) {
		InstantAction.redirect("/error-404");
		return null;
	}

	const series = seriesQuery.first();

    return {
        seriesId: series.id,
		series: series,
    };
};

/**
 * Exporting part of the React.Component file
 */
export default withRouter(connect(mapStateToProps)(ProductScene));
