// @flow
import React from "react";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import {withRouter} from "react-router-dom";
import {Col, Grid, Row} from "react-flexbox-grid";
import "./DPACalculator.scss";


/**
 * @class DPACalculator
 */
class DPACalculator extends React.Component {

	/**
	 * Constructor
	 * @param props
	 */
	constructor(props) {
		super(props);
		this.state = {
			NADR: "",
			PNUM: "",
			PCMD: "",
			HWPID: "ffff",
			PDATA: "",
			showPDATA: false,
		};

		// this.handleInputChange = this.handleInputChange.bind(this);
		// this.controlInput = this.controlInput.bind(this);
	}

	/**
	 * Control input
	 * @param number
	 */
	controlInput = (number)  =>{
		const regex = /[0-9A-Fa-f]+/g;
		if (!regex.test(number.key)) {
			number.preventDefault();
		}
	};

	/**
	 * Handle Input change
	 * @param event
	 */
	handleInputChange = (event) => {
		const target = event.target;
		const value = target.value;
		const name = target.name;

		this.setState({
			[name]: value
		});
	};

	/**
	 * @param hex string from input
	 * @returns {Array} in bytes
	 */
	hexToBytes(hex) {
		for (var bytes = [], counter = 0; counter < hex.length; counter += 2) {
			bytes.push(hex.substr(counter, 2).toString(16).toUpperCase());
		}
		return bytes;
	}

	/**
	 *
	 * @param num
	 * @param size
	 * @return {string}
	 */
	padZeros(num, size) {
		let string = num + "";

		while (string.length < size) string = "0" + string;
		return string;
	}

	/**
	 *
	 * @param arr
	 */
	pad0x(arr) {
		arr.forEach((num, index) => {
			return arr[index] = "0x" + num;
		});
	}

	/**
	 * Check Exception
	 * @param arrayOut
	 */
	checkExceptions(arrayOut) {
		for (let i = 0; i < arrayOut.length; i++) {
			if (arrayOut[i] === "0x7D") {
				let nestedArray7D = ["0x7D ", " 0x5D"];
				arrayOut.splice(i, 1, nestedArray7D);
			}
			if (arrayOut[i] === "0x7E") {
				let nestedArray7E = ["0x7D ", " 0x5E"];
				arrayOut.splice(i, 1, nestedArray7E);
			}
		}
	}

	/**
	 * Check CRC Exception
	 * @param CRC
	 * @return {*}
	 */
	checkCRCexception(CRC) {
		if (CRC === "0x7D") {
			CRC = "0x7D  0x5D";
		}
		if (CRC === "0x7E") {
			CRC = "0x7D  0x5E";
		}
		return CRC;
	}

	/**
	 * Calculate CRC
	 * @param array
	 * @return {number}
	 */
	calculateCRC(array) {
		let crc = 0xFF;
		for (let i = 0; i < array.length; i++) {
			crc = this.updateCRC(crc, array[i]);
		}
		return crc;
	}

	/**
	 * Update CRC
	 * @param crc
	 * @param value
	 * @return {*}
	 */
	updateCRC(crc, value) {
		for (let bitLoop = 8; bitLoop !== 0; --bitLoop, value >>= 1) {
			if (((crc ^ value) & 0x01) !== 0) {
				crc = ((crc >> 1) ^ 0x8C);
			} else {
				crc >>= 1;
			}
		}
		return crc;

	}


	/**
	 * Final Render
	 * @returns {*}
	 */
	render() {

		/**
		 * reverse() because of little endian
		 */

		const NADR = this.hexToBytes(this.padZeros(this.state.NADR, 4)).reverse();
		const PNUM = this.hexToBytes(this.padZeros(this.state.PNUM, 2)).reverse();
		const PCMD = this.hexToBytes(this.padZeros(this.state.PCMD, 2)).reverse();
		const HWPID = this.hexToBytes(this.padZeros(this.state.HWPID, 4)).reverse();
		const PDATA = this.hexToBytes(this.state.PDATA);

		const NADROut = this.hexToBytes(this.padZeros(this.state.NADR, 4)).reverse();
		const PNUMOut = this.hexToBytes(this.padZeros(this.state.PNUM, 2)).reverse();
		const PCMDOut = this.hexToBytes(this.padZeros(this.state.PCMD, 2)).reverse();
		const HWPIDOut = this.hexToBytes(this.padZeros(this.state.HWPID, 4)).reverse();
		const PDATAOut = this.hexToBytes(this.state.PDATA);

		this.pad0x(NADR);
		this.pad0x(PNUM);
		this.pad0x(PCMD);
		this.pad0x(HWPID);


		this.pad0x(NADROut);
		this.pad0x(PNUMOut);
		this.pad0x(PCMDOut);
		this.pad0x(HWPIDOut);

		let last_elementPDATA = PDATA[PDATA.length - 1];
		PDATA[PDATA.length - 1] = this.padZeros(last_elementPDATA, 2);
		this.pad0x(PDATA);

		let last_elementPDATAOut = PDATAOut[PDATAOut.length - 1];
		PDATAOut[PDATAOut.length - 1] = this.padZeros(last_elementPDATAOut, 2);
		this.pad0x(PDATAOut);

		this.checkExceptions(NADROut);
		this.checkExceptions(PNUMOut);
		this.checkExceptions(PCMDOut);
		this.checkExceptions(HWPIDOut);
		this.checkExceptions(PDATAOut);

		let CRCArray = NADR.concat(PNUM).concat(PCMD).concat(HWPID).concat(PDATA);

		let CRC = this.calculateCRC(CRCArray).toString(16).toUpperCase();
		CRC = "0x" + this.padZeros(CRC, 2);
		CRC = this.checkCRCexception(CRC);

		return (

			<Grid className={"dpa-calculator"}>
				<Row>
					<Col xs={12}>
						<h2>DPA UART HDLC and CRC calculator</h2>
						<p>Enter your DPA foursome and optional data to be sent via UART and you will obtain the
							appropriate DPA message including CRC and HDLC encapsulation.</p>
					</Col>
				</Row>
				<Row>
					<Col xs={12}>
						<form onSubmit={this.handleSubmit}>
							<div className="form-control">
								<label>
									<div className="text--orange-bold">NADR</div>
									0x
									<input className="NADR" type="text" required value={this.state.NADR} name="NADR" maxLength={4} onChange={this.handleInputChange} onKeyPress={(number) => this.controlInput(number)}/>
								</label>
							</div>
							<div className="form-control">
								<label>
									<div className="text--orange-bold">PNUM</div>
									0x
									<input className="PNUM" type="text" required value={this.state.PNUM} name="PNUM" maxLength={2} onChange={this.handleInputChange} onKeyPress={(number) => this.controlInput(number)}/>
								</label>
							</div>
							<div className="form-control">
								<label>
									<div className="text--orange-bold">PCMD</div>
									0x
									<input className="PCMD" type="text" required value={this.state.PCMD} name="PCMD" maxLength={2} onChange={this.handleInputChange} onKeyPress={(number) => this.controlInput(number)}/>
								</label>
							</div>
							<div className="form-control">
								<label>
									<div className="text--orange-bold">HWPID</div>
									0x
									<input className="HWPID" type="text" required value={this.state.HWPID} name="HWPID" maxLength={4} onChange={this.handleInputChange} onKeyPress={(number) => this.controlInput(number)}/>
								</label>
							</div>
							<div className="form-control">
								<label>
									<div className="text--orange-bold">PDATA</div>
									0x
									<input className="PDATA" type="text" value={this.state.PDATA} name="PDATA" maxLength={112} onChange={this.handleInputChange} onKeyPress={(number) => this.controlInput(number)}
										   onInput={() => this.setState({showPDATA: true})}/>
								</label>
							</div>
							<br/>
							<br/>
						</form>
					</Col>
				</Row>
				<Row className="page__section--with-margin">
					<Col xs={12}>
						<div className="table--with-results">
							<table>
								<tbody>
								<tr>
									<th colSpan="2"/>
									<th className="NADR" colSpan={2}>NADR</th>
									<th className="PNUM">PNUM</th>
									<th className="PCMD">PCMD</th>
									<th className="HWPID" colSpan={2}>HWPID</th>
									{this.state.showPDATA && (
										<th className="PDATA" colSpan={PDATA.length}>PDATA</th>
									)}
									<th className="CRC">CRC</th>
									<th/>
								</tr>
								<tr>
									<td>Data in</td>
									<td/>
									{NADR.map(NADRbyte => <td className="NADR">{NADRbyte}</td>)}
									<td className="PNUM">{PNUM}</td>
									<td className="PCMD">{PCMD}</td>
									{HWPID.map(HWPIDbyte => <td className="HWPID">{HWPIDbyte}</td>)}
									{PDATA.map(PDATAbyte => <td className="PDATA">{PDATAbyte}</td>)}
									<td className="CRC"/>
									<td/>
								</tr>
								<tr>
									<td>Data out</td>
									<td>0x7E</td>
									{NADROut.map(NADRbyte => <td className="NADR">{NADRbyte}</td>)}
									{PNUMOut.map(PNUMbyte => <td className="PNUM">{PNUMbyte}</td>)}
									<td className="PCMD">{PCMDOut}</td>
									{HWPIDOut.map(HWPIDbyte => <td className="HWPID">{HWPIDbyte}</td>)}
									{PDATAOut.map(PDATAbyte => <td className="PDATA">{PDATAbyte}</td>)}
									<td className="CRC">{CRC}</td>
									<td>0x7E</td>
								</tr>
								</tbody>
							</table>
						</div>
					</Col>
				</Row>
			</Grid>
		);
	}
}

/**
 * This function maps the state to a
 * prop called `state`.
 *
 * In larger apps it is often good
 * to be more selective and only
 * map the part of the state tree
 * that is necessary.
 */
const mapStateToProps = state => (
	{
		app: state.app,
	});

/**
 * Exporting part of the React.Component file
 */
export default withRouter(connect(mapStateToProps)(withTranslation()(DPACalculator)));