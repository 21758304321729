// @flow

// Necessary Evil
import React from "react";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import {withRouter} from "react-router-dom";
import BackendRequest from "../../Models/REST";
import MasterGetter from "../../Models/Utils/MasterGetter";

import "./EventsLoader.scss";
import Markdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import rehypeSanitize from "rehype-sanitize";
import remarkGfm from "remark-gfm";

/**
 * @class EventsLoader
 */
class EventsLoader extends React.Component {

	/**
	 * State
	 * @type {{expectingResponse: boolean}}
	 */
	state = {
		expectingResponse: true,
	};

	/**
	 * Fetch data from REST API
	 */
	fetchData = () => {

		BackendRequest({
			endpoint: "events/4",
			self: this,
		});
	};

	/**
	 * Component Did Mount
	 */
	componentDidMount() {
		this.fetchData();
	}

	/**
	 * Final Render
	 * @returns {*}
	 */
	render() {

		const {entities} = this.props;
		const session = MasterGetter.getSession(entities);
		const events = session.Event.all().filter(item => item.is_online).orderBy(["ordering", "starts"], ["desc", "desc"]);
		let limit = 5;

		return (
			<div className="events-loader">
				<ul>
					{events.toModelArray().map(item => {
						limit = limit - 1;
						if (limit <= 0) {
							return null;
						} else {
							return <li className="events-loader__item" key={item.id}>
								<div className="events-loader__title">
									<a href={item.url} rel="noopener noreferrer" target="_blank">
										<Markdown
											rehypePlugins={[rehypeRaw, rehypeSanitize]}
											remarkPlugins={[remarkGfm]}
											children={item.name}
										/>
									</a>
								</div>
								<div className="events-loader__description">{item.description}</div>
							</li>;
						}
					})}
					{events.count() === 0 && <div>No events available</div>}
				</ul>
			</div>
		);
	}
}

/**
 * This function maps the state to a
 * prop called `state`.
 * In larger apps it is often good
 * to be more selective and only
 * map the part of the state tree
 * that is necessary.
 */
const mapStateToProps = state => (
	{
		app: state.app,
		entities: state.entities,
	});

/**
 * Exporting part of the React.Component file
 */
export default withRouter(connect(mapStateToProps)(withTranslation()(EventsLoader)));
