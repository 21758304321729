// @flow

// Necessary Evil
import React from "react";
import PropTypes from "prop-types";
import {withTranslation} from "react-i18next";
import {Link} from "react-router-dom";
// Import component CSS
import "./FileAdminItem.scss";

/**
 * @class FileAdminItem
 */
class FileAdminItem extends React.Component {

	/**
	 * PropTypes
	 * @type {{file: shim, children: shim, allowOptions: shim}}
	 */
	static propTypes = {
		children: PropTypes.any,
		file: PropTypes.any.isRequired,
		allowOptions: PropTypes.bool,
	};

	/**
	 * Default Props
	 * @type {{allowOptions: boolean}}
	 */
	static defaultProps = {
		allowOptions: true,
	};

	/**
	 * Final Render
	 * @returns {*}
	 */
	render() {

		const {
			file,
		} = this.props;

		return <Link to={"/editor/file/" + file.id} className="file-item">
			<div className="file-item__name">
				<div className="file-item__icon">
					{file.type__icon && <img src={process.env.PUBLIC_URL + "/icon/" + file.type__icon + ".png"} alt={file.type__icon + " icon"}/>}</div>
				<strong>{file.name}</strong>
			</div>
			<div className="file-item__description">
				<table>
					<tbody>
					<tr>
						<td>Description</td>
						<td>{file.description}</td>
					</tr>
					<tr>
						<td>Filename</td>
						<td>{file.filename}</td>
					</tr>
					<tr>
						<td>URL</td>
						<td>{file.url}</td>
					</tr>
					<tr>
						<td>Online</td>
						<td>{file.is_online ? "Yes" : "No"}</td>
					</tr>
					<tr>
						<td>Archived</td>
						<td>{file.is_archived ? "Yes" : "No"}</td>
					</tr>
					</tbody>
				</table>
			</div>
		</Link>;
	}
}

export default withTranslation()(FileAdminItem);