// @flow
import React from "react";
import {withTranslation} from "react-i18next";
import {Col, Grid, Row} from "react-flexbox-grid";
import BackendRequest from "../../Models/REST";
import MasterGetter from "../../Models/Utils/MasterGetter";
import LoadingComponent from "../../Components/LoadingComponent";
import {connect} from "react-redux";
import {CareerItem} from "../../Components/Career";
import {Helmet} from "react-helmet";

/**
 * @class CareerScene
 */
class CareerScene extends React.Component {

	/**
	 * State
	 * @type {{expectingResponse: boolean}}
	 */
	state = {
		expectingResponse: true,
	};

	/**
	 * Fetch data from
	 */
	fetchData = () => {

		BackendRequest({
			endpoint: "careers",
			self: this
		});

	};

	/**
	 * Component will mount
	 */
	componentDidMount() {
		this.fetchData();
	}

	/**
	 * Final Render
	 * @returns {*}
	 */
	render() {

		const {
			t,
			entities,
		} = this.props;

		const session = MasterGetter.getSession(entities);
		const careers = session.CareerOffer.all();

		return (

			<Grid className={"page"}>
				<Helmet>
					<title>{t("title.Career")} - IQRF</title>
				</Helmet>
				<Row className="page__section--bottom-padding">
					<Col xs={12}>
						<h1>{t("title.Career")}</h1>
					</Col>
					<Col xs={12}>
						{this.state.expectingResponse ? <LoadingComponent/> :
							careers.toModelArray().map(offer => {
								return <CareerItem key={offer.id} offer={offer}/>;
							})}
					</Col>
				</Row>
			</Grid>
		);
	}

}

/**
 *
 * @param state
 * @return {{entities: {Patent: *}}}
 */
const mapStateToProps = state => (
	{
		entities: {
			CareerOffer: state.entities.CareerOffer,
		},
	});

/**
 * Exporting part of the React.Component file
 */
export default connect(mapStateToProps)(withTranslation()(CareerScene));