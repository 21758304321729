// @flow
import React from "react";
import {withTranslation} from "react-i18next";
import {Col, Grid, Row} from "react-flexbox-grid";
import BackendRequest from "../../Models/REST";
import MasterGetter from "../../Models/Utils/MasterGetter";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import ProductItemAdmin from "../../Components/Products/ProductItemAdmin";
import SearchBar from "../../Components/SearchBar/SearchBar";
import InstantAction from "../../Models/Utils/InstantAction";

/**
 * @class ProductsScene
 */
class ProductsScene extends React.Component {

    /**
     * State
     * @type {{expectingResponse: boolean}}
     */
    state = {
        expectingResponse: true,
        showArchived: false,
    };

    /**
     * Fetch app data if necessary
     */
    fetchData = () => {

        const {series} = this.props;

        if (series.count() === 0) {
            BackendRequest({
                endpoint: "navigation-data",
                self: this,
            });
        }

    };


    createNew = () => {
        BackendRequest({
            endpoint: "product",
            method: "post",
            afterSuccess: (response) => {
                InstantAction.redirect("/editor/product/item/" + response.data.product_series.id);
            }
        });
    };


    /**
     * Component did mount
     */
    componentDidMount() {

        if (localStorage.getItem("edit-archive")) {
            this.setState({
                showArchived: localStorage.getItem("edit-archive") === "true"
            });
        }
        this.fetchData();
    }

    /**
     * Final Render
     * @returns {*}
     */
    render() {

        const {
            series,
            categories,
            searchString,
        } = this.props;

        const {
            expectingResponse,
            showArchived,
        } = this.state;

        const products = series.filter(item => {
            // Filter search

            console.log(item.name, item.friendly_url, searchString);

            return (item.friendly_url !== "") &&
                (item.name ? item.name.toLowerCase().includes(searchString.toLowerCase()) : false) &&
                (item.is_archived === showArchived);

        }).orderBy(["ordering"], ["desc"]);

        /**
         *
         */

        // if (expectingResponse) {
        //     return <LoadingComponent/>;
        // }

        return (
            <Grid fluid>
                <Row>
                    <Col xs={12}>
                        <h1>Products</h1>
                    </Col>
                    <Col xs={12}>
                        <div className="page__section--bottom-padding">
                            <button className="btn" onClick={this.createNew}>+ Create New</button>
                        </div>
                    </Col>
                    <Col xs={12}>
                        <SearchBar/>
                        <br/>
                        <div className="text--center">
                            <button
                                onClick={() => {
                                    localStorage.setItem("edit-archive", !this.state.showArchived);
                                    this.setState({
                                        showArchived: !this.state.showArchived
                                    });
                                }}
                                className={"btn"}>{this.state.showArchived ? "Back from Archive" : "Archived Only"}</button>
                        </div>
                    </Col>
                    <Col xs={12} className="page__section--bottom-padding">
                        {categories.toRefArray().map(category => {

                            const productsInCategory = products.orderBy(["ordering"], ["desc"]).filter(product => {
                                return product.category === category.id;
                            }).orderBy("ordering");

                            return <div key={category.id}>
                                <h3>{category.name} ({productsInCategory.count()})</h3>
                                <div style={{
                                    display: "flex",
                                    flexWrap: "wrap"
                                }}>

                                    {productsInCategory.toModelArray().map(product => {
                                        return <ProductItemAdmin key={product.id} product={product}/>;
                                    })}
                                </div>
                            </div>;
                        })}
                        {products.count() === 0 && !expectingResponse ? "No products" : ""}
                    </Col>
                </Row>
            </Grid>
        );
    }

}

/**
 *
 * @param state
 * @return {{entities: *}}
 */
const mapStateToProps = (state) => {

    const session = MasterGetter.getSession(state.entities);
    const series = session.ProductSeries.all();
    const categories = session.ProductCategory.all().orderBy("ordering");

    return {
        categories: categories,
        series: series,
        searchString: state.app.search,
    };
};

/**
 * Exporting part of the React.Component file
 */
export default withRouter(connect(mapStateToProps)(withTranslation()(ProductsScene)));