// @flow

// Necessary Evil
import React from "react";
import PropTypes from "prop-types";
import {withTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import {Col} from "react-flexbox-grid";
// Import component CSS
import "./CaseStudyItemAdmin.scss";


/**
 * @class CaseStudyItemAdmin
 */
class CaseStudyItemAdmin extends React.Component {

	/**
	 * PropTypes
	 * @type {{children: shim}}
	 */
	static propTypes = {
		caseStudy: PropTypes.any.isRequired,
	};

	/**
	 * Final Render
	 * @returns {*}
	 */
	render() {

		const {caseStudy} = this.props;

		return <Link to={"/editor/case-studies/item/" + caseStudy.id}>
			<Col xs={12} className="case-study-item-admin">
				<div>
					 <i className="icon-use-case"/>&nbsp;
				</div>
				<div>
					<div className="case-study-item-admin__name">{caseStudy.ordering}. {caseStudy.name}</div>
				</div>
			</Col>
		</Link>;
	}
}

export default withTranslation()(CaseStudyItemAdmin);