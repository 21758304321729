// @flow
import React from "react";
import {withTranslation} from "react-i18next";
import BackendRequest from "../../Models/REST";
import {connect} from "react-redux";
import MasterGetter from "../../Models/Utils/MasterGetter";
import LoadingComponent from "../../Components/LoadingComponent";

import "../../Components/Patents/Patents.scss";
import {Col, Grid, Row} from "react-flexbox-grid";
import {Helmet} from "react-helmet";

/**
 * @class PatentsScene
 */
class PatentsScene extends React.Component {

	/**
	 * State
	 * @type {{expectingResponse: boolean}}
	 */
	state = {
		expectingResponse: true,
	};

	/**
	 * Fetch data from
	 */
	fetchData = () => {

		BackendRequest({
			endpoint: "patents",
			self: this
		});

	};

	/**
	 * Component will mount
	 */
	componentDidMount() {
		this.fetchData();
	}

	/**
	 * Final Render
	 * @returns {*}
	 */
	render() {

		const {t, entities} = this.props;
		const session = MasterGetter.getSession(entities);
		const patents = session.Patent.all();

		return (
			<Grid className={"page"}>
				<Helmet>
					<title>{t("title.Patents")} - IQRF</title>
				</Helmet>
				<Row className="page__section--bottom-padding">
					<Col xs={12}>
						<h1>{t("title.Patents")}</h1>
						<h2>IQRF Patents and Utility models</h2>
					</Col>
					<Col xl={4} xs={12}>
						{/*IQRF Patents and Utility models*/}
						<p>IQRF technology is protected by more than 30 patents and utility models in wireless,
							networking and
							automotive.</p>
					</Col>
					<Col xl={4} xs={12}>
						<div className="text--center">
							<img src="https://api.iqrf.org/static/media/img/web/c1d1314808176822b27c50e9143f04db.png" alt={"Patents"}/>
						</div>
					</Col>
					<Col xs={12}>
						<div className={"patents"}>
							<table>
								<tbody>
								{this.state.expectingResponse ? <LoadingComponent/> : ""}
								{patents.toModelArray().map(patent => {
									return <tr key={patent.id}>
										<td>{patent.name}</td>
										<td>{patent.description}</td>
									</tr>;
								})}
								</tbody>
							</table>
						</div>
					</Col>
				</Row>
			</Grid>


		);
	}

}

/**
 *
 * @param state
 * @return {{entities: {Patent: *}}}
 */
const mapStateToProps = state => (
	{
		entities: {
			Patent: state.entities.Patent,
		},
	});

/**
 * Exporting part of the React.Component file
 */
export default connect(mapStateToProps)(withTranslation()(PatentsScene));