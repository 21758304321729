// @flow

import PropTypes from "prop-types";
import {attr} from "redux-orm";

import CRUDModel from "../ORM/CRUDModel";


export default class FAQSection extends CRUDModel {
    // /**
    //  * @param {{id: number, name: string, color: string, voltage: number, capacity: number}} data
    //  * @returns {Object}
    //  */
    // static apiToPayload(data: Object)
    // {
    // 	return {
    // 		id: data.id,
    // 		name: data.name,
    // 		color: data.color,
    // 		voltage: Number(data.voltage),
    // 		capacity: Number(data.capacity) || null,
    // 	};
    // }


    // /**
    //  * @returns {{id: number, name: string, color: string, voltage: string, capacity: string}}
    //  */
    // toApi()
    // {
    // 	return {
    // 		id: this.id,
    // 		name: this.name,
    // 		color: this.color,
    // 		voltage: String(this.voltage),
    // 		capacity: String(this.capacity),
    // 	}
    // }
}

FAQSection.modelName = "FAQSection";

FAQSection.fields = {
    id: attr(), // non-relational field for any value; optional but highly recommended
};

FAQSection.propTypes = {
    name: PropTypes.string.isRequired,
};

// FAQSection.defaultProps = {
// };
//
// FAQSection.endpoint = "/group";

export const ADD_FAQ_SECTION = "ADD_FAQ_SECTION";
export const REMOVE_FAQ_SECTION = "REMOVE_FAQ_SECTION";
export const UPDATE_FAQ_SECTION = "UPDATE_FAQ_SECTION";