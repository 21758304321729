// @flow
import PropTypes from "prop-types";
import {attr} from "redux-orm";

import CRUDModel from "../ORM/CRUDModel";


export default class ProductStatus2 extends CRUDModel {

}

ProductStatus2.modelName = "ProductStatus2";

ProductStatus2.fields = {
    id: attr(), // non-relational field for any value; optional but highly recommended
};

ProductStatus2.propTypes = {
    name: PropTypes.string,
};

/**
 * Fixtures
 * @type {*[]}
 */
ProductStatus2.fixtures = [
    {
        id: 12,
        name: "3rd party product",
        description: "This product is supplied by the partner of IQRF Tech.",
        color: "#00cccc"
    }
];

export const ADD_PRODUCT_STATUS_2 = "ADD_PRODUCT_STATUS_2";
export const REMOVE_PRODUCT_STATUS_2 = "REMOVE_PRODUCT_STATUS_2";
export const UPDATE_PRODUCT_STATUS_2 = "UPDATE_PRODUCT_STATUS_2";