export default {
    Accessories: "Accessories",
    ApplicationNotes: "Application notes",
    ApplicationSoftware: "Application software",
    BrochuresLeafletsAndPresentations: "Brochures, leaflets and presentations",
    Certificates: "Certificates",
    ColorCombination: "Color, comb.",
    Combination: "Comb.",
    CommunicationProtocols: "Communication protocols",
    DevelopmentKits: "Development kits",
    DevelopmentSets: "Development sets",
    DevelopmentSoftware: "Development software",
    Distributors: "Distributors",
    DPA: "DPA",
    EndDevices: "End devices",
    Gateways: "Gateways",
    Miscellaneous: "Miscellaneous",
    OperatingSystem: "Operating system",
    Products: "Products",
    Repeaters: "Repeaters",
    StartingMaterials: "Starting materials",
    Text: "Text",
    ThirdPartyMaterials: "Third party materials",
    Trademarks: "Trademarks",
    Transceivers: "Transceivers",
    UseCases: "Use cases",
    WebContent: "Web content",
    Empty: "",
    BasicMaterials: "Basic materials"
};