// @flow
import React from "react";
import {withTranslation} from "react-i18next";
import {Col, Grid, Row} from "react-flexbox-grid";
import {Link} from "react-router-dom";
import IQRFAllianceLogoDark from "../../App/images/logo/iqrf-alliance-member-2023-blue.svg";
import microrisc_hq from "../../App/images/content/budovaMR.png";
import CopyToClipboard from "react-copy-to-clipboard";
import "../../Components/Company/Company.scss";
import {Helmet} from "react-helmet";

/**
 * @class CompanyScene
 */
class CompanyScene extends React.Component {

    /**
     * Final Render
     * @returns {*}
     */
    render() {

        const {t} = this.props;

        return (
            <div className={"page"}>
                <Helmet>
                    <title>IQRF Tech s.r.o.</title>
                </Helmet>
                <Grid className="page__section--bottom-padding">
                    <Row>
                        <Col xs={12}>
                            <h1>IQRF Tech s.r.o.</h1>
                            <h2>Company profile</h2>
                        </Col>
                        <Col xs={12} lg={8}>
                            <p>We are a Czech <a
                                href="https://or.justice.cz/ias/ui/rejstrik-firma.vysledky?subjektId=982248&typ=PLATNY"
                                rel="noopener noreferrer" target="_blank">privately
                                owned </a>
                                technological company focused on development of IQRF wireless Mesh technology.</p>
                            <p>The original IQRF inventor and developer was <a href="https://microrisc.com" rel="noopener noreferrer" target="_blank">MICRORISC
                                s.r.o.</a>, since 2004. In 2017,
                                all activities concerning IQRF and the complete development team were transferred
                                from it into the newly founded IQRF Tech purely dedicated to IQRF. Thus, IQRF Tech
                                is a technological spinoff company of the MICRORISC group. It is the owner of all
                                intelectual properties related to IQRF including all <Link
                                    to={"/patents"}>{t("patents")}</Link> and <Link
                                    to={"/trademarks"}>{t("trademarks")}</Link>.</p>
                            <p>IQRF development team has been awarded by number of prizes including <a rel="noopener noreferrer" href="http://www.ceskahlava.cz/cz/2014/vitezove-ceska-hlava/" target="_blank">Česká
                                hlava</a>,
                                the most prestigious scientifical award in Czech Republic (under patronage of
                                Ministry of Industry and Trade of Czech Republic, in 2014).</p>
                            <p>IQRF Tech is a founding member of <a rel="noopener noreferrer" href="https://www.iqrfalliance.org" target="_blank">IQRF
                                Alliance</a>, a non-profit international association
                                of companies and institutions building up an ecosystem of interoperable wireless
                                devices and solutions based on the IQRF technology.</p>
                            <br/>
                        </Col>
                        <Col xs={12} lg={4}>
                            <img src={microrisc_hq} alt={"Research and Technology Center MICRORISC"}/>

                            <br/>
                            <br/>
                            <a className={"dark-logo"} href={"https://iqrfalliance.org/"} target="_blank" rel="noopener noreferrer">
                                <img src={IQRFAllianceLogoDark} alt={"IQRF Alliance member"}/>
                            </a>
                        </Col>
                    </Row>
                </Grid>
                <div className="page__section--with-theme">
                    <Grid>
                        <Row>
                            <Col xs={12} lg={6}>
                                <h2>Company identification</h2>
                                <h3>Billing information</h3>
                                <p>IQRF Tech s.r.o.<br/>
                                    Prumyslova 1275<br/>
                                    506 01 Jicin<br/>
                                    Czech Republic, EU<br/>
                                    Company ID: 06317375<br/>
                                    VAT: CZ06317375<br/>
                                    Registered by the Regional Court in Hradec Králové Section C, Insert 40027</p>
                            </Col>
                            <Col xs={12} lg={6}>
                                <div>
                                    <h2>Bank information</h2>
                                    <p>Komerční banka, a.s.<br/>
                                        SWIFT (BIC):
                                        <CopyToClipboard text="KOMBCZPPXXX">
                                             <span className={"bank-info"}> KOMBCZPPXXX &nbsp;
                                                 <i className="icon-copy"/>
                                             </span>
                                        </CopyToClipboard>
                                    </p>
                                    <p><b>Account nr. (CZK):</b>
                                        <CopyToClipboard text="115-5065760257/0100">
                                            <span className={"bank-info"}> 115-5065760257/0100 &nbsp;
                                                <i className="icon-copy"/>
                                            </span>
                                        </CopyToClipboard>
                                        <br/>
                                        IBAN:
                                        <CopyToClipboard text="CZ7601000001155065760257">
                                            <span className={"bank-info"}> CZ7601000001155065760257 &nbsp;
                                                <i className="icon-copy"/>
                                            </span>
                                        </CopyToClipboard>
                                    </p>
                                    <p><b>Account nr. (EUR):</b>
                                        <CopyToClipboard text="115-5065810277/0100">
                                            <span className={"bank-info"}> 115-5065810277/0100 &nbsp;
                                                <i className="icon-copy"/>
                                            </span>
                                        </CopyToClipboard>
                                        <br/>
                                        IBAN:
                                        <CopyToClipboard text="CZ6701000001155065810277">
                                            <span className={"bank-info"}> CZ6701000001155065810277 &nbsp;
                                                <i className="icon-copy"/>
                                            </span>
                                        </CopyToClipboard>
                                    </p>
                                    <p><b>Account nr. (USD):</b>
                                        <CopyToClipboard text="115-5065790237/0100">
                                            <span className={"bank-info"}> 115-5065790237/0100 &nbsp;
                                                <i className="icon-copy"/>
                                            </span>
                                        </CopyToClipboard>
                                        <br/>
                                        IBAN:
                                        <CopyToClipboard text="CZ8101000001155065790237">
                                            <span className={"bank-info"}> CZ8101000001155065790237 &nbsp;
                                                <i className="icon-copy"/>
                                            </span>
                                        </CopyToClipboard>
                                    </p>
                                </div>
                            </Col>
                        </Row>
                    </Grid>
                </div>
            </div>
        );
    }

}

/**
 * Exporting part of the React.Component file
 */
export default withTranslation()(CompanyScene);