// @flow
import React from "react";
import {withTranslation} from "react-i18next";
import {Col, Grid, Row} from "react-flexbox-grid";
import "../../Components/Technology/Technology.scss";
import {Helmet} from "react-helmet";

/**
 * @class TechnologyOpenStandardScene
 */
class TechnologyOpenStandardScene extends React.Component {

  /**
   * Constructor
   * @param props
   */
  constructor(props) {
    super(props);

    this.state = {
      showContentSupportedGUI: false,
      showContentGUI: false,
      showContentCommand: false,
      supportedCommand: false,
    };
  }

  /**
   * Final Render
   * @returns {*}
   */
  render() {

    return (
      <Grid className={"page"}>
        <Helmet>
          <title>License and carrier free - IQRF</title>
        </Helmet>
        <Row>
          <Col xs={12}>
            <h1>IQRF Open Standard</h1>
            <p>IQRF technology is introduced as an <a href="https://open.iqrf.org/" rel="noopener noreferrer" target="_blank">open standard</a> for wireless mesh networks. Licensed <strong>Royalty-free</strong>.</p>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <h1>License and carrier free operation</h1>
            <p>Using <strong>IQRF</strong> wireless technology, the only costs associated with deployment and operation
    are <strong>one-shot acquisition costs</strong> for the <strong>equipment</strong> (hardware). Once the system is developed,
      there are no additional costs, independently on number of instances deployed.</p>
            <ul>
              <li>All <strong>software</strong> (operating system, plug-ins, ready-to-use SW solutions, etc.) is free.</li>
              <li>No paid SW solution stack is needed.</li>
              <li>RF bands are free.</li>
              <li>IQRF works <strong>autonomously, no external infrastructure</strong> and <strong>no provider</strong> are needed.</li>
            </ul>
            <p>Many other technologies charge considerable amounts for the operation of the network itself.
      These fees often exceed the acquisition cost within a few months. So, in today’s world full of lump sums, <strong>IQRF</strong> is a pleasant exception allowing wireless IoT <strong>without regular costs</strong>.</p>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
          </Col>
        </Row>
      </Grid>
);
}
}

/**
 * Exporting part of the React.Component file
 */
export default withTranslation()(TechnologyOpenStandardScene);