// @flow
import React from "react";
import {withTranslation} from "react-i18next";
import {Col, Grid, Row} from "react-flexbox-grid";
import autonetwork from "../../Components/Technology/video/autonetwork.mp4";
import {Helmet} from "react-helmet";
import {HashLink} from "react-router-hash-link";

/**
 * @class TechnologyAutonetworkScene
 */
class TechnologyAutonetworkScene extends React.Component {


  /**
   * Final Render
   * @returns {*}
   */
  render() {

    return (
      <div className={"page"}>
      <Helmet>
        <title>Autonetwork - IQRF</title>
      </Helmet>
        <Grid>
          <Row className="page__section--bottom-padding">
            <Col xs={12}>
              <h1>Autonetwork</h1>
              <h2>Automated creating the complete network</h2>
              <p>IQRF network can be created fully automatically.
                 All Nodes must have <a href="https://doc.iqrf.org/IQMESH-Network-deployment/pages/preparation-of-trs.html" rel="noreferrer noopener" target="_blank">configured</a> the same <HashLink to="/technology/security#access-password">Access Password</HashLink> as the Coordinator.</p>
              <p>The network creation passes in <strong>waves</strong>. In every wave, the following operations are performed:</p>
              <ul>
                <li><strong>Prebonding</strong> (assigning the <strong>temporary address</strong>)</li>
                <li><strong>Authorization</strong> (assigning the <strong>logical address</strong>)</li>
                <li><strong>Verification</strong> of bonded Nodes</li>
                <li><strong>Discovery</strong> (creation of <strong>routing structure</strong>)</li>
              </ul>
              <p>The waves repeat until no new Nodes are found.</p>
              <p>See chapter <a href="https://doc.iqrf.org/IQMESH-Network-deployment/pages/autonetwork.html" rel="noreferrer noopener" target="_blank">Autonetwork</a> in <a href="https://www.iqrf.org/download/2b4e3da9-f9f5-4853-87b2-4733dcd5022f">IQMESH Network deployment</a> technical guide.</p>
            </Col>
          </Row>
        </Grid>
        <Grid>
          <Row>
            <Col xs={12}>
              <div className="text--center">
                <h3 id="autonetwork-animation"></h3>
                <video width="560" controls>
                  <source src={autonetwork}/>
                <p>Your browser does not support the video tag.</p>
                </video>
              </div>
            </Col>
          </Row>
        </Grid>
    <Grid>
    <Row>
    <Col xs={12}>
      <p>&nbsp;</p>
    </Col>
    </Row>
    </Grid>
    </div>
  );
  }

}

/**
 * Exporting part of the React.Component file
 */
export default withTranslation()(TechnologyAutonetworkScene);
