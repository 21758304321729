export default {
    Account: "Account",
    Content: "Content",
    ContentPreview: "Content preview",
    CopySourceCode: "Copy source code",
    Country: "Country",
    Date: "Date",
    Description: "Description",
    Discount: "Discount",
    Email: "E-mail",
    Figure: "Figure",
    FillingDate: "Filling Date",
    ImagePreview: "Image preview",
    Length: "Length",
    Message: "Message",
    Name: "Name",
    NumberOfParticipants: "Number of participants",
    PageTitle: "Page title",
    Participants: "Participants",
    Password: "Password",
    PhotoName: "Photo name",
    Place: "Place",
    PleaseSelectSectionBeforeUpload: "Please select section before upload",
    Preconditions: "Preconditions",
    Preview: "Preview",
    Price: "Price",
    Refreshment: "Refreshment",
    RegNo: "Reg. No.",
    Search: "Search",
    Section: "Section",
    SelectImage: "Select image",
    SerialNo: "Serial No.",
    Subject: "Subject",
    Title: "Title",
    Trademark: "Trademark",
    Type: "Type",
    YourEmailAddress: "Your e-mail address",
    OrUploadImage: "Or upload image",
    Series: "Series",
    Category: "Category",
    Subcategory: "Subcategory",
    Status: "Status",
    PricelistDescription: "Pricelist description",
    RadioBands: "Radio bands",
    ProductPrice: "Product price",
    LeadTime: "Lead time",
    Packing: "Packing",
    IsArchived: "Is archived",
    IsOnline: "Is online",
    IsInPricelist: "Is in pricelist",
    IsAvailable: "Is available",
    MainPhoto: "Main Photo",
    Gallery: "Gallery",
    PhotoGallery: "Photo gallery",
    ValidFrom: "Valid from",
    Price1: "Price from 1 pts",
    Price5: "Price from 5 pts",
    Price20: "Price from 20 pts",
    Price100: "Price from 100 pts",
    Price1000: "Price from 1000 pts",
    OnRequest: "On request",
    ProductPrices: "Product prices",
    DateOfPublish: "Date of publish",
    TimeOfPublish: "Time of publish",
};