import React from "react";
import MasterGetter from "../../Models/Utils/MasterGetter";
import {connect} from "react-redux";
import {LinkItem} from "../Links";
import {Col} from "react-flexbox-grid";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";


class LinksLoader extends React.Component {

    state = {
        showAutoRedirectLinks: false,
        showNonRedirectLinks: true,
    };

    render() {
        const {
            links,
        } = this.props;

        let itemsQuery = links.filter(item => {

            if(item.auto_redirect && this.state.showAutoRedirectLinks){
                return true;
            }

            if(!item.auto_redirect && this.state.showNonRedirectLinks){
                return true;
            }

            return false;
        }).orderBy(["added"], ["desc"]);

        return (<div>
            <Col xs={12}>
                <FormControlLabel
                    control={<Checkbox
                        checked={this.state.showNonRedirectLinks}
                        onChange={() => {
                            this.setState({
                                showNonRedirectLinks: !this.state.showNonRedirectLinks
                            });
                        }}
                        inputProps={{"aria-label": "Non-Redirect Links"}}
                    />}
                    label="Show Non-Redirect links"
                />
                <FormControlLabel
                    control={<Checkbox
                        checked={this.state.showAutoRedirectLinks}
                        onChange={() => {
                            this.setState({
                                showAutoRedirectLinks: !this.state.showAutoRedirectLinks
                            });
                        }}
                        inputProps={{"aria-label": "Auto Redirect Links"}}
                    />}
                    label="Show redirect links"
                />
            </Col>
            Total Item: <strong>{itemsQuery.count()}</strong>
            <hr/>
            {itemsQuery.toModelArray().map(item => {
                return <LinkItem link={item} key={item.id} edit={item.edit}/>;
            })}
            {itemsQuery.count() === 0 && <div>No links to display</div>}
        </div>);
    }
}


/**
 *
 * @param state
 * @return {{entities: *}}
 */
const mapStateToProps = (state) => {

    const session = MasterGetter.getSession(state.entities);
    const searchString = state.app.search;
    const links = session.Link.all().filter(item => {

        let appSearchString = (searchString) ? searchString.toLowerCase() : "";
        let itemSearchString = item.name.toLowerCase();

        let foundInName = itemSearchString.includes(appSearchString);
        let foundInUrl = item.url ? item.url.toLowerCase().includes(appSearchString) : false;
        let foundInPage = item.page ? item.page.toLowerCase().includes(appSearchString) : false;

        return foundInName || foundInUrl || foundInPage;

    });


    return {
        links: links,
        searchTyping: state.app.searchTyping,
    };
};

/**
 * Exporting part of the React.Component file
 */
export default connect(mapStateToProps)(LinksLoader);