// @flow

// Necessary Evil
import React from "react";
import PropTypes from "prop-types";
import {withTranslation} from "react-i18next";
import Markdown from "react-markdown";
import remarkGfm from "remark-gfm";
// Import component CSS
import "./GenericItem.scss";


/**
 * @class GenericItem
 */
class GenericItem extends React.Component {

    /**
     * Prop Types
     * @type {{children: *}}
     */
    static propTypes = {
        children: PropTypes.any,
        onClick: PropTypes.func,
        item: PropTypes.any,
        nameProperty: PropTypes.string,
    };

	/**
	 * Default Props
	 * @type {{nameProperty: string}}
	 */
	static defaultProps = {
        nameProperty: "name",
    };

    /**
     * Final Render
     * @returns {*}
     */
    render() {

        const {
            item,
            onClick,
            nameProperty
        } = this.props;

        if(!item[nameProperty]){
            return null;
        }

        return <div className="generic-item" onClick={onClick}>
            {item.ordering}.&nbsp;<Markdown remarkPlugins={[remarkGfm]} children={item[nameProperty]}/>
        </div>;
    }
}

export default withTranslation()(GenericItem);
