// @flow
import React from "react";
import {withTranslation} from "react-i18next";
import {Col, Grid, Row} from "react-flexbox-grid";
import BackendRequest from "../../Models/REST";
import MasterGetter from "../../Models/Utils/MasterGetter";
import LoadingComponent from "../../Components/LoadingComponent";
import {FileSectionItem} from "../../Components/Files";
import SearchBar from "../../Components/SearchBar/SearchBar";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import AppContainer from "../../App/AppContainer";
import {Helmet} from "react-helmet";

/**
 * @class DownloadsScene
 */
class DownloadsScene extends React.Component {

	/**
	 * State
	 * @type {{expectingResponse: boolean}}
	 */
	state = {
		expectingResponse: true,
		archive: false,
	};

	/**
	 * Fetch data from REST API
	 */
	fetchData = () => {

		BackendRequest({
			endpoint: "downloads",
			self: this,
			afterSuccess: () => {
				AppContainer.hashLinkScroll();
			}
		});

	};

	/**
	 * component did mount
	 */
	componentDidMount() {
		this.fetchData();
	}

	/**
	 * Final Render
	 * @returns {*}
	 */
	render() {

		const {t, entities, app} = this.props;
		const session = MasterGetter.getSession(entities);
		const sections = session.DownloadSection.all().filter(item => (item.is_archived === false) && item.is_online).orderBy(["ordering"], ["asc"]);
		const files = session.File.all().filter(item => ((item.name.toLowerCase().includes(app.search.toLowerCase())) || (item.description.toLowerCase().includes(app.search.toLowerCase()))) && (item.is_archived === this.state.archive));

		return (
			<div className={"page"}>
				<Helmet>
					<title>{t("title.Downloads")} - IQRF</title>
				</Helmet>
				<Grid>
					<Row>
						<Col xs={12} lg={6}>
							<h1>{t("title.Downloads")}</h1>
						</Col>
						<Col xs={12} lg={6} style={{
							display: "flex",
							justifyContent: "flex-end",
							alignItems: "center",
						}}>
							<button onClick={() => this.setState({
								archive: !this.state.archive
							})} className={"btn"}>{this.state.archive ? "Back from Archive" : "Go to Archive"}
							</button>
						</Col>
					</Row>
					<Row>
						<Col xs={12}>
							<SearchBar placeholder={"Search in Downloads"}/>
							<br/>
						</Col>
					</Row>
					<Row>
						<Col xs={12}>
							{this.state.expectingResponse ? <LoadingComponent/> :
								sections.toModelArray().map(section => {
										const sectionItems = files.filter(item => item.section === section.id);
										return <FileSectionItem key={section.id} expanded={app.search.length > 0} section={section} items={sectionItems}/>;
									}
								)}
						</Col>

					</Row>
				</Grid>
				<br/>
				<Grid>
					<Row>
						<Col xs={12} className="page__section--bottom-padding">
							{/*<h3>{t("title.RecentlyChanged")}</h3>*/}
							{/*What do you want to be?*/}
						</Col>
					</Row>
				</Grid>
			</div>
		);
	}
}

const mapStateToProps = state => (
	{
		app: state.app,
		entities: state.entities,
	});

/**
 * Exporting part of the React.Component file
 */
export default withRouter(connect(mapStateToProps)(withTranslation()(DownloadsScene)));
