// @flow
import React from "react";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import {withRouter} from "react-router-dom";
import {Col, Grid, Row} from "react-flexbox-grid";
import {LoginForm} from "../../Forms";
import {Helmet} from "react-helmet";

/**
 * @class LoginScene
 */
class LoginScene extends React.Component {

    /**
     * Final Render
     * @returns {*}
     */
    render() {
        return (
            <Grid className={"page"}>
                <Helmet>
                    <title>Login - IQRF</title>
                </Helmet>
                <Row>
                    <Col xs={12} className="page__section--bottom-padding">
                        <h1>Login</h1>
                        <LoginForm/>
                    </Col>
                </Row>
            </Grid>
        );
    }
}

/**
 * This function maps the state to a
 * prop called `state`.
 *
 * In larger apps it is often good
 * to be more selective and only
 * map the part of the state tree
 * that is necessary.
 */
const mapStateToProps = state => (
    {
        app: state.app,
    });

/**
 * Exporting part of the React.Component file
 */
export default withRouter(connect(mapStateToProps)(withTranslation()(LoginScene)));