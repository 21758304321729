// @flow
import React from "react";
import {withTranslation} from "react-i18next";
import {Col, Grid, Row} from "react-flexbox-grid";
import BackendRequest from "../../Models/REST";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import {LoadingComponent} from "../../Components";
import InstantAction from "../../Models/Utils/InstantAction";
import {REMOVE_PRODUCT_SERIES} from "../../Models/Models/ProductSeries";
import "../../Components/Links/Links.scss";
import {UPDATE_LINK} from "../../Models/Models/Link";
import SearchBar from "../../Components/SearchBar/SearchBar";
import LinksLoader from "../../Components/Editor/LinksLoader";

/**
 * @class LinkListScene
 */
class LinkListScene extends React.Component {

    /**
     * State
     * @type {{expectingResponse: boolean}}
     */
    state = {
        expectingResponse: true,
        showAutoRedirectLinks: false,
        showNonRedirectLinks: true,
    };

    /**
     * Fetch app data if necessary
     */
    fetchData = () => {
        BackendRequest({
            endpoint: "links",
            self: this,
        });
    };

    /**
     * Component did mount
     */
    componentDidMount() {
        this.fetchData();
    }

    /**
     *
     */
    delete = () => {

        const {
            match
        } = this.props;

        const productId = match.params.id;


        BackendRequest({
            endpoint: "product/" + productId + "/delete",
            method: "delete",
            self: this,
            afterSuccess: (response) => {
                InstantAction.dispatch({
                    type: REMOVE_PRODUCT_SERIES,
                    payload: productId,
                });
                InstantAction.redirect("/editor/products");
            }
        });
    };

    /**
     *
     */
    createNew = () => {

        BackendRequest({
            endpoint: "link",
            method: "post",
            payload: {
                product: null,
            },
            afterSuccess: (response) => {

                console.log(response.data);

                InstantAction.dispatch({
                    type: UPDATE_LINK,
                    payload: {
                        ...response.data.link,
                        edit: true,
                    }
                });

            }
        });

    };

    /**
     *
     */
    clone = () => {
        const {
            match,
        } = this.props;

        const productId = match.params.id;

        BackendRequest({
            endpoint: "product/" + productId + "/copy",
            method: "post",
            self: this,
            afterSuccess: (response) => {
                InstantAction.redirect("/editor/product/item/" + response.data.product.id);
                this.forceUpdate();
            }
        });
    };


    /**
     * Final Render
     * @returns {*}
     */
    render() {

        const {
            searchTyping
        } = this.props;

        return (
            <Grid fluid>
                <Row>
                    <Col xs={12}>
                        <h1>Links</h1>
                        <div>
                            <button onClick={this.createNew} className="btn">Create New</button>
                        </div>
                        <br/><br/>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12}>
                        <SearchBar placeholder={"Search in links.."} />
                    </Col>
                </Row>
                <Row>
                    <Col xs={12}>
                        {(this.state.expectingResponse || searchTyping) ?
                            <LoadingComponent/> : <LinksLoader />
                        }
                    </Col>
                </Row>
            </Grid>
        );
    }

}

/**
 *
 * @param state
 * @return {{entities: *}}
 */
const mapStateToProps = (state) => {

    return {
        searchTyping: state.app.searchTyping,
    };
};

/**
 * Exporting part of the React.Component file
 */
export default withRouter(connect(mapStateToProps)(withTranslation()(LinkListScene)));