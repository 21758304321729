// @flow

// Necessary Evil
import React from "react";
import PropTypes from "prop-types";
import {withTranslation} from "react-i18next";
import BackendRequest from "../Models/REST/BackendRequest";
import ErrorMessage from "../Components/Form/ErrorMessage/ErrorMessage";
import FormGroup from "../Components/Form/FormGroup";
import LoadingComponent from "../Components/LoadingComponent";
import MasterGetter from "../Models/Utils/MasterGetter";
import MyDropZone from "../Components/MyDropzone";
import Markdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import rehypeSanitize from "rehype-sanitize";
import remarkGfm from "remark-gfm";
import SwitchButton from "../Components/Form/SwitchButton/SwitchButton";
import {Col, Row} from "react-flexbox-grid";
import {connect} from "react-redux";
import {Required} from "../Components";
import {Tag} from "../Components/Tag";
import {ProductPriceForm} from "./index";
import ProductItemAdmin from "../Components/Products/ProductItemAdmin";

/**
 * @class ProductVariantForm
 *
 */
class ProductVariantForm extends React.Component {

    /**
     * Variant
     * @type {{variant: shim, afterSuccess: shim}}
     */
    static propTypes = {
        variant: PropTypes.any,
        afterSuccess: PropTypes.func,
    };

    /**
     * Default Props
     * @type {{afterSuccess: ProductVariantForm.defaultProps.afterSuccess}}
     */
    static defaultProps = {
        afterSuccess: () => {
            // InstantAction.redirect("/editor/product-variants");
        },
    };

    /**
     * State
     * @type {{formData: {}, expectingResponse: boolean, errorMessage: null}}
     */
    state = {
        expectingResponse: false,
        errorMessage: null,
        // Properties
        id: null,
        name: "",
        series: 0,
        description: "",
        description_2: "",
        price_list_description: "",
        is_archived: false,
        is_online: false,
        in_price_list: true,
        is_available: true,
        lead_time: 35,
        packing: 1,
        ordering: 0,
        bands: [],
        status: 0,
        status_2: 0,
        // photos: [],
        image: 0,
        eshop_url: "",
    };

    /**
     *
     * @param props
     * @param state
     */
    static getDerivedStateFromProps(props, state) {

        if (props.variant !== null) {
            if (props.variant.id !== state.id) {

                // console.log("Setting derivated state", props.variant.bands.count(), props.variant.bands.toRefArray());

                // let photosIds = [];
                let bandsIds = [];

                // props.variant.photos.toRefArray().forEach(item => {
                //     photosIds.push(item.id);
                // });

                //console.log("Bands:", props.variant.bands.toRefArray());
                props.variant.bands.toModelArray().forEach(item => {
                    bandsIds.push(item.id);
                });

                return {
                    ...props.variant._fields,
                    // photos: photosIds,
                    bands: bandsIds,
                };
            }
        }

        return null;
    }

    /**
     * Update Photos
     * @param photoId
     */
    updatePhotos = (photoId) => {
        let updateMainPhoto = {};

        // If empty set main photo
        if (this.state.image === null || this.state.image === 0) {
            updateMainPhoto = {
                image: photoId,
            };
        }

        // Update current photo sets
        let currentPhotos = this.state.photos;
        currentPhotos.push(photoId);

        this.setState({
            // photos: currentPhotos,
            ...updateMainPhoto,
        });
    };

    /**
     * On Drop Action
     * @param acceptedFiles
     */
    onDrop = (acceptedFiles) => {

        acceptedFiles.forEach(file => {

            let filenameSpliced = file.name.split(".");
            let fileType = filenameSpliced[filenameSpliced.length - 1];

            /**
             * Payload
             * @type {{file: *, filename: *, section: number, type: (*|string)}}
             */
            const data = {
                file: file,
                section: 1,
                filename: file.name,
                type: fileType,
                folder: this.state.id,
            };

            BackendRequest({
                method: "post",
                endpoint: "photo",
                payload: data,
                self: this,
                afterSuccess: (response) => {

                    const data = response.data;
                    this.updatePhotos(data.photo.id);
                }
            });
        });

    };

    /**
     * Handle Input Changes
     * @param event
     */
    handleInputChange = (event) => {

        const target = event.target;
        const value = target.type === "checkbox" ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });

    };

    /**
     * Handle Submit
     * @param e
     */
    handleSubmit = (e) => {

        e.preventDefault();

        /**
         * Payload
         * @type {{image: null, name: string, title: string, content: string}}
         */
        const payload = {
            ...this.state,
            lead_time: typeof this.state.lead_time !== "number" ? 35 : this.state.lead_time,
            bands: JSON.stringify(this.state.bands),
            image: this.state.image,
        };

        let method = this.state.id === null ? "post" : "put";
        let endpoint = this.state.id === null ? "product-variant" : "product-variant/" + this.state.id;

        BackendRequest({
            endpoint: endpoint,
            method: method,
            payload: payload,
            self: this,
            afterSuccess: this.props.afterSuccess
        });
    };

    /**
     * On RadioBand Click
     * @param id
     */
    onBandClick = (id) => {

        if (this.state.bands.includes(id)) {
            // Remove from array
            let removeIndex = this.state.bands.indexOf(id);
            let newBands = this.state.bands;

            newBands.splice(removeIndex, 1);

            this.setState({
                bands: newBands,
            });

        } else {
            // Add to array
            let bands = this.state.bands;
            bands.push(id);
            this.setState({
                bands: bands,
            });
        }

    };

    /**
     * Handle Switch
     * @param key
     */
    handleSwitch = (key) => {

        this.setState({
            [key]: !this.state[key]
        });

    };

    /**
     * Delete photo
     * @param event
     */
    deletePhoto = (event) => {

        event.preventDefault();

        BackendRequest({
            endpoint: "photo/" + this.state.image,
            method: "delete",
            self: this,
            afterSuccess: () => {
                this.setState({
                    image: null,
                });
            }
        });

    };

    /**
     * Component will mount
     */
    componentDidMount() {

        const {variant} = this.props;

        if (variant !== null) {

            let properties = variant._fields;
            let photosIds = [];
            let bandsIds = [];

            variant.photos.toRefArray().forEach(item => {
                photosIds.push(item.id);
            });

            //console.log("Bands:", variant.bands.toRefArray());
            variant.bands.toRefArray().forEach(item => {
                bandsIds.push(item.id);
            });

            this.setState({
                ...properties,
                category: properties.category === null ? 0 : properties.category,
                subcategory: properties.section === null ? 0 : properties.subcategory,
                section: properties.section === null ? 0 : properties.section,
                photos: photosIds,
                bands: bandsIds,
            });
        }
        //
        // this.fetchEssentials();
    }

    /**
     * Final Render
     * @returns {*}
     */
    render() {

        /**
         * @info Translation function, className
         */
        const {
            t,
            entities,
        } = this.props;

        const {
            expectingResponse
        } = this.state;

        /**
         * @info Error variable
         */
        let error = "";

        /**
         * @info Handling Error Message
         */
        if (this.state.errorMessage !== null) {
            error = <ErrorMessage>{t("formError." + this.state.errorMessage)}</ErrorMessage>;
        }

        const session = MasterGetter.getSession(entities);
        const series = session.ProductSeries.all().orderBy("name");
        const statuses = session.ProductStatus.all().orderBy("name");
        const statuses2 = session.ProductStatus2.all().orderBy("name");
        const radioBands = session.RadioBand.all().orderBy("name");
        const prices = session.ProductPrice.all().filter(item => item.product === this.state.id).orderBy("valid_from");
        const image = session.Photo.withId(this.state.image);

        let selectedSeries = null;

        if (this.state.series !== 0) {
            selectedSeries = session.ProductSeries.withId(this.state.series);
        }

        /**
         * Final Output
         * @type {{}}
         */
        return <form onSubmit={this.handleSubmit} className="form page__section--bottom-padding">
            {error}
            <h2>Select Product / Series: </h2>
            <FormGroup>
                <label htmlFor="series">{t("label.Series")} <Required/></label>
                <select onChange={this.handleInputChange}
                        value={this.state.series}
                        name="series"
                        id="series"
                >
                    <option value={0}>[Empty]</option>
                    {series.toModelArray().map(item => {
                        return <option key={item.id} value={item.id}>{item.name}</option>;
                    })}
                </select>
            </FormGroup>
            <Row>
                <Col xs={12}>
                    <h4>Series / Product preview</h4>
                    {selectedSeries !== null &&
                    <ProductItemAdmin product={selectedSeries}/>
                    }
                </Col>
            </Row>
            <hr/>
            <h2>
                Product variant data
            </h2>
            <FormGroup>
                <label htmlFor="name">{t("label.Name")}</label>
                <input onChange={this.handleInputChange}
                       value={this.state.name}
                       type="text"
                       tabIndex={2}
                       name="name" id="name"
                       placeholder={t("label.Name")}
                />
            </FormGroup>
            <Row>
                <Col xs={12} lg={6}>
                    <Row>
                        <Col xs={12} lg={6}>
                            <FormGroup>
                                <label htmlFor="status">{t("label.Status")} <Required/></label>
                                <select onChange={this.handleInputChange}
                                        value={this.state.status}
                                        name="status"
                                        id="status"
                                >
                                    <option value={0}>[Empty]</option>
                                    {statuses.toModelArray().map(item => {
                                        return <option key={item.id} value={item.id}>{item.name}</option>;
                                    })}
                                </select>
                            </FormGroup>
                        </Col>
                        <Col xs={12} lg={6}>
                            <FormGroup>
                                <label htmlFor="status_2">{t("label.Status")} 2</label>
                                <select onChange={this.handleInputChange}
                                        value={this.state.status_2}
                                        name="status_2"
                                        id="status_2"
                                >
                                    <option value={0}>[Empty]</option>
                                    {statuses2.toModelArray().map(item => {
                                        return <option key={item.id} value={item.id}>{item.name}</option>;
                                    })}
                                </select>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} lg={6}>
                            <FormGroup>
                                <label htmlFor="lead_time">{t("label.LeadTime")}</label>
                                <input onChange={this.handleInputChange}
                                       value={this.state.lead_time}
                                       type="number"
                                       name="lead_time"
                                       id="lead_time"
                                />
                            </FormGroup>
                        </Col>
                        <Col xs={12} lg={6}>
                            <FormGroup>
                                <label htmlFor="packing">{t("label.Packing")}</label>
                                <input onChange={this.handleInputChange}
                                       value={this.state.packing}
                                       type="number"
                                       name="packing"
                                       id="packing"
                                />
                            </FormGroup>
                        </Col>
                        <Col xs={12} lg={6}>
                            <FormGroup>
                                <label htmlFor="ordering">Ordering</label>
                                <input onChange={this.handleInputChange}
                                       value={this.state.ordering}
                                       type="number"
                                       name="ordering"
                                       id="ordering"
                                />
                            </FormGroup>
                        </Col>
                        <Col xs={12} lg={6}>
                            <FormGroup>
                                <label htmlFor="eshop_url">E-shop URL</label>
                                <input onChange={this.handleInputChange}
                                       value={this.state.eshop_url}
                                       name="eshop_url"
                                       id="eshop_url"
                                />
                            </FormGroup>
                        </Col>
                    </Row>

                    <FormGroup>
                        <label>{t("label.RadioBands")}</label>
                        <br/>
                        {radioBands.toModelArray().map(item => {

                            return <Tag key={item.id} handleClick={this.onBandClick}
                                        className={(this.state.bands.includes(item.id) ? "selected" : "")}
                                        itemId={item.id}>{item.name} MHz</Tag>;
                        })}
                    </FormGroup>
                </Col>
                <Col xs={12} lg={6}>
                    <Row>
                        <Col xs={12}>
                            <label>{t("label.MainPhoto")}</label>
                            <br/>
                            {this.state.image !== 0 && image !== null ? <div>
                                <img className="image--preview" src={process.env.REACT_APP_BACKEND_SERVER + image.url}
                                     alt={"Preview"}/>
                                <button onClick={this.deletePhoto}><i className="icon-delete"/> Delete Picture</button>
                            </div> : <div>[NO IMAGE]</div>}
                            <br/>
                        </Col>
                        {/*<Col xs={12}>*/}
                        {/*    <label>{t("label.PhotoGallery")}</label>*/}
                        {/*    <br/>*/}
                        {/*    <div className="thumbnail-photo-admin__wrapper">*/}
                        {/*        {this.state.photos.map(photoId => {*/}
                        {/*            let photo = session.Photo.withId(photoId);*/}
                        {/*            return <ThumbnailPhotoAdmin photo={photo} parent={this}/>;*/}
                        {/*        })}*/}
                        {/*        {this.state.photos.length === 0 ? "[NO IMAGES IN GALLERY]" : ""}*/}
                        {/*    </div>*/}
                        {/*</Col>*/}
                        <Col xs={12}>
                            <label>Photo upload</label>
                            <br/>
                            <MyDropZone onDrop={this.onDrop} accept={"image/*"}/>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12}>
                            <hr/>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} lg={3} className={"text--center"}>
                            <FormGroup>
                                <label>{t("label.IsOnline")}?</label>
                                <br/>
                                <SwitchButton checked={this.state.is_online} handleClick={this.handleSwitch}
                                              name={"is_online"}/>
                            </FormGroup>
                        </Col>
                        <Col xs={12} lg={3} className={"text--center"}>
                            <FormGroup>
                                <label>{t("label.IsArchived")}?</label>
                                <br/>
                                <SwitchButton checked={this.state.is_archived} handleClick={this.handleSwitch}
                                              name={"is_archived"}/>
                            </FormGroup>
                        </Col>
                        <Col xs={12} lg={3} className={"text--center"}>
                            <FormGroup>
                                <label>{t("label.IsInPricelist")}?</label>
                                <br/>
                                <SwitchButton checked={this.state.in_price_list} handleClick={this.handleSwitch}
                                              name={"in_price_list"}/>
                            </FormGroup>
                        </Col>
                        <Col xs={12} lg={3} className={"text--center"}>
                            <FormGroup>
                                <label>{t("label.IsAvailable")}?</label>
                                <br/>
                                <SwitchButton checked={this.state.is_available} handleClick={this.handleSwitch}
                                              name={"is_available"}/>
                            </FormGroup>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row>
                <Col xs={12}>
                    <hr/>
                </Col>
                <Col xs={12}>
                    <h2>Product descriptions</h2>
                </Col>
            </Row>
            <Row>
                <Col xs={12} lg={6}>
                    <FormGroup>
                        <label htmlFor="description">{t("label.Description")}</label>
                        <textarea
                            onChange={this.handleInputChange}
                            value={this.state.description}
                            name="description"
                            id="description"
                        />
                        <em>(Use markdown - <a target="_blank"
                                               href="https://github.com/adam-p/markdown-here/wiki/Markdown-Cheatsheet"
                                               rel="noopener noreferrer">https://github.com/adam-p/markdown-here/wiki/Markdown-Cheatsheet</a>)</em>
                    </FormGroup>
                </Col>
                <Col xs={12} lg={6}>
                    <FormGroup>
                        <label>{t("label.ContentPreview")}</label>
                        <Markdown
                          rehypePlugins={[rehypeRaw, rehypeSanitize]}
                          remarkPlugins={[remarkGfm]}
                          children={this.state.description}
                        />
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col xs={12} lg={6}>
                    <FormGroup>
                        <label htmlFor="description_2">Description 2</label>
                        <textarea
                            onChange={this.handleInputChange}
                            value={this.state.description_2}
                            name="description_2"
                            id="description_2"
                        />
                        <em>(Use markdown - <a target="_blank"
                                               href="https://github.com/adam-p/markdown-here/wiki/Markdown-Cheatsheet"
                                               rel="noopener noreferrer">https://github.com/adam-p/markdown-here/wiki/Markdown-Cheatsheet</a>)</em>
                    </FormGroup>
                </Col>
                <Col xs={12} lg={6}>
                    <FormGroup>
                        <label>{t("label.ContentPreview")}</label>
                        <Markdown
                          rehypePlugins={[rehypeRaw, rehypeSanitize]}
                          remarkPlugins={[remarkGfm]}
                          children={this.state.description_2}
                        />
                    </FormGroup>
                </Col>
            </Row>
            <FormGroup>
                <label htmlFor="price_list_description">{t("label.PricelistDescription")}</label>
                <textarea
                    onChange={this.handleInputChange}
                    value={this.state.price_list_description}
                    name="price_list_description"
                    id="price_list_description"
                />
            </FormGroup>
            <Row>
                <Col xs={12}>
                    <hr/>
                </Col>
            </Row>
            <FormGroup>
                <h2>{t("label.ProductPrices")} ({prices.count()})</h2>
                <Row>
                    <Col xs={12}>
                        <ProductPriceForm product={this.state} price={null}/>
                        {prices.toModelArray().map(item => {
                            return <ProductPriceForm variant={this.state} price={item}/>;
                        })}
                    </Col>
                </Row>
            </FormGroup>
            <hr/>
            <FormGroup className={"text--center"}>
                {(expectingResponse) ? <LoadingComponent/> :
                    <button type="submit" tabIndex={2}
                            className="btn btn--animated">{this.state.id === null ? t("button.Submit") : t("button.Update")}</button>
                }
            </FormGroup>
        </form>;
    }
}

/**
 * State to Props
 * @param state
 * @return {{entities: {PhotoSection: (*|PhotoSection)}}}
 */
const mapStateToProps = state => (
    {
        entities: state.entities,
    });

export default (connect(mapStateToProps)(withTranslation()(ProductVariantForm)));
