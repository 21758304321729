// @flow
import React from "react";
import {withTranslation} from "react-i18next";
import {Col, Grid, Row} from "react-flexbox-grid";
import BackendRequest from "../../Models/REST";
import MasterGetter from "../../Models/Utils/MasterGetter";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import {ProductVariantForm} from "../../Forms";
import {LoadingComponent} from "../../Components";
import InstantAction from "../../Models/Utils/InstantAction";
import {REMOVE_PRODUCT} from "../../Models/Models/Product";
import DeleteModal from "../../Components/DeleteModal";

/**
 * @class ProductScene
 */
class ProductScene extends React.Component {

    /**
     * State
     * @type {{expectingResponse: boolean}}
     */
    state = {
        expectingResponse: true,
        modalIsOpen: false,
    };

    /**
     * Fetch app data if necessary
     */
    fetchData = () => {

        const {match} = this.props;

        if (match.params.id === undefined) {
            BackendRequest({
                endpoint: "product-variant",
                method: "post",
                self: this,
                afterSuccess: (response) => {
                    InstantAction.redirect("/editor/product-variant/" + response.data.product.id);
                }
            });
        } else {

        	BackendRequest({
				self: this,
				endpoint: "product-variant/"+match.params.id,
			});
        }

    };

    /**
     * Component did mount
     */
    componentDidMount() {


        BackendRequest({
            endpoint: "navigation-data",
        });


        this.fetchData();

    }


    /**
     *
     */
    delete = () => {

        const {
            match
        } = this.props;

        const productId = match.params.id;

        BackendRequest({
            endpoint: "product-variant/" + productId + "/delete",
            method: "delete",
            self: this,
            afterSuccess: (response) => {
                InstantAction.dispatch({
                    type: REMOVE_PRODUCT,
                    payload: productId,
                });
                InstantAction.redirect("/editor/product-variants");
            }
        });
    };

    /**
     *
     */
    clone = () => {
        const {
            match
        } = this.props;

        const productId = match.params.id;

        BackendRequest({
            endpoint: "product-variant/" + productId + "/copy",
            method: "post",
            self: this,
            afterSuccess: (response) => {
                InstantAction.redirect("/editor/product-variant/" + response.data.product.id);
                this.forceUpdate();
            }
        });
    };


    /**
     * Final Render
     * @returns {*}
     */
    render() {

        const {
            entities,
            match,
        } = this.props;

        const productId = match.params.id;
        const session = MasterGetter.getSession(entities);
        const variant = session.Product.withId(productId);
        const isNew = variant === null;

        return (
            <Grid fluid>
                <Row>
                    <Col xs={12} lg={6}>
                        <h1>Product variant: {isNew ? "[new]" : variant.name}</h1>
                    </Col>
                    <Col xs={12} lg={6} className={"text--right"}>
                        <br/>
                        <div>
                            <button className="btn" onClick={() => window.history.back()}>Cancel <i
                                className="icon-back-arrow"/></button>
                            <button className="btn" onClick={this.clone}>Clone <i className="icon-copy"/></button>
                            <button className="btn" onClick={() => {
                                this.setState({
                                    modalIsOpen: true,
                                });
                            }}>Delete <i className="icon-delete"/></button>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12}>
                        {this.state.expectingResponse ?
                            <LoadingComponent/> :
                            <ProductVariantForm variant={variant}/>
                        }
                    </Col>
                </Row>
                <DeleteModal
                    isOpen={this.state.modalIsOpen}
                    handleDelete={this.delete}
                    parent={this}
                />
            </Grid>
        );
    }

}

/**
 *
 * @param state
 * @return {{entities: *}}
 */
const mapStateToProps = state => (
    {
        entities: state.entities,
    });

/**
 * Exporting part of the React.Component file
 */
export default withRouter(connect(mapStateToProps)(withTranslation()(ProductScene)));