// @flow

import PropTypes from "prop-types";
import {attr, fk} from "redux-orm";

import CRUDModel from "../ORM/CRUDModel";


export default class Link extends CRUDModel {

}

Link.modelName = "Link";

Link.fields = {
	id: attr(), // non-relational field for any value; optional but highly recommended
	auto_redirect: attr(),
	description: attr(),
	file: fk("File"),
	icon: attr(),
	is_external: attr(),
	is_modal: attr(),
	ordering: attr(),
	target: attr(),
	url: attr(),
};

Link.propTypes = {
	name: PropTypes.string,
};

export const ADD_LINK = "ADD_LINK";
export const REMOVE_LINK = "REMOVE_LINK";
export const UPDATE_LINK = "UPDATE_LINK";