// @flow

import PropTypes from "prop-types";
import {attr} from "redux-orm";

import CRUDModel from "../ORM/CRUDModel";


export default class Patent extends CRUDModel {
    // /**
    //  * @param {{id: number, name: string, color: string, voltage: number, capacity: number}} data
    //  * @returns {Object}
    //  */
    // static apiToPayload(data: Object)
    // {
    // 	return {
    // 		id: data.id,
    // 		name: data.name,
    // 		color: data.color,
    // 		voltage: Number(data.voltage),
    // 		capacity: Number(data.capacity) || null,
    // 	};
    // }


    // /**
    //  * @returns {{id: number, name: string, color: string, voltage: string, capacity: string}}
    //  */
    // toApi()
    // {
    // 	return {
    // 		id: this.id,
    // 		name: this.name,
    // 		color: this.color,
    // 		voltage: String(this.voltage),
    // 		capacity: String(this.capacity),
    // 	}
    // }
}

Patent.modelName = "Patent";

Patent.fields = {
    id: attr(), // non-relational field for any value; optional but highly recommended
};

Patent.propTypes = {
    name: PropTypes.string.isRequired,
};

// Patent.defaultProps = {
// };
//
// Patent.endpoint = "/group";

export const ADD_PATENT = "ADD_PATENT";
export const REMOVE_PATENT = "REMOVE_PATENT";
export const UPDATE_PATENT = "UPDATE_PATENT";