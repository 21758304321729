// @flow

// Necessary Evil
import React from "react";
import PropTypes from "prop-types";
import {withTranslation} from "react-i18next";
import LoadingComponent from "../Components/LoadingComponent";
import BackendRequest from "../Models/REST/BackendRequest";
import FormGroup from "../Components/Form/FormGroup";
import {connect} from "react-redux";
import ErrorMessage from "../Components/Form/ErrorMessage/ErrorMessage";
import Markdown from "react-markdown";
import remarkGfm from "remark-gfm";
import MasterGetter from "../Models/Utils/MasterGetter";
import {Col, Row} from "react-flexbox-grid";
import MyDropZone from "../Components/MyDropzone";

/**
 * @class DistributorForm
 */
class DistributorForm extends React.Component {

	/**
	 * PropTypes
	 * @type {{children: shim}}
	 */
	static propTypes = {
		item: PropTypes.any,
		afterSuccess: PropTypes.func,
	};

	/**
	 * Default Props
	 * @type {{afterSuccess: DistributorForm.defaultProps.afterSuccess}}
	 */
	static defaultProps = {
		afterSuccess: () => {
			// InstantAction.redirect("/editor/startup-package-history");
		},
	};

	/**
	 * State
	 * @type {{formData: {}, expectingResponse: boolean, errorMessage: null}}
	 */
	state = {
		expectingResponse: false,
		errorMessage: null,
		// Properties
		city: "",
		contact_person: "",
		country: 0,
		email: "",
		id: 0,
		logo: 0,
		mobile: "",
		name: "",
		office: "",
		phone: "",
		postcode: "",
		street: "",
		website: "",
		additional_info: "",
	};

	/**
	 * Get derived state from props
	 * @param props
	 * @param state
	 */
	static getDerivedStateFromProps (props, state) {

		if (props.item !== null) {
			if (props.item.id !== state.id) {

				return {
					...props.item._fields,
					id: props.item.id,

				};
			}
		}
		return null;
	}


	/**
	 * On Drop Action
	 * @param acceptedFiles
	 */
	onDrop = (acceptedFiles) => {

		acceptedFiles.forEach(file => {

			let filenameSpliced = file.name.split(".");
			let fileType = filenameSpliced[filenameSpliced.length - 1];

			/**
			 * Payload
			 * @type {{file: *, filename: *, section: number, type: (*|string)}}
			 */
			const data = {
				file: file,
				section: 5,
				filename: file.name,
				type: fileType,
				distributor: this.state.id,
			};

			BackendRequest({
				method: "post",
				endpoint: "distributor/" + this.state.id + "/photo",
				payload: data,
				self: this,
				afterSuccess: (response) => {
					this.setState({
							logo: response.data.photo.id
					});
				}
			});
		});
	};


	/**
	 * Handle Input Changes
	 * @param event
	 */
	handleInputChange = (event) => {

		const target = event.target;
		const value = target.type === "checkbox" ? target.checked : target.value;
		const name = target.name;

		this.setState({
			[name]: value
		});

	};

	/**
	 * Handle Switch
	 * @param key
	 */
	handleSwitch = (key) => {

		this.setState({
			[key]: !this.state[key]
		});

	};

	/**
	 * Handle Submit
	 * @param e
	 */
	handleSubmit = (e) => {

		e.preventDefault();

		BackendRequest({
			afterSuccess: this.props.afterSuccess,
			endpoint: "distributor/" + this.state.id,
			method: "put",
			payload: {
				...this.state,
				id: undefined,
				logo: (this.state.logo === 0 || !this.state.logo) ? undefined : this.state.logo,
			},
			self: this,
		});
	};

	/**
	 * Final Render
	 * @returns {*}
	 */
	render () {

		/**
		 * @info Error variable
		 */
		let error = "";

		/**
		 * @info Handling Error Message
		 */
		if (this.state.errorMessage !== null) {
			error = <ErrorMessage>{this.state.errorMessage}</ErrorMessage>;
		}

		const session = MasterGetter.getSession();
		const countries = session.Country.all().orderBy(["name"], ["asc"]);
		const image = session.Photo.withId(this.state.logo);


		/**
		 * Final Output
		 * @type {{}}
		 */
		return (<form
			onSubmit={this.handleSubmit}
			className={"form"}
		>
			{error}
			<FormGroup>
				<label htmlFor="name">Name</label>
				<input onChange={this.handleInputChange}
					   value={this.state.name}
					   type="text"
					   tabIndex={1}
					   name="name"
					   id="name"
				/>
				<h2>{<Markdown remarkPlugins={[remarkGfm]} children={this.state.name}/>}</h2>
			</FormGroup>
			<FormGroup>
				<label htmlFor="phone">Phone</label>
				<input onChange={this.handleInputChange}
					   value={this.state.phone}
					   type="text"
					   name="phone"
					   id="phone"
				/>
				<h2>{<Markdown remarkPlugins={[remarkGfm]} children={this.state.phone}/>}</h2>
			</FormGroup>
			<FormGroup>
				<label htmlFor="mobile">Mobile</label>
				<input onChange={this.handleInputChange}
					   value={this.state.mobile}
					   type="text"
					   name="mobile"
					   id="mobile"
				/>
				<h2>{<Markdown remarkPlugins={[remarkGfm]} children={this.state.mobile}/>}</h2>
			</FormGroup>
			<FormGroup>
				<label htmlFor="email">Email</label>
				<input onChange={this.handleInputChange}
					   value={this.state.email}
					   type="email"
					   name="email"
					   id="email"
				/>
				<h2>{<Markdown remarkPlugins={[remarkGfm]} children={this.state.email}/>}</h2>
			</FormGroup>
			<FormGroup>
				<label htmlFor="website">Website</label>
				<input onChange={this.handleInputChange}
					   value={this.state.website}
					   type="text"
					   name="website"
					   id="website"
				/>
				<h2>{<Markdown remarkPlugins={[remarkGfm]} children={this.state.website}/>}</h2>
			</FormGroup>
			<FormGroup>
				<label htmlFor="country">Country</label>
				<select onChange={this.handleInputChange}
						value={this.state.country}
						name="country"
						id="country"
				>
					<option value={0} disabled={true}>Select country</option>
					{countries.toRefArray().map(country => {
						return <option key={country.id} value={country.id}>{country.name}</option>;
					})}
				</select>
			</FormGroup>
			<FormGroup>
				<label htmlFor="street">Street</label>
				<input onChange={this.handleInputChange}
					   value={this.state.street}
					   type="text"
					   name="street"
					   id="street"
				/>
				<h2>{<Markdown remarkPlugins={[remarkGfm]} children={this.state.street}/>}</h2>
			</FormGroup>
			<FormGroup>
				<label htmlFor="city">City</label>
				<input onChange={this.handleInputChange}
					   value={this.state.city}
					   type="text"
					   name="city"
					   id="city"
				/>
				<h2>{<Markdown remarkPlugins={[remarkGfm]} children={this.state.city}/>}</h2>
			</FormGroup>
			<FormGroup>
				<label htmlFor="postcode">Postcode</label>
				<input onChange={this.handleInputChange}
					   value={this.state.postcode}
					   type="text"
					   name="postcode"
					   id="postcode"
				/>
				<h2>{<Markdown remarkPlugins={[remarkGfm]} children={this.state.postcode}/>}</h2>
			</FormGroup>
			<FormGroup>
				<label htmlFor="office">Office</label>
				<input onChange={this.handleInputChange}
					   value={this.state.office}
					   type="text"
					   name="office"
					   id="office"
				/>
				<h2>{<Markdown remarkPlugins={[remarkGfm]} children={this.state.office}/>}</h2>
			</FormGroup>
			<FormGroup>
				<label htmlFor="contact_person">Contact Person</label>
				<input onChange={this.handleInputChange}
					   value={this.state.contact_person}
					   type="text"
					   name="contact_person"
					   id="contact_person"
				/>
				<h2>{<Markdown remarkPlugins={[remarkGfm]} children={this.state.contact_person}/>}</h2>
			</FormGroup>
			<FormGroup>

				<label htmlFor="additional_info">Additional Info</label>
				<Row>
					<Col xs={12} lg={6}>
						<textarea onChange={this.handleInputChange}
								  value={this.state.additional_info}
								  name="additional_info"
								  id="additional_info"
						/>
					</Col>
					<Col xs={12} lg={6}>
						<div>{<Markdown remarkPlugins={[remarkGfm]} children={this.state.additional_info}/>}</div>
					</Col>
				</Row>


			</FormGroup>
			<FormGroup>
				<label htmlFor="logo">Logo</label>
				<Row>
					<Col xs={12}>
						{this.state.logo !== 0 && image !== null ? <div>
							<img
								className="image--preview"
								src={process.env.REACT_APP_BACKEND_SERVER + image.url}
								alt={"Preview"}
							/>
						</div> : <div>[NO IMAGE]</div>}
						<br/>
					</Col>
					<Col xs={12}>
						<label>Photo upload</label>
						<br/>
						<MyDropZone onDrop={this.onDrop} accept={"image/*"}/>
					</Col>
				</Row>
			</FormGroup>
			<hr/>
			<FormGroup className={"text--center"}>
				{(this.state.expectingResponse) ? <LoadingComponent/> :
					<button
						className="btn btn--animated"
						tabIndex={2}
						type="submit"
					>
						Save
					</button>
				}
			</FormGroup>
		</form>);
	}
}

/**
 * Map State to Props
 * @param state
 * @return {{entities: *}}
 */
const mapStateToProps = state => (
	{
		entities: state.entities,
	});

export default (connect(mapStateToProps)(withTranslation()(DistributorForm)));
