// @flow

// Necessary Evil
import React from "react";
import PropTypes from "prop-types";
import {withTranslation} from "react-i18next";
import {Col, Row} from "react-flexbox-grid";
import {Country} from "../../../Models/Models";
// Import component CSS
import "./DistributorCountryItem.scss";
import Markdown from "react-markdown";
import remarkGfm from "remark-gfm";

/**
 * @class DistributorCountryItem
 */
class DistributorCountryItem extends React.Component {

	/**
	 * PropTypes
	 * @type {{children: shim}}
	 */
	static propTypes = {
		country: PropTypes.objectOf(Country),
		distributors: PropTypes.array,
	};

	state = {
		show: false,
	};


	/**
	 *
	 */
	handleClick = () => {
		const {show} = this.state;

		this.setState({
			show: !show
		});
	};

	/**
	 * Final Render
	 * @returns {*}
	 */
	render() {

		const {country, distributors} = this.props;

		const showClass = !this.state.show ? "hidden" : "";

		return <div className={"distributor__country"}>
			<div className="distributor__clickable" title={"Click for more"} onClick={this.handleClick}>
				<img className={"distributor__flag"} src={process.env.REACT_APP_BACKEND_SERVER + "static/media/img/country/" + country.iso_code.toLowerCase() + ".png"} alt={country.name + " (" + country.iso_code + ") flag"}/>
				<strong> {country.name} ({distributors.count()}) </strong>
			</div>

			<div className={"distributor__list " + showClass}>
				<Row>
					{
						distributors.toModelArray().map(distributor => {
							return <Col xs={12} lg={6}>
								<div className={"distributor__item"}>

									<Row>
										<Col xs={12} lg={8}>
											<h3>{distributor.name}</h3>
											<div>{distributor.street}</div>
											<div>{distributor.postcode} {distributor.city}</div>
											{distributor.phone && <div>Tel.: {distributor.phone}</div>}
											{distributor.mobile && <div>Mob.: {distributor.mobile}</div>}
											{distributor.email && <div>E-mail: <a href={"mailto:" + distributor.email}>{distributor.email}</a></div>}
											{distributor.website && <div>Website: <a target="_blank" href={distributor.website}	rel="noopener noreferrer">{distributor.website}</a></div>}
											{distributor.office && <div>Office: {distributor.office}</div>}
											{distributor.contact_person && <div>Contact person: {distributor.contact_person}</div>}
											{distributor.additional_info && <Markdown remarkPlugins={[remarkGfm]} children={distributor.additional_info}/>}
												</Col>
										<Col xs={12} lg={4}>
											{distributor.logo &&
												<div className={"distributor__logo"}>
													<br/>
													<img src={process.env.REACT_APP_BACKEND_SERVER + distributor.logo.url} alt={distributor.name + " logo"}/>
												</div>
											}
										</Col>
									</Row>
									<hr/>
								</div>
							</Col>;
						})
					}
				</Row>
			</div>

		</div>;
	}
}

export default withTranslation()(DistributorCountryItem);
