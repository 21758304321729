// @flow
import React from "react";
import {withTranslation} from "react-i18next";
import {Col, Grid, Row} from "react-flexbox-grid";
import BackendRequest from "../../Models/REST";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import MasterGetter from "../../Models/Utils/MasterGetter";
import LoadingComponent from "../../Components/LoadingComponent";
import { Link } from "react-router-dom";
import CaseStudyItemAdmin from "../../Components/CaseStudies/CaseStudyItemAdmin/CaseStudyItemAdmin";

/**
 * @class CaseStudiesScene
 */
class CaseStudiesScene extends React.Component {

    state = {
        expectingResponse: true,
    };

    /**
     *
     */
    fetchData = () => {

        BackendRequest({
            endpoint: "case-studies",
            self: this,
        });

    };

    /**
     * Component did mount
     */
    componentDidMount() {
        this.fetchData();
    }

    /**
     * Final Render
     * @returns {*}
     */
    render() {

        const {
            t,
            entities,

        } = this.props;

        const session = MasterGetter.getSession(entities);
        const studies = session.CaseStudy.all().orderBy(["ordering"], ["asc"]);

        return (

            <Grid fluid>
                <Row>
                    <Col xs={12}>
                        <h1>{t("title.CaseStudies")}</h1>
                    </Col>
                    <Col xs={12}>
                        <div className="page__section--bottom-padding">
                            <Link className="btn" to={"/editor/case-studies/item"}>+ {t("button.CreateNew")}</Link>
                        </div>
                    </Col>
                    <Col xs={12} className="page__section--bottom-padding">
                        {(this.state.expectingResponse) ?
                            <LoadingComponent/> : studies.toModelArray().map(study => {
                                return <CaseStudyItemAdmin key={study.id} caseStudy={study}/>;
                            })}
                    </Col>
                </Row>
            </Grid>
        );
    }

}

/**
 * Map state to props
 * @param state
 * @return {{entities: {CaseStudy: (*|CaseStudy), Photo: (*|Photo)}}}
 */
const mapStateToProps = state => (
    {
        entities: {
            Photo: state.entities.Photo,
            CaseStudy: state.entities.CaseStudy,
        },
    });

/**
 * Exporting part of the React.Component file
 */
export default withRouter(connect(mapStateToProps)(withTranslation()(CaseStudiesScene)));