// @flow

import PropTypes from "prop-types";
import {attr} from "redux-orm";

import CRUDModel from "../ORM/CRUDModel";


export default class Webinar extends CRUDModel {}

Webinar.modelName = "Webinar";

Webinar.fields = {
    id: attr(), // non-relational field for any value; optional but highly recommended
};

Webinar.propTypes = {
    name: PropTypes.string,
};

export const ADD_WEBINAR = "ADD_WEBINAR";
export const REMOVE_WEBINAR = "REMOVE_WEBINAR";
export const UPDATE_WEBINAR = "UPDATE_WEBINAR";