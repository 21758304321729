// @flow

import PropTypes from "prop-types";
import {attr, fk} from "redux-orm";

import CRUDModel from "../ORM/CRUDModel";
import slugify from "slugify";

/**
 * @class ProductSubcategory
 */
export default class ProductSubcategory extends CRUDModel {
    // /**
    //  * @param {{id: number, name: string, color: string, voltage: number, capacity: number}} data
    //  * @returns {Object}
    //  */
    // static apiToPayload(data: Object)
    // {
    // 	return {
    // 		id: data.id,
    // 		name: data.name,
    // 		color: data.color,
    // 		voltage: Number(data.voltage),
    // 		capacity: Number(data.capacity) || null,
    // 	};
    // }


    // /**
    //  * @returns {{id: number, name: string, color: string, voltage: string, capacity: string}}
    //  */
    // toApi()
    // {
    // 	return {
    // 		id: this.id,
    // 		name: this.name,
    // 		color: this.color,
    // 		voltage: String(this.voltage),
    // 		capacity: String(this.capacity),
    // 	}
    // }
    friendly_url = () => {
        return slugify(this.name).toLowerCase();
    }
}

ProductSubcategory.modelName = "ProductSubcategory";

ProductSubcategory.fields = {
    id: attr(), // non-relational field for any value; optional but highly recommended
    category: fk("ProductCategory"),
    description: attr(),
    image: fk("Photo"),
    name: attr(),
    products_count: attr(),
    image__url: attr(),
};

ProductSubcategory.propTypes = {
    name: PropTypes.string,
};

ProductSubcategory.defaultProps = {
    image__url: "",
};
//
// ProductSubcategory.endpoint = "/group";

export const ADD_PRODUCT_SUBCATEGORY = "ADD_PRODUCT_SUBCATEGORY";
export const REMOVE_PRODUCT_SUBCATEGORY = "REMOVE_PRODUCT_SUBCATEGORY";
export const UPDATE_PRODUCT_SUBCATEGORY = "UPDATE_PRODUCT_SUBCATEGORY";