// @flow
import React from "react";
import {withTranslation} from "react-i18next";
import {Col, Grid, Row} from "react-flexbox-grid";

import repository_diagram from "../../Components/Technology/img/repository-diagram.jpg";
import {Helmet} from "react-helmet";

/**
 * @class TechnologyRepositoryScene
 */
class TechnologyRepositoryScene extends React.Component {


	/**
	 * Final Render
	 * @returns {*}
	 */
	render() {

		return (<Grid className={"page"}>
				<Helmet>
					<title>Repository - IQRF</title>
				</Helmet>
				<Row>
					<Col xs={12}>
						<h1>Repository</h1>
						<p><a href="https://repository.iqrfalliance.org/doc/" rel="noreferrer noopener" target="_blank">IQRF
							Repository</a> is a public on-line <b>storage</b> and <strong>database </strong>
							containing information regarding all <b>IQRF interoperable products</b>. It provides
							information about and relations between IQRF ecosystem objects such
							as <b>companies, products, DPA handlers, standards, OS, DPA</b> etc. The
							repository is developed in <b>REST API</b> with <b>JSON</b> and can be utilized
							by <b>gateways, user applications, clouds, IQRF IDE</b> and so on.</p><br/>
					</Col>
				</Row>
				<Row>
					<Col className="text--center" xs={12}>
						<img src={repository_diagram} alt={"IQRF Repository diagram"}/><br/>
					</Col>
				</Row>
				<Row className="page__section--bottom-padding">
					<Col xs={12}>
						<strong><a href="https://repository.iqrfalliance.org/doc/" target="_blank" rel="noopener noreferrer">&raquo; IQRF Repository</a></strong>
					</Col>
				</Row>
			</Grid>

		);
	}

}

/**
 * Exporting part of the React.Component file
 */
export default withTranslation()(TechnologyRepositoryScene);