// Necessary Evil
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from "react";
import ReactDOM from "react-dom";

import * as Sentry from "@sentry/browser";
import App from "./App/App";

if(process.env.NODE_ENV !== "development"){
    Sentry.init({dsn: "https://288d672682d34ababb8e73a48cdd8d46@sentry.iqrf.org/3"});
}

ReactDOM.render(<App/>, document.getElementById("app"));
