// @flow

import PropTypes from "prop-types";
import {attr, fk, many} from "redux-orm";

import CRUDModel from "../ORM/CRUDModel";


export default class Product extends CRUDModel {
    // /**
    //  * @param {{id: number, name: string, color: string, voltage: number, capacity: number}} data
    //  * @returns {Object}
    //  */
    // static apiToPayload(data: Object)
    // {
    // 	return {
    // 		id: data.id,
    // 		name: data.name,
    // 		color: data.color,
    // 		voltage: Number(data.voltage),
    // 		capacity: Number(data.capacity) || null,
    // 	};
    // }


    // /**
    //  * @returns {{id: number, name: string, color: string, voltage: string, capacity: string}}
    //  */
    // toApi()
    // {
    // 	return {
    // 		id: this.id,
    // 		name: this.name,
    // 		color: this.color,
    // 		voltage: String(this.voltage),
    // 		capacity: String(this.capacity),
    // 	}
    // }
}

Product.modelName = "Product";

Product.fields = {
    id: attr(), // non-relational field for any value; optional but highly recommended
    name: attr(),
    friendly_url: attr(),
    series: fk("ProductSeries"),
    category: fk("ProductCategory"),
    subcategory: fk("ProductSubcategory"),
    section: fk("ProductSubcategorySection"),
    status: fk("ProductStatus"),
    status_2: fk("ProductStatus2", "status_2"),
    description: attr(),
    description_2: attr(),
    price_list_description: attr(),
    is_archived: attr(),
    is_online: attr(),
    in_price_list: attr(),
    is_available: attr(),
    bands:  many({
        to: "RadioBand",
        relatedName: "bands",
    }),
    lead_time: attr(),
    packing: attr(),
    photos: many({
        to: "Photo",
        relatedName: "variant_photos"
    }),
    image: fk("Photo", "image"),
    replace_by: fk("Product"),
    details: attr(),
    distributor_costs: attr(),
    distributor_mog: attr(),
    distributor_status: attr(),

};

Product.propTypes = {
    name: PropTypes.string,
};

// Product.defaultProps = {
// };
//
// Product.endpoint = "/group";

export const ADD_PRODUCT = "ADD_PRODUCT";
export const REMOVE_PRODUCT = "REMOVE_PRODUCT";
export const UPDATE_PRODUCT = "UPDATE_PRODUCT";