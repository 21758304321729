// @flow

// Necessary Evil
import React from "react";
import PropTypes from "prop-types";
import {withTranslation} from "react-i18next";
import {Col, Grid, Row} from "react-flexbox-grid";
import {Link} from "react-router-dom";
// Import component CSS
import "./PromoBox.scss";

import lowPowerPicture from "../../../App/images/why/low_power.svg";
import simpleAdoptionPicture from "../../../App/images/why/simple_adoption.svg";
import industrialPicture from "../../../App/images/why/industrial_reliability.svg";
import licencePicture from "../../../App/images/why/licence_and_carrier_free.svg";

/**
 * @class PromoBox
 */
class PromoBox extends React.Component {

    /**
     * PropTypes
     * @type {{children: shim}}
     */
    static propTypes = {
        children: PropTypes.any,
    };

    /**
     * Final Render
     * @returns {*}
     */
    render() {
        return <div className="promo-box">
            <div className="promo-box__background"/>
            <div className="promo-box__content">
                <Grid className={"primary"}>
                    <div className="promo-box__title"><span className="text--orange">Reliable</span> Wireless Mesh
                        Technology
                    </div>
                    <div className="text--center">
                        <div className="promo-box__title center">
							<span className="text--orange">Simply</span> connecting devices to IoT
                        </div>
                    </div>

                </Grid>
                <div style={{background: "black"}}>
                    <Grid>
                        <Row>
                            <Col className="promo-box__proof" xs={12} md={6} lg>
                                        <Link to={"/technology/industrial-reliability"}><img src={industrialPicture} alt={"Industrial reliability"}/></Link><span><Link to={"/technology/industrial-reliability"} className={"reliability"}> Industrial reliability</Link></span>
                            </Col>
                            <Col className="promo-box__proof" xs={12} md={6} lg>
                                <Link to={"/technology/low-power-modes"}><img src={lowPowerPicture} alt={"Low power"}/></Link><span><Link to={"/technology/low-power-modes"} className={"low_power"}>Low power</Link></span>
                            </Col>
                            <Col className="promo-box__proof" xs={12} md={6} lg>
                                <Link to={"/technology/simple-adoption-integration"}><img src={simpleAdoptionPicture} alt={"Simple integration"}/></Link><span><Link to={"/technology/simple-adoption-integration"} className={"reliability"}>Simple integration</Link></span>
                            </Col>
                            <Col className="promo-box__proof" xs={12} md={6} lg>
                                <Link to={"/technology/open-standard"}><img src={licencePicture} alt={"Open Standard"}/></Link><span><Link to={"/technology/open-standard"} className={"reliability"}>Open Standard</Link></span>
                            </Col>
                        </Row>
                    </Grid>
                </div>
            </div>
        </div>;
    }
}

export default withTranslation()(PromoBox);