// @flow

// Necessary Evil
import React from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import LoadingComponent from "../Components/LoadingComponent";
import BackendRequest from "../Models/REST/BackendRequest";
import FormGroup from "../Components/Form/FormGroup";
import { connect } from "react-redux";
import ErrorMessage from "../Components/Form/ErrorMessage/ErrorMessage";
import Markdown from "react-markdown";
import remarkGfm from "remark-gfm";
import SwitchButton from "../Components/Form/SwitchButton/SwitchButton";
import { Col, Row } from "react-flexbox-grid";

import moment from "moment";
import InstantAction from "../Models/Utils/InstantAction";

/**
 * @class CaseStudyForm
 *
 */
class CaseStudyForm extends React.Component {

	/**
	 * PropTypes
	 * @type {{children: shim, videoCategories: *}}
	 */
	static propTypes = {
		news: PropTypes.any,
		afterSuccess: PropTypes.func,
	};

	/**
	 * Default Props
	 * @type {{afterSuccess: CaseStudyForm.defaultProps.afterSuccess}}
	 */
	static defaultProps = {
		afterSuccess: () => {
			// InstantAction.redirect("/editor/news");
		},
	};

	/**
	 * State
	 * @type {{formData: {}, expectingResponse: boolean, errorMessage: null}}
	 */
	state = {
		expectingResponse: false,
		errorMessage: null,
		// Properties
		id: null,
		content: "",
		perex: "",
		published: moment().format("YYYY-MM-DD"),
		published_time: moment().format("HH:mm"),
		is_online: true,

	};

	/**
	 * Get derived state from props
	 * @param props
	 * @param state
	 */
	static getDerivedStateFromProps (props, state) {

		if (props.news !== null) {
			if (props.news.id !== state.id) {
				return {
					...props.news._fields,
					published_time: moment(props.news._fields.published).format("HH:mm"),
					published: moment(props.news._fields.published).format("YYYY-MM-DD"),
				};
			}
		}
		return null;
	}

	/**
	 * Handle Input Changes
	 * @param event
	 */
	handleInputChange = (event) => {

		const target = event.target;
		const value = target.type === "checkbox" ? target.checked : target.value;
		const name = target.name;

		this.setState({
			[name]: value
		});

	};

	/**
	 * Handle Switch
	 * @param key
	 */
	handleSwitch = (key) => {

		this.setState({
			[key]: !this.state[key]
		});

	};

	/**
	 * Handle Submit
	 * @param e
	 */
	handleSubmit = (e) => {

		e.preventDefault();

		/**
		 * Payload
		 * @type {{image: null, name: string, title: string, content: string}}
		 */

		const payload = {
			content: this.state.content,
			perex: this.state.perex,
			published: moment(this.state.published + " " + this.state.published_time).utc().format("YYYY-MM-DD HH:mm"),
			is_online: this.state.is_online,
		};


		if(!moment(payload.published).isValid()){
			this.setState({
				errorMessage: "Invalid date format"
			});

			return;
		}

		let method = this.state.id === null ? "post" : "put";
		let endpoint = this.state.id === null ? "news-item" : "news-item/" + this.state.id;

		BackendRequest({
			endpoint: endpoint,
			method: method,
			payload: payload,
			self: this,
			afterSuccess: (response) => {
				InstantAction.redirect("/editor/news/item/"+response.data.news.id);
				this.props.afterSuccess();
			},
			afterError: (error) => {
				console.log(error);
				this.setState({

				});
			}
		});
	};

	/**
	 * Component will mount
	 */
	componentDidMount () {

		if (this.props.news !== null) {
			this.setState({
				...this.props.news._fields,
				published_time: moment(this.props.news._fields.published).format("HH:mm"),
				published: moment(this.props.news._fields.published).format("YYYY-MM-DD"),
			});
		}
	}

	/**
	 * Final Render
	 * @returns {*}
	 */
	render () {

		/**
		 * @info Translation function, className
		 */
		const {
			t,
		} = this.props;

		/**
		 * @info Error variable
		 */
		let error = "";

		/**
		 * @info Handling Error Message
		 */
		if (this.state.errorMessage !== null) {
			error = <ErrorMessage>{t(this.state.errorMessage)}</ErrorMessage>;
		}

		/**
		 * Final Output
		 * @type {{}}
		 */
		return <form onSubmit={this.handleSubmit} className={"form"}>
			{error}
			<Row>
				<Col xs={12} lg={6}>
					<FormGroup>
						<label htmlFor="perex">{t("label.Content")}</label>
						<textarea onChange={this.handleInputChange}
								  value={this.state.perex}
								  tabIndex={2}
								  name="perex" id="perex"
								  placeholder={"Perex"}
						/>
						<em>(Use markdown - <a target="_blank"
											   href="https://github.com/adam-p/markdown-here/wiki/Markdown-Cheatsheet"
											   rel="noopener noreferrer">https://github.com/adam-p/markdown-here/wiki/Markdown-Cheatsheet</a>)</em>
					</FormGroup>
				</Col>
				<Col xs={12} lg={6}>
					<FormGroup>
						<label>{t("label.Preview")}</label>
						<Markdown remarkPlugins={[remarkGfm]} children={this.state.perex}/>
					</FormGroup>
				</Col>
			</Row>
			<Row>
				<Col xs={12} lg={6}>
					<FormGroup>
						<label htmlFor="content">Content (optional)</label>
						<textarea
							onChange={this.handleInputChange}
							value={this.state.content}
							tabIndex={2}
							name="content" id="content"
							placeholder={"Description"}
						/>
						<em>(Use markdown - <a target="_blank"
											   href="https://github.com/adam-p/markdown-here/wiki/Markdown-Cheatsheet"
											   rel="noopener noreferrer">https://github.com/adam-p/markdown-here/wiki/Markdown-Cheatsheet</a>)</em>
					</FormGroup>
				</Col>
				<Col xs={12} lg={6}>
					<FormGroup>
						<label>{t("label.Preview")}</label>
						<Markdown remarkPlugins={[remarkGfm]} children={this.state.content}/>
					</FormGroup>
				</Col>
			</Row>
			<Row>
				<Col xs={12} lg={3}>
					<FormGroup>
						<label htmlFor="published">{t("label.DateOfPublish")}</label>
						<input onChange={this.handleInputChange}
							   value={this.state.published}
							   type="date"

							   name="published" id="published"
							   placeholder={t("label.Published")}
						/>
					</FormGroup>

				</Col>
				<Col xs={12} lg={3}>
					<FormGroup>
						<label htmlFor="published_time">{t("label.TimeOfPublish")}</label>
						<input onChange={this.handleInputChange}
							   value={this.state.published_time}
							   type="time"
							   name="published_time" id="published_time"
							   placeholder={t("label.Published")}
						/>
					</FormGroup>
				</Col>
			</Row>
			<FormGroup>
				<label>{t("label.IsOnline")}?</label>
				<br/>
				<SwitchButton checked={this.state.is_online} handleClick={this.handleSwitch} name={"is_online"}/>
			</FormGroup>

			<hr/>
			<FormGroup className={"text--center"}>
				{(this.state.expectingResponse) ? <LoadingComponent/> :
					<button type="submit" tabIndex={2}
							className="btn btn--animated">{this.state.id === null ? t("button.Submit") : t("button.Update")}</button>
				}
			</FormGroup>
		</form>;
	}
}

/**
 * State to Props
 * @param state
 * @return {{entities: {PhotoSection: (*|PhotoSection)}}}
 */
const mapStateToProps = state => (
	{
		entities: {
			Photo: state.entities.Photo,
			PhotoSection: state.entities.PhotoSection
		},
	});

export default (connect(mapStateToProps)(withTranslation()(CaseStudyForm)));
