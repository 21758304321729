// @flow
import React from "react";
import BackendRequest from "../../Models/REST";
import InstantAction from "../../Models/Utils/InstantAction";
import MasterGetter from "../../Models/Utils/MasterGetter";
import {Col, Grid, Row} from "react-flexbox-grid";
import {connect} from "react-redux";
import {GenericItem} from "../../Components/GenericItem";
import {LoadingComponent} from "../../Components";
import {withTranslation} from "react-i18next";
import {withRouter} from "react-router-dom";
import "../../Components/Links/Links.scss";

/**
 * @class StartupHistoryListScene
 */
class StartupHistoryListScene extends React.Component {

	/**
	 * State
	 * @type {{expectingResponse: boolean}}
	 */
	state = {
		expectingResponse: true,
	};

	/**
	 * Fetch app data if necessary
	 */
	fetchData = () => {
		BackendRequest({
			endpoint: "startup-package-history",
			self: this,
		});
	};

	/**
	 * Component Did Mount
	 */
	componentDidMount(): void {
		this.fetchData();
	}

	/**
	 * Create New
	 */
	createNew = () => {
		BackendRequest({
			endpoint: "startup-package-history/new",
			method: "post",
			afterSuccess: (response) => {
				InstantAction.redirect("/editor/startup-package-history-item/" + response.data.startup_package_history.id);
			}
		});
	};

	/**
	 * Final Render
	 * @returns {*}
	 */
	render() {

		const {
			entities,
		} = this.props;

		const session = MasterGetter.getSession(entities);
		const historyRecords = session.StartupPackageHistory.all().orderBy(["ordering"], ["desc"]);

		if (this.state.expectingResponse) {
			return <LoadingComponent/>;
		}

		return (
			<Grid fluid>
				<Row>
					<Col xs={12}>
						<h1>Startup Package History</h1>
						<div>
							<button onClick={this.createNew} className="btn">Create New</button>
						</div>
						<br/><br/>
					</Col>
					<Col xs={12}>
						{historyRecords.toModelArray().map(item => {
							return <GenericItem item={item} nameProperty={"title"} key={item.id} onClick={() => {
								InstantAction.redirect("/editor/startup-package-history-item/" + item.id);
							}
							}/>;
						})}
						<div>
							{historyRecords.count() > 0 ? "" : "No records"}
						</div>
					</Col>
				</Row>
			</Grid>
		);
	}

}

/**
 *
 * @param state
 * @return {{entities: *}}
 */
const mapStateToProps = state => (
	{
		entities: state.entities,
	});

/**
 * Exporting part of the React.Component file
 */
export default withRouter(connect(mapStateToProps)(withTranslation()(StartupHistoryListScene)));