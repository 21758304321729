// @flow

import PropTypes from "prop-types";
import {attr, fk} from "redux-orm";

import CRUDModel from "../ORM/CRUDModel";


export default class ProductLink extends CRUDModel {}

ProductLink.modelName = "ProductLink";

ProductLink.fields = {
    id: attr(), // non-relational field for any value; optional but highly recommended
	product: fk("ProductSeries"),
	link: fk("Link"),
	ordering: attr(),
};

ProductLink.propTypes = {
    name: PropTypes.string,
};

// ProductLink.defaultProps = {
// };
//
// ProductLink.endpoint = "/group";

export const ADD_PRODUCT_LINK = "ADD_PRODUCT_LINK";
export const REMOVE_PRODUCT_LINK = "REMOVE_PRODUCT_LINK";
export const UPDATE_PRODUCT_LINK = "UPDATE_PRODUCT_LINK";