// @flow

import MasterDispatcher from "./MasterDispatcher";
import {RESET_STORE, setAppLoaded, setLoggedUser} from "../../App/App.actions";
import {withRouter} from "react-router";
import {TrademarkType} from "../Models";
import ProductStatus from "../Models/ProductStatus";
import ProductStatus2 from "../Models/ProductStatus2";
import BackendRequest from "../REST";

/**
 * Instant Action
 */
class InstantAction {

    /**
     * Fetch App Data
     */
    static fetchAppData(parent) {

        /**
         * Initiate fixtures
         * @type {{trademark_type: *[]}}
         */
        let fixtures = {
            trademark_type: TrademarkType.fixtures,
            product_status: ProductStatus.fixtures,
            product_status2: ProductStatus2.fixtures,
        };

        MasterDispatcher.dispatch(fixtures);


        /**
         * After Success
         * @param response
         */
        const afterSuccess = (response) => {

            const data = response.data;

            InstantAction.dispatch(setLoggedUser(data.user.id));
            InstantAction.dispatch(setAppLoaded(true));
            parent.setState({
                loadingData: false,
            }, () => {

            });
        };

        if(localStorage.getItem("JWT")) {
            BackendRequest({
                method: "get",
                endpoint: "user",
                afterSuccess: afterSuccess,
            });
        }else{
            parent.setState({
                loadingData: false,
            }, () => {

            });
        }

    }

    /**
     * Pure Dispatcher
     * @param data
     */
    static dispatch(data) {
        InstantAction.dispatcher(data);
    }

    /**
     * Init
     * @param history
     */
    static initiateHistory(history) {
        InstantAction.history = history;
    }

    /**
     * Initiate Dispatcher
     * @param dispatcher {func}
     */
    static initiateDispatcher(dispatcher) {
        InstantAction.dispatcher = dispatcher;
    }

    /**
     * Redirect
     * @param path
     */
    static redirect(path) {

        console.log("Pushing: ", path);
        InstantAction.history.push(path);

    }

    /**
     * Reset Store initial state
     */
    static resetStore() {

        InstantAction.dispatch({
            type: RESET_STORE
        });

    }
}


export default InstantAction = withRouter(InstantAction);