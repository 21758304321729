// @flow

import PropTypes from "prop-types";
import {attr, fk} from "redux-orm";

import CRUDModel from "../ORM/CRUDModel";


export default class CaseStudy extends CRUDModel {
    // /**
    //  * @param {{id: number, name: string, color: string, voltage: number, capacity: number}} data
    //  * @returns {Object}
    //  */
    // static apiToPayload(data: Object)
    // {
    // 	return {
    // 		id: data.id,
    // 		name: data.name,
    // 		color: data.color,
    // 		voltage: Number(data.voltage),
    // 		capacity: Number(data.capacity) || null,
    // 	};
    // }


    // /**
    //  * @returns {{id: number, name: string, color: string, voltage: string, capacity: string}}
    //  */
    // toApi()
    // {
    // 	return {
    // 		id: this.id,
    // 		name: this.name,
    // 		color: this.color,
    // 		voltage: String(this.voltage),
    // 		capacity: String(this.capacity),
    // 	}
    // }
}

CaseStudy.modelName = "CaseStudy";

CaseStudy.fields = {
    id: attr(), // non-relational field for any value; optional but highly recommended
    name: attr(),
    title: attr(),
    image: fk("Photo"),
    content: attr(),
};

CaseStudy.propTypes = {
    name: PropTypes.string.isRequired,
};

// CaseStudy.defaultProps = {
// };
//
// CaseStudy.endpoint = "/group";

export const ADD_CASE_STUDY = "ADD_CASE_STUDY";
export const REMOVE_CASE_STUDY = "REMOVE_CASE_STUDY";
export const UPDATE_CASE_STUDY = "UPDATE_CASE_STUDY";