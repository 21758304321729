// @flow
import React from "react";
import InstantAction from "../../../Models/Utils/InstantAction";
import PropTypes from "prop-types";
// Import CSS
import "./PublicSceneWrapper.scss";
import Footer from "../../Footer/Footer";
import {publicRoutes} from "../../../Config/routes";
import {Switch} from "react-router-dom";
import {MainNavigation} from "../../Navigation";
import BackendRequest from "../../../Models/REST";
import {LoadingComponent} from "../../index";
import TreeNavigation from "../../Navigation/TreeNavigation/TreeNavigation";
import {Col, Grid, Row} from "react-flexbox-grid";
import {setCloseNavigation, setProductsLoaded} from "../../../App/App.actions";
import ModalPopup from "../../ModalPopup";

/**
 * @class PublicSceneWrapper
 */
export default class PublicSceneWrapper extends React.Component {

    /**
     * PropTypes
     * @type {{children: shim}}
     */
    static propTypes = {
        children: PropTypes.any,
        className: PropTypes.string,
    };

    /**
     * Default Props
     * @type {{className: string}}
     */
    static defaultProps = {
        className: ""
    };

    /**
     * State
     * @type {{expectingResponse: boolean}}
     */
    state = {
        expectingResponse: true,
    };

    /**
     * Component Did mount
     */
    componentDidMount() {
        this.fetchEssentials();
    }

    /**
     * Fetch Essentials
     */
    fetchEssentials = () => {

        BackendRequest({
            endpoint: "navigation-data",
            self: this,
            afterSuccess: () => {
                console.log("Navigation data fetched");
                InstantAction.dispatch(setProductsLoaded(true));
                this.setState({
                    expectingResponse: false,
                });
            }
        });

    };

    /**
     * Final Render
     * @returns {*}
     */
    render() {

        const {className} = this.props;

        return <div className={"public-scene-wrapper " + className}>
            <MainNavigation/>
            {this.state.expectingResponse ? <LoadingComponent/> :
                <div onClick={() => InstantAction.dispatch(setCloseNavigation(true))}>
                    <Grid>
                        <Row>
                            <Col xs={12}>
                                <TreeNavigation/>
                            </Col>
                        </Row>
                    </Grid>
                    <Switch>{publicRoutes}</Switch>
                </div>
            }
            <ModalPopup/>
            <Footer/>
        </div>;
    }
}