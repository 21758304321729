// @flow

// Necessary Evil
import React from "react";
import PropTypes from "prop-types";
import {withTranslation} from "react-i18next";
import BackendRequest from "../Models/REST/BackendRequest";
import FormGroup from "../Components/Form/FormGroup";
import {connect} from "react-redux";
import {Col, Row} from "react-flexbox-grid";
import InstantAction from "../Models/Utils/InstantAction";
import {REMOVE_LINK, UPDATE_LINK} from "../Models/Models/Link";
import SwitchButton from "../Components/Form/SwitchButton/SwitchButton";
import DeleteModal from "../Components/DeleteModal";
import MasterGetter from "../Models/Utils/MasterGetter";
import Markdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import rehypeSanitize from "rehype-sanitize";
import remarkGfm from "remark-gfm";
import {REMOVE_PRODUCT_LINK} from "../Models/Models/ProductLink";

/**
 * @class LinkForm
 */
class LinkForm extends React.Component {

    /**
     * Proptypes
     * @type {{link: shim, afterSuccess: shim}}
     */
    static propTypes = {
        link: PropTypes.any,
        productLink: PropTypes.any,
        product: PropTypes.any,
        closeForm: PropTypes.func.isRequired,
    };

    /**
     * Default Props
     * @type {{afterSuccess: LinkForm.defaultProps.afterSuccess}}
     */
    static defaultProps = {
        link: null,
        product: null,
        productLink: null,
    };

    /**
     * State
     * @type {{formData: {}, expectingResponse: boolean, errorMessage: null}}
     */
    state = {
        expectingResponse: false,
        errorMessage: null,
        // Properties
        id: (this.props.link) ? this.props.link.id : null,
        auto_redirect: (this.props.link) ? this.props.link.auto_redirect : false,
        content: (this.props.link) ? this.props.link.content : "",
        description: (this.props.link) ? this.props.link.description : "",
        file: (this.props.link) ? ((this.props.link.file) ? this.props.link.file.id : 0) : 0,
        icon: (this.props.link) ? this.props.link.icon : "info",
        is_external: (this.props.link) ? this.props.link.is_external : false,
        is_modal: (this.props.link) ? this.props.link.is_modal : false,
        modalIsOpen: false,
        name: (this.props.link) ? this.props.link.name : "",
        ordering: (this.props.link) ? ((this.props.productLink) ? this.props.productLink.ordering : this.props.link.ordering) : 0,
        page: (this.props.link) ? this.props.link.page : "",
        product: (this.props.link) ? this.props.link.product : this.props.product.id,
        target: (this.props.link) ? this.props.link.target : "",
        url: (this.props.link) ? this.props.link.url : null,
    };

    /**
     * Handle Switch
     * @param key
     */
    handleSwitch = (key) => {
        this.setState({
            [key]: !this.state[key]
        });
    };

    closeForm = () => {
        this.props.closeForm();
    };

    /**
     * Handle Input Changes
     * @param event
     */
    handleInputChange = (event) => {

        const target = event.target;
        const value = target.type === "checkbox" ? target.checked : target.value;
        const name = target.name;
        this.setState({
            [name]: value
        });

    };

    /**
     * Handle Submit
     * @param e
     */
    handleSubmit = (e) => {

        e.preventDefault();

        const {
            link,
            productLink,
        } = this.props;

        /**
         * Payload
         * @type {{image: null, name: string, title: string, content: string}}
         */
        const payload = {
            ...this.state,
            description: this.state.description,
            name: this.state.name,
            url: this.state.url,
            icon: this.state.icon,
            product: (this.props.product ? this.props.product.id : null),
            ordering: this.state.ordering,
            page: this.state.page,
        };

        // let endpoint = "link";
        // let method = "post";

        const afterSuccess = (response) => {
            if (link) {
                InstantAction.dispatch({
                    type: UPDATE_LINK,
                    payload: {
                        ...response.data.link,
                        edit: false,
                    }
                });
            }
        };

        if (productLink) {
            /**
             * Case of editing product link
             */
            BackendRequest({
                endpoint: "product-link/" + productLink.id,
                method: "put",
                payload: payload,
                afterSuccess: afterSuccess,
                self: this,
            });
        } else {
            /**
             * Case of single link edit
             */
            BackendRequest({
                endpoint: "link/" + link.id,
                method: "put",
                payload: payload,
                afterSuccess: afterSuccess,
                self: this,
            });
        }

    };

    /**
     * Delete item
     */
    handleDelete = (e) => {

        e.preventDefault();

        const {productLink} = this.props;

        if (productLink) {
            BackendRequest({
                endpoint: "product-link/" + productLink.id,
                method: "delete",
                self: this,
                afterSuccess: () => {

                    InstantAction.dispatch({
                        type: REMOVE_PRODUCT_LINK,
                        payload: productLink.id,
                    });

                    InstantAction.dispatch({
                        type: REMOVE_LINK,
                        payload: this.state.id,
                    });
                }
            });
        } else {
            BackendRequest({
                endpoint: "link/" + this.state.id,
                method: "delete",
                self: this,
                afterSuccess: () => {

                    InstantAction.dispatch({
                        type: REMOVE_LINK,
                        payload: this.state.id,
                    });
                }
            });
        }

    };

    /**
     * Final Render
     * @returns {*}
     */
    render() {

        const {entities} = this.props;
        const session = MasterGetter.getSession(entities);
        const files = session.File.all().orderBy(["name"], ["asc"]);

        /**
         * Final Output
         * @type {{}}
         */
        return <form className={"form"} style={{
            background: "rgb(253, 253, 253)",
            border: "1px solid rgb(222, 222, 222)",
            padding: "1rem",
            marginBottom: "1rem",
            marginTop: "1rem",
        }}>
            <Row>
                <Col xs={12}>
                    {!this.state.expectingResponse && <div style={{
                        display: "flex",
                        justifyContent: "space-between"
                    }}>
                        <button
                            type="submit"
                            className="btn btn--animated"
                            onClick={this.handleSubmit}>
                            {this.props.link ? "Update" : "Add"}
                        </button>
                        {this.props.link &&
                        <button
                            className="btn btn--danger"
                            onClick={(e) => {
                                e.preventDefault();
                                this.setState({
                                    modalIsOpen: true,
                                });
                            }}>
                            <i className="icon-delete"/>
                        </button>
                        }
                        <button
                            type="button"
                            className="btn btn--animated"
                            onClick={this.closeForm}>
                            Close
                        </button>
                    </div>
                    }
                </Col>
                <Col xs={12}>
                    <hr/>
                </Col>
                <Col xs={12} lg>
                    <FormGroup>
                        <label htmlFor="icon">Icon</label>
                        <select onChange={this.handleInputChange}
                                value={this.state.icon}
                                name="icon"
                                id="icon"
                        >
                            <option value="pdf">PDF</option>
                            <option value="exe">EXE</option>
                            <option value="zip">ZIP</option>
                            <option value="doc">DOC</option>
                            <option value="info">INFO</option>
                        </select>
                    </FormGroup>
                </Col>
                <Col xs={12} lg>
                    <FormGroup>
                        <label htmlFor="name">Name</label>
                        <input onChange={this.handleInputChange}
                               value={this.state.name}
                               type="text"
                               name="name"
                               id="name"
                        />
                    </FormGroup>
                </Col>
                <Col xs={12} lg>
                    <FormGroup>
                        <label htmlFor="ordering">Ordering</label>
                        <input onChange={this.handleInputChange}
                               value={this.state.ordering}
                               type="text"
                               name="ordering"
                               id="ordering"
                        />
                    </FormGroup>
                </Col>
                <Col xs={12} lg>
                    <FormGroup>
                        <label htmlFor="page">page</label>
                        <input onChange={this.handleInputChange}
                               value={this.state.page}
                               type="text"
                               name="page"
                               id="page"
                        />
                    </FormGroup>
                </Col>
                <Col xs={12} lg>
                    <FormGroup>
                        <label htmlFor="url">URL</label>
                        <input onChange={this.handleInputChange}
                               value={this.state.url}
                               type="text"
                               name="url"
                               id="url"
                        />
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col xs={12}>
                    <FormGroup>
                        <label htmlFor="file">File</label>
                        <select onChange={this.handleInputChange}
                                value={this.state.file}
                                name="file"
                                id="file"
                        >
                            <option value={0}>Select file</option>
                            {files.toModelArray().map(item => {
                                return <option
                                    key={item.id}
                                    value={item.id}
                                >{item.name} ({item.filename})</option>;
                            })}
                        </select>
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col xs={12} lg={6}>
                    <FormGroup>
                        <label htmlFor="content">content</label>
                        <textarea onChange={this.handleInputChange}
                                  value={this.state.content}
                                  name="content"
                                  id="content"
                        />
                    </FormGroup>
                </Col>
                <Col xs={12} lg={6}>
                    <FormGroup>
                        <label>Content Preview</label>
                        <Markdown
                          rehypePlugins={[rehypeRaw, rehypeSanitize]}
                          remarkPlugins={[remarkGfm]}
                          children={this.state.content}
                        />
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col xs={12} lg={6}>
                    <FormGroup>
                        <label htmlFor="description">description</label>
                        <textarea onChange={this.handleInputChange}
                                  value={this.state.description}
                                  name="description"
                                  id="description"
                        />
                    </FormGroup>
                </Col>
                <Col xs={12} lg={6}>
                    <FormGroup>
                        <label>Content Preview</label>
                        <Markdown
                          rehypePlugins={[rehypeRaw, rehypeSanitize]}
                          remarkPlugins={[remarkGfm]}
                          children={this.state.description}
                        />
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col xs={12} lg>
                    <FormGroup>
                        <label>Is External</label>
                        <SwitchButton checked={this.state.is_external} handleClick={this.handleSwitch}
                                      name={"is_external"}
                        />
                    </FormGroup>
                </Col>
                <Col xs={12} lg>
                    <FormGroup>
                        <label>Auto Redirect</label>
                        <SwitchButton checked={this.state.auto_redirect} handleClick={this.handleSwitch}
                                      name={"auto_redirect"}
                        />
                    </FormGroup>
                </Col>
                <Col xs={12} lg>
                    <FormGroup>
                        <label>Is Modal</label>
                        <SwitchButton checked={this.state.is_modal} handleClick={this.handleSwitch}
                                      name={"is_modal"}
                        />
                    </FormGroup>
                </Col>
                <Col xs={12} lg={12}>
                    <FormGroup>
                        <label htmlFor="target">Target of auto-redirection</label>
                        <input onChange={this.handleInputChange}
                               value={this.state.target}
                               type="text"
                               name="target"
                               id="target"
                        />
                    </FormGroup>
                </Col>
            </Row>
            <hr/>
            <Row>
                <Col xs={12}>
                    {!this.state.expectingResponse && <div style={{
                        display: "flex",
                        justifyContent: "space-between"
                    }}>
                        <button
                            type="submit"
                            className="btn btn--animated"
                            onClick={this.handleSubmit}>
                            {this.props.link ? "Update" : "Add"}
                        </button>
                        {this.props.link &&
                        <button
                            className="btn btn--danger"
                            onClick={(e) => {
                                e.preventDefault();
                                this.setState({
                                    modalIsOpen: true,
                                });
                            }}>
                            <i className="icon-delete"/>
                        </button>
                        }
                        <button
                            type="button"
                            className="btn btn--animated"
                            onClick={this.closeForm}>
                            Close
                        </button>
                    </div>
                    }
                </Col>
            </Row>
            <DeleteModal
                isOpen={this.state.modalIsOpen}
                handleDelete={this.handleDelete}
                parent={this}
            />
        </form>;
    }
}

/**
 * State to Props
 * @param state
 * @return {{entities: {PhotoSection: (*|PhotoSection)}}}
 */
const mapStateToProps = state => (
    {
        entities: {
            File: state.entities.File,
            Link: state.entities.Link,
        }
    });

export default (connect(mapStateToProps)(withTranslation()(LinkForm)));
