// @flow
import React from "react";
import {withTranslation} from "react-i18next";
import {Col, Grid, Row} from "react-flexbox-grid";
import {Link} from "react-router-dom";
import {Helmet} from "react-helmet";

/**
 * @class FAEScene
 */
class FAEScene extends React.Component {


	/**
	 * Final Render
	 * @returns {*}
	 */
	render() {

		const {t} = this.props;

		return (
			<Grid className={"page"}>
				<Helmet>
					<title>{t("title.FAE")} - IQRF</title>
				</Helmet>
				<Row>
					<Col xs={12} className="page__section--bottom-padding">
						<h1>{t("title.FAE")}</h1>
						<p>
							IQRF <strong>FAE</strong> (Field Application Engineering) provides a massive customer-facing
							help with
							applications (from an assistance with system-level design to complete turn-key
							solutions).</p>
						<p>
							FAE is the <strong>most effective, most economic</strong> and <strong>fastest
							way</strong> to <strong>complete your commercial
							project</strong>.
						</p>

						<p><strong>Speed up your first design with our engineer</strong>. Then you can follow-up to your <strong>next designs
							yourself</strong>.
						</p>
						<Link to={"/support/contact-support"}>Submit a request</Link>
						<p>Another very effective approach is getting a complete turn-key application done by a local IQRF <Link to="/sales/design-houses">Design house</Link>.</p>
					</Col>
				</Row>
			</Grid>

		);
	}

}

/**
 * Exporting part of the React.Component file
 */
export default withTranslation()(FAEScene);
