import React from "react";
// import Loadable from "react-loadable";
import {Route} from "react-router-dom";
import PrivateRoute from "../Components/PrivateRoute";
import ApplicationNotesScene from "../Scenes/PublicScenes/ApplicationNotesScene";
import CareerScene from "../Scenes/PublicScenes/CareerScene";
import CareerFAEEngineerScene from "../Scenes/PublicScenes/CareerFAEEngineerScene";
import CaseStudiesScene from "../Scenes/EditorScenes/CaseStudiesScene";
import ClaimReturnsScene from "../Scenes/PublicScenes/ClaimReturnsScene";
import CompanyScene from "../Scenes/PublicScenes/CompanyScene";
import ContactSalesScene from "../Scenes/PublicScenes/ContactSalesScene";
import ContactsScene from "../Scenes/PublicScenes/ContactsScene";
import DistributorsScene from "../Scenes/PublicScenes/DistributorsScene";
import DesignHousesScene from "../Scenes/PublicScenes/DesignHousesScene";
import DownloadsScene from "../Scenes/PublicScenes/DownloadsScene";
import Error404Scene from "../Scenes/Error404Scene";
import EshopScene from "../Scenes/PublicScenes/EshopScene";
import EventsScene from "../Scenes/EditorScenes/EventsScene";
import FAEScene from "../Scenes/PublicScenes/FAEScene";
import FAQScene from "../Scenes/PublicScenes/FAQScene";
import FilesScene from "../Scenes/EditorScenes/FilesScene";
import GetStartedScene from "../Scenes/PublicScenes/GetStartedScene";
import GetStartedStartupPackageHistoryScene from "../Scenes/PublicScenes/GetStartedStartupPackageHistoryScene";
import LandingScene from "../Scenes/PublicScenes/LandingScene";
import LoginScene from "../Scenes/PublicScenes/LoginScene";
import NewsItemScene from "../Scenes/EditorScenes/NewsItemScene";
import NewsScene from "../Scenes/EditorScenes/NewsScene";
import PatentsScene from "../Scenes/PublicScenes/PatentsScene";
import PhotosScene from "../Scenes/EditorScenes/PhotosScene";
import PrivacyPolicyScene from "../Scenes/PublicScenes/PrivacyPolicyScene";
import SettingsScene from "../Scenes/PrivateScenes/SettingsScene";
import TechnicalSupportScene from "../Scenes/PublicScenes/TechnicalSupportScene";
import TechnologyCloudsScene from "../Scenes/PublicScenes/TechnologyCloudsScene";
import TechnologyCurrentOSScene from "../Scenes/PublicScenes/TechnologyCurrentOSScene";
import TechnologyDPAScene from "../Scenes/PublicScenes/TechnologyDPAScene";
import TechnologyDPAArchiveScene from "../Scenes/PublicScenes/TechnologyDPAArchiveScene";
import TechnologyFRCScene from "../Scenes/PublicScenes/TechnologyFRCScene";
import TechnologyLocalFRCScene from "../Scenes/PublicScenes/TechnologyLocalFRCScene";
import TechnologyDSMScene from "../Scenes/PublicScenes/TechnologyDSMScene";
import TechnologyDP2PScene from "../Scenes/PublicScenes/TechnologyDP2PScene";
import TechnologyBroadcastScene from "../Scenes/PublicScenes/TechnologyBroadcastScene";
import TechnologyOfflineFRCScene from "../Scenes/PublicScenes/TechnologyOfflineFRCScene";
import TechnologyGWDaemonScene from "../Scenes/PublicScenes/TechnologyGWDaemonScene";
import TechnologyHistoryOSScene from "../Scenes/PublicScenes/TechnologyHistoryOSScene";
import TechnologyIDEScene from "../Scenes/PublicScenes/TechnologyIDEScene";
import TechnologyIDE2Scene from "../Scenes/PublicScenes/TechnologyIDE2Scene";
import TechnologyIDEHistoryCommandScene from "../Scenes/PublicScenes/TechnologyIDEHistoryCommandScene";
import TechnologyIQMeshScene from "../Scenes/PublicScenes/TechnologyIQMeshScene";
import TechnologyLocalBondingScene from "../Scenes/PublicScenes/TechnologyLocalBondingScene";
import TechnologySmartConnectScene from "../Scenes/PublicScenes/TechnologySmartConnectScene";
import TechnologyAutonetworkScene from "../Scenes/PublicScenes/TechnologyAutonetworkScene";
import TechnologyDiscoveryScene from "../Scenes/PublicScenes/TechnologyDiscoveryScene";
import TechnologyUnicastScene from "../Scenes/PublicScenes/TechnologyUnicastScene";
import TechnologyOSFunctionsScene from "../Scenes/PublicScenes/TechnologyOSFunctionsScene";
import TechnologyOSMigrationNotesScene from "../Scenes/PublicScenes/TechnologyOSMigrationNotesScene";
import TechnologyOSMIDCloningScene from "../Scenes/PublicScenes/TechnologyOSMIDCloningScene";
import TechnologyOSScene from "../Scenes/PublicScenes/TechnologyOSScene";
import TechnologyRepositoryScene from "../Scenes/PublicScenes/TechnologyRepositoryScene";
import TechnologyRFScene from "../Scenes/PublicScenes/TechnologyRFScene";
import TechnologyRFPGMScene from "../Scenes/PublicScenes/TechnologyRFPGMScene";
import TechnologyRFPGMHistoryScene from "../Scenes/PublicScenes/TechnologyRFPGMHistoryScene";
import TechnologyScene from "../Scenes/PublicScenes/TechnologyScene";
import TechnologySDKScene from "../Scenes/PublicScenes/TechnologySDKScene";
import TermsOfUseScene from "../Scenes/PublicScenes/TermsOfUseScene";
import TrademarksScene from "../Scenes/PublicScenes/TrademarksScene";
import TrainingScene from "../Scenes/PublicScenes/TrainingScene";
import VideosScene from "../Scenes/PublicScenes/VideosScene";
import WebinarsScene from "../Scenes/PublicScenes/WebinarsScene";
import TechnologyLowPowerScene from "../Scenes/PublicScenes/TechnologyLowPowerScene";
import TechnologySimpleAdoptionScene from "../Scenes/PublicScenes/TechnologySimpleAdoptionScene";
import TechnologyOpenStandardScene from "../Scenes/PublicScenes/TechnologyOpenStandardScene";
import TechnologyIndustrialReliabilityScene from "../Scenes/PublicScenes/TechnologyIndustrialReliabilityScene";
import TechnologyEcosystemScene from "../Scenes/PublicScenes/TechnologyEcosystemScene";
import TechnologySecurityScene from "../Scenes/PublicScenes/TechnologySecurityScene";
import TechnologyInteroperabilityScene from "../Scenes/PublicScenes/TechnologyInteroperabilityScene";
import ProvenScene from "../Scenes/PublicScenes/ProvenScene";
import TechnologyDPACalculatorScene from "../Scenes/PublicScenes/TechnologyDPACalculatorScene";
import {default as EditorCaseStudyScene} from "../Scenes/EditorScenes/CaseStudyScene";
import {default as EditorDistributorScene} from "../Scenes/EditorScenes/DistributorScene";
import {default as EditorDistributorsScene} from "../Scenes/EditorScenes/DistributorsScene";
import {default as EditorProductScene} from "../Scenes/EditorScenes/ProductScene";
import {default as EditorProductsScene} from "../Scenes/EditorScenes/ProductsScene";
import CaseStudyScene from "../Scenes/PublicScenes/CaseStudyScene";
import ProductCategoryScene from "../Scenes/PublicScenes/ProductCategoryScene";
import ProductSubcategoryScene from "../Scenes/PublicScenes/ProductSubcategoryScene";
import ProductScene from "../Scenes/PublicScenes/ProductScene";
import WhatIsIQRFScene from "../Scenes/PublicScenes/WhatIsIQRFScene";
import ProductVariantsScene from "../Scenes/EditorScenes/ProductVariantsScene";
import ProductVariantScene from "../Scenes/EditorScenes/ProductVariantScene";
import ProductsCategoryArchiveScene from "../Scenes/PublicScenes/ProductsCategoryArchiveScene";
import EventItemScene from "../Scenes/EditorScenes/EventItemScene";
import ProductSubcategoryArchiveScene from "../Scenes/PublicScenes/ProductSubcategoryArchiveScene";
import NewsListScene from "../Scenes/PublicScenes/NewsListScene";
import EventsListScene from "../Scenes/PublicScenes/EventsListScene";
import LinksScene from "../Scenes/EditorScenes/LinksListScene";
import WebinarsListScene from "../Scenes/EditorScenes/WebinarsListScene";
import WebinarItemScene from "../Scenes/EditorScenes/WebinarItemScene";
import ForTRUnderOSScene from "../Scenes/PublicScenes/ForTRUnderOSScene";
import ForTRUnderDPAScene from "../Scenes/PublicScenes/ForTRUnderDPAScene";
import StartupHistoryListScene from "../Scenes/EditorScenes/StartupHistoryListScene";
import StartupPackageHistoryItemScene from "../Scenes/EditorScenes/StartupPackageHistoryItemScene";
import SearchScene from "../Scenes/PublicScenes/SearchScene";
import DownloadScene from "../Scenes/PublicScenes/DownloadScene";
import FileItemScene from "../Scenes/EditorScenes/FileItemScene";
import ReleaseNotesScene from "../Scenes/EditorScenes/ReleaseNotesScene";
import ReleaseNoteScene from "../Scenes/EditorScenes/ReleaseNoteScene";
import ProductCrossTableScene from "../Scenes/PublicScenes/ProductCrossTableScene";
import OSFunctionsScene from "../Scenes/EditorScenes/OSFunctionsScene";
import OSFunctionItemScene from "../Scenes/EditorScenes/OSFunctionItemScene";
import OSFunctionsSectionScene from "../Scenes/EditorScenes/OSFunctionSectionsScene";
import OSFunctionSectionItemScene from "../Scenes/EditorScenes/OSFunctionSectionItemScene";
import TechnologyDPAOsDpaCompatibilityScene from "../Scenes/PublicScenes/TechnologyDPAOsDpaCompatibilityScene";

/**
 * Editor Routes
 * @type {*[]}
 */
export const editorRoutes = [
	<Route path="/editor/photos" key={"photos"} exact component={PhotosScene}/>,
	<Route path="/editor/product/item/:id?" key={"product-item"} exact component={EditorProductScene}/>,
	<Route path="/editor/product-variant/:id?" key={"product-variant"} exact component={ProductVariantScene}/>,
	<Route path="/editor/product-variants" key={"product-variants"} exact component={ProductVariantsScene}/>,
	<Route path="/editor/products" key={"products"} exact component={EditorProductsScene}/>,
	<Route path="/editor/release-note/:id" key={"release-note"} exact component={ReleaseNoteScene}/>,
	<Route path="/editor/release-notes" key={"release"} exact component={ReleaseNotesScene}/>,
	<Route path="/editor/files" key={"files"} exact component={FilesScene}/>,
	<Route path="/editor/file/:id" key={"file"} exact component={FileItemScene}/>,
	<Route path="/editor/news/item/:id?" key={"news-item"} exact component={NewsItemScene}/>,
	<Route path="/editor/news" key={"news"} exact component={NewsScene}/>,
	<Route path="/editor/event/item/:id?" key={"event-item"} exact component={EventItemScene}/>,
	<Route path="/editor/events" key={"events"} exact component={EventsScene}/>,
	<Route path="/editor/case-studies/item/:id?" key={"case-study"} exact component={EditorCaseStudyScene}/>,
	<Route path="/editor/case-studies" key={"case-studies"} exact component={CaseStudiesScene}/>,
	<Route path="/editor/links" key={"case-studies"} exact component={LinksScene}/>,
	<Route path="/editor/distributors" key={"edit-distributors"} exact component={EditorDistributorsScene}/>,
	<Route path="/editor/distributor/:id?" key={"edit-distributors-item"} exact component={EditorDistributorScene}/>,
	<Route path="/editor/webinars" key={"edit-webinars"} exact component={WebinarsListScene}/>,
	<Route path="/editor/startup-package-history" key={"edit-startup-package-history"} exact component={StartupHistoryListScene}/>,
	<Route path="/editor/startup-package-history-item/:id" key={"edit-startup-package-history-item"} exact component={StartupPackageHistoryItemScene}/>,
	<Route path="/editor/webinar/:id" key={"edit-webinars"} exact component={WebinarItemScene}/>,
	<Route path="/editor/os-functions" key={"edit-os-function-sections"} exact component={OSFunctionsScene}/>,
	<Route path="/editor/os-function-sections" key={"edit-os-functions"} exact component={OSFunctionsSectionScene}/>,
	<Route path="/editor/os-function/:id" key={"edit-os-function"} exact component={OSFunctionItemScene}/>,
	<Route path="/editor/os-function-section/:id" key={"edit-os-function-section"} exact component={OSFunctionSectionItemScene}/>,
];


/**
 * Export of routes
 * @type {*[]}
 */
export const publicRoutes = [
	<Route key={"login"} exact path={"/login"} component={LoginScene}/>,
	// Private Scenes
	<PrivateRoute key={"settings"} path={"/settings"} component={SettingsScene}/>,
	<Route path="/download/:id" key={"download"} exact component={DownloadScene}/>,

	// Public Scenes
	<Route path="/" key={"landing"} exact component={LandingScene}/>,
	<Route path="/career" key={"contacts"} exact component={CareerScene}/>,
	<Route path="/career/detail-fae-engineer" key={"contacts"} exact component={CareerFAEEngineerScene}/>,
	<Route path="/claim-returns" key={"claim-returns"} exact component={ClaimReturnsScene}/>,
	<Route path="/sales/company" key={"about"} exact component={CompanyScene}/>,
	<Route path="/contacts" key={"contacts"} exact component={ContactsScene}/>,
	<Route path="/sales/distributors" key={"contacts"} exact component={DistributorsScene}/>,
  <Route path="/sales/design-houses" key={"contacts"} exact component={DesignHousesScene}/>,

	<Route path="/what-is-iqrf" key={"what-is-iqrf"} exact component={WhatIsIQRFScene}/>,

	<Route path="/e-shop" key={"e-shop"} exact component={EshopScene}/>,
	<Route path="/how-to-start" key={"get-started"} exact component={GetStartedScene}/>,
	<Route path="/how-to-start/startup-package-history" key={"get-started-startup-package-history"} exact component={GetStartedStartupPackageHistoryScene}/>,
	<Route path="/patents" key={"patents"} exact component={PatentsScene}/>,
	<Route path="/privacy-policy" key={"privacy-policy-scene"} exact component={PrivacyPolicyScene}/>,
	<Route path="/product-detail/:series_url" key={"product-detail"} exact component={ProductScene}/>,
	<Route path="/sales/contact" key={"sales"} exact component={ContactSalesScene}/>,
	<Route path="/news" key={"news"} exact component={NewsListScene}/>,
	<Route path="/events" key={"events-public"} exact component={EventsListScene}/>,
	<Route path="/search/:search?" key={"search-public"} exact component={SearchScene}/>,
	<Route path="/case-study/:friendly_url" key={"case-study-scene"} exact component={CaseStudyScene}/>,

	// Support

	<Route path="/support/application-notes" key={"application-notes"} exact component={ApplicationNotesScene}/>,
	<Route path="/support/fae" key={"fae"} exact component={FAEScene}/>,
	<Route path="/support/downloads" key={"downloads"} exact component={DownloadsScene}/>,
	<Route path="/support/faq" key={"faq"} exact component={FAQScene}/>,
	<Route path="/support/contact-support" key={"support-contact"} exact component={TechnicalSupportScene}/>,
	<Route path="/support/training" key={"training"} exact component={TrainingScene}/>,
	<Route path="/support/videos" key={"videos"} exact component={VideosScene}/>,
	<Route path="/support/webinars" key={"webinars"} exact component={WebinarsScene}/>,
	<Route path="/support/code-examples/for-tr-under-os" key={"for-tr-under-os"} exact component={ForTRUnderOSScene}/>,
  <Route path="/support/code-examples/for-tr-under-dpa" key={"for-tr-under-dpa"} exact component={ForTRUnderDPAScene}/>,

    // Technology
	<Route path="/technology" key={"technology"} exact component={TechnologyScene}/>,
	<Route path="/technology/clouds" key={"technology-clouds"} exact component={TechnologyCloudsScene}/>,
	<Route path="/technology/dpa" key={"technology-iqrf-dpa"} exact component={TechnologyDPAScene}/>,
	<Route path="/technology/dpa/archive-dpa" key={"technology-dpa-archive"} exact component={TechnologyDPAArchiveScene}/>,
	<Route path="/technology/dpa/calculator" key={"technology-calculator"} exact component={TechnologyDPACalculatorScene}/>,
	<Route path="/technology/dpa/OsDpaCompatibility" key={"OsDpaCompatibility"} exact component={TechnologyDPAOsDpaCompatibilityScene}/>,
	<Route path="/technology/iqmesh/frc" key={"technology-iqmesh-frc"} exact component={TechnologyFRCScene}/>,
	<Route path="/technology/iqmesh/local-frc" key={"technology-iqmesh-local-frc"} exact component={TechnologyLocalFRCScene}/>,
	<Route path="/technology/iqmesh/dpa-service-mode" key={"technology-iqmesh-dpa-service-mode"} exact component={TechnologyDSMScene}/>,
	<Route path="/technology/iqmesh/dpa-peer-to-peer" key={"technology-iqmesh-dpa-peer-to-peer "} exact component={TechnologyDP2PScene}/>,
	<Route path="/technology/iqmesh/offline-frc" key={"technology-iqmesh-offline-frc"} exact component={TechnologyOfflineFRCScene}/>,
	<Route path="/technology/gw-daemon" key={"technology-gw-daemon"} exact component={TechnologyGWDaemonScene}/>,
	<Route path="/technology/ide" key={"technology-iqrf-ide"} exact component={TechnologyIDEScene}/>,
	<Route path="/technology/ide/historycommand" key={"technology-iqrf-ide-historycommand"} exact component={TechnologyIDEHistoryCommandScene}/>,
	<Route path="/technology/ide/ide2" key={"technology-iqrf-ide2"} exact component={TechnologyIDE2Scene}/>,
	<Route path="/technology/iqmesh" key={"technology-iqmesh"} exact component={TechnologyIQMeshScene}/>,
	<Route path="/technology/iqmesh/local-bonding" key={"technology-iqmesh-localbonding"} exact component={TechnologyLocalBondingScene}/>,
	<Route path="/technology/iqmesh/smart-connect" key={"technology-iqmesh-smartconnect"} exact component={TechnologySmartConnectScene}/>,
	<Route path="/technology/iqmesh/autonetwork" key={"technology-iqmesh-autonetwork"} exact component={TechnologyAutonetworkScene}/>,
	<Route path="/technology/iqmesh/discovery" key={"technology-iqmesh-discovery"} exact component={TechnologyDiscoveryScene}/>,
	<Route path="/technology/iqmesh/unicast" key={"technology-iqmesh-unicast"} exact component={TechnologyUnicastScene}/>,
	<Route path="/technology/iqmesh/broadcast" key={"technology-iqmesh-unicast"} exact component={TechnologyBroadcastScene}/>,
	<Route path="/technology/os" key={"technology-iqrf-os"} exact component={TechnologyOSScene}/>,
	<Route path="/technology/os/current-os" key={"technology-current-os"} exact component={TechnologyCurrentOSScene}/>,
	<Route path="/technology/os/history-os" key={"technology-history-os"} exact component={TechnologyHistoryOSScene}/>,
	<Route path="/technology/os/mid-cloning" key={"technology-os-mid-cloning"} exact component={TechnologyOSMIDCloningScene}/>,
	<Route path="/technology/os/os-functions" key={"technology-os-functions"} exact component={TechnologyOSFunctionsScene}/>,
	<Route path="/technology/os/os-migration-notes" key={"technology-os-migration-notes"} exact component={TechnologyOSMigrationNotesScene}/>,
	<Route path="/technology/repository" key={"technology-repository"} exact component={TechnologyRepositoryScene}/>,
	<Route path="/technology/rf" key={"technology-rf"} exact component={TechnologyRFScene}/>,
	<Route path="/technology/rfpgm" key={"technology-rfpgm"} exact component={TechnologyRFPGMScene}/>,
	<Route path="/technology/rfpgm/rfpgmhistory" key={"technology-rfpgm-history"} exact component={TechnologyRFPGMHistoryScene}/>,
	<Route path="/technology/sdk" key={"technology-sdk"} exact component={TechnologySDKScene}/>,
	<Route path="/terms-of-use" key={"terms-of-use"} exact component={TermsOfUseScene}/>,
	<Route path="/trademarks" key={"sales"} exact component={TrademarksScene}/>,
	<Route path="/technology/low-power-modes" key={"technology-low-power-modes"} exact component={TechnologyLowPowerScene}/>,
	<Route path="/technology/ecosystem" key={"technology-ecosystem"} exact component={TechnologyEcosystemScene}/>,
	<Route path="/technology/industrial-reliability" key={"technology-industrial-reliability"} exact component={TechnologyIndustrialReliabilityScene}/>,
	<Route path="/technology/open-standard" key={"technology-open-standard"} exact component={TechnologyOpenStandardScene}/>,
	<Route path="/technology/simple-adoption-integration" key={"technology-simple-adoption-integration"} exact component={TechnologySimpleAdoptionScene}/>,
	<Route path="/technology/proven" key={"technology-proven"} exact component={ProvenScene}/>,
  <Route path="/technology/security" key={"technology-security"} exact component={TechnologySecurityScene}/>,
	<Route path="/technology/interoperability" key={"technology-interoperability"} exact component={TechnologyInteroperabilityScene}/>,
	<Route path="/products/cross-table" key={"product-cross-table"} exact component={ProductCrossTableScene}/>,
	<Route path="/products/:category/archive" key={"products-categories-archive"} exact component={ProductsCategoryArchiveScene}/>,
	<Route path="/products/:category" key={"products-categories"} exact component={ProductCategoryScene}/>,
	<Route path="/products/:category/:subcategory/archive" key={"products-subcategories-archive"} exact component={ProductSubcategoryArchiveScene}/>,
	<Route path="/products/:category/:subcategory" key={"products-subcategories"} exact component={ProductSubcategoryScene}/>,

	// Defaults
	//<Route key={""} exact path="/:page?/:secondary?/:entity?/:id?" component={AsyncPublic.Landing}/>,
	<Route key="404" component={Error404Scene}/>
];
