// @flow

// Necessary Evil
import React from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import LoadingComponent from "../Components/LoadingComponent";
import BackendRequest from "../Models/REST/BackendRequest";
import FormGroup from "../Components/Form/FormGroup";
import { connect } from "react-redux";
import ErrorMessage from "../Components/Form/ErrorMessage/ErrorMessage";
import { Col, Row } from "react-flexbox-grid";
import Markdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import rehypeSanitize from "rehype-sanitize";
import MasterGetter from "../Models/Utils/MasterGetter";
import MyDropZone from "../Components/MyDropzone";
import remarkGfm from "remark-gfm";

/**
 * @class CaseStudyForm
 *
 */
class CaseStudyForm extends React.Component {

	/**
	 * PropTypes
	 * @type {{children: shim, videoCategories: *}}
	 */
	static propTypes = {
		study: PropTypes.any,
		afterSuccess: PropTypes.func,
	};

	/**
	 * Default Props
	 * @type {{afterSuccess: CaseStudyForm.defaultProps.afterSuccess}}
	 */
	static defaultProps = {
		afterSuccess: () => {
			// InstantAction.redirect("/editor/case-studies");
		},
	};

	/**
	 * State
	 * @type {{formData: {}, expectingResponse: boolean, errorMessage: null}}
	 */
	state = {
		expectingResponse: false,
		errorMessage: null,
		// Properties
		id: null,
		title: "",
		image: 0,
		name: "",
		content: "",
		photo: "",
		ordering: 0,
	};

	/**
	 *
	 * @param props
	 * @param state
	 */
	static getDerivedStateFromProps (props, state) {

		if (props.study !== null) {
			if (props.study.id !== state.id) {
				return {
					...props.study._fields,
					image: (props.study._fields.image === null) ? 0 : props.study._fields.image
				};
			}
		}
		return null;
	}

	/**
	 * On Drop Action
	 * @param acceptedFiles
	 */
	onDrop = (acceptedFiles) => {

		acceptedFiles.forEach(file => {

			let filenameSpliced = file.name.split(".");
			let fileType = filenameSpliced[filenameSpliced.length - 1];

			/**
			 * Payload
			 * @type {{file: *, filename: *, section: number, type: (*|string)}}
			 */
			const data = {
				file: file,
				section: 3,
				filename: file.name,
				type: fileType,
			};

			BackendRequest({
				method: "post",
				endpoint: "photo",
				payload: data,
				self: this,
				afterSuccess: (response) => {

					const data = response.data;

					this.setState({
						image: data.photo.id,
					});
				}
			});
		});

	};

	/**
	 * Handle Input Changes
	 * @param event
	 */
	handleInputChange = (event) => {

		const target = event.target;
		const value = target.type === "checkbox" ? target.checked : target.value;
		const name = target.name;

		this.setState({
			[name]: value
		});

	};

	/**
	 * Handle Submit
	 * @param e
	 */
	handleSubmit = (e) => {

		e.preventDefault();

		/**
		 * Payload
		 * @type {{image: null, name: string, title: string, content: string}}
		 */
		const payload = {
			name: this.state.name,
			title: this.state.title,
			content: this.state.content,
			ordering: this.state.ordering,
			image: this.state.image === 0 ? null : this.state.image,
		};

		let method = this.state.id === null ? "post" : "put";
		let endpoint = this.state.id === null ? "case-study" : "case-study/" + this.state.id + "/edit";

		BackendRequest({
			endpoint: endpoint,
			method: method,
			payload: payload,
			self: this,
			afterSuccess: this.props.afterSuccess
		});
	};

	/**
	 * Component will mount
	 */
	componentDidMount () {

		if (this.props.study !== null) {
			this.setState({
				...this.props.study._fields,
				image: (this.props.study._fields.image === null) ? 0 : this.props.study._fields.image
			});
		}

	}

	/**
	 * Final Render
	 * @returns {*}
	 */
	render () {

		/**
		 * @info Translation function, className
		 */
		const {
			t,
			entities,
		} = this.props;

		/**
		 * @info Error variable
		 */
		let error = "";

		/**
		 * @info Handling Error Message
		 */
		if (this.state.errorMessage !== null) {
			error = <ErrorMessage>{t("formError." + this.state.errorMessage)}</ErrorMessage>;
		}

		const session = MasterGetter.getSession(entities);
		const photos = session.Photo.all().filter(item => item.section === 3);
		const image = session.Photo.withId(this.state.image);

		/**
		 * Final Output
		 * @type {{}}
		 */
		return <form onSubmit={this.handleSubmit} className={"form"}>
			{error}
			<FormGroup>
				<label htmlFor="name">{t("label.Name")}</label>
				<input onChange={this.handleInputChange}
					   value={this.state.name}
					   type="text"
					   tabIndex={1}
					   name="name"
					   id="name"
					   autoFocus={true}
					   placeholder={t("label.Name")}
				/>
			</FormGroup>
			<FormGroup>
				<label htmlFor="title">{t("label.PageTitle")}</label>
				<input onChange={this.handleInputChange}
					   value={this.state.title}
					   type="text"
					   tabIndex={2}
					   name="title" id="title"
					   placeholder={t("label.Title")}
				/>
			</FormGroup>
			<FormGroup>
				<label htmlFor="ordering">Ordering</label>
				<input onChange={this.handleInputChange}
					   value={this.state.ordering}
					   type="text"
					   tabIndex={3}
					   name="ordering"
					   id="ordering"

				/>
			</FormGroup>

			<Row>
				<Col xs={12}>
					<hr/>
				</Col>
				<Col xs={12} lg={6}>

					<FormGroup>
						<label htmlFor={"image"}>{t("label.SelectImage")}</label>
						<select
							onChange={this.handleInputChange}
							name={"image"}
							id={"image"}
							value={this.state.image}
						>
							<option value={0}>[No photo]</option>
							{photos.toModelArray().map(item => {
								return <option key={item.id} value={item.id}>{item.name}</option>;
							})}
						</select>
					</FormGroup>
					<FormGroup>
						<label>{t("label.OrUploadImage")}</label>
						<br/>
						<MyDropZone onDrop={this.onDrop} accept={"image/*"}/>
					</FormGroup>
				</Col>
				<Col xs={12} lg={6}>
					<label>
						{t("label.ImagePreview")}
					</label>
					{this.state.image !== 0 && image !== null ? <div>
						<img className="image--preview" src={process.env.REACT_APP_BACKEND_SERVER + image.url}
							 alt={"Preview"}/>
					</div> : <div>[NO IMAGE]</div>}
				</Col>
				<Col xs={12}>
					<hr/>
				</Col>
			</Row>
			<Row>
				<Col xs={12} lg={6}>
					<FormGroup>
						<label htmlFor="content">{t("label.Content")} </label>
						<textarea
							onChange={this.handleInputChange}
							value={this.state.content}
							tabIndex={3}
							name="content"
							id="content"
							style={{ minHeight: "500px" }}
						/>
						<em>(Use markdown - <a target="_blank"
											   href="https://github.com/adam-p/markdown-here/wiki/Markdown-Cheatsheet"
											   rel="noopener noreferrer">https://github.com/adam-p/markdown-here/wiki/Markdown-Cheatsheet</a>)</em>
					</FormGroup>
				</Col>
				<Col xs={12} lg={6}>
					<FormGroup>
						<label>{t("label.ContentPreview")}</label>
						<Markdown
							rehypePlugins={[rehypeRaw, rehypeSanitize]}
							remarkPlugins={[remarkGfm]}
							children={this.state.content}
						/>
					</FormGroup>
				</Col>
			</Row>
			<br/>
			<hr/>
			<FormGroup className={"text--center"}>
				{(this.state.expectingResponse) ? <LoadingComponent/> :
					<button type="submit" tabIndex={2} className="btn btn--animated">
						{this.state.id === null ? t("button.Submit") : t("button.Update")}
					</button>
				}
			</FormGroup>
		</form>;
	}
}

/**
 * State to Props
 * @param state
 * @return {{entities: {PhotoSection: (*|PhotoSection)}}}
 */
const mapStateToProps = state => (
	{
		entities: {
			Photo: state.entities.Photo,
			PhotoSection: state.entities.PhotoSection
		},
	});

export default (connect(mapStateToProps)(withTranslation()(CaseStudyForm)));
