// @flow

// Necessary Evil
import React from "react";
import PropTypes from "prop-types";
import {withTranslation} from "react-i18next";
// Import component CSS
import "./ThumbnailPhotoAdmin.scss";
import Thumbnail from "../../Images/Thumbnail/Thumbnail";
import {REMOVE_PHOTO} from "../../../Models/Models/Photo";
import BackendRequest from "../../../Models/REST";
import InstantAction from "../../../Models/Utils/InstantAction";


/**
 * @class ThumbnailPhotoAdmin
 */
class ThumbnailPhotoAdmin extends React.Component {

    /**
     * PropTypes
     * @type {{children: shim}}
     */
    static propTypes = {
        photo: PropTypes.any,
        parent: PropTypes.any,
        product: PropTypes.any,
    };

    /**
     * Delete Photo
     */
    handleDelete = (event) => {

        event.preventDefault();

        const {photo, product, parent} = this.props;

        BackendRequest({
            endpoint: "product/"+product.id+"/photo/" + photo.id,
            method: "delete",
            afterSuccess: () => {
                // Remove from state
                parent.updatePhotos(photo.id);

                // Remove from store
                InstantAction.dispatch({
                    type: REMOVE_PHOTO,
                    payload: photo.id,
                });
            }
        });

    };

    /**
     * Handle set as main photo
     * @param event
     */
    handleSetPrimary = (event) => {

        event.preventDefault();

        const {parent, photo} = this.props;

        parent.setState({
            stateChange: true,
            image: photo.id,
        });

    };

    /**
     * Final Render
     * @returns {*}
     */
    render() {

        const {photo} = this.props;



        return <div className="thumbnail-photo-admin">
            <Thumbnail photoUrl={photo.url}/>
            <button onClick={this.handleDelete}><i className="icon-delete"/></button>
            <button onClick={this.handleSetPrimary}><i className="icon-photos"/></button>
        </div>;
    }
}

export default withTranslation()(ThumbnailPhotoAdmin);