// @flow
import React from "react";
import {withTranslation} from "react-i18next";
import {Col, Grid, Row} from "react-flexbox-grid";
import MasterGetter from "../../Models/Utils/MasterGetter";
import {NavLink, withRouter} from "react-router-dom";
import {connect} from "react-redux";
import Markdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import rehypeSanitize from "rehype-sanitize";
import remarkGfm from "remark-gfm";
import BackendRequest from "../../Models/REST";
import {SeriesItem, SubcategoryItem} from "../../Components/Products";
import slugify from "slugify";
import InstantAction from "../../Models/Utils/InstantAction";
import {Helmet} from "react-helmet";

/**
 * @class ProductCategoryScene
 */
class ProductCategoryScene extends React.Component {

    /**
     * State
     * @type {{expectingResponse: boolean}}
     */
    state = {
        categoryId: null,
    };


    static checkProducts = (category, subcategories) => {

        if (!category.is_fetched) {
            console.log("Category is not fetched");
            // Category has not been fetched
            if (subcategories.count() === 0) {
                console.log("subcategories are zero");
                // readyToRender = false;
                // Category has only products so fetch them?
                BackendRequest({
                    endpoint: "category/" + category.id + "/products",
                });
            }
        }
    };

    static gerDerivedStateFromProps = (nextProps, state) => {

        if (nextProps.categoryId !== state.categoryId) {
            ProductCategoryScene.checkProducts(nextProps.category, nextProps.subcategories);

            return {
                categoryId: nextProps.categoryId
            };
        }

        return null;

    };

    /**
     * Final Render
     * @returns {*}
     */
    render() {

        const {
            archivedSeriesCount,
            category,
            subcategories,
            series,
        } = this.props;

        if (!category) {
            return null;
        }

        return (
            <Grid className={"page"}>
                <Helmet>
                    <title>{category.name} - IQRF</title>
                </Helmet>
                <Row>
                    <Col xs={12}>
                        <h1>{category.name}</h1>
                    </Col>
                    <Col xs={12} className="page__section--bottom-padding">
                        {(!category) ? "404 - Missing information" :
                            <div>
                                <Markdown
                                  rehypePlugins={[rehypeRaw, rehypeSanitize]}
                                  remarkPlugins={[remarkGfm]}
                                  children={category.description}
                                />
                            </div>}
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} className="page__section--bottom-padding">
                        {subcategories.count() > 0 ?
                            <Row key={subcategories.id}>
                                {subcategories.orderBy(["priority"], ["asc"]).toModelArray().map(item => {
                                    return <SubcategoryItem subcategory={item} key={item.id}/>;
                                })}
                            </Row>
                            :
                            <div>
                                {series.toModelArray().map(item => {
                                    return <SeriesItem key={item.id} series={item}/>;
                                })}
                                {(series?.count() === 0) && <div style={{color: "orange", fontWeight: "bold", fontStyle:"italic"}}>No products in this category. </div>}
                                <br/>
                                {archivedSeriesCount > 0 &&
                                <div className="text--center">
                                    <br/>
                                    <NavLink to={"/products/" + category.friendly_url() + "/archive"} className={"btn"}>Go
                                        to
                                        Archive</NavLink>
                                </div>}
                            </div>}
                    </Col>
                </Row>
            </Grid>
        );
    }

}

/**
 *
 * @param state
 * @return {{entities: *}}
 */
const mapStateToProps = (state, props) => {

    const session = MasterGetter.getSession(state.entities);
    const categoryNameSlug = props.match.params.category;
    const category = session.ProductCategory.filter(item => {
            return slugify(item.name).toLowerCase() === categoryNameSlug;
        }
    ).first();

    let subcategories = [];
    let series = [];
    let archivedSeriesCount = 0;

    if (category) {
        // Category found from navigation
        let categoryId = category.id;
        subcategories = session.ProductSubcategory.all().filter(item => item.category === categoryId);

        series = session.ProductSeries.all().filter(
            series => series.category === categoryId && series.is_online && !series.is_archived
        ).orderBy(["ordering"], ["desc"]);
        archivedSeriesCount = session.ProductSeries.all().filter(series => series.category === categoryId && series.is_online && series.is_archived).count();

        return {
            productsLoaded: state.app.productsLoaded,
            entities: state.entities,
            category: category,
            subcategories: subcategories,
            series: series,
            archivedSeriesCount: archivedSeriesCount,
            categoryId: categoryId,
        };

    } else {
        InstantAction.redirect("/error-404");
        return {};
    }
};


/**
 * Exporting part of the React.Component file
 */
export default withRouter(connect(mapStateToProps)(withTranslation()(ProductCategoryScene)));
