// @flow

// Necessary Evil
import React from "react";
import PropTypes from "prop-types";
import {withTranslation} from "react-i18next";
import {withRouter} from "react-router";
import {HashLink} from "react-router-hash-link";
import Markdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import rehypeSanitize from "rehype-sanitize";
import remarkGfm from "remark-gfm";
// Import component CSS
import "./FAQItem.scss";

/**
 * @class FAQItem
 */
class FAQItem extends React.Component {

	/**
	 * PropTypes
	 * @type {{children: shim}}
	 */
	static propTypes = {
		question: PropTypes.any,
	};

	/**
	 * State
	 * @type {{show: boolean}}
	 */
	state = {
		show: false,
	};

	/**
	 * Handle click
	 */
	handleClick = () => {
		const {show} = this.state;
		const {question} = this.props;

		localStorage.setItem("faq-" + question.id, true);

		this.setState({
			show: !show
		});
	};


	/**
	 * Component did mount
	 */
	componentDidMount(): void {

		const {
			location,
			question
		} = this.props;

		if (location.hash === ("#" + question.slug_name)) {
			this.setState({
				show: true
			});
		}

	};

	/**
	 * Final Render
	 * @returns {*}
	 */
	render() {
		const {question} = this.props;

		let visited = false;

		if (localStorage.getItem("faq-" + question.id) === "true") {
			visited = true;
		}

		return <div className={"faq-item" + (visited ? " visited" : "")} id={question.slug_name}>
			<div onClick={this.handleClick} className="faq-item__name">
				<Markdown
					rehypePlugins={[rehypeRaw, rehypeSanitize]}
					remarkPlugins={[remarkGfm]}
					children={question.name}
				/>
			</div>
			{this.state.show && <div className="faq-item__content">
				<Markdown
					rehypePlugins={[rehypeRaw, rehypeSanitize]}
					remarkPlugins={[remarkGfm]}
					children={question.answer}
				/>
				<div className="faq-item__link">
					<HashLink to={"/support/faq#" + question.slug_name}>{process.env.REACT_APP_WEBSITE}support/faq#{question.slug_name}</HashLink>
				</div>
			</div>}
		</div>;
	}
}

export default withRouter(withTranslation()(FAQItem));
