// @flow
import React from "react";
import {withTranslation} from "react-i18next";
import {Col, Grid, Row} from "react-flexbox-grid";
import {Helmet} from "react-helmet";

/**
 * @class TermsOfUseScene
 */
class TermsOfUseScene extends React.Component {

	/**
	 * Final Render
	 * @returns {*}
	 */
	render() {

		return (
			<div className={"page"}>
				<Helmet>
					<title>IQRF Tech s.r.o. (Ltd.) web site terms of use</title>
				</Helmet>
				<Grid>
					<Row className="page__section--bottom-padding">
						<Col xs={12}>
							<h1>IQRF Tech s.r.o. (Ltd.) web site terms of use</h1>
							<p>The IQRF Tech s.r.o. (Ltd.) website is integrates of web pages owned by IQRF Tech s.r.o.
								(Ltd.).</p>
							<p>The IQRF Tech s.r.o. (Ltd.) website is offered to you conditioned on your acceptance
								without modification
								of the terms, conditions, and notices contained herein. Your use of the IQRF Tech s.r.o.
								(Ltd.) website
								constitutes your agreement to all such terms, conditions, and notices.</p>
							<p>IQRF Tech s.r.o. (Ltd.) reserves the right to change the terms, conditions, and notices
								under which the
								IQRF Tech s.r.o. (Ltd.) website is offered, including but not limited to the charges
								associated with
								the use of the IQRF Tech s.r.o. (Ltd.) website.</p>
							<p>The IQRF Tech s.r.o. (Ltd.) website may contain links to other web Sites ("Linked Sites"). The Linked Sites
								are not under the control of IQRF Tech s.r.o. (Ltd.) and IQRF Tech s.r.o. (Ltd.) is not responsible for
								the contents of any Linked Site, including without limitation any link contained in a Linked Site, or
								any changes or updates to a Linked Site. IQRF Tech s.r.o. (Ltd.) is not responsible for webcasting or
								any other form of transmission received from any Linked Site. IQRF Tech s.r.o. (Ltd.) is providing these
								links to you only as a convenience, and the inclusion of any link does not imply endorsement by IQRF
								Tech s.r.o. (Ltd.) of the site or any association with its operators.</p>
							<p>As a condition of your use of the IQRF Tech s.r.o. (Ltd.) web site, you warrant to IQRF Tech s.r.o.
								(Ltd.) that you will not use the IQRF Tech s.r.o. (Ltd.) web site for any purpose that is unlawful
								or prohibited by these terms, conditions, and notices. You may not use the IQRF Tech s.r.o. (Ltd.)
								website in any manner which could damage, disable, overburden, or impair the IQRF Tech s.r.o. (Ltd.)
								website or interfere with any other party's use and enjoyment of the IQRF Tech s.r.o. (Ltd.) website.
								You may not obtain or attempt to obtain any materials or information through any means not
								intentionally made available or provided for through the IQRF Tech s.r.o. (Ltd.) web sites.</p>
							<p>The IQRF Tech s.r.o. (Ltd.) web site may contain news board services, chat areas, forums, communities,
								personal web pages, calendars, and/or other message or communication facilities designed to enable you
								to communicate with the public at large or with a group (collectively, "Communication Services"), you
								agree to use the Communication Services only to post, send and receive messages and material that are
								proper and related to the particular Communication Service. By way of example, and not as a limitation,
								you agree that when using a Communication Service, you will not:</p>
							<ul>
								<li>Defame, abuse, harass, stalk, threaten or otherwise violate the legal rights (such as rights
									of privacy and publicity) of others.
								</li>
								<li>Publish, post, upload, distribute or disseminate any inappropriate, profane, defamatory,
									infringing, obscene, indecent or unlawful topic, name, material or information.
								</li>
								<li>Upload files that contain software or other material protected by intellectual property laws
									(or by rights of privacy of publicity) unless you own or control the rights thereto or have
									received all necessary consents.
								</li>
								<li>Upload files that contain viruses, corrupted files, or any other similar software or programs
									that may damage the operation of another's computer.
								</li>
								<li>Advertise or offer to sell or buy any goods or services for any business purpose, unless such
									Communication Service specifically allows such messages.
								</li>
								<li>Conduct or forward surveys, contests, pyramid schemes or chain letters.</li>
								<li>Download any file posted by another user of a Communication Service that you know, or reasonably
									should know, cannot be legally distributed in such manner.
								</li>
								<li>Falsify or delete any author attributions, legal or other proper notices or proprietary
									designations or labels of the origin or source of software or other material contained in a
									file that is uploaded.
								</li>
								<li>Restrict or inhibit any other user from using and enjoying the Communication Services..</li>
								<li>Violate any code of conduct or other guidelines which may be applicable for any particular
									Communication Service.
								</li>
								<li>Harvest or otherwise collect information about others, including e-mail addresses, without
									their consent.
								</li>
								<li>Violate any applicable laws or regulations.</li>
							</ul>
							<p>IQRF Tech s.r.o. (Ltd.) has no obligation to monitor the Communication Services. However,
								IQRF Tech s.r.o. (Ltd.) reserves the right to review materials posted to a Communication Service
								and to remove any materials in its sole discretion. IQRF Tech s.r.o. (Ltd.) reserves the right to
								terminate your access to any or all of the Communication Services at any time without notice for any
								reason whatsoever.</p>
							<p>IQRF Tech s.r.o. (Ltd.) reserves the right at all times to disclose any information as necessary to
								satisfy any applicable law, regulation, legal process or governmental request, or to edit, refuse to
								post or to remove any information or materials, in whole or in part, in IQRF Tech s.r.o. (Ltd.)'s sole
								discretion.</p>
							<p>Always use caution when giving out any personally identifying information about yourself or your
								children in any Communication Service. IQRF Tech s.r.o. (Ltd.) does not control or endorse the content,
								messages or information found in any Communication Service and, therefore, IQRF Tech s.r.o. (Ltd.)
								specifically disclaims any liability with regard to the Communication Services and any actions
								resulting from your participation in any Communication Service. Managers and hosts are not authorized
								IQRF Tech s.r.o. (Ltd.) spokespersons, and their views do not necessarily reflect those of
								IQRF Tech s.r.o. (Ltd.).</p>
							<p>Materials uploaded to a Communication Service may be subject to posted limitations on usage,
								reproduction and/or dissemination. You are responsible for adhering to such limitations if you
								download the materials.</p>
							<p>IQRF Tech s.r.o. (Ltd.) does not claim ownership of the materials you provide to IQRF Tech s.r.o. (Ltd.)
								(including feedback and suggestions) or post, upload, input or submit to any IQRF Tech s.r.o. (Ltd.)
								website or its associated services (collectively "Submissions"). However, by posting, uploading,
								inputting, providing or submitting your Submission you are granting IQRF Tech s.r.o. (Ltd.),
								its affiliated companies and necessary sublicensees permission to use your Submission in connection
								with the operation of their Internet businesses including, without limitation, the rights to: copy,
								distribute, transmit, publicly display, publicly perform, reproduce, edit, withTranslation and reformat your
								Submission; and to publish your name in connection with your Submission.</p>
							<p>No compensation will be paid with respect to the use of your Submission, as provided herein.
								IQRF Tech s.r.o. (Ltd.) is under no obligation to post or use any Submission you may provide and may
								remove any Submission at any time in IQRF Tech s.r.o. (Ltd.)'s sole discretion.</p>
							<p>By posting, uploading, inputting, providing or submitting your Submission you warrant and represent
								that you own or otherwise control all of the rights to your Submission as described in this section
								including, without limitation, all the rights necessary for you to provide, post, upload, input or
								submit the Submissions.</p>
						</Col>
					</Row>
				</Grid>
				<div className="page__section--with-theme">
					<Grid>
						<Row>
							<Col xs={12}>
								<h2>Liability disclaimer</h2>
								<p>The information, software, products, and services included in or available through the IQRF
									Tech s.r.o. (Ltd.) website may include inaccuracies or typographical errors. Changes are
									periodically added to the information herein. IQRF Tech s.r.o. (Ltd.) and/or its suppliers
									may make improvements and/or changes in the IQRF Tech s.r.o. (Ltd.) website at any time. Advice
									received via the IQRF Tech s.r.o. (Ltd.) website should not be relied upon for personal, medical,
									legal or financial decisions and you should consult an appropriate professional for specific advice
									tailored to your situation.</p>
								<p>IQRF Tech s.r.o. (Ltd.) and/or its suppliers make no representations about the suitability,
									reliability, availability, timeliness, and accuracy of the information, software, products,
									services and related graphics contained on the IQRF Tech s.r.o. (Ltd.) website for any purpose.
									To the maximum extent permitted by applicable law, all such information, software, products,
									services and related graphics are provided "as is" without warranty or condition of any kind.
									IQRF Tech s.r.o. (Ltd.) and/or its suppliers hereby disclaim all warranties and conditions with
									regard to this information, software, products, services and related graphics, including all
									implied warranties or conditions of merchantability, fitness for a particular purpose, title
									and non-infringement.</p>
								<p>To the maximum extent permitted by applicable law, in no event shall IQRF Tech s.r.o.
									(Ltd.) and/or its suppliers be liable for any direct, indirect, punitive, incidental,
									special, consequential damages or any damages whatsoever including, without limitation,
									damages for loss of use, data or profits, arising out of or in any way connected with the
									use or performance of the IQRF Tech s.r.o. (Ltd.) website, with the delay or inability to use
									the IQRF Tech s.r.o. (Ltd.) website or related services, the provision of or failure to provide
									services, or for any information, software, products, services and related graphics obtained
									through the IQRF Tech s.r.o. (Ltd.) website, or otherwise arising out of the use of the IQRF
									Tech s.r.o. (Ltd.) website, whether based on contract, tort, negligence, strict liability or
									otherwise, even if IQRF Tech s.r.o. (Ltd.) or any of its suppliers has been advised of the
									possibility of damages. Because some states/jurisdictions do not allow the exclusion or
									limitation of liability for consequential or incidental damages, the above limitation may
									not apply to you. If you are dissatisfied with any portion of the IQRF Tech s.r.o. (Ltd.)
									website, or with any of these terms of use, your sole and exclusive remedy is to discontinue
									using the IQRF Tech s.r.o. (Ltd.) website.</p>
							</Col>
						</Row>
					</Grid>
				</div>
				<Grid>
					<Row className="page__section--with-margin">
						<Col xs={12}>
							<h2>Termination/access restriction</h2>
							<p>IQRF Tech s.r.o. (Ltd.) reserves the right, in its sole discretion, to terminate your access to
								the IQRF Tech s.r.o. (Ltd.) Website and the related services or any portion thereof at any time,
								without notice. GENERAL To the maximum extent permitted by law, this agreement is governed by the
								laws of the Czech Republic. and you hereby consent to the exclusive jurisdiction and venue of
								courts in Prague, Czech Republic, in all disputes arising out of or relating to the use of the
								IQRF Tech s.r.o. (Ltd.) Web Site. Use of the IQRF Tech s.r.o. (Ltd.) Website is unauthorized in
								any jurisdiction that does not give effect to all provisions of these terms and conditions,
								including without limitation this paragraph. You agree that no joint venture, partnership,
								employment, or agency relationship exists between you and IQRF Tech s.r.o. (Ltd.) as a result
								of this agreement or use of the IQRF Tech s.r.o. (Ltd.) Website. IQRF Tech s.r.o. (Ltd.)'s
								performance of this agreement is subject to existing laws and legal process, and nothing contained
								in this agreement is in derogation of IQRF Tech s.r.o. (Ltd.)'s right to comply with governmental,
								court and law enforcement requests or requirements relating to your use of the
								IQRF Tech s.r.o. (Ltd.) Website or information provided to or gathered by IQRF Tech s.r.o. (Ltd.)
								with respect to such use. If any part of this agreement is determined to be invalid or
								unenforceable pursuant to applicable law including, but not limited to, the warranty disclaimers
								and liability limitations set forth above, then the invalid or unenforceable provision will be
								deemed superseded by a valid, enforceable provision that most closely matches the intent of the
								original provision and the remainder of the agreement shall continue in effect. Unless otherwise
								specified herein, this agreement constitutes the entire agreement between the user and IQRF Tech
								s.r.o. (Ltd.) with respect to the IQRF Tech s.r.o. (Ltd.) Website and it supersedes all prior or
								contemporaneous communications and proposals, whether electronic, oral or written, between the
								user and IQRF Tech s.r.o. (Ltd.) with respect to the IQRF Tech s.r.o. (Ltd.) Website. A printed
								version of this agreement and of any notice given in electronic form shall be admissible in
								judicial or administrative proceedings based upon or relating to this agreement to the same extent
								an d subject to the same conditions as other business documents and records originally generated
								and maintained in printed form. It is the express wish to the parties that this agreement and all
								related documents be drawn up in English.</p>
						</Col>
					</Row>
				</Grid>
				<div className="page__section--with-theme">
					<Grid>
						<Row>
							<Col xs={12}>
								<h2>Copyright and trademark notices</h2>
								<p>All contents of the IQRF Tech s.r.o. (Ltd.) Web Site are: Copyright 2017 by IQRF Tech s.r.o. (Ltd.)
									and/or its suppliers. All rights reserved.</p>
								<h2>Trademarks</h2>
								<p>The names of actual companies and products mentioned herein may be the trademarks of their
									respective owners.</p>
								<p>The example companies, organizations, products, people and events depicted herein are fictitious.
									No association with any real company, organization, product, person, or event is intended or
									should be inferred.</p>
								<p>Any rights not expressly granted herein are reserved.</p>
							</Col>
						</Row>
					</Grid>
				</div>
			</div>
		);
	}

}

/**
 * Exporting part of the React.Component file
 */
export default withTranslation()(TermsOfUseScene);