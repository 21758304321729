// @flow
import React from "react";
import {withTranslation} from "react-i18next";
import {Col, Grid, Row} from "react-flexbox-grid";
import "../../Components/Technology/Technology.scss";
import {Helmet} from "react-helmet";

/**
 * @class TechnologyRFPGMHistoryScene
 */
class TechnologyRFPGMHistoryScene extends React.Component {

	/**
	 * Constructor
	 * @param props
	 */
	constructor(props) {
		super(props);

		this.state = {
			showContentSupportedGUI: false,
			showContentGUI: false,
			showContentCommand: false,
			supportedCommand: false,
		};
	}

	/**
	 * Final Render
	 * @returns {*}
	 */
	render() {

		return (
			<Grid className={"page"}>
				<Helmet>
					<title>RFPGM – Release notes - IQRF</title>
				</Helmet>
				<Row className="page__section--bottom-padding">
					<Col xs={12}>
						<h1>RFPGM</h1>
						<h2>Release notes</h2>
						<ul>
							<li><strong>IQRF OS v3.06D</strong> - using IQRF IDE CATS tool only. System packets
								supported. RFPGM in LP mode supported.
							</li>
							<li><strong>IQRF OS v3.04D</strong> - Standard RFPGM version: higher robustness but lower RF
								range, 2 channels, configurable, checking implemented.
							</li>
							<li><strong>IQRF OS v3.02D</strong> - Implemented directly by OS functions for programmed TR
								and by IQRF IDE (Create RF Programmer) for auxiliary TR.
							</li>
							<li><strong>IQRF OS v3.00</strong> - Switching between 868/916 MHz by OS plug-in.</li>
							<li><strong>IQRF OS v2.11</strong> - Lite RFPGMversion. First RFPGM release.<br/>
								Implemented by OS plug-ins for both programmed as well as auxiliary TR transceivers.<br/>
								For 868 MHz only.<br/></li>
						</ul>
					</Col>
				</Row>
			</Grid>
		);
	}
}

/**
 * Exporting part of the React.Component file
 */
export default withTranslation()(TechnologyRFPGMHistoryScene);