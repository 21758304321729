// @flow

// Necessary Evil
import React from "react";
import {withTranslation} from "react-i18next";
import {NavLink, withRouter} from "react-router-dom";
import "./Navigation.scss";
/**
 * @class Navigation
 */
class Navigation extends React.Component {

    /**
     * Final Render
     * @returns {*}
     */
    render() {

        const {t} = this.props;

        return (

            <div className="navigation">
                <h2>{t("title.EShop")}</h2>
                <ul>
                    <li><NavLink to={"/editor/products"}><i className="icon-products" />&nbsp;{t("navigation.Products")}</NavLink></li>
                    <li><NavLink to={"/editor/product-variants"}><i className="icon-products" />&nbsp;{t("navigation.ProductVariants")}</NavLink></li>
                </ul>
                <br/>
                <h2>{t("title.WebContent")}</h2>
                <ul>
                    <li><NavLink to={"/editor/files"}><i className="icon-files" />&nbsp;{t("navigation.Files")}</NavLink></li>
                    <li><NavLink to={"/editor/photos"}><i className="icon-photos" />&nbsp;{t("navigation.Photos")}</NavLink></li>
                    <li><NavLink to={"/editor/news"}><i className="icon-news" />&nbsp;{t("navigation.News")}</NavLink></li>
                    <li><NavLink to={"/editor/events"}><i className="icon-events" />&nbsp;{t("navigation.Events")}</NavLink></li>
                    <li><NavLink to={"/editor/links"}><i className="icon-link" />&nbsp;Links</NavLink></li>
                    <li><NavLink to={"/editor/distributors"}><i className="icon-distributors" />&nbsp;{t("navigation.Distributors")}</NavLink></li>
                    <li><NavLink to={"/editor/case-studies"}><i className="icon-use-case" />&nbsp;{t("navigation.CaseStudies")}</NavLink></li>
					<li><NavLink to={"/editor/webinars"}><i className="icon-webinar" />&nbsp;Webinars</NavLink></li>
					<li><NavLink to={"/editor/startup-package-history"}><i className="icon-markdown" />&nbsp;Startup package history</NavLink></li>
					<li><NavLink to={"/editor/release-notes"}><i className="icon-note" />&nbsp;Release Notes</NavLink></li>
					<li><NavLink to={"/editor/os-functions"}><i className="icon-software" />&nbsp;OS Function</NavLink></li>
					<li><NavLink to={"/editor/os-function-sections"}><i className="icon-software" />&nbsp;OS Function Sections</NavLink></li>
                </ul>
                <br/>
                <h2>{t("title.Other")}</h2>
                <ul>
                    <li><NavLink to={"/"}><i className="icon-back-arrow" />&nbsp;{t("navigation.BackToWebsite")}</NavLink></li>
                    <li onClick={() => {
                        localStorage.removeItem("JWT");
                    }}><NavLink to={"/"}><i className="icon-back-arrow" />&nbsp;Logout</NavLink></li>
                </ul>
            </div>
        );
    }

}


/**
 * Exporting part of the React.Component file
 */
export default withRouter((withTranslation()(Navigation)));