// @flow
import React from "react";
import {withTranslation} from "react-i18next";
import {Col, Grid, Row} from "react-flexbox-grid";
import iqmesh from "../../Components/Technology/img/iqmesh.png";
import "../../Components/Technology/Technology.scss";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {Helmet} from "react-helmet";
import {Link} from "react-router-dom";

/**
 * @class TechnologyIQMeshScene
 */
class TechnologyIQMeshScene extends React.Component {

	/**
	 * Final Render
	 * @returns {*}
	 */
	render() {
		return (
			<div className={"page"}>
				<Helmet>
					<title>IQMESH - IQRF</title>
				</Helmet>
				<Grid>
					<Row>
						<Col xs={12}>
							<h1>IQMESH</h1>
							<p>
								<strong>Routing</strong> extends the <strong>range</strong> and enable to deliver data
								to <strong>difficult areas</strong>.
							</p>
						</Col>
					</Row>
					<Row className="page__section--bottom-padding">
						<Col xs={12} xl={8}>
							<h2>IQMESH routing protocol</h2>
							<p>
								IQRF <strong>Mesh</strong> routing protocol is called <strong>IQMESH</strong><sup>&reg;</sup>.
								It is based on an optimized directional <Link to="/unicast"><strong>flooding</strong></Link>.
								Its main advantages are higher <strong>throughput</strong> and much
								higher <strong>robustness</strong> which primarily becomes apparent in industrial and other
								applications where a high <strong>reliability</strong> is a must.
							</p>
						</Col>
						<Col className="text--center" xs={12} xl={4}>
							<img width="100%" src={iqmesh} alt={iqmesh}/>
						</Col>
					</Row>
				</Grid>
				<div className="page__section--with-theme">
					<Grid>
						<Row>
							<Col>
								<h2>IQMESH features</h2>
								<p>IQMESH is implemented above the <Link to="/technology/os">IQRF OS</Link> by the <Link to="/technology/dpa">DPA</Link> Framework (layer).</p>
								<ul>
									<li>Up to <strong>240 devices</strong> in the network (1 Coordinator + 239 Nodes)</li>
									<li>Up to <strong>240 hops</strong></li>
									<li><Link to="/technology/iqmesh/unicast"><strong>Routing</strong></Link> packets in background</li>
									<li>Low latency (<strong>STD</strong>) as well as low power (<strong>STD+LP</strong>) <a href="https://doc.iqrf.org/IQMESH-Network-deployment/pages/network-types.html" rel="noreferrer" target="_blank">network types</a> supported</li>
									<li>Routing in real time:
										<ul>
											<li>Max. <strong>60 ms</strong> per hop for STD network, based on the payload</li>
											<li>Max. <strong>100 ms</strong> per hop for LP network, based on the payload</li>
										</ul>
									</li>
									<li>Payload up to <strong>64 B</strong></li>
									<li>All communication is automatically <Link to="/technology/security"><strong>encrypted</strong></Link> by <strong>AES-128</strong></li>
									<li><Link to="/technology/iqmesh/autonetwork"><strong>Autonetwork</strong></Link> functionality for automated network build-ups</li>
									<li><Link to="/technology/iqmesh/discovery"><strong>Discovery</strong></Link> functionality to discover / recover topology</li>
								</ul>
							</Col>
						</Row>
					</Grid>
				</div>
				<Grid>
					<Row className="page__section--bottom-padding page__section--top-padding">
						<Col>
							<h2 id="iqrfbonding">Bonding</h2>
							<h3>Including Nodes into IQMESH network</h3>
							<p>IQRF devices can be included (<strong>bonded</strong>) into an IQMESH network in various ways: </p>
							<ul>
								<li>“<strong>Manually</strong>” or <strong>automated</strong></li>
								<li>With or without an <strong>action</strong> on the <strong>Node side</strong></li>
								<li>With or without the <strong>direct RF range</strong> to the Coordinator</li>
							</ul>
							<p><Link to="/technology/iqmesh/local-bonding">» Local bonding</Link> – Adding a single Node into the network</p>
							<p><Link to="/technology/iqmesh/smart-connect">» Smart Connect</Link> – Automated adding a single Node into the network</p>
							<p><Link to="/technology/iqmesh/autonetwork">» Autonetwork</Link> – Automated <strong>creating the complete network</strong> (bonding, Discovery and enumeration)</p>
						</Col>
					</Row>
				</Grid>
				<div className="page__section--with-theme">
					<Grid>
						<Row>
							<Col xs={12}>
								<h2>Discovery</h2>
								<h3>Establishing the routing structure</h3>
								<p>After <strong>installation</strong> or <strong>subsequent changes</strong> in installation</p>
								<p><Link to="/technology/iqmesh/discovery">» Discovery</Link> - Automated establishing the routing structure (paths for hopping)</p>
							</Col>
						</Row>
					</Grid>
				</div>
				<Grid>
					<Row className="page__section--top-padding page__section--bottom-padding">
						<Col>
							<h2>Communication</h2>
							<h3>Basic communication</h3>
							<p>
								<Link to="/technology/iqmesh/unicast">» Unicast</Link> - Packet sent to a <strong>single Node</strong>, with response<br/>
								<Link to="/technology/iqmesh/broadcast">» Broadcast</Link> - Packet sent to <strong>all Nodes</strong>, without responses
							</p>
							<h3>Beaming</h3>
							<p>
								<Link to="/technology/iqmesh/offline-frc">» Beaming</Link> - Data sent from sensors with outstandingly low power (enabling battery lifetime for years).
							</p>
							<h3>Fast Response Command (FRC)</h3>
							<p>Sending a <strong>command</strong> to Nodes and receive <strong>responses</strong> with <strong>small individual data</strong> collected from them in outstandingly short time.</p>
							<p>
								<Link to="/technology/iqmesh/frc">» FRC</Link> - FRC <strong>synchronously</strong> sent from the <strong>Coordinator</strong> to all or selected Nodes.<br/>
								<Link to="/technology/iqmesh/local-frc">» Local FRC</Link> - FRC <strong>asynchronously</strong> sent from a <strong>Node</strong> to control selected Nodes in direct RF range<br/>
								<Link to="/technology/iqmesh/offline-frc">» Offline FRC</Link> - Collection data from <a href="https://doc.iqrf.org/IQMESH-Network-deployment/index.html?page=data-aggregating-from-beaming-sensors.html" target="_blank" rel="noreferrer noopener">Beaming sensors</a> by <a href="https://doc.iqrf.org/IQMESH-Network-deployment/index.html?page=data-aggregating-from-beaming-sensors.html" target="_blank" rel="noreferrer noopener">Aggregating repeaters</a>
							</p>
							<h3>Advanced communication</h3>
							<p>For special purposes, service and maintenance.</p>
							<p>
								<Link to="/technology/iqmesh/dpa-peer-to-peer">» DPA Peer-to-peer (DP2P)</Link> - <strong>Local</strong> control of bonded Nodes from a <strong>non-network device</strong><br/>
								<Link to="/technology/iqmesh/dpa-service-mode">» DPA Service Mode (DSM)</Link> - <strong>Local</strong> maintenance of any network device using a special service tool (<strong>CATS</strong>)
							</p>
						</Col>
					</Row>
				</Grid>
			</div>
		);
	}

}

/**
 * Exporting part of the React.Component file
 */
export default withTranslation()(TechnologyIQMeshScene);
