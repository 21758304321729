// @flow
import React from "react";
import BackendRequest from "../../Models/REST";
import InstantAction from "../../Models/Utils/InstantAction";
import MasterGetter from "../../Models/Utils/MasterGetter";
import { Col, Grid, Row } from "react-flexbox-grid";
import { connect } from "react-redux";
import { GenericItem } from "../../Components/GenericItem";
import { LoadingComponent } from "../../Components";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import "../../Components/Links/Links.scss";

/**
 * @class OSFunctionSectionsSection
 */
class OSFunctionSectionsSection extends React.Component {

	/**
	 * State
	 * @type {{expectingResponse: boolean}}
	 */
	state = {
		expectingResponse: true,
	};

	/**
	 * Fetch app data if necessary
	 */
	fetchData = () => {
		BackendRequest({
			endpoint: "os-function-sections",
			self: this,
		});
	};

	/**
	 * Component Did Mount
	 */
	componentDidMount (): void {
		this.fetchData();
	}

	/**
	 * Create New
	 */
	createNew = () => {
		BackendRequest({
			endpoint: "os-function-section",
			method: "post",
			afterSuccess: (response) => {
				InstantAction.redirect("/editor/os-function-section/" + response.data.o_s_function_section.id);
			},
		});
	};

	/**
	 * Final Render
	 * @returns {*}
	 */
	render () {

		const {
			entities,
		} = this.props;

		const session = MasterGetter.getSession(entities);
		const sections = session.OSFunctionSection.all().orderBy(["ordering"], ["asc"]);
		if (this.state.expectingResponse) {
			return <LoadingComponent/>;
		}

		return (
			<Grid fluid>
				<Row>
					<Col xs={12}>
						<h1>OS Function Sections</h1>
						<div>
							<button onClick={this.createNew} className="btn">Create New</button>
						</div>
						<br/><br/>
					</Col>
					<Col xs={12}>
						{sections.toModelArray().map(section => {
							return <>
								<GenericItem
									item={section}
									nameProperty={"name"}
									onClick={() => InstantAction.redirect("/editor/os-function-section/"+section.id)}
									style={{
									padding: "1rem 0",
									fontWeight: 800,
								}} />
							</>;
						})}

						<div>
							{sections.count() > 0 ? "" : "No sections"}
						</div>
					</Col>
				</Row>
			</Grid>
		);
	}

}

/**
 *
 * @param state
 * @return {{entities: *}}
 */
const mapStateToProps = state => (
	{
		entities: state.entities,
	});

/**
 * Exporting part of the React.Component file
 */
export default withRouter(connect(mapStateToProps)(withTranslation()(OSFunctionSectionsSection)));