// @flow

// Necessary Evil
import React from "react";
import PropTypes from "prop-types";
import {withTranslation} from "react-i18next";
import {Col} from "react-flexbox-grid";
import YouTube from "react-youtube";
// Import component CSS
import "./VideoItem.scss";


/**
 * @class VideoItem
 */
class VideoItem extends React.Component {

    /**
     * PropTypes
     * @type {{children: shim}}
     */
    static propTypes = {
        video: PropTypes.any,
    };

    /**
     * Final Render
     * @returns {*}
     */
    render() {

        const {video} = this.props;
        const opts = {
			width: '600',
			height: '320',
		};

        return <Col xs={12} lg={6} className="video-item" style={{ overflow: "hidden"}}>
            <h2>{video.ordering}. {video.name}</h2>
            <YouTube
                videoId={video.youtube_url.split(".be/")[1]}
                containerClassName={"video-item__wrapper"}
                opts={opts}
            />
            <p>{video.description}</p>
        </Col>;
    }
}

export default withTranslation()(VideoItem);
