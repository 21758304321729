// @flow

import PropTypes from "prop-types";
import {attr} from "redux-orm";

import CRUDModel from "../ORM/CRUDModel";


export default class News extends CRUDModel {

}

News.modelName = "News";

News.fields = {
    id: attr(), // non-relational field for any value; optional but highly recommended
    content: attr(),
    perex: attr(),
    published: attr(),
};

News.propTypes = {
    content: PropTypes.string,
};

// News.defaultProps = {
// };
//
// News.endpoint = "/group";

export const ADD_NEWS = "ADD_NEWS";
export const REMOVE_NEWS = "REMOVE_NEWS";
export const UPDATE_NEWS = "UPDATE_NEWS";