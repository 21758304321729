// @flow

// Necessary Evil
import React from "react";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import {withRouter} from "react-router-dom";
import MasterGetter from "../../Models/Utils/MasterGetter";
import BackendRequest from "../../Models/REST";
import PropTypes from "prop-types";

/**
 * @class LinksModule
 */
class LinksModule extends React.Component {

	/**
	 * PropTypes
	 * @type {{page: *}}
	 */
	static propTypes = {
		page: PropTypes.string,
	};

	/**
	 * State
	 * @type {{}}
	 */
	state = {
		expectingResponse: true,
	};

	/**
	 * Fetch Data
	 */
	fetchData = () => {

		const {page} = this.props;

		BackendRequest({
			endpoint: "links/" + page,
			self: this,
		});
	};

	/**
	 * Component did mount
	 */
	componentDidMount(): void {
		this.fetchData();
	}

	/**
	 * Final Render
	 * @returns {*}
	 */
	render() {

		const {
			entities,
			page,
		} = this.props;

		const session = MasterGetter.getSession(entities);
		const links = session.Link.all().filter(item => item.page === page).orderBy(["ordering"], ["asc"]);

		if (links.count() === 0 || this.state.expectingResponse) {
			return null;
		}

		return (<div className="related-downloads page__section--bottom-padding">
			<div>
				<h2>Links and Downloads</h2>
			</div>
			<div>
				{links.toModelArray().map(item => {

					if (item.is_modal) {
						return <div
							className="related-downloads__item"
							key={item.id}
						>
						</div>;
					} else {
						return <a
							target={(item.external ? "_blank" : "_self")}
							className="related-downloads__item"
							key={item.id}
							href={item.url}
							rel="noreferrer noopener"
						>
							<div className="link-item__icon">
								<img src={process.env.PUBLIC_URL + "/icon/" + item.icon + ".png"} alt={"icon"}/>
							</div>
							<strong>{item.name}</strong> &nbsp;
							<span style={{color: "black"}}>
							{item.description}
						</span>
						</a>;
					}
				})}
			</div>
		</div>);
	}

}

/**
 * This function maps the state to a
 * prop called `state`.
 * In larger apps it is often good
 * to be more selective and only
 * map the part of the state tree
 * that is necessary.
 */
const mapStateToProps = state => (
	{
		app: state.app,
		entities: state.entities,
	});

/**
 * Exporting part of the React.Component file
 */
export default withRouter(connect(mapStateToProps)(withTranslation()(LinksModule)));
