// @flow
import React from "react";
import {withTranslation} from "react-i18next";
import {Col, Grid, Row} from "react-flexbox-grid";
import {connect} from "react-redux";
import BackendRequest from "../../Models/REST";
import MasterGetter from "../../Models/Utils/MasterGetter";
import MyDropZone from "../../Components/MyDropzone";
import {PhotoItem} from "../../Components/Photos";
import {PhotoForm} from "../../Forms";
import SearchBar from "../../Components/SearchBar/SearchBar";


/**
 * @class PhotosScene
 */
class PhotosScene extends React.Component {

	/**
	 * State
	 * @type {{expectingResponse: boolean}}
	 */
	state = {
		expectingResponse: true,
		selectedSection: 0,
		selectedPhoto: null,
		name: ""
	};

	/**
	 * Fetch data
	 */
	fetchData() {

		BackendRequest({
			endpoint: "photos",
			self: this,
		});

	}

	/**
	 * On Drop Action
	 * @param acceptedFiles
	 * @param rejectedFiles
	 */
	onDrop = (acceptedFiles, rejectedFiles) => {

		acceptedFiles.forEach(file => {

			let filenameSpliced = file.name.split(".");
			let fileType = filenameSpliced[filenameSpliced.length - 1];

			/**
			 * Payload
			 * @type {{file: *, filename: *, section: number, type: (*|string)}}
			 */
			const data = {
				file: file,
				section: this.state.selectedSection,
				filename: file.name,
				type: fileType,
			};

			BackendRequest({
				method: "post",
				endpoint: "photo",
				payload: data,
				self: this,
				afterSuccess: (response) => {
					const data = response.data;

					// Catch photo id from backend
					this.setState({
						selectedPhoto: data.photo.id,
					});
				}
			});
		});

	};

	/**
	 * On Section select
	 * @param event
	 */
	onSelectChange = (event) => {

		const target = event.target;
		const value = target.type === "checkbox" ? target.checked : target.value;
		const name = target.name;

		this.setState({
			[name]: value
		}, () => {

		});
	};

	/**
	 * ComponentWillMount
	 */
	componentDidMount() {

		this.fetchData();

	}

	onPhotoClick = (photoId) => {

		this.setState({
			selectedPhoto: photoId
		});

	};

	cancelEdit = () => {
		this.setState({
			selectedPhoto: null
		});
	};

	/**
	 * Final Render
	 * @returns {*}
	 */
	render() {

		const {
			app,
			entities,
			t,
		} = this.props;
		const session = MasterGetter.getSession(entities);
		const photos = session.Photo.all().filter(item => {
			// Filter search
			return item.name.toLowerCase().includes(app.search.toLowerCase());

		});
		const photoSections = session.PhotoSection.all();
		const allPhotos = photos.all();
		const selectedPhoto = session.Photo.withId(this.state.selectedPhoto);

		return (
			<Grid fluid>
				<Row>
					<Col xs={12}>
						<h1>{t("title.Photos")}</h1>
					</Col>
				</Row>
				<Row>
					<Col xs={12} className={"form"}>
						<h2>{t("title.PhotoUpload")}</h2>
						<select name="selectedSection" onChange={this.onSelectChange}
								value={this.state.selectedSection}>
							<option value={0} disabled>{t("label.PleaseSelectSectionBeforeUpload")}</option>
							{photoSections.toModelArray().map(section => {
								return <option value={section.id}>{t("option." + section.name)}</option>;
							})}
						</select>
						{
							this.state.selectedSection !== 0 &&
							<MyDropZone onDrop={this.onDrop} accept={"image/*"}/>
						}
						<br/>
						<hr/>
						<br/>
					</Col>
				</Row>
				{this.state.selectedPhoto !== null &&
				<div style={{background: "#eee", padding: "2rem"}}>
					<PhotoForm photo={selectedPhoto}
							   afterSuccess={this.cancelEdit}
							   onCancel={this.cancelEdit}
					/>
				</div>
				}
				<Row>
					<Col xs={12}>
						<SearchBar/>
						<br/>
						{photoSections.toModelArray().map(section => {
							const photosInSection = allPhotos.filter(photo => photo.section === section.id);
							return <div>
								<h2>Photos in {section.name} ({photosInSection.count()})</h2>
								<div className="photo-item__wrapper">
									{photosInSection.toModelArray().map(photo => {
										return <PhotoItem key={photo.id} photo={photo}
														  handleClick={this.onPhotoClick}/>;
									})}

								</div>
							</div>;
						})}
					</Col>
				</Row>
			</Grid>
		);
	}
}

/**
 * This function maps the state to a
 * prop called `state`.
 * In larger apps it is often good
 * to be more selective and only
 * map the part of the state tree
 * that is necessary.
 */
const mapStateToProps = state => (
	{
		app: state.app,
		entities: {
			PhotoSection: state.entities.PhotoSection,
			Photo: state.entities.Photo,
		},
	});
/**
 * Exporting part of the React.Component file
 */
export default connect(mapStateToProps)(withTranslation()(PhotosScene));