// @flow

import React from "react";
import { Provider as ReduxProvider } from "react-redux";
import { I18nextProvider } from "react-i18next"; // as we build ourself via webpack
import { BrowserRouter, Route, Switch } from "react-router-dom";
// Good
import { store } from "../Config/store";
import i18n from "../Config/I18next";
import MasterGetter from "../Models/Utils/MasterGetter";
import AppContainer from "./AppContainer";
import InstantAction from "../Models/Utils/InstantAction";
import { RESET_STORE } from "./App.actions";

import "./App.scss";
import EditorSceneWrapper from "../Components/Wrappers/EditorSceneWrapper/EditorSceneWrapper";
import PublicSceneWrapper from "../Components/Wrappers/PublicSceneWrapper";
import BackendRequest from "../Models/REST";

/**
 * @class App
 */
export default class App extends React.Component {

	/**
	 * Constructor
	 * @param props
	 */
	constructor (props) {
		super(props);
		this.checkRedirect();
	}

	/**
	 * Reset Redux Store for App
	 */
	static resetStore = () => {
		InstantAction.dispatch({
			type: RESET_STORE,
		});
	};

	/**
	 * Is User Logged
	 * @return {boolean}
	 */
	static isUserLogged = () => {

		const app = MasterGetter.getApp();

		// Should be string because of UUID4
		return (typeof app.loggedUser === "string");

	};

	/**
	 * Try redirect
	 */
	checkRedirect = () => {

		const url = window.location.pathname + window.location.search + window.location.hash;

		if (url !== "/") {
			BackendRequest({
				endpoint: "check-redirect",
				method: "post",
				payload: {
					url: url,
				},
				afterSuccess: (response) => {
					if (response.data.redirect) {
						// Redirect to new URL
						const newURL = response.data.redirect;
						const currentURL = window.location.href;
						console.log("Redirecting from ", currentURL, " to ",newURL);
						if(currentURL !== newURL){
							window.location.replace(response.data.redirect);
						}

					} else {
						// Do nothing
					}
				},
			});
		}

	};

	/**
	 * Final Render
	 * @returns {*}
	 */
	render () {
		return <ReduxProvider store={store}>
			<I18nextProvider i18n={i18n}>
				<BrowserRouter>
					<AppContainer className="app-wrapper">
						<Switch>
							<Route key={"editor"} path={"/editor"} component={EditorSceneWrapper}/>
							<Route key={"public"} path={"/"} component={PublicSceneWrapper}/>
						</Switch>
					</AppContainer>
				</BrowserRouter>
			</I18nextProvider>
		</ReduxProvider>;

	}
}
