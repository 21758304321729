// @flow

// Necessary Evil
import React from "react";
import PropTypes from "prop-types";
import {withTranslation} from "react-i18next";
import {CopyToClipboard} from "react-copy-to-clipboard";
// Import component CSS
import "./PhotoItem.scss";
import BackendRequest from "../../../Models/REST";
import InstantAction from "../../../Models/Utils/InstantAction";
import {REMOVE_PHOTO} from "../../../Models/Models/Photo";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import DeleteModal from "../../DeleteModal"; // This only needs to be imported once in your app

/**
 * @class PhotoItem
 */
class PhotoItem extends React.Component {

    /**
     * PropTypes
     * @type {{children: shim}}
     */
    static propTypes = {
        children: PropTypes.any,
        photo: PropTypes.any.isRequired,
        handleClick: PropTypes.func,
    };

    /**
     * Default Props
     * @type {{handleClick: PhotoItem.defaultProps.handleClick}}
     */
    static defaultProps = {
        handleClick: () => {
        }
    };

	/**
	 * State
	 * @type {{isOpen: boolean, modalIsOpen: boolean}}
	 */
	state = {
        isOpen: false,
		modalIsOpen: false,
    };

	/**
	 * Delete
	 */
    delete = () => {

        const {photo} = this.props;

        BackendRequest({
            endpoint: "photo/" + photo.id,
            method: "DELETE",
            afterSuccess: () => {
                InstantAction.dispatch({
                    type: REMOVE_PHOTO,
                    payload: photo.id,
                });

                this.props.handleClick(null);
            }
        });

    };

    /**
     * Final Render
     * @returns {*}
     */
    render() {

        const {
            handleClick,
            photo,
            t
        } = this.props;

        return <div
            className="photo-item"
            style={{
                color: "black",
                backgroundImage: "url(" + process.env.REACT_APP_BACKEND_SERVER + photo.url + ")",
                backgroundSize: "contain",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "50% 50%",
            }}>
            <div className="photo-item__light-box" onClick={() => { this.setState({
                isOpen: true,
            });}}>
            </div>

            <div className="photo-item__action-panel">
                <button onClick={() => handleClick(photo.id)}>
                    <i className="icon-edit"/>
                </button>

                <CopyToClipboard
                    text={"<img src=\"" + process.env.REACT_APP_BACKEND_SERVER + photo.url + "\" alt=\"" + photo.name + "\" />"}
                >
                    <button title={t("label.CopySourceCode")}>
                        <i className="icon-code"/>
                    </button>
                </CopyToClipboard>

                <button onClick={() => {
                	this.setState({
						modalIsOpen: true,
					});
				}}>
                    <i className="icon-delete"/>
                </button>
            </div>
            {this.state.isOpen && (
                <Lightbox
                    mainSrc={process.env.REACT_APP_BACKEND_SERVER + photo.url}
                    onCloseRequest={() => this.setState({ isOpen: false })}
                />
            )}
            <DeleteModal parent={this} handleDelete={this.delete} isOpen={this.state.modalIsOpen} />
        </div>;
    }
}

export default withTranslation()(PhotoItem);