// @flow
import React from "react";
import {withTranslation} from "react-i18next";
import {Col, Grid, Row} from "react-flexbox-grid";
import BackendRequest from "../../Models/REST";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import MasterGetter from "../../Models/Utils/MasterGetter";
import LoadingComponent from "../../Components/LoadingComponent";
import {CaseStudyForm} from "../../Forms";
import InstantAction from "../../Models/Utils/InstantAction";
import {REMOVE_CASE_STUDY} from "../../Models/Models/CaseStudy";
import DeleteModal from "../../Components/DeleteModal";

/**
 * @class CaseStudiesScene
 */
class CaseStudiesScene extends React.Component {

	/**
	 * State
	 * @type {{expectingResponse: boolean}}
	 */
	state = {
		expectingResponse: true,
		modalIsOpen: false,
	};

	/**
	 * Fetch data
	 */
	fetchData = () => {

		const {match} = this.props;
		const studyId = match.params.id;

		BackendRequest({
			endpoint: "case-study/" + studyId + "/edit",
			self: this,
		});

	};

	/**
	 * Component did mount
	 */
	componentDidMount() {
		this.fetchData();
	}

	/**
	 * Handle Delete
	 */
	handleDelete = () => {

		const {
			match
		} = this.props;
		const studyId = match.params.id;

		BackendRequest({
			endpoint: "case-study/" + studyId + "/edit",
			method: "delete",
			afterSuccess: () => {

				InstantAction.dispatch({
					type: REMOVE_CASE_STUDY,
					payload: studyId
				});

				InstantAction.redirect("/editor/case-studies");
			}
		});

	};

	/**
	 * Final Render
	 * @returns {*}
	 */
	render() {

		const {
			t,
			entities,
			match,
		} = this.props;

		const studyId = match.params.id;
		const session = MasterGetter.getSession(entities);
		const study = session.CaseStudy.withId(studyId);
		const isNew = study === null;


		if (this.state.expectingResponse) {
			return <LoadingComponent/>;
		}

		return (
			<Grid fluid>
				<Row>
					<Col xs={12} lg={6}>
						<h1>{t("title.CaseStudy")}: {isNew ? "[new]" : study.name}</h1>
					</Col>
					<Col xs={12} lg={6} className="text--right">
						<br/>
						<button className="btn" onClick={() => window.history.back()}>Cancel <i className="icon-back-arrow"/></button>
						&nbsp;
						<button className={"btn btn--danger"} onClick={(e) => {
							e.preventDefault();
							this.setState({
								modalIsOpen: true,
							});

						}}>{t("button.Delete")}</button>
					</Col>
					<Col xs={12}>
						<hr/>
					</Col>
					<Col xs={12} className="page__section--bottom-padding">
						<CaseStudyForm study={study}/>
					</Col>
				</Row>
				<DeleteModal
					parent={this}
					handleDelete={this.handleDelete}
					isOpen={this.state.modalIsOpen}
				/>
			</Grid>
		);
	}

}

/**
 * Map state to props
 * @param state
 * @return {{entities: {CaseStudy: (*|CaseStudy), Photo: (*|Photo)}}}
 */
const mapStateToProps = state => (
	{
		entities: {
			Photo: state.entities.Photo,
			CaseStudy: state.entities.CaseStudy,
		},
	});

/**
 * Exporting part of the React.Component file
 */
export default withRouter(connect(mapStateToProps)(withTranslation()(CaseStudiesScene)));