// @flow

// Necessary Evil
import React from "react";
import PropTypes from "prop-types";
import {withTranslation} from "react-i18next";
import Markdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import rehypeSanitize from "rehype-sanitize";
import remarkGfm from "remark-gfm";
import InstantAction from "../../../Models/Utils/InstantAction";
// Import component CSS
import "./ReleaseNoteItem.scss";

/**
 * @class ReleaseNoteItem
 */
class ReleaseNoteItem extends React.Component {

    /**
     * Prop Types
     * @type {{children: *}}
     */
    static propTypes = {
        children: PropTypes.any,
        onClick: PropTypes.func,
        item: PropTypes.any,
    };

	/**
	 * Default Props
	 * @type {{nameProperty: string}}
	 */
	static defaultProps = {
        nameProperty: "name",
    };

	/**
	 *
	 */
	handleClick = () => {

		const {item} = this.props;

		InstantAction.redirect("/editor/release-note/"+item.id);

	};

    /**
     * Final Render
     * @returns {*}
     */
    render() {

        const {
            item,
        } = this.props;

        return <div className="release-note-item" onClick={this.handleClick}>
	        <i>{item.subject ? item.subject.name : ""}</i>&nbsp; {item.ordering}.&nbsp; <Markdown
	          rehypePlugins={[rehypeRaw, rehypeSanitize]}
	          remarkPlugins={[remarkGfm]}
	          children={item.title}
          />
        </div>;
    }
}

export default withTranslation()(ReleaseNoteItem);
