// @flow
import React from "react";
import {withTranslation} from "react-i18next";
import {Col, Grid, Row} from "react-flexbox-grid";
import DPACompatibility from "../../Components/DPACompatibility/DPACompatibility";
import {Helmet} from "react-helmet";

/**
 * @class TechnologyDPAOsDpaCompatibilityScene
 */
class TechnologyDPAOsDpaCompatibilityScene extends React.Component {


    /**
     * Final Render
     * @returns {*}
     */
    render() {

        return (
            <Grid className={"page"}>
                <Helmet>
                    <title>DPA - Compatible versions of IQRF OS and DPA - IQRF</title>
                </Helmet>
                <Row> 
					<Col xs={12}>
                        <h1>IQRF OS and DPA compatibility</h1>
                        <p>The following table shows the compatible versions of IQRF OS and DPA.</p>
                    </Col>
                    <Col xs={10} xl={10}>
                        <DPACompatibility/>
                    </Col>
                </Row>
            </Grid>
        );
    }

}

/**
 * Exporting part of the React.Component file
 */
export default withTranslation()(TechnologyDPAOsDpaCompatibilityScene);