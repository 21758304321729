// @flow
import React from "react";
import {withTranslation} from "react-i18next";
import {Col, Grid, Row} from "react-flexbox-grid";
import BackendRequest from "../../Models/REST";
import MasterGetter from "../../Models/Utils/MasterGetter";
import {Link, withRouter} from "react-router-dom";
import {connect} from "react-redux";
import SearchBar from "../../Components/SearchBar/SearchBar";
import ProductVariantItem from "../../Components/Editor/Products/ProductVariantItem";

/**
 * @class ProductVariantsScene
 */
class ProductVariantsScene extends React.Component {

    /**
     * State
     * @type {{expectingResponse: boolean}}
     */
    state = {
        expectingResponse: true,
        showArchived: false,
    };

    /**
     * Fetch app data if necessary
     */
    fetchData = () => {

        BackendRequest({
			self: this,
            endpoint: "products-variants/all",
        });

    };

    /**
     * Component did mount
     */
    componentDidMount() {

        BackendRequest({
            endpoint: "navigation-data",
        });


        if (localStorage.getItem("edit-archive")) {
            this.setState({
                showArchived: localStorage.getItem("edit-archive") === "true"
            });
        }

        this.fetchData();

    }

    /**
     * Final Render
     * @returns {*}
     */
    render() {

        const {
            t,
            productVariants,
            categories,
            searchString
        } = this.props;

        const variants = productVariants.filter(item => (
            item.name.toLowerCase().includes(searchString.toLowerCase())) && (item.is_archived === this.state.showArchived)).orderBy(["ordering"], ["desc"]);

        return (
            <Grid fluid>
                <Row>
                    <Col xs={12}>
                        <h1>{t("title.ProductVariants")}</h1>
                    </Col>
                    <Col xs={12}>
                        <div className="page__section--bottom-padding">
                            <Link className="btn" to={"/editor/product-variant"}>+ {t("button.CreateNew")}</Link>
                        </div>
                    </Col>
                    <Col xs={12}>
                        <SearchBar/>
                        <br/>
                        <div className="text--center">
                            <button
                                onClick={() => {
                                    localStorage.setItem("edit-archive", !this.state.showArchived);
                                    this.setState({
                                        showArchived: !this.state.showArchived
                                    });
                                }}
                                className={"btn"}>{this.state.showArchived ? "Back from Archive" : "Archived Only"}</button>
                        </div>
                    </Col>

                    <Col xs={12}>
                        {searchString.length === 0 ? categories.toModelArray().map(category => {

                            let displayVariants = variants.filter(variant => (variant.category === category.id));

                            return <div key={category.id}>
                                <h3>{category.name}</h3>
                                <div style={{
                                    display: "flex",
                                    flexWrap: "wrap"
                                }}>
                                    {displayVariants.toModelArray().map(variant => {
                                        return <ProductVariantItem key={variant.id} variant={variant}/>;
                                    })}
                                </div>
                            </div>;

                        }) : variants.toModelArray().map(variant => {

                            return <ProductVariantItem key={variant.id} variant={variant}/>;
                        })}

                    </Col>

                    {/*<Col xs={12}>*/}
                    {/*    {app.search.length === 0 ? products.toModelArray().map(product => {*/}
                    {/*        return <div>*/}
                    {/*            <h3>{product.name}</h3>*/}
                    {/*            <div>*/}
                    {/*                {variants.filter(item => item.series === product.id).toModelArray().map(variant => {*/}
                    {/*                    return <ProductVariantItem key={variant.id} variant={variant}/>;*/}
                    {/*                })}*/}
                    {/*            </div>*/}
                    {/*        </div>;*/}
                    {/*    }) : variants.toModelArray().map(variant => {*/}
                    {/*        return <ProductVariantItem key={variant.id} variant={variant}/>;*/}
                    {/*    })}*/}
                    {/*    {variants.count() === 0 && !expectingResponse ? "No variants" : ""}*/}
                    {/*</Col>*/}
                </Row>
            </Grid>
        );
    }

}

/**
 *
 * @param state
 * @return {{entities: *}}
 */
const mapStateToProps = (state) => {

    const session = MasterGetter.getSession(state.entities);
    const categories = session.ProductCategory.all();
    const variants = session.Product.all();

    console.log(categories, "categhorie");

    return {
        categories: categories,
        productVariants: variants,
        searchString: state.app.search,
    };
};

/**
 * Exporting part of the React.Component file
 */
export default withRouter(connect(mapStateToProps)(withTranslation()(ProductVariantsScene)));