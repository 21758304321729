// @flow
import React from "react";
import {withTranslation} from "react-i18next";
import {Col, Grid, Row} from "react-flexbox-grid";
import {Link} from "react-router-dom";
import pdf_ico from "../../App/extra-icons/pdf_ico.png";
import {Helmet} from "react-helmet";
import i_ico from "../../App/extra-icons/i_ico.png";

/**
 * @class GetStartedScene
 */
class GetStartedScene extends React.Component {


	/**
	 * Final Render
	 * @returns {*}
	 */
	render() {

		const {t} = this.props;

		return (
			<Grid className={"page"}>
				<Helmet>
					<title>IQRF - {t("title.HowToStart")}</title>
				</Helmet>
				<Row className="page page__section--bottom-padding">
					<Col xs={12}>
						<h1>{t("title.HowToStart")}</h1>
						<h2>The first IQRF design</h2>
						<p>To start working with IQRF, you need:</p>
						<ul>
							<li><strong>Hardware:</strong> one of IQRF
								<Link to={"/products/development-tools/development-sets"}> development sets </Link>
								, e.g.
								<Link to={"/product-detail/ds-start-05"}> DS-START-05 </Link>
								or
								<Link to={"/product-detail/ds-dpa-03"}> DS-DPA-03 </Link>
								<a href="https://www.youtube.com/embed/SXImYVFfNq0?showinfo=0&rel=0&autoplay=0"
								   rel="noopener noreferrer"
								   target="_blank"> <i className="icon-youtube-logo"/></a></li>
							<li><strong>Software:</strong>
								<Link to={"/technology/ide"}> IQRF IDE </Link>
								development environment installed.
								<a href="https://youtu.be/LTqeZ1pANKA"
								   rel="noopener noreferrer"
								   target="_blank"> <i className="icon-youtube-logo"/></a>
							</li>
						</ul>
						<p><strong>Since IQRF OS v4.06D and IDE v4.63, the IQRF Startup package is not released any more. It is replaced with the <a href="https://doc.iqrf.org/IQRF-IDE-Help/menu_project_new_project.htm?zoom_highlightsub=New+Project"
								   rel="noopener noreferrer"
								   target="_blank">New Project</a> wizard in IQRF IDE connected to the <a href="https://repository.iqrfalliance.org/doc/"
								   rel="noopener noreferrer"
								   target="_blank">IQRF Repository</a>.</strong> Files for OTA upgrading IQRF OS & DPA are available via an <a href="https://doc.iqrf.org/IQRF-IDE-Help/index.html?window_ntw_manager_control_upload.htm" rel="noopener noreferrer"
								   target="_blank">Assistant</a> in IQRF IDE.</p>
						<hr/>
						<p>Depending on the type of your application, select one of the two approaches:</p>
						<ul>
							<li><strong>Networking</strong> (
								<a href="https://en.wikipedia.org/wiki/Wireless_mesh_network"
								   rel="noopener noreferrer"
								   target="_blank">Mesh</a>,
								<a href="https://en.wikipedia.org/wiki/Network_topology#Star"
								   rel="noopener noreferrer"
								   target="_blank"> Star</a>)
								<br/>
								Using ready-to-use plug-ins under the
								<Link to={"/technology/dpa"}> DPA </Link>
								layer (the higher application level), often <strong>without programming</strong>.
								<a href="https://www.youtube.com/embed/UynZCPe2k3U?rel=0"
								   rel="noopener noreferrer"
								   target="_blank"> <i className="icon-youtube-logo"/></a>
								<a href="https://www.youtube.com/embed/nJgEx_oRxWY?showinfo=0&rel=0"
								   rel="noopener noreferrer"
								   target="_blank"> <i className="icon-youtube-logo"/></a>
							</li>
							<li><strong>Non-networking</strong> (
								<a href="https://en.wikipedia.org/wiki/Peer-to-peer"
								   rel="noopener noreferrer"
								   target="_blank">Peer-to-peer</a>)
								<br/>
								Programming TR transceivers directly under
								<Link to={"/technology/os"}> OS </Link>
								(IQRF <strong>operating system</strong>, the lower application level),
								in <strong>C</strong> language.
								<a href="https://www.youtube.com/watch?v=E55BfWXo2_0"
								   rel="noopener noreferrer"
								   target="_blank"> <i className="icon-youtube-logo"/></a>
							</li>
						</ul>
						<p>Follow the step-by-step instructions in IQRF
							<Link to="/download/bacd7c72-2382-41c1-ac1d-e9377ce0ae31"
							   target="_self"> Quick start guide</Link>.
						</p>
						<hr/>
						<h2>Design service</h2>
						<h3>Training</h3>
						<p>To speed up the startup, a one-day
							<Link to={"/support/training"}> training </Link>
							is recommended. It can be arranged with respect to your specific needs.
						</p>
						<h3>Consultancy</h3>
						<p>Before starting your commercial project, you can
							<Link to={"/support/contact-support"}> contact </Link>
							our technical support to consult how to proceed in the most effective way.
						</p>
						<h3>FAE</h3>
						<p>Speed up your first commercial design with our
							<Link to={"/support/fae"}> FAE </Link>
							(application) engineer. Then you can follow-up to your next designs yourself.
						</p>
						<h3>Design house</h3>
						<p>Or you can get a complete turn-key application done by a local <Link to={"/sales/design-houses"}>Design house</Link>.</p>
						<hr/>
						<h2>Links</h2>
						<div className="table--wrapper">
							<table className="table--with-links">
								<tbody>
								<tr>
									<td><Link to="/download/bacd7c72-2382-41c1-ac1d-e9377ce0ae31"
										   target="_self"><img alt={"icon"} src={pdf_ico}/> IQRF Quick Start guide </Link>
										- Step-by-step instructions
									</td>
								</tr>
								<tr>
									<td><Link to="/download/2b4e3da9-f9f5-4853-87b2-4733dcd5022f"
										   target="_self"><img alt={"icon"} src={pdf_ico}/> IQRF Network deployment technical guide </Link>
										- for networking applications only
									</td>
								</tr>
                <tr>
									<td><a href="https://doc.iqrf.org/DpaTechGuide/examples/"
										   rel="noreferrer noopener" target="_blank"><img alt={"icon"} src={i_ico}/> Examples and headers </a>
										- Networking (DPA)
									</td>
								</tr>
                <tr>
									<td><a href="https://doc.iqrf.org/os-examples/os-examples.html"
										   rel="noreferrer noopener" target="_blank"><img alt={"icon"} src={i_ico}/> Examples and headers </a>
										-  Non-networking (OS)
									</td>
								</tr>
								<tr>
									<td><Link to={"/how-to-start/startup-package-history"}>» Release notes</Link></td>
								</tr>
								</tbody>
							</table>
						</div>
					</Col>
				</Row>
			</Grid>

		);
	}

}

/**
 * Exporting part of the React.Component file
 */
export default withTranslation()(GetStartedScene);
