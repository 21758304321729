// @flow
import React from "react";
import {withTranslation} from "react-i18next";
import LoadingComponent from "../../Components/LoadingComponent";
import BackendRequest from "../../Models/REST";
import {connect} from "react-redux";
import MasterGetter from "../../Models/Utils/MasterGetter";
import moment from "moment";

import "../../Components/Trademarks/Trademarks.scss";
import {Col, Grid, Row} from "react-flexbox-grid";
import {Helmet} from "react-helmet";

/**
 * @class TrademarksScene
 */
class TrademarksScene extends React.Component {

    /**
     * State
     * @type {{expectingResponse: boolean}}
     */
    state = {
        expectingResponse: true,
    };

    /**
     * Fetch data from
     */
    fetchData = () => {

        BackendRequest({
            endpoint: "trademarks",
            self: this
        });

    };

    /**
     * Component will mount
     */
    componentDidMount() {
        this.fetchData();
    }

    /**
     * Final Render
     * @returns {*}
     */
    render() {

        const {t, entities} = this.props;

        const session = MasterGetter.getSession(entities);
        const trademarks = session.Trademark.all().orderBy(["filling_date"], ["asc"]);

        return (
            <Grid className={"page"}>
                <Helmet>
                    <title>{t("title.Trademarks")} - IQRF</title>
                </Helmet>
                <Row className="page__section--bottom-padding">
                    <Col xs={12}>
                        <h1>{t("title.Trademarks")}</h1>
                        <div className={"trademarks"}>

                            <div className="trademarks-table">

                                <div className="trademarks-table__row trademarks-table__row--heading">
                                    <div>{t("label.Country")}</div>
                                    <div>{t("label.SerialNo")}</div>
                                    <div>{t("label.RegNo")}</div>
                                    <div>{t("label.Trademark")}</div>
                                    <div>{t("label.Description")}</div>
                                    <div>{t("label.Type")}</div>
                                    <div>{t("label.FillingDate")}</div>
                                    <div>{t("label.Figure")}</div>
                                </div>
                                {this.state.expectingResponse ? <div className={"text--center"}><LoadingComponent/></div> : ""}
                                {trademarks.toModelArray().map(trademark => {

                                    let formattedDate = moment(trademark.filling_date).isValid() ? moment(trademark.filling_date).format("DD MMM YYYY") : "";

                                    return <div className="trademarks-table__row" key={trademark.id}>
                                        <div>{trademark.country !== null ? trademark.country.name : ""}</div>
                                        <div>{trademark.serial}</div>
                                        <div>{trademark.registration}</div>
                                        <div><strong>{trademark.trademark}</strong></div>
                                        <div>{trademark.description}</div>
                                        <div>{t("option." + trademark.status.name)}</div>
                                        <div>{formattedDate}</div>
                                        <div className={"text--center"}>{trademark.figure !== null ? <img
                                            src={process.env.REACT_APP_BACKEND_SERVER + trademark.figure.url}
                                            alt={trademark.trademark}
                                        /> : ""}</div>
                                    </div>;
                                })}
                            </div>

                        </div>
                    </Col>
                </Row>
            </Grid>

        );
    }

}

/**
 *
 * @param state
 * @return {{entities: {Trademark: *}}}
 */
const mapStateToProps = state => (
    {
        entities: {
            Photo: state.entities.Photo,
            Trademark: state.entities.Trademark,
            TrademarkCountry: state.entities.TrademarkCountry,
            TrademarkType: state.entities.TrademarkType,
        },
    });

/**
 * Exporting part of the React.Component file
 */
export default connect(mapStateToProps)(withTranslation()(TrademarksScene));

