// @flow

// Necessary Evil
import React from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import Floater from "react-floater";
// Import component CSS
import "./ProductStatus.scss";

/**
 * @class ProductStatus
 */
class ProductStatus extends React.Component {

	/**
	 * PropTypes
	 * @type {{children: shim}}
	 */
	static propTypes = {
		children: PropTypes.any,
		status: PropTypes.any,
		hideHelp: PropTypes.bool
	};

	static defaultProps = {
		hideHelp: false,
	};

	/**
	 * Final Render
	 * @returns {*}
	 */
	render () {

		const { status, hideHelp } = this.props;

		if (status === null || status === 0) {
			return null;
		}

		if (hideHelp) {
			return <div className={"product-status"} style={{ color: status.color }}>
				<span>{status.name}</span>
			</div>;
		} else {
			return <div className={"product-status"} style={{ color: status.color }}>

					<span> {status.name} <Floater
						event="hover"
						placement="top"
						offset={0}
						content={status.description}
					><i className="icon-tooltip"/>
					</Floater> </span>

			</div>;
		}
	}
}

export default withTranslation()(ProductStatus);