// @flow

// Necessary Evil
import React from "react";
import PropTypes from "prop-types";
import {withTranslation} from "react-i18next";
import {Col} from "react-flexbox-grid";
import {connect} from "react-redux";
// Import component CSS
import "./RelatedDownloads.scss";


import LinkItem from "./LinkItem";
import MasterGetter from "../../../Models/Utils/MasterGetter";
import BackendRequest from "../../../Models/REST";

/**
 * @class RelatedDownloads
 */
class RelatedDownloads extends React.Component {

	/**
	 * PropTypes
	 * @type {{children: shim}}
	 */
	static propTypes = {
		seriesId: PropTypes.number.isRequired,
		links: PropTypes.any,
	};

	state = {
		fetchedData: false,
		seriesId: null,
	};

	fetchData = (seriesId = null) => {

		if(seriesId == null){
			seriesId = this.props.seriesId;
		}

		BackendRequest({
			endpoint: "product-series/" + seriesId + "/links",
			beforeRequest: () => {
				this.setState({
					fetchedData: false
				});
			}
		});
	};

	static getDerivedStateFromProps(nextProps, state) {

		if (nextProps.seriesId !== state.seriesId) {
			return {
				fetchedData: true,
				seriesId: nextProps.seriesId
			};
		}

		return {};

	}

	shouldComponentUpdate(nextProps: Readonly<P>, nextState: Readonly<S>, nextContext: any): boolean {

		if(nextState.fetchedData){
			this.fetchData(nextState.seriesId);
		}

		return true;

	}

	/**
	 * Final Render
	 * @returns {*}
	 */
	render() {

		const {links} = this.props;

		if (links.count() === 0) {
			// Hide when no links available
			return null;
		}

		return <Col xs={12} className="related-downloads page__section--bottom-padding">
			<div>
				<h2>Links and Downloads</h2>
			</div>
			<div>
				{links.orderBy(["ordering"], ["asc"]).toModelArray().map((item, index) => {
					return <LinkItem item={item.link} key={item.id}/>;
				})}
			</div>
		</Col>;
	}
}

const mapStateToProps = (state, props) => {

	const session = MasterGetter.getSession(state.entities);
	const seriesId = props.seriesId;
	const productLinks = session.ProductLink.filter(item => item.product === seriesId);

	return {
		links: productLinks,
	};
};

export default connect(mapStateToProps)(withTranslation()(RelatedDownloads));