// @flow
import React from "react";
import {withTranslation} from "react-i18next";
import {Col, Grid, Row} from "react-flexbox-grid";
import {Link} from "react-router-dom";
import YouTube from "react-youtube";
import {Helmet} from "react-helmet";

/**
 * @class TechnologyInteroperabilityScene
 */
class TechnologyInteroperabilityScene extends React.Component {

  /**
   * Final Render
   * @returns {*}
   */
  render() {

    return (
      <div className={"page"}>
        <Helmet>
          <title>Interoperability - IQRF</title>
        </Helmet>
        <Grid>
          <Row className="page__section--bottom-padding">
            <Col xs={12}>
              <h1>Interoperability</h1>
              <p>
                <Link to="/technology/dpa">DPA</Link> enables worldwide interoperability of products with IQRF inside.
                This is ensured by <a href="https://www.iqrfalliance.org/" target="_blank" rel="noopener noreferrer">
                IQRF Alliance</a>.
                The <a href="https://www.iqrfalliance.org/techDocs" target="_blank" rel="noopener noreferrer">IQRF
                Interoperability Standard</a> specifies standard DPA commands, so devices are not only
                compatible (working <strong>in one network</strong>) but also interoperable (controlled by
                the <strong>same commands</strong> and understanding the <strong>same data formats</strong>).
                Thus, products from <strong>different producers</strong> can plug-and-play work together.
              </p>
              <p>
                <a href="https://www.iqrfalliance.org/techDocs" target="_blank" rel="noopener noreferrer">
                  » IQRF Interoperability Standard
                </a> – with specifications in PDF<br></br>
                <a href="https://doc.iqrf.org/" target="_blank" rel="noopener noreferrer">
                  » IQRF Interoperability Standard
                </a> – links to online specifications
              </p>

            </Col>
          </Row>
          <Row className="page__section--bottom-padding">
            <Col xs={12} lg={6}>
              <YouTube videoId={"NTik4jvvz0k"} containerClassName={"video-item__wrapper"}/>
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }

}

/**
 * Exporting part of the React.Component file
 */
export default withTranslation()(TechnologyInteroperabilityScene);