// @flow
import React from "react";
import {withTranslation} from "react-i18next";
import {Col, Grid, Row} from "react-flexbox-grid";
import {Helmet} from "react-helmet";
import {Link} from "react-router-dom";

/**
 * @class TechnologyHistoryOSScene
 */
class TechnologyHistoryOSScene extends React.Component {

	componentDidMount(): * {
		document.body.scrollTop = 0; // For Safari
		document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
	}

	/**
	 * Final Render
	 * @returns {*}
	 */
	render() {

		return (
			<Grid className={"page"}>
				<Helmet>
					<title>OS history - IQRF</title>
				</Helmet>
				<Row className="page__section--bottom-padding">
					<Col xs={12}>
						<h1>IQRF OS history</h1>
						<table className="table--os-history">
							<tbody>
							<tr>
								<th>Version/Build</th>
								<th>Main differencies</th>
								<th>Release</th>
								<th>Status</th>
								<th>DPA versions</th>
								<th>TR modules</th>
							</tr>
							<tr>
								<td><strong>v4.06G</strong><br/>
										for TR-7xG<br/>
										<strong>090F</strong>
								</td>
								<td>
									<ul>
										<li><strong>MCU</strong> PIC16LF18877 with higher performance, larger memories, more flexible pins, and extended functionality.</li>
										<li>Brown-out reset (BOR) setting is fixed and cannot be changed in SW.</li>
									</ul>
								</td>
								<td>Aug 2022</td>
								<td><strong>Current</strong></td>
								<td><Link to="/technology/dpa/OsDpaCompatibility">Allowed DPA versions</Link></td>
								<td><strong>
										TR-72G<br/>
										TR-75G<br/>
										TR-76G</strong></td>
							</tr>
							<tr>
								<td><strong>v4.06D</strong><br/>
										for TR-7xD<br/>
										<strong>08D8</strong>
								</td>
								<td>
									<ul>
										<li>Brown-out reset (BOR) behavior changed</li>
										<li>Higher immunity to problematic power-up rising during TR start-up</li>
									</ul>
								</td>
								<td>Mar 2022</td>
								<td><strong>Current</strong></td>
								<td><Link to="/technology/dpa/OsDpaCompatibility">Allowed DPA versions</Link></td>
								<td><strong>
										TR-72D<br/>
										TR-75D<br/>
										TR-76D<br/>
										TR-77D<br/>
										TR-78D</strong></td>
							</tr>
							<tr>
								<td><strong>v4.05D</strong><br/>
										for TR-7xD<br/>
										<strong>08D7</strong>
								</td>
								<td>
									<ul>
										<li>IQuip (IQD-NFC-01) supported</li>
										<li>Slightly improved <strong>FRC</strong></li>
									</ul>
								</td>
								<td>Aug 2021</td>
								<td>Not for new designs</td>
								<td><Link to="/technology/dpa/OsDpaCompatibility">Allowed DPA versions</Link></td>
								<td>TR-72D<br/>
										TR-75D<br/>
										TR-76D<br/>
										TR-77D<br/>
										TR-78D</td>
							</tr>
							<tr>
								<td><strong>v4.04D</strong><br/>
									for TR-7xD<br/>
										<strong>08D5</strong>
								</td>
								<td>
									<ul>
										<li><strong>Local FRC</strong> introduced</li>
										<li><strong>Beaming sensors</strong> and <strong>Aggregating repeaters</strong> supported</li>
										<li>Faster FRC</li>
										<li>Some minor improvements</li>
									</ul>
								</td>
								<td>18 Sep 2020</td>
								<td>Not for new designs </td>
								<td>4.15, 4.16</td>
								<td>
									TR-72D<br/>
									TR-75D<br/>
									TR-76D<br/>
									TR-77D<br/>
									TR-78D</td>
							</tr>
							<tr>
								<td><strong>v4.03D</strong><br/>
									for TR-7xD<br/>
										<strong>08C8</strong>
								</td>
								<td>
									<ul>
										<li><strong>Smart connect</strong> based on <strong>IBK</strong> (Individual Bonding Key) introduced</li>
										<li>Dedicated fixed <strong>service RF channels</strong></li>
										<li>Operating <strong>RF channel inherited</strong> from network Coordinator</li>
										<li><strong>4 B FRC</strong></li>
										<li>User encryption slightly more flexible</li>
										<li>LED control more user-friendly</li>
										<li>Some minor improvements</li>
									</ul>
								</td>
								<td>26 Oct 2018</td>
								<td>not for new designs</td>
								<td>3.03, 4.00, 4.01, 4.02, 4.03, 4.10, 4.11, 4.12, 4.13, 4.14</td>
								<td>
									TR-72D<br/>
									TR-75D<br/>
									TR-76D<br/>
									TR-77D<br/>
									TR-78D</td>
							</tr>
							<tr>
								<td><strong>v4.02D</strong><br/>
									for TR-7xD<br/>
										<strong>08B8</strong>
								</td>
								<td>
									<ul>
										<li>TR and DCTR not differentiated. <strong>All TRs support</strong> both
											OS as well as <strong>DPA</strong> approaches.
										</li>
										<li><strong>TR upload</strong> extended. See IQRF SPI Technical guide.</li>
										<li>Fixed a bug in 2B FRC individual packets processing for not discovered
											Nodes in range with the Coordinator only.
										</li>
										<li>Higher immunity against possible failure of RF IC.</li>
										<li>Some minor improvements.</li>
									</ul>
								</td>
								<td>23 Aug 2017</td>
								<td>not for new designs</td>
								<td>3.01, 3.02</td>
								<td>TR-72D<br/>
									TR-75D<br/>
									TR-76D<br/>
									TR-77D<br/>
									TR-78D
								</td>
							</tr>
							<tr>
								<td><strong>v4.01D</strong><br/>
									for TR-7xD
								</td>
								<td>
									<ul>
										<li><strong>Removed</strong> due to a <strong>serious bug</strong> when
											using CATS based on TR with OS v4.01D to wirelessly upload TRs with
											OS v4.00D. A TR corrupted in this way can be recovered at the user.
											Contact IQRF support for instructions in this case.
										</li>
									</ul>
								</td>
								<td>14 Aug 2017</td>
								<td><strong>Do not use</strong></td>
								<td>-</td>
								<td>TR-72D<br/>
									TR-75D<br/>
									TR-76D<br/>
									TR-77D<br/>
									TR-78D
								</td>
							</tr>
							<tr>
								<td><strong>v4.00D</strong><br/>
									for TR-7xD<br/>
										<strong>08B1</strong>
								</td>
								<td>
									<ul>
										<li><strong>Packet structure</strong> changed</li>
										<li>Extended <strong>security</strong> based on <strong>AES-128 encryption</strong></li>
										<li><strong>Deep sleep</strong> mode</li>
										<li>Lots of other enhancements</li>
										<li>DPA is the only recommended way for IQMESH implementation</li>
										<li>Networking with more than 240 devices is not supported</li>
										<li><strong>DPA v3.00</strong> support</li>
										<li>IQRF IDE v4.40 or higher required</li>
										<li>template-basic.h header file renamed to IQRF.h</li>
									</ul>
								</td>
								<td>3/2017</td>
								<td>not for new designs</td>
								<td>3.00</td>
								<td>TR-72D<br/>
									TR-75D<br/>
									TR-76D<br/>
									TR-77D<br/>
									TR-78D
								</td>
							</tr>
							<tr>
								<td><strong>v3.09D</strong><br/>
									for TR-7xD<br/>
									<strong>087A</strong>
								</td>
								<td>
									<ul>
										<li>TR-7xDA-IL version supported (intended for <strong>Israel</strong>
											, observing local RF band/channel restrictions)
										</li>
										<li><strong>It is meaningless to migrate from OS v3.08D to v3.09D unless
											a ready application should be operated in Israel</strong>. For new
											designs in Israel <strong>use OS v4.xxD</strong>.
										</li>
										<li>OS downgrade is restricted</li>
										<li>IQRF IDE v4.41 or higher required</li>
									</ul>
								</td>
								<td>3/2016</td>
								<td>not for new designs</td>
								<td></td>
								<td>TR-72D<br/>
									TR-76D
								</td>
							</tr>
							<tr>
								<td><strong>v3.08D</strong><br/>
									for TR-7xD<br/>
										<strong>0879</strong>
								</td>
								<td>
									<ul>
										<li><strong>433</strong> and <strong>916 MHz</strong> bands implemented</li>
										<li>RF channel maps slightly changed, <strong>more channels</strong> available</li>
										<li>isBondedNode() return value slightly changed</li>
										<li>optimizeHops() output value slightly changed</li>
										<li>Minor bug and side effect fixed</li>
										<li>DPA v2.26 to v2.28 support</li>
										<li>IQRF IDE v4.34 or higher required</li>
									</ul>
								</td>
								<td>3/2016</td>
								<td>not for new designs</td>
								<td></td>
								<td>TR-72D<br/>
									TR-76D
								</td>
							</tr>
							<tr>
								<td><strong>v3.08D</strong><br/>
									for TR-5xD<br/>
										<strong>0858</strong>
								</td>
								<td>
									<ul>
										<li>isBondedNode() return value slightly changed</li>
										<li>optimizeHops() output value slightly changed</li>
										<li>Minor bug fixed</li>
										<li>DPA v2.26 to v2.28 support</li>
										<li>IQRF IDE v4.34 or higher required</li>
									</ul>
								</td>
								<td>3/2016</td>
								<td><strong>final</strong></td>
								<td></td>
								<td><strong>
									TR-52D<br/>
									TR-54D<br/>
									TR-55D<br/>
									TR-56D<br/>
									TR-58D-RJ
								</strong></td>
							</tr>
							<tr>
								<td><strong>v3.07D</strong><br/>
									for TR-7xD<br/>
										<strong>0870</strong>
								</td>
								<td>
									<ul>
										<li>Different RF IC, 916 MHz not yet supported</li>
										<li>MCU clock <strong>16 MHz, faster SPI</strong>. RF bit rate 19.8 kb/s.</li>
										<li><strong>Selective FRC</strong> and <strong>2B FRC</strong></li>
										<li>getSupplyVoltage, setRFmode, checkRF and getRSSI changed</li>
										<li>Serial EEPROM: <strong>32 KB</strong>, more flexible access, error check</li>
										<li><strong>OS upgradeable at the user</strong></li>
										<li>Various other improvements</li>
										<li>DPA v2.20 to v2.24 support</li>
										<li>IQRF IDE v4.30 or higher required</li>
									</ul>
								</td>
								<td>7/2015</td>
								<td>not for new designs</td>
								<td></td>
								<td>TR-72D<br/>
									TR-76D
								</td>
							</tr>
							<tr>
								<td><strong>v3.07D</strong><br/>
									for TR-5xD<br/>
										<strong>0850, 0852</strong>
								</td>
								<td>
									<ul>
										<li><strong>Selective FRC</strong> and <strong>2B FRC</strong></li>
										<li>Serial EEPROM: more flexible access, error check</li>
										<li><strong>OS upgradeable at the user</strong></li>
										<li>Various slight improvments</li>
										<li>DPA v2.20 to v2.24 support</li>
										<li>IQRF IDE v4.30 or higher required</li>
									</ul>
								</td>
								<td>7/2015</td>
								<td>not for new designs</td>
								<td></td>
								<td>TR-52D<br/>
									TR-54D<br/>
									TR-55D<br/>
									TR-56D<br/>
									TR-58D-RJ
								</td>
							</tr>
							<tr>
								<td><strong>v3.06D</strong><br/>
										<strong>0707</strong></td>
								<td>
									<ul>
										<li>Forced LP routing option</li>
										<li>Advanced FRC option</li>
										<li>Low power modes supported in bonding, FRC and Discovery</li>
										<li>Various slight improvments and side effect removals</li>
										<li>IQRF IDE v4.20 or higher required</li>
									</ul>
								</td>
								<td>11/2014</td>
								<td>not for new designs</td>
								<td></td>
								<td>TR-52D<br/>
									TR-54D<br/>
									TR-55D<br/>
									TR-56D<br/>
									TR-58D-RJ
								</td>
							</tr>
							<tr>
								<td><strong>v3.05D</strong></td>
								<td>
									<ul>
										<li><strong>Fast response command</strong> (FRC)</li>
										<li><strong>DPA v2.00</strong> support</li>
										<li>Licensed Flash memory extended to 3008 machine instructions</li>
										<li>Other enhancements</li>
										<li>IQRF IDE v4.15 or higher required</li>
									</ul>
								</td>
								<td>4/2014</td>
								<td>not for new designs</td>
								<td></td>
								<td>TR-52D<br/>
									TR-54D<br/>
									TR-55D<br/>
									TR-56D<br/>
									TR-58D-RJ
								</td>
							</tr>
							<tr>
								<td><strong>v3.04D</strong></td>
								<td>
									<ul>
										<li>TR configuration (predefining OS parameters from IQRF IDE)</li>
										<li>Enhanced RFPGM (Standard version instead of Lite version)</li>
										<li>Remote bonding</li>
										<li>A lot of additional improvements</li>
										<li>DPA (Direct Peripheral Access) v1.00 support</li>
										<li>IQRF IDE v4.13 or higher required</li>
									</ul>
								</td>
								<td>9/2013</td>
								<td>not for new designs</td>
								<td></td>
								<td>TR-52D<br/>
									TR-54D<br/>
									TR-55D<br/>
									TR-56D<br/>
									TR-58D-RJ
								</td>
							</tr>
							<tr>
								<td><strong>v3.03D</strong></td>
								<td>
									<ul>
										<li>Function getRSSI added</li>
										<li>Some other improvements</li>
										<li>IQRF IDE v4.11 or higher required</li>
									</ul>
								</td>
								<td>5/2013</td>
								<td>not for new designs</td>
								<td></td>
								<td>TR-52D<br/>
									TR-54D<br/>
									TR-55D<br/>
									TR-56D
								</td>
							</tr>
							<tr>
								<td><strong>v3.02D</strong></td>
								<td>
									<ul>
										<li>User interrupt</li>
										<li>HW Timer6 available</li>
										<li>Functions setINDFx and getINDFx</li>
										<li>Lower power consumption in LP and XLP</li>
										<li>More precise timing</li>
										<li>Improved Discovery</li>
										<li>Some other changes and improvements</li>
										<li>IQRF IDE v4.05 or higher required</li>
									</ul>
								</td>
								<td>8/2012</td>
								<td>not for new designs</td>
								<td></td>
								<td>TR-52D<br/>
									TR-54D<br/>
									TR-55D<br/>
									TR-56D
								</td>
							</tr>
							<tr>
								<td><strong>v3.01D</strong></td>
								<td>
									<ul>
										<li>Powerfull MCU PIC16LF1938: extended memories
											(RAM 1024 B, Flash 16 kwords), 16-level stack, efficient architecture
											, faster interrupt
										</li>
										<li>Serial EEPROM 16 Kb user available (not for Coordinator)</li>
										<li>Precission temperature sensor</li>
										<li>Additional new features and enhancements</li>
									</ul>
								</td>
								<td>3/2012</td>
								<td>not for new designs</td>
								<td></td>
								<td>TR-52D<br/>
									TR-54D
								</td>
							</tr>
							<tr>
								<td><strong>v3.00</strong></td>
								<td>
									<ul>
										<li>Many new features, ~20 functions added</li>
										<li>IQMESH with 65 000 devices and 240 hops</li>
										<li>New power management modes, 35 µA in XLP Rx</li>
										<li>Various routing algorithms</li>
										<li>Battery operated routers, real-time transparent routing</li>
										<li>Fast and transparent discovery</li>
										<li>A lot of other outstanding features</li>
									</ul>
								</td>
								<td>1/2011</td>
								<td><strong>final</strong></td>
								<td></td>
								<td><strong>
									TR-52B<br/>
									TR-53B</strong></td>
							</tr>
							<tr>
								<td>v2.11</td>
								<td>
									<ul>
										<li>RF power management supported (setRFmode, checkRF)</li>
										<li>RF channels available</li>
										<li>Selectable RF bit rate (provisionally for experimental purpose)</li>
									</ul>
								</td>
								<td>3/2010</td>
								<td>not for new designs</td>
								<td></td>
								<td>TR-52B<br/>
									TR-53B
								</td>
							</tr>
							<tr>
								<td><strong>v2.10</strong></td>
								<td>
									<ul>
										<li>Concept of OS plug-ins</li>
										<li>RF power not limited during bonding</li>
										<li>Green LED support</li>
									</ul>
								</td>
								<td>12/2009</td>
								<td><strong>final</strong></td>
								<td></td>
								<td><strong>
									TR-31B<br/>
									TR-32B</strong></td>
							</tr>
							<tr>
								<td>v2.09</td>
								<td>
									<ul>
										<li>minor change in first falling to Sleep mode</li>
										<li>bonding robustness increased</li>
									</ul>
								</td>
								<td>6/2009</td>
								<td>not for new designs</td>
								<td></td>
								<td>TR-31B<br/>
									TR-32B
								</td>
							</tr>
							<tr>
								<td rowSpan={2}><strong>v2.08</strong></td>
								<td>
									<ul>
										<li>broadcast message support added</li>
									</ul>
								</td>
								<td>10/2008</td>
								<td><strong>final</strong></td>
								<td></td>
								<td><strong>
									TR-11A<br/>
									TR-21A
								</strong></td>
							</tr>
							<tr>
								<td>
									<ul>
										<li>implemented in TR-868-31B modules</li>
									</ul>
								</td>
								<td>11/2008</td>
								<td>not for new designs</td>
								<td></td>
								<td>TR-31B</td>
							</tr>
							<tr>
								<td>v2.07</td>
								<td>
									<ul>
										<li>bug in the <strong>setLoggingOff()</strong> function fixed</li>
										<li><strong>Wake-up on pin change</strong> improved.<br/>
											To utilize it, the sequence GIE = 0; RBIE = 1; is required just before iqrfSleep().
										</li>
									</ul>
								</td>
								<td>9/2008</td>
								<td>not for new designs</td>
								<td></td>
								<td>–"–</td>
							</tr>
							<tr>
								<td>v2.06</td>
								<td>
									<ul>
										<li>minor change in routing</li>
									</ul>
								</td>
								<td>8/2008</td>
								<td>not for new designs</td>
								<td></td>
								<td>–"–</td>
							</tr>
							<tr>
								<td>v2.05</td>
								<td>
									<ul>
										<li>higher RF noise immunity</li>
										<li>corrected transfer of MPRWx while not routing</li>
										<li>several minor bugs not affecting module functionality corrected</li>
									</ul>
								</td>
								<td>8/2008</td>
								<td>not for new designs</td>
								<td></td>
								<td>–"–</td>
							</tr>
							<tr>
								<td>v2.04</td>
								<td>
									<ul>
										<li><strong>setNetworkFilteringOn()</strong> switches just packet from
											active network (1/2), non-networking communication ignored
										</li>
										<li><strong>Wake-up on pin change</strong> under user's control. Default
											disabled. To eneble, set RBIE = 1 before iqrfSleep().<br/>
											Not compatible with previous versions (permanently enabled in Sleep
											up to v2.03).
										</li>
									</ul>
								</td>
								<td>7/2008</td>
								<td>internal release only</td>
								<td></td>
								<td>–"–</td>
							</tr>
							<tr>
								<td>v2.03</td>
								<td>
									<ul>
										<li>Improvements:
											<ul>
												<li><strong>BufferCOM</strong> size increased from 35B to <strong>41B</strong></li>
												<li>Number of <strong>nodes</strong> in one network increased from 128 to <strong>239</strong></li>
											</ul>
										</li>
										<li>Minor bug in routing fixed</li>
									</ul>
								</td>
								<td>7/2008</td>
								<td>not for new designs</td>
								<td></td>
								<td>–"–</td>
							</tr>
							<tr>
								<td>v2.02</td>
								<td>
									<ul>
										<li>Minor SPI bug fixed</li>
									</ul>
								</td>
								<td>5/2008</td>
								<td>not for new designs</td>
								<td></td>
								<td>–"–</td>
							</tr>
							<tr>
								<td>v2.01</td>
								<td>
									<ul>
										<li>function <strong>wipeBondNR</strong> added</li>
										<li>function <strong>batteryValueOK</strong> added</li>
									</ul>
								</td>
								<td>3/2008</td>
								<td>not for new designs</td>
								<td></td>
								<td>–"–</td>
							</tr>
							<tr>
								<td>v2.00</td>
								<td>
									<ul>
										<li>Much more <strong>effective, easier</strong> to use
											, higher <strong>>performance</strong></li>
										<li><strong>Networking</strong> totally reworked. Extended capability
											. <strong>Complete MESH</strong>.
										</li>
										<li><strong>SPI in background</strong></li>
										<li><strong>Encoded</strong> network communication</li>
										<li>Indirect RAM access</li>
										<li>Temperature measurement supported by OS</li>
										<li>Supports user application debugging directly by IQRF OS</li>
										<li>Many other improvements</li>
										<li>IDE – complete <strong>development environment</strong>
											with all SW tools integrated including effective <strong>debug</strong> tools
										</li>
									</ul>
								</td>
								<td>1/2008</td>
								<td>not for new designs</td>
								<td></td>
								<td>TR-11A<br/>
									TR-21A
								</td>
							</tr>
							<tr>
								<td>v1.14</td>
								<td>
									<ul>
										<li>function <strong>moveMemoryBlock</strong> added</li>
										<li>RF receiving improved</li>
										<li>improving and patching some small errors</li>
									</ul>
								</td>
								<td>7/2007</td>
								<td>not for new designs</td>
								<td></td>
								<td>–"–</td>
							</tr>
							<tr>
								<td>v1.13</td>
								<td>
									<ul>
										<li>improving and patching some small errors</li>
									</ul>
								</td>
								<td>2/2007</td>
								<td>obsolete</td>
								<td></td>
								<td>–"–</td>
							</tr>
							<tr>
								<td>v1.12</td>
								<td>
									<ul>
										<li>networking improved</li>
										<li>RF power control</li>
										<li>function getTemperature()</li>
									</ul>
								</td>
								<td>1/2007</td>
								<td>obsolete</td>
								<td></td>
								<td>TR-11A<br/>
									TR-21A
								</td>
							</tr>
							<tr>
								<td>v1.07</td>
								<td>
									<ul>
										<li>sleep power consumption reduced from 50 µA to 5 µA</li>
									</ul>
								</td>
								<td>1/2006</td>
								<td>obsolete</td>
								<td></td>
								<td><strong>TR-01A</strong></td>
							</tr>
							<tr>
								<td>v1.06</td>
								<td>First release</td>
								<td>10/2005</td>
								<td>obsolete</td>
								<td></td>
								<td>TR-01A</td>
							</tr>
							</tbody>
						</table>
					</Col>
				</Row>
			</Grid>

		);
	}

}

/**
 * Exporting part of the React.Component file
 */
export default withTranslation()(TechnologyHistoryOSScene);