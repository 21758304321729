// @flow

// Necessary Evil
import React from "react";
import PropTypes from "prop-types";
import {withTranslation} from "react-i18next";
// Import component CSS
import {Link} from "react-router-dom";

import "./ProductVariantItem.scss";
import Thumbnail from "../../Images/Thumbnail/Thumbnail";
import ProductStatus from "../../Products/ProductStatus/ProductStatus";

/**
 * @class ProductVariantItem
 */
class ProductVariantItem extends React.Component {

	/**
	 * PropTypes
	 * @type {{children: shim}}
	 */
	static propTypes = {
		variant: PropTypes.any,
	};

	/**
	 * Final Render
	 * @returns {*}
	 */
	render() {

		const {
			variant,
		} = this.props;

		if (variant === undefined) {
			return null;
		}

		return <div className="product-variant-item">
			<Link to={"/editor/product-variant/" + variant.id} style={{display: "flex"}}>
				<div className="product-variant-item__image">
					<Thumbnail photoUrl={variant.image__url}/>
				</div>
				<div>
					<div className="product-variant-item__name">
						<strong>{variant.name}</strong><br/>
						<ProductStatus status={variant.status}/><br/>
						<ProductStatus status={variant.status_2}/>
						<br/>
						Product: {variant.series && variant.series.name}
					</div>
				</div>
			</Link>
		</div>;
	}
}

export default withTranslation()(ProductVariantItem);