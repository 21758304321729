// @flow

// Necessary Evil
import React from "react";
import PropTypes from "prop-types";
import slugify from "slugify";

/**
 * @class AnchorText
 */
export default class AnchorText extends React.Component {

	/**
	 * PropTypes
	 * @type {{children: *}}
	 */
	static propTypes = {
		text: PropTypes.any,
	};

	/**
	 * Final Render
	 * @returns {*}
	 */
	render() {
		const {text} = this.props;

		if(!text){
			return null;
		}

		return <span id={slugify(text.toLowerCase())}>
			{text}
		</span>;
	}
}
