// @flow

// Necessary Evil
import React from "react";
import PropTypes from "prop-types";
import {withTranslation} from "react-i18next";
import Markdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import rehypeSanitize from "rehype-sanitize";
import remarkGfm from "remark-gfm";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import MasterGetter from "../../Models/Utils/MasterGetter";
import BackendRequest from "../../Models/REST";
import {LoadingComponent} from "../index";

/**
 * @class ReleaseNotesLoader
 */
class ReleaseNotesLoader extends React.Component {

	/**
	 * PropTypes
	 * @type {{children: *}}
	 */
	static propTypes = {
		children: PropTypes.any,
		code: PropTypes.string.isRequired,
	};

	/**
	 * State
	 * @type {{}}
	 */
	state = {
		expectingResponse: true,
	};

	/**
	 * Fetch data
	 */
	fetchData = () => {

		const {
			code
		} = this.props;

		BackendRequest({
			endpoint: "release-notes/" + code,
			self: this,
		});

	};

	/**
	 * Component did moun
	 */
	componentDidMount(): void {
		this.fetchData();
	}

	/**
	 * Final Render
	 * @returns {*}
	 */
	render() {

		const {
			code,
			entities,
		} = this.props;

		if(this.state.expectingResponse){
			return <LoadingComponent/>;
		}

		const session = MasterGetter.getSession(entities);
		const subjects = session.ReleaseNoteSubject.all().filter(item => item.code === code);
		let subject;

		if (subjects.count() === 1) {
			subject = subjects.first();
		} else {
			return null;
		}

		let notes = session.ReleaseNote.all().filter(item => {

			let result = parseInt(item.subject) === parseInt(subject.id);
			return result;

		}).orderBy(["ordering"], ["desc"]);

		return <div className="release-notes-loader">
			{notes.toModelArray().map(notes => {
				return <div>
					<h2>
						<Markdown
							rehypePlugins={[rehypeRaw, rehypeSanitize]}
							remarkPlugins={[remarkGfm]}
							children={notes.title}
						/>
					</h2>
					<Markdown
						rehypePlugins={[rehypeRaw, rehypeSanitize]}
						remarkPlugins={[remarkGfm]}
						children={notes.content}
					/>
				</div>;
			})}
		</div>;
	}
}

/**
 * Map State To Props
 * @param state
 * @return {{entities: *}}
 */
const mapStateToProps = state => (
	{
		entities: state.entities,
	});

/**
 * Exporting part of the React.Component file
 */
export default withRouter(connect(mapStateToProps)(withTranslation()(ReleaseNotesLoader)));
