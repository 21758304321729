// @flow

import PropTypes from "prop-types";
import {attr} from "redux-orm";

import CRUDModel from "../ORM/CRUDModel";

export default class TrademarkCountry extends CRUDModel {

}

TrademarkCountry.modelName = "TrademarkCountry";

TrademarkCountry.fields = {
    id: attr(),
    name: attr(),
};

TrademarkCountry.propTypes = {
    name: PropTypes.string.isRequired,
};

TrademarkCountry.defaultProps = {
    name: "",
};

export const ADD_TRADEMARK_COUNTRY = "ADD_TRADEMARK_COUNTRY";
export const REMOVE_TRADEMARK_COUNTRY = "REMOVE_TRADEMARK_COUNTRY";
export const UPDATE_TRADEMARK_COUNTRY = "UPDATE_TRADEMARK_COUNTRY";