// @flow

// Necessary Evil
import React from "react";
import PropTypes from "prop-types";
import {withTranslation} from "react-i18next";
import Modal from "react-modal";
import Markdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import rehypeSanitize from "rehype-sanitize";
import remarkGfm from "remark-gfm";

// Import component CSS
/**
 * @class LinkItem
 */
class LinkItem extends React.Component {

    /**
     * PropTypes
     * @type {{children: *}}
     */
    static propTypes = {
        item: PropTypes.any,
        productLink: PropTypes.any,
    };

    /**
     * Default props
     * @type {{productLink: null}}
     */
    static defaultProps = {
        productLink: null,
    };

    /**
     * State
     * @type {{modalIsOpen: boolean}}
     */
    state = {
        modalIsOpen: false,
    };

    closeModal = () => {
        this.setState({
            modalIsOpen: false,
        });
    };

    /**
     * Final Render
     * @returns {*}
     */
    render() {

        const {
            item,
            productLink,
        } = this.props;

        return <div
            data-ordering={(productLink ? productLink.ordering : item.ordering)}
            data-product-link={productLink === null}
        >
            <Modal
                isOpen={this.state.modalIsOpen}
                onRequestClose={this.closeModal}
                contentLabel="Example Modal"
                ariaHideApp={false}
            >
                <Markdown
                  rehypePlugins={[rehypeRaw, rehypeSanitize]}
                  remarkPlugins={[remarkGfm]}
                  children={item.content}
                />
                <div className="text--center">
                    <button
                        className="btn"
                        onClick={this.closeModal}
                    >
                        Close
                    </button>
                </div>
            </Modal>
            <a target={item.is_external ? "_blank" : "_self"} className="related-downloads__item"
               key={item.id}
               href={item.url}
               onClick={item.is_modal ? (e) => {
                   e.preventDefault();
                   this.setState({
                       modalIsOpen: true,
                   });
               } : () => {
               }}>

                <div className="link-item__icon">
                    <img src={process.env.PUBLIC_URL + "/icon/" + item.icon + ".png"} alt={"icon"}/>
                </div>
                <div className="link-item ">
                    {item.name} &nbsp;{(item.description && (item.description.replace(/ /g, "") !== "")) &&
                <span>–&nbsp;</span>
                }
                    <span style={{color: "black"}}>
				<Markdown
          rehypePlugins={[rehypeRaw, rehypeSanitize]}
          remarkPlugins={[remarkGfm]}
          children={item.description}
        />
			</span>
                </div>
            </a>
        </div>;
    }
}

export default withTranslation()(LinkItem);
