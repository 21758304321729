// @flow

import PropTypes from "prop-types";
import {attr} from "redux-orm";

import CRUDModel from "../ORM/CRUDModel";

/**
 * @class Release Note Subject
 */
export default class ReleaseNoteSubject extends CRUDModel {}

ReleaseNoteSubject.modelName = "ReleaseNoteSubject";

ReleaseNoteSubject.fields = {
    id: attr(), // non-relational field for any value; optional but highly recommended
};

ReleaseNoteSubject.propTypes = {
    name: PropTypes.string,
};

export const ADD_RELEASE_NOTE_SUBJECT = "ADD_RELEASE_NOTE_SUBJECT";
export const REMOVE_RELEASE_NOTE_SUBJECT = "REMOVE_RELEASE_NOTE_SUBJECT";
export const UPDATE_RELEASE_NOTE_SUBJECT = "UPDATE_RELEASE_NOTE_SUBJECT";