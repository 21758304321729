// @flow
import React from "react";
import {withTranslation} from "react-i18next";
import {Col, Grid, Row} from "react-flexbox-grid";
import MasterGetter from "../../Models/Utils/MasterGetter";
import {AnchorText, LoadingComponent} from "../../Components";
import {SeriesItem} from "../../Components/Products";
import {NavLink, withRouter} from "react-router-dom";
import {connect} from "react-redux";

import Markdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import rehypeSanitize from "rehype-sanitize";
import remarkGfm from "remark-gfm";
import slugify from "slugify";
import InstantAction from "../../Models/Utils/InstantAction";
import {Helmet} from "react-helmet";

/**
 * @class ProductSubcategoryScene
 */
class ProductSubcategoryScene extends React.Component {

    /**
     * State
     * @type {{expectingResponse: boolean}}
     */
    state = {
        expectingResponse: true,
    };


    /**
     * Final Render
     * @returns {*}
     */
    render() {

        const {
            subcategory,
            sections,
            series,
            archivedProductsCount,
        } = this.props;

        if (!subcategory) {
            return null;
        }

        return (
            <Grid className={"page"}>
                <Helmet>
                    <title>{subcategory.name} - IQRF</title>
                </Helmet>
                <Row>
                    <Col xs={12} className="page__section--bottom-padding">
                        <h1><AnchorText text={subcategory.name}/></h1>
                        <div>
                            {this.props.expectingResponse && <LoadingComponent/>}
                            {
                                (sections.count() > 0 ? sections.orderBy(["list_order"], ["desc"]).toModelArray().map(item => {

                                    const seriesItems = series.filter(series => series.section === item.id);

                                    if(seriesItems.count() === 0){
                                        return null;
                                    }

                                    return <div key={"sub-" + item.id}>
                                        <h2 id={item.friendly_url()}>{item.name}</h2>
                                        {item.description && <div>
                                            <Markdown
                                              rehypePlugins={[rehypeRaw, rehypeSanitize]}
                                              remarkPlugins={[remarkGfm]}
                                              children={item.description}
                                            />
                                        </div>}
                                        {seriesItems.toModelArray().map(item => {
                                            return <SeriesItem key={item.id} series={item}/>;
                                        })}
                                        <br/>
                                    </div>;

                                }) : series.toModelArray().map(item => {
                                    return <SeriesItem key={item.id} series={item}/>;
                                }))
                            }
                            {((series.count() === 0) && !this.state.expectingResponse) &&
                            <div>No products in this section</div>}
                        </div>
                        {archivedProductsCount > 0 &&
                        <div className="text--center">
                            <br/>
                            <NavLink
                                to={"/products/" + subcategory.category.friendly_url() + "/" + subcategory.friendly_url() + "/archive"}
                                className={"btn"}>
                                Go to Archive
                            </NavLink>
                        </div>
                        }
                    </Col>
                </Row>
            </Grid>
        );
    }
}

/**
 * Map State To Props
 * @param state
 * @return {{app: *}}
 */
const mapStateToProps = (state, props) => {

    const session = MasterGetter.getSession(state.entities);
    const subcategoryNameSlug = props.match.params.subcategory;
    const subcategory = session.ProductSubcategory.filter(item => {
            return (slugify(item.name).toLowerCase() === subcategoryNameSlug);
        }
    ).first();

    if (subcategory) {

        let subcategoryId = subcategory.id;
        const series = session.ProductSeries.all().filter(series => {
            return series.subcategory === subcategoryId && series.is_online && !series.is_archived;
        }).orderBy(["ordering"], ["desc"]);

        const sections = session.ProductSubcategorySection.all().filter(item => item.subcategory === subcategoryId);
        const archivedProductsCount = session.ProductSeries.all().filter(series => series.subcategory === subcategoryId && series.is_online && series.is_archived).count();

        return {
            subcategoryId: subcategoryId,
            sections: sections,
            archivedProductsCount: archivedProductsCount,
            subcategory: subcategory,
            series: series,
        };
    } else {
        console.log("Subcategory not found", subcategoryNameSlug);
        InstantAction.redirect("/error-404");
        return {};
    }

};

/**
 * Exporting part of the React.Component file
 */
export default withRouter(connect(mapStateToProps)(withTranslation()(ProductSubcategoryScene)));
