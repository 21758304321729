// @flow
import React from "react";
import {withTranslation} from "react-i18next";
import {Col, Grid, Row} from "react-flexbox-grid";
import BackendRequest from "../../Models/REST";
import MasterGetter from "../../Models/Utils/MasterGetter";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import {ProductForm} from "../../Forms";
import {LoadingComponent} from "../../Components";
import InstantAction from "../../Models/Utils/InstantAction";
import {REMOVE_PRODUCT_SERIES} from "../../Models/Models/ProductSeries";
import DeleteModal from "../../Components/DeleteModal";

/**
 * @class ProductScene
 */
class ProductScene extends React.Component {

    /**
     * State
     * @type {{expectingResponse: boolean}}
     */
    state = {
        expectingResponse: true,
        modalIsOpen: false,
    };

    /**
     * Fetch app data if necessary
     */
    fetchData = () => {

        const {match} = this.props;

		BackendRequest({
			endpoint: "product-series/" + match.params.id,
			beforeRequest: () => {
				this.setState({
					expectingResponse: true,
				});
			},
			afterSuccess: () => {
				/**
				 * Fetch Files
				 */
				BackendRequest({
					endpoint: "files",
				});

				BackendRequest({
					endpoint: "product/edit-essentials",
				});

				BackendRequest({
					endpoint: "navigation-data",
				});

				BackendRequest({
					endpoint: "product-series/" + match.params.id + "/photos",
				});

				BackendRequest({
					endpoint: "product/" + match.params.id + "/links",
					self: this,
				});
			}
		});

    };

    /**
     * Component did mount
     */
    componentDidMount() {

        this.fetchData();

    }

    /**
     * Delete
     */
    delete = () => {

        const {
            match
        } = this.props;

        const productId = match.params.id;

        BackendRequest({
            endpoint: "product/" + productId + "/delete",
            method: "delete",
            self: this,
            afterSuccess: (response) => {
                InstantAction.dispatch({
                    type: REMOVE_PRODUCT_SERIES,
                    payload: productId,
                });
                InstantAction.redirect("/editor/products");
            }
        });
    };

    /**
     * Clone
     */
    clone = () => {
        const {
            match
        } = this.props;

        const productId = match.params.id;

        BackendRequest({
            endpoint: "product/" + productId + "/copy",
            method: "post",
            self: this,
            afterSuccess: (response) => {
                InstantAction.redirect("/editor/product/item/" + response.data.product.id);
                this.forceUpdate();
            }
        });
    };

    /**
     * Final Render
     * @returns {*}
     */
    render() {

        const {
            t,
            entities,
            match
        } = this.props;

        const productId = match.params.id;
        const session = MasterGetter.getSession(entities);
        const product = session.ProductSeries.withId(productId);
        const isNew = product === null;

        if (this.state.expectingResponse) {
            return <LoadingComponent/>;
        }

        return (
            <Grid fluid>
                <Row>
                    <Col xs={12} lg={6}>
                        <h1>{t("title.Product")}: {isNew ? "[new]" : product.name}</h1>
                    </Col>
                    <Col xs={12} lg={6} className={"text--right"}>
                        <br/>
                        <div>
                            <button className="btn" onClick={() => window.history.back()}>Cancel <i
                                className="icon-back-arrow"/></button>
                            <button className="btn" onClick={this.clone}>Clone <i className="icon-copy"/></button>
                            <button className="btn" onClick={() => {
                                this.setState({
                                    modalIsOpen: true,
                                });
                            }}>Delete <i className="icon-delete"/></button>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12}>
                        {this.state.expectingResponse ?
                            <LoadingComponent/> :
                            <ProductForm product={product}/>
                        }
                    </Col>
                </Row>
                <DeleteModal parent={this} handleDelete={this.delete} isOpen={this.state.modalIsOpen}/>
            </Grid>
        );
    }
}

/**
 *
 * @param state
 * @return {{entities: *}}
 */
const mapStateToProps = state => (
    {
        entities: state.entities,
    });

/**
 * Exporting part of the React.Component file
 */
export default withRouter(connect(mapStateToProps)(withTranslation()(ProductScene)));