// @flow

import PropTypes from "prop-types";
import {attr} from "redux-orm";

import CRUDModel from "../ORM/CRUDModel";


export default class FileType extends CRUDModel {
    // /**
    //  * @param {{id: number, name: string, color: string, voltage: number, capacity: number}} data
    //  * @returns {Object}
    //  */
    // static apiToPayload(data: Object)
    // {
    // 	return {
    // 		id: data.id,
    // 		name: data.name,
    // 		color: data.color,
    // 		voltage: Number(data.voltage),
    // 		capacity: Number(data.capacity) || null,
    // 	};
    // }


    // /**
    //  * @returns {{id: number, name: string, color: string, voltage: string, capacity: string}}
    //  */
    // toApi()
    // {
    // 	return {
    // 		id: this.id,
    // 		name: this.name,
    // 		color: this.color,
    // 		voltage: String(this.voltage),
    // 		capacity: String(this.capacity),
    // 	}
    // }
}

FileType.modelName = "FileType";

FileType.fields = {
    id: attr(), // non-relational field for any value; optional but highly recommended
};

FileType.propTypes = {
    name: PropTypes.string.isRequired,
};

// FileType.defaultProps = {
// };
//
// FileType.endpoint = "/group";

export const ADD_FILE_TYPE = "ADD_FILE_TYPE";
export const REMOVE_FILE_TYPE = "REMOVE_FILE_TYPE";
export const UPDATE_FILE_TYPE = "UPDATE_FILE_TYPE";