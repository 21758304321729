// @flow

// Necessary Evil
import React from "react";
// Import component CSS
import "./MainNavigation.scss";
import {Link, NavLink, withRouter} from "react-router-dom";
import logo from "./IQRF-logo.svg";
import {Col, Grid, Row} from "react-flexbox-grid";
import SubNavigation from "../SubNavigation/SubNavigation";
import {withTranslation} from "react-i18next";
import InstantAction from "../../../Models/Utils/InstantAction";
import {connect} from "react-redux";
import {setCloseNavigation} from "../../../App/App.actions";
import MobileNavigation from "../MobileNavigation/MobileNavigation";

/**
 * @class MainNavigation
 */
class MainNavigation extends React.Component {

	/**
	 * State
	 * @type {{classExpanded: boolean}}
	 */
	state = {
		item: null,
		activeSection: "",
		windowWidth: 0,
	};

	/**
	 * Constructor
	 * @param props
	 */
	constructor(props) {
		super(props);

		const self = this;

		/**
		 * Leave on press [ESC]
		 * @param e
		 */
		document.onkeydown = function (e) {
			let event = e || window.event;
			if (event.keyCode === 27) {
				self.handleLeave();
			}
		};
	}

	changeWindowWidth = () =>{

		const browserWidth = window.innerWidth;

		this.setState({
			windowWidth: browserWidth
		});
	}

	componentDidMount() {

		this.changeWindowWidth();

		window.addEventListener("resize", this.changeWindowWidth);
	}

	componentWillUnmount() {
		window.removeEventListener("resize", this.changeWindowWidth);
	}

	/**
	 *
	 * @param props
	 * @param state
	 * @return {{activeSection: null}}
	 */
	static getDerivedStateFromProps(props, state) {

		if (props.closeNavigation) {
			InstantAction.dispatch(setCloseNavigation(false));
			return {
				activeSection: null,
			};
		}

		return null;

	}

	/**
	 * Switch Secondary
	 * @param item
	 */
	switchSecondary = (item) => {

		this.setState({
			activeSection: item,
		});

	};

	/**
	 * Handle Leave
	 */
	handleLeave = (parent = null) => {

		this.setState({
			activeSection: null,
		});

		if (parent) {
			if (parent.setState) {
				parent.setState({
					archive: false,
				});
			}
		}
	};

	/**
	 * Final Render
	 * @returns {*}
	 */
	render() {

		const {
			t,
			location,
		} = this.props;

		const sceneParent = location.pathname.split("/")[1];


		/**
		 * Handle click
		 **/
		const handleClick = (section) => {

			if (this.state.activeSection === null) {
				this.switchSecondary(section);
			} else {
				this.handleLeave();
			}
		};


		if (this.state.windowWidth <= 1080) {
			return <MobileNavigation/>;
		}

		return <div className={"main-navigation"} key={"primary-nav"}>
			<div className={"main-navigation__logo"} onClick={this.handleLeave}>
				<Link to={"/"}>
					<img
						src={logo}
						alt={"IQRF logo"}
						title={"IQRF logo"}
					/>
				</Link>
			</div>
			<Grid>
				<Row>
					<Col xs={12}>
						<div className="main-navigation__top-panel">
							<div className={"main-navigation__logo--inside"} onClick={this.handleLeave}>
								<Link to={"/"}>
									<img
										src={logo}
										alt={"IQRF logo"}
										title={"IQRF logo"}
									/>
								</Link>
							</div>
							<ul className="main-navigation__list">
								<li onMouseOver={() => this.switchSecondary(null)}>
									<NavLink
										activeClassName={"active"}
										exact
										onClick={this.handleLeave}
										onMouseOver={this.handleLeave}
										to={"/what-is-iqrf"}
									>
										{t("navigation.AboutIQRF")}
									</NavLink>
								</li>
								<li onMouseOver={() => this.switchSecondary("technology")}
									onClick={() => handleClick("technology")}
								>
                                    <span
										onClick={() => this.switchSecondary("technology")}
										className={(this.state.activeSection === "technology" ? "hover-active" : "") + (sceneParent === "technology" ? " active" : "")}
									>
                                        {t("navigation.Technology")}
                                    </span>
									<SubNavigation
										activeSection={this.state.activeSection}
										handleLeave={this.handleLeave}
										primarySection={"technology"}
									/>
								</li>
								<li onMouseOver={() => this.switchSecondary("products")}

								>
                                    <span onClick={() => {
										this.switchSecondary("products");
										handleClick("products");
									}}
										  className={(this.state.activeSection === "products" ? " hover-active" : "") + (sceneParent === "products" || sceneParent === "product-detail" ? " active" : "")}
									>
                                    {t("navigation.Products")}
                                        </span>
									<SubNavigation
										activeSection={this.state.activeSection}
										handleLeave={this.handleLeave}
										primarySection={"products"}
									/>
								</li>
								<li onMouseOver={() => this.switchSecondary(null)}>
									<NavLink
										activeClassName={"active"}
										onClick={this.handleLeave}
										onMouseOver={this.handleLeave}
										to={"/how-to-start"}
									>
										{t("navigation.HowToStart")}
									</NavLink>
								</li>
								<li onMouseOver={() => this.switchSecondary("support")}
									onClick={() => handleClick("support")}
								>
                                        <span
											className={(this.state.activeSection === "support" ? "hover-active" : "") + (sceneParent === "support" ? " active" : "")}
										>
                                        {t("navigation.Support")}
                                        </span>
									<SubNavigation
										activeSection={this.state.activeSection}
										handleLeave={this.handleLeave}
										primarySection={"support"}
									/>
								</li>
								<li onMouseOver={() => this.switchSecondary("sales")}
									onClick={() => handleClick("sales")}
									className={this.state.activeSection === "sales" ? "hover-active" : ""}
								>
                                        <span
											className={(this.state.activeSection === "sales" ? "hover-active" : "") + (sceneParent === "sales" ? " active" : "")}
										>
                                        {t("navigation.Sales")}
                                        </span>
									<SubNavigation
										activeSection={this.state.activeSection}
										handleLeave={this.handleLeave}
										primarySection={"sales"}
									/>
								</li>
							</ul>
							{/*<ul className="main-navigation__list-left">*/}
							{/*	<li onMouseOver={() => this.switchSecondary(null)}>*/}
							{/*		/!*<NavLink*!/*/}
							{/*		/!*	activeClassName={"active"}*!/*/}
							{/*		/!*	exact*!/*/}
							{/*		/!*	onClick={this.handleLeave}*!/*/}
							{/*		/!*	onMouseOver={this.handleLeave}*!/*/}
							{/*		/!*	to={"/login"}*!/*/}
							{/*		/!*>*!/*/}
							{/*		/!*	{App.isUserLogged() ? MasterGetter.getCurrentUser().username : t("navigation.Login")}*!/*/}
							{/*		/!*</NavLink>*!/*/}
							{/*		/!*<NavLink exact activeClassName={"active"} to={"/shopping-cart"}*!/*/}
							{/*		/!*		 onClick={this.handleLeave}*!/*/}
							{/*		/!*		 onMouseOver={this.handleLeave}*!/*/}
							{/*		/!*>*!/*/}
							{/*		/!*	<i className="icon-shopping-cart"/>*!/*/}
							{/*		/!*</NavLink>*!/*/}
							{/*		/!*<NavLink exact activeClassName={"active"} to={"/search"}*!/*/}
							{/*		/!*		 onClick={this.handleLeave}*!/*/}
							{/*		/!*		 onMouseOver={this.handleLeave}*!/*/}
							{/*		/!*>*!/*/}
							{/*		/!*	<i className="icon-search"/>*!/*/}
							{/*		/!*</NavLink>*!/*/}
							{/*	</li>*/}
							{/*</ul>*/}
						</div>

					</Col>
				</Row>
			</Grid>
			<div className={"main-navigation__motto"}>
				Smarter Wireless. Simply.
			</div>
		</div>;
	}
}

/**
 *
 * @param state
 * @return {{app: *}}
 */
const mapStateToProps = state => (
	{
		closeNavigation: state.app.closeNavigation,
	});


export default withRouter(connect(mapStateToProps)(withTranslation()(MainNavigation)));
