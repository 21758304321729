// @flow
import React from "react";
import {withTranslation} from "react-i18next";
import {Col, Grid, Row} from "react-flexbox-grid";
import MasterGetter from "../../Models/Utils/MasterGetter";
import {LoadingComponent} from "../../Components";
import {NavLink, withRouter} from "react-router-dom";
import {connect} from "react-redux";
import Markdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import rehypeSanitize from "rehype-sanitize";
import remarkGfm from "remark-gfm";
import BackendRequest from "../../Models/REST";
import {SeriesItem} from "../../Components/Products";
import slugify from "slugify";
import InstantAction from "../../Models/Utils/InstantAction";
import {Helmet} from "react-helmet";

/**
 * @class ProductsCategoryArchiveScene
 */
class ProductsCategoryArchiveScene extends React.Component {

	/**
	 * State
	 * @type {{expectingResponse: boolean}}
	 */
	state = {
		expectingResponse: false,
	};

	/**
	 * Fetch data from REST API
	 */
	fetchData = () => {

		const {entities, match} = this.props;
		const session = MasterGetter.getSession(entities);
		const category = session.ProductCategory.filter(item => item.friendly_url === match.params.category).first();
		const series = session.ProductSeries.all().filter(item => item.category === category.id).orderBy(["ordering"], ["desc"]);

		if (series.count() === 0) {
			// Expecting response now
			this.setState({
				expectingResponse: true,
			});

			// Fetch product from API
			BackendRequest({
				endpoint: "category/" + category.id + "/products",
				self: this,
			});

		} else {
			// It's ok now, data already fetched
			this.setState({
				expectingResponse: false,
			});
		}
	};


	/**
	 * Final Render
	 * @returns {*}
	 */
	render() {

		const {
			category,
			series,
		} = this.props;

		return (
			<Grid className={"page"}>
				<Helmet>
					<title>{category.name} archive - IQRF</title>
				</Helmet>
				<Row>
					<Col xs={12}>
						<h1>{category.name} archive</h1>
					</Col>
					<Col xs={12} className="page__section--bottom-padding">
						{(category === undefined) ? "404 - Missing information" :
							<div>
								<Markdown
									rehypePlugins={[rehypeRaw, rehypeSanitize]}
									remarkPlugins={[remarkGfm]}
									children={category.description}
								/>
							</div>}
					</Col>
				</Row>
				<Row>
					<Col xs={12} className="page__section--bottom-padding">
						<div>
							{this.props.expectingResponse && <LoadingComponent/>}
							{series.toModelArray().map(item => {
								return <SeriesItem key={item.id} series={item}/>;
							})}
						</div>
						<div className="text--center">
							<br/>
							<NavLink to={"/products/" + category.friendly_url()} className={"btn"}>Back from Archive</NavLink>
						</div>
					</Col>
				</Row>
			</Grid>
		);
	}

}

/**
 *
 * @param state
 * @return {{entities: *}}
 */
const mapStateToProps = (state, props) => {

	const session = MasterGetter.getSession(state.entities);
	const categoryNameSlug = props.match.params.category;
	const category = session.ProductCategory.filter(item => {
			return slugify(item.name).toLowerCase() === categoryNameSlug;
		}
	).first();

	let subcategories = [];
	let series = [];

	if (category) {
		// Category found from navigation
		let categoryId = category.id;
		subcategories = session.ProductSubcategory.all().filter(item => item.category === categoryId);

		series = session.ProductSeries.all().filter(
			series => series.category === categoryId && series.is_online && series.is_archived
		).orderBy(["ordering"], ["desc"]);

		return {
			productsLoaded: state.app.productsLoaded,
			entities: state.entities,
			category: category,
			subcategories: subcategories,
			series: series,
			categoryId: categoryId,
		};

	} else {
		InstantAction.redirect("/error-404");
		return {};
	}
};


/**
 * Exporting part of the React.Component file
 */
export default withRouter(connect(mapStateToProps)(withTranslation()(ProductsCategoryArchiveScene)));
