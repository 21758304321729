// @flow
import React from "react";
import {withTranslation} from "react-i18next";
import {Col, Grid, Row} from "react-flexbox-grid";
import frc_principle from "../../Components/Technology/img/frc-principle.jpg";
import frc_vs_polling from "../../Components/Technology/img/frc-vs-polling.jpg";
import frc from "../../Components/Technology/video/frc.mp4";
import {Helmet} from "react-helmet";

/**
 * @class TechnologyFRCScene
 */
class TechnologyFRCScene extends React.Component {


	/**
	 * Final Render
	 * @returns {*}
	 */
	render() {

		return (
			<div className={"page"}>
				<Helmet>
					<title>FRC - Fast Response Command - IQRF</title>
				</Helmet>
				<Grid>
					<Row className="page__section--bottom-padding">
						<Col xs={12}>
							<h1>FRC<sup>&reg;</sup> - Fast Response Command</h1>
              <h2>The fastest network control, management and data collection</h2>
							<p>The <strong>fastest</strong> network <strong>control</strong>, management and <strong>data collection</strong>.
              A patented method how to <strong>send a command</strong> from the Coordinator <strong>to all or selected Nodes</strong> and <strong>receive responses</strong> including <strong>small data</strong> collected by individual Nodes in outstandingly short time.</p>
							<div className="text--center">
								<img src={frc_principle} alt={frc_principle}/>
							</div>
							<p>FRC is <strong>much faster</strong> (even by orders of magnitude) <strong>than polling </strong>
								individual Nodes one by one:</p>
							<div className="text--center">
								<img src={frc_vs_polling} alt={frc_vs_polling}/>
							</div>
              <p>See chapter <a href="https://doc.iqrf.org/IQMESH-Network-deployment/pages/fast-response-command-frc.html" rel="noreferrer noopener" target="_blank">FRC</a> in <a href="https://www.iqrf.org/download/2b4e3da9-f9f5-4853-87b2-4733dcd5022f">IQMESH Network deployment</a> technical guide.</p>
							<p><a href="https://doc.iqrf.org/DpaTechGuide/misc/IqMeshTiming.htm" rel="noreferrer noopener" target="_blank">&raquo; Timing calculator</a></p>
						</Col>
					</Row>
				</Grid>
				<div className="page__section--with-theme">
					<Grid>
						<Row>
							<Col xs={12}>
								<h2>Typical usage</h2>
								<h3>Control</h3>
								<p>Fast sending a command to multiple Nodes and checking the results
									(e.g. an acknowledged broadcast).</p>
								<h3>Telemetry</h3>
								<p>Fast collecting of small data from multiple Nodes.</p>
							</Col>
						</Row>
					</Grid>
				</div>
				<Grid>
					<Row>
						<Col xs={12}>
							<div className="text--center">
								<h3 id="frc-animation"></h3>
								<video width="560" controls>
									<source src={frc}/>
									<p>Your browser does not support the video tag.</p>
								</video>
							</div>
						</Col>
					</Row>
				</Grid>
					<Grid>
						<Row>
							<Col xs={12}>
								<p>&nbsp;</p>
							</Col>
						</Row>
					</Grid>
			</div>

		);
	}

}

/**
 * Exporting part of the React.Component file
 */
export default withTranslation()(TechnologyFRCScene);
