// @flow

// Necessary Evil
import React from "react";
import PropTypes from "prop-types";
import {withTranslation} from "react-i18next";
// Import component CSS
import "./SuccessMessage.scss";

/**
 * @class SuccessMessage
 */
class SuccessMessage extends React.Component {

    /**
     * PropTypes
     * @type {{children: shim}}
     */
    static propTypes = {
        children: PropTypes.any,
    };

    /**
     * Final Render
     * @returns {*}
     */
    render() {
        return <div className="success-message">
            {this.props.children}
        </div>;
    }
}

export default withTranslation()(SuccessMessage);