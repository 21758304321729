// @flow
import React from "react";
import {Col, Grid, Row} from "react-flexbox-grid";
import {Helmet} from "react-helmet";
import {Link} from "react-router-dom";

/**
 * @class TechnicalSupportScene
 */
class TechnicalSupportScene extends React.Component {


	/**
	 * Final Render
	 * @returns {*}
	 */
	render() {
		return (
			<Grid className={"page"}>
				<Helmet>
					<title>Contact IQRF Support - IQRF</title>
				</Helmet>
				<Row>
					<Col xs={12} className="page__section--bottom-padding">
						<h1>Contact IQRF Support</h1>
						<p>Submit a question and our experienced support team will respond.<br/>
							Use this form <em>for technical matters only</em>. For commercial or sales matters go to <Link to="/sales/contact">Contact</Link>.
						</p>
						<p>Please, before you post a question:
						</p>
						<ul>
							<li>Watch the appropriate IQRF <Link to="/support/videos">video tutorial</Link>.</li>
							<li>Read all relevant IQRF <strong>technical documentation</strong> and <strong>web pages</strong>.</li>
							<li>If it is possible, check your task using IQRF original HW (e.g. development kit <Link to="/product-detail/dk-eval-04a">DK-EVAL-04A</Link>) and/or SW (an example from IQRF Startup package).</li>
							<li>See IQRF technology <Link to="/support/faq">FAQ</Link> whether your question has been answered there.</li>
							<li>Think about attending IQRF <Link to="/support/training">training</Link>.</li>
							<li>To develop your project faster and more efficiently, think about IQRF <Link to="/support/fae"><strong>FAE</strong></Link> (field application engineer to provide an assistance or fully complete your design).</li>
						</ul>

						<p>When posting a question:
						</p>
						<ul>
							<li>Provide us with all relevant information, e.g. TR transceiver version, IQRF OS version and build, DPA version, TR configuration, IQRF IDE instance (which can easily be transferred to Windows clipboard by
								the <i>Copy</i> button in menu <i>Help/About</i>), …
							</li>
						</ul>
					</Col>
				</Row>
				<Row>
					<Col xs={12}>
						<hr/>
						<h1 id="ContactSupport">Contact IQRF Support</h1>
					</Col>
					<Col xs={12} className="page__section--bottom-padding">
						<p>Submit a question and our experienced support team will respond.<br/>
						<a href="mailto:support@iqrf.org">Contact IQRF Support</a> for technical matters only. For commercial or sales matters go to <a href="https://www.iqrf.org/sales/contact">Contact</a>.</p>
					</Col>
				</Row>
			</Grid>

		);
	}

}

/**
 * Exporting part of the React.Component file
 */
export default (TechnicalSupportScene);
