export default {
    AboutUs: "About Us",
    Career: "Careers",
    CaseStudies: "Case Studies",
    CaseStudy: "Case study",
    ClaimReturns: "Claim / Returns",
    Contacts: "Contacts",
    ContactSales: "Contact Sales",
    Distributor: "Distributor",
    Distributors: "Distributors",
    Downloads: "Downloads",
    Editor: "Editor",
    EditPhoto: "Edit photo",
    EShop: "E-Shop",
    FAQ: "Frequently Asked Questions",
    Files: "Files",
    Follow: "Follow",
    GetStarted: "Get Started",
    Technology: "IQRF Technology",
    News: "News",
    Other: "Other",
    Patents: "Patents",
    Photos: "Photos",
    PhotoUpload: "Photo Upload",
    PrivacyPolicy: "Privacy Policy",
    Product: "Product",
    Products: "Products",
    RecentlyChanged: "Recently Changed",
    Repository: "Repository",
    RequestForIQRFTraining: "Request For IQRF Training",
    Sales: "Sales",
    SignIn: "Sign In",
    SignUp: "Sign Up",
    Support: "Support",
    TechnicalSupport: "Technical Support",
    TermsOfUse: "Terms of use",
    Trademarks: "Trademarks",
    Training: "Training",
    UsefulLinks: "Useful Links",
    Videos: "Videos",
    VideoTutorials: "Video Tutorials",
    WebContent: "Web content",
    Webinars: "Webinars",
    WhyIQRF: "Why IQRF?",
    HowToStart: "How to start?",
    ProductVariants: "Product variants",
    FAE: "FAE",
};