// @flow

// Necessary Evil
import React from "react";
import PropTypes from "prop-types";
import {withTranslation} from "react-i18next";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css"; // This only needs to be imported once in your app
// Import component CSS
import "./Thumbnail.scss";


/**
 * @class Thumbnail
 */
class Thumbnail extends React.Component {

	/**
	 * PropTypes
	 * @type {{children: shim}}
	 */
	static propTypes = {
		children: PropTypes.any,
		photoUrl: PropTypes.any,
	};

	state = {
		isOpen: false,
	};

	/**
	 * Final Render
	 * @returns {*}
	 */
	render() {

		const {photoUrl} = this.props;

		if (photoUrl === null) {
			return <div className="ordering-box__no-image">No Photo</div>;
		}

		return <div className="thumbnail" onClick={() => {
			this.setState({
				isOpen: true,
			});
		}}>
			<img src={process.env.REACT_APP_BACKEND_SERVER + photoUrl} alt={""}/>
			{this.state.isOpen && (
				<Lightbox
					mainSrc={process.env.REACT_APP_BACKEND_SERVER + photoUrl}
					onCloseRequest={() => this.setState({isOpen: false})}
				/>
			)}
		</div>;
	}
}

export default withTranslation()(Thumbnail);