import buttons from "./_buttons";
import content from "./_content";
import errors from "./_error";
import items from "./_items";
import label from "./_labels";
import links from "./_links";
import navigation from "./_navigation";
import notices from "./_notices";
import options from "./_options";
import titles from "./_titles";
import countries from "./_countries";

/**
 * @Language English
 * @type {*}
 */
const enLocales = {
    button: buttons,
    content: content,
    error: errors,
    item: items,
    label: label,
    link: links,
    navigation: navigation,
    notice: notices,
    title: titles,
    option: options,
    country: countries,
};

export default enLocales;