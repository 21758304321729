// @flow

// Necessary Evil
import React from "react";
import PropTypes from "prop-types";
import {withRouter} from "react-router";
import InstantAction from "../Models/Utils/InstantAction";
import {connect} from "react-redux";
// import localStorageProxy from "../System/localStorage";
// import MasterGetter from "../System/MasterGetter";

/**
 * @class AppContainer
 */
class AppContainer extends React.Component {

    /**
     * PropTypes
     * @type {{children: any}}
     */
    static propTypes = {
        children: PropTypes.any,
    };


    /**
     * Constructor
     * @param props
     */
    constructor(props) {
        super(props);

        if(InstantAction.hasOwnProperty("initiateHistory")){
			InstantAction.initiateHistory(this.props.history);
		}
        if(InstantAction.hasOwnProperty("initiateDispatcher")){
			InstantAction.initiateDispatcher(this.props.dispatch);
		}

        // Fetch App Fixtures
        InstantAction.fetchAppData(this);

        this.state = {
            loadingData: true,
            downloadRedirect: null,
            location: "",
        };

    }

    componentDidUpdate(): void {
    }

    static getDerivedStateFromProps = (nextProps, state) => {

        if (nextProps.location.pathname !== state.location) {
            /**
             * Log Page view on every location change
             */

            AppContainer.hashLinkScroll(nextProps.history);

            return {
                location: nextProps.location.pathname
            };
        }

        return null;

    };

    /**
     * Get snapshot before update
     * @param prevProps
     * @param prevState
     * @return {null}
     */
    getSnapshotBeforeUpdate(prevProps: Readonly<P>, prevState: Readonly<S>): SS | null {

        let path = prevProps.location.pathname.split("/")[1];

        if (path !== "download") {
            localStorage.setItem("downloadRedirect", prevProps.location.pathname);
        }

        return null;
    }


    static scrollToHash = () => {

        const {hash} = window.location;

        setTimeout(() => {
            console.log("Scrolling");
            const id = hash.replace("#", "");
            const element = document.getElementById(id);
            if (element) element.scrollIntoView();
        }, 1500);
    };

    /**
     *
     */
    static hashLinkScroll(history) {

        let autoScroll = () => {

            const {hash} = window.location;
            if (hash !== "") {
                // Push onto callback queue so it runs after the DOM is updated,
                // this is required when navigating from a different page so that
                // the element is rendered on the page before trying to getElementById.
                AppContainer.scrollToHash();

            } else {

                setTimeout(() => {
                    window.scrollTo(0, 0);
                }, 200);

            }
        };

        if (history) {
            //console.log("With History Module");
            if (history.action === "PUSH") {
                autoScroll();
            }else{
                AppContainer.scrollToHash();
            }
        } else {
            //console.log("Without history module");
            autoScroll();
        }
    }

    /**
     * Final Render
     * @returns {*}
     */
    render() {

        const {location} = this.props;

        return <div className={"app-wrapper " + location.pathname.split("/")[1]}>
            <div className={"app-theme"}>
                {this.props.children}
            </div>
        </div>;

    }
}

/**
 * This function maps the state to a
 * prop called `state`.
 *
 * In larger apps it is often good
 * to be more selective and only
 * map the part of the state tree
 * that is necessary.
 */
const mapStateToProps = state => (
    {});

/**
 * Exporting part of the React.Component file
 */
export default withRouter(connect(mapStateToProps)((AppContainer)));
