// @flow

// Necessary Evil
import React from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import LoadingComponent from "../Components/LoadingComponent";
import BackendRequest from "../Models/REST/BackendRequest";
import FormGroup from "../Components/Form/FormGroup";
import { connect } from "react-redux";
import ErrorMessage from "../Components/Form/ErrorMessage/ErrorMessage";
import { Col, Row } from "react-flexbox-grid";
import Markdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import rehypeSanitize from "rehype-sanitize";
import remarkGfm from "remark-gfm";
import MasterGetter from "../Models/Utils/MasterGetter";

/**
 * @class ReleaseNoteForm
 *
 */
class ReleaseNoteForm extends React.Component {

	/**
	 * PropTypes
	 * @type {{children: shim, releaseNote: *}}
	 */
	static propTypes = {
		releaseNote: PropTypes.any,
		afterSuccess: PropTypes.func,
	};

	/**
	 * Default Props
	 * @type {{afterSuccess: ReleaseNoteForm.defaultProps.afterSuccess}}
	 */
	static defaultProps = {
		afterSuccess: () => {
			// InstantAction.redirect("/editor/case-studies");
		},
	};

	/**
	 * State
	 * @type {{formData: {}, expectingResponse: boolean, errorMessage: null}}
	 */
	state = {
		expectingResponse: false,
		errorMessage: null,
		// Properties
		id: (this.props.releaseNote) ? this.props.releaseNote.id : null,
		subject: (this.props.releaseNote) ? (this.props.releaseNote.subject ? this.props.releaseNote.subject.id : 0) : 0,
		content: (this.props.releaseNote) ? this.props.releaseNote.content : "",
		title: (this.props.releaseNote) ? this.props.releaseNote.title : "",
		ordering: (this.props.releaseNote) ? this.props.releaseNote.ordering : "",
	};

	/**
	 *
	 * @param props
	 * @param state
	 */
	static getDerivedStateFromProps (props, state) {

		if (props.releaseNote !== null) {
			if (props.releaseNote.id !== state.id) {
				return {
					...props.releaseNote._fields,
				};
			}
		}
		return null;
	}

	/**
	 * Handle Input Changes
	 * @param event
	 */
	handleInputChange = (event) => {

		const target = event.target;
		const value = target.type === "checkbox" ? target.checked : target.value;
		const name = target.name;

		this.setState({
			[name]: value
		});

	};

	/**
	 * Handle Submit
	 * @param e
	 */
	handleSubmit = (e) => {

		e.preventDefault();

		/**
		 * Payload
		 * @type {{image: null, name: string, title: string, content: string}}
		 */
		const payload = {
			...this.state
		};

		let method = this.state.id === null ? "post" : "put";
		let endpoint = this.state.id === null ? "release-note" : "release-note/" + this.state.id;

		BackendRequest({
			endpoint: endpoint,
			method: method,
			payload: payload,
			self: this,
			afterSuccess: this.props.afterSuccess
		});
	};

	/**
	 * Final Render
	 * @returns {*}
	 */
	render () {

		/**
		 * @info Translation function, className
		 */
		const {
			t,
			entities,
		} = this.props;

		/**
		 * @info Error variable
		 */
		let error = "";

		/**
		 * @info Handling Error Message
		 */
		if (this.state.errorMessage !== null) {
			error = <ErrorMessage>{t("formError." + this.state.errorMessage)}</ErrorMessage>;
		}

		const session = MasterGetter.getSession(entities);
		const subjects = session.ReleaseNoteSubject.all();
		/**
		 * Final Output
		 * @type {{}}
		 */
		return <form className={"form"}>
			{error}
			<Row>
				<Col xs={12}>
					<FormGroup>
						<label htmlFor="subject">Subject</label>
						<select onChange={this.handleInputChange}
								value={this.state.subject}
								name="subject"
								id="subject"
						>
							<option value={0}>Select subject</option>
							{subjects.toModelArray().map(item => {
								return <option
									key={item.id}
									value={item.id}
								>{item.name} ("{item.code}")</option>;
							})}
						</select>
					</FormGroup>
				</Col>
			</Row>
			<Row>
				<Col xs={12}>
					<FormGroup>
						<label htmlFor="title">Title</label>
						<input onChange={this.handleInputChange}
							   value={this.state.title}
							   type="text"
							   tabIndex={2}
							   name="title" id="title"
							   placeholder={t("label.Title")}
						/>
					</FormGroup>
					<FormGroup>
						<label htmlFor="ordering">Ordering</label>
						<input onChange={this.handleInputChange}
							   value={this.state.ordering}
							   type="text"
							   tabIndex={3}
							   name="ordering"
							   id="ordering"

						/>
					</FormGroup>
				</Col>
			</Row>
			<Row>
				<Col xs={12} lg={6}>
					<FormGroup>
						<label htmlFor="content">{t("label.Content")} </label>
						<textarea
							onChange={this.handleInputChange}
							value={this.state.content}
							tabIndex={3}
							name="content"
							id="content"
							style={{ minHeight: "500px" }}
						/>
						<em>(Use markdown - <a target="_blank"
											   href="https://github.com/adam-p/markdown-here/wiki/Markdown-Cheatsheet"
											   rel="noopener noreferrer">https://github.com/adam-p/markdown-here/wiki/Markdown-Cheatsheet</a>)</em>
					</FormGroup>
				</Col>
				<Col xs={12} lg={6}>
					<FormGroup>
						<label>{t("label.ContentPreview")}</label>
						<Markdown
							rehypePlugins={[rehypeRaw, rehypeSanitize]}
							remarkPlugins={[remarkGfm]}
							children={this.state.content}
						/>
					</FormGroup>
				</Col>
			</Row>
			<br/>
			<hr/>
			<FormGroup className={"text--center"}>
				{(this.state.expectingResponse) ? <LoadingComponent/> :
					<button type="submit" onClick={this.handleSubmit} tabIndex={2}
							className="btn btn--animated">{this.state.id === null ? t("button.Submit") : t("button.Update")}</button>
				}
			</FormGroup>
		</form>;
	}
}

/**
 * State to Props
 * @param state
 * @return {{entities: {PhotoSection: (*|PhotoSection)}}}
 */
const mapStateToProps = state => (
	{
		entities: {
			ReleaseNoteSubject: state.entities.ReleaseNoteSubject,
		},
	});

export default (connect(mapStateToProps)(withTranslation()(ReleaseNoteForm)));
