// @flow

// Necessary Evil
import React from "react";
import { withTranslation } from "react-i18next";
import LoadingComponent from "../Components/LoadingComponent";
import BackendRequest from "../Models/REST/BackendRequest";
import { FormGroup } from "../Components/Form";

/**
 * @class SignUpForm
 *
 */
class SignUpForm extends React.Component {

	/**
	 * Default props
	 * @type {{formData: {}}}
	 */
	static defaultProps = {};
	/**
	 * State
	 * @type {{formData: {}, expectingResponse: boolean, errorMessage: null}}
	 */
	state = {
		expectingResponse: false,
		errorMessage: null,
		signUpEmail: "",
		signUpPassword: "",
		genderSelection: 1
	};

	/**
	 * Handle Input Changes
	 * @param event
	 */
	handleInputChange = (event) => {

		const target = event.target;
		const value = target.type === "checkbox" ? target.checked : target.value;
		const name = target.name;

		this.setState({
			[name]: value
		});

	};

	/**
	 * Handle Submit
	 * @param e
	 */
	handleSubmit = (e) => {

		e.preventDefault();

		/**
		 *
		 * @type {{formData: {}, expectingResponse: boolean, errorMessage: null, reCaptcha}}
		 */
		const payload = {
			email: this.state.signUpEmail,
			password: this.state.signUpPassword,
		};

		BackendRequest({
			endpoint: "sign-up",
			payload: payload,
			method: "post",
			self: this,
			afterSuccess: () => {
				this.setState({
					signUpEmail: "",
					signUpPassword: "",
				});
			}
		});

	};

	/**
	 * Final Render
	 * @returns {*}
	 */
	render () {

		/**
		 * @info Translation function, className
		 */
		const { t } = this.props;

		/**
		 * Final Output
		 * @type {{}}
		 */
		return <form onSubmit={this.handleSubmit} className={"form"}>

			<FormGroup>
				<label htmlFor="sign-up-email">{t("label.YourEmailAddress")}</label>
				<input onChange={this.handleInputChange}
					   value={this.state.signUpEmail}
					   type="email"
					   tabIndex={2}
					   name="signUpEmail"
					   id="sign-up-email"
					   placeholder={t("label.Email")}/>
			</FormGroup>

			<FormGroup>
				<label htmlFor="sign-up-password">{t("label.Password")}</label>
				<input type="password"
					   onChange={this.handleInputChange}
					   value={this.state.signUpPassword}
					   name="signUpPassword"
					   id="sign-up-password"
					   tabIndex={3}
					   placeholder={t("label.Password")}

				/>
			</FormGroup>
			<div className="form--group text--center">
				{(this.state.expectingResponse) ? <LoadingComponent/> :
					<button
						type="submit"
						tabIndex={4}
						className="btn btn--animated"
						onSubmit={this.handleSubmit}
					>{t("button.Submit")}</button>}
			</div>
		</form>;
	}
}

export default withTranslation()(SignUpForm);