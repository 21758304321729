// @flow
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import React from "react";
import {withTranslation} from "react-i18next";
import {Col, Grid, Row} from "react-flexbox-grid";
import daemon_target from "../../Components/Technology/img/Daemon-target.png";
import daemon_diagram from "../../Components/Technology/img/daemon-diagram.jpg";
import {HashLink} from "react-router-hash-link";
import {Helmet} from "react-helmet";


/**
 * @class TechnologyGWDaemonScene
 */
class TechnologyGWDaemonScene extends React.Component {

	/**
	 * Constructor
	 * @param props
	 */
	constructor(props) {
		super(props);

		this.state = {
			isOpen: false,
		};
	}


	/**
	 *
	 * Final Render
	 * @returns {*}
	 */
	render() {

		const {isOpen} = this.state;

		return (
			<Grid className={"page"}>
				<Helmet>
					<title>GW Daemon - IQRF</title>
				</Helmet>
				<Row>
					<Col xs={12}>
						<h1>GW Daemon</h1>
						<h2>TR with DPA + Linux single-board computer + IQRF GW Daemon = IQRF Gateway</h2>
						<p><strong>Create your own IQRF gateway</strong></p>
						<p>
							<em>IQRF Gateway Daemon</em> is a powerful <strong>open source</strong> SW package
							allowing to easily create an <strong>IQRF gateway</strong> with Internet and cloud
							connectivity from a <em>Linux</em> machine.
							Typically it runs on an embedded Linux-based single-board computer
							like <strong>Raspberry Pi, Up Board, Linux PlugPC, BeagleBone</strong> or many others.
							It can be connected to a TR via <em>SPI, UART</em> or <em>USB CDC</em>.
							The TR implements an IQMESH network <strong>Coordinator</strong> under the DPA layer.
							The IQRF Gateway Daemon utilizes multiple communication
							channels <em>UDP, MQ, MQTT</em> and <em>WebSocket</em> and can be managed through
							the <em>IQRF Gateway Webapp</em>.
						</p>
					</Col>
				</Row>
				<br/>
				<Row>
					<Col className="text--center" xs={12}>
						<img src={daemon_target} alt={"Daemon Target"}/><br/>
					</Col>
				</Row>
				<Row className="page__section--bottom-padding">
					<Col xs={12}>
						<p><HashLink to={"/products/development-tools/development-kits#shield-and-breakout-boards"}> » Shields and breakout boards </HashLink>
							to connect TRs to devices</p>
						<hr/>
						<br/>
						<ul>
							<li>Modular design</li>
							<li>IQRF Repository integration</li>
							<li>API for
								<a href="https://docs.iqrf.org/iqrf-gateway/daemon-api.html#iqrf-standard" rel="noopener noreferrer" target="_blank"> IQRF Standard </a>
								and for
								<a href="https://docs.iqrf.org/iqrf-gateway/daemon-api.html#iqmesh-network" rel="noopener noreferrer" target="_blank"> Network management </a></li>
							<li>Interfaces: SPI, UART and USB CDC</li>
							<li>Channels: UDP, MQ, WebSocket and MQTT</li>
							<li>An IQRF gateway based on the IQRF GW Daemon can simply be managed by secure and
								powerful web application <strong>IQRF Gateway Webapp</strong>.
							</li>
						</ul>
						<br/>
						<hr/>
						<br/>
						<span className={"link"} onClick={() => this.setState({isOpen: true})}>
                                <strong>» Block diagram</strong>
                            </span>
						<br/>
						<br/>
						{isOpen && (
							<Lightbox
								mainSrc={daemon_diagram}
								onCloseRequest={() => this.setState({isOpen: false})}
							/>
						)}
						<br/>
						<p><strong>This project has been made possible with a government grant by means of the Ministry of Industry and Trade of Czech Republic in the TRIO program.</strong></p>
						<br/>
						<div className="table--wrapper">
							<table className="table--with-links">
								<tbody>
								<tr>
									<td>
										<a href="https://gitlab.iqrf.org/open-source/iqrf-gateway-daemon" rel="noopener noreferrer" target="_blank">» Software IQRF Gateway Daemon</a>
									</td>
								</tr>
								<tr>
									<td>
										<a href="https://gitlab.iqrf.org/open-source/iqrf-gateway-webapp" rel="noopener noreferrer" target="_blank">» Software IQRF Gateway Webapp</a>
									</td>
								</tr>
								<tr>
									<td>
										<a href="https://docs.iqrf.org/iqrf-gateway/" rel="noopener noreferrer" target="_blank">» Documentation</a>
									</td>
								</tr>
								<tr>
									<td>
										<a href="https://docs.iqrf.org/iqrf-gateway/news.html#releases" rel="noopener noreferrer" target="_blank">» IQRF GW Daemon news</a> - Release notes
									</td>
								</tr>
								</tbody>
							</table>
						</div>
					</Col>
				</Row>
			</Grid>

		);
	}

}

/**
 * Exporting part of the React.Component file
 */
export default withTranslation()(TechnologyGWDaemonScene);