// @flow

import PropTypes from "prop-types";
import {attr, fk} from "redux-orm";

import CRUDModel from "../ORM/CRUDModel";


export default class FAQuestion extends CRUDModel {
    // /**
    //  * @param {{id: number, name: string, color: string, voltage: number, capacity: number}} data
    //  * @returns {Object}
    //  */
    // static apiToPayload(data: Object)
    // {
    // 	return {
    // 		id: data.id,
    // 		name: data.name,
    // 		color: data.color,
    // 		voltage: Number(data.voltage),
    // 		capacity: Number(data.capacity) || null,
    // 	};
    // }


    // /**
    //  * @returns {{id: number, name: string, color: string, voltage: string, capacity: string}}
    //  */
    // toApi()
    // {
    // 	return {
    // 		id: this.id,
    // 		name: this.name,
    // 		color: this.color,
    // 		voltage: String(this.voltage),
    // 		capacity: String(this.capacity),
    // 	}
    // }
}

FAQuestion.modelName = "FAQuestion";

FAQuestion.fields = {
    id: attr(), // non-relational field for any value; optional but highly recommended
    answer: attr(),
    section: fk("FAQSection"),
    slug_name: attr()
};

FAQuestion.propTypes = {
    name: PropTypes.string.isRequired,
};

// FAQuestion.defaultProps = {
// };
//
// FAQuestion.endpoint = "/group";

export const ADD_FA_QUESTION = "ADD_FA_QUESTION";
export const REMOVE_FA_QUESTION = "REMOVE_FA_QUESTION";
export const UPDATE_FA_QUESTION = "UPDATE_FA_QUESTION";