// @flow
import React from "react";
import {withTranslation} from "react-i18next";
import BackendRequest from "../../Models/REST";
import MasterGetter from "../../Models/Utils/MasterGetter";
import {Col, Grid, Row} from "react-flexbox-grid";
import Markdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import rehypeSanitize from "rehype-sanitize";
import remarkGfm from "remark-gfm";
import {LoadingComponent} from "../../Components";
import {Helmet} from "react-helmet";

/**
 * @class NewsListScene
 */
class NewsListScene extends React.Component {

	/**
	 *
	 * @type {{expectingResponse: boolean}}
	 */
	state = {
		expectingResponse: true,
	};

	/**
	 * Fetch data from REST API
	 */
	fetchData = () => {

		BackendRequest({
			endpoint: "events/50",
			self: this,
		});

	};

	/**
	 * Component did mount
	 */
	componentDidMount(): * {
		this.fetchData();
	}

	/**
	 * Final Render
	 * @returns {*}
	 */
	render() {

		// const {t} = this.props;
		const {entities} = this.props;
		const session = MasterGetter.getSession(entities);
		const events = session.Event.all().filter(item => item.is_online).orderBy(["ordering", "starts"], ["desc", "desc"]);

		return (
			<Grid>
				<Helmet>
					<title>Events - IQRF</title>
				</Helmet>
				<Row className="page__section--bottom-padding">
					<Col xs={12}>
						<div>
							<div className="page__section--bottom-padding">
								<h1>Events</h1>
								<ul>
									{events.toModelArray().map(item => {
										return <li className="events-loader__item" key={item.id}>
											<div className="events-loader__title">
												<a href={item.url} rel="noopener noreferrer">
													<Markdown
														rehypePlugins={[rehypeRaw, rehypeSanitize]}
														remarkPlugins={[remarkGfm]}
														children={item.name}
													/>
												</a>
											</div>
											<div className="events-loader__description">{item.description}</div>
										</li>;
									})}
									{this.state.expectingResponse && <LoadingComponent/>}
									{events.count() === 0 && <div>No events available</div>}
								</ul>
							</div>
						</div>
					</Col>
				</Row>
			</Grid>
		);
	}
}

/**
 * Exporting part of the React.Component file
 */
export default withTranslation()(NewsListScene);
