// @flow

// Necessary Evil
import React from "react";
import PropTypes from "prop-types";
import {withTranslation} from "react-i18next";
// Import component CSS
import "./Description.scss";


/**
 * @class Description
 */
export default withTranslation()(class Description extends React.Component {

    /**
     * PropTypes
     * @type {{children: shim}}
     */
    static propTypes = {
        children: PropTypes.any,
        inline: PropTypes.bool,
        className: PropTypes.any,
    };

    /**
     * Default Props
     * @type {{}}
     */
    static defaultProps = {
        inline: true,
    };


    /**
     * Final Render
     * @returns {*}
     */
    render() {
        return <div className={"description "+(this.props.inline ? "inline" : "")+" "+this.props.className}>
            {this.props.children}
        </div>;
    }
});