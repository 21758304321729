// @flow

// Necessary Evil
import React from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import LoadingComponent from "../Components/LoadingComponent";
import BackendRequest from "../Models/REST/BackendRequest";
import FormGroup from "../Components/Form/FormGroup";
import { connect } from "react-redux";
import ErrorMessage from "../Components/Form/ErrorMessage/ErrorMessage";
import Markdown from "react-markdown";
import remarkGfm from "remark-gfm";

/**
 * @class OSFunctionSectionForm
 */
class OSFunctionSectionForm extends React.Component {

	/**
	 * PropTypes
	 * @type {{children: shim, videoCategories: *}}
	 */
	static propTypes = {
		item: PropTypes.any,
		afterSuccess: PropTypes.func,
	};

	/**
	 * Default Props
	 * @type {{afterSuccess: OSFunctionSectionForm.defaultProps.afterSuccess}}
	 */
	static defaultProps = {
		afterSuccess: () => {
			// InstantAction.redirect("/editor/startup-package-history");
		},
	};

	/**
	 * State
	 * @type {{formData: {}, expectingResponse: boolean, errorMessage: null}}
	 */
	state = {
		expectingResponse: false,
		errorMessage: null,
		// Properties
		id: 0,
		name: "",
		ordering: 0,

	};

	/**
	 * Get derived state from props
	 * @param props
	 * @param state
	 */
	static getDerivedStateFromProps (props, state) {

		if (props.item !== null) {
			if (props.item.id !== state.id) {
				return {
					...props.item._fields,
				};
			}
		}
		return null;
	}

	/**
	 * Handle Input Changes
	 * @param event
	 */
	handleInputChange = (event) => {

		const target = event.target;
		const value = target.type === "checkbox" ? target.checked : target.value;
		const name = target.name;

		this.setState({
			[name]: value
		});

	};

	/**
	 * Handle Switch
	 * @param key
	 */
	handleSwitch = (key) => {

		this.setState({
			[key]: !this.state[key]
		});

	};

	/**
	 * Handle Submit
	 * @param e
	 */
	handleSubmit = (e) => {

		e.preventDefault();

		BackendRequest({
			afterSuccess: this.props.afterSuccess,
			endpoint: "os-function-section/" + this.state.id,
			method: "put",
			payload: this.state,
			self: this,
		});
	};

	/**
	 * Final Render
	 * @returns {*}
	 */
	render () {

		/**
		 * @info Error variable
		 */
		let error = "";

		/**
		 * @info Handling Error Message
		 */
		if (this.state.errorMessage !== null) {
			error = <ErrorMessage>{this.state.errorMessage}</ErrorMessage>;
		}

		/**
		 * Final Output
		 * @type {{}}
		 */
		return (<form
			onSubmit={this.handleSubmit}
			className={"form"}
		>
			{error}
			<FormGroup>
				<label htmlFor="name">Name</label>
				<input onChange={this.handleInputChange}
					   value={this.state.name}
					   type="text"
					   tabIndex={1}
					   name="name"
					   id="name"
				/>
				<h2>{<Markdown remarkPlugins={[remarkGfm]} children={this.state.name}/>}</h2>
			</FormGroup>
			<FormGroup>
				<label htmlFor="ordering">Ordering</label>
				<input onChange={this.handleInputChange}
					   value={this.state.ordering}
					   type="text"
					   name="ordering"
					   id="ordering"
				/>
			</FormGroup>
			<hr/>
			<FormGroup className={"text--center"}>
				{(this.state.expectingResponse) ? <LoadingComponent/> :
					<button
						className="btn btn--animated"
						tabIndex={2}
						type="submit"
					>
						Save
					</button>
				}
			</FormGroup>
		</form>);
	}
}

/**
 * Map State to Props
 * @param state
 * @return {{entities: *}}
 */
const mapStateToProps = state => (
	{
		entities: state.entities,
	});

export default (connect(mapStateToProps)(withTranslation()(OSFunctionSectionForm)));
