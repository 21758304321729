// @flow

// Necessary Evil
import React from "react";
import PropTypes from "prop-types";
import {Col} from "react-flexbox-grid";
// Import component CSS
import "./ProductGallery.scss";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import BackendRequest from "../../../Models/REST";

/**
 * @class ProductGallery
 */
class ProductGallery extends React.Component {

    /**
     * PropTypes
     * @type {{children: shim}}
     */
    static propTypes = {
        children: PropTypes.any,
        photos: PropTypes.object,
    };

	/**
	 * Default Props
	 * @type {{photos: []}}
	 */
	static defaultProps = {
        photos: [],
    };

	/**
	 * State
	 * @type {{isOpen: boolean, selectedPhoto: number}}
	 */
	state = {
        selectedPhoto: 0,
        isOpen: false,
        fetchedData: false,
        seriesId: null,
    };

    fetchData = (seriesId = null) => {

        if(seriesId == null){
            seriesId = this.props.seriesId;
        }

        BackendRequest({
            endpoint: "product-series/" + seriesId + "/photos",
            beforeRequest: () => {
                this.setState({
                    fetchedData: false
                });
            }
        });
    };

    static getDerivedStateFromProps(nextProps, state) {

        if (nextProps.seriesId !== state.seriesId) {
            return {
                fetchedData: true,
                seriesId: nextProps.seriesId
            };
        }

        return {};

    }

    shouldComponentUpdate(nextProps: Readonly<P>, nextState: Readonly<S>, nextContext: any): boolean {

        if(nextState.fetchedData){
            this.fetchData(nextState.seriesId);
        }

        return true;

    }

    /**
     * Handle Click
     * @param photoIndex
     */
    handleClick = (photoIndex) => {

        this.setState({
           selectedPhoto: photoIndex,
           isOpen: true,
        });
    };

    /**
     * Final Render
     * @returns {*}
     */
    render() {

        const {photos} = this.props;

        if(photos.count() === 0){
            return null;
        }

        const images = [];
        photos.all().toModelArray().forEach(item => {
        	images.push(item);
		});

        return <Col xs={12} className="product-gallery">
            <div>
                <h2>Gallery</h2>
            </div>
            <div>
                {images.map((photo, index) => {

                    return <div
						key={photo.id}
                        className="product-gallery__item"
                        style={{backgroundImage: "url('"+process.env.REACT_APP_BACKEND_SERVER + photo.url+"')"}}
                        onClick={() => this.handleClick(index)}
                    />;
                })}
                {((photos.length === 0) || (photos.count() === 0)) && <div>No images available for this product</div>}
                {this.state.isOpen && (
                    <Lightbox
                        mainSrc={process.env.REACT_APP_BACKEND_SERVER+(images[this.state.selectedPhoto]).url}
						nextSrc={process.env.REACT_APP_BACKEND_SERVER+(images[(this.state.selectedPhoto + 1) % images.length].url)}
						prevSrc={process.env.REACT_APP_BACKEND_SERVER+(images[(this.state.selectedPhoto + images.length - 1) % images.length]).url}
						onCloseRequest={() => this.setState({ isOpen: false })}
						onMovePrevRequest={() =>
							this.setState({
								selectedPhoto: (this.state.selectedPhoto + images.length - 1) % images.length,
							})
						}
						onMoveNextRequest={() =>
							this.setState({
								selectedPhoto: (this.state.selectedPhoto + 1) % images.length,
							})
						}
                    />
                )}
            </div>
        </Col>;
    }
}



export default (ProductGallery);