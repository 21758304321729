// @flow

// Necessary Evil
import React from "react";
import PropTypes from "prop-types";
import {withTranslation} from "react-i18next";
// Import component CSS
import {Link} from "react-router-dom";

import "./ProductItemAdmin.scss";
import Thumbnail from "../Images/Thumbnail/Thumbnail";
import ProductStatus from "./ProductStatus/ProductStatus";

/**
 * @class ProductItemAdmin
 */
class ProductItemAdmin extends React.Component {

	/**
	 * PropTypes
	 * @type {{children: *}}
	 */
	static propTypes = {
		product: PropTypes.any,
	};

	/**
	 * Final Render
	 * @returns {*}
	 */
	render() {

		const {
			product,
		} = this.props;

		return	<div className="product-item-admin">
			<Link to={"/editor/product/item/" + product.id} style={{display: "flex"}}>
				<div className="product-item-admin__image">
					<Thumbnail photoUrl={product.image__url}/>
				</div>
				<div>
					<div className="product-item-admin__name">
						<strong>{product.ordering}. {product.name}</strong><br/>
						<ProductStatus status={product.status}/><br/>
						<ProductStatus status={product.status_2}/><br/>
						{product.category && <span>{product.category.name} {product.subcategory !== null &&
						<span>{product.subcategory.name}</span>}</span>}
					</div>
				</div>
			</Link>
		</div>
		;
	}
}

export default withTranslation()(ProductItemAdmin);