// @flow
import React from "react";
import PropTypes from "prop-types";
import {attr} from "redux-orm";

import CRUDModel from "../ORM/CRUDModel";


export default class ProductStatus extends CRUDModel {
    // /**
    //  * @param {{id: number, name: string, color: string, voltage: number, capacity: number}} data
    //  * @returns {Object}
    //  */
    // static apiToPayload(data: Object)
    // {
    // 	return {
    // 		id: data.id,
    // 		name: data.name,
    // 		color: data.color,
    // 		voltage: Number(data.voltage),
    // 		capacity: Number(data.capacity) || null,
    // 	};
    // }


    // /**
    //  * @returns {{id: number, name: string, color: string, voltage: string, capacity: string}}
    //  */
    // toApi()
    // {
    // 	return {
    // 		id: this.id,
    // 		name: this.name,
    // 		color: this.color,
    // 		voltage: String(this.voltage),
    // 		capacity: String(this.capacity),
    // 	}
    // }
}

ProductStatus.modelName = "ProductStatus";

ProductStatus.fields = {
    id: attr(), // non-relational field for any value; optional but highly recommended
};

ProductStatus.propTypes = {
    name: PropTypes.string,
};

/**
 * Fixtures
 * @type {*[]}
 */
ProductStatus.fixtures = [
    {
        id: 1,
        name: "New",
        description: "Newly introduced, available. Currently the best choice for new designs.",
        color: "#ffcc00"
    },
    {
        id: 2,
        name: "Standard",
        description: "Favorite. Currently the best choice for new designs.",
        color: "#009900"
    },
    {
        id: 3,
        name: "On request",
        description: <span>Ask <a className="moz-txt-link-abbreviated" href="mailto:sales@iqrf.org">sales@iqrf.org</a> for availability.</span>,
        color: "#993300"
    },
    {
        id: 4,
        name: "Obsolete",
        description: "A newer product may be available that have significantly more/better features. Availability possibly until sold out.",
        color: "#ff0000"
    },
    {
        id: 5,
        name: "Not available",
        description: "Not available any more. A newer product may be available that have more/better features. This is an end of life notice for this product.",
        color: "#ff0000",
    },
    {
        id: 6,
        name: "Preliminary",
        description: "Preliminary information, subject to change. Future product, not available yet.",
        color: "#993300",
    },
    {
        id: 7,
        name: "Mature",
        description: "Available. In production but there is probably a better choice for new designs. A newer product may be available that may be more cost effective and/or have more/better features. This is not an end of life notice for this product.",
        color: "#cc33cc",

    },
    {
        id: 8,
        name: "NFND",
        description: "Available. In production but Not recommended For New Designs. A newer product is available that have significantly more/better features. This is not an end of life notice for this product.",
        color: "#ff0000",
    },
    {
        id: 9,
        name: "EAP",
        description: "Available within Early Adopter Program. Beta samples, possibly with limited functionality. Intended for pre-evaluation only. Subject to change. Resale (either standalone or inside any product) or using in a final product is not allowed.",
        color: "#993300",
    },
    {
        id: 10,
        name: "Preparing",
        description: "Planned product, not available yet. No details provided yet.",
        color: "#993300",
    },
    {
    id: 11,
        name: "Free",
        description: "Free of charge",
        color: "#009900"
    },
    {
        id: 13,
        name: "Sale",
        description: "Available until sold out.",
        color: "#ff0000"
    }
];

// ProductStatus.defaultProps = {
// };
//
// ProductStatus.endpoint = "/group";

export const ADD_PRODUCT_STATUS = "ADD_PRODUCT_STATUS";
export const REMOVE_PRODUCT_STATUS = "REMOVE_PRODUCT_STATUS";
export const UPDATE_PRODUCT_STATUS = "UPDATE_PRODUCT_STATUS";