// @flow

import PropTypes from "prop-types";
import { attr } from "redux-orm";

import CRUDModel from "../ORM/CRUDModel";

export default class TrademarkType extends CRUDModel {

}

TrademarkType.modelName = "TrademarkType";

TrademarkType.fields = {
  id: attr(),
};

TrademarkType.propTypes = {
  serial: PropTypes.string,
};

TrademarkType.fixtures = [
  {
    id: 0,
    name: "Empty",
  },
  {
    id: 1,
    name: "Text",
  },
  {
    id: 2,
    name: "ColorCombination",
  },
  {
    id: 3,
    name: "Combination",
  },
];

export const ADD_TRADEMARK_TYPE = "ADD_TRADEMARK_TYPE";
export const REMOVE_TRADEMARK_TYPE = "REMOVE_TRADEMARK_TYPE";
export const UPDATE_TRADEMARK_TYPE = "UPDATE_TRADEMARK_TYPE";