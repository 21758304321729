// @flow

import PropTypes from "prop-types";
import {attr} from "redux-orm";

import CRUDModel from "../ORM/CRUDModel";


export default class FileSection extends CRUDModel {
}

FileSection.modelName = "FileSection";

FileSection.fields = {
    id: attr(), // non-relational field for any value; optional but highly recommended
	description: attr(),
	name: attr(),
};

FileSection.propTypes = {
    name: PropTypes.string.isRequired,
};

export const ADD_FILE_SECTION = "ADD_FILE_SECTION";
export const REMOVE_FILE_SECTION = "REMOVE_FILE_SECTION";
export const UPDATE_FILE_SECTION = "UPDATE_FILE_SECTION";