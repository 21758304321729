// @flow
import React from "react";
import {withTranslation} from "react-i18next";
import {Col, Grid, Row} from "react-flexbox-grid";
import "../../Components/Technology/Technology.scss";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {Helmet} from "react-helmet";
import {HashLink} from "react-router-hash-link";

/**
 * @class TechnologyLocalBondingScene
 */
class TechnologyLocalBondingScene extends React.Component {

  /**
   * Final Render
   * @returns {*}
   */
  render() {
    return (
      <div className={"page"}>
      <Helmet>
        <title>Local bonding - IQRF</title>
      </Helmet>
      <Grid>
        <Row>
        <Col xs={12}>
          <h1>Local bonding</h1>
          <h2>Adding a single Node into the network</h2>
          <ul>
            <li>The Node must be <strong>in direct RF range to the Coordinator</strong> during bonding.</li>
            <li>An <strong>action</strong> (e.g. a <strong>button-press</strong>) is <strong>required</strong> on the Node side.</li>
            <li>The Node must have <a href="https://doc.iqrf.org/IQMESH-Network-deployment/pages/preparation-of-trs.html" rel="noreferrer noopener" target="_blank">configured</a> the same <HashLink to="/technology/security#access-password">Access Password</HashLink> as the Coordinator.</li>
          </ul>
          <p>See chapter <a href="https://doc.iqrf.org/IQMESH-Network-deployment/pages/local-bonding.html" rel="noreferrer noopener" target="_blank">Local bonding</a> in <a href="https://www.iqrf.org/download/2b4e3da9-f9f5-4853-87b2-4733dcd5022f">IQMESH Network deployment</a> technical guide.</p>
        </Col>
      </Row>
    </Grid>
    </div>
  );
  }
}

/**
 * Exporting part of the React.Component file
 */
export default withTranslation()(TechnologyLocalBondingScene);
