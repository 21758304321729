// @flow

// Necessary Evil
import React from "react";
import PropTypes from "prop-types";
import {withTranslation} from "react-i18next";
// Import component CSS
import "./Tag.scss";

/**
 * @class Tag
 */
class Tag extends React.Component {

    /**
     * PropTypes
     * @type {{children: shim}}
     */
    static propTypes = {
        children: PropTypes.any,
        className: PropTypes.string,
        handleClick: PropTypes.func,
        itemId: PropTypes.any,
    };

    /**
     * Default Props
     * @type {{onClick: Tag.defaultProps.onClick}}
     */
    static defaultProps = {
        handleClick: () => {
        },
    };

    /**
     * On Click Wrapper
     */
    handleClick = () => {

        this.props.handleClick(this.props.itemId);

    };

    /**
     * Final Render
     * @returns {*}
     */
    render() {

        const {className} = this.props;

        return <div className={"tag " + className} onClick={this.handleClick}><div>{this.props.children}</div></div>;
    }
}

export default withTranslation()(Tag);