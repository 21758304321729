// @flow
import React from "react";
import {withTranslation} from "react-i18next";
import {Col, Grid, Row} from "react-flexbox-grid";
import "../../Components/Technology/Technology.scss";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {Helmet} from "react-helmet";
import {HashLink} from "react-router-hash-link";

/**
 * @class TechnologySmartConnectScene
 */
class TechnologySmartConnectScene extends React.Component {

  /**
   * Final Render
   * @returns {*}
   */
  render() {
    return (
      <div className={"page"}>
      <Helmet>
        <title>Smart Connect - IQRF</title>
      </Helmet>
      <Grid>
        <Row>
          <Col xs={12}>
            <h1>Smart Connect</h1>
            <h2>Automated adding a single Node into the network</h2>
            <ul>
              <li>The Node need not be in direct RF range with the Coordinator but must be <strong>in range with at least one discovered Node</strong>.</li>
              <li><strong>No action</strong> (e.g. a button-press) on the Node side is required.</li>
              <li>The bonding is based on the <strong>IBK</strong> and <strong>MID</strong> codes which must be <strong>imported</strong> from the Node into the Coordinator, e.g. via the <strong>QR code</strong> or <strong>NFC</strong>. <HashLink to="/technology/security#access-password">Access Password</HashLink> is inherited from the Coordinator.</li>
            </ul>
            <p>See chapter <a href="https://doc.iqrf.org/IQMESH-Network-deployment/pages/smart-connect.html" rel="noreferrer noopener" target="_blank">Smart Connect</a> in <a href="https://www.iqrf.org/download/2b4e3da9-f9f5-4853-87b2-4733dcd5022f">IQMESH Network deployment</a> technical guide.</p>
          </Col>
        </Row>
      </Grid>
      </div>
  );
}
}

/**
 * Exporting part of the React.Component file
 */
export default withTranslation()(TechnologySmartConnectScene);
