// @flow
import React from "react";
import {withTranslation} from "react-i18next";
import {Col, Grid, Row} from "react-flexbox-grid";
import {Link} from "react-router-dom";
import iqrf_cloud_principle from "../../Components/Technology/img/Clouds.png";
import {Helmet} from "react-helmet";

/**
 * @class TechnologyCloudsScene
 */
class TechnologyCloudsScene extends React.Component {


	/**
	 * Final Render
	 * @returns {*}
	 */
	render() {

		return (
			<Grid className={"page"}>
				<Helmet>
					<title>Clouds - IQRF</title>
				</Helmet>
				<Row className="page__section--bottom-padding">
					<Col xs={12}>
						<h1>Clouds</h1>
						<p>IQRF network can be connected to <strong>any cloud</strong>. Usually it is accomplished
							by an IQRF <strong>gateway</strong> created from an embedded Linux-based single-board
							computer (e.g. Raspberry Pi) with the
							<Link to={"/technology/gw-daemon"}> IQRF GW Daemon </Link>
							running. Typical communication interface is
							standard <strong>MQTT</strong> with <strong>JSON</strong>.</p>
						<div className="text--center">
							<img src={iqrf_cloud_principle} alt={iqrf_cloud_principle}/>
						</div>
						<p>Utilization of various clouds can be tested using the
							<Link to={"/product-detail/up-iqrf-iot-starterkit-01"}> UP-IQRF IoT Starter Kit 01 </Link>
							development set. It is illustrated in video tutorials:</p>
						<div className="table--wrapper">
							<table className="table--with-links">
								<tbody>
								<tr>
									<td><a href="https://www.youtube.com/watch?v=xciSSiu2n98"
										   rel="noopener noreferrer" target="_blank"><i className="icon-youtube-logo"/> IQRF network </a>
										- IoT Starter Kit - Part1: Build your IQRF Network
									</td>
								</tr>
								<tr>
									<td><a href="https://www.youtube.com/watch?v=5BzxgPCByPM"
										   rel="noopener noreferrer" target="_blank"><i className="icon-youtube-logo"/> IQRF gateway </a>
										- IoT Starter Kit - Part2: Install your IQRF Gateway
									</td>
								</tr>
								<tr>
									<td><a href="https://www.youtube.com/watch?v=Z9R2vdaw3KA"
										   rel="noopener noreferrer" target="_blank"><i className="icon-youtube-logo"/> AWS IoT </a>
										- IoT Starter Kit - Part3: Connect to the cloud - AWS IoT
									</td>
								</tr>
								<tr>
									<td><a href="https://www.youtube.com/watch?v=SIBoTrYwR2g"
										   rel="noopener noreferrer" target="_blank"><i className="icon-youtube-logo"/> Azure </a>
										- IoT Starter Kit - Part3: Connect to the cloud - Microsoft Azure
									</td>
								</tr>
								<tr>
									<td><a href="https://www.youtube.com/watch?v=xoAReOyrkZ4"
										   rel="noopener noreferrer" target="_blank"><i className="icon-youtube-logo"/> IBM Cloud </a>
										- IoT Starter Kit - Part3: Connect to the cloud - IBM Cloud
									</td>
								</tr>
								<tr>
									<td><a href="https://www.youtube.com/watch?v=V5xZqreLsLY"
										   rel="noopener noreferrer" target="_blank"><i className="icon-youtube-logo"/> InteliGlue </a>
										- How to connect to Inteliments Platform
									</td>
								</tr>
								</tbody>
							</table>
						</div>
					</Col>
				</Row>
			</Grid>

		);
	}

}

/**
 * Exporting part of the React.Component file
 */
export default withTranslation()(TechnologyCloudsScene);