// @flow
import React from "react";
import {withTranslation} from "react-i18next";
import {Col, Grid, Row} from "react-flexbox-grid";
import {Link} from "react-router-dom";
import {Helmet} from "react-helmet";

/**
 * @class ForTRUnderOSScene
 */
class ForTRUnderOSScene extends React.Component {

	/**
	 * Final Render
	 * @returns {*}
	 */
	render() {

		return (

			<Grid className={"page"}>
				<Helmet>
					<title>Examples for TR under IQRF OS - IQRF</title>
				</Helmet>
				<Row>
					<Col xs={12}>
						<h1>Examples for TR under IQRF OS</h1>
						<h2>Basic examples</h2>
						<p>Main OS functions explained.</p>
					</Col>
				</Row>
				<Row>
					<Col xs={12} md={6} xl={3}>
						<ul>
							<li>Startup (LEDs)</li>
							<li>RF transmitter</li>
							<li>RF receiver</li>
							<li>RF transceiver</li>
						</ul>
					</Col>
					<Col xs={12} md={6} xl={3}>
						<ul>
							<li>RF link/range test</li>
							<li>RAM</li>
							<li>EEPROM</li>
							<li>Extended Flash</li>
						</ul>
					</Col>
					<Col xs={12} md={6} xl={3}>
						<ul>
							<li>Timing</li>
							<li>SPI</li>
							<li>Temperature</li>
							<li>RX low power modes</li>
						</ul>
					</Col>
					<Col xs={12} md={6} xl={3}>
						<ul>
							<li>Interrupt</li>
							<li>Consumption</li>
							<li>RFPGM</li>
							<li>Template</li>
						</ul>
					</Col>
				</Row>
				<hr/>
				<Row>
					<Col xs={12}>
						<h2>Advanced examples</h2>
						<ul>
							<li>RF transfer with simple <strong>authentication</strong> (security)</li>
							<li>RF <strong>scanner</strong></li>
							<li><strong>SPI master</strong></li>
							<li>Remotely controlled <strong>servo</strong></li>
							<li>Bidirectional link between <strong>UART</strong> (RS232) and IQRF (wireless)</li>
							<li>Watchdog <strong>alarm</strong> (check whether correct packets are received in time)</li>
							<li><strong>PWM</strong> (pulse-width modulation) output</li>
							<li>Analog <strong>comparator</strong></li>
							<li><strong>D/A</strong> converter</li>
						</ul>
						<hr/>
						<h2>DDC examples</h2>
						<p>Applications using modular <Link to={"/products/development-tools/development-kits"}>DDC Kits</Link>.</p>
					</Col>
				</Row>
				<Row>
					<Col xs={12} md={6} xl={6}>
						<ul>
							<li>Remote controller</li>
							<li>Remotely controlled relays</li>
							<li>I2C bus, temperature measurement</li>
						</ul>
					</Col>
					<Col xs={12} md={6} xl={6}>
						<ul>
							<li>Analog-to-Digital Converter, voltmeter</li>
							<li>Analog-to-Digital Converter, light sensor</li>
							<li>Dallas 1-Wire bus, temperature measurement</li>
						</ul>
					</Col>
				</Row>
				<hr/>
				<Row className="page__section--bottom-padding">
					<Col xs={12}>
						<h2>IQMESH network examples</h2>
						<p>Examples for IQMESH Coordinator and Node with DFM routing, in STD and LP power modes</p>
						<hr/>
						<div className="table--wrapper">
							<table className="table--with-links">
								<tbody>
								<tr>
									<td><Link to="/download/d1bfba92-36c4-472f-929e-df78f68d7c73" target="_blank"><i className="icon-zip"/> StartUp Package </Link>
										– all examples are included in this complete starter bundle
									</td>
								</tr>
								<tr>
									{/*modalni okno*/}
									{/* // eslint-disable-next-line **/}
									{/*<td>TODO<a href=""*/}
									{/*		   rel="noopener noreferrer" target="_blank">Release notes</a>*/}
									{/*</td>*/}
								</tr>
								</tbody>
							</table>
						</div>
					</Col>
				</Row>
			</Grid>

		);
	}

}

/**
 * Exporting part of the React.Component file
 */
export default withTranslation()(ForTRUnderOSScene);
