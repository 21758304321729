// @flow

import PropTypes from "prop-types";
import {attr, fk} from "redux-orm";

import CRUDModel from "../ORM/CRUDModel";

/**
 * @class OSFunction
 */
export default class OSFunction extends CRUDModel {
}

OSFunction.modelName = "OSFunction";

OSFunction.fields = {
	id: attr(), // non-relational field for any value; optional but highly recommended
	section: fk("OSFunctionSection"),
};

OSFunction.propTypes = {
	name: PropTypes.string,
};


export const ADD_O_S_FUNCTION = "ADD_O_S_FUNCTION";
export const REMOVE_O_S_FUNCTION = "REMOVE_O_S_FUNCTION";
export const UPDATE_O_S_FUNCTION = "UPDATE_O_S_FUNCTION";