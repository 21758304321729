// @flow

// Necessary Evil
import React from "react";
import PropTypes from "prop-types";
import {withTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import Markdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import rehypeSanitize from "rehype-sanitize";
import remarkGfm from "remark-gfm";
import ProductStatus from "../ProductStatus/ProductStatus";
// Import component CSS
import "./SeriesItem.scss";

/**
 * @class SeriesItem
 */
class SeriesItem extends React.Component {

	/**
	 * PropTypes
	 * @type {{children: shim}}
	 */
	static propTypes = {
		series: PropTypes.any.isRequired,
	};

	/**
	 * Final Render
	 * @returns {*}
	 */
	render() {

		const {series} = this.props;

		return <div className="series-item">
			<div className="series-item__name">
				<Link to={"/product-detail/" + series.friendly_url}> {series.name} </Link>
				<div className="series-item__status">
					<ProductStatus status={series.status} hideHelp /><br/>
					<ProductStatus status={series.status_2} hideHelp />
				</div>
			</div>
			<div className="series-item__image">
				<Link to={"/product-detail/" + series.friendly_url}>
					{series.image__url ? <img src={process.env.REACT_APP_BACKEND_SERVER + series.image__url} alt={series.name}/> : <div className="series-item__no-photo">
						NO PHOTO AVAILABLE</div>}
				</Link>
			</div>
			<div className="series-item__description">
				<ul>
					{series.description && <li>
						<Markdown
							rehypePlugins={[rehypeRaw, rehypeSanitize]}
							remarkPlugins={[remarkGfm]}
							children={series.description}
						/>
					</li>}
					{series.description_2 && <li>
						<Markdown
							rehypePlugins={[rehypeRaw, rehypeSanitize]}
							remarkPlugins={[remarkGfm]}
							children={series.description_2}
						/>
					</li>}
				</ul>
			</div>
		</div>;
	}
}

export default withTranslation()(SeriesItem);
