// @flow

import React from "react";
import PropTypes from "prop-types";
// Import component CSS
import "./SearchBar.scss";
import InstantAction from "../../Models/Utils/InstantAction";
import {setSearch, setSearchTyping} from "../../App/App.actions";
import {withTranslation} from "react-i18next";

/**
 * @class SearchBar
 */
class SearchBar extends React.Component {

    /**
     * PropTypes
     * @type {{children: shim}}
     */
    static propTypes = {
        children: PropTypes.any,
		placeholder: PropTypes.string,
    };

    static defaultProps = {
    	placeholder: "Search...",
	};

    constructor (props) {
        super(props);

        this.state = {
            searchString: "",
        };

        this.timer = null;
    }



    searchDelayChange = (searchString) => {

        const timerTimeout = 250;

        this.timer = setTimeout(function () {
            InstantAction.dispatch(setSearch(searchString));
            InstantAction.dispatch(setSearchTyping(false));
        }, timerTimeout);
    };


    /**
     * Handle search bar change
     * @param event
     */
    handleChange = (event) => {

        const target = event.target;
        const value = target.value;

        clearTimeout(this.timer);

        // Scroll to top on change
        window.scrollTo(0, 0);

        if (value !== "" && value.length > 1) {
            InstantAction.dispatch(setSearchTyping(true));
        }

        this.setState({
            searchString: value,
        }, () => {

            if (value === "") {
                InstantAction.dispatch(setSearch(value));
                InstantAction.dispatch(setSearchTyping(false));
            } else {
                if (value.length > 2) {
                    this.searchDelayChange(value);
                }
            }
        });
    };

    render () {

        return <div className="form search-bar">
            <input
                autoFocus={true}
                type={"text"}
                name={"search"}
                value={this.state.searchString}
                placeholder={this.props.placeholder + "..."}
                onChange={this.handleChange}/>
            {/*<button className="btn" onClick={parent.closeSearch}><i className="icon-close"/></button>*/}
        </div>;
    }
}


export default withTranslation()(SearchBar);