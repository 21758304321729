// @flow

// Necessary Evil
import React from "react";
import PropTypes from "prop-types";
import { Trans, withTranslation } from "react-i18next";

import { Link } from "react-router-dom";
import LoadingComponent from "../Components/LoadingComponent";
import BackendRequest from "../Models/REST/BackendRequest";
import InstantAction from "../Models/Utils/InstantAction";
import { setLoggedUser } from "../App/App.actions";
import FormMethod from "./FormMethods";
import { Description, FormGroup } from "../Components/Form";

/**
 * @class LoginForm
 */
class LoginForm extends React.Component {

	/**
	 * PropTypes
	 * @type {{children: shim}}
	 */
	static propTypes = {
		children: PropTypes.any,
		signInUrl: PropTypes.string,
		recaptcha: PropTypes.bool,
	};

	/**
	 * Default props
	 * @type {{this.state: {}}}
	 */
	static defaultProps = {
		signInUrl: "sign-in",
		recaptcha: true,
	};

	/**
	 * State
	 * @type {{this.state: {}, expectingResponse: boolean, errorMessage: null}}
	 */
	state = {
		password: "",
		username: "",
		error: null,
		expectingResponse: false,
	};

	/**
	 * constructor
	 * @param props
	 */
	constructor (props) {
		super(props);

		FormMethod.self = this;
		this.handleInputChange = FormMethod.handleInputChange.bind(this);
	}

	/**
	 * On Success
	 * @param response
	 */
	afterSuccess = (response) => {
		/**
		 * Set status to null
		 */
		this.setState({
			expectingResponse: false,
		});

		const data = response.data;

		localStorage.setItem("JWT", data.token);

		BackendRequest({
			endpoint: "user",
			afterSuccess: (response) => {
				InstantAction.fetchAppData(this);
				InstantAction.dispatch(setLoggedUser(response.data.user.id));

				if (response.data.user.is_staff) {
					InstantAction.redirect("/editor");
				} else {
					InstantAction.redirect("/");
				}

			}
		});
	};

	/**
	 *
	 * @param value
	 */
	onCaptcha = (value) => {

		this.setState({
			reCaptcha: value
		});

	};

	/**
	 * Handle submit form
	 * @warning DO NOT CHANGE!
	 * @param event
	 */
	handleSubmit = (event) => {
		/**
		 * @info Prevent default submitting action
		 */
		event.preventDefault();

		this.setState({ expectingResponse: true });

		/**
		 * On Error
		 * @param error
		 */
		const onError = (error) => {

			if (error.hasOwnProperty("response")) {

				if (error.response.status === 401 || error.response.status === 400) {

					localStorage.removeItem("JWT");

					this.setState({
						error: "WRONG_PASSWORD_OR_ACCOUNT_DOES_NOT_EXISTS",
						expectingResponse: false,
					});
				} else if (error.response.status === 500) {
					this.setState({
						error: "INTERNAL_SERVER_ERROR",
						expectingResponse: false,
					});
				} else {
					this.setState({
						error: error.response.data,
						expectingResponse: false,
					});
				}
			} else {
				console.log(error);
			}
		};

		/**
		 * @info Make API request to backend
		 */
		BackendRequest({
			method: "post",
			endpoint: this.props.signInUrl,
			payload: {
				username: this.state.username,
				password: this.state.password,
			},
			self: this,
			afterSuccess: this.afterSuccess,
			onError: onError,
		});
	};

	/**
	 * Final Render
	 * @returns {*}
	 */
	render () {

		/**
		 * @info Translation function, className
		 */
		const { t } = this.props;
		/**
		 * @info Error variable
		 */
		let error = "";
		/**
		 * @info Handling Error Message
		 */
		if (this.state.error !== null) {
			error = <div style={{ color: "orange", fontWeight: 700 }}><i
				className="icon-cube"/> {t("error." + this.state.error)}</div>;
		}

		/**
		 * Final Output
		 * @type {{}}
		 */
		return <form onSubmit={this.handleSubmit} className={"form"}>

			<FormGroup>
				<label htmlFor="username">{t("label.Account")}</label>
				<input onChange={this.handleInputChange} value={this.state.username} type="text" tabIndex={1}
					   name="username" id="username"

					   autoFocus={true} placeholder={t("label.Email")}/>
			</FormGroup>
			<FormGroup>
				<label htmlFor="password">{t("label.Password")}</label>
				<input onChange={this.handleInputChange} value={this.state.password} type="password"
					   tabIndex={2}
					   name="password" id="password"
					   autoFocus={true} placeholder={t("label.Password")}/>
			</FormGroup>
			{error}
			<br/>
			<FormGroup style={{ display: "flex", justifyContent: "center" }}>
				{(this.state.expectingResponse) ? <LoadingComponent/> :

					<button type="submit" tabIndex={4} className="btn btn--animated">{t("button.SignIn")}</button>
				}
			</FormGroup>

			<br/>
			<Description inline={false} className={"text--center"}>
				<Link className="link" to={"/forgotten-password"}>{t("link.ForgottenPassword")}</Link> | <Trans
				i18nKey={"content.DoNotHaveAccountYet"}>Do not have account yet?</Trans> <Link className="link"
																							   to={"/sign-up"}>{t("link.SignUp")}</Link>
			</Description>
		</form>;
	}
}

export default withTranslation()(LoginForm);