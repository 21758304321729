// @flow
import React from "react";
import {withTranslation} from "react-i18next";
import {Col, Grid, Row} from "react-flexbox-grid";
import BackendRequest from "../../Models/REST";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";

import MasterGetter from "../../Models/Utils/MasterGetter";
import DistributorForm from "../../Forms/DistributorForm";

/**
 * @class DistributorScene
 */
class DistributorScene extends React.Component {

    /**
     * State
     * @type {{expectingResponse: boolean}}
     */
    state = {
        expectingResponse: true,
    };

    /**
     * Fetch Data
     */
    fetchData = () => {

    	const {match} = this.props;

        BackendRequest({
            endpoint: "distributor/"+match.params.id,
            self: this,
        });

    };

    /**
     * Component Did Mount
     */
    componentDidMount() {
        this.fetchData();
    }

    /**
     * Final Render
     * @returns {*}
     */
    render() {

        const {
            entities,
            match,
        } = this.props;

        const session = MasterGetter.getSession(entities);
        const itemId = match.params.id;
        const distributor = session.Distributor.withId(itemId);

        return (
            <Grid fluid>
                <Row>
                    <Col xs={12} className="page__section--bottom-padding">
                        <h1>Distributor Editor</h1>
                    </Col>
					<Col xs={12}>
						<button className="btn" onClick={() => window.history.back()}>Cancel <i className="icon-back-arrow"/></button>
					</Col>
                    <Col xs={12}>
						<DistributorForm item={distributor} />
                    </Col>
                </Row>
            </Grid>
        );
    }

}

/**
 * Map state to props
 * @param state
 * @return {{}}
 */
const mapStateToProps = state => (
    {
        entities: state.entities,
    });

/**
 * Exporting part of the React.Component file
 */
export default withRouter(connect(mapStateToProps)(withTranslation()(DistributorScene)));