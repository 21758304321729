// @flow

// Necessary Evil
import React from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import { Col, Row } from "react-flexbox-grid";
import FormGroup from "../Components/Form/FormGroup";
import Markdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import rehypeSanitize from "rehype-sanitize";
import remarkGfm from "remark-gfm";
// Import component CSS
// import "./ProductFormOptionalBox.scss";
//

/**
 * @class ProductFormOptionalBox
 */
class ProductFormOptionalBox extends React.Component {

	/**
	 * PropTypes
	 * @type {{children: *}}
	 */
	static propTypes = {
		children: PropTypes.any,
		parent: PropTypes.any,
		boxNumber: PropTypes.number,
	};

	/**
	 * Final Render
	 * @returns {*}
	 */
	render () {

		const {
			parent,
			boxNumber,
		} = this.props;

		const title = parent.state[("box_" + boxNumber + "_title")];
		const content = parent.state[("box_" + boxNumber + "_content")];
		const openMode = parent.state[("box_" + boxNumber + "_open")];

		return <Row>
			<Col xs={12} lg={6}>
				<FormGroup>
					<h3>Content box {boxNumber}</h3>
					<label htmlFor={("box_" + boxNumber + "_title")}>Title</label>
					<input onChange={parent.handleInputChange}
						   value={title}
						   type="text"
						   name={("box_" + boxNumber + "_title")}
						   id={("box_" + boxNumber + "_title")}
					/>
					<label htmlFor={("box_" + boxNumber + "_title")}>Content</label>
					<textarea
						onChange={parent.handleInputChange}
						value={content}
						name={("box_" + boxNumber + "_content")}
						id={("box_" + boxNumber + "_content")}
					/>
					<em>(Use markdown - <a target="_blank"
										   href="https://github.com/adam-p/markdown-here/wiki/Markdown-Cheatsheet"
										   rel="noopener noreferrer">https://github.com/adam-p/markdown-here/wiki/Markdown-Cheatsheet</a>)</em>
					<select onChange={parent.handleInputChange}
							value={openMode}
							name={("box_" + boxNumber + "_open")}
							id={("box_" + boxNumber + "_open")}
					>
						<option value={2}>Normal</option>
						<option value={1}>On click</option>
					</select>
				</FormGroup>
			</Col>
			<Col xs={12} lg={6}>
				<FormGroup>
					<label>Content preview</label>
					<Markdown
						rehypePlugins={[rehypeRaw, rehypeSanitize]}
						remarkPlugins={[remarkGfm]}
						children={content}
					/>
				</FormGroup>
			</Col>
		</Row>;
	}
}

export default withTranslation()(ProductFormOptionalBox);
