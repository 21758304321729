// @flow
import React from "react";
import {withTranslation} from "react-i18next";
import {Col, Grid, Row} from "react-flexbox-grid";
import offlinefrc from "../../Components/Technology/video/Beaming-and-Offline-FRC.mp4";
import {Helmet} from "react-helmet";
import {Link} from "react-router-dom";

/**
 * @class TechnologyOfflineFRCScene
 */
class TechnologyOfflineFRCScene extends React.Component {


  /**
   * Final Render
   * @returns {*}
   */
  render() {

    return (
      <div className={"page"}>
      <Helmet>
      <title>Beaming and Offline FRC - IQRF</title>
    </Helmet>
    <Grid>
    <Row className="page__section--bottom-padding">
      <Col xs={12}>
      <h1>Beaming and Offline FRC</h1>
      <h2>Asynchronous sending data from a low power sensor and its collection</h2>
      <p>Beaming enables to operate sensors with outstandingly low power.</p>
      <p>A <strong>Beaming sensor</strong> (e.g. <a href="https://www.iqrfalliance.org/marketplace/microrisc-industrial-pro" target="_blank" rel="noreferrer noopener">Sensor T+RH Industrial Pro</a>) is a Node sensor device working in <a href="https://doc.iqrf.org/IQMESH-Network-deployment/pages/data-aggregating-from-beaming-sensors.html" target="_blank" rel="noreferrer noopener">Offline mode</a> and communicating <strong>unidirectionally</strong> and <strong>asynchronously</strong> with Aggregating repeaters only.</p>
      <p>An <strong>Aggregating repeater</strong> (e.g. <Link to="/product-detail/iqd-rep-02">IQD-REP-02</Link>) is
    a repeater providing not only a common routing but additionally concurrently serves as an interface to <strong>collect data from Beaming sensors</strong> and <strong>forward it to IQMESH network.</strong>.</p>
      <p>A Beaming sensor, once bonded, works in <strong>Offline mode</strong>. It is <strong>sleeping</strong> all the time and
    periodically or on some event <strong>asynchronously</strong> sends data to all Aggregating repeaters <strong>in direct RF range</strong>. The Aggregating repeaters store the received data from every
    Beaming sensor into the buffer. The data can be read out
    synchronously by the Coordinator using the <strong>Offline FRC</strong>. The Offline FRC is an analogy to the common FRC
    intended to read out common (non-Beaming) sensors. It operates identically as common FRC but skips the individual
    phase (phase 2 in <Link to="/frc-animation">FRC animation</Link>) within the FRC response.</p>
      <p>See chapter <a href="https://doc.iqrf.org/IQMESH-Network-deployment/pages/offline-frc.html" rel="noreferrer noopener" target="_blank">Offline FRC</a> in <Link to="/download/2b4e3da9-f9f5-4853-87b2-4733dcd5022f">IQMESH Network deployment</Link> technical guide.</p>
    </Col>
    </Row>
    </Grid>
    <Grid>
    <Row>
      <Col xs={12}>
    <div className="text--center" id="beaming-animation">
      <video width="560" controls>
      <source src={offlinefrc}/>
      <p>Your browser does not support the video tag.</p>
      </video>
    </div>
    </Col>
    </Row>
    </Grid>
      <Grid>
    <Row>
    <Col xs={12}>
      <p>&nbsp;</p>
    </Col>
    </Row>
    </Grid>
    </div>

  );
  }

}

/**
 * Exporting part of the React.Component file
 */
export default withTranslation()(TechnologyOfflineFRCScene);
