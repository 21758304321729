// @flow

// Necessary Evil
import React from "react";
import PropTypes from "prop-types";
import {withTranslation} from "react-i18next";
import {Col} from "react-flexbox-grid";
import {Link} from "react-router-dom";
// Import component CSS
import "./CaseStudyItem.scss";


/**
 * @class CaseStudyItem
 */
class CaseStudyItem extends React.Component {

    /**
     * PropTypes
     * @type {{children: shim}}
     */
    static propTypes = {
        caseStudy: PropTypes.any.isRequired,
    };

    /**
     * Final Render
     * @returns {*}
     */
    render() {

        const {caseStudy} = this.props;

        return <Col xs={12} lg={3}>
            <Link to={"/case-study/" + caseStudy.friendly_url}>
                <div className="case-study-item">
                    <div className="case-study-item__photo">
                        {caseStudy.image !== null ?
                            <img src={process.env.REACT_APP_BACKEND_SERVER + caseStudy.image.url} alt={caseStudy.name}/> : "NO PHOTO"}
                    </div>
                    <h4>{caseStudy.name}</h4>
                </div>
            </Link>
        </Col>;
    }
}

export default withTranslation()(CaseStudyItem);