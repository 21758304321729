// @flow

import PropTypes from "prop-types";
import {attr} from "redux-orm";

import CRUDModel from "../ORM/CRUDModel";

/**
 * @class OSFunctionSection
 */
export default class OSFunctionSection extends CRUDModel {}

OSFunctionSection.modelName = "OSFunctionSection";

OSFunctionSection.fields = {
    id: attr(), // non-relational field for any value; optional but highly recommended
};

OSFunctionSection.propTypes = {
    name: PropTypes.string,
};


export const ADD_O_S_FUNCTION_SECTION = "ADD_O_S_FUNCTION_SECTION";
export const REMOVE_O_S_FUNCTION_SECTION = "REMOVE_O_S_FUNCTION_SECTION";
export const UPDATE_O_S_FUNCTION_SECTION = "UPDATE_O_S_FUNCTION_SECTION";