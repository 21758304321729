// @flow
import React from "react";
import {withTranslation} from "react-i18next";
import {Col, Grid, Row} from "react-flexbox-grid";
import pdf_ico from "../../App/extra-icons/pdf_ico.png";
import {Helmet} from "react-helmet";
import {Link} from "react-router-dom";

/**
 * @class TechnologyCurrentOSScene
 */
class TechnologyCurrentOSScene extends React.Component {


	/**
	 * Final Render
	 * @returns {*}
	 */
	render() {

		return (
			<Grid className={"page"}>
				<Helmet>
					<title>Current OS versions for archive TRs - IQRF</title>
				</Helmet>
				<Row className="page__section--bottom-padding">
					<Col xs={12}>
						<h1>Current OS versions for archive TRs</h1>
						<p>For <strong>TR-52D, TR-54D, TR-55D, TR-56D</strong> and <strong>TR-58D-RJ</strong>
							: <em>OS v3.08D for TR-5xD</em></p>
						<div className="table--wrapper">
							<table className="table--with-links">
								<tbody>
								<tr>
									<td><Link to="/download/fca30c5c-2853-4013-8316-07928c19c436"
										   target="_self"><img alt="icon" src={pdf_ico}/> User's Guide</Link> –
										<strong> OS v3.08D</strong></td>
									<td>21 Sep 2016</td>
								</tr>
								<tr>
									<td><Link to="/download/5b665a6f-3320-4f28-ab84-e20c9a49a1b9"
										   target="_self"><img alt="icon" src={pdf_ico}/> Reference Guide</Link> –
										<strong> OS v3.08D</strong></td>
									<td>21 Sep 2016</td>
								</tr>
								</tbody>
							</table>
						</div>
						<p>For <strong>TR-52B</strong> and <strong>TR-53B</strong>: <em>OS v3.00</em></p>
						<div className="table--wrapper">
							<table className="table--with-links">
								<tbody>
								<tr>
									<td><Link to="/download/b132740b-7020-440c-9436-61a901807fc5"
										   target="_self"><img alt="icon" src={pdf_ico}/> User's Guide</Link> –
										<strong> OS v3.00</strong></td>
									<td>13 Dec 2011</td>
								</tr>
								<tr>
									<td><Link to="/download/c1b4047d-4859-47ba-998e-f7c903038d13"
										   target="_self"><img alt="icon" src={pdf_ico}/> Reference Guide</Link> –
										<strong> OS v3.00</strong></td>
									<td>21 Jun 2011</td>
								</tr>
								</tbody>
							</table>
						</div>
						<p>For <strong>TR-31B</strong> and <strong>TR-32B</strong>: <em>OS v2.10</em></p>
						<div className="table--wrapper">
							<table className="table--with-links">
								<tbody>
								<tr>
									<td><Link to="/download/b851a32b-545b-4d98-9a28-4fedf6d112a6"
										   target="_self"><img alt="icon" src={pdf_ico}/> User's Guide</Link> –
										<strong> OS v2.10</strong></td>
									<td>19 Aug 2009</td>
								</tr>
								<tr>
									<td><Link to="/download/7a59741c-9f47-4a2c-b93c-69ca02559a32"
										   target="_self"><img alt="icon" src={pdf_ico}/> Reference Guide</Link> –
										<strong> OS v2.10</strong></td>
									<td>18 Jan 2010</td>
								</tr>
								</tbody>
							</table>
						</div>
						<p>For <strong>TR-11A</strong> and <strong>TR-21A</strong>: <em>OS v2.08</em></p>
						<div className="table--wrapper">
							<table className="table--with-links">
								<tbody>
								<tr>
									<td><Link to="/download/4e3a2d09-6196-4ad7-881b-d9e14cbbdcb6"
										   target="_self"><img alt="icon" src={pdf_ico}/> User's Guide</Link> –
										<strong> OS v2.08</strong></td>
									<td>4 May 2009</td>
								</tr>
								</tbody>
							</table>
						</div>
					</Col>
				</Row>
			</Grid>

		);
	}

}

/**
 * Exporting part of the React.Component file
 */
export default withTranslation()(TechnologyCurrentOSScene);
