// @flow

import PropTypes from "prop-types";
import {attr} from "redux-orm";

import CRUDModel from "../ORM/CRUDModel";

/**
 * @class DownloadSection
 */
export default class DownloadSection extends CRUDModel {
}

DownloadSection.modelName = "DownloadSection";

DownloadSection.fields = {
    id: attr(), // non-relational field for any value; optional but highly recommended
	description: attr(),
	name: attr(),
};

DownloadSection.propTypes = {
    name: PropTypes.string.isRequired,
};

export const ADD_DOWNLOAD_SECTION = "ADD_DOWNLOAD_SECTION";
export const REMOVE_DOWNLOAD_SECTION = "REMOVE_DOWNLOAD_SECTION";
export const UPDATE_DOWNLOAD_SECTION = "UPDATE_DOWNLOAD_SECTION";