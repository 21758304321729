// @flow

// Necessary Evil
import React from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
// Import component CSS
import "./DistributorItemAdmin.scss";
import { GenericItem } from "../../GenericItem";
import InstantAction from "../../../Models/Utils/InstantAction";

/**
 * @class DistributorItemAdmin
 */
class DistributorItemAdmin extends React.Component {

	/**
	 * PropTypes
	 * @type {{children: shim}}
	 */
	static propTypes = {
		children: PropTypes.any,
		distributor: PropTypes.any,
	};

	/**
	 * Final Render
	 * @returns {*}
	 */
	render () {

		const {
			distributor
		} = this.props;

		if (!distributor.country) {
			return <GenericItem item={distributor} nameProperty={"name"}
								onClick={() => InstantAction.redirect("/editor/distributor/" + distributor.id)}/>;
		}

		return <Link to={"/editor/distributor/" + distributor.id}>
			<div className={"distributor-item-admin"}>
				<img
					src={process.env.REACT_APP_BACKEND_SERVER + "static/media/img/country/" + distributor.country.iso_code.toLowerCase() + ".png"}
					alt={distributor.country.name + " flag"}
					className="distributor-item-admin__flag"
				/>
				{distributor.name} ({distributor.country.name})
			</div>
		</Link>;
	}
}

export default withTranslation()(DistributorItemAdmin);