// @flow
import React from "react";
import {withTranslation} from "react-i18next";
import {Col, Grid, Row} from "react-flexbox-grid";
import ReleaseNotesLoader from "../../Components/RelaseNotesLoader/ReleaseNotesLoader";
import {Helmet} from "react-helmet";

/**
 * @class TechnologyIDEHistoryCommandScene
 */
class TechnologyIDEHistoryCommandScene extends React.Component {


	/**
	 * Final Render
	 * @returns {*}
	 */
	render() {

		return (
			<Grid className={"page"}>
				<Helmet>
					<title>IDE - Command - IQRF</title>
				</Helmet>
				<Row className="page__section--bottom-padding">
					<Col xs={12}>
						<h1>IQRF IDE - Command</h1>
						<h2>Release notes</h2>
						<ReleaseNotesLoader code={"history-command"}/>
					</Col>
				</Row>
			</Grid>

		);
	}

}

/**
 * Exporting part of the React.Component file
 */
export default withTranslation()(TechnologyIDEHistoryCommandScene);