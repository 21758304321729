// @flow
import React from "react";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import {withRouter} from "react-router-dom";
import {Col, Grid, Row} from "react-flexbox-grid";
import BackendRequest from "../../Models/REST";
import MasterGetter from "../../Models/Utils/MasterGetter";
import Markdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import rehypeSanitize from "rehype-sanitize";
import remarkGfm from "remark-gfm";
import {Helmet} from "react-helmet";

/**
 * @class CaseStudyScene
 */
class CaseStudyScene extends React.Component {

    /**
     * State
     * @type {{expectingResponse: boolean}}
     */
    state = {
        expectingResponse: true,
    };

    /**
     * Fetch Data
     */
    fetchData = () => {

        const {match} = this.props;

        BackendRequest({
            endpoint: "case-study/" + match.params.friendly_url,
            self: this,
        });

    };

    /**
     * Component did mount
     */
    componentDidMount() {

        this.fetchData();

    }

    /**
     * Final Render
     * @returns {*}
     */
    render() {

        const {entities, match} = this.props;
        const session = MasterGetter.getSession(entities);
        const studies = session.CaseStudy.filter(item => item.friendly_url === match.params.friendly_url);

        return (
            <Grid className={"page"}>
                <Row>
                    <Col xs={12} className="page__section--bottom-padding">
                        {studies.toModelArray().map(study => {
                            return (
                                <div key={study.id}>
                                    <Helmet>
                                        <title>{study.title} - IQRF</title>
                                    </Helmet>
                                    <h1>{study.title}</h1>
                                    <Markdown
                                      rehypePlugins={[rehypeRaw, rehypeSanitize]}
                                      remarkPlugins={[remarkGfm]}
                                      children={study.content}
                                    />
                                </div>
                            );
                        })}
                    </Col>
                </Row>
            </Grid>
        );
    }

}

/**
 * This function maps the state to a
 * prop called `state`.
 *
 * In larger apps it is often good
 * to be more selective and only
 * map the part of the state tree
 * that is necessary.
 */
const mapStateToProps = state => (
    {
        app: state.app,
        entities: state.entities,
    });

/**
 * Exporting part of the React.Component file
 */
export default withRouter(connect(mapStateToProps)(withTranslation()(CaseStudyScene)));
