// @flow
import React from "react";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import BackendRequest from "../../Models/REST";
import MasterGetter from "../../Models/Utils/MasterGetter";


import "../../Components/FAQ/FAQ.scss";
import {Col, Grid, Row} from "react-flexbox-grid";
import LoadingComponent from "../../Components/LoadingComponent";
import {FAQItem} from "../../Components/FAQ";
import AppContainer from "../../App/AppContainer";
import {Helmet} from "react-helmet";

/**
 * @class FAQScene
 */
class FAQScene extends React.Component {

	/**
	 * State
	 * @type {{expectingResponse: boolean}}
	 */
	state = {
		expectingResponse: true,
	};

	/**
	 * Fetch data from REST API
	 */
	fetchData() {

		BackendRequest({
			endpoint: "faq",
			self: this,
			afterSuccess: () => {
				AppContainer.hashLinkScroll();
			}
		});

	}

	/**
	 * Component will mount
	 */
	componentDidMount() {
		this.fetchData();
	}

	/**
	 * Final Render
	 * @returns {*}
	 */
	render() {

		const {
			t,
			entities
		} = this.props;

		const session = MasterGetter.getSession(entities);
		const sections = session.FAQSection.all().orderBy(["list_order"], ["asc"]);
		const questions = session.FAQuestion.all();

		return (
			<Grid className={"page"}>
				<Helmet>
					<title>{t("title.FAQ")} - IQRF</title>
				</Helmet>
				<Row>
					<Col xs={12}>
						<h1>{t("title.FAQ")}</h1>
						<div className="page__section--bottom-padding">
							{this.state.expectingResponse ? <LoadingComponent/> : ""}
							{sections.toModelArray().map(section => {

								let filteredQuestions = questions.filter(item => item.section === section.id);

								return <div className="faq-section" key={section.id}>
									<h2 className="faq-section__title">{section.name}</h2>
									{filteredQuestions.toModelArray().map(question => {

										return <FAQItem question={question} key={question.id}/>;
									})}
									{filteredQuestions.count() === 0 ?
										<div className="faq-item__availability">No questions available</div> : ""}
								</div>;
							})}
						</div>
					</Col>
				</Row>
			</Grid>

		);
	}

}

/**
 *
 * @param state
 * @return {{entities: {FAQuestion: *}}}
 */
const mapStateToProps = state => (
	{
		entities: {
			FAQuestion: state.entities.FAQuestion,
			FAQSection: state.entities.FAQSection,
		},
	});


/**
 * Exporting part of the React.Component file
 */
export default connect(mapStateToProps)(withTranslation()(FAQScene));