// @flow
import React from "react";
import {withTranslation} from "react-i18next";
import {Col, Grid, Row} from "react-flexbox-grid";
import BackendRequest from "../../Models/REST";
import MasterGetter from "../../Models/Utils/MasterGetter";
import {Link} from "react-router-dom";
import EventAdminItem from "../../Components/Editor/Events/EventAdminItem";

/**
 * @class EventsScene
 */
class EventsScene extends React.Component {


	/**
	 * Fetch Data
	 */
	fetchData = () => {

		BackendRequest({
			endpoint: "events/1000",
			self: this,
		});

	};

	/**
	 * Component did Mount
	 */
	componentDidMount = () => {

		this.fetchData();

	};


	/**
	 * Final Render
	 * @returns {*}
	 */
	render() {

		// const {t} = this.props;
		const {
			entities
		} = this.props;

		const session = MasterGetter.getSession(entities);
		const events = session.Event.all().orderBy(["ordering", "starts"], ["desc", "desc"]);

		return (
			<div>
				<Grid fluid>
					<Row>
						<Col xs={12}>
							<h1>Events</h1>
							<div>
								<Link to={"/editor/event/item"} className="btn">Create New</Link>
							</div>
							<br/>
							{events.toModelArray().map(item => {
								return <EventAdminItem event={item}/>;
							})}
							<div>
								{events.count() > 0 ? "" : "No events"}
							</div>
						</Col>
					</Row>
				</Grid>
			</div>
		);
	}

}

/**
 * Exporting part of the React.Component file
 */
export default withTranslation()(EventsScene);