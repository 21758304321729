// @flow
import React from "react";
import {withTranslation} from "react-i18next";
import {Col, Grid, Row} from "react-flexbox-grid";
import {Link} from "react-router-dom";

import arduino from "../../Components/Technology/img/Arduino.png";
import beaglebone from "../../Components/Technology/img/BeagleBone.png";
import device from "../../Components/Technology/img/Proprietary-device.jpg";
import mcu from "../../Components/Technology/img/Standalone-MCU.png";
import {Helmet} from "react-helmet";
import {HashLink} from "react-router-hash-link";


/**
 * @class TechnologySDKScene
 */
class TechnologySDKScene extends React.Component {


	/**
	 * Final Render
	 * @returns {*}
	 */
	render() {

		return (

			<Grid className={"page"}>
				<Helmet>
					<title>SDK - IQRF</title>
				</Helmet>
				<Row>
					<Col xs={12}>
						<h1>SDK</h1>
						<h2><strong>Open source</strong> software development package for various devices used with IQRF
						</h2>
						<p><b>Wherever <span className="text--orange">C++</span> or <span
							className="text--orange">C</span> runs, you can easily implement wireless.</b></p>
						<p>IQRF <b>SDK</b> (Software Development Kit) enables easy development of IQRF wireless network
							applications implemented with devices programmable in <b>C++</b> or <b>C</b>. It is a
							package of ready-to-use <b>libraries</b> for <b>MCUs</b> or <b>single-board computers</b>
							connected to TRs via <b>SPI, UART</b> or <b>USB CDC</b>.</p>
						<p>IQRF SDK relates just to <b>libraries</b> (intended to <b>write a user's own
							application</b>). For the <b>complete gateway</b> SW implementation use
							<Link to={"/technology/gw-daemon"}> IQRF Gateway Daemon </Link>
							(under <b>Linux</b> and <b>DPA</b>).</p>
						<hr/>
						<h3>Platforms</h3>
						<ul>
							<li>Machines without an operating system:
								<ul>
									<li>Standard single-board computers like <b>Arduino, chipKIT</b>, …</li>
									<li>Standalone <b>MCUs</b></li>
									<li>Any <b>proprietary device</b> with an MCU</li>
								</ul>
							</li>
							<li><b>Linux</b> machines (<b>Raspberry Pi, BeagleBone</b>, …)</li>
						</ul>
						<hr/>
						<h3>Targets</h3>
						<p>Either for IQRF end devices or gateways. Even gateways without an operating system are
							connectable to <b>MQTT</b> broker and can communicate via messages in <b>JSON</b> format.
							But the easiest approach for a gateway is
							<Link to={"/technology/gw-daemon"}> IQRF Gateway Daemon </Link>
							under Linux. A basic complete IQRF gateway <b>IQD-GW-01</b> based on the Daemon
							is available.</p>
					</Col>
				</Row>
				<Row>
					<Col className="text--center" xs={12} md={6} xl={3}>
						<p><b>Arduino</b>, ...<br/>
							No OS</p>
						<img src={arduino} alt={"Arduino"}/>
					</Col>
					<Col className="text--center" xs={12} md={6} xl={3}>
						<p><b>Standalone MCU</b><br/>
							No OS</p>
						<img src={mcu} alt={"Standalone MCU"}/>
					</Col>
					<Col className="text--center" xs={12} md={6} xl={3}>
						<p><b>Proprietary</b> device</p>
						<br/>
						<img src={device} alt={"Proprietary device"}/>
					</Col>
					<Col className="text--center" xs={12} md={6} xl={3}>
						<p><b>BeagleBone</b>, …<br/>
							Linux</p>
						<img src={beaglebone} alt={"BeagleBone"}/>
					</Col>
				</Row>
				<Row>
					<Col xs={12}>
						<HashLink to={"/products/development-tools/development-kits#shield-and-breakout-boards"}>
							» IQRF shields and breakout boards
						</HashLink>
						<hr/>
						<h3 id="libraries">Libraries</h3>
					</Col>
				</Row>
				<Row className="page__section--bottom-padding">
					<Col xs={12} lg={4}>
						<p>Without an operating system</p>
						<ul>
							<li><a href="https://github.com/iqrfsdk/clibspi-mcu" rel="noopener noreferrer" target="_blank">SPI</a></li>
							<li><a href="https://github.com/iqrfsdk/clibdpa-mcu" rel="noopener noreferrer" target="_blank">DPA</a></li>
						</ul>
					</Col>
					<Col xs={12} lg={4}>
						<p>Linux</p>
						<ul>
							<li><a href="https://github.com/iqrfsdk/clibcdc" rel="noopener noreferrer" target="_blank">CDC</a></li>
							<li><a href="https://github.com/iqrfsdk/clibspi" rel="noopener noreferrer" target="_blank">SPI</a></li>
							<li><a href="https://github.com/iqrfsdk/clibuart" rel="noopener noreferrer" target="_blank">UART</a></li>
							<li><a href="https://github.com/iqrfsdk/clibdpa" rel="noopener noreferrer" target="_blank">DPA</a></li>
						</ul>
					</Col>
					<Col xs={12} lg={4}>
						<p>Windows</p>
						<ul>
							<li><a href="https://github.com/iqrfsdk/clibcdc" rel="noopener noreferrer" target="_blank">CDC</a></li>
							<li><a href="https://github.com/iqrfsdk/clibdpa" rel="noopener noreferrer" target="_blank">DPA</a></li>
						</ul>
					</Col>
				</Row>
			</Grid>

		);
	}

}

/**
 * Exporting part of the React.Component file
 */
export default withTranslation()(TechnologySDKScene);