// @flow
import React from "react";
import {withTranslation} from "react-i18next";
import {Col, Grid, Row} from "react-flexbox-grid";
import {Link} from "react-router-dom";
import {Helmet} from "react-helmet";

/**
 * @class TechnologyScene
 */
class TechnologyScene extends React.Component {


	/**
	 * Final Render
	 * @returns {*}
	 */
	render() {

		const {t} = this.props;

		return (
			<Grid className={"page"}>
				<Helmet>
					<title>{t("title.IQRFTechnology")} - IQRF</title>
				</Helmet>
				<Row>
					<Col xs={12}>
						<h1>{t("title.IQRFTechnology")}</h1>
					</Col>
					<Col xs={12} className="page__section--bottom-padding">
						<ul>
							<li><Link to={"/technology/rf"}>RF</Link></li>
							<li><Link to={"/technology/iqmesh"}>IQMesh<sup>&reg;</sup></Link></li>
							<li><Link to={"/technology/frc"}>FRC<sup>&reg;</sup></Link></li>
							<li><Link to={"/technology/os"}>OS</Link></li>
							<li><Link to={"/technology/dpa"}>DPA</Link></li>
							<li><Link to={"/technology/ide"}>IDE</Link></li>
							<li><Link to={"/technology/gw-daemon"}>GW Daemon</Link></li>
							<li><Link to={"/technology/sdk"}>SDK</Link></li>
							<li><Link to={"/technology/repository"}>Repository</Link></li>
							<li><Link to={"/technology/clouds"}>Clouds</Link></li>

						</ul>
					</Col>
				</Row>
			</Grid>

		);
	}

}

/**
 * Exporting part of the React.Component file
 */
export default withTranslation()(TechnologyScene);