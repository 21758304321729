// @flow

// Necessary Evil
import React from "react";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import {withRouter} from "react-router-dom";
import LoadingComponent from "../../LoadingComponent";
import BackendRequest from "../../../Models/REST";
import MasterGetter from "../../../Models/Utils/MasterGetter";
import {CaseStudyItem} from "../index";
import {Row} from "react-flexbox-grid";


/**
 * @class CaseStudiesLoader
 */
class CaseStudiesLoader extends React.Component {

    /**
     *
     * @type {{expectingResponse: boolean}}
     */
    state = {
        expectingResponse: true,
    };

    /**
     * Fetch Data
     */
    fetchData = () => {

        BackendRequest({
            endpoint: "case-studies",
            self: this,
        });

    };

    /**
     *
     */
    componentDidMount(){

        this.fetchData();

    }

    /**
     * Final Render
     * @returns {*}
     */
    render() {

        if(this.state.expectingResponse){
            return <LoadingComponent />;
        }

        const {entities} = this.props;

        const session = MasterGetter.getSession(entities);
        const studies = session.CaseStudy.all().orderBy(["ordering"],["desc"]);

        return (
            <Row>
                {studies.toModelArray().map(study => {
                    return <CaseStudyItem key={study.id} caseStudy={study} />;
                })}
            </Row>
        );
    }

}

/**
 * This function maps the state to a
 * prop called `state`.
 * In larger apps it is often good
 * to be more selective and only
 * map the part of the state tree
 * that is necessary.
 */
const mapStateToProps = state => (
    {
        entities: {
            CaseStudy: state.entities.CaseStudy,
            Photo: state.entities.Photo,
        },
    });

/**
 * Exporting part of the React.Component file
 */
export default withRouter(connect(mapStateToProps)(withTranslation()(CaseStudiesLoader)));