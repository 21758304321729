// @flow

// Necessary Evil
import React from "react";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import {withRouter, Link} from "react-router-dom";
import {Col, Row} from "react-flexbox-grid";

import "./WhyBox.scss";

import lowPowerPicture from "../../../App/images/why/low_power.svg";
import licencePicture from "../../../App/images/why/licence_and_carrier_free.svg";
import interoperabilityPicture from "../../../App/images/why/interoperability.svg";
import simpleAdoptionPicture from "../../../App/images/why/simple_adoption.svg";
import industrialPicture from "../../../App/images/why/industrial_reliability.svg";
import ecosystemPicture from "../../../App/images/why/ecosystem.svg";
import securityPicture from "../../../App/images/why/ultimate_security.svg";
import provenPicture from "../../../App/images/why/proven.svg";

/**
 * @class WhyBox
 */
class WhyBox extends React.Component {

    /**
     * Final Render
     * @returns {*}
     */
    render() {
        return (
            <Row className="why-box">
                <Col xs={12} lg={6}>
                    <div className="why-box__item">
                        <Link to={"/technology/low-power-modes"}>
                            <div className="why-box__picture">
                                <img src={lowPowerPicture} alt={"low power"}/>
                            </div>
                        </Link>
                        <div className="why-box__content">
                            <h3><Link to={"/technology/low-power-modes"} className="text--orange">Low power</Link></h3>
                            <p>
                                Years on a battery
                            </p>
                        </div>
                    </div>
                </Col>
                <Col xs={12} lg={6}>
                    <div className="why-box__item">
                        <Link to={"/technology/license-carrier-free"}>
                        <div className="why-box__picture">
                            <img src={licencePicture} alt={"licence"}/>
                        </div>
                        </Link>
                        <div className="why-box__content">
                            <h3><Link to={"/technology/open-standard"} className="text--orange">
                                Open standard</Link></h3>
                            <p>
                                IQRF Open Standard for wireless mesh networks
                            </p>
                        </div>
                    </div>
                </Col>
                <Col xs={12} lg={6}>
                    <div className="why-box__item">
                        <Link to={"/technology/interoperability"}>
                        <div className="why-box__picture">
                            <img src={interoperabilityPicture} alt={"licence"}/>
                        </div>
                        </Link>
                        <div className="why-box__content">
                            <h3><Link to={"/technology/interoperability"} className="text--orange">
                                Interoperability</Link></h3>
                            <p>
                                Integrators can combine products from various producers
                            </p>
                        </div>
                    </div>
                </Col>
                <Col xs={12} lg={6}>
                    <div className="why-box__item">
                        <Link to={"/technology/simple-adoption-integration"}>
                        <div className="why-box__picture">
                            <img src={simpleAdoptionPicture} alt={"adoption and integration"}/>
                        </div>
                        </Link>
                        <div className="why-box__content">
                            <h3><Link to={"/technology/simple-adoption-integration"} className="text--orange">Simple adoption and integration</Link></h3>
                            <p>
                                Adding wireless connectivity to any device
                            </p>
                        </div>
                    </div>
                </Col>
                <Col xs={12} lg={6}>
                    <div className="why-box__item">
                        <Link to={"/technology/industrial-reliability"}>
                        <div className="why-box__picture">
                            <img src={industrialPicture} alt={"Industrial reliability"}/>
                        </div>
                        </Link>
                        <div className="why-box__content">
                            <h3><Link to={"/technology/industrial-reliability"} className="text--orange">Industrial reliability</Link></h3>
                            <p>
                                Thanks to unique IQMESH<sup>®</sup> routing protocol
                            </p>
                        </div>
                    </div>
                </Col>
                <Col xs={12} lg={6}>
                    <div className="why-box__item">
                        <Link to={"/technology/ecosystem"}>
                        <div className="why-box__picture">
                            <img src={ecosystemPicture} alt={"Fast growing ecosystem"}/>
                        </div>
                        </Link>
                        <div className="why-box__content">
                            <h3><Link to={"/technology/ecosystem"}  className="text--orange">Fast growing ecosystem</Link></h3>
                            <p>
                                From protocols through products to any cloud
                            </p>
                        </div>
                    </div>
                </Col>
                <Col xs={12} lg={6}>
                    <div className="why-box__item">
                        <Link to={"/technology/security"}>
                        <div className="why-box__picture">
                            <img src={securityPicture} alt={"Ultimate security"}/>
                        </div>
                        </Link>
                        <div className="why-box__content">
                            <h3><Link to={"/technology/security"} className="text--orange">Ultimate security</Link></h3>
                            <p>
                                Based on standards, automatic, multilayered
                            </p>
                        </div>
                    </div>
                </Col>
                <Col xs={12} lg={6}>
                    <div className="why-box__item">
                      <Link to={"/technology/proven"}>
                        <div className="why-box__picture">
                            <img src={provenPicture} alt={"Proven"}/>
                        </div>
                      </Link>
                        <div className="why-box__content">
                            <h3><Link to={"/technology/proven"} className="text--orange">Proven</Link></h3>
                            <p>
                                Since 2004
                            </p>
                        </div>
                    </div>
                </Col>
            </Row>
        );
    }

}

/**
 * This function maps the state to a
 * prop called `state`.
 * In larger apps it is often good
 * to be more selective and only
 * map the part of the state tree
 * that is necessary.
 */
const mapStateToProps = state => (
    {
        app: state.app,
        entities: state.entities,
    });

/**
 * Exporting part of the React.Component file
 */
export default withRouter(connect(mapStateToProps)(withTranslation()(WhyBox)));