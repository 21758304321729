// @flow

// Necessary Evil
import React from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
// Import component CSS
import "./SubNavigation.scss";
import { NavLink } from "react-router-dom";
import MasterGetter from "../../../Models/Utils/MasterGetter";

/**
 * @class SubNavigation
 */
class SubNavigation extends React.Component {

	/**
	 * PropTypes
	 * @type {{children: shim}}
	 */
	static propTypes = {
		primarySection: PropTypes.string,
		activeSection: PropTypes.any,
		handleLeave: PropTypes.any,
	};

	/**
	 * State
	 * @type {{productSubcategory: null, secondSection: string, activeSection: null, productCategory: null}}
	 */
	state = {
		activeSection: null,
		archive: false,
		lastBox: "",
		locked: false,
		productCategory: null,
		productSubcategory: null,
		secondSection: "",
		thirdSection: null,
		showPreviousState: true,
	};

	/**
	 * Constructor
	 * @param props
	 */
	constructor (props) {
		super(props);

		this.timer = null;
	}

	/**
	 * Switch Product Category
	 * @param value
	 */
	switchCategory = (value) => {
		this.setState({
			productCategory: value
		});
	};

	/**
	 * Switch third Section
	 * @param value
	 */
	switchThirdSection = (value) => {
		this.setState({
			thirdSection: value
		});
	};

	/**
	 * Switch second Section
	 * @param value
	 */
	switchSecondSection = (value) => {
		this.setState({
			secondSection: value,
			locked: value !== null,
			showPreviousState: true,
		});
	};

	/**
	 * Switch Product Subcategory
	 * @param value
	 */
	switchSubcategory = (value) => {
		this.setState({
			productSubcategory: value
		});
	};

	/**
	 * Cancel waiting
	 */
	cancelWaitingPrimary = () => {
		this.setState({
			locked: false,
			lastBox: "secondary",
			showPreviousState: false,
		});
	};

	/**
	 * Set last box
	 * @param name
	 */
	setLastBox = (name) => {
		this.setState({
			lastBox: name,
		});
	};

	/**
	 * Switch Archive
	 */
	switchArchive = () => {

		this.setState({
			archive: !this.state.archive
		});

	};

	/**
	 *
	 */
	handleLeave = () => {

		this.props.handleLeave(this);

	};

	/**
	 * Final Render
	 * @returns {*}
	 */
	render () {

		const {
			activeSection,

			t,
			entities,
			primarySection,
		} = this.props;

		const handleLeave = this.handleLeave;

		let primaryBoxContent = "";
		let secondaryBoxContent = "";
		let thirdBoxContent = "";
		let fourthBoxContent = "";
		let compactBoxClass = false;
		const { secondSection } = this.state;

		/**
		 * Products data
		 */
		const session = MasterGetter.getSession(entities);
		const productCategories = session.ProductCategory.all().filter(item => item.is_online).orderBy("priority");

		/**
		 * Old version - alignment with nav items
		 */
		if (primarySection !== activeSection) {
			return null;
		}
		/**
		 * New version - alignment with page logo
		 */

		if (activeSection === null || activeSection === "") {
			return null;
		}

		let self = this;
		const timerTimeout = 250;

		/**
		 * Category Delayed change
		 * @param target
		 */
		const categoryDelayedChange = (target) => {
			this.timer = setTimeout(function () {
				self.switchCategory(target);
			}, timerTimeout);
		};

		// /**
		//  * Subcategory Delayed change
		//  * @param target
		//  */
		// const subcategoryDelayedChange = (target) => {
		//
		// 	this.setState({
		// 		archive: false,
		// 	});
		//
		// 	this.timer = setTimeout(function () {
		// 		self.switchSubcategory(target);
		// 	}, timerTimeout);
		//
		// };

		/**
		 * Primary delayed change
		 * @param target
		 */
		const primaryDelayedChange = (target) => {
			this.timer = setTimeout(function () {
				self.switchSecondSection(target);
			}, timerTimeout);
		};

		/**
		 * Handle Primary Section
		 */
		switch (activeSection) {
			case "technology":
				/**
				 *
				 * Technology Section
				 */
				primaryBoxContent = <div
					onMouseEnter={() => {
						this.switchSubcategory(null);
						this.setLastBox("primary");
					}}
				>

					<NavLink
						to={"/technology/rf"}
						onClick={handleLeave}
						onMouseOver={() => primaryDelayedChange("rf")}
						onMouseLeave={() => {
							clearTimeout(this.timer);
						}}
						className={(this.state.secondSection) === "rf" && this.state.showPreviousState ? "pseudo-hovered" : ""}
					>
						RF
					</NavLink>
					<NavLink
						to={"/technology/iqmesh"}
						onClick={handleLeave}
						onMouseOver={() => primaryDelayedChange("iqmesh")}
						onMouseLeave={() => {
							clearTimeout(this.timer);
						}}
						className={this.state.secondSection === "iqmesh" && this.state.showPreviousState ? "pseudo-hovered" : ""}
					>
						IQMESH
					</NavLink>
					<NavLink
						to={"/technology/os"}
						onClick={handleLeave}
						onMouseOver={() => primaryDelayedChange("os")}
						onMouseLeave={() => {
							clearTimeout(this.timer);
						}}
						className={this.state.secondSection === "os" && this.state.showPreviousState ? "pseudo-hovered" : ""}
					>
						Operating system
					</NavLink>
					<NavLink
						to={"/technology/dpa"}
						onClick={handleLeave}
						onMouseOver={() => primaryDelayedChange("dpa")}
						onMouseLeave={() => {
							clearTimeout(this.timer);
						}}
						className={this.state.secondSection === "dpa" && this.state.showPreviousState ? "pseudo-hovered" : ""}
					>
						DPA
					</NavLink>
					<NavLink
						to={"/technology/ide"}
						onClick={handleLeave}
						onMouseOver={() => primaryDelayedChange("ide")}
						onMouseLeave={() => {
							clearTimeout(this.timer);
						}}
						className={this.state.secondSection === "ide" && this.state.showPreviousState ? "pseudo-hovered" : ""}
					>
						IDE
					</NavLink>
					<NavLink
						to={"/technology/gw-daemon"}
						onClick={handleLeave}
						onMouseOver={() => primaryDelayedChange("gw-daemon")}
						onMouseLeave={() => {
							clearTimeout(this.timer);
						}}
						className={this.state.secondSection === "gw-daemon" && this.state.showPreviousState ? "pseudo-hovered" : ""}
					>
						GW Daemon
					</NavLink>
					<NavLink
						to={"/technology/sdk"}
						onClick={handleLeave}
						onMouseOver={() => primaryDelayedChange("sdk")}
						onMouseLeave={() => {
							clearTimeout(this.timer);
						}}

						className={this.state.secondSection === "sdk" && this.state.showPreviousState ? "pseudo-hovered" : ""}
					>
						SDK
					</NavLink>
					<NavLink
						to={"/technology/repository"}
						onClick={handleLeave}
						onMouseOver={() => primaryDelayedChange("repository")}
						onMouseLeave={() => {
							clearTimeout(this.timer);
						}}

						className={this.state.secondSection === "repository" && this.state.showPreviousState ? "pseudo-hovered" : ""}
					>
						Repository
					</NavLink>
					<NavLink
						to={"/technology/clouds"}
						onClick={handleLeave}
						onMouseOver={() => primaryDelayedChange("clouds")}
						onMouseLeave={() => {
							clearTimeout(this.timer);
						}}

						className={this.state.secondSection === "clouds" && this.state.showPreviousState ? "pseudo-hovered" : ""}
					>
						Clouds
					</NavLink>
					<a
						href={"https://open.iqrf.org"}
						target="_blank"
						onMouseOver={() => primaryDelayedChange("openiqrf")}
						onMouseLeave={() => {
							clearTimeout(this.timer);
						}}
						className={this.state.secondSection && this.state.showPreviousState === "openiqrf" && this.state.showPreviousState ? "pseudo-hovered" : ""}
						rel="nofollow noreferrer noopener">
						IQRF Open Standard 
					</a>
				</div>;

				/**
				 * Technology Second Section
				 */
				switch (secondSection) {
					case "rf":
						secondaryBoxContent = <div
							onMouseOver={this.cancelWaitingPrimary}
							className="sub-navigation__content"
							style={{ width: "450px", textAlign: "left" }}
						>
							<strong>RF – Radio frequency (wireless)</strong>
							<p style={{ textAlign: "left" }}>Transmission features and parameters </p>
							<img
								src={process.env.REACT_APP_BACKEND_SERVER + "/static/media/img/web/22e47b2ea3fb812e28858e9d02502806.png"}
								alt="tr+rf.png"/>
						</div>;
						break;
					case "iqmesh":
						secondaryBoxContent = <div
							className="sub-navigation__content"
							onMouseOver={this.cancelWaitingPrimary}
							style={{
								width: "450px",
								borderLeft: "20px solid rgba(128, 128, 128, 0.2)",
								textAlign: "left"
							}}
						>
							<strong>IQMESH = IQRF Mesh network</strong>
							<p style={{ textAlign: "left" }}><strong>Mesh</strong> extends
								the <strong>range</strong> and delivers data to <strong>difficult
									areas</strong> via <strong>redundant paths (routing)</strong>.</p>
							<img
								src={process.env.REACT_APP_BACKEND_SERVER + "/static/media/img/web/a2813524e63ae87647ab9a5c7b13d4e4.jpg"}
								alt="mesh-routing.png"/>
						</div>;
						break;
					case "dpa":
						secondaryBoxContent = <div
							onMouseOver={this.cancelWaitingPrimary}
							className="sub-navigation__content"
							style={{ width: "450px", textAlign: "left" }}
						>
							<strong>DPA – Direct Peripheral Access</strong>
							<p style={{ textAlign: "left" }}>Framework with predefined functionality for <strong>IQRF
								wireless networks</strong>.<br/>
								Higher communication layer.
							</p>
						</div>;
						break;
					case "os":
						secondaryBoxContent = <div
							onMouseOver={this.cancelWaitingPrimary}
							className="sub-navigation__content"
							style={{ width: "450px", textAlign: "left" }}
						>
							<strong>IQRF OS</strong>
							<p style={{ textAlign: "left" }}>Operating system with predefined functionality for <strong>IQRF
								wireless</strong>.<br/>
								Lower communication layer.
							</p>
						</div>;
						break;
					case "ide":
						secondaryBoxContent = <div
							onMouseOver={this.cancelWaitingPrimary}
							className="sub-navigation__content"
							style={{ width: "450px", textAlign: "left" }}
						>
							<strong>IQRF IDE</strong>
							<p style={{ textAlign: "left" }}>Integrated development environment</p>
							<p style={{ textAlign: "left" }}>Free SW for IQRF programming, debug, maintenance, service,
								...</p>
							<img
								src={process.env.REACT_APP_BACKEND_SERVER + "/static/media/img/web/6f4d5cfc76bb38ea976c64288c6e683d.png"}
								alt="ide.png"/>
						</div>;
						break;
					case "gw-daemon":
						secondaryBoxContent = <div
							onMouseOver={this.cancelWaitingPrimary}
							className="sub-navigation__content"
							style={{ width: "450px", textAlign: "left" }}
						>
							<strong>IQRF Gateway Daemon</strong>
							<p style={{ textAlign: "left" }}>TR under DPA + Linux single-board computer + IQRF GW Daemon
								= IQRF Gateway</p>
							<p style={{ textAlign: "left" }}>Create your own IQRF gateway e.g. from Raspberry Pi</p>
							<img
								src={process.env.REACT_APP_BACKEND_SERVER + "/static/media/img/web/4c06fdcb565b08c21e053761db69c469.jpg"}
								alt="gw-daemon.png"/>
						</div>;
						break;
					case "sdk":
						secondaryBoxContent = <div
							onMouseOver={this.cancelWaitingPrimary}
							className="sub-navigation__content"
							style={{ width: "450px", textAlign: "left" }}
						>
							<strong>IQRF SDK – Software Development Kit</strong>
							<p style={{ textAlign: "left" }}><strong>Open source</strong> software development package
								for various devices used with
								IQRF</p>
							<p style={{ textAlign: "left" }}>Wherever <strong>C++</strong> or <strong>C</strong> runs,
								you can easily implement
								wireless</p>
						</div>;
						break;
					case "repository":
						secondaryBoxContent = <div
							onMouseOver={this.cancelWaitingPrimary}
							className="sub-navigation__content"
							style={{ width: "450px", textAlign: "left" }}
						>
							<strong>IQRF Repository</strong>
							<p style={{ textAlign: "left" }}>
								<strong>Public</strong> on-line <strong>storage</strong> and database containing
								information regarding
								all <strong>IQRF interoperable products</strong>.</p>
						</div>;
						break;
					case "clouds":
						secondaryBoxContent = <div
							onMouseOver={this.cancelWaitingPrimary}
							className="sub-navigation__content"
							style={{ width: "450px", textAlign: "left" }}
						>
							<strong>Clouds</strong>
							<p style={{ textAlign: "left" }}>IQRF network can be connected to <strong>any cloud</strong>.
							</p>
							<p style={{ textAlign: "left" }}><strong>AWS IoT</strong>, Microsoft <strong>Azure, IBM
								Cloud, InteliGlue</strong>, ...
							</p>
						</div>;
						break;
						case "openiqrf":
							secondaryBoxContent = <div
								onMouseOver={this.cancelWaitingPrimary}
								className="sub-navigation__content"
								style={{ width: "450px", textAlign: "left" }}
							>
								<strong>IQRF Open Standard</strong>
								<p style={{ textAlign: "left" }}>Mature IQRF technology is introduced as an open standard, licensed Royalty-free. 
								</p>
							</div>;
							break;
					default:
						secondaryBoxContent = "";
				}

				break;
			case "support":
				/**
				 * Primary content of Sales
				 */
				compactBoxClass = true;
				primaryBoxContent = <div onMouseEnter={() => this.switchSubcategory(null)}>
					<NavLink
						onClick={() => handleLeave}
						to={"/support/videos"}
						onMouseOver={() => primaryDelayedChange("videos")}
						onMouseLeave={() => {
							clearTimeout(this.timer);
						}}
					>
						{t("navigation.Videos")}
					</NavLink>
					<NavLink
						onClick={() => handleLeave}
						to={"/support/application-notes"}
						onMouseOver={() => primaryDelayedChange("application-notes")}
						onMouseLeave={() => {
							clearTimeout(this.timer);
						}}
						className={this.state.secondSection === "application-notes" && this.state.showPreviousState ? "pseudo-hovered" : ""}
					>
						{"Application notes"}
					</NavLink>
					<NavLink
						onClick={() => handleLeave}
						to={"/support/webinars"}
						onMouseOver={() => primaryDelayedChange("webinars")}
						onMouseLeave={() => {
							clearTimeout(this.timer);
						}}
						className={this.state.secondSection === "webinars" && this.state.showPreviousState ? "pseudo-hovered" : ""}
					>
						{t("navigation.Webinars")}
					</NavLink>
					<NavLink
						onClick={() => handleLeave}
						to={"/support/faq"}
						onMouseOver={() => primaryDelayedChange("faq")}
						onMouseLeave={() => {
							clearTimeout(this.timer);
						}}
						className={this.state.secondSection === "faq" && this.state.showPreviousState ? "pseudo-hovered" : ""}
					>
						{t("navigation.FAQ")}
					</NavLink>
					<NavLink
						onClick={() => handleLeave}
						to={"/support/training"}
						onMouseOver={() => primaryDelayedChange("training")}
						onMouseLeave={() => {
							clearTimeout(this.timer);
						}}
						className={this.state.secondSection === "training" && this.state.showPreviousState ? "pseudo-hovered" : ""}
					>
						{t("navigation.Training")}
					</NavLink>
					<NavLink
						onClick={() => handleLeave}
						to={"/support/fae"}
						onMouseOver={() => primaryDelayedChange("fae")}
						onMouseLeave={() => {
							clearTimeout(this.timer);
						}}
						className={this.state.secondSection === "fae" && this.state.showPreviousState ? "pseudo-hovered" : ""}
					>
						{t("navigation.FAE")}
					</NavLink>
					<NavLink
						onClick={() => handleLeave}
						to={"/support/downloads"}
						onMouseOver={() => primaryDelayedChange("downloads")}
						onMouseLeave={() => {
							clearTimeout(this.timer);
						}}
						className={this.state.secondSection === "downloads" && this.state.showPreviousState ? "pseudo-hovered" : ""}
					>
						{t("navigation.Downloads")}
					</NavLink>
					<NavLink
						onClick={() => handleLeave}
						to={"/support/contact-support"}
						onMouseOver={() => primaryDelayedChange("contact-support")}
						onMouseLeave={() => {
							clearTimeout(this.timer);
						}}
						className={this.state.secondSection === "contact-support" && this.state.showPreviousState ? "pseudo-hovered" : ""}
					>
						{t("navigation.ContactSupport")}
					</NavLink>
				</div>;

				/**
				 * Support Second Section
				 */
				switch (secondSection) {
					case "videos":
						secondaryBoxContent = <div
							onMouseOver={this.cancelWaitingPrimary}
							className="sub-navigation__content"
							style={{ width: "275px", textAlign: "left" }}
						>
							<strong>IQRF video tutorials</strong>
							<p style={{ textAlign: "left" }}>Everything you need to know about IQRF in a few minutes</p>
						</div>;
						break;
					case "application-notes":
						secondaryBoxContent = <div
							onMouseOver={this.cancelWaitingPrimary}
							className="sub-navigation__content"
							style={{ width: "275px", textAlign: "left" }}
						>
							<strong>IQRF application notes</strong>
							<p style={{ textAlign: "left" }}>Technical papers regarding IQRF.</p>
							<p style={{ textAlign: "left" }}>For developers. Read to save time and costs.</p>
						</div>;
						break;
					case "webinars":
						secondaryBoxContent =
							<div className="sub-navigation__content" style={{ width: "275px", textAlign: "left" }}>
								<strong>IQRF webinars</strong>
								<p style={{ textAlign: "left" }}>Interactive on-line IQRF workshops.</p>
							</div>;
						break;
					case "faq":
						secondaryBoxContent = <div
							onMouseOver={this.cancelWaitingPrimary}
							className="sub-navigation__content"
							style={{ width: "275px", textAlign: "left" }}
						>
							<strong>FAQ – Frequently Asked Questions</strong>
							<p style={{ textAlign: "left" }}>Please, read before contacting IQRF support.</p>
						</div>;
						break;
					case "training":
						secondaryBoxContent = <div
							onMouseOver={this.cancelWaitingPrimary}
							className="sub-navigation__content"
							style={{ width: "275px", textAlign: "left" }}
						>
							<strong>IQRF training</strong>
							<p style={{ textAlign: "left" }}>Full-day extensive training in IQRF (In Jicin, Czech
								Rep).</p>
							<p style={{ textAlign: "left" }}>The content can be adapted for individual needs.</p>
							<p style={{ textAlign: "left" }}>The price is lower than the discount for IQRF development
								tools.</p>
						</div>;
						break;
					case "fae":
						secondaryBoxContent =
							<div className="sub-navigation__content" style={{ width: "275px", textAlign: "left" }}>
								<strong>IQRF FAE – Field Application Engineering</strong>
								<p style={{ textAlign: "left" }}>Massive customer-facing help with applications.</p>
								<p style={{ textAlign: "left" }}>Speed up your first design with our engineer.</p>
							</div>;
						break;
					case "downloads":
						secondaryBoxContent = <div
							onMouseOver={this.cancelWaitingPrimary}
							className="sub-navigation__content"
							style={{ width: "275px", textAlign: "left" }}
						>
							<strong>IQRF Downloads</strong>
							<p style={{ textAlign: "left" }}>All IQRF software, documentation and other files to
								download from central storage.</p>
							<p style={{ textAlign: "left" }}>This is just a secondary source for advanced users or when
								in doubt. You should primarily
								search relevant files in respective product pages.</p>
						</div>;
						break;
					case "contact-support":
						secondaryBoxContent = <div
							onMouseOver={this.cancelWaitingPrimary}
							className="sub-navigation__content"
							style={{ width: "275px", textAlign: "left" }}
						>
							<strong>Contact IQRF Support</strong>
							<p style={{ textAlign: "left" }}>Submit a question and our experienced support team will
								respond.</p>
							<p style={{ textAlign: "left" }}>Use this form for technical matters only. For commercial or
								sales matters go to <strong>Contact</strong> under menu <strong>Sales</strong>.</p>
						</div>;
						break;
					default:
						secondaryBoxContent = "";
				}
				break;
			case "sales":
				/**
				 * Primary content of Sales
				 */
				compactBoxClass = true;
				primaryBoxContent = <div onMouseEnter={() => this.switchSubcategory(null)}>
					<a
						href={"http://eshop.iqrf.org"}
						target="_blank"
						onMouseOver={() => primaryDelayedChange("eshop")}
						onMouseLeave={() => {
							clearTimeout(this.timer);
						}}
						className={this.state.secondSection && this.state.showPreviousState === "eshop" && this.state.showPreviousState ? "pseudo-hovered" : ""}
						rel="nofollow noreferrer noopener">
						{t("navigation.Eshop")}
					</a>
					<a
						href={"/download/7d239781-638e-477e-b26b-8b6475199c4f"}
						target="_self"
						onMouseOver={() => primaryDelayedChange("pricelist")}
						onMouseLeave={() => {
							clearTimeout(this.timer);
						}}
						className={this.state.secondSection && this.state.showPreviousState === "pricelist" && this.state.showPreviousState ? "pseudo-hovered" : ""}
						rel="nofollow noreferrer noopener">
						{t("navigation.Pricelist")}
					</a>
					<NavLink
						activeClassName={"active"}
						to={"/sales/distributors"}
						onMouseOver={() => primaryDelayedChange("distributors")}
						onMouseLeave={() => {
							clearTimeout(this.timer);
						}}
						className={this.state.secondSection === "distributors" && this.state.showPreviousState ? "pseudo-hovered" : ""}
					>
						{t("navigation.Distributors")}
					</NavLink>
					<NavLink
						activeClassName={"active"}
						to={"/sales/design-houses"}
						onMouseOver={() => primaryDelayedChange("design-houses")}
						onMouseLeave={() => {
							clearTimeout(this.timer);
						}}
						className={this.state.secondSection === "design-houses" && this.state.showPreviousState ? "pseudo-hovered" : ""}
					>
						{t("navigation.DesignHouses")}
				</NavLink>
					<a
						href={"https://rma.iqrf.org"}
						target="_blank"
						onMouseOver={() => primaryDelayedChange("claim-returns")}
						onMouseLeave={() => {
							clearTimeout(this.timer);
						}}
						className={this.state.secondSection === "claim-returns" && this.state.showPreviousState ? "pseudo-hovered" : ""}
						rel="nofollow noreferrer noopener">
						{t("navigation.ClaimReturns")}
					</a>
					<NavLink
						activeClassName={"active"}
						to={"/sales/company"}
						onMouseOver={() => primaryDelayedChange("company")}
						onMouseLeave={() => {
							clearTimeout(this.timer);
						}}
						className={this.state.secondSection === "company" && this.state.showPreviousState ? "pseudo-hovered" : ""}
					>
						{t("navigation.Company")}
					</NavLink>
					<NavLink
						activeClassName={"active"}
						to={"/sales/contact"}
						onMouseOver={() => primaryDelayedChange("contact")}
						onMouseLeave={() => {
							clearTimeout(this.timer);
						}}
						className={this.state.secondSection === "contact" && this.state.showPreviousState ? "pseudo-hovered" : ""}
					>
						{t("navigation.ContactSales")}
					</NavLink>
				</div>;

				/**
				 * Sales Second Section
				 */
				switch (secondSection) {
					case "contact-sales":
						secondaryBoxContent = <div
							onMouseOver={this.cancelWaitingPrimary}
							className="sub-navigation__content"
							style={{ width: "250px", textAlign: "left" }}
						>
							<strong>Contact IQRF Sales</strong>
							<p style={{ textAlign: "left" }}>Use this form for commercial matters only.</p>
						</div>;
						break;
					case "distributors":
						secondaryBoxContent = <div
							onMouseOver={this.cancelWaitingPrimary}
							className="sub-navigation__content"
							style={{ width: "250px", textAlign: "left", justifyContent: "left" }}
						>
							<strong>IQRF distributors</strong>
							<p style={{ textAlign: "left" }}>IQRF local distributors, partners and representation.</p>
						</div>;
						break;
						case "design-houses":
							secondaryBoxContent = <div
								onMouseOver={this.cancelWaitingPrimary}
								className="sub-navigation__content"
								style={{ width: "250px", textAlign: "left", justifyContent: "left" }}
							>
								<strong>IQRF Design houses</strong>
								<p style={{ textAlign: "left" }}>You can get a complete turn-key application done by a local Design house.</p>
							</div>;
						break;
					case "company":
						secondaryBoxContent = <div
							onMouseOver={this.cancelWaitingPrimary}
							className="sub-navigation__content"
							style={{ width: "250px", textAlign: "left", justifyContent: "left" }}
						>
							<strong>IQRF Tech company</strong>
							<p style={{ textAlign: "left" }}>Company profile, address, identification and bank
								accounts.</p>
						</div>;
						break;
					case "contact":
						secondaryBoxContent = <div
							onMouseOver={this.cancelWaitingPrimary}
							className="sub-navigation__content"
							style={{ width: "250px", textAlign: "left", justifyContent: "left" }}
						>
							<p style={{ textAlign: "left" }}>Use this form to contact IQRF office for commercial or
								sales matters. For technical
								questions go to <strong>Contact Support</strong> under menu <strong>Support</strong>.
							</p>
						</div>;
						break;
					case "claim-returns":
						secondaryBoxContent = <div
							onMouseOver={this.cancelWaitingPrimary}
							className="sub-navigation__content"
							style={{ width: "250px", textAlign: "left", justifyContent: "left" }}
						>
							<strong>Claim and returns</strong>
							<p style={{ textAlign: "left" }}>For request to return goods, ask for repair etc.</p>
						</div>;
						break;
					default:
						secondaryBoxContent = "";
				}
				break;
			case "products":
				/**
				 * Primary Box Content
				 */
				primaryBoxContent = <div onMouseEnter={() => this.switchSubcategory(null)}>
					{productCategories.toModelArray().map(item => {

						// /**
						//  * Get Subcategories for count()
						//  **/
/*						// let countSubs = session.ProductSubcategory.filter(subItem => {
						// 	return (subItem.category === item.id);
						// });*/
						const products = session.ProductSeries.all().filter(
							product => (product.category === item.id) && (!product.is_archived));
						if (products.count() === 0){
							return null;
						}

						/**
						 * Final render of Navigation items
						 **/
						return <NavLink
							onClick={handleLeave}
							to={"/products/" + item.friendly_url()}
							className={(this.state.productCategory === item.id ? "active-path" : "")}
							key={item.id}
							onMouseOver={() => {
								categoryDelayedChange(item.id);
							}}
							onMouseLeave={() => {
								clearTimeout(this.timer);
							}}
						>{item.name}
							{/*{countSubs.count() > 0 && <i className="icon-play-button"/>}*/}
						</NavLink>;
					})}
					<span
						className={"archive-submenu"}
						onClick={handleLeave}
						onMouseOver={() => {
							categoryDelayedChange("archive");
						}}
						onMouseLeave={() => {
							clearTimeout(this.timer);
						}}
					>Archive <i className="icon-play-button"/></span>
				</div>;

				if (this.state.productCategory === "archive") {
					secondaryBoxContent = <div className="sub-navigation__top-bottom-padding">
						{productCategories.toModelArray().map(item => {

							const archivedProductsCount = session.ProductSeries.all().filter(product => (product.category === item.id) && (product.is_archived === true)).orderBy(["ordering"], ["desc"]).count();

							/**
							 * Final render of Navigation items
							 **/
							if (archivedProductsCount > 0) {
								return <NavLink
									onClick={handleLeave}
									to={"/products/" + item.friendly_url() + "/archive"}
									key={item.id}
								>{item.name}</NavLink>;
							}
						})}
					</div>;
				}

				// let products = session.ProductSeries.all().filter(product => (product.subcategory === this.state.productSubcategory) && (product.is_archived === this.state.archive)).orderBy(["ordering"], ["desc"]);
				// const category = session.ProductCategory.withId(this.state.productCategory);
				// const subcategory = session.ProductSubcategory.withId(this.state.productSubcategory);

				// /**
				//  * Secondary box content / Subcategories
				//  * =====================
				//  */
				// if (productSubcategories.count() > 0) {
				//
				// 	// switch (category.id) {
				// 	// 	default:
				// 	// 		// secondaryBoxContent = <div
				// 	// 		// 	onMouseEnter={() => {
				// 	// 		// 		this.switchSubcategory(null);
				// 	// 		// 		this.switchThirdSection(null);
				// 	// 		// 		clearTimeout(this.timer);
				// 	// 		// 	}}
				// 	// 		// 	className="sub-navigation__top-bottom-padding"
				// 	// 		// >
				// 	// 		// 	{productSubcategories.toModelArray().map(item => {
				// 	// 		// 		/**
				// 	// 		// 		 * Select section for counting
				// 	// 		// 		 */
				// 	// 		// 		let countSections = session.ProductSubcategorySection.filter(sectionItem => sectionItem.subcategory === item.id);
				// 	// 		// 		let countProducts = session.ProductSeries.filter(series => (series.subcategory === item.id) && !item.is_archived);
				// 	// 		//
				// 	// 		// 		/**
				// 	// 		// 		 * Final Render of subcategories
				// 	// 		// 		 */
				// 	// 		// 		return <NavLink
				// 	// 		// 			onClick={handleLeave}
				// 	// 		// 			key={item.id}
				// 	// 		// 			className={"sub-link " + (this.state.productSubcategory === item.id ? "active-path" : "")}
				// 	// 		// 			onMouseOver={() => subcategoryDelayedChange(item.id)}
				// 	// 		// 			onMouseLeave={() => clearTimeout(this.timer)}
				// 	// 		// 			to={"/products/" + category.friendly_url() + "/" + item.friendly_url()}>{item.name} {((countSections.count() > 0) || countProducts.count() > 0) &&
				// 	// 		// 		<i className="icon-play-button"/>}
				// 	// 		// 		</NavLink>;
				// 	// 		// 	})}
				// 	// 		// </div>;
				// 	// }
				// } else {
				// 	// if (category) {
				// 	// 	// products = session.ProductSeries.all().filter(product => (product.category === this.state.productCategory) && (product.is_archived === this.state.archive)).orderBy(["ordering"], ["desc"]);
				// 	// 	// let archivedProducts = session.ProductSeries.all().filter(product => ((product.category === category.id) && product.is_archived)).orderBy(["ordering"], ["desc"]);
				// 	//
				// 	// 	switch (category.id) {
				// 	// 		case 1:
				// 	// 		case 2:
				// 	// 		case 3:
				// 	// 			// secondaryBoxContent = <div
				// 	// 			// 	onMouseEnter={() => {
				// 	// 			// 		this.switchSubcategory(null);
				// 	// 			// 		this.switchThirdSection(null);
				// 	// 			// 		clearTimeout(this.timer);
				// 	// 			// 	}} className="sub-navigation__top-bottom-padding"
				// 	// 			// >
				// 	// 			// 	{products.toModelArray().map(product => {
				// 	// 			//
				// 	// 			// 		if (product === null) {
				// 	// 			// 			return null;
				// 	// 			// 		}
				// 	// 			//
				// 	// 			// 		return <NavLink
				// 	// 			// 			onClick={handleLeave}
				// 	// 			// 			key={product.id}
				// 	// 			// 			onMouseEnter={() => {
				// 	// 			// 				this.switchThirdSection(product.id);
				// 	// 			// 			}}
				// 	// 			// 			className={"sub-link" + (this.state.thirdSection === product.id ? " active-path" : "")}
				// 	// 			// 			to={"/product-detail/" + product.friendly_url}>{product.name}&nbsp;
				// 	// 			// 			{product.status !== null && (product.status.id === 1 &&
				// 	// 			// 				<Tag className={"inline green"}>New</Tag>)}
				// 	// 			// 			{product.status !== null && (product.status.id === 4 &&
				// 	// 			// 				<Tag className={"inline red"}>Obsolete</Tag>)}
				// 	// 			// 		</NavLink>;
				// 	// 			// 	})}
				// 	// 			//
				// 	// 			//
				// 	// 			// 	{(archivedProducts.count() > 0 && category !== null) &&
				// 	// 			// 	<div className="sub-navigation__archive-wrapper">
				// 	// 			// 		<button onClick={this.switchArchive}
				// 	// 			// 				className={"btn btn--unimportant " + (this.state.archive && "btn--danger")}
				// 	// 			// 				style={{
				// 	// 			// 					width: "100%",
				// 	// 			// 					textAlign: "center",
				// 	// 			// 					justifyContent: "center",
				// 	// 			// 					fontSize: ".96rem"
				// 	// 			// 				}}>
				// 	// 			// 			{this.state.archive ? "Back from Archive" : "Go to Archive"}
				// 	// 			// 		</button>
				// 	// 			// 	</div>}
				// 	// 			// </div>;
				// 	// 			break;
				// 	// 		default:
				// 	// 			break;
				// 	// 	}
				// 	// }
				//
				// }

				/**
				 * Third box content
				 * =================
				 * */
				// if (productSections.count() > 0) {
				// 	/**
				// 	 * Get archived products from store
				// 	 **/
				// 	let archivedProducts = session.ProductSeries.all().filter(product => {
				// 		return (product.subcategory === subcategory.id) && product.is_archived;
				// 	});
				//
				// 	/**
				// 	 * Set box content
				// 	 **/
				// 	// thirdBoxContent = <div>
				// 	// 	{productSections.orderBy(["list_order"], ["desc"]).toModelArray().map(section => {
				// 	// 		/**
				// 	// 		 * Handle sections with content
				// 	// 		 */
				// 	// 		const products = session.ProductSeries.all().filter(product => {
				// 	// 			return (product.section === section.id) && (product.is_archived === this.state.archive);
				// 	// 		}).orderBy(["ordering"], ["desc"]);
				// 	//
				// 	// 		if (products.count() === 0) {
				// 	// 			return null;
				// 	// 		}
				// 	//
				// 	// 		return <div
				// 	// 			id={"section-" + section.id}
				// 	// 			key={section.id}
				// 	// 		>
				// 	// 			<DropDownBox
				// 	// 				title={section.name}
				// 	// 				onTitleHover={() => {
				// 	// 					this.switchThirdSection("section-" + section.id);
				// 	// 				}}
				// 	// 			>
				// 	// 				{products.toModelArray().map(product => {
				// 	// 					return <NavLink
				// 	// 						onClick={handleLeave}
				// 	// 						onMouseEnter={() => {
				// 	// 							this.switchThirdSection(product.id);
				// 	// 						}}
				// 	// 						key={product.id}
				// 	// 						className={"sub-link" + (this.state.thirdSection === product.id ? " active-path" : "")}
				// 	// 						to={"/product-detail/" + product.friendly_url}>{product.name} {product.status.id === 1 &&
				// 	// 					<Tag className={"inline green"}>New</Tag>} {product.status.id === 4 &&
				// 	// 					<Tag className={"inline red"}>Obsolete</Tag>}
				// 	// 					</NavLink>;
				// 	// 				})}
				// 	// 			</DropDownBox>
				// 	// 		</div>;
				// 	// 	})}
				// 	//
				// 	// 	{(archivedProducts.count() > 0 && category !== null && subcategory !== null) &&
				// 	// 	<div className="sub-navigation__archive-wrapper">
				// 	// 		<button onClick={this.switchArchive}
				// 	// 				className={"btn btn--unimportant " + (this.state.archive && "btn--danger")} style={{
				// 	// 			width: "100%",
				// 	// 			textAlign: "center",
				// 	// 			justifyContent: "center",
				// 	// 			fontSize: ".96rem"
				// 	// 		}}>
				// 	// 			{this.state.archive ? "Back from Archive" : "Go to Archive"}
				// 	// 		</button>
				// 	// 	</div>}
				// 	//
				// 	// </div>;
				// }

				/**
				 * Products only section
				 */
				// if (products.count() > 0 && productSections.count() === 0 && this.state.productSubcategory !== null) {
				//
				// 	const archivedProducts = session.ProductSeries.all().filter(product => (product.subcategory === this.state.productSubcategory) && product.is_archived).orderBy(["ordering"], ["desc"]);
				// 	/**
				// 	 * Third box content
				// 	 **/
				// 	thirdBoxContent = <div>
				// 		{products.toModelArray().map(product => {
				//
				// 			if (product === null) {
				// 				return null;
				// 			}
				//
				// 			return <NavLink
				// 				onClick={handleLeave}
				// 				key={product.id}
				// 				onMouseEnter={() => {
				// 					this.switchThirdSection(product.id);
				// 				}}
				// 				className={"sub-link" + (this.state.thirdSection === product.id ? " active-path" : "")}
				// 				to={"/product-detail/" + product.friendly_url}>{product.name}&nbsp;
				// 				{product.status !== null && (product.status.id === 1 &&
				// 					<Tag className={"inline green"}>New</Tag>)}
				// 				{product.status !== null && (product.status.id === 4 &&
				// 					<Tag className={"inline red"}>Obsolete</Tag>)}
				// 			</NavLink>;
				// 		})}
				//
				//
				// 		{(archivedProducts.count() > 0 && category !== null && subcategory !== null) &&
				// 		<div className="sub-navigation__archive-wrapper">
				// 			<button onClick={this.switchArchive}
				// 					className={"btn btn--unimportant " + (this.state.archive && "btn--danger")} style={{
				// 				width: "100%",
				// 				textAlign: "center",
				// 				justifyContent: "center",
				// 				fontSize: ".96rem"
				// 			}}>
				// 				{this.state.archive ? "Back from Archive" : "Go to Archive"}
				// 			</button>
				// 		</div>}
				// 	</div>;
				// }

				// if (this.state.thirdSection !== null) {
				//
				// 	switch (this.state.thirdSection) {
				// 		default:
				// 			fourthBoxContent = "";
				// 			break;
				// 	}
				// }
				break;
			default:
				primaryBoxContent = null;

		}

		return <div className="sub-navigation">
			<div className={`sub-navigation__primary-panel ${compactBoxClass ? "sub-navigation--compact" : ""}`}>
				{primaryBoxContent}
			</div>
			{secondaryBoxContent !== "" &&
			<div className="sub-navigation__second-panel">
				{secondaryBoxContent}
			</div>
			}
			{thirdBoxContent !== "" &&
			<div className="sub-navigation__third-panel">
				{thirdBoxContent}
			</div>
			}
			{fourthBoxContent !== "" &&
			<div className="sub-navigation__fourth-panel">
				{fourthBoxContent}
			</div>
			}
		</div>;
	}
}

export default withTranslation()(SubNavigation);
