// @flow

// Necessary Evil
import React from "react";
import PropTypes from "prop-types";
import {withTranslation} from "react-i18next";
// Import component CSS
import Modal from "react-modal";

/**
 * @class DeleteModal
 */
class DeleteModal extends React.Component {

	/**
	 * PropTypes
	 * @type {{children: *}}
	 */
	static propTypes = {
		children: PropTypes.any,
		parent: PropTypes.any.isRequired,
		handleDelete: PropTypes.func.isRequired,
		isOpen: PropTypes.bool,
	};

	/**
	 *
	 * @type {{isOpen: boolean}}
	 */
	static defaultProps = {
		isOpen: false,
	};

	/**
	 * Close Modal
	 */
	closeModal = () => {

		this.props.parent.setState({
			modalIsOpen: false,
		});
	};

	/**
	 * Final Render
	 * @returns {*}
	 */
	render() {

		const {
			isOpen,
			handleDelete
		} = this.props;

		return <Modal
			isOpen={isOpen}
			onRequestClose={this.closeModal}
			contentLabel="Example Modal"
			ariaHideApp={false}
		>
			<div className="text--center">
				<h3 className="text--center">Do you want to delete this item?</h3>
				<br/>
				<button
					className="btn btn--danger"
					onClick={handleDelete}
				>
					Yes, delete item
				</button>
				&nbsp;
				<button
					className="btn"
					onClick={this.closeModal}
				>
					Close
				</button>
			</div>
		</Modal>;
	}
}

export default withTranslation()(DeleteModal);