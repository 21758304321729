// @flow
import React from "react";
import {withTranslation} from "react-i18next";
import {Col, Grid, Row} from "react-flexbox-grid";
import {Helmet} from "react-helmet";

/**
 * @class ForTRUnderDPAScene
 */
class ForTRUnderDPAScene extends React.Component {

	/**
	 * Final Render
	 * @returns {*}
	 */
	render() {

		return (

			<Grid className={"page"}>
				<Helmet>
					<title>Examples for TR under IQRF DPA - IQRF</title>
				</Helmet>
				<Row>
					<Col xs={12}>
						<h1>Examples for TR under IQRF DPA</h1>
						<h2>Custom DPA handler examples</h2>
						<h2>For Coordinator</h2>
						TODO {/*odkazy nefunguji tak jak by mely*/}
					</Col>
				</Row>
			</Grid>

		);
	}

}

/**
 * Exporting part of the React.Component file
 */
export default withTranslation()(ForTRUnderDPAScene);