// @flow
import React from "react";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import {withRouter} from "react-router-dom";
import {Col, Grid, Row} from "react-flexbox-grid";
import check_icon from "./check.jpg";
import "./DPACompatibility.scss";


/**
 * @class DPACompatibilityTest
 */
 class DPACompatibilityTest extends React.Component {

    constructor(props) {
        super(props);
   
        this.state = {
            items: [],
            DataisLoaded: false
        };
    }

    componentDidMount() {
        fetch( "https://repository.iqrfalliance.org/api/osdpa")
            .then((res) => res.json())
            .then((json) => {
                this.setState({
                    items: json,
                    DataisLoaded: true
                });
            })
    }

    createHeader(items) {
        let pok = items.filter((item, i) => i === 0);
        let first = pok[0];
        let isData = 1;

        if (first != undefined) {
            isData = parseInt(first.osDpaID);
        }
        let itemRev = "";
        if (isData === 1) {
            itemRev = items.reverse();
        } else {
            itemRev = items;
        }

        return itemRev.map((a) => {
            let osdpa = "";
            let osdpaAll = "";
            if (a.osVersion.slice(0, -1) >= '4.05') {
                osdpa = a.osVersion;
                let dpa = a.dpa;
                let xx = a.dpa.substring(0,2) + "." + a.dpa.substring(2);

                if (xx.charAt(0) === '0') {
                    xx = xx.slice(1);
                    dpa = a.dpa.slice(1);
                } 
                let plus = " + ";
                let link = 'https://doc.iqrf.org/DpaTechGuide/' + dpa +'/pages/dpa-' + dpa + '.html';
                if (a.dpa >= '0400') {
                    osdpaAll =  <span><a href="https://www.iqrf.org/technology/os/history-os"  rel="noopener noreferrer" target="_blank">{ osdpa }</a>{ plus }<a href={ link } rel="noopener noreferrer" target="_blank"><strong>{ xx }</strong></a></span>;
                } else {
                    osdpaAll =  <span><a href="https://www.iqrf.org/technology/os/history-os"  rel="noopener noreferrer" target="_blank">{ osdpa }</a>{ plus }<strong>{ xx }</strong></span>;
                }
                //osdpa = osdpa + ' + ' + tt;   
            } else {
                osdpaAll = "xx";
            }  

            return osdpaAll;
        });
    }

    createHeaderIDs(items) {
        //let itemRev2 = items.reverse();
        return items.map((a) => {
            let idDpa = 0;
            if (a.osVersion.slice(0, -1) >= '4.05') {
                idDpa = a.osDpaID;   
            } 
            return `${idDpa}`;
        });
    }

    createMyRow(items, columnCount, headerIDs) {
        //let itemRev3 = items.reverse();
        return items.map((a) => {
            let rowsArray = {};
            let osdpa = a.osVersion;
            let dpa = a.dpa;
            let xx = a.dpa.substring(0,2) + "." + a.dpa.substring(2);
            if (xx.charAt(0) === '0') {
                xx = xx.slice(1);
                dpa = a.dpa.slice(1);
            } 
            let link = 'https://doc.iqrf.org/DpaTechGuide/' + dpa +'/pages/dpa-' + dpa + '.html';
            let plus = " + ";

            if (a.dpa >= '0400') {
                rowsArray['row1'] = <span><a href="https://www.iqrf.org/technology/os/history-os"  rel="noopener noreferrer" target="_blank"><strong>{ osdpa }</strong></a>{ plus }<a href={ link } rel="noopener noreferrer" target="_blank"><strong>{ xx }</strong></a></span>;
            } else {
                rowsArray['row1'] = <span><a href="https://www.iqrf.org/technology/os/history-os"  rel="noopener noreferrer" target="_blank"><strong>{ osdpa }</strong></a>{ plus }<strong>{ xx }</strong></span>;
            }

            let compatibility = a.compatibility;
            let isInArray = 0;

            for (let i = 0; i < columnCount ; i++) {
                if (headerIDs[i] !== '0') {
                    if (compatibility.includes(parseInt(headerIDs[i]))) {
                        rowsArray['col' + i] = <img src={check_icon}  width="35px" height="35px" />;
                        isInArray = 1;
                    } else {
                        rowsArray['col' + i] = ' ';
                    }
                }
            }

            if (isInArray === 0) {
                rowsArray['col' + columnCount] = <img src={check_icon} width="35px" height="35px" />; 
            } else {
                rowsArray['col' + columnCount] = ' '; 
            }
          return rowsArray;
        });
    }

    getHeader(count) {
        let headerKey = [];

        headerKey.push('row1');

        for (let i = 0; i < count; i++) {
            let x = 'col' + i;
            headerKey.push(x);
        }

        return headerKey;
    }
    
    render() { 
        const { items } = this.state;

        const headerValuesString = this.createHeader(items);
        const headerIDs = this.createHeaderIDs(items);
        const movies = headerIDs.filter(item => item !== '0');
        const columnCount = movies.length; 
        const columnCountHeader = columnCount + 1;

        const normalizedImageStrings = this.createMyRow(items, columnCount, headerIDs);
        const headings = this.getHeader(columnCountHeader);
        
        const firstRow = normalizedImageStrings.filter((item, i) => i === 0);
        const countRow = normalizedImageStrings.length;
        const otherRow = normalizedImageStrings.filter((item, i) => i !== 0);
 
        return (
            <div>
            <table className="table--os-dpa">
                <tbody>
				    <tr>
                        <th rowspan={2} colSpan={2}>&nbsp;</th>
					    <th colSpan={ columnCountHeader }>IQRF OS + DPA at Node</th>
				    </tr>
                    <tr>
                    {
                        headerValuesString.map((item) => ( item !== "xx" ?
                            ( <th>{ item }</th> )
                            : null
                        ))
                    }
                        <th><i>Same as Coordinator</i></th>
				    </tr>
                    {
                        firstRow.map((row) => (
                            <tr>
                                <td rowSpan={ countRow }><div className="rotated-th"><span class="rotated-th__label"><strong>IQRF OS + DPA at Coordinator</strong></span></div></td>
                                {headings.map((heading) => <td>{row[heading]}</td>)}
                            </tr>
                        ))}    
                    {
                        otherRow.map((row) => (
                            <tr>
                            {headings.map((heading) => <td>{row[heading]}</td>)}
                            </tr>
                        ))}
                </tbody>
            </table>
            <p>&nbsp;</p>
           </div>
        );
    }
 }

  /**
 * This function maps the state to a
 * prop called `state`.
 *
 * In larger apps it is often good
 * to be more selective and only
 * map the part of the state tree
 * that is necessary.
 */
const mapStateToProps = state => (
{
    app: state.app,
});

/**
 * Exporting part of the React.Component file
 */
export default withRouter(connect(mapStateToProps)(withTranslation()(DPACompatibilityTest)));