// @flow
import React from "react";
import {withTranslation} from "react-i18next";
import {Col, Grid, Row} from "react-flexbox-grid";
import {Helmet} from "react-helmet";

/**
 * @class SearchScene
 */
class SearchScene extends React.Component {

    componentDidMount() {
        (function () {
            let cx = "010961357664436421486:c5qyw3zmsio";
            let gcse = document.createElement("script");
            gcse.type = "text/javascript";
            gcse.async = true;
            gcse.src = "https://cse.google.com/cse.js?cx=" + cx;
            let s = document.getElementsByTagName("script")[0];
            s.parentNode.insertBefore(gcse, s);
        })();
    }

    /**
     * Final Render
     * @returns {*}
     */
    render() {

        return (
            <Grid className={"page"}>
                <Helmet>
                    <title>Search - IQRF</title>
                </Helmet>
                <Row>
                    <Col xs={12}>
                        <h1>Search v1</h1>
                        <form className="form" method="get" title="Search Form" target="_self"
                              action="https://cse.google.com/cse/publicurl">
                            <div>
                                <input type="text" id="q" name="q" title="Search this site" alt="Search Text"
                                       maxLength="256"/>
                                <input type="hidden" id="cx" name="cx" value="010961357664436421486:c5qyw3zmsio"/>
                                <div className="text--center">
                                    <button
                                        type="image"
                                        id="searchSubmit"
                                        name="submit"
                                        className="btn"
                                        title="Submit Search Query">
                                        Search &nbsp;<i className="icon-search"/>
                                    </button>
                                </div>
                            </div>
                        </form>
                    </Col>
                    <hr/>
                    <Col xs={12}>
                        <h1>Search v2</h1>
                        <script async src="https://cse.google.com/cse.js?cx=010961357664436421486:c5qyw3zmsio" />
                        <div className="gcse-search" />
                    </Col>
                </Row>
            </Grid>
        );
    }

}

/**
 * Exporting part of the React.Component file
 */
export default withTranslation()(SearchScene);
