// @flow

import React from "react";


/**
 * @class Required
 */
class Required extends React.Component {

    /**
     * Final Render
     * @returns {*}
     */
    render() {
        return <span className="text--red">
			*
		</span>;
    }
}

export default Required;