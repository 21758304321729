// @flow
import React from "react";
import {withTranslation} from "react-i18next";
import {Col, Grid, Row} from "react-flexbox-grid";
import {Helmet} from "react-helmet";

/**
 * @class PrivacyPolicyScene
 */
class PrivacyPolicyScene extends React.Component {


	/**
	 * Final Render
	 * @returns {*}
	 */
	render() {

		// const {t} = this.props;

		return (
			<div className={"page privacy-policy"}>
				<Helmet>
					<title>Principles for the processing of personal data under the GDPR - IQRF</title>
				</Helmet>
				<div className="page__section--bottom-padding">
					<Grid>
						<Row>
							<Col xs={12}>
								<h1>Principles for the processing of personal data under the GDPR</h1>
								<p>These principles become effective on 25. 05. 2018 and are issued in compliance with
									Regulation (EU) 2016/679 on the protection of natural persons with regard to the
									processing of personal data (the “Regulation” or “GDPR” henceforth). </p>
							</Col>
							<Col xs={12} className="page__section--with-margin">
								<h2>1. Personal data controller</h2>
								<address>
									Trade name: IQRF Tech s.r.o.<br/>
									Adress: Průmyslová 1275, 506 01 Jičín, Czech Republic<br/>
									Company ID No.: 06317375<br/>
									Tax ID No.: CZ06317375<br/>
									WWW: <a rel="noopener noreferrer" target="_blank" href="https://www.iqrf.tech">www.iqrf.tech</a>
								</address>
							</Col>
							<Col xs={12}>
								<h2>2. Privacy protection declaration</h2>
								<p>IQRF Tech s.r.o. undertakes to respect and protect your privacy. </p>
								<p>The company further commits to protect your personal data and adhere to requirements
									laid down in Act No. 101/2000 Coll., on Personal Data Protection, and, beginning
									from
									25.05.2018, in the GDPR (General Data Protection Regulation) - Regulation (EU)
									2016/679
									of the European Parliament and of the Council, of 27 April 2016, on the protection
									of
									natural persons with regard to the processing of personal data and on the free
									movement
									of such data, and repealing Directive 95/46/EC (General Data Protection
									Regulation).</p>
							</Col>
							<Col xs={12}>
								<h2>3. What data are processed and how long</h2>
								<p>The data controller processes the personal data in the scope of the name, surname,
									e-mail address, telephone contact, billing address and delivery address. </p>
								<p>The processing of the aforementioned personal data is carried out in order to
									execute the concluded purchase contract, the delivery of the ordered goods and in
									order to protect data controller’s rights concerning possible legal disputes
									arising from the concluded purchase contracts. </p>
								<p>The data controller will process the above mentioned personal data of the customers
									for 10 years from the conclusion of the relevant purchase contract in accordance
									with Section 35 of Act No. 235/2004 Coll., On Value Added Tax.</p>
								<p>We use third-party cookies on the website, specifically Google Analytics. Based on
									these, we monitor traffic to our site. This is the processing of anonymized data,
									which serves to evaluate our interest in our products. You can disable
									the use of cookies on your browser.</p>
							</Col>
							<Col xs={12}>
								<h2>4. Security of personal data</h2>
								<p>In electronic form, personal data of entities are stored on secure servers owned or
									leased by the data controller. Access to electronic data is only enabled to
									authorized
									persons only, determined by the data controller. Data is protected by modern
									security
									features. Data is secured against unauthorized access, use, or disclosure to a third
									party.</p>
								<p>In paper form, the data is stored within the secure premises of the business
									department
									and the company archive. Only authorized persons designated by the data controller
									have access to these spaces. </p>
							</Col>
							<Col xs={12}>
								<h2>5. Why personal data are processed</h2>
								<p>The provision of personal data is necessary for the creation of the order and the
									issue of the invoice, the sending of the goods and the possible settlement of the
									claim. Processing is necessary to comply with the legal obligation that
									the administrator applies, namely the registration of tax documents. </p>
							</Col>
							<Col xs={12}>
								<h2>6. Who obtains personal data</h2>
								<p>The controller will process the personal data of the customer himself. </p>
								<p>If the ordered goods are delivered at the customer's request to the address stated in
									the order, the trustee will provide the customer with the customer's personal data
									(to the extent of: name, surname, telephone contact and
									delivery address) to the relevant carrier. </p>
								<p>Personal data are used only for the purpose for which they were provided, and to the
									extent strictly necessary, the data controller do not sells or transfers the
									personal
									data to a third party for commercial use. </p>
							</Col>
							<Col xs={12}>
								<h2>7. Rights of data subjects</h2>
								<h3>7.1 Right to erasure</h3>
								<p>Pursuant to Art. 17 of the Regulation, the data subject has a right to erasure of
									personal data concerning him/her unless the controller proves legitimate reasons
									for processing such personal data. The controller has set up mechanisms to ensure
									automatic anonymization or erasure of personal data in case any personal data are
									no longer required for the purpose for which such data have been processed. If
									the data subject believes that his/her personal data have not been erased, he/she
									may contact the controller in writing to the address of the controller’s registered
									office address or in an electronic form to the controller’s email address.</p>
								<h3>7.2 Right to restriction of processing</h3>
								<p>Pursuant to Art. 18 of the Regulation, the data subject has the right to restriction
									of processing until the data subject’s instigation is settled, if the data subject
									contests the accuracy of personal data, reasons for processing of the same, or if
									the data subject has objected to processing, in writing to the controller’s
									registered
									office address or in an electronic form to the controller’s email address.</p>
								<h3>7.3 Right to notification regarding rectification or erasure of personal data or
									restriction of processing</h3>
								<p>Pursuant to Art. 19 of the Regulation, the data subject has a right to be notified
									by the controller of any rectification or erasure of personal data or restriction
									of processing. Where we rectify or erase personal data, we inform individual
									recipients, unless this proves impossible or involves disproportionate effort.
									The application regarding this right may be sent in writing to the controller’s
									registered office address or in an electronic form to the controller’s
									email address.</p>
								<h3>7.4 Right to data portability</h3>
								<p>Pursuant to Art. 20 of the Regulation, the data subject has the right to portability
									of data concerning him/her and provided by him/her to the controller, in a
									structured, commonly used and machine-readable format, and the right to ask the
									controller to transfer these data to another controller. If technically feasible,
									these data may be IQRF Tech. s.r.o. transferred to a controller appointed by you
									provided that a person acting through the respective controller is duly
									appointed and it is possible to authorize such person.</p>
								<p>In case the assertion of this right might affect any rights or freedoms of third
									parties adversely, your application cannot be complied with. The application
									regarding this right may be sent in writing to the controller’s registered
									office address or in an electronic form to the controller’s email address.</p>
								<h3>7.5 Right to object to personal data processing</h3>
								<p>Pursuant to Art. 21 of the Regulation, the data subject has the right to object to
									processing of his/her personal data for the reason of the controller’s legitimate
									interest. </p>
								<p>Unless the controller demonstrates the existence of compelling legitimate grounds
									for the processing which override the interests or rights and freedoms of the data
									subject, the controller will no longer process the personal data based on the data
									subject’s objection without undue delay. The letter of objection may be sent in
									writing to the controller’s registered office address or in an electronic form to
									the controller’s email address.</p>
								<h3>7.6 Right to withdraw consent to personal data processing </h3>
								<p>The consent to personal data processing for marketing and business purposes with
									effect from 25.05.2018 can be withdrawn at any time thereafter. The withdrawal must
									be effected in form of an express and intelligible expression of will, either in
									writing to the controller’s registered office address or in an electronic form to
									the controller’s email address. </p>
								<p>Processing of data from cookies can be eliminated by adjusting the browser
									settings.</p>
								<h3>7.7 Automated individual decision-making including profiling </h3>
								<p>The data subject has a right not to be subject to a decision based solely on
									automated processing, including profiling, which produces legal effects concerning
									him/her or affects him/her significantly in a similar way. The controller declares
									that no automated decisions are made without human evaluation as to the legal
									effects on data subjects. </p>
								<h3>7.8 Right to contact the Office for Personal Data Protection</h3>
								<p>The data subject has a right to contact the Office for Personal Data Protection
									(<a rel="noopener noreferrer" target="_blank" href="https://www.uoou.cz">www.uoou.cz</a>). </p>
							</Col>
						</Row>
					</Grid>
				</div>
			</div>
		);
	}
}

/**
 * Exporting part of the React.Component file
 */
export default withTranslation()(PrivacyPolicyScene);