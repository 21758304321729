// @flow
import React from "react";
import {withTranslation} from "react-i18next";
import {Col, Grid, Row} from "react-flexbox-grid";
import BackendRequest from "../../Models/REST";
import MasterGetter from "../../Models/Utils/MasterGetter";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import {GenericItem} from "../../Components/GenericItem";

import "../../Components/Links/Links.scss";
import InstantAction from "../../Models/Utils/InstantAction";
import {LoadingComponent} from "../../Components";


/**
 * @class WebinarsListScene
 */
class WebinarsListScene extends React.Component {

    /**
     * State
     * @type {{expectingResponse: boolean}}
     */
    state = {
        expectingResponse: true,
    };

    /**
     * Fetch app data if necessary
     */
    fetchData = () => {
        BackendRequest({
            endpoint: "webinars",
            self: this,
        });
    };

    /**
     * Component Did Mount
     */
    componentDidMount(): void {
        this.fetchData();
    }

    /**
     * Create New
     */
    createNew = () => {

        BackendRequest({
            endpoint: "webinar",
            method: "post",
            afterSuccess: (response) => {
                InstantAction.redirect("/editor/webinar/" + response.data.webinar.id);
            }
        });

    };

    /**
     * Final Render
     * @returns {*}
     */
    render() {

        const {
            entities,
        } = this.props;

        const session = MasterGetter.getSession(entities);
        const webinars = session.Webinar.all().filter(item => item.title).orderBy(["added"], ["desc"]);


        if (this.state.expectingResponse) {
            return <LoadingComponent/>;
        }

        return (
            <Grid fluid>
                <Row>
                    <Col xs={12}>
                        <h1>Webinars</h1>
                        <div>
                            <button onClick={this.createNew} className="btn">Create New</button>
                        </div>
                        <br/><br/>
                    </Col>
                    <Col xs={12}>
                        {webinars.toModelArray().map(item => {
                            return <GenericItem item={item} nameProperty={"title"} key={item.id} onClick={() => {
                                InstantAction.redirect("/editor/webinar/" + item.id);
                            }
                            }/>;
                        })}
                        <div>
                            {webinars.count() > 0 ? "" : "No webinars"}
                        </div>
                    </Col>
                </Row>
            </Grid>
        );
    }

}

/**
 *
 * @param state
 * @return {{entities: *}}
 */
const mapStateToProps = state => (
    {
        entities: state.entities,
    });

/**
 * Exporting part of the React.Component file
 */
export default withRouter(connect(mapStateToProps)(withTranslation()(WebinarsListScene)));