// @flow
import React from "react";
import {withTranslation} from "react-i18next";
import {Col, Grid, Row} from "react-flexbox-grid";
import {Helmet} from "react-helmet";

/**
 * @class EshopScene
 */
class EshopScene extends React.Component {


    /**
     * Final Render
     * @returns {*}
     */
    render() {

        const {t} = this.props;

        return (
            <Grid className={"page"}>
                <Helmet>
                    <title>{t("title.EShop")} - IQRF</title>
                </Helmet>
                <Row>
                    <Col xs={12}>
                        <h1>{t("title.EShop")}</h1>
                    </Col>
                </Row>
            </Grid>

        );
    }

}

/**
 * Exporting part of the React.Component file
 */
export default withTranslation()(EshopScene);