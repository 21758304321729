// @flow
import React from "react";
import {withTranslation} from "react-i18next";
import {Col, Grid, Row} from "react-flexbox-grid";
import {Link} from "react-router-dom";
import os_block_diagram from "../../Components/Technology/img/os-block-diagram.jpg";
import os_principle from "../../Components/Technology/img/os-principle.png";
import os_upgrades_downgrades from "../../Components/Technology/img/os-upgrades-downgrades.jpg";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import pdf_ico from "../../App/extra-icons/pdf_ico.png";
import i_ico from "../../App/extra-icons/i_ico.png";
import exe_ico from "../../App/extra-icons/exe_ico.png";
import {Helmet} from "react-helmet";


/**
 * @class TechnologyOSScene
 */
class TechnologyOSScene extends React.Component {

	/**
	 * Constructor
	 * @param props
	 */
	constructor(props) {
		super(props);

		this.state = {
			isOpen: false,
			showContent: false,
		};
	}

	/**
	 * Final Render
	 * @returns {*}
	 */
	render() {

		const {
			isOpen,
			showContent
		} = this.state;

		return (

			<div className={"page"}>
				<Helmet>
					<title>Operating system - IQRF</title>
				</Helmet>
				<Grid>
					<Row>
						<Col xs={12} xl={6}>
							<h1>Operating system</h1>
						</Col>
					</Row>
					<Row className="page__section--bottom-padding">
						<Col xs={12} xl={6}>
							<h2>Two-layer IQRF architecture</h2>
							<ul>
								<li>Operating system <strong>IQRF OS</strong> with a set of predefined
									<a href="https://doc.iqrf.org/IQRF-OS-Reference-guide/pages/table-of-os-functions.html" target="_blank" rel="noopener noreferrer"> functions</a>.
								</li>
								<li>User <strong>application</strong> written in C-language.</li>
							</ul>
							<p>This two-layer architecture is applicable for <strong>non-network applications only</strong>.
								For network applications use the three-layer architecture with
								<Link to={"/technology/dpa"}> DPA</Link> instead.</p>
							<span className={"link"} onClick={() => this.setState({isOpen: true})}>
                              » OS block diagram
                            </span>
							<br/>
							<br/>
							{isOpen && (
								<Lightbox
									mainSrc={os_block_diagram}
									onCloseRequest={() => this.setState({isOpen: false})}
								/>
							)}
						</Col>
						<Col className="text--center" xs={12} xl={6}>
							<img src={os_principle} alt={"IQRF OS principle"}/>
						</Col>
					</Row>
				</Grid>
				<div className="page__section--with-theme">
					<Grid>
						<Row>
							<Col xs={12}>
								<h2 id="os-versions">OS versions</h2>
								<h3>Current OS version for current TRs</h3>
								<p>For <strong>TR-72G, TR-75G</strong> and <strong>TR-76G</strong>:
									<em> OS v4.06G</em></p>
								<div className="table--wrapper">
									<table className="table--with-links">
										<tbody>
										<tr>
											<td><Link to="/download/0f73ab46-1bde-4cbc-8bcc-8547e6a5ebad"
												   target="_self"><img alt={"icon"} src={pdf_ico}/> User's Guide </Link>
												– OS v4.06G
											</td>
											<td></td>
										</tr>
										<tr>
											<td><Link to="/download/1480afd2-cbe3-47e0-961a-d51aba8e53b7"
												   target="_self"><img alt={"icon"} src={pdf_ico}/> Reference Guide </Link>
												– OS v4.06G – PDF version
											</td>
											<td></td>
										</tr>
										<tr>
											<td><a href="https://doc.iqrf.org/IQRF-OS-Reference-guide/"
												   rel="noopener noreferrer"
												   target="_blank"><img alt={"icon"} src={i_ico}/> Reference Guide </a>
												– OS v4.06G – Online version
											</td>
											<td></td>
										</tr>
										</tbody>
									</table>
								</div>
								<p>For <strong>TR-72D, TR-75D, TR-76D, TR-77D</strong> and <strong>TR-78D</strong>:
									<em> OS v4.06D</em></p>
								<div className="table--wrapper">
									<table className="table--with-links">
										<tbody>
										<tr>
											<td><Link to="/download/6123722d-8262-4721-9e7c-b857b3344e18"
												   target="_self"><img alt={"icon"} src={pdf_ico}/> User's Guide </Link>
												– OS v4.06D
											</td>
											<td></td>
										</tr>
										<tr>
											<td><Link to="/download/93cef3b4-3473-4a4d-80b7-3cdd4a0a82a3"
												   target="_self"><img alt={"icon"} src={pdf_ico}/> Reference Guide </Link>
												– OS v4.06D – PDF version
											</td>
											<td></td>
										</tr>
										</tbody>
									</table>
								</div>
								<h3>Deliveries</h3>
								<div className="table--wrapper">
									<table className="table--with-links">
										<tbody>
										<tr>
											<td><strong>Caution: &nbsp; &nbsp; &nbsp;All TRs</strong> in the whole application must be equipped with  <strong>the same IQRF OS version</strong>.
											</td>
										</tr>
										<tr>
											<td><strong>Exception:</strong>  Mixing of TRs with OS <strong>v4.06D</strong> and <strong>v4.06G</strong> is allowed.<br/>
											<a href="https://www.iqrf.org/technology/dpa/OsDpaCompatibility"
												rel="noopener noreferrer">» Coompatible combinations</a> of IQRF OS and DPA versions
											</td>
										</tr>
										</tbody>
									</table>
								</div>
								<p id="interim-period">Every TR can be delivered with <strong>any of existing OS version</strong>.
									It is up to the user whether to upgrade one's own application or not. If no OS
									version is <strong>specified in the order</strong>, the current one will be delivered
									(with the exception in the
									<span className={"link"} onClick={() => this.setState({showContent: !showContent})}>
                                    &nbsp; interim period &nbsp;
                                    </span>
									after releasing a new OS version).<br/>
									OS versions with <em>RFPGM enabled after reset</em> are also available <strong>on request.</strong></p>
								{showContent && (
									<div>
										<hr/>
										<p>Unless otherwise requested, all TR-xxG transceivers (including gateways, other devices, and development sets) will be delivered with IQRF OS v4.06G</p>
										<p>Unless otherwise requested, IQRF OS in all TR-xxD transceivers (including
											gateways and other devices) will be delivered as follows:</p>
										<ul>
											<li>Till 11 Apr 2022: v4.05D standard, v 4.06D on request</li>
											<li>From 12 Apr 2022: v4.06D standard, lower versions on request.</li>
										</ul>
										<hr/>
									</div>
								)}
								<h3>Archive OS versions</h3>
								<Link to={"/technology/os/current-os"}>» Current OS versions for archive TRs </Link>
								<br/>
								<Link to={"/technology/os/history-os"}>» History of all OS versions </Link>
							</Col>
						</Row>
					</Grid>
				</div>
				<Grid>
					<Row className="page__section--top-padding">
						<Col xs={12}>
							<h2 id="os-upgrade">OS upgrade</h2>
							<p>All IQRF transceivers allows upgrade to higher OS any of the OS version released for the given TR type. Downgrade (down to a lower version) is possible too.</p>
							<p>Upgrades and downgrades at the user oneself are possible according the table below.
								Other cases must be done at the factory. This can easily be ordered via
								<a href="https://eshop.iqrf.org/p/iqrf-os-upgrade" rel="noopener noreferrer" target="_blank"> IQRF E-shop</a>.
							</p>
						</Col>
					</Row>
					<Row className="page__section--bottom-padding">
						<Col className="text--center" xs={12}>
							<img src={os_upgrades_downgrades} alt={"IQRF OS upgrades and downgrades"}/>
						</Col>
						<Col xs={12}>
							<p><Link to={"/technology/os/os-migration-notes"}>&raquo; OS migration notes</Link></p>
						</Col>
					</Row>
				</Grid>
			</div>
		);
	}

}

/**
 * Exporting part of the React.Component file
 */
export default withTranslation()(TechnologyOSScene);
