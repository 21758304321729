import React, {useCallback} from "react";
import {useDropzone} from "react-dropzone";
import "./MyDropZone.scss";

/**
 * MyDropZone
 * @param props
 * @return {*}
 * @constructor
 */
function MyDropZone(props) {

	/**
	 * On Drop
	 * @type {Function}
	 */
		// eslint-disable-next-line
	const onDrop = useCallback(acceptedFiles => {
			props.onDrop(acceptedFiles);
		});

	/**
	 * Const
	 */
	const {
		getRootProps,
		getInputProps,
		isDragActive,
	} = useDropzone({
		onDrop,
		accept: props.accept,
		multiple: false,
	});

	return (
		<div className={"dropzone"} {...getRootProps()}>
			<input {...getInputProps()} />
			{
				isDragActive ?
					<p>Drop the files here ...</p> :
					<p>Click here to upload a file</p>
			}
		</div>
	);

}

export default MyDropZone;