// @flow
import React from "react";
import { withTranslation } from "react-i18next";
import { Col, Grid, Row } from "react-flexbox-grid";
import BackendRequest from "../../Models/REST";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import MasterGetter from "../../Models/Utils/MasterGetter";
import { LoadingComponent } from "../../Components";
import DistributorItemAdmin from "../../Components/Distributors/DistributorItemAdmin/DistributorItemAdmin";
import InstantAction from "../../Models/Utils/InstantAction";

/**
 * @class DistributorsScene
 */
class DistributorsScene extends React.Component {

	/**
	 * State
	 * @type {{expectingResponse: boolean}}
	 */
	state = {
		expectingResponse: true,
	};

	/**
	 *
	 */
	fetchData = () => {
		// Simply fetch every time
		BackendRequest({
			endpoint: "distributors",
			self: this,
		});

	};

	/**
	 * Create New
	 */
	createNew = () => {
		BackendRequest({
			endpoint: "distributor",
			method: "post",
			afterSuccess: (response) => {
				InstantAction.redirect("/editor/distributor/" + response.data.distributor.id);
			}
		});
	};

	/**
	 * Component Did Mount
	 */
	componentDidMount () {
		this.fetchData();
	}

	/**
	 * Final Render
	 * @returns {*}
	 */
	render () {

		const { t, entities } = this.props;
		const { expectingResponse } = this.state;

		const session = MasterGetter.getSession(entities);
		const distributors = session.Distributor.all().orderBy(["country", "name"], ["asc", "asc"]);
		const countries = session.Country.all();

		return (
			<Grid fluid>
				<Row>
					<Col xs={12}>
						<h1>{t("title.Distributors")}</h1>
					</Col>
					<Col xs={12}>
						<div>
							<button onClick={this.createNew} className="btn">Create New</button>
						</div>
						<br/><br/>
					</Col>
					<Col xs={12} className="page__section--bottom-padding">
						{distributors.filter(item => !item.country).toModelArray().map(distributor => {
							return <DistributorItemAdmin key={distributor.id} distributor={distributor}/>;
						})}
						{countries.toModelArray().map(country => {
							return <div style={{
								marginBottom: "2rem",
							}}>
								<div>
									<h2>{country.name}</h2>
								</div>
								{distributors.filter(item => item.country === country.id).toModelArray().map(distributor => {
									return <DistributorItemAdmin key={distributor.id} distributor={distributor}/>;
								})}
							</div>;
						})}
						{expectingResponse ? <LoadingComponent/> : ""}
						{distributors.count() && !expectingResponse === 0 ? "No distributors" : ""}
					</Col>
				</Row>
			</Grid>
		);
	}

}

/**
 * Map state to props
 * @param state
 * @return {{}}
 */
const mapStateToProps = state => (
	{
		entities: state.entities,
	});

/**
 * Exporting part of the React.Component file
 */
export default withRouter(connect(mapStateToProps)(withTranslation()(DistributorsScene)));