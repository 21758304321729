import React from "react";
// Import component CSS
import Modal from "react-modal";
import {withTranslation} from "react-i18next";
import moment from "moment";
import BackendRequest from "../Models/REST";
import Markdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import rehypeSanitize from "rehype-sanitize";
import remarkGfm from "remark-gfm";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

import "./ModalPopup.scss";


const customStyles = {
    overlay: {
        background: "rgba(0,0,0,.5)",
    },
    // content: {
    //     borderRadius: 0,
    //     position: "relative",
    //     border: "1px solid #004d81",
    //     zIndex: 999,
    // }
};

class ModalPopup extends React.Component {

    state = {
        id: null,
        title: "",
        content: "",
        showModal: false,
        doNotShowId: null,
        doNowShowStatus: false,
    };

    /**
     * Close Modal
     */
    closeModal = () => {

        this.setState({
            showModal: false,
        });
    };

    componentDidMount(): void {

        this.fetchData();

        let doNotShow = localStorage.getItem("doNotShowPopup");

        if (doNotShow) {
            this.setState({
                doNotShowId: doNotShow,
            });
        }

    }

    doNotShowAgain = (event) => {

        let value = event.target.checked;

        this.setState({
            doNowShowStatus: value,
        });

        if(value){
            localStorage.setItem("doNotShowPopup", this.state.id);
        }else{
            localStorage.removeItem("doNotShowPopup");
        }
    };

    fetchData = () => {
        if (this.showToday()) {
            BackendRequest({
                endpoint: "modal-popup",
                afterSuccess: (response) => {
                    let data = response.data;
                    if (data.length > 0) {
                        localStorage.setItem("modalShownAt", moment().format());
                        let modalData = data[0];
                        this.setState({
                            showModal: true,
                            title: modalData.title,
                            content: modalData.content,
                            id: modalData.id,
                        });
                    }
                }
            });
        }
    };

    showToday = () => {

        let lastShown = moment(localStorage.getItem("modalShownAt"));
        let lastMoment = lastShown ? moment(lastShown) : null;

        if (lastMoment) {
            if (lastMoment.isSame(moment(), "day")) {
                // It is today
                // console.log("it is today");
                return false;
            } else {
                // console.log("show modal");
                return true;
            }
        } else {
            return true;
        }


    };

    /**
     * Final Render
     * @returns {*}
     */
    render() {

        const {
            showModal,
        } = this.state;

        if(parseInt(this.state.doNotShowId) === this.state.id){
            return null;
        }

        return <Modal
            isOpen={showModal}
            onRequestClose={this.closeModal}
            style={customStyles}
            className={"modal-popup"}
            ariaHideApp={false}
        >
            <div className="text--center">
                <button
                    style={{
                        background: "transparent",
                        border: 0,
                        right: 5,
                        cursor: "pointer",
                        top: 10,
                        position: "absolute",
                        outline: "none",
                    }}
                    onClick={this.closeModal}
                >
                    <i className="icon-close" />
                </button>
                <Markdown
                  rehypePlugins={[rehypeRaw, rehypeSanitize]}
                  remarkPlugins={[remarkGfm]}
                  children={this.state.title}
                />
                <Markdown
                  rehypePlugins={[rehypeRaw, rehypeSanitize]}
                  remarkPlugins={[remarkGfm]}
                  children={this.state.content}
                />
                &nbsp;
                <FormControlLabel
                    control={<Checkbox checked={this.state.doNowShowStatus}
                                       onChange={this.doNotShowAgain}
                     name="show-again"/>}
                    label="Do not display this message any more"
                />

            </div>
        </Modal>;
    }
}

export default withTranslation()(ModalPopup);
