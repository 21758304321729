// @flow

import PropTypes from "prop-types";
import {attr} from "redux-orm";

import CRUDModel from "../ORM/CRUDModel";

/**
 * @class StartupPackageHistory
 */
export default class StartupPackageHistory extends CRUDModel {}

StartupPackageHistory.modelName = "StartupPackageHistory";

StartupPackageHistory.fields = {
	id: attr(), // non-relational field for any value; optional but highly recommended
	description: attr(),
	ordering: attr(),
	title: attr(),
};

StartupPackageHistory.propTypes = {
	name: PropTypes.string,
};


export const ADD_STARTUP_PACKAGE_HISTORY = "ADD_STARTUP_PACKAGE_HISTORY";
export const REMOVE_STARTUP_PACKAGE_HISTORY = "REMOVE_STARTUP_PACKAGE_HISTORY";
export const UPDATE_STARTUP_PACKAGE_HISTORY = "UPDATE_STARTUP_PACKAGE_HISTORY";