// @flow

// Necessary Evil
import React from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import LoadingComponent from "../Components/LoadingComponent";
import BackendRequest from "../Models/REST/BackendRequest";
import FormGroup from "../Components/Form/FormGroup";
import ReCAPTCHA from "react-google-recaptcha";
import { Required } from "../Components";
import { SuccessMessage } from "../Components/Form";
import ErrorMessage from "../Components/Form/ErrorMessage/ErrorMessage";

/**
 * @class TrainingRequestForm
 *
 */
class TrainingRequestForm extends React.Component {

	/**
	 * PropTypes
	 * @type {{children: shim, videoCategories: *}}
	 */
	static propTypes = {
		afterSuccess: PropTypes.func,
		recaptcha: PropTypes.bool,
	};

	/**
	 * Default Props
	 * @type {{afterSuccess: TrainingRequestForm.defaultProps.afterSuccess}}
	 */
	static defaultProps = {
		afterSuccess: () => {
		},
		recaptcha: true,
	};

	/**
	 * State
	 * @type {{formData: {}, expectingResponse: boolean, errorMessage: null}}
	 */
	state = {
		expectingResponse: false,
		success: false,
		reCaptcha: "",
		errorMessage: null,
		email: "",
		participants: 1,
		message: "",
	};

	/**
	 * Handle Input Changes
	 * @param event
	 */
	handleInputChange = (event) => {

		const target = event.target;
		const value = target.type === "checkbox" ? target.checked : target.value;
		const name = target.name;

		this.setState({
			[name]: value
		});

	};

	/**
	 * Handle Submit
	 * @param e
	 */
	handleSubmit = (e) => {

		e.preventDefault();

		/**
		 *
		 * @type {{subject: string, message: string, email: string, participants: number}}
		 */
		const payload = {
			email: this.state.email,
			participants: this.state.participants,
			message: this.state.message,
			reCaptcha: this.state.reCaptcha,
		};

		BackendRequest({
			endpoint: "request-training",
			method: "post",
			payload: payload,
			self: this,
			afterSuccess: () => {
				this.setState({
					success: true,
					email: "",
					participants: 1,
					message: "",
				});
			},
		});

	};

	/**
	 *
	 * @param value
	 */
	onCaptcha = (value) => {

		this.setState({
			reCaptcha: value
		});

	};

	/**
	 * Final Render
	 * @returns {*}
	 */
	render () {

		/**
		 * @info Translation function, className
		 */
		const { t } = this.props;

		/**
		 * @info Error variable
		 */
		let error = "";
		let success = this.state.success ? <SuccessMessage>Your request has been successfully
			send.</SuccessMessage> : "";

		/**
		 * @info Handling Error Message
		 */
		if (this.state.errorMessage !== null) {
			error = <ErrorMessage><i
				className="icon-cube"/> {t(this.state.errorMessage)}</ErrorMessage>;
		}

		/**
		 * Final Output
		 * @type {{}}
		 */
		return <form onSubmit={this.handleSubmit} className={"form"}>

			<h2>
				{t("title.RequestForIQRFTraining")}
			</h2>
			<FormGroup>
				<label htmlFor="email">{t("label.YourEmailAddress")} <Required/></label>
				<input
					type="email"
					onChange={this.handleInputChange}
					value={this.state.email}
					id="email"
					name="email"
					required
				/>
			</FormGroup>
			<FormGroup>
				<label htmlFor="participants">{t("label.NumberOfParticipants")} <Required/></label>
				<input
					type="number"
					onChange={this.handleInputChange}
					value={this.state.participants}
					id="participants"
					name="participants"
					required
				/>
			</FormGroup>
			<FormGroup>
				<label htmlFor="message">{t("label.Message")}</label>
				<textarea
					onChange={this.handleInputChange}
					value={this.state.message}
					id="message"
					name="message"
					placeholder="Put your wants and suggestions here, e.g. Suggested date: … or …"
				/>
			</FormGroup>
			{this.props.recaptcha ?
				<FormGroup style={{ display: "flex", justifyContent: "flex-start" }}>
					<ReCAPTCHA
						theme={"light"}
						size={"normal"}
						tabindex={3}
						sitekey={process.env.REACT_APP_GOOGLE_SITE_KEY}
						onChange={this.onCaptcha}
					/>
				</FormGroup> : ""
			}
			<FormGroup className="text--center">
				{error}
				{(this.state.expectingResponse) ? <LoadingComponent/> :
					this.state.success ? success : <button type="submit" tabIndex={2}
														   className="btn">{t("button.Submit")}</button>}
				{this.state.success ? <div onClick={() => {
					this.setState({ success: false });
				}} className={"link"}><br/>Send another request?</div> : ""}

			</FormGroup>

		</form>;
	}
}

export default withTranslation()(TrainingRequestForm);