// @flow
import React from "react";
import { withTranslation } from "react-i18next";
import { Col, Grid, Row } from "react-flexbox-grid";
import BackendRequest from "../../Models/REST";
import MasterGetter from "../../Models/Utils/MasterGetter";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { LoadingComponent } from "../../Components";
import InstantAction from "../../Models/Utils/InstantAction";
import { ReleaseNoteItem } from "../../Components/RelaseNotesLoader/ReleaseNoteItem";

/**
 * @class ReleaseNotesScene
 */
class ReleaseNotesScene extends React.Component {

	/**
	 * State
	 * @type {{expectingResponse: boolean}}
	 */
	state = {
		expectingResponse: true,
	};

	/**
	 * Fetch app data if necessary
	 */
	fetchData = () => {
		BackendRequest({
			endpoint: "release-notes",
			self: this,
		});
		// BackendRequest({
		// 	endpoint: "files",
		// 	self: this,
		// });
	};

	/**
	 * Component did mount
	 */
	componentDidMount () {

		this.fetchData();

	}

	/**
	 *
	 */
	createNew = () => {

		BackendRequest({
			endpoint: "release-note",
			method: "post",
			payload: {
				product: null,
			},
			afterSuccess: (response) => {
				InstantAction.redirect("/editor/release-note/" + response.data.release_note.id);
			}
		});

	};

	/**
	 *
	 */
	clone = () => {
		const {
			match
		} = this.props;

		const productId = match.params.id;

		BackendRequest({
			endpoint: "release-note/" + productId + "/copy",
			method: "post",
			self: this,
			afterSuccess: (response) => {
				InstantAction.redirect("/editor/release-note/item/" + response.data.release_note.id);
				this.forceUpdate();
			}
		});
	};

	/**
	 * Final Render
	 * @returns {*}
	 */
	render () {

		const {
			entities,
		} = this.props;

		const session = MasterGetter.getSession(entities);
		const releaseNotes = session.ReleaseNote.all().orderBy(["added"], ["desc"]);

		return (
			<Grid fluid>
				<Row>
					<Col xs={12}>
						<h1>Release notes</h1>
						<div>
							<button onClick={this.createNew} className="btn">Create New</button>
						</div>
						<br/><br/>
					</Col>
				</Row>
				<Row>
					<Col xs={12}>
						{this.state.expectingResponse ?
							<LoadingComponent/> :
							<div>
								{releaseNotes.toModelArray().map(item => {
									return <ReleaseNoteItem nameProperty={"title"} item={item} key={item.id}/>;
								})}
								{releaseNotes.count() === 0 && <div>No release notes to display</div>}
							</div>
						}
					</Col>
				</Row>
			</Grid>
		);
	}

}

/**
 *
 * @param state
 * @return {{entities: *}}
 */
const mapStateToProps = state => (
	{
		entities: state.entities,
	});

/**
 * Exporting part of the React.Component file
 */
export default withRouter(connect(mapStateToProps)(withTranslation()(ReleaseNotesScene)));