import {
	RESET_STORE,
	SET_APP_LOADED,
	SET_APP_URL,
	SET_CLOSE_NAVIGATION, SET_EDITOR_LINKS_FETCHED,
	SET_LOGGED_USER,
	SET_PRODUCTS_LOADED,
	SET_SEARCH,
	SET_SEARCH_TYPING,
} from "./App.actions";


/**
 * App Initial State
 * @type {{appLoaded: boolean, loggedMember: boolean, url: string, appMessage: null}}
 */
const INITIAL_STATE = {
    appLoaded: false,
    loggedUser: null, // TODO: Remove -> set False
    search: "",
    url: "",
    closeNavigation: false,
    productsLoaded: false,
    searchTyping: false,
    editorLinksFetched: false,

};


/**
 * Main App Reducer
 * @param state
 * @param action
 * @returns {*}
 */
export function appReducer(state: Object = INITIAL_STATE, action: any) {

    switch (action.type) {

        case SET_EDITOR_LINKS_FETCHED:
            return Object.assign({}, state, {
                editorLinksFetched: action.status
            });
        case SET_APP_LOADED:
            return Object.assign({}, state, {
                appLoaded: action.status
            });
        case SET_SEARCH_TYPING:
            return Object.assign({}, state, {
                searchTyping: action.status
            });
        case SET_CLOSE_NAVIGATION:
            return Object.assign({}, state, {
                closeNavigation: action.status
            });
        case SET_PRODUCTS_LOADED:
            return Object.assign({}, state, {
                productsLoaded: action.status
            });
        case SET_APP_URL:
            return Object.assign({}, state, {
                url: action.url
            });
        case SET_LOGGED_USER:
            return Object.assign({}, state, {
                loggedUser: action.id
            });
        case SET_SEARCH:
            return Object.assign({}, state, {
                search: action.searchTerm,
            });
        case RESET_STORE:
            return INITIAL_STATE;
        default:
            return state;
    }
}