// @flow
import React from "react";
import {withTranslation} from "react-i18next";
import {Col, Grid, Row} from "react-flexbox-grid";
import {connect} from "react-redux";
import MasterGetter from "../../Models/Utils/MasterGetter";
import BackendRequest from "../../Models/REST";
import SearchBar from "../../Components/SearchBar/SearchBar";
import LoadingComponent from "../../Components/LoadingComponent";
import MyDropZone from "../../Components/MyDropzone";
import FileAdminItem from "../../Components/Files/FileAdminItem/FileAdminItem";
import {FileSection} from "../../Components/Editor/File";
import InstantAction from "../../Models/Utils/InstantAction";
/**
 * @class FilesScene
 */
class FilesScene extends React.Component {

	/**
	 * State
	 * @type {{expectingResponse: boolean}}
	 */
	state = {
		expectingResponse: true,
		showArchived: false,
		errorMessage: false,
		error: false,
	};

	/**
	 * Fetch data
	 */
	fetchData() {
		BackendRequest({
			endpoint: "editor/files",
			self: this,
		});
	}

	/**
	 * ComponentWillMount
	 */
	componentDidMount() {
		this.fetchData();
	}

	/**
	 * On Drop Action
	 * @param acceptedFiles
	 */
	onDrop = (acceptedFiles) => {

		acceptedFiles.forEach(file => {

			let filenameSpliced = file.name.split(".");
			let fileType = filenameSpliced[filenameSpliced.length - 1];

			/**
			 * Payload
			 * @type {{file: *, filename: *, section: number, type: (*|string)}}
			 */
			const data = {
				file: file,
				filename: file.name,
				type: fileType,
			};

			BackendRequest({
				method: "post",
				endpoint: "file-upload",
				payload: data,
				self: this,
				afterSuccess: (response) => {
					InstantAction.redirect("/editor/file/"+response.data.file.id);
				},
				afterError: () => {
					this.setState({
						errorMessage: "Error during upload. Please, contact your support."
					});
				}
			});
		});
	};

	/**
	 *
	 */
	showArchive = () => {

		localStorage.setItem("edit-archive", !this.state.showArchived);
		this.setState({
			showArchived: !this.state.showArchived
		});

	};

	/**
	 * Final Render
	 * @returns {*}
	 */
	render() {

		const {t, entities, app} = this.props;
		const session = MasterGetter.getSession(entities);
		const files = session.File.all().filter(item => {

			let appSearchString = (app.search) ? app.search.toLowerCase() : "";
			let itemSearchString = item.name.toLowerCase();

			return itemSearchString.includes(appSearchString);

		}).filter(item => item.is_archived === this.state.showArchived).orderBy(["uploaded"], ["desc"]);

		const sections = session.DownloadSection.all();

		return (
			<Grid fluid>
				<Row>
					<Col xs={12}>
						<h1>{t("title.Files")}</h1>
					</Col>
				</Row>
				<Row>
					<Col xs={12}>
						<h2>Upload file</h2>
						<div>
							<MyDropZone
								onDrop={this.onDrop}
								// accept={"image/*,.pdf,.doc,.docx,.xsl,.xlsx,.zip,.txt,.iqrfmcr"}
							/>
						</div>
						{this.state.errorMessage && <div className="text--red">{this.state.errorMessage}</div>}
					</Col>
				</Row>
				<Row>
					<Col xs={12}>
						<SearchBar/>
						<br/>
						<div className="text--center">
							<button
								onClick={this.showArchive}
								className={"btn"}>{this.state.showArchived ? "Back from Archive" : "Archived Only"}</button>
						</div>
					</Col>
				</Row>
				<Row>
					<Col xs={12} style={{paddingBottom: "2rem"}}>
						{(this.state.expectingResponse) ?
							<LoadingComponent/> : sections.toModelArray().map(section => {
								return <FileSection section={section._fields} files={files.filter(item => item.section__id === section.id)} isOpen={app.search.length > 0}/>;
							})}
							<h3>No Section</h3>
						{files.filter(item => item.section === null || item.section__id === null).toModelArray().map(file => {
							return <FileAdminItem file={file}/>;
						})}
						{!this.state.expectingResponse && files.count() === 0 ? <div>No files available</div> : ""}
					</Col>
				</Row>
			</Grid>
		);
	}

}

/**
 * This function maps the state to a
 * prop called `state`.
 * In larger apps it is often good
 * to be more selective and only
 * map the part of the state tree
 * that is necessary.
 */
const mapStateToProps = state => (
	{
		app: state.app,
		entities: {
			File: state.entities.File,
			DownloadSection: state.entities.DownloadSection,
			FileType: state.entities.FileType,
		},
	});
/**
 * Exporting part of the React.Component file
 */
export default connect(mapStateToProps)(withTranslation()(FilesScene));
