import React from "react";
import PropTypes from "prop-types";
import FileAdminItem from "../../Files/FileAdminItem/FileAdminItem";

export default class FileSection extends React.PureComponent {

    propTypes = {
        files: PropTypes.any,
        section: PropTypes.any,
        isOpen: PropTypes.bool,
    };


    state = {
        opened: false,
    };

    static getDerivedStateFromProps = (nextProps, state) => {

        if((nextProps.isOpen !== state.opened) && nextProps.isOpen){
            return {
                opened: nextProps.isOpen,
            };
        }

        return null;
    };

    switchOpened = () => {
        this.setState({
            opened: !this.state.opened
        });
    };

    render() {

        const {section, files} = this.props;

        if(files.count() === 0){
            return null;
        }

        return (<div key={section.id} onClick={this.switchOpened} style={{cursor: "pointer"}}>
            <h3>{section.name} {this.state.opened ? "-" : "+"}</h3>
            {this.state.opened &&
            files.toModelArray().map(file => {
                return <FileAdminItem file={file}/>;
            })}
        </div>);
    }

}