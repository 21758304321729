// @flow
import React from "react";
import {withTranslation} from "react-i18next";
import {Col, Grid, Row} from "react-flexbox-grid";
import dsm from "../../Components/Technology/video/dsm.mp4";
import {Helmet} from "react-helmet";
import {HashLink} from "react-router-hash-link";

/**
 * @class TechnologyDSMScene
 */
class TechnologyDSMScene extends React.Component {


  /**
   * Final Render
   * @returns {*}
   */
  render() {

    return (
      <div className={"page"}>
      <Helmet>
      <title>DPA Service Mode - IQRF</title>
    </Helmet>
    <Grid>
    <Row className="page__section--bottom-padding">
      <Col xs={12}>
        <h1>DPA Service Mode (DSM)</h1>
        <h2>Local maintenance of any network device using a special service tool (CATS)</h2>
        <p><a href="https://doc.iqrf.org/IQMESH-Network-deployment/pages/dpa-service-mode.html" target="_blank" rel="noreferrer noopener">DSM</a> is a special <strong>service mode</strong> intended especially for service purposes. It can be established with (<strong>only one</strong>) network device (<strong>in direct RF range</strong>) <strong>after reset</strong> using the <a href="https://doc.iqrf.org/IQMESH-Network-deployment/pages/cats-service-tools.html" target="_blank" rel="noreferrer noopener">CATS</a> service tool (created within IQRF IDE). The knowledge of the <HashLink to="/technology/security#access-password">Access Password</HashLink> of this network device is required. RF communication passes via the dedicated service channel 0, or possibly via the alternative DSM channel specified in the <a href="https://doc.iqrf.org/IQMESH-Network-deployment/pages/preparation-of-trs.html" target="_blank" rel="noreferrer noopener">TR configuration</a> of the given network device.</p>
      </Col>
    </Row>
    </Grid>
    <Grid>
    <Row>
      <Col xs={12}>
      <div className="text--center" id="dsm-animation">
        <video width="560" controls>
        <source src={dsm}/>
        <p>Your browser does not support the video tag.</p>
        </video>
      </div>
    </Col>
    </Row>
    </Grid>
    <Grid>
    <Row>
    <Col xs={12}>
      <p>&nbsp;</p>
    </Col>
    </Row>
    </Grid>
    </div>

  );
  }

}

/**
 * Exporting part of the React.Component file
 */
export default withTranslation()(TechnologyDSMScene);
