// @flow
import React from "react";
import {withTranslation} from "react-i18next";
import BackendRequest from "../../Models/REST";
import Markdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import rehypeSanitize from "rehype-sanitize";
import remarkGfm from "remark-gfm";
import moment from "moment";
import MasterGetter from "../../Models/Utils/MasterGetter";
import {Col, Grid, Row} from "react-flexbox-grid";
import {LoadingComponent} from "../../Components";
import {Helmet} from "react-helmet";

/**
 * @class NewsListScene
 */
class NewsListScene extends React.Component {

	/**
	 *
	 * @type {{expectingResponse: boolean}}
	 */
	state = {
		expectingResponse: true,
	};


	/**
	 * Fetch data from REST API
	 */
	fetchData = () => {

		BackendRequest({
			endpoint: "news/200",
			self: this,
		});

	};

	/**
	 * Component did mount
	 */
	componentDidMount(): * {
		this.fetchData();
	}

	/**
	 * Final Render
	 * @returns {*}
	 */
	render() {

		// const {t} = this.props;
		const {entities} = this.props;
		const session = MasterGetter.getSession(entities);
		const news = session.News.all().filter(item => {
			return item.is_online && (moment(item.published) < moment());
		});

		return (
			<Grid>
				<Helmet>
					<title>News - IQRF</title>
				</Helmet>
				<Row className="page__section--bottom-padding">
					<Col xs={12}>
						<div>
							<div className="page__section--bottom-padding">
								<h1>News</h1>
								<ul>
									{news.toRefArray().map(item => {

										const publishedNode = <span
											className="news-loader__posted"><i>{moment(item.published).format("D MMM YYYY")}</i></span>;

										return <li className="news-loader__item full" key={item.id}>
											<div>
												<Markdown
													rehypePlugins={[rehypeRaw, rehypeSanitize]}
													remarkPlugins={[remarkGfm]}
													children={item.perex}
												/>&nbsp;{!item.content && publishedNode}</div>
											{item.content && <Markdown
												rehypePlugins={[rehypeRaw, rehypeSanitize]}
												remarkPlugins={[remarkGfm]}
												children={item.content}
												className={"news-loader__inner-content"}
											/>}
											{item.content && <div style={{textAlign: "right"}}>{publishedNode}</div>}
										</li>;
									})}
								</ul>
								{this.state.expectingResponse && <LoadingComponent />}
								{news.count() === 0 && <div>No news available</div>}
							</div>
						</div>
					</Col>
				</Row>
			</Grid>
		);
	}
}

/**
 * Exporting part of the React.Component file
 */
export default withTranslation()(NewsListScene);
