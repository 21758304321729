// @flow
import React from "react";
import {withTranslation} from "react-i18next";
import {Col, Grid, Row} from "react-flexbox-grid";
import BackendRequest from "../../Models/REST";
import LoadingComponent from "../../Components/LoadingComponent";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import MasterGetter from "../../Models/Utils/MasterGetter";

import "../../Components/Distributors/Distributors.scss";
import DistributorCountryItem from "../../Components/Distributors/DistributorCountryItem/DistributorCountryItem";
import {Helmet} from "react-helmet";

/**
 * @class DistributorsScene
 */
class DistributorsScene extends React.Component {

    /**
     * State
     * @type {{expectingResponse: boolean}}
     */
    state = {
        expectingResponse: true,
    };


    /**
     * Fetch data from REST API
     */
    fetchData = () => {

        BackendRequest({
            endpoint: "distributors",
            self: this,
        });

    };

    /**
     *
     */
    componentDidMount() {
        this.fetchData();
    }

    /**
     * Final Render
     * @returns {*}
     */
    render() {

        const {t, entities} = this.props;

        const session = MasterGetter.getSession(entities);
        const countries = session.Country.all().orderBy("name");
        const distributors = session.Distributor.all();

        return (
            <Grid className={"page"}>
                <Helmet>
                    <title>{t("title.Distributors")} - IQRF</title>
                </Helmet>
                <Row>
                    <Col xs={12}>
                        <h1>{t("title.Distributors")}</h1>
                        <div className="page__section--with-margin">
                            {this.state.expectingResponse ?
                                <div className={"text--center"}><LoadingComponent/></div> : ""}
                            {countries.toModelArray().map(country => {

                                let distributorsQuery = distributors.filter(item => item.country === country.id);

                                if (distributorsQuery.count() === 0) {
                                    return null;
                                }

                                return <DistributorCountryItem country={country} distributors={distributorsQuery}/>;

                            })}
                        </div>
                    </Col>
                </Row>
            </Grid>

        );
    }

}

/**
 * Map State To Props
 * @param state
 * @return {{entities: *}}
 */
const mapStateToProps = state => (
    {
        entities: {
            Photo: state.entities.Photo,
            Country: state.entities.Country,
            Distributor: state.entities.Distributor,
        },
    });

/**
 * Exporting part of the React.Component file
 */
export default withRouter(connect(mapStateToProps)(withTranslation()(DistributorsScene)));