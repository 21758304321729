// @flow
import React from "react";
import {withTranslation} from "react-i18next";
import {Col, Grid, Row} from "react-flexbox-grid";
import "../../Components/Technology/Technology.scss";
import {Helmet} from "react-helmet";

/**
 * @class TechnologyEcosystemScene
 */
class TechnologyEcosystemScene extends React.Component {

  /**
   * Constructor
   * @param props
   */
  constructor(props) {
    super(props);

    this.state = {
      showContentSupportedGUI: false,
      showContentGUI: false,
      showContentCommand: false,
      supportedCommand: false,
    };
  }

  /**
   * Final Render
   * @returns {*}
   */
  render() {

    return (
      <Grid className={"page"}>
        <Helmet>
          <title>Fast growing ecosystem - IQRF</title>
        </Helmet>
        <Row>
          <Col xs={12}>
            <h1>Fast-growing ecosystem</h1>
            <p><img src="https://api.iqrf.org/static/media/img/web/4c264732ed8daa34bd28c04e0779fafc.jpg" alt="ecosystem" /></p>
            <p>Be a part of the <strong>IQRF community</strong>. Your product equipped with IQRF wireless technology can become <strong>attractive</strong> to other companies and customers.</p>
            <p>
              <strong>Develop</strong> a comprehensive IQRF system competitive in IoT market <strong>quickly</strong> and <strong>cost-effectively</strong>.
              <strong> Offer your products</strong> to others and/or use <strong>the products</strong> of others within the IQRF community, either at <a href="https://www.iqrfalliance.org/marketplace/"  target="_blank" rel="noopener noreferrer">IQRF Marketplace</a> (for IQRF interoperable devices by members of <a href="https://www.iqrfalliance.org/"  target="_blank" rel="noopener noreferrer">IQRF Alliance</a>) and/or at <a href="https://iqrf.shop/"  target="_blank" rel="noopener noreferrer">IQRF Solution shop</a> (for all IQRF devices, even not interoperable, not yet certified, preliminary and so on).</p>
            <p>Participate in synergy rather than discover America.</p>
          </Col>
        </Row>
      </Grid>
    );
  }
}

/**
 * Exporting part of the React.Component file
 */
export default withTranslation()(TechnologyEcosystemScene);