/**
 * Get Cookie
 * @param cname
 * @returns {*}
 */
export function getCookie(cname: string) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let cookia = decodedCookie.split(";");
    for(let i = 0; i <cookia.length; i++) {
        let cad = cookia[i];
        while (cad.charAt(0) === " ") {
            cad = cad.substring(1);
        }
        if (cad.indexOf(name) === 0) {
            return cad.substring(name.length, cad.length);
        }
    }
    return "";
}

/**
 *
 * @param cname
 * @param cvalue
 * @param exdays
 */
export function setCookie(cname, cvalue, exdays) {
    let date = new Date();
    date.setTime(date.getTime() + (exdays*24*60*60*1000));
    let expires = "expires="+ date.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}