// @flow

// Necessary Evil
import React from "react";
import {withTranslation} from "react-i18next";
// Import component CSS
import "./MobileNavigation.scss";
import logo from "../../TopPanel/iqrf-logo.png";
import {Link, NavLink, withRouter} from "react-router-dom";
import MasterGetter from "../../../Models/Utils/MasterGetter";
import {connect} from "react-redux";
import App from "../../../App/App";


/**
 * @class MobileNavigation
 */
class MobileNavigation extends React.Component {

	/**
	 * Default State
	 * @type {{secondary: null, open: boolean}}
	 */
	state = {
		open: false,
		secondary: null,
	};

	/**
	 * Switch secondary navigation
	 * @param secondary
	 */
	switchSecondary = (secondary) => {

		if (secondary === this.state.secondary) {
			// It's same click
			secondary = null;
		}

		this.setState({
			secondary: secondary
		});
	};

	/**
	 * Switch open navigation
	 */
	switchOpen = () => {
		this.setState({
			open: !this.state.open,
			secondary: null,
		});
	};

	/**
	 * Final Render
	 * @returns {*}
	 */
	render() {

		const {
			open,
		} = this.state;

		const {
			t,
			entities
		} = this.props;

		const session = MasterGetter.getSession(entities);
		const productCategories = session.ProductCategory.all().filter(item => item.is_online).orderBy("priority");

		return <div className="mobile-navigation">
			<div className="mobile-navigation__options">
				<div className={"mobile-navigation__logo"}>
					<Link to={"/"} onClick={this.switchOpen}>
						<img src={logo} alt={"IQRF logo"}/>
					</Link>
				</div>
				<div>
					<button
						onClick={this.switchOpen}
						className="mobile-navigation__switcher">
						<i className="icon-menu"/>
					</button>
				</div>
			</div>
			{open &&
			<div className="mobile-navigation__content">
				<ul className="mobile-navigation__list">
					<li>
						<NavLink onClick={this.switchOpen} exact activeClassName={"active"} to={"/what-is-iqrf"}>
							{t("navigation.AboutIQRF")}
						</NavLink>
					</li>
					<li onClick={() => this.switchSecondary("technology")}>
						<span>
							{t("navigation.Technology")} <i className="icon-sort-down"/>
						</span>
						{this.state.secondary === "technology" &&
						<ul className="mobile-navigation__secondary">
							<li>
								<NavLink onClick={this.switchOpen} to={"/technology/rf"}>
									RF
								</NavLink>
							</li>
							<li>
								<NavLink onClick={this.switchOpen} to={"/technology/iqmesh"}>
									IQMESH
								</NavLink>
							</li>
							<li>
								<NavLink onClick={this.switchOpen} to={"/technology/frc"}>
									FRC
								</NavLink>
							</li>
							<li>
								<NavLink onClick={this.switchOpen} to={"/technology/os"}>
									OS
								</NavLink>
							</li>
							<li>
								<NavLink onClick={this.switchOpen} to={"/technology/dpa"}>
									DPA
								</NavLink>
							</li>
							<li>
								<NavLink onClick={this.switchOpen} to={"/technology/ide"}>
									IDE
								</NavLink>
							</li>
							<li>
								<NavLink onClick={this.switchOpen} to={"/technology/gw-daemon"}>
									GW Daemon
								</NavLink>
							</li>
							<li>
								<NavLink onClick={this.switchOpen} to={"/technology/sdk"}>
									SDK
								</NavLink>
							</li>
							<li>
								<NavLink onClick={this.switchOpen} to={"/technology/repository"}>
									Repository
								</NavLink>
							</li>
							<li>
								<NavLink onClick={this.switchOpen} to={"/technology/clouds"}>
									Clouds
								</NavLink>
							</li>
						</ul>
						}
					</li>
					<li onClick={() => this.switchSecondary("products")}>
						<span>
							{t("navigation.Products")} <i className="icon-sort-down"/>
						</span>
						{this.state.secondary === "products" &&
						<ul className="mobile-navigation__secondary">
							{productCategories.toModelArray().map(item => {
								return <li>
									<NavLink
										onClick={this.switchOpen}
										to={"/products/" + item.friendly_url()}
									>
										{item.name}
									</NavLink>
								</li>;
							})}
						</ul>
						}
					</li>
					<li>
						<NavLink onClick={this.switchOpen} activeClassName={"active"} to={"/how-to-start"}>
							{t("navigation.HowToStart")}
						</NavLink>
					</li>
					<li onClick={() => this.switchSecondary("support")}>
                        <span>
							{t("navigation.Support")} <i className="icon-sort-down"/>
                        </span>
						{this.state.secondary === "support" &&
						<ul className="mobile-navigation__secondary">
							<li>
								<NavLink
									onClick={this.switchOpen}
									to={"/support/videos"}
								>
									{t("navigation.Videos")}
								</NavLink>
							</li>
							<li>
								<NavLink
									onClick={this.switchOpen}
									to={"/support/application-notes"}
								>
									{"Application Notes"}
								</NavLink>
							</li>
							<li>
								<NavLink
									onClick={this.switchOpen}
									to={"/support/webinars"}
								>
									{t("navigation.Webinars")}
								</NavLink>
							</li>
							<li>
								<NavLink
									onClick={this.switchOpen}
									to={"/support/faq"}
								>
									{t("navigation.FAQ")}
								</NavLink>
							</li>
							<li>
								<NavLink
									onClick={this.switchOpen}
									to={"/support/training"}
								>
									{t("navigation.Training")}
								</NavLink>
							</li>
							<li>
								<NavLink
									onClick={this.switchOpen}
									to={"/support/fae"}
								>
									{t("navigation.FAE")}
								</NavLink>
							</li>
							<li>
								<NavLink
									onClick={this.switchOpen}
									to={"/support/downloads"}>
									{t("navigation.Downloads")}
								</NavLink>
							</li>
							<li>
								<NavLink
									onClick={this.switchOpen}
									to={"/support/contact-support"}
								>
									{t("navigation.ContactSupport")}
								</NavLink>
							</li>
						</ul>}
					</li>
					<li onClick={() => this.switchSecondary("sales")}>
						<span>
							{t("navigation.Sales")} <i className="icon-sort-down"/>
						</span>
						{this.state.secondary === "sales" &&
						<ul className="mobile-navigation__secondary">
							<li>
								<NavLink
									onClick={this.switchOpen}
									activeClassName={"active"}
									to={"/sales/contact"}
								>
									{t("navigation.ContactSales")}
								</NavLink>
							</li>
							<li>
								<NavLink
									onClick={this.switchOpen}
									activeClassName={"active"}
									to={"/sales/distributors"}
								>
									{t("navigation.Distributors")}
								</NavLink></li>
							<li>
								<NavLink
									onClick={this.switchOpen}
									activeClassName={"active"}
									to={"/sales/company"}
								>
									{t("navigation.Company")}
								</NavLink>
							</li>
							<li>
								<a
									onClick={this.switchOpen}
									href={"/downloads.php?id=81"}
									target="_blank"
									rel="nofollow noreferrer noopener">
									{t("navigation.Pricelist")}
								</a>
							</li>
							<li>
								<a
									onClick={this.switchOpen}
									href={"https://rma.iqrf.org"}
									target="_blank"
									rel="nofollow noreferrer noopener">
									{t("navigation.ClaimReturns")}
								</a>
							</li>
						</ul>}
					</li>
				</ul>
			</div>}
			{open && <div className="mobile-navigation__bottom-panel">
				<NavLink exact activeClassName={"active"} to={"/login"} onClick={this.switchOpen}
				>
					{App.isUserLogged() ? MasterGetter.getCurrentUser().username : t("navigation.Login")}
				</NavLink>
				<NavLink exact activeClassName={"active"} to={"/shopping-cart"}
						 onClick={this.switchOpen}
				>
					<i className="icon-shopping-cart"/>
				</NavLink>
				<NavLink exact activeClassName={"active"} to={"/search"}
						 onClick={this.switchOpen}
				>
					<i className="icon-search"/>
				</NavLink>
			</div>}
		</div>;
	}
}

/**
 *
 * @param state
 * @return {{app: *, entities: *}}
 */
const mapStateToProps = state => (
	{
		app: state.app,
		entities: state.entities,
	});

export default withRouter(connect(mapStateToProps)(withTranslation()(MobileNavigation)));