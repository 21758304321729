// @flow
import React from "react";
import {withTranslation} from "react-i18next";
import {Col, Grid, Row} from "react-flexbox-grid";
import localfrc from "../../Components/Technology/video/Local-FRC.mp4";
import {Helmet} from "react-helmet";

/**
 * @class TechnologyLocalFRCScene
 */
class TechnologyLocalFRCScene extends React.Component {


  /**
   * Final Render
   * @returns {*}
   */
  render() {

    return (
      <div className={"page"}>
      <Helmet>
      <title>Local FRC - IQRF</title>
    </Helmet>
    <Grid>
    <Row className="page__section--bottom-padding">
      <Col xs={12}>
      <h1>Local FRC</h1>
      <h2>FRC asynchronously invoked from a Node to control other Nodes in direct RF range</h2>
      <p><strong>Local FRC</strong> is an FRC <strong>asynchronously</strong> invoked <strong>from a Node</strong> to <strong>control other Nodes</strong> in <strong>direct RF range</strong>. Quickly, without lengthy propagation
    of the packet through the entire network. Thus, e.g., controllers (pushbuttons, switches, motion sensors, …)
    can control and monitor actuators (lights, blinds, …) <strong>with no significant delay</strong>.</p>
    <p>See chapter <a href="https://doc.iqrf.org/IQMESH-Network-deployment/pages/local-frc.html" rel="noreferrer noopener" target="_blank">Local FRC</a> in <a href="https://www.iqrf.org/download/2b4e3da9-f9f5-4853-87b2-4733dcd5022f">IQMESH Network deployment</a> technical guide.</p>
    </Col>
    </Row>
    </Grid>
    <Grid>
    <Row>
      <Col xs={12}>
        <div className="text--center">
          <h3 id="local-frc-animation"></h3>
          <video width="560" controls>
          <source src={localfrc}/>
          <p>Your browser does not support the video tag.</p>
    </video>
    </div>
    </Col>
    </Row>
    </Grid>
    <Grid>
    <Row>
    <Col xs={12}>
      <p>&nbsp;</p>
    </Col>
    </Row>
    </Grid>
    </div>

  );
  }

}

/**
 * Exporting part of the React.Component file
 */
export default withTranslation()(TechnologyLocalFRCScene);