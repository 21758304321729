// @flow

// Necessary Evil
import React from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import LoadingComponent from "../Components/LoadingComponent";
import BackendRequest from "../Models/REST/BackendRequest";
import FormGroup from "../Components/Form/FormGroup";
import ReCAPTCHA from "react-google-recaptcha";
import { Required } from "../Components";
import { SuccessMessage } from "../Components/Form";
import ErrorMessage from "../Components/Form/ErrorMessage/ErrorMessage";
import MyDropZone from "../Components/MyDropzone";

/**
 * @class ContactSupportForm
 */
class ContactSupportForm extends React.Component {

	/**
	 * PropTypes
	 * @type {{children: shim, videoCategories: *}}
	 */
	static propTypes = {
		afterSuccess: PropTypes.func,
		recaptcha: PropTypes.bool,
	};

	/**
	 * Default Props
	 * @type {{afterSuccess: ContactSupportForm.defaultProps.afterSuccess}}
	 */
	static defaultProps = {
		afterSuccess: () => {
		},
		recaptcha: true,
	};

	/**
	 * State
	 * @type {{formData: {}, expectingResponse: boolean, errorMessage: null}}
	 */
	state = {
		expectingResponse: false,
		success: false,
		reCaptcha: "",
		errorMessage: null,
		email: "",
		subject: "",
		message: "",
		file: null,
		filename: "",
	};

	/**
	 * Handle Input Changes
	 * @param event
	 */
	handleInputChange = (event) => {

		const target = event.target;
		const value = target.type === "checkbox" ? target.checked : target.value;
		const name = target.name;

		this.setState({
			[name]: value
		});

	};

	/**
	 * On drop
	 * @param acceptedFiles
	 */
	onDrop = (acceptedFiles) => {
		acceptedFiles.forEach(file => {
			/**
			 * Payload
			 * @type {{file: *, filename: *, section: number, type: (*|string)}}
			 */
			this.setState({
				file: file,
				filename: file.name,
			});
		});
	};

	/**
	 * Handle Submit
	 * @param e
	 */
	handleSubmit = (e) => {

		e.preventDefault();

		let fileData = {};
		this.setState({
			errorMessage: null,
		});

		/**
		 * Assign file
		 */
		if (this.state.file !== null) {
			fileData = {
				file: this.state.file,
				filename: this.state.filename,
			};
		}

		if((this.state.subject === "") ||
			(this.state.message === "") ||
			(this.state.email === "")
		){
			this.setState({
				errorMessage: "Please fill in all required fields"
			});
			return;
		}

		/**
		 *
		 * @type {{subject: string, message: string, email: string, participants: number}}
		 */
		const payload = {
			subject: this.state.subject,
			email: this.state.email,
			message: this.state.message,
			reCaptcha: this.state.reCaptcha,
			...fileData,
		};

		BackendRequest({
			endpoint: "contact-support",
			method: "post",
			payload: payload,
			self: this,
			afterSuccess: () => {
				this.setState({
					success: true,
					email: "",
					subject: "",
					message: "",
					file: null,
					filename: ""
				});
			},
		});

	};

	/**
	 * Delete Attachment
	 */
	deleteAttachment = () => {
		this.setState({
			file: null,
			filename: "",
		});
	};

	/**
	 * On captcha
	 * @param value
	 */
	onCaptcha = (value) => {

		this.setState({
			reCaptcha: value
		});

	};

	/**
	 * Final Render
	 * @returns {*}
	 */
	render () {

		/**
		 * @info Translation function, className
		 */
		const { t } = this.props;

		/**
		 * @info Error variable
		 */
		let error = "";
		let success = this.state.success ?
			<SuccessMessage>Your message has been successfully send.</SuccessMessage> : "";

		/**
		 * @info Handling Error Message
		 */
		if (this.state.errorMessage) {
			error = <ErrorMessage><i
				className="icon-cube"/> {t(this.state.errorMessage)}</ErrorMessage>;
		}

		/**
		 * Final Output
		 * @type {{}}
		 */
		return <form onSubmit={this.handleSubmit} className={"form"}>
			<FormGroup>
				<label htmlFor="email">{t("label.YourEmailAddress")} <Required/></label>
				<input
					type="email"
					onChange={this.handleInputChange}
					value={this.state.email}
					id="email"
					name="email"
				/>
			</FormGroup>
			<FormGroup>
				<label htmlFor="subject">Subject <Required/></label>
				<input
					type="text"
					onChange={this.handleInputChange}
					value={this.state.subject}
					id="subject"
					name="subject"

				/>
			</FormGroup>
			<FormGroup>
				<label htmlFor="message">{t("label.Message")} <Required/></label>
				<textarea
					onChange={this.handleInputChange}
					value={this.state.message}
					id="message"
					name="message"
					placeholder=""
				/>
			</FormGroup>
			<FormGroup>
				<label htmlFor="message">Attach a file</label><br/>
				<MyDropZone
					onDrop={this.onDrop}
					accept={"image/*,.pdf,.doc,.docx,.xsl,.xlsx,.zip"}
				/>
				{this.state.file !== null && <div>
					<label>Attachment</label>
					<div className="email-attachment" onClick={this.deleteAttachment}><i
						className="icon-close"/> {this.state.filename}</div>
				</div>}
			</FormGroup>
			{this.props.recaptcha &&
			<FormGroup style={{ display: "flex", justifyContent: "flex-start" }}>
				<ReCAPTCHA
					theme={"light"}
					size={"normal"}
					tabindex={3}
					sitekey={process.env.REACT_APP_GOOGLE_SITE_KEY}
					onChange={this.onCaptcha}
				/>
			</FormGroup>}
			<FormGroup className="text--center">
				{error}
				{(this.state.expectingResponse) ? <LoadingComponent/> :
					this.state.success ? success :
						<button type="submit" tabIndex={2} className="btn">{t("button.Submit")}</button>}
				{this.state.success && <div onClick={() => {
					this.setState({ success: false });
				}} className={"link"}><br/>Sent successfully</div>}
			</FormGroup>
		</form>;
	}
}

export default withTranslation()(ContactSupportForm);