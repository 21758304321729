// @flow

import PropTypes from "prop-types";
import {attr, fk} from "redux-orm";

import CRUDModel from "../ORM/CRUDModel";

/**
 *
 * @class File
 */
export default class File extends CRUDModel {
}

File.modelName = "File";

File.fields = {
    id: attr(), // non-relational field for any value; optional but highly recommended
    section: fk("DownloadSection"),
    type: fk("FileType"),
    type__icon: attr(),
    section__id: attr(),
};

File.propTypes = {
    name: PropTypes.string.isRequired,
};

export const ADD_FILE = "ADD_FILE";
export const REMOVE_FILE = "REMOVE_FILE";
export const UPDATE_FILE = "UPDATE_FILE";