// @flow
import React from "react";
import {withTranslation} from "react-i18next";
import {Col, Grid, Row} from "react-flexbox-grid";
import broadcasting0 from "../../Components/Technology/video/Broadcast.mp4";
import broadcasting2 from "../../Components/Technology/video/Broadcast-2-broken-links.mp4";
import broadcasting5 from "../../Components/Technology/video/Broadcast-5-broken-links.mp4";
import {Helmet} from "react-helmet";

/**
 * @class TechnologyBroadcastScene
 */
class TechnologyBroadcastScene extends React.Component {


  /**
   * Final Render
   * @returns {*}
   */
  render() {

    return (
      <div className={"page"}>
      <Helmet>
      <title>Broadcast - IQRF</title>
      </Helmet>
      <Grid>
      <Row className="page__section--bottom-padding">
      <Col xs={12}>
      <h1>Broadcast</h1>
      <h2>Packet sent to all Nodes, without responses</h2>
      </Col>
      <Col xs={12}>
      <p><strong>Command</strong> sent from the Coordinator <strong>to all Nodes</strong>, with <strong>no response</strong> back:</p>
    <div className="text--center">
      <h3 id="broadcast-animation"></h3>
      <video width="560" controls>
    <source src={broadcasting0}/>
    <p>Your browser does not support the video tag.</p>
      </video>
      </div>
      </Col>
      </Row>
      <Row>
      <Col xs={12}>
      <p>Avoiding possible obstacles:</p>
    </Col>
    <Col xs={6}>
      <div className="text--center">
      <h3 id="broadcast-animation"></h3>
      <video width="460" controls>
    <source src={broadcasting2}/>
    <p>Your browser does not support the video tag.</p>
    </video>
    </div>
    </Col>
    <Col xs={6}>
      <div className="text--center">
      <h3 id="broadcasting5"></h3>
      <video width="460" controls>
    <source src={broadcasting5}/>
    <p>Your browser does not support the video tag.</p>
    </video>
    </div>
    </Col>
    </Row>
    </Grid>
    <Grid>
    <Row>
    <Col xs={12}>
      <p>&nbsp;</p>
    </Col>
    </Row>
    </Grid>
    </div>

  );
  }

}

/**
 * Exporting part of the React.Component file
 */
export default withTranslation()(TechnologyBroadcastScene);