// @flow
import React from "react";
import {withTranslation} from "react-i18next";
import {Col, Grid, Row} from "react-flexbox-grid";
import "react-image-lightbox/style.css";
import {Helmet} from "react-helmet";

/**
 * @class TechnologyOSMigrationNotesScene
 */
class TechnologyOSMigrationNotesScene extends React.Component {

	/**
	 * Constructor
	 * @param props
	 */
	constructor(props) {
		super(props);

		this.state = {
			isOpen: false,
			showContent: false,
		};
	}

	/**
	 * Final Render
	 * @returns {*}
	 */
	render() {

		return (
			<div className={"page"}>
				<Helmet>
					<title>OS migration notes - IQRF</title>
				</Helmet>
				<Grid className="page__section--bottom-padding">
					<Row>
						<Col xs={12}>
							<h1>OS migration notes</h1>
							<h2 id="4-06-d-4-06g">Migration from OS v4.06D to v4.06G</h2>
							<table className="table--os-history">
								<tbody>
									<tr>
										<td colSpan="2">Features</td>
										<td>OS v4.06D</td>
										<td>OS v4.06G</td>
									</tr>
									<tr>
										<td colSpan="2">Applicable for TR series</td>
										<td>TR-7xD</td>
										<td>TR-7xG</td>
									</tr>
									<tr>
										<td colSpan="2">Brown-out reset (BOR) behavior</td>
										<td>SW control in the application is possible by macros setBORon() and setBORoff().</td>
										<td>SW control in the application is not possible.</td>
									</tr>
									<tr>
										<td colSpan="2">MCU Flash and RAM memories</td>
										<td>&nbsp;</td>
										<td>Extended</td>
									</tr>
									<tr>
										<td colSpan="2">User interrupt routine starting address</td>
										<td>0x3F00</td>
										<td>0x4F00</td>
									</tr>
									<tr>
										<td colSpan="2">Using MCU pin RC5 as I/O when SPI is enabled</td>
										<td>Possible</td>
										<td>Not possible</td>
									</tr>
									<tr>
										<td colSpan="2">PPS (Peripheral Pin Select)</td>
										<td>Not implemented in MCU</td>
										<td>Implemented in MCU.</td>
									</tr>
									<tr>
										<td colSpan="2">PMD (Peripheral Module Disable)</td>
										<td>Not implemented in MCU</td>
										<td>Implemented in MCU. Individual MCU peripherals must be enabled before use in the application and should be disabled when not used.</td>
									</tr>
								</tbody>
							</table>
							<br/>
							<h2 id="4-06-d-7xd">Migration from OS v4.05D to v4.06D</h2>
							<table className="table--os-history">
								<tbody>
									<tr>
										<td colSpan="2">Features</td>
										<td>OS v4.05D</td>
										<td>OS v4.06D</td>
									</tr>
									<tr>
										<td colSpan="2">Brown-out reset (BOR) SW control</td>
										<td>BOR is default disabled. </td>
										<td>BOR is default disabled in Sleep/Deep sleep and enabled otherwise.</td>
									</tr>
									<tr>
										<td colSpan="2">Immunity to problematic power-up rising during TR start-up</td>
										<td>&nbsp;</td>
										<td>Slightly increased.</td>
									</tr>
								</tbody>
							</table>
							<br/>
							<h2 id="4-05-d-7xd">Migration from OS v4.04D to v4.05D </h2>
							<table className="table--os-history">
								<tbody>
									<tr>
										<td colSpan="2">Features</td>
										<td>OS v4.04D</td>
										<td>OS v4.05D</td>
									</tr>
									<tr>
										<td colSpan="2">FRC data collection</td>
										<td>&nbsp;</td>
										<td>Better support for Aggregation </td>
									</tr>
									<tr>
										<td colSpan="2">Bonding using IQuip</td>
										<td>Not supported</td>
										<td>Supported </td>
									</tr>
								</tbody>
							</table>
							<br/>
							<h2 id="4-04-d-7xd">Migration from OS v4.03D to v4.04D </h2>
							<table className="table--os-history">
								<tbody>
									<tr>
										<td colSpan="2">Features</td>
										<td>OS v4.03D</td>
										<td>OS v4.04D</td>
									</tr>
									<tr>
										<td colSpan="2">Local FRC</td>
										<td>Not supported</td>
										<td>Supported </td>
									</tr>
									<tr>
										<td colSpan="2">Beaming sensors and Aggregating repeaters</td>
										<td>Not supported</td>
										<td>Supported </td>
									</tr>
									<tr>
										<td colSpan="2">FRC duration</td>
										<td/>
										<td>Slightly faster</td>
									</tr>
									<tr>
										<td colSpan="2">removeBondedNode() also undiscovers the Node</td>
										<td>No</td>
										<td>Yes</td>
									</tr>
								</tbody>
							</table>
							<br/>
							<h2 id="4-03d-7xd">Migration from OS v4.02D to OS v4.03D for TR-7xD</h2>
							<table className="table--os-history">
								<tbody>
								<tr>
									<td colSpan="2">Features</td>
									<td>OS v4.02D</td>
									<td>OS v4.03D</td>
								</tr>
								<tr>
									<td colSpan="2">Direct LED switching on ( _LEDR = 1, _LEDG = 1)</td>
									<td>Available</td>
									<td>Possible but unnecessary. Use LED control OS functions, e.g. setLEDR() and setLEDG() instead.</td>
								</tr>
								<tr>
									<td colSpan="2">eeeReadData() and eeeWriteData() default block length</td>
									<td>16 B</td>
									<td>64 B</td>
								</tr>
								<tr>
									<td colSpan="2">eeeWriteData() 64 B blocks boundary crossing</td>
									<td>Not allowed</td>
									<td>Allowed</td>
								</tr>
								<tr>
									<td colSpan="2">Service RF channels</td>
									<td>Not available</td>
									<td>Fixed 3 dedicated channels are used for bonding and Smart connect.</td>
								</tr>

								<tr>
									<td colSpan="2">RF channels for RFPGM and DPA DSM</td>
									<td>The same as the operational channels</td>
									<td>Shifted by 50 kHz above the operational channels</td>
								</tr>
								<tr>
									<td colSpan="2">Operating RF channel of the Node to be bonded to the network</td>
									<td>Must be specified in tr configuration of given Node.</td>
									<td>Need not be specified. Inherited from the Coordinator. Stored to Node's Configuration during
										bonding.
									</td>
								</tr>
								<tr>
									<td colSpan="2">Standard FRC</td>
									<td>Available</td>
									<td>Not available. Use Advanced FRC.</td>
								</tr>
								<tr>
									<td colSpan="2">4 B FRC</td>
									<td>Not available</td>
									<td>Available</td>
								</tr>
								<tr>
									<td colSpan="2">Location of User key for User encryption/decryption</td>
									<td>Specified by setUserKey()</td>
									<td>Specified by setUserKey() or placed in bufferINFO</td>
								</tr>
								<tr>
									<td colSpan="2">Variables for bidirectional user data exchange during (pre)bonding</td>
									<td>userBondingData[4]</td>
									<td>
										<span>
											hostUserDataToSend[4]<br/>
											<strong>nodeUserDataToSend[4]</strong><br/>
											<strong>hostUserDataReceived[4]</strong><br/>
											<strong>nodeUserDataReceived[4]</strong>
										</span>
									</td>
								</tr>
								<tr>
									<td colSpan="2">IBK and Smart connect</td>
									<td>Not implemented</td>
									<td>Implemented</td>
								</tr>
								<tr>
									<td colSpan="2">IBK available via moduleInfo()</td>
									<td>No</td>
									<td>Optionally</td>
								</tr>
								</tbody>
							</table>
							<br/>
							<h2 id="4-02d-7xd">Migration from OS v4.00D to OS v4.02D for TR-7xD</h2>
							<table className="table--os-history">
								<tbody>
								<tr>
									<td colSpan="2">Features</td>
									<td>OS v4.00D</td>
									<td>OS v4.02D</td>
								</tr>
								<tr>
									<td colSpan="2">tr and DCtr transceiver types differentiation</td>
									<td>Differentiated</td>
									<td>Not differentiated. Even a transceiver named tr features both OS and DPA (data controlled)
										approaches.
									</td>
								</tr>
								<tr>
									<td colSpan="2">getTemperature() return value</td>
									<td>uns8</td>
									<td>int8</td>
								</tr>
								<tr>
									<td colSpan="2">eeReadData() return value</td>
									<td>Not documented</td>
									<td>Documented</td>
								</tr>
								<tr>
									<td colSpan="2">tr upload (see IQRF SPI Technical guide)</td>
									<td>&nbsp;</td>
									<td>Extended possibilities</td>
								</tr>
								<tr>
									<td colSpan="2">Immunity against possible failure of RF IC</td>
									<td>&nbsp;</td>
									<td>Extended</td>
								</tr>
								<tr>
									<td colSpan="2">Bug in 2B FRC individual packets processing for not discovered Nodes in range with the
										Coordinator only
									</td>
									<td>Yes</td>
									<td>Fixed</td>
								</tr>
								</tbody>
							</table>
							<br/>
							<h2 id="4-01d-7xd">Migration to OS v4.01D for TR-7xD</h2>
							<p>Do not use OS v4.01D at all due to a serious bug in RFPGM (wireless upload).</p>
							<h2 id="4-00d-7xd">Migration from OS v3.08D to OS v4.00D for TR-7xD</h2>

							<table className="table--os-history">
								<tbody>
								<tr>
									<td colSpan="2">Features</td>
									<td>OS v3.08D</td>
									<td>OS v4.00D</td>
								</tr>
								<tr>
									<td colSpan="2">RF packet interoperability <strong>between OS v3.xxD and v4.xxD</strong></td>
									<td colSpan="2">Not interoperable</td>
								</tr>
								<tr>
									<td colSpan="2">Networking with more than 240 devices</td>
									<td>Supported</td>
									<td>Not supported</td>
								</tr>
								<tr>
									<td colSpan="2">IQMESH implementation</td>
									<td>DPA or non-DPA</td>
									<td>DPA only</td>
								</tr>
								<tr>
									<td colSpan="2">setUserAddress()</td>
									<td>Implemented</td>
									<td>Not implemented</td>
								</tr>
								<tr>
									<td colSpan="2">setrFband()</td>
									<td>Implemented</td>
									<td>Not implemented</td>
								</tr>
								<tr>
									<td colSpan="2">Networking RF packet propagation time</td>
									<td>&nbsp;</td>
									<td>Prolonged due to encryption</td>
								</tr>
								<tr>
									<td colSpan="2">RF IC restart after RF failure detection</td>
									<td>Not available</td>
									<td>wasRFICrestarted()</td>
								</tr>
								<tr>
									<td colSpan="2">deepSleep()</td>
									<td>Not implemented</td>
									<td>Implemented</td>
								</tr>
								<tr>
									<td colSpan="2">getINDF0() and getINDF1()</td>
									<td>Obsolete</td>
									<td>Not implemented</td>
								</tr>
								<tr>
									<td colSpan="2">clearBufferINFO() and clearBufferRF()</td>
									<td>Complete buffer is cleared</td>
									<td>Can be limited by memoryLimit</td>
								</tr>
								<tr>
									<td colSpan="2">swapBufferINFO()</td>
									<td>Complete buffer is swapped</td>
									<td>Can be limited by memoryLimit</td>
								</tr>
								<tr>
									<td colSpan="2">eeReadData() return value</td>
									<td>None (void)</td>
									<td>Bit (at least one zero read)</td>
								</tr>
								<tr>
									<td colSpan="2">eeReadData() and eeWriteData() data length</td>
									<td>1 B to 32 B, default 32 B</td>
									<td>1 B to 64 B, default 32 B</td>
								</tr>

								<tr>
									<td colSpan="2">Using memoryOffsetTo and memoryOffsetFrom <strong>with eeReadData() and eeWriteData()</strong></td>
									<td>Not available</td>
									<td>Available</td>
								</tr>

								<tr>
									<td rowSpan="2">Serial EEPROM mapping</td>
									<td>Dedicated to OS</td>
									<td>0x0000 – 0x07FF, default <strong>cleared, not write protected</strong></td>
									<td>0x4000 – 0x7FFF, default not cleared, <strong>write protected</strong></td>
								</tr>
								<tr>
									<td>User available</td>
									<td>0x0800 – 0x7FFF</td>
									<td>0x0000 – 0x3FFF</td>
								</tr>

								<tr>
									<td colSpan="2">Application info default setting</td>
									<td>“Hello everybody. IQRF is here!”</td>
									<td>Cleared</td>
								</tr>

								<tr>
									<td colSpan="2">writeToRAM() and appInfo() implementations</td>
									<td>OS functions</td>
									<td>Macros</td>
								</tr>

								<tr>
									<td colSpan="2">Network variables for DPA renamed</td>
									<td>3 ms</td>
									<td>Selectable 4 ms or 8 ms</td>
								</tr>

								<tr>
									<td colSpan="2">Preamble length in Std TX</td>
									<td>MPRW0, MPRW1, MPRW2</td>
									<td>PNUM, PCMD, PPAR</td>
								</tr>

								<tr>
									<td colSpan="2">Preamble quality check for checkRF(x)</td>
									<td>Not available</td>
									<td>Available</td>
								</tr>

								<tr>
									<td colSpan="2">Alternative names of routing variables</td>
									<td>RtdT0-3 also allowed</td>
									<td>RTHOPS, RTSLOT, RtdID, RTAUX only</td>
								</tr>

								<tr>
									<td colSpan="2">Alternative names for RTAUX (formerly RtdO3)</td>
									<td>RtdT3, RTV3, RX2BH, OTX2BH</td>
									<td>RTAUX only</td>
								</tr>

								<tr>
									<td colSpan="2">Number of Nodes addressed by FRC (bytes collected)</td>
									<td>62 (1 B mode), 30 (2 B mode)</td>
									<td>63 (1 B mode), 31 (2 B mode)</td>
								</tr>

								<tr>
									<td colSpan="2">bondRequest()</td>
									<td>Obsolete</td>
									<td>Not implemented</td>
								</tr>

								<tr>
									<td colSpan="2">FRC duration</td>
									<td>&nbsp;</td>
									<td>Slightly longer</td>
								</tr>

								<tr>
									<td colSpan="2">Possible vacations within bonding addresses during <strong>bondNewNode(0) and
										nodeAuthorization(x,0)</strong></td>
									<td>Should be avoided</td>
									<td>Allowed, the lowest free address is used (unless otherwise requested)</td>
								</tr>

								<tr>
									<td colSpan="2">Parameter 0xFF at nodeAuthorization(x)</td>
									<td>Not available</td>
									<td>Available</td>
								</tr>
								<tr>
									<td colSpan="2">Node prebonding functions <strong>(see IQRF OS Reference guides for differences)</strong></td>
									<td>prebondNode()</td>
									<td>prebondNodeAtNode(), <strong>prebondNodeAtCoordinator()</strong></td>
								</tr>
								<tr>
									<td colSpan="2">Flag _prebondNode for Node prebonding and authorization</td>
									<td>Must be handled by the user</td>
									<td>Not used</td>
								</tr>
								<tr>
									<td colSpan="2">User data exchanged during prebonding</td>
									<td>2 B, via special variables</td>
									<td>4 B, via bufferRF</td>
								</tr>
								<tr>
									<td colSpan="2">
										Bonding variables DataInPrebondNode, <strong>DataOutPrebondNode</strong>, <strong>DataInBondRequestAdvanced</strong>, <strong>DataOutBondRequestAdvanced</strong>
									</td>
									<td>Used</td>
									<td>Replace by universal variable UserBondingData</td>
								</tr>
								<tr>
									<td colSpan="2">MID passed by nodeAuthorization()</td>
									<td>Lower 2 B, via MIDoutBondRequest</td>
									<td>Complete 4 B, via BondingNodeMID</td>
								</tr>
								<tr>
									<td colSpan="2">Waiting for nodeAuthorization finishing</td>
									<td>No waiting (non-blocking, <strong>running in OS background)</strong></td>
									<td>Waiting (blocking, running in OS foreground). No user delay is needed.</td>
								</tr>
								<tr>
									<td rowSpan="2">Networking packets allowed for prebonded Nodes</td>
									<td>RX</td>
									<td>Allowed</td>
									<td>Node authorization system packets only</td>
								</tr>
								<tr>
									<td>TX</td>
									<td>Allowed</td>
									<td>Not allowed</td>
								</tr>
								<tr>
									<td colSpan="2">amIRecipientOfFRC() return value for non-selective FRC</td>
									<td>Always true</td>
									<td>true only for Nodes with addresses within given range</td>
								</tr>

								<tr>
									<td colSpan="2">RF communication security / encryption</td>
									<td>Proprietary coding</td>
									<td>AES-128 (User, Networking and Access)</td>
								</tr>

								<tr>
									<td colSpan="2">trs to be operated in Israel (IL version)</td>
									<td>Not available</td>
									<td>Available</td>
								</tr>

								<tr>
									<td colSpan="2">Basic IQRF header file</td>
									<td>template-basic.h</td>
									<td>Renamed to IQRF.h</td>
								</tr>
								</tbody>
							</table>
							<br/>
							<h2 id="3-09d-7xd">Migration from OS v3.08D to OS v3.09D for TR-7xD</h2>
							<p>It is meaningless to migrate from OS v3.08D to v3.09D unless the application should be operated in
								Israel.</p>

							<table className="table--os-history">
								<tbody>
								<tr>
									<th>Features</th>
									<th>OS v3.08D</th>
									<th>OS v3.09D</th>
								</tr>
								<tr>
									<td>TR-7xDx-IL (intended for Israel, with RF band/channel limitation. <strong>See IQRF OS User's guide,
										Appendix 2, 916 MHz channel map.)</strong></td>
									<td>Not supported</td>
									<td>Supported</td>
								</tr>
								<tr>
									<td>Downgrade to lower OS versions</td>
									<td>Possible to v3.07D or v3.06D</td>
									<td>Restricted</td>
								</tr>
								</tbody>
							</table>
							<br/>
							<h2 id="3-08d-7xd">Migration from OS v3.07D to OS v3.08D for TR-7xD</h2>

							<table className="table--os-history">
								<tbody>
								<tr>
									<th>Features</th>
									<th>OS v3.07D</th>
									<th>OS v3.08D</th>
								</tr>
								<tr>
									<td>916 MHz and 433 MHz bands</td>
									<td>Not supported</td>
									<td>Supported</td>
								</tr>
								<tr>
									<td>Number of RF channels for 433, 868 and 916 MHz band</td>
									<td>16, 62 resp. 189 (at TR-5xD)</td>
									<td>17, 68 resp. 255 (at TR-7xD)</td>
								</tr>
								<tr>
									<td>isBondedNode() return value for Nodes from 0xF0 to 0xFF</td>
									<td>Not defined</td>
									<td>Defined</td>
								</tr>
								<tr>
									<td>Overflow bug in sending 2B selective FRC</td>
									<td>May occur in very special cases</td>
									<td>Fixed</td>
								</tr>
								<tr>
									<td>optimizeHops() output value for not discoveded Node</td>
									<td>Number of bonded Nodes</td>
									<td>Number of discovered Nodes</td>
								</tr>
								<tr>
									<td>setrFmode() WDT side effect</td>
									<td>Yes</td>
									<td>Fixed</td>
								</tr>
								</tbody>
							</table>

							<br/>
							<h2 id="3-08d-5xd">Migration from OS v3.07D to OS v3.08D for TR-5xD</h2>
							<table className="table--os-history">
								<tbody>
								<tr>
									<th>Features</th>
									<th>OS v3.07D</th>
									<th>OS v3.08D</th>
								</tr>
								<tr>
									<td>isBondedNode() return value for Nodes from 0xF0 to 0xFF</td>
									<td>Not defined</td>
									<td>Defined</td>
								</tr>
								<tr>
									<td>Overflow bug in sending 2B selective FRC</td>
									<td>May occur in very special cases</td>
									<td>Fixed</td>
								</tr>
								<tr>
									<td>optimizeHops() output value for not discoveded Node</td>
									<td>Number of bonded Nodes</td>
									<td>Number of discovered Nodes</td>
								</tr>
								</tbody>
							</table>
							<br/>
							<h2 id="3-07d-7xd">Migration from TR-5xD OS v3.06D to TR-7xD OS v3.07D</h2>

							<table className="table--os-history">
								<tbody>
								<tr>
									<th colSpan="2">Features</th>
									<th>TR-5xD with OS v3.06D</th>
									<th>OS v3.07D</th>
								</tr>
								<tr>
									<td colSpan="2">MCU clock</td>
									<td>8 MHz</td>
									<td>16 MHz</td>
								</tr>
								<tr>
									<td colSpan="2">SPI</td>
									<td/>
									<td>Faster. See Tech_Guide_SPI_TR-7xD.</td>
								</tr>
								<tr>
									<td colSpan="2">calistrongateTimer function</td>
									<td>Obsolete</td>
									<td>Not implemented</td>
								</tr>
								<tr>
									<td colSpan="2">916 MHz band</td>
									<td>Implemented</td>
									<td>Not yet implemented</td>
								</tr>
								<tr>
									<td colSpan="2">RF bit rate</td>
									<td>19.2 kb/s</td>
									<td>19.836 kb/s. Other bit rates coming later.</td>
								</tr>
								<tr>
									<td colSpan="2">LP modes</td>
									<td> Implemented</td>
									<td>Improved, power consumption lowered</td>
								</tr>
								<tr>
									<td colSpan="2">XLP modes</td>
									<td>Implemented</td>
									<td>Implemented for not routing networking only</td>
								</tr>
								<tr>
									<td rowSpan="3">checkRF</td>
									<td>Internal offset</td>
									<td>32</td>
									<td>36</td>
								</tr>
								<tr>
									<td>Values related to</td>
									<td>Relative range</td>
									<td>dBm</td>
								</tr>
								<tr>
									<td>Recommended level for noise-free environment</td>
									<td>5</td>
									<td>0</td>
								</tr>
								<tr>
									<td colSpan="2">getrSSI</td>
									<td>Measures current value. <strong>Takes 66 &micro;s.</strong></td>
									<td>Just reads the last value from RF IC stored there after checkRF or RFRXpacket. <strong>Value relates to
										dBm. Takes a few &micro;s.</strong></td>
								</tr>
								<tr>
									<td colSpan="2">RFIM receive mode</td>
									<td>Implemented</td>
									<td>Not implemented</td>
								</tr>
								<tr>
									<td colSpan="2">Incoming signal in LP and XLP filtering</td>
									<td>Selectable in setrFmode(m)</td>
									<td>Derived automatically from last checkRF(x)</td>
								</tr>
								<tr>
									<td colSpan="2">Macro setrFRXchain</td>
									<td>Supported</td>
									<td>Not supported</td>
								</tr>
								<tr>
									<td colSpan="2">Stay in RX mode</td>
									<td>Implemented</td>
									<td>Not implemented. checkRF, RFRXpacket and RFTXpacket always return to RF ready mode</td>
								</tr>
								<tr>
									<td colSpan="2">RF CRC checking</td>
									<td>Proprietary</td>
									<td>CRC16 IBM</td>
								</tr>
								<tr>
									<td rowSpan="5">FRC</td>
									<td>Selective FRC</td>
									<td>Not supported</td>
									<td>Supported</td>
								</tr>
								<tr>
									<td>2 B FRC</td>
									<td>Not supported</td>
									<td>Supported</td>
								</tr>
								<tr>
									<td>_wasFRC flag</td>
									<td>Not implemented</td>
									<td>Implemented</td>
								</tr>
								<tr>
									<td>amIRecipientOfFRC()</td>
									<td>Not implemented</td>
									<td>Implemented</td>
								</tr>
								<tr>
									<td>Max. delay before responseFRC calling</td>
									<td>40 ms</td>
									<td>Selectable by extraProcessingTicksFRC</td>
								</tr>
								<tr>
									<td rowSpan="5">getTemperature</td>
									<td>Temperature sensor</td>
									<td>TMP112</td>
									<td>MCP9808</td>
								</tr>
								<tr>
									<td>Resolution</td>
									<td>12 b (0.0625 °C)</td>
									<td>9 b resolution (0.5 °C)</td>
								</tr>
								<tr>
									<td>Return value 0x80</td>
									<td>If temperature sensor is not assembled</td>
									<td>If error in communication with temperature sensor is detected</td>
								</tr>
								<tr>
									<td>Delay required after wake up from sleep and in (X)LP RX modes</td>
									<td>100 ms</td>
									<td>300 ms</td>
								</tr>
								<tr>
									<td>Duration</td>
									<td>~ 1.5 ms</td>
									<td>~ 2 ms</td>
								</tr>
								<tr>
									<td rowSpan="7">Serial EEPROM</td>
									<td>Coordinator EEPROM data consistency</td>
									<td>Not checked</td>
									<td>Checked by several OS functions</td>
								</tr>
								<tr>
									<td>Memory size</td>
									<td>2 KB (0 to 0x7FF)</td>
									<td>32 KB (0 to 0x7FFF)</td>
								</tr>
								<tr>
									<td>Read block</td>
									<td>16 B (fixed) <strong>Starting address divisible by 16</strong></td>
									<td>1 to 64 B (selectable) <strong>Any starting address</strong></td>
								</tr>
								<tr>
									<td>Write block</td>
									<td>16 B (fixed) <strong>Starting address divisible by 16</strong></td>
									<td>1 to 64 B (selectable) <strong>Any starting address. Not crossing 64 B block.</strong></td>
								</tr>
								<tr>
									<td>eeeReadData and eeeWriteData return values</td>
									<td>Void</td>
									<td>Bit – successful access indication</td>
								</tr>
								<tr>
									<td>_eeeError flag</td>
									<td>Not implemented</td>
									<td>Set by several OS functions</td>
								</tr>
								<tr>
									<td>Access error check</td>
									<td>Not implemented</td>
									<td>Performed by several OS functions, return values added</td>
								</tr>

								<tr>
									<td colSpan="2">RFPGM versions</td>
									<td>Lite and Standard</td>
									<td>Standard, using IQRF IDE CATS tool only</td>
								</tr>
								<tr>
									<td colSpan="2">OS upgrade / downgrade at the user</td>
									<td>–</td>
									<td>Available</td>
								</tr>
								</tbody>
							</table>
							<br/>
							<h2 id="3-08d-0852-5xd">Migration from OS v3.07D (build 0850) to OS v3.07D (build 0852) for TR-5xD</h2>
							<table className="table--os-history">
								<tbody>
								<tr>
									<th>Features</th>
									<th>TR-5xD with OS v3.06D</th>
									<th>OS v3.07D</th>
								</tr>
								<tr>
									<td>Bug in RFPGM termination by low level on dedicated pin in case of strong RF noise</td>
									<td>Yes</td>
									<td>Fixed</td>
								</tr>
								</tbody>
							</table>
							<br/>
							<h2 id="3-07d-5xd">Migration from OS v3.06D to v3.07D (build 0850) for TR-5xD</h2>
							<table className="table--os-history">
								<tbody>
								<tr>
									<th colSpan="2">Features</th>
									<th>OS v3.06D</th>
									<th>OS v3.07D</th>
								</tr>
								<tr>
									<td colSpan="2">RFIM receive mode</td>
									<td>Implemented</td>
									<td>Not implemented</td>
								</tr>
								<tr>
									<td colSpan="2">Incoming signal in LP and XLP filtering</td>
									<td>Selectable in setrFmode(m)</td>
									<td>Derived automatically from last checkRF(x)</td>
								</tr>
								<tr>
									<td colSpan="2">RF CRC checking</td>
									<td>Proprietary</td>
									<td>CRC16 IBM</td>
								</tr>
								<tr>
									<td rowSpan="5">FRC</td>
									<td>Selective FRC</td>
									<td>Not supported</td>
									<td>Supported</td>
								</tr>
								<tr>
									<td>2 B FRC</td>
									<td>Not supported</td>
									<td>Supported</td>
								</tr>
								<tr>
									<td>_wasFRC flag</td>
									<td>Not implemented</td>
									<td>Implemented</td>
								</tr>
								<tr>
									<td>amIRecipientOfFRC()</td>
									<td>Not implemented</td>
									<td>Implemented</td>
								</tr>
								<tr>
									<td>Max. delay before responseFRC calling</td>
									<td>40 ms</td>
									<td>Selectable by extraProcessingTicksFRC</td>
								</tr>
								<tr>
									<td colSpan="2">getTemperature return value 0x80</td>
									<td>If temperature sensor is not assembled</td>
									<td>If error in communication with temperature sensor is detected</td>
								</tr>
								<tr>
									<td rowSpan="5">Serial EEPROM</td>
									<td>Coordinator EEPROM data consistency</td>
									<td>Not checked</td>
									<td>Checked by several OS functions</td>
								</tr>
								<tr>
									<td>Read block</td>
									<td>16 B (fixed) <strong>Starting address divisible by 16</strong></td>
									<td>1 to 64 B (selectable) <strong>Any starting address.</strong></td>
								</tr>
								<tr>
									<td>eeeReadData and eeeWriteData return values</td>
									<td>Void</td>
									<td>Bit – successful access indication</td>
								</tr>
								<tr>
									<td>_eeeError flag</td>
									<td>Not implemented</td>
									<td>Set by several OS functions</td>
								</tr>
								<tr>
									<td>Access error check</td>
									<td>Not implemented</td>
									<td>Performed by several OS functions, return values added</td>
								</tr>
								<tr>
									<td colSpan="2">RFPGM versions</td>
									<td>Lite and Standard</td>
									<td>Standard, using IQRF IDE CATS tool only</td>
								</tr>
								<tr>
									<td colSpan="2">OS upgrade / downgrade at the user</td>
									<td colSpan="2" align="center">
										<span>
											For v3.06D and higher versions only<br/>
											<strong>For other OS versions at thefactory only</strong>
										</span>
									</td>
								</tr>
								</tbody>
							</table>
							<br/>
							<h2 id="3-06d">Migration from OS v3.05D to v3.06D</h2>

							<table className="table--os-history">
								<tbody>
								<tr>
									<th>Features</th>
									<th>OS v3.05D</th>
									<th>OS v3.06D</th>
								</tr>
								<tr>
									<td>Silent mode in RFPGM configuration</td>
									<td>Not supported</td>
									<td>Supported</td>
								</tr>
								<tr>
									<td>userStatus after power-on reset</td>
									<td>Not cleared</td>
									<td>Cleared</td>
								</tr>
								<tr>
									<td>Flag -POR (bit PCON.1) after OS boot</td>
									<td>Unchanged</td>
									<td>Set to log. 1</td>
								</tr>
								<tr>
									<td>Flags IOCBN and IOCBF within iqrfSleep()</td>
									<td>Cleared by OS</td>
									<td>Unchanged (not cleared by OS)</td>
								</tr>
								<tr>
									<td>bufferCOM side effect after sendFRC(x)</td>
									<td>bufferCOM destroyed</td>
									<td>bufferCOM unchanged</td>
								</tr>
								<tr>
									<td>SWDTEN = 0 precondition for answerSystemPacket()</td>
									<td>Must be accomplished by the user</td>
									<td>Accomplished by OS</td>
								</tr>

								<tr>
									<td>RFmode, SWDTEN and toutrF sideffects in anwerSystemPacket(), discovery(x), bondNewNode(x),
										prebondNode(), bondRequestAdvanced(), bondRequest(), sendFRC(x) and responseFRC()
									</td>
									<td>See Side effects and Preconditions for these functions in IQRF OS Reference guide</td>
									<td>RFmode, SWDTEN and toutrF unchanged after calling these functions</td>
								</tr>

								<tr>
									<td>Coordinator mode selecting before sendFRC(x)</td>
									<td>Must be accomplished by the user</td>
									<td>Accomplished by OS</td>
								</tr>
								<tr>
									<td>Background LED activity disabling before RFRXpacket() in LP or XLP or before iqrfSleep()</td>
									<td>Must be disabled in application</td>
									<td>Disabled by OS</td>
								</tr>
								<tr>
									<td>RFPGM LP mode</td>
									<td>Not supported</td>
									<td>Supported</td>
								</tr>
								<tr>
									<td>runRFPGM() preconditions _enableUserInterrupt = 0; setNonetMode(); setNetworkFilteringOff();</td>
									<td>Must be called in application</td>
									<td>Included in runRFPGM() function</td>
								</tr>
								<tr>
									<td>DFM2B routing</td>
									<td>Tested</td>
									<td>Not tested</td>
								</tr>
								<tr>
									<td>Forced LP routing</td>
									<td>Not supported</td>
									<td>Supported</td>
								</tr>
								<tr>
									<td>XLP No wait option</td>
									<td>Not supported</td>
									<td>Supported</td>
								</tr>
								<tr>
									<td>Advanced FRC</td>
									<td>Not supported</td>
									<td>Supported</td>
								</tr>
								<tr>
									<td>DataOutBeforeResponseFRC and DataInSendFRC size and location</td>
									<td>2 B, in dedicated variables</td>
									<td>2 B or 30 B, in bufferRF</td>
								</tr>
								<tr>
									<td>Bonding in LP and XLP modes</td>
									<td>Not supported</td>
									<td>Supported</td>
								</tr>
								<tr>
									<td>Discovery and FRC in LP mode</td>
									<td>Not supported</td>
									<td>Supported</td>
								</tr>
								<tr>
									<td>OptimizeHops(x) for not discovered Nodes</td>
									<td>Ignoring of not discovered Nodes must be arranged in application</td>
									<td>Ignoring of not discovered Nodes is arranged by OS</td>
								</tr>
								<tr>
									<td>Interrupt on pin change (via flag IOCBF)</td>
									<td>Not enabled</td>
									<td>Enabled</td>
								</tr>
								<tr>
									<td>Serial EEPROM upload from IQRF IDE</td>
									<td>Not supported</td>
									<td>Supported</td>
								</tr>
								<tr>
									<td>Serial EEPROM default values</td>
									<td>Undefined</td>
									<td>Cleared (filled with 0x00)</td>
								</tr>
								<tr>
									<td>User area in MCU EEPROM default values</td>
									<td>Undefined</td>
									<td>Cleared (filled with 0x00)</td>
								</tr>
								</tbody>
							</table>
							<br/>
							<h2 id="3-05d">Migration from OS v3.04D to v3.05D</h2>

							<table className="table--os-history">
								<tbody>
								<tr>
									<th>Features</th>
									<th>OS v3.04D</th>
									<th>OS v3.05D</th>
								</tr>
								<tr>
									<td>FRC (Fast Response Command)</td>
									<td>Not supported</td>
									<td>Supported</td>
								</tr>
								<tr>
									<td>Remote prebonding by Coordinator</td>
									<td>Not supported</td>
									<td>Supported</td>
								</tr>
								<tr>
									<td>2 B user data exchanged during prebonding</td>
									<td>Not supported</td>
									<td>Supported</td>
								</tr>
								<tr>
									<td>Variable <i>memoryLimit</i> for buffer copying</td>
									<td>Not applicable</td>
									<td>Applicable</td>
								</tr>
								<tr>
									<td>Power consumption in LP and XLP modes</td>
									<td>-</td>
									<td>Improved management during routing</td>
								</tr>
								<tr>
									<td>Licensed Flash memory</td>
									<td>1984 machine instructions</td>
									<td>3008 machine instructions</td>
								</tr>
								<tr>
									<td>DPA framework support</td>
									<td>v1.00</td>
									<td>v2.00</td>
								</tr>
								<tr>
									<td>TR vs. DCTR differentiation</td>
									<td>No DCTR available</td>
									<td>DCTR available</td>
								</tr>
								<tr>
									<td>IQRF IDE support</td>
									<td>v4.13 or higher</td>
									<td>v4.15 or higher</td>
								</tr>
								<tr>
									<td>C compiler CC5X version required by some IQRF examples</td>
									<td>3.04I</td>
									<td>3.5</td>
								</tr>
								<tr>
									<td>DQI implementation in function checkRF()</td>
									<td>Via OS patch PATCH_3040666_01</td>
									<td>Included in OS</td>
								</tr>
								</tbody>
							</table>
				    	<br/>
							<h2 id="3-04d">Migration from OS v3.03D to v3.04D</h2>

							<table className="table--os-history">
								<tbody>
								<tr>
									<th>Features</th>
									<th>OS v3.03D</th>
									<th>OS v3.04D</th>
								</tr>
								<tr>
									<td>Remote bonding</td>
									<td>Not implemented</td>
									<td>Implemented</td>
								</tr>
								<tr>
									<td>RFPGM (wireless upload) version</td>
									<td>Lite</td>
									<td>Standard</td>
								</tr>
								<tr>
									<td>DPA (Direct Peripheral Access)</td>
									<td>Not supported</td>
									<td>Supported</td>
								</tr>
								<tr>
									<td><i>removeBondAddress</i> function</td>
									<td>Not supported</td>
									<td>Supported</td>
								</tr>
								<tr>
									<td>tr configuration via IQRF IDE</td>
									<td>Not supported</td>
									<td>Supported</td>
								</tr>
								<tr>
									<td>OS function <i>setrFband</i></td>
									<td>Available</td>
									<td>Replaced by tr configuration</td>
								</tr>
								<tr>
									<td>Licensed Flash memory</td>
									<td>2016 machine instructions</td>
									<td>1984 machine instructions</td>
								</tr>
								<tr>
									<td>Detection of temperature sensor and serial EEPROM on the tr module</td>
									<td>Not supported</td>
									<td>Supported via tr Configuration
									</td>
								</tr>
								<tr>
									<td>RFTXpacket duration</td>
									<td>-</td>
									<td>Prolonged for cca 3 ms</td>
								</tr>
								<tr>
									<td>RF RX mode before initiating RFPGM, bondRequest or bondNewNode</td>
									<td>Std mode must be selected first</td>
									<td>Applicable in any RX mode</td>
								</tr>
								<tr>
									<td>LED indication during Discovery</td>
									<td>Always enabled</td>
									<td>Controlled by bit <i>_systemLedIndication</i></td>
								</tr>
								<tr>
									<td>Wait Packet End feature improvement</td>
									<td>Via OS <i>RX-timeout-patch</i></td>
									<td>Included in OS</td>
								</tr>
								<tr>
									<td>STKPtr handling when using RFPGM</td>
									<td>STKPtr = 0x1F must be placed as the first command of application program</td>
									<td>Included in OS</td>
								</tr>
								<tr>
									<td>Discovery parameter</td>
									<td>Number of zones</td>
									<td>Max. logical address of routing nodes</td>
								</tr>
								<tr>
									<td>IQRF IDE support</td>
									<td>v4.11 or higher</td>
									<td>v4.13 or higher</td>
								</tr>
								</tbody>
							</table>
							<br/>
							<h2 id="3-03d">Migration from OS v3.02D to v3.03D</h2>

							<table className="table--os-history">
								<tbody>
								<tr>
									<th>Features</th>
									<th>OS v3.02D</th>
									<th>OS v3.03D</th>
								</tr>
								<tr>
									<td>Function <i>getrSSI</i></td>
									<td>Not implemented</td>
									<td>Implemented</td>
								</tr>
								<tr>
									<td>DPA network visualization</td>
									<td>Not supported</td>
									<td>Supported</td>
								</tr>
								<tr>
									<td>Function <i>RFRXpacket</i> in LP and XLP modes</td>
									<td>A/D stays on after termination</td>
									<td>A/D is switched off after termination</td>
								</tr>
								<tr>
									<td>RFPGM termination 1 s after reset</td>
									<td>Even if RFPGM in progress</td>
									<td>If RFPGM is not in progress</td>
								</tr>
								<tr>
									<td><i>NID</i> after <i>removeBond</i></td>
									<td>Not cleared</td>
									<td>Cleared</td>
								</tr>
								<tr>
									<td>Sender&prime;s address availability for Coordinator in DFM2B</td>
									<td>Not available in OS registers</td>
									<td>Available in OS registers</td>
								</tr>
								<tr>
									<td>TX preamble in XLP</td>
									<td>
										<ul>
											<li>1 s (OS without <strong>PATCH_3.02XLPXTTIME</strong>)</li>
											<li>900 ms (OS with <strong>PATCH_3.02XLPXTTIME</strong>)</li>
										</ul>
									</td>
									<td>900 ms</td>
								</tr>
								<tr>
									<td>Recommended time slot for XLP routing</td>
									<td>
										<ul>
											<li>1.2 s (OS without <strong>PATCH_3.02XLPXTTIME</strong>)</li>
											<li>1 s (OS with <strong>PATCH_3.02XLPXTTIME</strong>)</li>
										</ul>
									</td>
									<td>1 s</td>
								</tr>
								<tr>
									<td>IQRF IDE support</td>
									<td>v4.00 or higher</td>
									<td>v4.11 or higher</td>
								</tr>
								</tbody>
							</table>
							<br/>
							<h2 id="3-02d">Migration from OS v3.01D to v3.02D</h2>

							<table className="table--os-history">
								<tbody>
								<tr>
									<th>Features</th>
									<th>OS v3.01D</th>
									<th>OS v3.02D</th>
								</tr>
								<tr>
									<td>Functions <i>setINDFx</i> and <i>getINDFx</i></td>
									<td>Not available</td>
									<td>Available</td>
								</tr>
								<tr>
									<td>User interrupt</td>
									<td>Not available</td>
									<td>Available</td>
								</tr>
								<tr>
									<td>Using restricted register</td>
									<td>Replaced by the nop instruction</td>
									<td>Replaced by the reset instruction</td>
								</tr>
								<tr>
									<td>Reset detection in userReg0</td>
									<td>_TO, _PD, _POR, _BOR flags</td>
									<td>_RI, _RMCLR, _STKUNF and _STKOVF added</td>
								</tr>
								<tr>
									<td>Timer6</td>
									<td>Not user available</td>
									<td>User available</td>
								</tr>
								<tr>
									<td>Power consumption</td>
									<td>LP: 400 µA, XLP: 35 µA</td>
									<td>LP: 330 µA, XLP: 25 µA</td>
								</tr>
								<tr>
									<td>Cycle duration</td>
									<td>LP: 40 ms, XLP: 500 ms</td>
									<td>LP: 46 ms, XLP: 770 ms</td>
								</tr>
								<tr>
									<td><i>toutrF</i> timing</td>
									<td>Less precise. 0 not allowed.</td>
									<td>More precise. 0 means 2.560 s.</td>
								</tr>
								<tr>
									<td>System tick timing</td>
									<td/>
									<td>More precise.</td>
								</tr>
								<tr>
									<td>Module Info</td>
									<td>Available in PGM mode only</td>
									<td>Can also be read by SPI master</td>
								</tr>
								<tr>
									<td><i>moduleInfo()</i> side effects</td>
									<td>None</td>
									<td><i>bufferINFO[8 to 63]</i> is modified</td>
								</tr>
								<tr>
									<td><i>ntwPVRN</i> network register</td>
									<td>Not available</td>
									<td>Available</td>
								</tr>
								<tr>
									<td>Restriced access to several registers</td>
									<td>INDFx and EECONx restricted</td>
									<td>More registers restricted, see RAM <i>access restrictions</i>.</td>
								</tr>
								<tr>
									<td>Wakeup on pin change</td>
									<td>Simplier macro <i>sleepWOC()</i></td>
									<td>More complex macro <i>sleepWOC()</i></td>
								</tr>
								<tr>
									<td><i>discovery() </i></td>
									<td/>
									<td>More effective for small networks</td>
								</tr>
								</tbody>
							</table>
							<br/>
							<h2 id="3-01d">Migration from TR-5xB</h2>

							<table className="table--os-history">
								<tbody>
								<tr>
									<th>Features</th>
									<th>OS v3.00</th>
									<th>OS v3.01D</th>
								</tr>
								<tr>
									<td>MCU</td>
									<td>PIC16F886</td>
									<td>Powerful PIC16LF1938 with efficient architecture and extended resources</td>
								</tr>
								<tr>
									<td>User available MCU resources:</td>
									<td/>
									<td/>
								</tr>
								<tr>
									<td>RAM</td>
									<td>40 B</td>
									<td>96 B</td>
								</tr>
								<tr>
									<td>Communication buffers (RF, COM, INFO and AUX)</td>
									<td>64 B, 41 B, 35 B, 32 B</td>
									<td>128 B *, 64 B, 64 B, 64 B</td>
								</tr>
								<tr>
									<td>Program memory (Flash)</td>
									<td>1024 words</td>
									<td>1536 words</td>
								</tr>
								<tr>
									<td>RAM addressing</td>
									<td>traditional</td>
									<td>traditional and linear</td>
								</tr>
								<tr>
									<td>Stack levels</td>
									<td>0 to 2, depending on OS functions used</td>
									<td>5</td>
								</tr>
								<tr>
									<td><i>writeToRAM()</i> / <i>readFromRAM()</i> handling</td>
									<td>IRP must be set before</td>
									<td>No settings needed</td>
								</tr>
								<tr>
									<td>External serial EEPROM</td>
									<td>For OS only</td>
									<td>16 kb user available (not for Coordinator utilizing Discovery)</td>
								</tr>
								<tr>
									<td>Brown-out reset (BOR)</td>
									<td>Enabled during operation and disabled in Sleep</td>
									<td>Default disabled, can be enabled by SW (SBOREN = 1)</td>
								</tr>
								<tr>
									<td valign="top">Watchdog</td>
									<td>
										<ul>
											<li>Timeout period from 1 ms 268 s</li>
											<li>Handling by WDTCON and OPTION</li>
											<li>Default enabled, can be disabled by SW (SWDTEN = 0)</li>
										</ul>
									</td>
									<td>
										<ul>
											<li>Timeout period from 1 ms to 256 s</li>
											<li>Simplified handling (by WDTCON only)</li>
											<li>Default disabled, can be enabled by SW (SWDTEN = 1)</li>
										</ul>
									</td>
								</tr>
								<tr>
									<td>Wake-up from sleep on pin change</td>
									<td>Both edges always active</td>
									<td>Selectable edge: rising/falling/both
										Required sequence changed
									</td>
								</tr>
								<tr>
									<td>Temperature sensor</td>
									<td>Accuracy ±2 oC max., sensor MCP9700A</td>
									<td>Accuracy 0.5 oC, sensor TMP112
										Function <i>getTemperature()</i> changed
									</td>
								</tr>
								<tr>
									<td>Register param1</td>
									<td>Used</td>
									<td>Not used</td>
								</tr>
								<tr>
									<td><i>reset()</i></td>
									<td>OS function, iduced by watchdog</td>
									<td>MCU machine instruction</td>
								</tr>
								<tr>
									<td><i>getNetworkParams()</i> return value</td>
									<td>Used</td>
									<td>Not used, parameters available in respective registers</td>
								</tr>
								<tr>
									<td><i>checkRF()</i></td>
									<td>1 ms (or 350 µs staying in RX mode)</td>
									<td>1.4 ms (or ~690 µs staying in RX mode)</td>
								</tr>
								<tr>
									<td>Access to system, RF and network parameters via RAM registers (read/write or read only)</td>
									<td>Just a few parameters available (<i>toutrF</i>, …) as RAM registers. Some others available
										via <i>getNetworkParams()</i>.
									</td>
									<td>Extended (RF channel, RF power etc.). See Appendix 1, memory maps.</td>
								</tr>
								<tr>
									<td>SPI packet length</td>
									<td>up to 41 B</td>
									<td>up to 64 B. IQRF IDE v4.02 beta supports SPI packets up to 60 B only.</td>
								</tr>
								<tr>
									<td><i>CLID0</i> and <i>CLID1</i> registers</td>
									<td>–</td>
									<td>Renamed to <i>NID0</i> and <i>NID1</i></td>
								</tr>
								<tr>
									<td>RF PGM Lite (wireless upload)</td>
									<td>OS plug-in</td>
									<td>Included in OS</td>
								</tr>
								<tr>
									<td>IQRF IDE</td>
									<td>v2.08</td>
									<td>v4.00 or higher</td>
								</tr>
								</tbody>
							</table>
						</Col>
					</Row>
				</Grid>
			</div>
		);
	}

}

/**
 * Exporting part of the React.Component file
 */
export default withTranslation()(TechnologyOSMigrationNotesScene);