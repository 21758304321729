// @flow
import React from "react";
import {withTranslation} from "react-i18next";
import {Col, Grid, Row} from "react-flexbox-grid";
import BackendRequest from "../../Models/REST";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import MasterGetter from "../../Models/Utils/MasterGetter";
import LoadingComponent from "../../Components/LoadingComponent";
import {EventForm} from "../../Forms";
import InstantAction from "../../Models/Utils/InstantAction";
import {REMOVE_EVENT} from "../../Models/Models/Event";
import DeleteModal from "../../Components/DeleteModal";

/**
 * @class EventItemScene
 */
class EventItemScene extends React.Component {

	/**
	 * State
	 * @type {{expectingResponse: boolean}}
	 */
	state = {
		expectingResponse: true,
		modalIsOpen: false,
	};

	/**
	 * Fetch data
	 */
	fetchData = () => {

		const {match} = this.props;
		const itemId = match.params.id;


		if (itemId !== undefined) {
			BackendRequest({
				endpoint: "event/" + itemId,
				self: this,
			});
		} else {
			this.setState({
				expectingResponse: false,
			});
		}

	};

	/**
	 * Component did mount
	 */
	componentDidMount() {
		this.fetchData();
	}

	/**
	 * Handle Delete
	 */
	handleDelete = () => {

		const {
			match
		} = this.props;
		const itemId = match.params.id;

		BackendRequest({
			endpoint: "event/" + itemId,
			method: "delete",
			afterSuccess: () => {

				InstantAction.dispatch({
					type: REMOVE_EVENT,
					payload: itemId
				});

				InstantAction.redirect("/editor/events");
			}
		});

	};

	/**
	 * Final Render
	 * @returns {*}
	 */
	render() {

		const {
			t,
			entities,
			match,
		} = this.props;

		const itemId = match.params.id;
		const session = MasterGetter.getSession(entities);
		const event = session.Event.withId(itemId);
		const isNew = event === null;


		if (this.state.expectingResponse) {
			return <LoadingComponent/>;
		}

		return (
			<Grid fluid>
				<Row>
					<Col xs={12} lg={6}>
						<h1>Event: {isNew ? "[new]" : "[edit]"}</h1>
					</Col>
					<Col xs={12} lg={6} className="text--right">
						<br/>
						<button className="btn" onClick={() => window.history.back()}>Cancel <i className="icon-back-arrow"/></button>
						&nbsp;
						<button className={"btn btn--danger"} onClick={() => InstantAction.redirect("/editor/events")}>Go Back</button>
						&nbsp;
						<button className={"btn btn--warning"} onClick={(e) => {
							e.preventDefault();
							this.setState({
								modalIsOpen: true,
							});
						}}>{t("button.Delete")}</button>
					</Col>
					<Col xs={12}>
						<hr/>
					</Col>
					<Col xs={12} className="page__section--bottom-padding">
						<EventForm event={event}/>
					</Col>
				</Row>
				<DeleteModal isOpen={this.state.modalIsOpen} handleDelete={this.handleDelete} parent={this} />
			</Grid>
		);
	}

}

/**
 * Map state to props
 * @param state
 * @return {{entities: {CaseStudy: (*|CaseStudy), Photo: (*|Photo)}}}
 */
const mapStateToProps = state => (
	{
		entities: {
			Photo: state.entities.Photo,
			Event: state.entities.Event,
		},
	});

/**
 * Exporting part of the React.Component file
 */
export default withRouter(connect(mapStateToProps)(withTranslation()(EventItemScene)));