// @flow

// Necessary Evil
import React from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import LoadingComponent from "../Components/LoadingComponent";
import BackendRequest from "../Models/REST/BackendRequest";
import FormGroup from "../Components/Form/FormGroup";
import { connect } from "react-redux";
import ErrorMessage from "../Components/Form/ErrorMessage/ErrorMessage";
import Markdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import rehypeSanitize from "rehype-sanitize";
import remarkGfm from "remark-gfm";
import { Col, Row } from "react-flexbox-grid";

/**
 * @class StartupPackageHistoryForm
 */
class StartupPackageHistoryForm extends React.Component {

	/**
	 * PropTypes
	 * @type {{children: shim, videoCategories: *}}
	 */
	static propTypes = {
		item: PropTypes.any,
		afterSuccess: PropTypes.func,
	};

	/**
	 * Default Props
	 * @type {{afterSuccess: StartupPackageHistoryForm.defaultProps.afterSuccess}}
	 */
	static defaultProps = {
		afterSuccess: () => {
			// InstantAction.redirect("/editor/startup-package-history");
		},
	};

	/**
	 * State
	 * @type {{formData: {}, expectingResponse: boolean, errorMessage: null}}
	 */
	state = {
		expectingResponse: false,
		errorMessage: null,
		// Properties
		id: null,
		title: null,
		description: "",
		ordering: 0,
	};

	/**
	 * Get derived state from props
	 * @param props
	 * @param state
	 */
	static getDerivedStateFromProps (props, state) {

		if (props.item !== null) {
			if (props.item.id !== state.id) {
				return {
					...props.item._fields,
				};
			}
		}
		return null;
	}

	/**
	 * Handle Input Changes
	 * @param event
	 */
	handleInputChange = (event) => {

		const target = event.target;
		const value = target.type === "checkbox" ? target.checked : target.value;
		const name = target.name;

		this.setState({
			[name]: value
		});

	};

	/**
	 * Handle Switch
	 * @param key
	 */
	handleSwitch = (key) => {

		this.setState({
			[key]: !this.state[key]
		});

	};

	/**
	 * Handle Submit
	 * @param e
	 */
	handleSubmit = (e) => {

		e.preventDefault();

		/**
		 * Payload
		 * @type {{image: null, name: string, title: string, content: string}}
		 */

		const payload = {
			id: this.state.id,
			title: this.state.title,
			description: this.state.description,
			ordering: this.state.ordering,
		};

		BackendRequest({
			endpoint: "startup-package-history/" + this.state.id,
			method: "put",
			payload: payload,
			self: this,
			afterSuccess: this.props.afterSuccess
		});
	};

	/**
	 * Final Render
	 * @returns {*}
	 */
	render () {

		/**
		 * @info Translation function, className
		 */
		const {
			t,
		} = this.props;

		/**
		 * @info Error variable
		 */
		let error = "";

		/**
		 * @info Handling Error Message
		 */
		if (this.state.errorMessage !== null) {
			error = <ErrorMessage>{this.state.errorMessage}</ErrorMessage>;
		}

		/**
		 * Final Output
		 * @type {{}}
		 */
		return (<form
			onSubmit={this.handleSubmit}
			className={"form"}
		>
			{error}
			<FormGroup>
				<label htmlFor="title">Title</label>
				<input onChange={this.handleInputChange}
					   value={this.state.title}
					   type="text"
					   tabIndex={1}
					   name="title"
					   id="title"
				/>
				<h2>{<Markdown remarkPlugins={[remarkGfm]} children={this.state.title}/>}</h2>
			</FormGroup>
			<Row>
				<Col xs={12} lg={6}>
					<FormGroup>
						<label htmlFor="description">Description</label>
						<textarea
							onChange={this.handleInputChange}
							value={this.state.description}
							tabIndex={2}
							name="description" id="description"
							placeholder={"Description"}
						/>
						<em>(Use markdown - <a target="_blank"
											   href="https://github.com/adam-p/markdown-here/wiki/Markdown-Cheatsheet"
											   rel="noopener noreferrer">https://github.com/adam-p/markdown-here/wiki/Markdown-Cheatsheet</a>)</em>
					</FormGroup>
				</Col>
				<Col xs={12} lg={6}>
					<FormGroup>
						<label>{t("label.Preview")}</label>
						<Markdown
							rehypePlugins={[rehypeRaw, rehypeSanitize]}
							remarkPlugins={[remarkGfm]}
							children={this.state.description}
						/>
					</FormGroup>
				</Col>
			</Row>
			<FormGroup>
				<label htmlFor="ordering">Ordering</label>
				<input onChange={this.handleInputChange}
					   value={this.state.ordering}
					   type="text"
					   name="ordering"
					   id="ordering"
				/>
			</FormGroup>
			<hr/>
			<FormGroup className={"text--center"}>
				{(this.state.expectingResponse) ? <LoadingComponent/> :
					<button
						type="submit"
						tabIndex={2}
						className="btn btn--animated"
					>
						Save
					</button>
				}
			</FormGroup>
		</form>);
	}
}

/**
 * Map State to Props
 * @param state
 * @return {{entities: *}}
 */
const mapStateToProps = state => (
	{
		entities: state.entities,
	});

export default (connect(mapStateToProps)(withTranslation()(StartupPackageHistoryForm)));
