// @flow
import React from "react";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import {Link, withRouter} from "react-router-dom";
import {Col, Grid, Row} from "react-flexbox-grid";
import {NewsLoader} from "../../Components/News";
import "../../Components/Landing/Landing.scss";
import {PromoBox} from "../../Components/Landing";
import {EventsLoader} from "../../Components/Events";
import {Helmet} from "react-helmet";

/**
 * @class LandingScene
 */
class LandingScene extends React.Component {

	/**
	 * Final Render
	 * @returns {*}
	 */
	render() {

		return (
			<div className={"page"}>
				<Helmet>
					<title>IQRF - Technology for wireless</title>
				</Helmet>
				<PromoBox/>
				<Grid>
					<Row>
						<Col xs={12} lg={6} className="page__section--with-margin">
							<Link to={"/news"}><h2>News</h2></Link>
							<NewsLoader/>
							<Link to={"/news"}>» More news</Link>
						</Col>
						<Col xs={12} lg={6} className="page__section--with-margin">
							<Link to={"/events"}><h2>Events</h2></Link>
							<EventsLoader/>
							<Link to={"/events"}>» More events</Link>
						</Col>
					</Row>
				</Grid>
			</div>
		);
	}

}

/**
 * This function maps the state to a
 * prop called `state`.
 *
 * In larger apps it is often good
 * to be more selective and only
 * map the part of the state tree
 * that is necessary.
 */
const mapStateToProps = state => (
	{
		app: state.app,
	});

/**
 * Exporting part of the React.Component file
 */
export default withRouter(connect(mapStateToProps)(withTranslation()(LandingScene)));