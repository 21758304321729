import React from "react";
import {GridLoader} from "react-spinners";

/**
 * Loading component for Async Loading
 * @return {*}
 * @constructor
 */
const LoadingComponent = () => (
    <div style={{justifyContent: "center", alignItems: "center", display: "flex", flex: "1 1", margin: "2rem"}}>
        <GridLoader
            color={"#5cabf9"}
            size={1}
            sizeUnit={"rem"}
            margin={"0.5rem"}
        />
    </div>
);

export default LoadingComponent;