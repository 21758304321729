// @flow
import React from "react";
import {withTranslation} from "react-i18next";
import {Col, Grid, Row} from "react-flexbox-grid";
import tr_76da from "../../Components/Technology/img/TR-76GA-RF.png";
import {LinksModule} from "../../Components/Links";
import {Helmet} from "react-helmet";
import {Link} from "react-router-dom";

/**
 * @class TechnologyRFScene
 */
class TechnologyRFScene extends React.Component {


	/**
	 * Final Render
	 * @returns {*}
	 */
	render() {

		return (
			<Grid className={"page"}>
				<Helmet>
					<title>RF - IQRF</title>
				</Helmet>
				<Row>
					<Col xl={6} xs={12}>
						<h1>RF</h1>
						<h2>RF bands</h2>
					</Col>
				</Row>
				<Row className="page__section--bottom-padding">
					<Col xl={6} xs={12}>
						<p><strong>Free</strong> sub-GHz
							<a href="https://en.wikipedia.org/wiki/ISM_band" rel="noopener noreferrer"
							   target="_blank"> ISM</a>, multichannel.</p>
						<div className="table--wrapper">
							<table>
								<tbody>
								<tr>
									<td><strong>433</strong> MHz</td>
									<td>Worldwide</td>
								</tr>
								<tr>
									<td><strong>787</strong> MHz</td>
									<td>China (Preliminary)</td>
								</tr>
								<tr>
									<td><strong>868</strong> MHz</td>
									<td>Europe and other</td>
								</tr>
								<tr>
									<td><strong>915</strong> MHz</td>
									<td>USA and other</td>
								</tr>
								<tr>
									<td><strong>920</strong> MHz</td>
									<td>Japan and other</td>
								</tr>
								</tbody>
							</table>
						</div>
						<br/>
						<h2>RF range</h2>
						<p><Link
							to="/download/d0af3084-af00-40ba-9d94-6d7613265f13"
							target="_self"
						>RF range</Link> with tiny omnidirectional antennas and RF output power
							<strong> 10 mW</strong> only:</p>
						<div className="table--wrapper">
							<table>
								<tbody>
								<tr>
									<td>In buildings:</td>
									<td>Tens of meters</td>
								</tr>
								<tr>
									<td>In free space:</td>
									<td><strong>Hundreds of meters</strong></td>
								</tr>
								</tbody>
							</table>
						</div>
						<br/>
						<h2>RF bit rate</h2>
						<p>19.8 kb/s</p>
					</Col>
					<Col xl={6} xs={12}>
						<img src={tr_76da} alt={"TR-72D and TR-72DA"} width={"60%"}/>
					</Col>
					<Col xs={12}>
							<LinksModule page={"technology-rf"}/>
					</Col>
				</Row>
			</Grid>

		);
	}

}

/**
 * Exporting part of the React.Component file
 */
export default withTranslation()(TechnologyRFScene);
