export default {
    Login: "Login",
    Save: "Save",
    SignIn: "Sign In",
    SignUp: "Sign Up",
    Submit: "Submit",
    Download: "Download",
    CopyFileUrl: "Copy URL",
    GetMarkdown: "Get Markdown",
    CreateNew: "Create New",
    Delete: "Delete",
    Update: "Update",
    AddPrice: "Add price",
    ShowMore: "Show more",
    MoreInfo: "More info",
};