// @flow

// Necessary Evil
import React from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import LoadingComponent from "../Components/LoadingComponent";
import BackendRequest from "../Models/REST/BackendRequest";
import FormGroup from "../Components/Form/FormGroup";
import { connect } from "react-redux";
import ErrorMessage from "../Components/Form/ErrorMessage/ErrorMessage";
import Markdown from "react-markdown";
import remarkGfm from "remark-gfm";
import SwitchButton from "../Components/Form/SwitchButton/SwitchButton";
import { Col, Row } from "react-flexbox-grid";
import MasterGetter from "../Models/Utils/MasterGetter";

/**
 * @class FileForm
 */
class FileForm extends React.Component {

	/**
	 * PropTypes
	 * @type {{children: shim, videoCategories: *}}
	 */
	static propTypes = {
		file: PropTypes.any,
		afterSuccess: PropTypes.func,
	};

	/**
	 * Default Props
	 * @type {{afterSuccess: FileForm.defaultProps.afterSuccess}}
	 */
	static defaultProps = {
		afterSuccess: () => {
			// InstantAction.redirect("/editor/files");
		},
	};

	/**
	 * State
	 * @type {{formData: {}, expectingResponse: boolean, errorMessage: null}}
	 */
	state = {
		expectingResponse: false,
		errorMessage: null,
		// Properties
		id: null,
		description: "",
		filename: "",
		is_archived: false,
		is_online: true,
		name: "",
		old_id: null,
		old_url: "",
		ordering: "",
		section: 0,
		type: 0,
		url: "",
	};

	/**
	 * Get derived state from props
	 * @param props
	 * @param state
	 */
	static getDerivedStateFromProps (props, state) {

		if (props.file !== null) {
			if (props.file.id !== state.id) {
				return {
					...props.file._fields,
				};
			}
		}
		return null;
	}

	/**
	 * Handle Input Changes
	 * @param event
	 */
	handleInputChange = (event) => {

		const target = event.target;
		const value = target.type === "checkbox" ? target.checked : target.value;
		const name = target.name;

		this.setState({
			[name]: value
		});

	};

	/**
	 * Handle Switch
	 * @param key
	 */
	handleSwitch = (key) => {

		this.setState({
			[key]: !this.state[key]
		});

	};

	/**
	 * Handle Submit
	 * @param e
	 */
	handleSubmit = (e) => {

		e.preventDefault();

		/**
		 * Payload
		 * @type {{image: null, name: string, title: string, content: string}}
		 */
		let method = this.state.id === null ? "post" : "put";
		let endpoint = this.state.id === null ? "file-item" : "file-item/" + this.state.id;

		BackendRequest({
			endpoint: endpoint,
			method: method,
			payload: {
				...this.state
			},
			self: this,
			afterSuccess: this.props.afterSuccess
		});
	};

	/**
	 * Final Render
	 * @returns {*}
	 */
	render () {

		/**
		 * @info Translation function, className
		 */
		const {
			t,
			entities,
		} = this.props;

		const session = MasterGetter.getSession(entities);
		const sections = session.DownloadSection.all();
		const types = session.FileType.all();

		/**
		 * @info Error variable
		 */
		let error = "";

		/**
		 * @info Handling Error Message
		 */
		if (this.state.errorMessage !== null) {
			error = <ErrorMessage>{t("formError." + this.state.errorMessage)}</ErrorMessage>;
		}

		/**
		 * Final Output
		 * @type {{}}
		 */
		return <form
			onSubmit={this.handleSubmit}
			className={"form"}
		>
			{error}
			<Row>
				<Col xs={12}>
					<FormGroup>
						<label htmlFor="filename">Filename</label>
						<input onChange={this.handleInputChange}
							   value={this.state.filename}
							   disabled={true}
							   type="text"
							   name="filename"
							   id="filename"
							   placeholder={"Filename"}
						/>
					</FormGroup>
					<FormGroup>
						<label htmlFor="name">{t("label.Name")}</label>
						<input onChange={this.handleInputChange}
							   value={this.state.name}
							   type="text"
							   name="name"
							   id="name"
							   placeholder={t("label.Name")}
						/>
					</FormGroup>
					<FormGroup>
						<label htmlFor="section">Download Section</label>
						<select onChange={this.handleInputChange}
								value={this.state.section}
								name="section"
								id="section"
						>
							<option value={0}>[select]</option>
							{sections.toModelArray().map(item => {
								return <option key={item.id} value={item.id}>{item.name}</option>;
							})}
						</select>
					</FormGroup>
					<FormGroup>
						<label htmlFor="type">File Type</label>
						<select onChange={this.handleInputChange}
								value={this.state.type}
								name="type"
								id="type"
						>
							<option value={0}>[select]</option>
							{types.toModelArray().map(item => {
								return <option key={item.id} value={item.id}>{item.name}</option>;
							})}
						</select>
					</FormGroup>
					<FormGroup>
						<label htmlFor="url">URL</label>
						<input onChange={this.handleInputChange}
							   value={this.state.url}
							   type="text"
							   name="url"
							   id="url"
						/>
					</FormGroup>
					<FormGroup>
						<label htmlFor="old_url">Old URL</label>
						<input onChange={this.handleInputChange}
							   value={this.state.old_url}
							   type="text"
							   name="old_url"
							   id="old_url"
						/>
					</FormGroup>
					<FormGroup>
						<label htmlFor="ordering">Ordering</label>
						<input onChange={this.handleInputChange}
							   value={this.state.ordering}
							   type="text"
							   name="ordering"
							   id="ordering"
						/>
					</FormGroup>
				</Col>
				<Col xs={12} lg={6}>
					<FormGroup>
						<label htmlFor="description">Description</label>
						<textarea onChange={this.handleInputChange}
								  value={this.state.description}
								  name="description"
								  id="description"
								  placeholder={"description"}
						/>
						<em>(Use markdown - <a
							target="_blank"
							href="https://github.com/adam-p/markdown-here/wiki/Markdown-Cheatsheet"
							rel="noopener noreferrer"
						>https://github.com/adam-p/markdown-here/wiki/Markdown-Cheatsheet</a>)</em>
					</FormGroup>
				</Col>
				<Col xs={12} lg={6}>
					<FormGroup>
						<label>{t("label.Preview")}</label>
						<Markdown remarkPlugins={[remarkGfm]} children={this.state.description}/>
					</FormGroup>
				</Col>
			</Row>
			<Row>
				<Col xs={12}>
					<FormGroup>
						<label htmlFor="description">Description</label>

					</FormGroup>
				</Col>
			</Row>
			<FormGroup>
				<label>{t("label.IsOnline")}?</label>
				<br/>
				<SwitchButton checked={this.state.is_online} handleClick={this.handleSwitch} name={"is_online"}/>
			</FormGroup>
			<FormGroup>
				<label>Is archived?</label>
				<br/>
				<SwitchButton checked={this.state.is_archived} handleClick={this.handleSwitch} name={"is_archived"}/>
			</FormGroup>
			<hr/>
			<FormGroup className={"text--center"}>
				{(this.state.expectingResponse) ? <LoadingComponent/> :
					<button type="submit" tabIndex={2}
							className="btn btn--animated">{this.state.id === null ? t("button.Submit") : t("button.Update")}</button>
				}
			</FormGroup>
		</form>;
	}
}

/**
 * State to Props
 * @param state
 * @return {{entities: {PhotoSection: (*|PhotoSection)}}}
 */
const mapStateToProps = state => (
	{
		entities: {
			File: state.entities.File,
			DownloadSection: state.entities.DownloadSection,
			FileType: state.entities.FileType,
		},
	});

export default (connect(mapStateToProps)(withTranslation()(FileForm)));
