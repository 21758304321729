// @flow

// Necessary Evil
import React from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import LoadingComponent from "../Components/LoadingComponent";
import BackendRequest from "../Models/REST/BackendRequest";
import FormGroup from "../Components/Form/FormGroup";
import { connect } from "react-redux";
import ErrorMessage from "../Components/Form/ErrorMessage/ErrorMessage";
import Markdown from "react-markdown";
import remarkGfm from "remark-gfm";
import SwitchButton from "../Components/Form/SwitchButton/SwitchButton";
import { Col, Row } from "react-flexbox-grid";

/**
 * @class WebinarForm
 */
class WebinarForm extends React.Component {

	/**
	 * PropTypes
	 * @type {{children: shim, videoCategories: *}}
	 */
	static propTypes = {
		item: PropTypes.any,
		afterSuccess: PropTypes.func,
	};

	/**
	 * Default Props
	 * @type {{afterSuccess: WebinarForm.defaultProps.afterSuccess}}
	 */
	static defaultProps = {
		afterSuccess: () => {
			// InstantAction.redirect("/editor/webinars");
		},
	};

	/**
	 * State
	 * @type {{formData: {}, expectingResponse: boolean, errorMessage: null}}
	 */
	state = {
		expectingResponse: false,
		errorMessage: null,
		// Properties
		id: null,
		description: "",
		is_archived: true,
		is_online: true,
		ordering: 0,
		subtitle: "",
		title: null,
	};

	/**
	 * Get derived state from props
	 * @param props
	 * @param state
	 */
	static getDerivedStateFromProps (props, state) {

		if (props.item !== null) {
			if (props.item.id !== state.id) {
				return {
					...props.item._fields,
				};
			}
		}
		return null;
	}

	/**
	 * Handle Input Changes
	 * @param event
	 */
	handleInputChange = (event) => {

		const target = event.target;
		const value = target.type === "checkbox" ? target.checked : target.value;
		const name = target.name;

		this.setState({
			[name]: value
		});

	};

	/**
	 * Handle Switch
	 * @param key
	 */
	handleSwitch = (key) => {

		this.setState({
			[key]: !this.state[key]
		});

	};

	/**
	 * Handle Submit
	 * @param e
	 */
	handleSubmit = (e) => {

		e.preventDefault();

		/**
		 * Payload
		 * @type {{image: null, name: string, title: string, content: string}}
		 */

		const payload = {
			id: this.state.id,
			title: this.state.title,
			subtitle: this.state.subtitle,
			is_archived: this.state.is_archived,
			description: this.state.description,
			ordering: this.state.ordering,
			is_online: this.state.is_online,
		};

		BackendRequest({
			endpoint: "webinar/" + this.state.id,
			method: "put",
			payload: payload,
			self: this,
			afterSuccess: this.props.afterSuccess
		});
	};

	/**
	 * Final Render
	 * @returns {*}
	 */
	render () {

		/**
		 * @info Translation function, className
		 */
		const {
			t,
		} = this.props;

		/**
		 * @info Error variable
		 */
		let error = "";

		/**
		 * @info Handling Error Message
		 */
		if (this.state.errorMessage !== null) {
			error = <ErrorMessage>{t("formError." + this.state.errorMessage)}</ErrorMessage>;
		}

		/**
		 * Final Output
		 * @type {{}}
		 */
		return <form onSubmit={this.handleSubmit} className={"form"}>
			{error}
			<FormGroup>
				<label htmlFor="title">Title</label>
				<input onChange={this.handleInputChange}
					   value={this.state.title}
					   type="text"
					   tabIndex={1}
					   name="title"
					   id="title"
				/>
			</FormGroup>
			<FormGroup>
				<label htmlFor="subtitle">Subtitle (optional)</label>
				<input onChange={this.handleInputChange}
					   value={this.state.subtitle}
					   type="text"
					   tabIndex={2}
					   name="subtitle"
					   id="subtitle"
				/>
			</FormGroup>
			<Row>
				<Col xs={12} lg={6}>
					<FormGroup>
						<label htmlFor="description">Description</label>
						<textarea
							onChange={this.handleInputChange}
							value={this.state.description}
							tabIndex={2}
							name="description" id="description"
							placeholder={"Description"}
						/>
						<em>(Use markdown - <a target="_blank"
											   href="https://github.com/adam-p/markdown-here/wiki/Markdown-Cheatsheet"
											   rel="noopener noreferrer">https://github.com/adam-p/markdown-here/wiki/Markdown-Cheatsheet</a>)</em>
					</FormGroup>
				</Col>
				<Col xs={12} lg={6}>
					<FormGroup>
						<label>{t("label.Preview")}</label>
						<Markdown remarkPlugins={[remarkGfm]} children={this.state.description}/>
					</FormGroup>
				</Col>
			</Row>
			<FormGroup>
				<label>{t("label.IsOnline")}?</label>
				<br/>
				<SwitchButton checked={this.state.is_online} handleClick={this.handleSwitch} name={"is_online"}/>
			</FormGroup>
			<FormGroup>
				<label>Is archived?</label>
				<br/>
				<SwitchButton checked={this.state.is_archived} handleClick={this.handleSwitch} name={"is_archived"}/>
			</FormGroup>
			<FormGroup>
				<label htmlFor="ordering">Ordering</label>
				<input onChange={this.handleInputChange}
					   value={this.state.ordering}
					   type="text"
					   name="ordering"
					   id="ordering"
				/>
			</FormGroup>
			<hr/>
			<FormGroup className={"text--center"}>
				{(this.state.expectingResponse) ? <LoadingComponent/> :
					<button type="submit" tabIndex={2}
							className="btn btn--animated">{this.state.id === null ? t("button.Submit") : t("button.Update")}</button>
				}
			</FormGroup>
		</form>;
	}
}

/**
 * State to Props
 * @param state
 * @return {{entities: {PhotoSection: (*|PhotoSection)}}}
 */
const mapStateToProps = state => (
	{
		entities: state.entities,
	});

export default (connect(mapStateToProps)(withTranslation()(WebinarForm)));
