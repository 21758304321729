export default {
    AboutIQRF: "What is IQRF?",
    AboutUs: "About Us",
    BackToWebsite: "Back to website",
    Career: "Careers",
    CaseStudies: "Case Studies",
    ClaimReturns: "Claim / Returns",
    Company: "Company",
    Contacts: "Contacts",
    ContactSales: "Contact",
    Distributors: "Distributors",
    DesignHouses: "Design houses",
    Downloads: "Downloads",
    Edit: "Edit",
    Editor: "Editor",
    EShop: "E-shop",
    Events: "Events",
    FAQ: "FAQ",
    Files: "Files",
    GetStarted: "Get Started",
    Home: "Home",
    HowToStart: "How to start?",
    IQRFAlliance: "IQRF Alliance",
    Logout: "Logout",
    News: "News",
    Patents: "Patents",
    Photos: "Photos",
    Pricelist: "Pricelist",
    PrivacyPolicy: "Privacy policy",
    Products: "Products",
    Sales: "Sales",
    Support: "Support",
    TechnicalSupport: "Technical Support",
    Technology: "Technology",
    TermsOfUse: "Terms of use",
    Trademarks: "Trademarks",
    Training: "Training",
    UseCases: "Use cases",
    Videos: "Videos",
    Webinars: "Webinars",
    ProductVariants: "Product variants",
    Login: "Login",
    FAE: "FAE",
    ContactSupport: "Contact Support",
    CompanyProfile: "Company profile",
    Eshop: "E-shop",
    VOP: "Commercial terms & conditions"
};