// @flow

// Necessary Evil
import React from "react";
import PropTypes from "prop-types";
import {withTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import {Col} from "react-flexbox-grid";
// Import component CSS
import "./SubcategoryItem.scss";


/**
 * @class SubcategoryItem
 */
class SubcategoryItem extends React.Component {

    /**
     * PropTypes
     * @type {{children: shim}}
     */
    static propTypes = {
        children: PropTypes.any,
    };

    /**
     * Final Render
     * @returns {*}
     */
    render() {

        const {subcategory} = this.props;

        return <Col xs={12} lg={6} className="subcategory-item">
            <Link to={"/products/" + subcategory.category.friendly_url() + "/" + subcategory.friendly_url()} key={subcategory.id}>
                <div className="subcategory-item__image">
                    {subcategory.image__url &&
                    <img src={process.env.REACT_APP_BACKEND_SERVER + subcategory.image__url} alt={subcategory.name}/>
                    }
                </div>
                <div>
                    <div className="subcategory-item__title">
                        {subcategory.name}
                    </div>
                    <div className="subcategory-item__description">
                        {subcategory.description}
                    </div>
                </div>
            </Link>
        </Col>;
    }
}

export default withTranslation()(SubcategoryItem);