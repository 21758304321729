// @flow
import React from "react";
import {withTranslation} from "react-i18next";
import {Col, Grid, Row} from "react-flexbox-grid";
import routing0 from "../../Components/Technology/video/Unicast.mp4";
import routing2 from "../../Components/Technology/video/Unicast-2-broken-links.mp4";
import routing5 from "../../Components/Technology/video/Unicast-5-broken-links.mp4";
import {Helmet} from "react-helmet";

/**
 * @class TechnologyUnicastScene
 */
class TechnologyUnicastScene extends React.Component {


  /**
   * Final Render
   * @returns {*}
   */
  render() {

    return (
      <div className={"page"}>
      <Helmet>
      <title>Unicast - IQRF</title>
    </Helmet>
    <Grid>
    <Row className="page__section--bottom-padding">
      <Col xs={12}>
      <h1>Unicast</h1>
      <h2>Packet sent to a single Node, with response</h2>
      <p><strong>IQMESH</strong> communication is primarily <strong>synchronous</strong>. Thus, it is <strong>initiated
    by the Coordinator</strong> and <strong>Nodes only respond</strong> to its requests. The <strong>timing</strong> is
    strictly <strong>deterministic</strong>. Every <strong>routing Node retransmits</strong> the packet in <strong>fixed
    timeslot</strong> according to its <strong>VRN</strong>.</p>
      <p>The <strong>response</strong> goes through the same routing paths and timeslots but <strong>in reverse order</strong>.</p>
      <p>This (patented) mechanism is called <strong>directional flooding</strong>. Avoiding obstacles is arranged automatically.</p>
      <p><a href="https://doc.iqrf.org/DpaTechGuide/misc/IqMeshTiming.htm" rel="noreferrer noopener" target="_blank">&raquo;  Timing calculator</a></p>
      </Col>
    </Row>
    </Grid>
    <Grid>
    <Row >
      <Col xs={12}>
      <p><strong>Request</strong> sent from the Coordinator <strong>to Node 3</strong>:</p>
      <div className="text--center">
        <h3 id="unicast-animation"></h3>
          <video width="560" controls>
          <source src={routing0}/>
          <p>Your browser does not  support the video tag.</p>
          </video>
      </div>
      </Col>
    </Row>
    <Row>
    <Col xs={12}>
      <p>Avoiding possible obstacles:</p>
    </Col>
    <Col xs={6}>
      <div className="text--center">
        <h3 id="routing2"></h3>
        <video width="460" controls>
        <source src={routing2}/>
        <p>Your browser does not support the video tag.</p>
        </video>
      </div>
    </Col>
    <Col xs={6}>
      <div className="text--center">
        <h3 id="routing5"></h3>
        <video width="460" controls>
        <source src={routing5}/>
        <p>Your browser does not support the video tag.</p>
        </video>
      </div>
    </Col>
    </Row>
    </Grid>
    <Grid>
    <Row>
    <Col xs={12}>
      <p>&nbsp;</p>
    </Col>
    </Row>
    </Grid>
    </div>

  );
  }

}

/**
 * Exporting part of the React.Component file
 */
export default withTranslation()(TechnologyUnicastScene);