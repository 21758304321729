// @flow
import React from "react";
import {withTranslation} from "react-i18next";
import {Col, Grid, Row} from "react-flexbox-grid";
import "../../Components/Technology/Technology.scss";
import {Helmet} from "react-helmet";
import {Link} from "react-router-dom";

/**
 * @class TechnologySimpleAdoptionScene
 */
class TechnologySimpleAdoptionScene extends React.Component {

  /**
   * Constructor
   * @param props
   */
  constructor(props) {
    super(props);

    this.state = {
      showContentSupportedGUI: false,
    };
  }

  /**
   * Final Render
   * @returns {*}
   */
  render() {
    const {
      showContentSupportedGUI,
    } = this.state;
    return (
      <Grid className={"page"}>
        <Helmet>
          <title>Simple adoption and integration - IQRF</title>
        </Helmet>
        <Row>
          <Col xs={12}>
            <h1>Simple adoption and integration</h1>
            <p>
              <a href="https://www.youtube.com/playlist?list=PLEQy1I01En9--rYrRUcYgF6WE_q9jjDlV"
                 rel="noopener noreferrer" target="_blank">
                <i className="icon-youtube-logo"/> IQRF video tutorials
              </a>
            </p>
            <p>
              <strong>Add wireless</strong> to <strong>your device</strong> using <Link to="/products/transceivers"><strong>IQRF transceiver</strong></Link>.
            </p>
            <p>They can easily be integrated <strong>into any devices</strong> (sensors, actuators, lights, etc.).</p>
            <hr/>
            <p>IQRF transceivers allow connection via <strong>SIM connector</strong> or <strong>soldering</strong>.</p>
            <div className="text--center">
              <p><img src="https://api.iqrf.org/static/media/img/web/746b715f180e22bc7840585029182cb3.jpg"
                      alt="Mounting-options.png"/></p>
            </div>
            <hr/>
            <p>You can use the <strong>built-in antenna</strong> or connect an <strong>external antenna</strong>.</p>

            <div className="text--center">
              <p>
                <img src="https://api.iqrf.org/static/media/img/web/8d65c5d42881ddfff651b6a51430bfb2.png" alt="Antenna-options.png" />
              </p>
            </div>
            <div className="table--wrapper">
              <table className="table--with-links">
                <tbody>
                <tr>
                  <td>
                    <a href="https://youtu.be/TNuqN1zkm8M" rel="noopener noreferrer" target="_blank">
                      <i className="icon-youtube-logo"/> Recommendations for IQRF integration into your device
                    </a>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
            <p>Case study: <Link to="/case-study/intelligent-heating">» Intelligent heating</Link></p>
            <p>Case study: <span className={"link"}
                                 onClick={() => this.setState({ showContentSupportedGUI: !showContentSupportedGUI })}>
              » Intelligent lighting
            </span></p>
            {showContentSupportedGUI && (
              <div>
                <p>If you want to quickly control your lights wirelessly, use the <Link
                  to={"/product-detail/dali-bridge-iqd-db1m-01a"}>IQRF DALI Bridge</Link>. It is certified for IQRF
                  Interoperability.</p>
                <p>
                  <a href="https://www.iqrfalliance.org/downloads_files/2020-01-lighting-vertical-for-mails.pdf"
                     rel="noopener noreferrer" target="_blank">
                    <img src="https://api.iqrf.org/static/media/img/web/1a9617b157345d29fc81c00481d0f062.png"
                         alt="lighting.png"/>
                  </a>
                </p>
              </div>
            )}
            <p>If you meet the <a href="https://www.iqrfalliance.org/iqrf-interoperability/" rel="noopener noreferrer"
                                  target="_blank">IQRF Standards</a>,
              your devices will be <Link to="/technology/interoperability"><strong>interoperable</strong></Link> with devices by other
              vendors and can easily be <strong>integrated to any 3-rd party</strong> IQRF
              interoperable <strong>systems</strong>.</p>
          </Col>
        </Row>
      </Grid>
    );
  }
}

/**
 * Exporting part of the React.Component file
 */
export default withTranslation()(TechnologySimpleAdoptionScene);
