// @flow
import React from "react";
import {withTranslation} from "react-i18next";
import {TrainingRequestForm} from "../../Forms";
import {Col, Grid, Row} from "react-flexbox-grid";
import "../../Components/Training/Training.scss";
import {Helmet} from "react-helmet";
import {HashLink} from "react-router-hash-link";

/**
 * @class TrainingScene
 */
class TrainingScene extends React.Component {

    /**
     * Final Render
     * @returns {*}
     */
    render() {

        const {t} = this.props;

        return (
            <Grid className={"page"}>
                <Helmet>
                    <title>{t("title.Training")} - IQRF</title>
                </Helmet>
                <Row>
                    <Col xs={12}>
                        <h1>{t("title.Training")}</h1>
                    </Col>
                    <Col xs={12}>
                        <table className="training__table">
                            <tbody>
                            <tr>
                                <th>
                                    {t("label.Preconditions")}
                                </th>
                                <td>
                                    Basic knowledge of electronics and PC. RF and programming experience not required.
                                </td>
                            </tr>
                            <tr>
                                <th>
                                    {t("label.Content")}
                                </th>
                                <td>
                                    Introduction to IQRF<br/>
                                    <strong>RF transceivers</strong><br/>
                                    Basics of <strong>IQMESH</strong><br/>
                                    <strong>IQRF Operating system</strong><br/>
                                    <strong>DPA</strong><br/>
                                    <strong>Development tools, IQRF IDE</strong> development environment<br/>
                                    <strong>How to start</strong> with IQRF<br/>
                                    RF and SPI communication<br/>
                                    Hands-on <strong>examples</strong><br/>
                                    <i>The content can be adapted for individual needs (by prior arrangement).</i><br/>
                                </td>
                            </tr>
                            <tr>
                                <th>
                                    {t("label.Date")}
                                </th>
                                <td>
                                    <HashLink to="#training-request"> On request</HashLink>
                                </td>
                            </tr>
                            <tr>
                                <th>
                                    {t("label.Length")}
                                </th>
                                <td>
                                    One day, about 6 hours
                                </td>
                            </tr>
                            <tr>
                                <th>
                                    {t("label.Place")}
                                </th>
                                <td>
                                    <a rel="noopener noreferrer"
                                       href="https://www.google.com/maps/place/IQRF/@50.4252594,15.3691877,17z/data=!4m2!3m1!1s0x470ea3f256013c39:0x78bb99b7ab5777aa?hl=cs" target="_blank">
                                        Prumyslova 1275
                                    </a>, Jicin, Czech Republic, EU
                                </td>
                            </tr>
                            <tr>
                                <th>
                                    {t("label.Refreshment")}
                                </th>
                                <td>
                                    Lunch included
                                </td>
                            </tr>
                            <tr>
                                <th>
                                    {t("label.Participants")}
                                </th>
                                <td>
                                    1 – 6
                                </td>
                            </tr>
                            <tr>
                                <th>
                                    {t("label.Price")}
                                </th>
                                <td>
                                    20 EUR (including VAT)<br/>
                                    <strong>Free</strong> for schools and teachers
                                </td>
                            </tr>
                            <tr>
                                <th>
                                    {t("label.Discount")}
                                </th>
                                <td>
                                    <strong>40 EUR</strong> discount for IQRF development sets
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </Col>
                    <Col xs={12} className="page__section--with-margin" id="training-request">
                        <TrainingRequestForm/>
                    </Col>
                </Row>
            </Grid>

        );
    }

}

/**
 * Exporting part of the React.Component file
 */
export default withTranslation()(TrainingScene);