// @flow

import PropTypes from "prop-types";
import {attr, fk} from "redux-orm";

import CRUDModel from "../ORM/CRUDModel";


export default class Distributor extends CRUDModel {

}

Distributor.modelName = "Distributor";

Distributor.fields = {
    id: attr(), // non-relational field for any value; optional but highly recommended
	contact_person: attr(),
	mobile: attr(),
	office: attr(),
    city: attr(),
    country: fk("Country"),
    logo: fk("Photo"),
    phone: attr(),
    postcode: attr(),
    street: attr(),
    website: attr(),
    additional_info: attr(),
};

Distributor.propTypes = {
    name: PropTypes.string.isRequired,
    country: PropTypes.object,
    additional_info: PropTypes.string,
};

Distributor.defaultProps = {
    additional_info: "",
};

export const ADD_DISTRIBUTOR = "ADD_DISTRIBUTOR";
export const REMOVE_DISTRIBUTOR = "REMOVE_DISTRIBUTOR";
export const UPDATE_DISTRIBUTOR = "UPDATE_DISTRIBUTOR";