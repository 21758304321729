// @flow

// Necessary Evil
import React from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import LoadingComponent from "../Components/LoadingComponent";
import BackendRequest from "../Models/REST/BackendRequest";
import FormGroup from "../Components/Form/FormGroup";
import { connect } from "react-redux";
import MasterGetter from "../Models/Utils/MasterGetter";

/**
 * @class PhotoForm
 *
 */
class PhotoForm extends React.Component {

	/**
	 * PropTypes
	 * @type {{children: shim}}
	 */
	static propTypes = {
		photo: PropTypes.any,
		afterSuccess: PropTypes.func,
		onCancel: PropTypes.func,
	};

	/**
	 * Default Props
	 * @type {{afterSuccess: PhotoForm.defaultProps.afterSuccess}}
	 */
	static defaultProps = {
		afterSuccess: () => {},
		onCancel: () => {},
	};

	/**
	 * State
	 * @type {{formData: {}, expectingResponse: boolean, errorMessage: null}}
	 */
	state = {
		expectingResponse: false,
		errorMessage: null,
		name: "",
		section: 0,
		id: null,
	};

	/**
	 *
	 * @param props
	 * @param state
	 */
	static getDerivedStateFromProps (props, state) {

		if (props.photo !== null) {
			if (props.photo.id !== state.id) {
				return {
					...props.photo._fields,
				};
			}
		}
	}

	/**
	 * Handle Input Changes
	 * @param event
	 */
	handleInputChange = (event) => {

		const target = event.target;
		const value = target.type === "checkbox" ? target.checked : target.value;
		const name = target.name;

		this.setState({
			[name]: value
		});

	};

	/**
	 * Handle Submit
	 * @param e
	 */
	handleSubmit = (e) => {

		e.preventDefault();

		const payload = {
			name: this.state.name,
			section: this.state.section,
		};

		BackendRequest({
			endpoint: "photo/" + this.state.id,
			method: "put",
			payload: payload,
			self: this,
			afterSuccess: this.props.afterSuccess
		});

	};

	/**
	 * Component will mount
	 */
	componentDidMount () {

		if (this.props.photo !== null) {
			this.setState({
				...this.props.photo._fields,
			});
		}
	}

	/**
	 * Final Render
	 * @returns {*}
	 */
	render () {

		/**
		 * @info Translation function, className
		 */
		const { t, entities } = this.props;
		const session = MasterGetter.getSession(entities);
		const sections = session.PhotoSection.all();

		/**
		 * @info Error variable
		 */
		let error = "";

		/**
		 * @info Handling Error Message
		 */
		if (this.state.errorMessage !== null) {
			error = <div style={{ color: "orange", fontWeight: 700 }}><i
				className="icon-cube"/> {t("formError." + this.state.errorMessage)}</div>;
		}

		/**
		 * Final Output
		 * @type {{}}
		 */
		return <form className={"form"}>
			{error}
			<h2>
				{t("title.EditPhoto")}
			</h2>
			<FormGroup>
				<div
					style={{
						color: "black",
					}}>
					<img style={{ maxHeight: "250px" }} src={process.env.REACT_APP_BACKEND_SERVER + this.state.url}
						 alt={this.state.name}/>
				</div>
			</FormGroup>
			<FormGroup>
				<label htmlFor="">{t("label.Section")}</label>
				<select onChange={this.handleInputChange} value={this.state.section} name="section">
					{sections.toModelArray().map(section => {
						return <option value={section.id}>{section.name}</option>;
					})}
				</select>
			</FormGroup>
			<FormGroup>
				<label htmlFor="">{t("label.PhotoName")}</label>
				<input onChange={this.handleInputChange} value={this.state.name} type="text" tabIndex={1}
					   name="name" id="name"
					   autoFocus={true}
					   placeholder={t("label.Name")}
				/>
			</FormGroup>
			<FormGroup className="text--center">
				{(this.state.expectingResponse) ? <LoadingComponent/> :
					<>
						<button type="submit" className="btn btn--animated" onClick={this.props.onCancel}>Cancel</button>
						<button type="submit" tabIndex={2} className="btn btn--animated" onSubmit={this.handleSubmit} >Submit</button>
					</>}
			</FormGroup>
		</form>;
	}
}

/**
 * State to Props
 * @param state
 * @return {{entities: {PhotoSection: (*|PhotoSection)}}}
 */
const mapStateToProps = state => (
	{
		entities: {
			PhotoSection: state.entities.PhotoSection
		},
	});

export default (connect(mapStateToProps)(withTranslation()(PhotoForm)));