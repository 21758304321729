// @flow
import React from "react";
import {withTranslation} from "react-i18next";
import {Col, Grid, Row} from "react-flexbox-grid";
import mid_cloning from "../../Components/Technology/img/mid-cloning.png";
import {Link} from "react-router-dom";
import {Helmet} from "react-helmet";


/**
 * @class TechnologyOSMIDCloningScene
 */
class TechnologyOSMIDCloningScene extends React.Component {


	/**
	 * Final Render
	 * @returns {*}
	 */
	render() {

		return (

			<div className={"page"}>
				<Helmet>
					<title>MID cloning procedure - IQRF</title>
				</Helmet>
				<Grid>
					<Row>
						<Col xs={12}>
							<h1>MID cloning procedure</h1>
						</Col>
					</Row>
					<Row>
						<Col xs={12} xl={8}>
							<p>MID cloning is a procedure to <strong>replace a transceiver</strong> in a network. It is
								especially important for replacement of a broken network Coordinator.</p>
							<p>It is applicable for IQRF OS from <strong>v3.06D</strong> to <strong>v3.08D</strong> only.
								TRs with IQRF OS <strong>v4.00D</strong> and higher can be replaced without MID cloning,
								using the <em>Backup/Restore</em> procedure only.</p>
							<p>The <strong>backup</strong> of network information (network data backup file *.iqrfbkp) of
								the TR to be replaced <strong>must exist</strong> (being created in IQRF IDE
								<strong> before</strong> a damage), otherwise MID cloning is not possible.</p>
						</Col>
						<Col className="text--center" xs={12} xl={4}>
							<img src={mid_cloning} alt={"MID cloning"}/>
						</Col>
					</Row>
				</Grid>
				<div className="page__section--with-theme">
					<Grid>
						<Row>
							<Col xs={12}>
								<p><em>The terms <strong>Current</strong> and <strong>New</strong> mentioned below relate
									to the "new" TR (not to the "damaged" TR).</em></p>
								<h2>Step 1 – Ask for the MID cloning file</h2>
								<p>Send the MID cloning request to
									<Link to={"/support/contact-support"}> IQRF support </Link>
									including the <strong>Current MID</strong> and <strong>New MID</strong> values.</p>
								<p><strong>Current MID</strong> - the MID of the „functional“ TR: The MID of a "new" TR at
									the user’s disposal, intended to be used as a replacement of a damaged TR.</p>
								<p><strong>New MID</strong> - the MID of the „damaged“ TR: The MID requested for this
									"functional" TR. It must be identical to the MID of the TR to be replaced
									(especially due to a damage). The backup of the TR with this MID must exist.</p>
								<h2>Step 2 – Wait for the MID cloning file receiving</h2>
								<p>You will receive the <strong>MID cloning file</strong> from IQRF support to your
									<strong> e-mail.</strong> The file is not generated automatically and the sending
									will take certain time.</p>
								<h2>Step 3 – Perform the MID change in IQRF IDE</h2>
								<p>To complete the MID cloning, connect the "functional" IQRF transceiver
									to <strong>IQRF IDE</strong> and use the MID cloning file with the wizard
									(from <em>IQMESH Network Manager</em> when using DPA or from menu
									<em>Tools / Clone MID</em> wizard otherwise).</p>
							</Col>
						</Row>
					</Grid>
				</div>
			</div>

		);
	}

}

/**
 * Exporting part of the React.Component file
 */
export default withTranslation()(TechnologyOSMIDCloningScene);