// @flow

// Necessary Evil
import React from "react";
import PropTypes from "prop-types";
import {withTranslation} from "react-i18next";
import {Col, Row} from "react-flexbox-grid";
// Import component CSS
import "./FileSectionItem.scss";
import {FileItem} from "../index";
import slugify from "slugify";


/**
 * @class FileSectionItem
 */
class FileSectionItem extends React.Component {

    /**
     * PropTypes
     * @type {{children: *}}
     */
    static propTypes = {
        hideIfEmpty: PropTypes.bool,
    };

    /**
     * Default Props
     * @type {{hideIfEmpty: boolean}}
     */
    static defaultProps = {
        hideIfEmpty: true,
    };

    /**
     * State
     * @type {{expanded: boolean, fromSelf: boolean}}
     */
    state = {
        expanded: false,
        fromSelf: false,
    };

    /**
     * Get Derived State From Props
     * @param props
     * @param state
     */
    static getDerivedStateFromProps(props, state) {

        if (props.expanded !== state.expanded) {
            if (state.fromSelf) {
                // Call comes from self
                return {
                    fromSelf: false,
                    expanded: true
                };
            }
        }

        return null;
    }

    /**
     * Handle Section click
     */
    handleSectionClick = () => {

        const {section} = this.props;

        localStorage.setItem("downloads-" + section.id, true);


        this.setState({
            expanded: !this.state.expanded,
            fromSelf: true,
        });

    };

    checkHash = () => {

		const {section} = this.props;
		const {hash} = window.location;
		const id = hash.replace("#", "");

		if(slugify(section.name.toLowerCase()) === id){
			this.setState({
				expanded: true,
			});
		}
    };

    componentDidMount(): void {

        this.checkHash();

    }

    /**
     * Final Render
     * @returns {*}
     */
    render() {

        const {
            section,
            items,
            hideIfEmpty
        } = this.props;

        if (hideIfEmpty && items.count() === 0) {

            return null;
        }

        let visited = false;

        if (localStorage.getItem("downloads-" + section.id) === "true") {
            visited = true;
        }

        return <div className="file-section-item" id={slugify(section.name.toLowerCase())}>
            <Row>
                <Col xs={12} lg>
                    <h2 onClick={this.handleSectionClick} className={visited ? "visited" : ""}>{section.name} <span
                        style={{
                            fontSize: "1rem",
                            display: "inline-block",
                            transform: "translateY(-4px)"
                        }}>({items.count()})</span>
                    </h2>
                </Col>
                <Col xs={12} lg>
                    <div className="file-section-item__description">
                        {section.description}
                    </div>
                </Col>
            </Row>
            {this.state.expanded && <div className="file-section-item__content">
                {items.orderBy(["ordering"], ["asc"]).toModelArray().map(item => {
                    return <FileItem key={item.id} file={item} allowOptions={false}/>;
                })}
            </div>}
        </div>;
    }
}

export default withTranslation()(FileSectionItem);