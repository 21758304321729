// @flow

import PropTypes from "prop-types";
import {attr, fk} from "redux-orm";

import CRUDModel from "../ORM/CRUDModel";

/**
 * @class ReleaseNote
 */
export default class ReleaseNote extends CRUDModel {}

ReleaseNote.modelName = "ReleaseNote";

ReleaseNote.fields = {
	id: attr(), // non-relational field for any value; optional but highly recommended
	subject: fk("ReleaseNoteSubject"),
};

ReleaseNote.propTypes = {
	name: PropTypes.string,
};

export const ADD_RELEASE_NOTE = "ADD_RELEASE_NOTE";
export const REMOVE_RELEASE_NOTE = "REMOVE_RELEASE_NOTE";
export const UPDATE_RELEASE_NOTE = "UPDATE_RELEASE_NOTE";