// @flow
import React from "react";
import {withTranslation} from "react-i18next";
import {Col, Grid, Row} from "react-flexbox-grid";
import {Link, withRouter} from "react-router-dom";
import {connect} from "react-redux";
import BackendRequest from "../../Models/REST";
import MasterGetter from "../../Models/Utils/MasterGetter";
import NewsItemAdmin from "../../Components/News/NewsItemAdmin/NewsItemAdmin";
import SearchBar from "../../Components/SearchBar/SearchBar";
import InstantAction from "../../Models/Utils/InstantAction";
import {setSearch} from "../../App/App.actions";
import LoadingComponent from "../../Components/LoadingComponent";

/**
 * @class NewsScene
 */
class NewsScene extends React.Component {

	/**
	 * State
	 * @type {{expectingResponse: boolean}}
	 */
	state = {
		expectingResponse: true,
	};

	/**
	 * Fetch Data
	 */
	fetchData = () => {

		BackendRequest({
			endpoint: "editor/news",
			self: this,
		});

	};

	/**
	 * Component did Mount
	 */
	componentDidMount = () => {

		this.fetchData();

	};

	/**
	 * Clean up
	 */
	componentWillUnmount(): void {
		InstantAction.dispatch(setSearch(""));
	}

	/**
	 * Final Render
	 * @returns {*}
	 */
	render() {

		const {
			news,
		} = this.props;

		return (

			<Grid fluid>
				<Row>
					<Col xs={12}>
						<h1>News</h1>
						<SearchBar/>
						<div>
							<Link to={"/editor/news/item"} className="btn">Create New</Link>
						</div>
						<br/>
						{this.state.expectingResponse && <LoadingComponent />}
						{news.toRefArray().map(item => {
							return <NewsItemAdmin key={item.id} news={item}/>;
						})}
						<div>
							{((news.count() === 0) && (!this.state.expectingResponse)) && "No news"}
						</div>
					</Col>
				</Row>
			</Grid>

		);
	}

}

/**
 * Map state to props
 * @param state
 * @return {{entities: {}}}
 */
const mapStateToProps = state => {

	const session = MasterGetter.getSession(state.entities);
	const appSearch = state.app.search;
	const news = session.News.all().filter(item => {

		if (item.perex && appSearch) {
			return item.perex.toLowerCase().includes(appSearch.toLowerCase());
		}else{
			return true;
		}

	}).orderBy(["published"], ["desc"]);

	return {
		news: news,
	};
};

/**
 * Exporting part of the React.Component file
 */
export default withRouter(connect(mapStateToProps)(withTranslation()(NewsScene)));