// @flow
import React from "react";
import {withTranslation} from "react-i18next";
import {Col, Grid, Row} from "react-flexbox-grid";
import DPACalculator from "../../Components/DPACalculator/DPACalculator";
import {Helmet} from "react-helmet";

/**
 * @class TechnologyDPACalculatorScene
 */
class TechnologyDPACalculatorScene extends React.Component {


    /**
     * Final Render
     * @returns {*}
     */
    render() {

        return (
            <div className={"page"}>
                <Helmet>
                    <title>DPA - Calculator - IQRF</title>
                </Helmet>
                <Grid>
                    <Row className="page__section--with-margin">
                        <Col>
                            <DPACalculator/>
                        </Col>
                    </Row>
                </Grid>
            </div>
        );
    }

}

/**
 * Exporting part of the React.Component file
 */
export default withTranslation()(TechnologyDPACalculatorScene);