// @flow
import React from "react";
import {withTranslation} from "react-i18next";
import {Col, Grid, Row} from "react-flexbox-grid";
import pdf_ico from "../../App/extra-icons/pdf_ico.png";
import MasterGetter from "../../Models/Utils/MasterGetter";
import Markdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import rehypeSanitize from "rehype-sanitize";
import remarkGfm from "remark-gfm";
import BackendRequest from "../../Models/REST";
import {Helmet} from "react-helmet";

/**
 * @class WebinarsScene
 */
class WebinarsScene extends React.Component {

	/**
	 * State
	 * @type {{expectingResponse: boolean}}
	 */
	state = {
		expectingResponse: true,
	};

	/**
	 * Fetch data from REST API
	 */
	fetchData = () => {
		BackendRequest({
			endpoint: "webinars",
			self: this,
		});
	};

	/**
	 * Component did mount
	 */
	componentDidMount(): void {
		this.fetchData();
	}

	/**
	 * Final Render
	 * @returns {*}
	 */
	render() {

		const {
			entities
		} = this.props;

		const session = MasterGetter.getSession(entities);
		const webinars = session.Webinar.all().filter(item => !item.is_archived && item.is_online).orderBy(["ordering"], ["desc"]);

		return (
			<div className={"page"}>
				<Helmet>
					<title>Webinars - IQRF</title>
				</Helmet>
				<Grid>
					<Row className="page__section--bottom-padding">
						<Col xs={12}>
							<h1>Webinars</h1>
							<h2>Interactive on-line IQRF workshops</h2>
						</Col>
						{webinars.count() === 0 ?
							<Col xs={12}>
								<span style={{fontSize: "1.5rem"}}><span className="text--orange">Next webinar:</span> coming later on</span>
							</Col>
							: <Col xs={12}>
								{webinars.toModelArray().map(item => {
									return <Row>
										<Col xs={12}>
											<h3>{item.title}</h3>
											<Markdown
												rehypePlugins={[rehypeRaw, rehypeSanitize]}
												remarkPlugins={[remarkGfm]}
												children={item.description}
											/>
										</Col>
									</Row>;
								})}
							</Col>
						}
					</Row>
				</Grid>
				<div className="page__section--with-theme">
					<Grid>
						<Row>
							<Col xs={12}>
								<div className="table--wrapper">
									<table className="table--with-links">
										<tbody>
										<tr>
											<td><a href="https://www.youtube.com/watch?v=DNk-JpEmkJk" rel="noopener noreferrer"
												   target="_blank"><i className="icon-youtube-logo"/> IQRF for integrators</a>, in English (18 Jan 2018)
											</td>
										</tr>
										<tr>
											<td><a href="https://www.youtube.com/watch?v=2UyG1rVDi9Q" rel="noopener noreferrer"
												   target="_blank"><i className="icon-youtube-logo"/> IQRF for integrators</a>, in Czech (18 Jan 2018)
											</td>
										</tr>
										<tr>
											<td><a href="https://www.iqrfalliance.org/data_files/news/iqrf-marco.pdf"
												   rel="noopener noreferrer" target="_blank"><img alt="icon" src={pdf_ico}/> AAEON - IQRF Solutions for IoT</a>
												, by Marco Barbato, Europe, Product & technical director AAEON Europe (1 Sep 2017)
											</td>
										</tr>
										<tr>
											<td><a href="https://www.iqrfalliance.org/data_files/news/iqrf-simon.pdf"
												   rel="noopener noreferrer" target="_blank"><img alt="icon" src={pdf_ico}/> IQRF - Technology, ecosystem and community for IoT</a>
												, by Simon Chudoba, IQRF Alliance CEO (1 Sep 2017)
											</td>
										</tr>
										<tr>
											<td><a href="https://www.youtube.com/watch?v=Jk1rZGePSkI&t=35s"
												   rel="noopener noreferrer" target="_blank"><i className="icon-youtube-logo"/> New IQRF OS v4.00D with ultimate security</a>
												, in English (30 Mar 2017)
											</td>
										</tr>
										<tr>
											<td><a href="https://www.iqrfalliance.org/data_files/news/2017-03-30-iqrf---os-4.0-webinar.pdf"
												   rel="noopener noreferrer" target="_blank"><img alt="icon" src={pdf_ico}/> New IQRF OS v4.00D with ultimate security</a>
												, in English (30 Mar 2017)
											</td>
										</tr>
										<tr>
											<td><a href="https://www.youtube.com/watch?v=WC_9vx6gOhU&t=4s"
												   rel="noopener noreferrer" target="_blank"><i className="icon-youtube-logo"/> New IQRF OS v4.00D with ultimate security</a>
												, in Czech (30 Mar 2017)
											</td>
										</tr>
										</tbody>
									</table>
								</div>
							</Col>
						</Row>
					</Grid>
				</div>
			</div>

		);
	}

}

/**
 * Exporting part of the React.Component file
 */
export default withTranslation()(WebinarsScene);
