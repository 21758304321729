// @flow
import React from "react";
import {withTranslation} from "react-i18next";
import {Col, Grid, Row} from "react-flexbox-grid";
import "../../Components/Technology/Technology.scss";
import {Helmet} from "react-helmet";
import {Link} from "react-router-dom";

/**
 * @class TechnologyIDEScene
 */
class TechnologyLowPowerScene extends React.Component {

  /**
   * Constructor
   * @param props
   */
  constructor(props) {
    super(props);

    this.state = {
      showContentSupportedGUI: false,
      showContentGUI: false,
      showContentCommand: false,
      supportedCommand: false,
    };
  }

  /**
   * Final Render
   * @returns {*}
   */
  render() {
    return (
      <Grid className={"page"}>
        <Helmet>
          <title>Low power modes - IQRF</title>
        </Helmet>
        <Row>
          <Col xs={12}>
            <h1>Low power modes</h1>
            <h2>IQRF wireless is extremely low power</h2>
            <p>
              IQRF is outstandingly suitable for <strong>battery-operated</strong> devices.
            </p>
            <table className="lowpower__table">
              <tbody>
              <tr>
                <th><strong>Mode</strong></th>
                <th><strong>Operation</strong></th>
                <th><strong>Time spent</strong></th>
                <th><strong>Requirements</strong></th>
                <th><strong>Importance</strong></th>
              </tr>
              <tr>
                <td><strong>Transmit</strong> (<strong>TX</strong>)</td>
                <td>TR is transmitting an RF packet</td>
                <td>Very short periods</td>
                <td>As low as possible, but given mostly by RF output power</td>
                <td>Important</td>
              </tr>
              <tr>
                <td><strong>Receive</strong> (<strong>RX</strong>)</td>
                <td>TR is receiving or attempting to detect incoming RF packet</td>
                <td><strong>Majority of non-sleeping time</strong></td>
                <td><span className="text--orange">As low as possible</span></td>
                <td><span className="text--orange">Crucial</span></td>
              </tr>
              <tr>
                <td><strong>Run</strong></td>
                <td>TR is operating (either with or without RF circuitry sleeping) but no RX or TX is in progress</td>
                <td>Negligible</td>
                <td>Not critical</td>
                <td>Negligible</td>
              </tr>
              <tr>
                <td><strong>Sleep</strong></td>
                <td>No operation (including incoming RF signal detection) is possible until TR wakes up.</td>
                <td><strong>Majority of time</strong></td>
                <td><span className="text--orange">1 &micro;A</span> order of magnitude for battery life of years</td>
                <td><span className="text--orange">Necessary</span></td>
              </tr>
              <tr>
                <td><strong>Deep sleep</strong></td>
                <td>
                  No operation (including incoming RF signal detection) is possible. Complete initialization of RF
                  circuitry must be performed after TR awakening.
                </td>
                <td>Very long periods (if applicable)</td>
                <td>
                  <span className="text--orange">&lt; 100 nA</span>. Extremely low power in very long periods of
                  inactivity. <strong>In special applications only</strong>.
                </td>
                <td>Either not critical or crucial</td>
              </tr>
              </tbody>
            </table>
            <p>&nbsp;</p>
            <h2>IQRF power consumption</h2>
            <h3>Sleep</h3>
            <p><strong>Tens</strong> of <strong>nano Amperes</strong> in Deep sleep and <strong>hundreds</strong> of <strong>nano Amperes</strong> in Sleep.</p>
            <h3>RX power saving modes</h3>
            <p>To optimize the consumption in this most crucial mode (RX, see the table above) for various needs, three RX modes are available:</p>
            <ul>
              <li>
                <strong>STD</strong> (Standard)
              </li>
              <li><strong>LP</strong> (Low Power)</li>
              <li>
                <strong>XLP</strong> (Extra Low Power). This mode is not available in <Link to="/technology/iqmesh/">IQMESH</Link>. For IQMESH, other <Link to="/technology/low-power-modes#Advanced_SW_methods">advanced SW methods</Link> for low power are available.
              </li>
            </ul>
          </Col>
        </Row>
        <Row>
          <Col xl={6} xs={6}>
            <p><strong>TR-76D power consumption parameters</strong></p>
            <ul>
              <li>Deep sleep mode: &lt; 100 nA</li>
              <li>Sleep mode: <strong> &lt; 1 &micro;A</strong></li>
              <li>Run mode
                <ul>
                  <li>RF sleep: 1.6 mA</li>
                  <li>RF ready: 3.0 mA</li>
                </ul>
              </li>
              <li>RX mode
                <ul>
                  <li>STD: 11.8 mA</li>
                  <li>LP: 250 &micro;A</li>
                  <li>XLP: <strong>16.3 &micro;A</strong></li>
                </ul>
              </li>
              <li>TX mode: 8.3 mA - 25 mA (according to RF output power)</li>
            </ul>
            <p className="text--orange">Look at other platforms boasting ultra / extra low power</p>
          </Col>
          <Col xl={6} xs={6}>
            <p>&nbsp;</p>
            <div className="lowpower-frame">
              <p>IQRF <strong>lifetime</strong>* for 1 Ah, 3.6 V, 1/2 AA sized <strong>battery</strong></p>
              <ul>
                <li><strong>1 000 years</strong> in Deep <strong>sleep</strong></li>
                <li><strong>7 years</strong> continually <strong>receiving</strong> in XLP <sup>**</sup></li>
                <li><strong>300 MB</strong> data <strong>received</strong></li>
                <li><strong>200 MB</strong> data <strong>transmitted</strong> (at highest RF output power)</li>
              </ul>
              <p><i>* Theoretical values</i></p>
              <p><i>** Without an incoming RF signal including a noise</i></p>
            </div>
          </Col>
        </Row>
        <Row className="page__section--bottom-padding">
          <Col xs={12}>
            <hr/>
            <p>
              TR-76D power consumption measured by <a href="http://www.htest.cz/en/" target="_blank"
                                                      rel="noopener noreferrer">H&nbsp;TEST</a>, an independent company
              specialized in advanced tests and precise measurements:</p>
            <img src="https://api.iqrf.org/static/media/img/web/30d67812ef1051763e3993a6d0ee2508.jpg"
                 alt="tr-76d-consumption-htest.jpg"/>
            <br/>
            <p>
              <i>Measuring equipment: Keysight N6705B DC power analyzer with N6785A source/measure unit for battery
                drain analysis.</i>
            </p>
          </Col>
        </Row>
        <Row className="page__section--bottom-padding">
          <Col xs={12}>
            <hr/>
            <h2 id="Advanced_SW_methods">Advanced SW methods for low power</h2>
            <p>For IQMESH, various advanced SW methods for further power consumption lowering are available:</p>
            <ul>
              <li>Receiving in <strong>LP</strong> combined with <strong>sub-µA precisely timed sleeping</strong></li>
              <li>The most power-saving operational mode for sensors is <Link to="/technology/iqmesh/offline-frc"><strong>Beaming</strong></Link>.</li>
            </ul>
            <h3>Beaming</h3>
            <p>Data asynchronously sent from <Link to="/technology/iqmesh/offline-frc">Beaming</Link> sensors with outstandingly low power (enabling <strong>battery lifetime for years</strong>).</p>
            <p>&nbsp;</p>
          </Col>
        </Row>
      </Grid>
    );
  }
}

/**
 * Exporting part of the React.Component file
 */
export default withTranslation()(TechnologyLowPowerScene);
