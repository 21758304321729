// @flow
import React from "react";
import {withTranslation} from "react-i18next";
import {Col, Grid, Row} from "react-flexbox-grid";
import MasterGetter from "../../Models/Utils/MasterGetter";
import {AnchorText, LoadingComponent} from "../../Components";
import {SeriesItem} from "../../Components/Products";
import {NavLink, withRouter} from "react-router-dom";
import {connect} from "react-redux";
import slugify from "slugify";
import InstantAction from "../../Models/Utils/InstantAction";
import {Helmet} from "react-helmet";

/**
 * @class ProductSubcategoryArchiveScene
 */
class ProductSubcategoryArchiveScene extends React.Component {

	/**
	 * State
	 * @type {{expectingResponse: boolean}}
	 */
	state = {
		expectingResponse: false,
	};

	/**
	 * Final Render
	 * @returns {*}
	 */
	render() {

		const {
			subcategory,
			series,
		} = this.props;


		return (
			<Grid className={"page"}>
				<Helmet>
					<title>{subcategory.name} - IQRF</title>
				</Helmet>
				<Row>
					<Col xs={12} className="page__section--bottom-padding">
						<h1><AnchorText text={subcategory.name}/></h1>
						<div>
							{this.props.expectingResponse && <LoadingComponent/>}
							{series.toModelArray().map(item => {
								return <SeriesItem key={item.id} series={item}/>;
							})}
							{((series.count() === 0) && !this.state.expectingResponse) &&
							<div>No products in this section</div>}
						</div>
						<div className="text--center">
							<br/>
							<NavLink to={"/products/" + subcategory.category.friendly_url() + "/" + subcategory.friendly_url()} className={"btn"}>Back from Archive</NavLink>
						</div>
					</Col>
				</Row>
			</Grid>
		);
	}
}

const mapStateToProps = (state, props) => {

	const session = MasterGetter.getSession(state.entities);
	const subcategoryNameSlug = props.match.params.subcategory;
	const subcategory = session.ProductSubcategory.filter(item => {
			return (slugify(item.name).toLowerCase() === subcategoryNameSlug);
		}
	).first();

	if (subcategory) {

		let subcategoryId = subcategory.id;
		const series = session.ProductSeries.all().filter(series => {
			return series.subcategory === subcategoryId && series.is_online && series.is_archived;
		}).orderBy(["ordering"], ["desc"]);

		const sections = session.ProductSubcategorySection.all().filter(item => item.subcategory === subcategoryId);

		return {
			subcategoryId: subcategoryId,
			sections: sections,
			subcategory: subcategory,
			series: series,
		};
	} else {
		InstantAction.redirect("/error-404");
		return {};
	}

};

/**
 * Exporting part of the React.Component file
 */
export default withRouter(connect(mapStateToProps)(withTranslation()(ProductSubcategoryArchiveScene)));