// @flow
import React from "react";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import {withRouter} from "react-router-dom";


/**
 * @class SettingsScene
 */
class SettingsScene extends React.Component {

    /**
     * Final Render
     * @returns {*}
     */
    render() {

        const {t} = this.props;

        return (
            <div>
                <div className={"page"}>
                    <h1>{t("title.Settings")}</h1>

                </div>
            </div>
        );
    }

}

/**
 * This function maps the state to a
 * prop called `state`.
 *
 * In larger apps it is often good
 * to be more selective and only
 * map the part of the state tree
 * that is necessary.
 */
const mapStateToProps = state => (
    {
        app: state.app,
    });

/**
 * Exporting part of the React.Component file
 */
export default withRouter(connect(mapStateToProps)(withTranslation()(SettingsScene)));