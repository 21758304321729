// @flow

// Necessary Evil
import React from "react";
import PropTypes from "prop-types";
import {withTranslation} from "react-i18next";
import Markdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import rehypeSanitize from "rehype-sanitize";
import remarkGfm from "remark-gfm";
import {Col} from "react-flexbox-grid";
import {CSSTransition, TransitionGroup} from "react-transition-group";
import {AnchorText} from "../../index";

// Import component CSS


/**
 * @class ProductOptionalBox
 */
class ProductOptionalBox extends React.Component {

	/**
	 * PropTypes
	 * @type {{children: shim}}
	 */
	static propTypes = {
		product: PropTypes.any,
		boxNumber: PropTypes.number,
	};

	/**
	 * State
	 * @type {{open: boolean}}
	 */
	state = {
		open: false,
	};

	/**
	 * Final Render
	 * @returns {*}
	 */
	render() {

		const {
			product,
			boxNumber,
		} = this.props;

		const title = product[("box_" + boxNumber + "_title")];
		const content = product[("box_" + boxNumber + "_content")];
		const openMode = product[("box_" + boxNumber + "_open")];

		if (!content) {
			return null;
		}

		if (openMode === 1) {
			/**
			 * OnClick view
			 */
			return <Col xs={12} className={"product__optional-content-wrapper "+ (this.state.open ? "open" : "")}>
				<span className="text--clickable link" onClick={() => {
					this.setState({
						open: !this.state.open
					});
				}}>&raquo; <AnchorText text={title}/></span>
				<div className={"product__optional-content-toggle" + (this.state.open ? " show" : "")}>
					<TransitionGroup component={null}>
						{this.state.open &&
						<CSSTransition classNames="dialog" timeout={300}>
							<div>
								<Markdown
									rehypePlugins={[rehypeRaw, rehypeSanitize]}
									remarkPlugins={[remarkGfm]}
									children={content}
								/>
								<hr/>
							</div>
						</CSSTransition>
						}
					</TransitionGroup>
				</div>
			</Col>;
		} else {
			/**
			 * Normal view
			 */
			return <Col xs={12} className="product__optional-content-wrapper">
				<h2>{title}</h2>
				<Markdown
					rehypePlugins={[rehypeRaw, rehypeSanitize]}
					remarkPlugins={[remarkGfm]}
					children={content}
				/>
				<hr/>
			</Col>;
		}
	}
}

export default withTranslation()(ProductOptionalBox);
