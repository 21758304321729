// @flow

// Necessary Evil
import React from "react";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import {withRouter} from "react-router-dom";
import BackendRequest from "../../Models/REST";
import MasterGetter from "../../Models/Utils/MasterGetter";
import Markdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import rehypeSanitize from "rehype-sanitize";
import remarkGfm from "remark-gfm";
import moment from "moment";

import "./NewsLoader.scss";

/**
 * @class NewsLoader
 */
class NewsLoader extends React.Component {

	/**
	 * State
	 * @type {{expectingResponse: boolean}}
	 */
	state = {
		expectingResponse: true,
	};

	/**
	 * Fetch data from REST API
	 */
	fetchData = () => {

		BackendRequest({
			endpoint: "news/6",
			self: this,
		});
	};

	/**
	 * Component did mount
	 */
	componentDidMount() {
		this.fetchData();
	}

	/**
	 * Final Render
	 * @returns {*}
	 */
	render() {
		const {entities} = this.props;
		const session = MasterGetter.getSession(entities);
		const news = session.News.all().filter(item => {
			return item.is_online && moment(item.published) < moment();
		}).orderBy(["published"], ["desc"]);
		let limit = 7;


		return (
			<div className="news-loader">
				<ul>
					{news.toModelArray().map(item => {
						if (--limit <= 0) {
							return null;
						} else {
							return <li className="news-loader__item" key={item.id}>
								<div className="news-loader__content">
									<Markdown
										rehypePlugins={[rehypeRaw, rehypeSanitize]}
										remarkPlugins={[remarkGfm]}
										children={item.perex}
									/>
								</div>
								<span className="news-loader__posted">{moment(item.published).format("D MMM YYYY")}</span>
							</li>;
						}
					})}
					{news.count() === 0 && <div>No news available</div>}
				</ul>
			</div>
		);
	}

}

/**
 * This function maps the state to a
 * prop called `state`.
 * In larger apps it is often good
 * to be more selective and only
 * map the part of the state tree
 * that is necessary.
 */
const mapStateToProps = state => (
	{
		app: state.app,
		entities: state.entities,
	});

/**
 * Exporting part of the React.Component file
 */
export default withRouter(connect(mapStateToProps)(withTranslation()(NewsLoader)));
