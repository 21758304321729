// @flow

// Necessary Evil
import React from "react";
import PropTypes from "prop-types";
import {withTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import {Col} from "react-flexbox-grid";
import "./EventAdminItem.scss";
import Markdown from "react-markdown";
import remarkGfm from "remark-gfm";

/**
 * @class EventAdminItem
 */
class EventAdminItem extends React.Component {

	/**
	 * PropTypes
	 * @type {{children: shim}}
	 */
	static propTypes = {
		event: PropTypes.any.isRequired,
	};

	/**
	 * Final Render
	 * @returns {*}
	 */
	render() {
		const {
			event
		} = this.props;

		return <Link to={"/editor/event/item/" + event.id}>
			<Col xs={12} className="event-item-admin">
				<div>
					<i className="icon-event"/>&nbsp;
				</div>
				<div className="event-item-admin__name">
					{event.ordering}.
					<Markdown remarkPlugins={[remarkGfm]} children={event.name}/>
					<div className="event-item-admin__description">
						<Markdown remarkPlugins={[remarkGfm]} children={event.description}/>
					</div>
				</div>
				<div className="event-item-admin__options">{event.starts} {!event.starts && "Start not set"}</div>
			</Col>
		</Link>;
	}
}

export default withTranslation()(EventAdminItem);
