// @flow
import React from "react";
import {withTranslation} from "react-i18next";
import {Col, Grid, Row} from "react-flexbox-grid";
import network_diagram from "../../Components/Technology/img/Network-encryption-diagram.svg";
import access_diagram from "../../Components/Technology/img/Access-encryption-diagram.svg";
import {Helmet} from "react-helmet";

/**
 * @class TechnologySecurityScene
 */
class TechnologySecurityScene extends React.Component {


  /**
   * Final Render
   * @returns {*}
   */
  render() {

    return (
        <div className={"page"}>
            <Helmet>
                <title>IQRF security</title>
            </Helmet>
            <Grid>
                <Row className="page__section--bottom-padding">
                    <Col xs={12}>
                        <h1>IQRF security</h1>
                        <p>Every wireless system is exposed to potential OTA attacks. IQRF OS v4.00 brings ultimate
                            security based on industrial standards. <strong>Security in IQRF does not mean just an encryption but a complex approach to avoid
                                potential vulnerabilities.</strong></p>
                        <ul>
                            <li><strong>Packets consistency checking</strong><br/>
                                Security starts at basic trust to received data. To avoid transmission errors and
                                process valid data only, IQRF strictly checks all received packets against bit error
                                failures using several block checksums. Additionally, complete packet is protected by
                                standard IBM CRC-16.
                            </li>
                            <li><strong>Encryption</strong><br/>
                Sensitive data should be hidden for unauthorized users and systems. Encryption is a way how to protect such sensitive data. IQRF utilizes AES-128 encryption, industrial standard for wireless communication. Besides hiding sensitive data, control data encryption increases consistency protection and prevents packets forging. There are three different protections based on AES-128 encryption.</li>
            <li id="access-password"><strong>Unauthorized access protection</strong><br/>
                Only authorized devices may join the network and only authorized users may have maintenance access to network devices. As communication during bonding and maintenance is encrypted by AES-128, only authorized devices and users with valid  <strong>Access Password</strong> are able to join the network or to maintain network devices. Sensitive data, such as network Passwords, exchanged encrypted during bonding, are well protected.</li>
            <li><strong>Network communication protection</strong><br/>
                Protection of standard network communication is the most important as it continues during the whole system life. Protection is based on AES-128 encryption standard. Thus, only systems/users having valid network Password may join network communication and process data. To avoid wrong implementations all network communication has been forced to be encrypted automatically. Besides encryption, checking of expired / re-used packets was added.</li>
            <li><strong> User’s data protection</strong><br/>
                Besides protection of network communication there is an optional way to increase privacy by adding another encryption shield. It utilizes AES-128 and userKey defined by the user to protect sensitive user’s data. This protection is fully under user’s control and may be used  to add extra network protection or to hide sensitive data even from the IQRF platform as shown in the schematics below.</li>
            <li><strong>Passwords and Keys manipulation</strong><br/>
                Keys compromising is very frequent source of security problems. Therefore IQRF minimized and protected also manipulation with keys - network and access keys are not known during physical manipulation as they are generated from respective passwords. Network password is generated randomly with high entropy. The password is provided to devices that are joining the network and that are being encrypted during bonding.</li>
            <li><strong>Future upgrades</strong><br/>
                Threats coming in the future from more powerful attacking tools and from discovered vulnerabilities should be fixed. All IQRF software layers enable upgrades to keep system secure. </li>
          </ul>
          <p><strong>Protection during bonding (simplified)</strong></p>
          <div className="text--center">
            <img src={access_diagram} alt={access_diagram}/>
          </div>
          <p><strong>Protection during networking communication (simplified)</strong></p>
          <div className="text--center">
            <img src={network_diagram} alt={network_diagram}/>
          </div>
    </Col>
      </Row>
      </Grid>
      </div>

  );
  }

}

/**
 * Exporting part of the React.Component file
 */
export default withTranslation()(TechnologySecurityScene);