// @flow

// Necessary Evil
import React from "react";
import PropTypes from "prop-types";
import {withTranslation} from "react-i18next";
// Import component CSS
import "./CareerItem.scss";
import Markdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import rehypeSanitize from "rehype-sanitize";
import remarkGfm from "remark-gfm";


/**
 * @class CareerItem
 */
class CareerItem extends React.Component {

    /**
     * PropTypes
     * @type {{children: shim}}
     */
    static propTypes = {
        offer: PropTypes.any.isRequired,
    };

    /**
     * Final Render
     * @returns {*}
     */
    render() {

        const {offer} = this.props;

        return <div className="career-item">
            <h2>{offer.name}</h2>
            <Markdown
              rehypePlugins={[rehypeRaw, rehypeSanitize]}
              remarkPlugins={[remarkGfm]}
              children={offer.description}
            />
        </div>;
    }
}

export default withTranslation()(CareerItem);
