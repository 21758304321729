// @flow
import React from "react";
import {withTranslation} from "react-i18next";
import {Col, Grid, Row} from "react-flexbox-grid";
import BackendRequest from "../../Models/REST/BackendRequest";
import {connect} from "react-redux";
import MasterGetter from "../../Models/Utils/MasterGetter";
import Markdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import rehypeSanitize from "rehype-sanitize";
import {Helmet} from "react-helmet";
import remarkGfm from "remark-gfm";


/**
 * @class GetStartedStartupPackageHistoryScene
 */
class GetStartedStartupPackageHistoryScene extends React.Component {

	/**
	 * State
	 * @type {{releaseNotes: []}}
	 */
	state = {
		releaseNotes: []
	};

	/**
	 *
	 */
	fetchData = () => {

		BackendRequest({
			endpoint: "startup-package-history",
			self: this,
		});

	};

	/**
	 *
	 */
	componentDidMount() {

		this.fetchData();

	}


	/**
	 * Final Render
	 * @returns {*}
	 */
	render() {

		const {
			entities
		} = this.props;
		const session = MasterGetter.getSession(entities);
		const historyNotes = session.StartupPackageHistory.all().orderBy(["ordering"], ["desc"]);

		return (<Grid className={"page"}>
				<Helmet>
					<title>IQRF Startup package release notes - IQRF</title>
				</Helmet>
				<Row className="page__section--bottom-padding">
					<Col xs={12}>
						<h1>IQRF Startup package release notes</h1>
						{historyNotes.toModelArray().map(notes => {
							return <div>
								<h2>
									<Markdown
										rehypePlugins={[rehypeRaw, rehypeSanitize]}
										remarkPlugins={[remarkGfm]}
										children={notes.title}
									/>
								</h2>
								<Markdown
									rehypePlugins={[rehypeRaw, rehypeSanitize]}
									remarkPlugins={[remarkGfm]}
									children={notes.description}
								/>
							</div>;
						})}
					</Col>
				</Row>
			</Grid>
		);

		// return (<Grid className={"page"}>
		// 		<Row className="page__section--bottom-padding">
		// 			<Col xs={12}>
		// 				<h1>IQRF Startup package release notes</h1>
		// 				<h2>16 Oct 2019 - For OS v4.03D, DPA v4.10 and IDE v4.54 at TR-7xD</h2>
		// 				<p>Updated for DPA v4.10 and IQRF IDE v4.54.</p>
		// 				<ul>
		// 					<li><strong>DPA v4.03</strong> <a href="https://doc.iqrf.org/DpaTechGuide/pages/dpa-410.html" rel="noopener noreferrer" target="_blank"> Release notes</a></li>
		// 					<li><strong>IQRF IDE v4.53</strong> <a href="https://doc.iqrf.org/IQRF-IDE-Release-Notes" rel="noopener noreferrer" target="_blank"> Release notes</a></li>
		// 					<li>Documentation:
		// 						<ul>
		// 							<li>Datasheets for TR-72D, TR-76D, TR-75D, and TR-77D updated.</li>
		// 							<li>IQMESH Network Deployment Technical guide updated.</li>
		// 							<li>IQRF OS User's guide updated.</li>
		// 							<li>IQRF OS Reference Guide updated.</li>
		// 							<li>DK-EVAL-04A User's guide updated.</li>
		// 						</ul>
		// 					</li>
		// 				</ul>
		// 				<p>&nbsp;</p>
		// 				<h2>27 Jun 2019 - For OS v4.03D, DPA v4.03 and IDE v4.53 at TR-7xD</h2>
		// 				<p>Updated for DPA v4.03 and IQRF IDE v4.53.</p>
		// 				<ul>
		// 					<li>DPA v4.03:
		// 						<ul>
		// 							<li>New features:
		// 								<ul>
		// 									<li>Newly documented command RAM Read Any.</li>
		// 								</ul>
		// 							</li>
		// 							<li>Bugs fixed:
		// 								<ul>
		// 									<li>Fixed an issue introduced at DPA V4.02 when any of MemoryRead+1 FRC
		// 										commands also executed an DPA Request from bufferAUX (e.g. stored from
		// 										the previous FRC Acknowledged Broadcast).
		// 									</li>
		// 								</ul>
		// 							</li>
		// 						</ul>
		// 					</li>
		// 					<li>IQRF IDE v4.53:
		// 						<ul>
		// 							<li>Bugs fixed
		// 								<ul>
		// 									<li>Project file loading under Windows 10 optimized.</li>
		// 									<li>IQMESH Network Manager / Upload used different External EEPROM Address
		// 										than specified under certain circumstances.
		// 									</li>
		// 									<li>Minor repairs and improvements.</li>
		// 								</ul>
		// 							</li>
		//
		// 							<li>Changes and enhancements
		// 								<ul>
		// 									<li>Terminal Log and Communication Log window autosave implemented.</li>
		// 									<li>DPA 4.03 supported.</li>
		// 									<li>Project Options:
		// 										<ul>
		// 											<li>Possibility to select the section in the Project window where
		// 												the selected item is automatically scrolled into view after the
		// 												IQRF IDE starts.
		// 											</li>
		// 										</ul>
		// 									</li>
		// 								</ul>
		// 							</li>
		// 						</ul>
		// 					</li>
		// 				</ul>
		// 				<hr/>
		// 				<h2>7 Jun 2019 - For OS v4.03D, DPA v4.02 and IDE v4.52 at TR-7xD</h2>
		// 				<p>Updated for DPA v4.02, IQRF IDE v4.52 and IQRF IDE Command v1.05.</p>
		// 				<ul>
		// 					<li>DPA v4.02:
		// 						<ul>
		// 							<li>New features:
		// 								<ul>
		// 									<li>New FRC command Memory read 4 bytes.</li>
		// 								</ul>
		// 							</li>
		// 							<li>Bugs fixed:
		// 								<ul>
		// 									<li>Fixed an issue when custom bonding of the STD [N] implemented in the
		// 										Reset event did not store valid RF mode. Therefore the [N] did receive
		// 										and transmit well.
		// 									</li>
		// 								</ul>
		// 							</li>
		// 						</ul>
		// 					</li>
		// 					<li>IQRF IDE v4.52:
		// 						<ul>
		// 							<li>Changes and enhancements:
		// 								<ul>
		// 									<li>Content of the Terminal Log and Communication Log window can be
		// 										saved/opened to/from a file.
		// 									</li>
		// 									<li>The IQRF Code Tool allows to encode/decode the datablock and text code
		// 										part according to the last IQRF Code specification.
		// 									</li>
		// 									<li>Microsoft Visual Studio 2019 editor supported.</li>
		// 								</ul>
		// 							</li>
		// 							<li>Features added:
		// 								<ul>
		// 									<li>The AutoNetwork feature added in the IQMESH Network Manager. The
		// 										progress of the AutoNetwork process is displayed in the Communication
		// 										Log window.
		// 									</li>
		// 								</ul>
		// 							</li>
		// 						</ul>
		// 					</li>
		// 					<li>IQRF IDE Command v1.05:
		// 						<ul>
		// 							<li>Changes and enhancements:
		// 								<ul>
		// 									<li>Decoding of IQRF Smart Connect code containing the DataBlock and Text
		// 										tag supported.
		// 									</li>
		// 								</ul>
		// 							</li>
		// 						</ul>
		// 					</li>
		// 				</ul>
		// 				<hr/>
		// 				<h2>12 Mar 2019 - For OS v4.03D, DPA v4.01 and IDE v4.51 at TR-7xD</h2>
		// 				<p>Updated for DPA v4.01, IQRF IDE v4.51 and IQRF IDE Command v1.04.</p>
		// 				<ul>
		// 					<li>DPA v4.01:
		// 						<ul>
		// 							<li>New features:
		// 								<ul>
		// 									<li>New command Test RF Signal.</li>
		// 								</ul>
		// 							</li>
		// 							<li>Bugs fixed:
		// 								<ul>
		// 									<li>Fixed an issue when Remove bond did not call Disable Interrupts event.
		// 										This might cause [N] not to restart. [N] then had to be restarted by
		// 										turning off and on.
		// 									</li>
		// 									<li>Fixed an issue when HDLC UART interface packet receiver might get out of
		// 										sync when HDLC Flag Sequence byte (0x7e) is not received. The receiver
		// 										then had to be restarted by turning device off and on.
		// 									</li>
		// 									<li>Fixed an issue when the Autonetwork V2 did not unbond unresponsive [Ns]
		// 										at the end of the each wave. It might result in duplicate addresses in
		// 										the network.
		// 									</li>
		// 								</ul>
		// 							</li>
		// 						</ul>
		// 					</li>
		// 					<li>IQRF IDE v4.51:
		// 						<ul>
		// 							<li>Bugs fixed:
		// 								<ul>
		// 									<li>Incorrect enumeration of IQRF Standard peripherals in some specific
		// 										peripheral combinations.
		// 									</li>
		// 									<li>Incorrect export of IQMESH Network Manager - Table View.</li>
		// 									<li>The TR configuration upload using F5, Shift+Ctrl+G, Shift+Ctrl+F10 or
		// 										file pop-up menu does not check valid RF band of uploaded TR module.
		// 									</li>
		// 								</ul>
		// 							</li>
		// 							<li>Minor repairs and improvements.</li>
		// 							<li>Changes and enhancements:
		// 								<ul>
		// 									<li>Connect only check box added in the CATS / DPA Service Mode window. Used
		// 										to connect to the DSM with failing Custom DPA Handler. It only connects
		// 										to the device without executing any further commands.
		// 									</li>
		// 									<li>IQRF Standard Light supported in the Packet Inspector.</li>
		// 									<li>The waiting time for the DPA response in the IQMESH Network Manager
		// 										prolonged from 200 ms to 500 ms.
		// 									</li>
		// 									<li>The Test RF Signal feature (IQMESH Network Manager / Control /
		// 										Maintenance) can be used also on the Coordinator.
		// 									</li>
		// 									<li>IQRF IDE checks if the RF mode of DPA plug-in and Custom DPA Handler
		// 										match.
		// 									</li>
		// 								</ul>
		// 							</li>
		// 						</ul>
		// 					</li>
		// 					<li>IQRF IDE Command v1.04:
		// 						<ul>
		// 							<li>Bugs fixed:
		// 								<ul>
		// 									<li>TR configuration upload does not check valid RF band of uploaded TR
		// 										module.
		// 									</li>
		// 								</ul>
		// 							</li>
		// 						</ul>
		// 					</li>
		// 				</ul>
		// 				<hr/>
		// 				<h2>20 Jan 2019 - For OS v4.03D, DPA v4.00 and IDE v4.50 at TR-7xD</h2>
		// 				<p>Updated for DPA v4.00 and IQRF IDE v4.50.</p>
		// 				<ul>
		// 					<li>DPA v4.00:
		// 						<ul>
		// 							<li>Changes and enhancements:
		// 								<ul>
		// 									<li>DPA plug-ins for [C] support both STD+LP and STD networks, thus DPA
		// 										Plug-in filename format was changed and the former specific STD and LP
		// 										plug-ins for [C] are not released anymore. New configuration bit.7 at TR
		// 										Configuration byte index 0x05 selects the network type the [C] controls.
		// 									</li>
		// 									<li>DPA plug-ins for [Ns] support either both or just STD+LP networks, thus
		// 										DPA Plug-in filename format was changed.
		// 									</li>
		// 									<li>When the [N] supports SPI or UART Interface then neither SPI nor UART
		// 										embedded peripherals are supported.
		// 									</li>
		// 									<li>Supported Interface type at [N] is controlled by the upload of the
		// 										appropriate DPA Plug-in, therefore the bit.1 at TR Configuration byte
		// 										index 0x05 was discarded.
		// 									</li>
		// 									<li>Remote bonding support removed.</li>
		// 									<li>Parameter BondingMask at Bond Node command renamed to BondingTestRetries
		// 										with the same meaning like in Smart Connect.
		// 									</li>
		// 									<li>FRC peripheral is always enabled at [C] regardless of the configuration
		// 										settings.
		// 									</li>
		// 									<li>Remove bond command also restarts the [N] (except in DSM).</li>
		// 									<li>Meaning of DPA value bit.7 was changed.</li>
		// 									<li>Discarded commands: CMD_COORDINATOR_REBOND_NODE,
		// 										CMD_COORDINATOR_DISCOVERY_DATA, and CMD_NODE_REMOVE_BOND_ADDRESS.
		// 									</li>
		// 									<li>When the [N] is successfully bonded using the button then the green LED
		// 										is lit for 0.5 seconds but does not wait for the button to be released
		// 										anymore.
		// 									</li>
		// 								</ul>
		// 							</li>
		// 							<li>New features:
		// 								<ul>
		// 									<li>New bit at Peripheral enumeration flags indicates actual IQMESH network
		// 										type.
		// 									</li>
		// 									<li>FRC command FRC_Ping replaces value of the former FRC_Prebonding
		// 										command.
		// 									</li>
		// 									<li>FRC command FRC_SupplyVoltage.</li>
		// 								</ul>
		// 							</li>
		// 						</ul>
		// 					</li>
		// 					<li>IQRF IDE v4.50:
		// 						<ul>
		// 							<li>Bugs fixed:
		// 								<ul>
		// 									<li>It was not possible to create DSM (CATS) connection with a TR module
		// 										with MID xxxxxxx2.
		// 									</li>
		// 									<li>Minor repairs and improvements.</li>
		// 								</ul>
		// 							</li>
		// 							<li>Changes and enhancements:
		// 								<ul>
		// 									<li>DPA v4.00 supported.</li>
		// 									<li>TR configuration allows the Network Type (STD or STD+LP) selection for
		// 										the Coordinator.
		// 									</li>
		// 									<li>Support of subordinated networks (supported in DPA 1.0 - 2.28)
		// 										cancelled.
		// 									</li>
		// 									<li>The reliability of DSM connection in noisy environment improved.</li>
		// 									<li>New version of compiler included: CC5X Version 3.7C FREE edition.</li>
		// 									<li>GW-GSM-02A FW 3.14 added to the IQRF Device Manager:
		// 										<ul>
		// 											<li>Optimization of algorithm with respect to prevent connectivity
		// 												issues with various GSM operators (T-Mobile).
		// 											</li>
		// 										</ul>
		// 									</li>
		// 								</ul>
		// 							</li>
		// 							<li>Features added:
		// 								<ul>
		// 									<li>New items in the IQMESH Network Manager / Perform Selected Operations
		// 										button:
		// 										<ul>
		// 											<li>Send FRC Ping</li>
		// 											<li>Send FRC Supply Voltage</li>
		// 											<li>Enumerate (preview)</li>
		// 										</ul>
		// 									</li>
		// 								</ul>
		// 							</li>
		// 						</ul>
		// 					</li>
		// 					<li>IQRF IDE Command v1.03:
		// 						<ul>
		// 							<li>Bugs fixed:
		// 								<ul>
		// 									<li>Incorrect setting of default RF channels for TR-7xDx-IL and TR-77Dx
		// 										module types using the Factory command.
		// 									</li>
		// 								</ul>
		// 							</li>
		// 							<li>Changes and enhancements:
		// 								<ul>
		// 									<li>Generating of IQRF Smart Connect code without HWPID parameter allowed.
		// 									</li>
		// 									<li>Decoding of IQRF Smart Connect code containing the NOP tag supported.
		// 									</li>
		// 									<li>TR configuration v4.xx format supported.</li>
		// 									<li>GW-GSM-02A FW 3.14 supported.</li>
		// 								</ul>
		// 							</li>
		// 						</ul>
		// 					</li>
		// 				</ul>
		// 				<ul>
		// 					<li>Documentation:
		// 						<ul>
		// 							<li>IQRF Quick start guide revised and updated.</li>
		// 							<li>IQMESH Network Deployment Technical guide added.</li>
		// 							<li>IQRF OS User's guide revised and simplified. Some parts transferred into IQMESH
		// 								Network Deployment Technical guide.
		// 							</li>
		// 							<li>IQRF DPA Framework technical guide updated for DPA v4.00.</li>
		// 						</ul>
		// 					</li>
		// 				</ul>
		// 				<hr/>
		// 				<h2>23 Nov 2018 - For OS v4.03D, DPA v3.03 and IDE v4.49 at TR-7xD</h2>
		// 				<p>Updated for IDE v4.49.</p>
		// 				<ul>
		// 					<li>CustomDpaHandler-ChangeIQRFOS.iqrf file updated.</li>
		// 					<li>IQRF IDE v4.49:
		// 						<ul>
		// 							<li>Bugs fixed:
		// 								<ul>
		// 									<li>This installation fixes an error when updates from IQRF OS 4.02D to
		// 										version 4.03D of some TR modules did not properly register newly used
		// 										service channels. The error can be detected when the TR configuration
		// 										from the affected module is loaded into IQRF IDE. The error causes the
		// 										module to become unable to bond to the network.
		// 									</li>
		// 									<li>It was not possible to create a new project if no existing project was
		// 										opened.
		// 									</li>
		// 									<li>Minor repairs and improvements.</li>
		// 								</ul>
		// 							</li>
		// 						</ul>
		// 					</li>
		// 				</ul>
		// 				<hr/>
		// 				<h2>5 Nov 2018 - For OS v4.03D, DPA v3.03 and IDE v4.48 at TR-7xD</h2>
		// 				<p>Do not use this version. Serious bug present (see version 5 Nov 2018). Use version 23 Nov
		// 					2018 instead.</p>
		// 				<p>Updated for IDE v4.48.</p>
		// 				<ul>
		// 					<li>CustomDpaHandler-ChangeIQRFOS.iqrf file updated.</li>
		// 					<li>IQRF IDE v4.48:
		// 						<ul>
		// 							<li>Changes and enhancements:</li>
		// 							<li>Downgrade from IQRF OS 4.03D to 4.02D possible.</li>
		// 						</ul>
		// 					</li>
		// 				</ul>
		// 				<hr/>
		// 				<h2>26 Oct 2018 - For OS v4.03D and DPA v3.03 at TR-7xD</h2>
		// 				<p>Updated for OS v4.03D, DPA v3.03 and IDE v4.47.</p>
		// 				<ul>
		// 					<li>OS v4.03D:
		// 						<ul>
		// 							<li>Smart connect based on IBK (Individual Bonding Key)</li>
		// 							<li>Dedicated fixed service RF channels</li>
		// 							<li>Operating RF channel inherited from network Coordinator during bonding</li>
		// 							<li>4 B FRC</li>
		// 							<li>LED control more user-friendly</li>
		// 							<li>Some minor improvements</li>
		// 							<li>Macros and examples updated and extended</li>
		// 						</ul>See <a href="/technology/os/history-os">Release notes</a> and <a href="/technology/os/os-migration-notes">Migration notes</a>.*/
		// 					</li>
		//
		// 					<li>DPA v3.03:
		// 						<ul>
		// 							<li>Changes and enhancements:
		// 								<ul>
		// 									<li>IQRF OS v4.03D supported</li>
		// 									<li>IBK and Smart connect supported</li>
		// 									<li>Default bonding sleep timeout extended to 5 hours</li>
		// 									<li>DSM (DPA Service Mode) slightly improved</li>
		// 									<li>Custom DPA handlers and examples updated and extended</li>
		// 								</ul>
		// 							</li>
		// 							<li>New features:
		// 								<ul>
		// 									<li>New commands Smart connect, Validate bonds, LED Flashing and Set MID
		// 									</li>
		// 									<li>4 B FRC supported</li>
		// 									<li>New embedded FRC command Test RF Signal</li>
		// 								</ul>
		// 							</li>
		// 							<li>A lot of other enhancements</li>
		// 							<li>Several bug fixes</li>
		// 						</ul>See
		// 						<a href="https://iqrf.org/DpaTechGuide/index.html?page=dpa-410.html"
		// 						   rel="noopener noreferrer"
		// 						   target="_blank"> Release notes</a>.
		// 					</li>
		//
		// 					<li>IQRF IDE v4.47:
		// 						<ul>
		// 							<li>Changes and enhancements:
		// 								<ul>
		// 									<li>IQRF OS v4.03D and DPA v3.03 supported</li>
		// 									<li>Device enumeration added to the CATS service tool</li>
		// 									<li>IQRF Repository supported (non-beta)</li>
		// 									<li>GW-GSM-02A FW 3.13, GW-USB-06 FW 1.08 and CK-USB-04A FW 1.15 supported
		// 									</li>
		// 								</ul>
		// 							</li>
		//
		// 							<li>Features added:
		// 								<ul>
		// 									<li>IQRF Code Tool for encoding/decoding of the IQRF Code</li>
		// 									<li>IQMESH Network manager supports IQRF Smart connect</li>
		// 									<li>NetBeans and IntelliJ IDEA editors supported</li>
		// 								</ul>
		// 							</li>
		// 							<li>A lot of other enhancements</li>
		// 							<li>Several bug fixes</li>
		// 						</ul>See <a href="https://doc.iqrf.org/IQRF-IDE-Release-Notes">Release notes</a>
		// 					</li>
		//
		// 					<li>New C compiler version: CC5X Version 3.7A FREE edition, included in IQRF IDE</li>
		// 					<li>IQRF SPI and CDC support IBK</li>
		// 					<li>Documentation:
		// 						<ul>
		// 							<li>IQRF Quick start guide updated</li>
		// 							<li>IQRF OS User's and Reference guides updated for OS v4.03D</li>
		// 							<li>IQRF DPA Framework technical guide updated for DPA v3.03</li>
		// 							<li>IQRF SPI technical guide and IQRF CDC technical guide updated</li>
		// 						</ul>
		// 					</li>
		// 				</ul>
		// 				<hr/>
		// 				<h2>28 Jun 2018 - For OS v4.02D and DPA v3.02 at TR-7xD</h2>
		// 				<p>Updated for TR-77D, TR-78D, IDE v4.46 and DK-SW2-01.</p>
		// 				<ul>
		// 					<li>TR-77D and TR-78D transceiver series supported.</li>
		// 					<li>DK-SW2-01 development kit supported.</li>
		// 					<li>IQRF IDE v4.46:
		// 						<ul>
		// 							<li>IQRF Repository supported (beta).</li>
		// 							<li>MPLAB X supported as an external source code editor.</li>
		// 							<li>New version of compiler included: CC5X Version 3.6 FREE edition.</li>
		// 							<li>Default compiler build options changed from -a -bu -Q -Vn -cif -dc to -a -gb -GS
		// 								-Vn -cif -dc -AH.
		// 							</li>
		// 							<li>IBK (Individual Bonding Key) supported. IBK will be utilized in future OS
		// 								versions.
		// 							</li>
		// 							<li>Several bugs fixed, minor repairs and improvements.</li>
		// 						</ul>
		// 						See <a href="https://doc.iqrf.org/IQRF-IDE-Release-Notes">Release notes</a>
		// 					</li>
		// 					<li>DPA macros (file DPA-macros.iqrfmcr) revised, macros for PWM removed.</li>
		// 					<li>Basic example E09-LINK revised, TR-77D support added.</li>
		// 					<li>IoT-StarterKit-01 renamed to UP-IQRF-IoT-Starter-Kit</li>
		// 					<li>Documentation:
		// 						<ul>
		// 							<li>Datasheets for all TR transceivers revised.</li>
		// 							<li>IQRF OS User's and Reference guides slightly revised.</li>
		// 							<li>UP-IQRF-IoT-Starter-Kit User's guide updated and renamed to
		// 								Getting_started_guide_UP-IQRF-IoT-Starter-Kit.
		// 							</li>
		// 						</ul>
		// 					</li>
		// 				</ul>
		// 				<hr/>
		// 				<h2>20 Nov 2017 - For OS v4.02D and DPA v3.02 at TR-7xD</h2>
		// 				<p>Updated for DPA 3.02.</p>
		// 				<ul>
		// 					<li><strong>DPA v3.02:</strong>
		// 						<ul>
		// 							<li>Changes and enhancements:
		// 								<ul>
		// 									<li>Autoexec and IO Setup can use embedded peripherals that are not enabled
		// 										in the HWP Configuration.
		// 									</li>
		// 								</ul>
		// 							</li>
		// 						</ul>
		// 						<ul>
		// 							<li>New features:
		// 								<ul>
		// 									<li>New API variable RxFilter.</li>
		// 								</ul>
		// 							</li>
		// 						</ul>
		// 						<ul>
		// 							<li>Bug fixes:
		// 								<ul>
		// 									<li>Fixed an issue when during precise sleep the current drawn jumps by a
		// 										few µA under certain GPIO settings.
		// 									</li>
		// 									<li>Fixes and enhancements at CustomDpaHandler-AutoNetwork example.</li>
		// 								</ul>
		// 							</li>
		// 						</ul>
		// 					</li>
		// 				</ul>
		// 				<p>See
		// 					<a href="http://www.iqrf.org/DpaTechGuide/#12%20DPA%20Release%20Notes"
		// 					   rel="noopener noreferrer"
		// 					   target="_blank"> Release notes</a>.
		// 				</p>
		// 				<hr/>
		// 				<h2>13 Nov 2017 - For OS v4.02D and DPA v3.01 at TR-7xD</h2>
		// 				<p>Updated for TR-75DA and IQRF IDE v4.45.<br/>
		// 					- TR-75DA transceiver supported.<br/>
		// 					- IQRF OS header files updated for TR-75D.</p>
		// 				<ul>
		// 					<li><strong>IQRF IDE v4.43:</strong>
		// 						<ul>
		// 							<li>Bugs Fixed: Minor repairs and improvements.</li>
		// 							<li>Changes and Enhancements:
		// 								<ul>
		// 									<li>(DC)TR-75D transceiver supported.</li>
		// 									<li>GW-GSM-02A FW 3.12 added to the IQRF Device Manager.</li>
		// 								</ul>
		// 							</li>
		// 						</ul>
		// 						See <a href="https://doc.iqrf.org/IQRF-IDE-Release-Notes">Release notes</a>
		// 					</li>
		// 				</ul>
		// 				<ul>
		// 					<li><strong>Documentation:</strong>
		// 						<ul>
		// 							<li>Datasheets for all TR transceivers revised.</li>
		// 							<li>CK-USB-04A and DK-EVAL-04A updated for TR-75DA.</li>
		// 							<li>IQRF OS Reference guide updated for TR-75D. Some minor improvements.</li>
		// 						</ul>
		// 					</li>
		// 				</ul>
		// 				<hr/>
		// 				<h2>23 Aug 2017 - For OS v4.02D and DPA v3.01 at TR-7xD</h2>
		// 				<p>Updated for IQRF OS v4.02D, DPA v3.01 and IQRF IDE v4.43.<br/>
		// 					TR and DCTR not differentiated. All TRs support both OS as well as DPA approaches.<br/>
		// 					TR upload via SPI or CDC available.<br/>
		// 					IQRF Alliance DPA interoperable standard specification updated.<br/>
		// 					IQRF developer/manufacturer changed from MICRORISC to IQRF Tech (the MICRORISC's spin-off).
		// 				</p>
		// 				<ul>
		// 					<li><strong>IQRF OS v4.02D:</strong>
		// 						<ul>
		// 							<li>TR/DCTR fusion implemented.</li>
		// 							<li>TR upload extended and fully documented. See IQRF SPI Technical guide.</li>
		// 							<li>Fixed a bug in 2B FRC individual packets processing for not discovered Nodes in
		// 								range with the Coordinator only.
		// 							</li>
		// 							<li>Fixed an RFPGM bug in OS v4.01D (see below).</li>
		// 							<li>Higher immunity against possible failure of RF IC.</li>
		// 							<li>Some minor improvements</li>
		// 						</ul>
		// 						See <a href="http://www.iqrf.org/technology/operating-system/os-migration/os-history"
		// 							   rel="noopener noreferrer" target="_blank">IQRF OS History</a> and <a
		// 							href="http://www.iqrf.org/technology/operating-system/os-migration/os-migration-notes"
		// 							rel="noopener noreferrer" target="_blank">Migration notes</a>.
		// 					</li>
		// 				</ul>
		// 				<ul>
		// 					<li><strong>DPA v3.01:</strong>
		// 						<ul>
		// 							<li>IQRF OS v4.02D supported.</li>
		// 							<li>HWP plug-in files renamed from GeneralHWP… to HWP…</li>
		// 							<li>HWP-Node-STD without Interface support available.</li>
		// 							<li>New features regarding BondingButton Event, Selective Batch and
		// 								Clear &amp; Write &amp; Read Command.
		// 							</li>
		// 							<li>UART Peripheral and UART Interface support also 230400 Baud rate.</li>
		// 							<li>Lots of other changes and enhancements.</li>
		// 						</ul>
		// 						See <a href="http://www.iqrf.org/DpaTechGuide/#12%20DPA%20Release%20Notes"
		// 							   rel="noopener noreferrer" target="_blank">Release notes</a>.
		// 					</li>
		// 				</ul>
		// 				<ul>
		// 					<li><strong>IQRF IDE v4.43:</strong>
		// 						<ul>
		// 							<li>IQRF OS 4.02D supported.</li>
		// 							<li>IQRF OS 4.01D not supported.</li>
		// 							<li>DPA v3.01 supported.</li>
		// 							<li>IQRF Alliance interoperability standard supported (beta).</li>
		// 							<li>Packet inspector enhanced.</li>
		// 							<li>GW-USB-06 FW 1.07 added to the IQRF Device Manager.</li>
		// 							<li>Upload of user application in CDC IQRF USB mode supported for IQRF OS 4.00D and
		// 								higher.
		// 							</li>
		// 							<li>CC5X C compiler User´s Guide is not included as a separate file but is
		// 								automatically installed within the IDE installation and is available via the IDE
		// 								Help menu.
		// 							</li>
		// 							<li>Minor improvements and bug fixes.</li>
		// 						</ul>
		// 						See <a href="https://doc.iqrf.org/IQRF-IDE-Release-Notes">Release notes</a>
		// 					</li>
		// 				</ul>
		// 				<ul>
		// 					<li><strong>Documentation:</strong>
		// 						<ul>
		// 							<li>All documentation updated for:
		// 								<ul>
		// 									<li>TR/DCTR fusion.</li>
		// 									<li>The IQRF developer/manufacturer change to IQRF Tech.</li>
		// 								</ul>
		// 							</li>
		// 							<li>All TR datasheets slightly updated and revised. Mechanical drawings and
		// 								recommended PCB layouts revised.
		// 							</li>
		// 							<li>IQRF OS User's and Reference guides updated for OS v4.02D. Some minor
		// 								improvements and bug fixes.
		// 							</li>
		// 							<li>IQRF SPI and IQRF CDC Technical guides revised. Chapter TR upload added into
		// 								both of them.
		// 							</li>
		// 							<li>IoT StarterKit-01 User's guide available.</li>
		// 						</ul>
		// 					</li>
		// 				</ul>
		// 				<hr/>
		// 				<h2>14 Aug 2017 - For OS v4.01D and DPA v3.01 at TR-7xD</h2>
		// 				<p>This version is <strong>removed</strong> 18 Aug 2017 due to a <strong>serious bug</strong>.
		// 				</p>
		// 				<p><strong>Caution!</strong><br/>
		// 					Do not perform RFPGM (wireless upload) of TR transceivers with IQRF OS v4.00D using IQRF
		// 					IDE CATS based on TR with OS v4.01D, otherwise the OS inside the TRs become corrupted.</p>
		// 				<p><strong>Workaround:</strong> A corrupted TR can be recovered at the user. Contact
		// 					<a href="/support/contact-support"> IQRF support</a> for instructions in this case.
		// 				</p>
		// 				<p>We apologize for your inconvenience. IQRF OS v4.02D and IDE v4.43 fixing this bug will
		// 					be available by the end of August.</p>
		// 				<hr/>
		// 				<h2>1 Jun 2017 - For OS v4.00D and DPA v3.00 at TR-7xD</h2>
		// 				<p>Updated for IQRF IDE v4.41, IQRF Alliance interoperable products and IoT StarterKit-01.</p>
		// 				<ul>
		// 					<li>IQRF IDE
		// 						v4.41: &nbsp; <a href="https://doc.iqrf.org/IQRF-IDE-Release-Notes">» History</a></li>
		// 					<li>PRELIMINARY support for IQRF Alliance standard (to design interoperable products)
		// 						<ul>
		// 							<li>IQRFAstandard.h and IQRFA_HWPID.h header files added to DPA header files.
		// 							</li>
		// 						</ul>
		// 					</li>
		// 					<li>IoT StarterKit-01:
		// 						<ul>
		// 							<li>IoT StarterKit-01 folder added to Examples\DPA.</li>
		// 							<li>Demo Project, DPA macros and configuration file for IQRF IDE available.</li>
		// 						</ul>
		// 					</li>
		// 					<li>Documentation:
		// 						<ul>
		// 							<li>IQRF OS User's and Reference guides slightly updated.</li>
		// 						</ul>
		// 					</li>
		// 				</ul>
		// 				<hr/>
		// 				<h2>23 Mar 2017 - For OS v4.00D and DPA v3.00 at TR-7xD</h2>
		// 				<p>Updated for IQRF OS v4.00D, DPA v3.00 and IQRF IDE v4.40. For TR-7xDx only. TR-5xDx not
		// 					supported any more.</p>
		// 				<ul>
		// 					<li>TR-72D and TR-76D: Datasheets updated for OS v4.00D</li>
		// 				</ul>
		// 				<ul>
		// 					<li>IQRF OS v4.00D:
		// 						<ul>
		// 							<li>Packet structure changed</li>
		// 							<li>Packet structure changed</li>
		// 							<li>Extended security based on AES-128 encryption</li>
		// 							<li>Deep sleep mode</li>
		// 							<li>DPA is the only recommended way for IQMESH implementation</li>
		// 							<li>Networking with more than 240 devices is not supported</li>
		// 							<li>Lots of other enhancements</li>
		// 						</ul>
		// 					</li>
		// 					<p><a href="/technology/os/history-os">» OS history</a></p>
		// 				</ul>
		// 				<ul>
		// 					<li>DPA v3.00:
		// 						<ul>
		// 							<li>IQRF OS v4.00D supported</li>
		// 							<li>For DCTR-7xD only. DCTR-5xD is not supported from DPA v3.00.</li>
		// 							<li>Demo DPA version is not released any more</li>
		// 							<li>DPA for [CN] devices is not released any more</li>
		// 							<li>New features:
		// 								<ul>
		// 									<li>Command Set Security</li>
		// 									<li>Deep sleep feature at Sleep</li>
		// 									<li>DPA API function DpaApiSetRfDefaults</li>
		// 									<li>IQRF OS Change process can also change the DPA version at the same
		// 										time
		// 									</li>
		// 								</ul>
		// 							</li>
		// 							<li>Lots of other enhancements</li>
		// 						</ul>
		// 					</li>
		// 					<p><a href="http://www.iqrf.org/DpaTechGuide/" rel="noopener noreferrer" target="_blank">»
		// 						DPA Technical guide</a></p>
		// 				</ul>
		// 				<ul>
		// 					<li>IQRF IDE v4.40:
		// 						<ul>
		// 							<li>OS v4.00D and DPA v3.00 supported</li>
		// 							<li>TR-77D supported (beta)</li>
		// 							<li>GW-USB-07 supported (beta)</li>
		// 							<li>Czech help removed</li>
		// 							<li>Features added:
		// 								<ul>
		// 									<li>RF Scanner within CATS</li>
		// 									<li>Encryption supported in TR Configuration</li>
		// 									<li>Log background communication of IQMESH Network Manager</li>
		// 									<li>Microsoft Visual Studio editor supported</li>
		// 								</ul>
		// 							</li>
		// 							<li>Lots of other enhancements</li>
		// 							<li>Several bugs fixed</li>
		// 							<li><a href="https://doc.iqrf.org/IQRF-IDE-Release-Notes">» IDE History</a></li>
		// 						</ul>
		// 					</li>
		// 				</ul>
		// 				<ul>
		// 					<li>IQRF Quick start guide updated for OS v4.00D</li>
		// 				</ul>
		// 				<hr/>
		// 				<h2>3 Oct 2016 - For OS v3.08D and DPA v2.28 at TR-7xD and TR-5xD</h2>
		// 				<ul>
		// 					<li>Updated for DPA v2.28 and IDE v4.36.
		// 						<ul>
		// 							<li>DPA v2.28 - This is the ending major DPA release for DCTR-5xD.
		// 								<ul>
		// 									<li>Maximum data block length for EEPROM peripheral extended from 32 B to 55
		// 										B.
		// 									</li>
		// 									<li>Bugs fixed:
		// 										<ul>
		// 											<li>Fixed an issue when more LP mode [N] devices restarted at the
		// 												same time caused some of them to delay their start by
		// 												approximately 2 seconds.
		// 											</li>
		// 											<li>Fixed an issue when the demo DPA version [C] device responded
		// 												with ERROR_NADR when broadcast address or temporary address was
		// 												specified in the request. Same applies to the demo version of
		// 												[CN] device at Bridge command.
		// 											</li>
		// 											<li>Fixed an issue when the PWM peripheral or the corresponding
		// 												CustomDpaHandler-UserPeripheral-PWM.c example generated unwanted
		// 												output glitch when PWM parameters were set.
		// 											</li>
		// 											<li>Improved Sleep accuracy at DCTR-7xD for times above 2 s.</li>
		// 										</ul>
		// 									</li>
		// 								</ul><a href="http://www.iqrf.org/DpaTechGuide/#12.2%20DPA%202.28"
		// 										rel="noopener noreferrer" target="_blank">» Release notes</a>
		// 							</li>
		// 						</ul>
		//
		// 						<ul>
		// 							<li>IQRF IDE v4.36
		// 								<ul>
		// 									<li>Several changes and enhancements</li>
		// 									<li>Minor repairs and improvements.</li>
		// 									<li>Several bugs fixed.</li>
		// 									<li><a href="https://doc.iqrf.org/IQRF-IDE-Release-Notes">» Release notes</a></li>
		// 								</ul>
		// 							</li>
		// 						</ul>
		//
		// 					</li>
		// 				</ul>
		// 				<ul>
		// 					<li>IQRF OS User's and Reference guides slightly updated.</li>
		// 				</ul>
		// 				<hr/>
		// 				<ul>
		// 					<li>
		// 						<span className="text--orange">27 Apr 2016</span>
		// 						<ul>
		// 							<li>Updated for DPA v2.27 and IDE v4.35.
		// 								<ul>
		// 									<li>DPA v2.27
		// 										<ul>
		// 											<li>Write HWP configuration byte command enhanced.</li>
		// 											<li>Several minor changes, especially regarding configuration.</li>
		// 										</ul><a href="http://www.iqrf.org/DpaTechGuide/#12.3%20DPA%202.27"
		// 												rel="noopener noreferrer"
		// 												target="_blank">» Release notes</a>
		// 									</li>
		// 								</ul>
		//
		// 								<ul>
		// 									<li>IQRF IDE v3.35
		// 										<ul>
		// 											<li>IQMESH Network Manager slightly enhanced.</li>
		// 											<li>Minor repairs and improvements.</li>
		// 											<li>Several bugs fixed.</li>
		// 											<li><a href="https://doc.iqrf.org/IQRF-IDE-Release-Notes">» Release notes</a></li>
		// 										</ul>
		// 									</li>
		// 								</ul>
		//
		// 							</li>
		// 						</ul>
		// 						<ul>
		// 							<li>IQRF OS User's and Reference guides slightly updated.</li>
		// 						</ul>
		// 					</li>
		// 				</ul>
		// 				<hr/>
		// 				<ul>
		// 					<li>
		// 						<span className="text--orange">10 Mar 2016</span>
		// 						<ul>
		// 							<li>Updated for IQRF OS 3.08D, DPA v2.26 and IDE v4.34.
		// 								<ul>
		// 									<li>IQRF OS v3.08D
		// 										<ul>
		// 											<li>916 MHz and 433 MHz bands supported.</li>
		// 											<li>Number of RF channels higher than for TR-5xD transceivers.</li>
		// 											<li>Several minor changes, side effects and small bugs fixed.</li>
		// 										</ul>
		// 										<a href="/technology/os/os-migration-notes">» Migration notes</a>
		// 									</li>
		// 								</ul>
		//
		// 								<ul>
		// 									<li>DPA v2.26
		// 										<ul>
		// 											<li>Custom DPA handler for changing IQRF OS version over the
		// 												network
		// 											</li>
		// 											<li>Autonetwork examples support LP mode</li>
		// 											<li>Several new features, changes and bug fixes</li>
		// 										</ul><a href="http://www.iqrf.org/DpaTechGuide/#12.4%20DPA%202.26"
		// 												rel="noopener noreferrer"
		// 												target="_blank">» Release notes</a>
		// 									</li>
		// 								</ul>
		//
		// 								<ul>
		// 									<li>IQRF IDE v3.34
		// 										<ul>
		// 											<li>Several features added in IQMESH Network Manager</li>
		// 											<li>GW-UNI-01 supported (IQRF Alliance product)</li>
		// 											<li>Several changes, enhancements and bugs fixed</li>
		// 											<li><a href="https://doc.iqrf.org/IQRF-IDE-Release-Notes">» Release notes</a></li>
		// 										</ul>
		// 									</li>
		// 								</ul>
		//
		// 								<ul>
		// 									<li>Examples for IQRF OS
		// 										<ul>
		// 											<li>All examples tested for OS 3.08D</li>
		// 											<li>E13-INTERRUPT, TR_COMPARATOR, RF_SCANNER, TR_SERVO and
		// 												UART_LINK_INTR modified
		// 											</li>
		// 										</ul>
		// 										<a href="/support/code-examples/for-tr-under-os" target="_blank" rel="noopener noreferrer">» Release notes</a>
		// 									</li>
		// 								</ul>
		// 							</li>
		// 						</ul>
		// 						<ul>
		// 							<li>TR-72D and TR-76D datasheets updated</li>
		// 						</ul>
		// 					</li>
		// 				</ul>
		// 				<hr/>
		// 				<ul>
		// 					<li>
		// 						<span className="text--orange">8 Dec 2015</span>
		// 						<ul>
		// 							<li>Updated for DPA v2.24 and IDE v4.32.</li>
		// 							<li>DPA StartUp project:
		// 								<ul>
		// 									<li>General HWP plug-ins added</li>
		// 									<li>Custom DPA Handler source file added</li>
		// 									<li>Paths to compile Custom DPA Handlers added to the Project Properties
		// 									</li>
		// 								</ul>
		// 							</li>
		// 							<li>Custom DPA handler project: Removed.</li>
		// 							<li>DPA macros: Macros for pulse LEDR and pulse LEDG added.</li>
		// 							<li>E09-LINK.c example: Comments slightly modified.</li>
		// 						</ul>
		// 					</li>
		// 				</ul>
		// 				<hr/>
		// 				<ul>
		// 					<li>
		// 						<span className="text--orange">6 Nov 2015</span>
		// 						<ul>
		// 							<li>Updated for DPA v2.23.</li>
		// 							<li>Tech_Guide_SPI_TR-7xD changed.</li>
		// 							<li>External SPI master example modified.</li>
		// 						</ul>
		// 					</li>
		// 				</ul>
		// 				<hr/>
		// 				<ul>
		// 					<li>
		// 						<span className="text--orange">8 Oct 2015</span>
		// 						<ul>
		// 							<li>Updated for DPA v2.22.</li>
		// 							<li>IQRF IDE Autonetwork macro updated for DPA 2.22</li>
		// 							<li>IQRF OS includes: IQRF OS function setTXpower renamed to setRFpower</li>
		// 							<li>TR-72D datasheet updated for latest ETSI directives</li>
		// 							<li>TR-76D datasheet released</li>
		// 						</ul>
		// 					</li>
		// 				</ul>
		// 				<hr/>
		// 				<ul>
		// 					<li>
		// 						<span className="text--orange">23 Sep 2015</span>
		// 						<ul>
		// 							<li>Updated for DPA v2.21 and IDE v4.31.</li>
		// 							<li>IQRF OS User's and Reference guides slightly updated.</li>
		// 							<li>CK-USB-04A User's guide slightly updated.v</li>
		// 							<li>Macro buttonPressed (IQRF-macros.h) changed from pin RA5 to RB4.</li>
		// 							<li>Macros for Autonetwork-embedded added to IQRF IDE DPA macros.</li>
		// 							<li>E15-EXTENDED-FLASH.c added to basic examples.</li>
		// 						</ul>
		// 					</li>
		// 				</ul>
		// 				<hr/>
		// 				<ul>
		// 					<li>
		// 						<span className="text--orange">20 Aug 2015</span> – First release for
		// 						<strong> TR-7xD</strong> and <strong>OS v3.07D</strong>
		// 						<ul>
		// 							<li>For IQRF OS v3.07D, DPA v2.20 and IQRF IDE v4.30.</li>
		// 							<li>Startup package structure changed (compared to older versions for TR-5xD).</li>
		// 						</ul>
		// 					</li>
		// 				</ul>
		// 			</Col>
		// 		</Row>
		// 	</Grid>
		// );
	}
}

/**
 * This function maps the state to a
 * prop called `state`.
 * In larger apps it is often good
 * to be more selective and only
 * map the part of the state tree
 * that is necessary.
 */
const mapStateToProps = state => (
	{

		entities: state.entities,
	});

/**
 * Exporting part of the React.Component file
 */
export default connect(mapStateToProps)(withTranslation()(GetStartedStartupPackageHistoryScene));
