import React from "react";
import {Redirect, Route,} from "react-router-dom";

import App from "../App/App";

/**
 * Private Route
 * @param Component
 * @param rest
 * @returns {*}
 * @constructor
 */
const PrivateRoute = ({component: Component, ...rest}) => (
    <Route {...rest} render={(props) => (
        App.isUserLogged() === true
            ? <Component {...props} />
            : <Redirect to="/"/>
    )}/>
);

export default PrivateRoute;