// @flow
import React from "react";
import PropTypes from "prop-types";
// Import CSS
import "./EditorSceneWrapper.scss";
import {editorRoutes} from "../../../Config/routes";
import {Switch, withRouter} from "react-router-dom";
import {Navigation} from "../../Editor";
import InstantAction from "../../../Models/Utils/InstantAction";


/**
 * @class EditorSceneWrapper
 */
class EditorSceneWrapper extends React.Component {

	/**
	 * PropTypes
	 * @type {{children: shim}}
	 */
	static propTypes = {
		children: PropTypes.any,
		className: PropTypes.string,
	};

	static defaultProps = {
		className: ""
	};

	/**
	 * Component Did mount
	 */
	componentDidMount() {
		// if (App.isUserLogged()) {
		//     InstantAction.redirect("/");
		// }
	}

	/**
	 * Final Render
	 * @returns {*}
	 */
	render() {

		const {className} = this.props;

		if(!localStorage.getItem("JWT")){
			InstantAction.redirect("/login");
			return null;
		}

		return <div className={"editor-scene-wrapper " + className}>
			<Navigation/>
			<div className="editor-scene-wrapper__content">
				<Switch>

					{editorRoutes}

				</Switch>
			</div>
		</div>;
	}
}

export default withRouter(EditorSceneWrapper);