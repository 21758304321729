// @flow
import React from "react";
import {withTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import {Col, Grid, Row} from "react-flexbox-grid";
import "../../Components/Technology/Technology.scss";
import {Helmet} from "react-helmet";

/**
 * @class TechnologyIDE2Scene
 */
class TechnologyIDE2Scene extends React.Component {

	/**
	 * Constructor
	 * @param props
	 */
	constructor(props) {
		super(props);

		this.state = {
			showContentSupportedGUI: false,
			showContentGUI: false,
			showContentCommand: false,
			supportedCommand: false,
		};
	}

	/**
	 * Final Render
	 * @returns {*}
	 */
	render() {

		return (
			<Grid className={"page"}>
				<Helmet>
					<title>IDE 2 - IQRF</title>
				</Helmet>
				<Row className="page__section--bottom-padding">
					<Col xs={12}>
						<h1>IQRF IDE 2</h1>
						<h2>For oldest TR types only</h2>
						<h3>Integrated development and service environment</h3>
						<p>For old TR transceivers (TR-11A, TR-21A, TR-31B and TR-32B).<strong>For TR-52B and higher
							use <Link to={"/technology/ide"}>IQRF IDE 4 </Link>.</strong></p>
						<p>For all IQRF USB development kits (CK-USB-04, GW-USB-03,&nbsp;…).</p>
						<p>&nbsp;</p>
						<div className="table--wrapper">
							<table className="table--with-links">
								<tbody>
								<tr>
									<td><Link to="/download/0bf2c6c1-5a6d-4778-83a9-20560412dd67"
										   target="_self"><i className="icon-zip"/> IQRF IDE </Link> v2.08
									</td>
								</tr>
								</tbody>
							</table>
						</div>

					</Col>
				</Row>
			</Grid>
		);
	}
}

/**
 * Exporting part of the React.Component file
 */
export default withTranslation()(TechnologyIDE2Scene);
