// @flow

import PropTypes from "prop-types";
import {attr} from "redux-orm";
import slugify from "slugify";
import CRUDModel from "../ORM/CRUDModel";


export default class ProductCategory extends CRUDModel {
    friendly_url = () => {
        return slugify(this.name).toLowerCase();
    }
}

ProductCategory.modelName = "ProductCategory";

ProductCategory.fields = {
    id: attr(), // non-relational field for any value; optional but highly recommended
    products_count: attr(),
    description: attr(),
    is_online: attr(),
    is_fetched: attr(),
};

ProductCategory.propTypes = {
    name: PropTypes.string,
};

ProductCategory.defaultProps = {
    is_online: true,
    name: "",
    is_fetched: false,
};
//
// ProductCategory.endpoint = "/group";

export const ADD_PRODUCT_CATEGORY = "ADD_PRODUCT_CATEGORY";
export const REMOVE_PRODUCT_CATEGORY = "REMOVE_PRODUCT_CATEGORY";
export const UPDATE_PRODUCT_CATEGORY = "UPDATE_PRODUCT_CATEGORY";