// @flow
import React from "react";
import {withTranslation} from "react-i18next";
import {Col, Grid, Row} from "react-flexbox-grid";
import dp2p from "../../Components/Technology/video/dp2p.mp4";
import {Helmet} from "react-helmet";
import {HashLink} from "react-router-hash-link";
import {Link} from "react-router-dom";

/**
 * @class TechnologyDP2PScene
 */
class TechnologyDP2PScene extends React.Component {

  /**
   * Final Render
   * @returns {*}
   */
  render() {
    return (
      <div className={"page"}>
        <Helmet>
          <title>DPA Peer-to-peer - IQRF</title>
        </Helmet>
        <Grid>
          <Row className="page__section--bottom-padding">
            <Col xs={12}>
              <h1>DPA Peer-to-peer (DP2P)</h1>
              <h2>Local control of bonded Nodes from a non-network device</h2>
              <p>
                <a href="https://doc.iqrf.org/IQMESH-Network-deployment/pages/dpa-peer-to-peer.html" rel="noreferrer noopener" target="_blank">DP2P</a> is intended
                for communication <strong>with one or more bonded Nodes in direct RF range</strong>.
                Unlike <Link to="/dsm">DSM</Link>, no reset or restart of the Node is needed.
                A knowledge of the <a href="https://doc.iqrf.org/IQMESH-Network-deployment/pages/network-types.html"  rel="noreferrer noopener" target="_blank">Network type</a>,
                working channel, and <HashLink to="/technology/security#access-password">Access Password</HashLink> of addressed Nodes are required.
                DP2P must be enabled in the <a href="https://doc.iqrf.org/IQMESH-Network-deployment/pages/preparation-of-trs.html" target="_blank" rel="noreferrer noopener">TR configuration</a> of given Nodes.
              </p>
              <p>DP2P allows <strong>sending any DPA Request</strong> to addressed Nodes. It is useful for inventorying the network (e.g. localization of Nodes after Autonetwork), device maintenance, remote control, etc.</p>
            </Col>
          </Row>
        </Grid>
        <Grid>
          <Row>
            <Col xs={12}>
              <div className="text--center" id="dp2p-animation">
                <video width="560" controls>
                  <source src={dp2p}/>
                  <p>Your browser does not support the video tag.</p>
                </video>
              </div>
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }

}

/**
 * Exporting part of the React.Component file
 */
export default withTranslation()(TechnologyDP2PScene);
