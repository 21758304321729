// @flow

import PropTypes from "prop-types";
import {attr, fk, many} from "redux-orm";

import CRUDModel from "../ORM/CRUDModel";


export default class ProductSeries extends CRUDModel {

}

ProductSeries.modelName = "ProductSeries";

ProductSeries.fields = {
	id: attr(), // non-relational field for any value; optional but highly recommended
	description: attr(),
	name: attr(),
	status: fk("ProductStatus"),
	status_2: fk("ProductStatus2", "status_2_field"),
	section: fk("ProductSubcategorySection"),
	category: fk("ProductCategory"),
	subcategory: fk("ProductSubcategory"),
	photos: many({
		to: "Photo",
		relatedName: "photos"
	}),
	links: many({
		to: "Link",
		relatedName: "links"
	}),
	downloads: many({
		to: "File",
		relatedName: "downloads"
	}),
	image: fk("Photo"),


	top_page_left: attr(),
	top_page_bottom: attr(),

	box_1_title: attr(),
	box_1_open: attr(),
	box_1_content: attr(),
	box_2_title: attr(),
	box_2_open: attr(),
	box_2_content: attr(),
	box_3_title: attr(),
	box_3_open: attr(),
	box_3_url: attr(),
	box_3_content: attr(),
	box_4_title: attr(),
	box_4_open: attr(),
	box_4_content: attr(),
	box_5_title: attr(),
	box_5_open: attr(),
	box_5_content: attr(),
	box_6_title: attr(),
	box_6_open: attr(),
	box_6_url: attr(),
	box_6_content: attr(),
	box_7_title: attr(),
	box_7_open: attr(),
	box_7_content: attr(),
	is_fetched: attr(),
};

ProductSeries.propTypes = {
	name: PropTypes.string,
};

ProductSeries.defaultProps = {
	is_online: true,
	is_fetched: false,
};

// ProductSeries.endpoint = "/group";

export const ADD_PRODUCT_SERIES = "ADD_PRODUCT_SERIES";
export const REMOVE_PRODUCT_SERIES = "REMOVE_PRODUCT_SERIES";
export const UPDATE_PRODUCT_SERIES = "UPDATE_PRODUCT_SERIES";