// @flow

// Necessary Evil
import React from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import LoadingComponent from "../Components/LoadingComponent";
import BackendRequest from "../Models/REST/BackendRequest";
import FormGroup from "../Components/Form/FormGroup";
import { connect } from "react-redux";
import ErrorMessage from "../Components/Form/ErrorMessage/ErrorMessage";
import Markdown from "react-markdown";
import remarkGfm from "remark-gfm";
import { Col, Row } from "react-flexbox-grid";
import SwitchButton from "../Components/Form/SwitchButton/SwitchButton";

/**
 * @class EventForm
 *
 */
class EventForm extends React.Component {

	/**
	 * PropTypes
	 * @type {{children: shim, videoCategories: *}}
	 */
	static propTypes = {
		event: PropTypes.any,
		afterSuccess: PropTypes.func,
	};

	/**
	 * Default Props
	 * @type {{afterSuccess: EventForm.defaultProps.afterSuccess}}
	 */
	static defaultProps = {
		afterSuccess: () => {
			// InstantAction.redirect("/editor/events");
		},
	};

	/**
	 * State
	 * @type {{formData: {}, expectingResponse: boolean, errorMessage: null}}
	 */
	state = {
		expectingResponse: false,
		errorMessage: null,
		// Properties
		id: null,
		description: "",
		url: "",
		name: "",
		starts: null,
		ordering: 0,
		is_online: true,

	};

	/**
	 * Get derived state from props
	 * @param props
	 * @param state
	 */
	static getDerivedStateFromProps (props, state) {

		if (props.event !== null) {
			if (props.event.id !== state.id) {
				return {
					...props.event._fields,
				};
			}
		}
		return null;
	}

	/**
	 * Handle Input Changes
	 * @param event
	 */
	handleInputChange = (event) => {

		const target = event.target;
		const value = target.type === "checkbox" ? target.checked : target.value;
		const name = target.name;

		this.setState({
			[name]: value
		});

	};

	/**
	 * Handle Switch
	 * @param key
	 */
	handleSwitch = (key) => {

		this.setState({
			[key]: !this.state[key]
		});

	};

	/**
	 * Handle Submit
	 * @param e
	 */
	handleSubmit = (e) => {

		e.preventDefault();

		/**
		 * Payload
		 * @type {{image: null, name: string, title: string, content: string}}
		 */

		const payload = {
			name: this.state.name,
			description: this.state.description,
			url: this.state.url,
			starts: this.state.starts,
			is_online: this.state.is_online,
			ordering: this.state.ordering,
		};

		let method = this.state.id === null ? "post" : "put";
		let endpoint = this.state.id === null ? "event" : "event/" + this.state.id;

		BackendRequest({
			endpoint: endpoint,
			method: method,
			payload: payload,
			self: this,
			afterSuccess: this.props.afterSuccess
		});
	};

	/**
	 * Component will mount
	 */
	componentDidMount () {

		if (this.props.event !== null) {
			this.setState({
				...this.props.event._fields,
			});
		}
	}

	/**
	 * Final Render
	 * @returns {*}
	 */
	render () {

		/**
		 * @info Translation function, className
		 */
		const {
			t,
		} = this.props;

		/**
		 * @info Error variable
		 */
		let error = "";

		/**
		 * @info Handling Error Message
		 */
		if (this.state.errorMessage !== null) {
			error = <ErrorMessage>{t("formError." + this.state.errorMessage)}</ErrorMessage>;
		}

		/**
		 * Final Output
		 * @type {{}}
		 */
		return <form onSubmit={this.handleSubmit} className={"form"}>

			<FormGroup>
				<label htmlFor="name">Name</label>
				<input onChange={this.handleInputChange}
					   value={this.state.name}
					   type="text"
					   tabIndex={2}
					   name="name" id="name"
					   placeholder={"Markdown format.."}
				/>
				<em>(Use markdown - <a target="_blank"
									   href="https://github.com/adam-p/markdown-here/wiki/Markdown-Cheatsheet"
									   rel="noopener noreferrer">https://github.com/adam-p/markdown-here/wiki/Markdown-Cheatsheet</a>)</em>
			</FormGroup>
			<FormGroup>
				<label>{t("label.Preview")}</label>
				<Markdown remarkPlugins={[remarkGfm]} children={this.state.name}/>
			</FormGroup>
			<Row>
				<Col xs={12} lg={12}>

					<FormGroup>
						<label htmlFor="starts">Starts</label>
						<input onChange={this.handleInputChange}
							   value={this.state.starts}
							   type="date"
							   name="starts" id="starts"
							   placeholder={"Starts (date)"}
						/>
					</FormGroup>

					<FormGroup>
						<label htmlFor="url">URL of event website</label>
						<input onChange={this.handleInputChange}
							   value={this.state.url}
							   type="text"
							   name="url"
							   id="url"
							   placeholder={"URL to event website"}
						/>
					</FormGroup>

					<FormGroup>
						<label htmlFor="ordering">Ordering</label>
						<input onChange={this.handleInputChange}
							   value={this.state.ordering}
							   type="number"
							   name="ordering"
							   id="ordering"
						/>
					</FormGroup>

				</Col>
				<Col xs={12} lg={12}>
					<FormGroup>
						<label htmlFor="description">Description</label>
						<input onChange={this.handleInputChange}
							   value={this.state.description}
							   type="text"
							   name="description"
							   id="description"
							   placeholder="Place, date..."
						/>
					</FormGroup>
					<FormGroup>
						<label>{t("label.IsOnline")}?</label>
						<br/>
						<SwitchButton checked={this.state.is_online} handleClick={this.handleSwitch}
									  name={"is_online"}/>
					</FormGroup>
				</Col>
			</Row>
			<hr/>
			{error}
			<FormGroup className={"text--center"}>
				{(this.state.expectingResponse) ? <LoadingComponent/> :
					<button type="submit" tabIndex={2}
							className="btn btn--animated">{this.state.id === null ? t("button.Submit") : t("button.Update")}</button>
				}
			</FormGroup>
		</form>;
	}
}

/**
 * State to Props
 * @param state
 * @return {{entities: {PhotoSection: (*|PhotoSection)}}}
 */
const mapStateToProps = state => (
	{
		entities: {
			Photo: state.entities.Photo,
			PhotoSection: state.entities.PhotoSection
		},
	});

export default (connect(mapStateToProps)(withTranslation()(EventForm)));
