// @flow

// Necessary Evil
import React from "react";
import PropTypes from "prop-types";
import {withTranslation} from "react-i18next";
import {CopyToClipboard} from "react-copy-to-clipboard";
// Import component CSS
import "./FileItem.scss";


/**
 * @class FileItem
 */
class FileItem extends React.Component {

    /**
     * PropTypes
     * @type {{file: shim, children: shim, allowOptions: shim}}
     */
    static propTypes = {
        children: PropTypes.any,
        file: PropTypes.any.isRequired,
        allowOptions: PropTypes.bool,
    };

    /**
     * Default Props
     * @type {{allowOptions: boolean}}
     */
    static defaultProps = {
        allowOptions: true,
    };

    /**
     * Final Render
     * @returns {*}
     */
    render() {

        const {
            t,
            file,
            allowOptions
        } = this.props;

        return <div className="file-item">
            <div className="file-item__name">
                <a href={process.env.REACT_APP_WEBSITE+"download/"+file.id} target="_blank" rel="noreferrer noopener">

					<div className="file-item__icon">
						{file.type && <img src={process.env.PUBLIC_URL + "/icon/" + file.type.icon + ".png"} alt={file.type.icon + " icon"}/>}</div>
					{file.name}
                </a> {file.description !== "" ?
                <span className="file-item__description">&nbsp;- {file.description}</span> : ""}
            </div>
            {allowOptions ?
                <div className="file-item__actions">
                    <a className="action" href={file.url}>
                        <i className="icon-download"/>{t("button.Download")}
                    </a>
                    <CopyToClipboard
                        text={file.url}
                        onCopy={() => {
                        }}>
                        <div className="action"><i className="icon-copy"/>{t("button.CopyFileUrl")}</div>
                    </CopyToClipboard>
                    <CopyToClipboard
                        text={"[" + file.name + "](" + file.url + ")"}
                    >
                        <div className="action"><i className="icon-copy"/>{t("button.GetMarkdown")}</div>
                    </CopyToClipboard>
                </div> : ""
            }
        </div>;
    }
}

export default withTranslation()(FileItem);