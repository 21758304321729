// @flow

// Necessary Evil
import React from "react";
import PropTypes from "prop-types";
import {withTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import {Col} from "react-flexbox-grid";
import moment from "moment";
import Markdown from "react-markdown";
import remarkGfm from "remark-gfm";
// Import component CSS

import "./NewsItemAdmin.scss";


/**
 * @class NewsItemAdmin
 */
class NewsItemAdmin extends React.Component {

    /**
     * PropTypes
     * @type {{children: shim}}
     */
    static propTypes = {
        news: PropTypes.any.isRequired,
    };

    /**
     * Final Render
     * @returns {*}
     */
    render() {
        const {
            news
        } = this.props;

        const posted = moment(news.published);

        return <Link to={"/editor/news/item/" + news.id}><Col xs={12} className="news-item-admin">
            <div>
                <i className="icon-news"/>&nbsp;
            </div>
            <div>
                {/*<div className="case-study-item-admin__name">{news.title}</div>*/}
                <Markdown remarkPlugins={[remarkGfm]} children={news.perex}/>
            </div>
            <div className="news-item-admin__options">{posted.format("dddd, DD. M. YYYY HH:mm")} ({posted.fromNow()})</div>
        </Col>
        </Link>;
    }
}

export default withTranslation()(NewsItemAdmin);
