// @flow

// Necessary Evil
import React from "react";
import PropTypes from "prop-types";
import {withTranslation} from "react-i18next";
import BackendRequest from "../Models/REST/BackendRequest";
import ErrorMessage from "../Components/Form/ErrorMessage/ErrorMessage";
import FormGroup from "../Components/Form/FormGroup";
import LoadingComponent from "../Components/LoadingComponent";
import MasterGetter from "../Models/Utils/MasterGetter";
import MyDropZone from "../Components/MyDropzone";
import Markdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import rehypeSanitize from "rehype-sanitize";
import remarkGfm from "remark-gfm";
import SwitchButton from "../Components/Form/SwitchButton/SwitchButton";
import ThumbnailPhotoAdmin from "../Components/Products/ThumbnailPhotoAdmin/ThumbnailPhotoAdmin";
import {Col, Row} from "react-flexbox-grid";
import {connect} from "react-redux";
import {Required} from "../Components";
import ProductFormOptionalBox from "./ProductFormOptionalBox";
import {LinkItem} from "../Components/Links";
import InstantAction from "../Models/Utils/InstantAction";
import {setEditorLinksFetched} from "../App/App.actions";

/**
 * @class ProductForm
 *
 */
class ProductForm extends React.Component {

	/**
	 * PropTypes
	 * @type {{children: shim, videoCategories: *}}
	 */
	static propTypes = {
		product: PropTypes.any,
		afterSuccess: PropTypes.func,
	};

	/**
	 * Default Props
	 * @type {{afterSuccess: ProductForm.defaultProps.afterSuccess}}
	 */
	static defaultProps = {
		afterSuccess: (response) => {
			// InstantAction.redirect("/editor/products");
		},
	};

	/**
	 * State
	 * @type {{formData: {}, expectingResponse: boolean, errorMessage: null}}
	 */
	state = {
		id: null,
		expectingResponse: false,
		errorMessage: null,
		// Properties
		category: 0,
		description: "",
		description_2: "",
		friendly_url: "",
		ordering: 0,
		image: 0,
		is_archived: false,
		is_available: true,
		is_online: false,
		links: [],
		name: "",
		photos: [],
		section: 0,
		stateChange: false,
		status: 0,
		status_2: 0,
		subcategory: 0,
		top_page_left: "",
		top_page_bottom: "",
		box_1_title: "",
		box_1_open: 2,
		box_1_content: "",
		box_2_title: "",
		box_2_open: 2,
		box_2_content: "",
		box_3_title: "",
		box_3_open: 2,

		box_3_content: "",
		box_4_title: "",
		box_4_open: 2,
		box_4_content: "",
		box_5_title: "",
		box_5_open: 2,
		box_5_content: "",
		box_6_title: "",
		box_6_open: 2,

		box_6_content: "",
		box_7_title: "",
		box_7_open: 2,
		box_7_content: "",

		title_2: "",
		title_3: "",
		addLinkId: null,
	};

	/**
	 *
	 * @param props
	 * @param state
	 */
	static getDerivedStateFromProps (props, state) {

		if (props.product !== null) {
			if (props.product.id !== state.id) {
				let photosIds = [];
				let linksIds = [];

				props.product.photos.all().toModelArray().forEach(item => {
					photosIds.push(item.id);
				});

				props.product.links.toModelArray().forEach(item => {
					linksIds.push(item.id);
				});

				return {
					...props.product._fields,
					category: props.product._fields.category === null ? 0 : props.product._fields.category,
					subcategory: props.product._fields.subcategory === null ? 0 : props.product._fields.subcategory,
					section: props.product._fields.section === null ? 0 : props.product._fields.section,
					photos: photosIds,
					links: linksIds,
				};
			}

			// Change of photos
			if (props.product.photos !== state.photos || props.product.links !== state.links || props.product.image !== state.image) {
				let photosIds = [];
				props.product.photos.all().toModelArray().forEach(item => {
					photosIds.push(item.id);
				});

				let linksIds = [];
				props.product.links.toModelArray().forEach(item => {
					linksIds.push(item.id);
				});

				return {
					photos: photosIds,
					links: linksIds
				};
			}
		}

		return null;
	}

	/**
	 * Update Photos
	 * @param photoId
	 */
	updatePhotos = (photoId) => {

		// Update current photo sets
		let currentPhotos = this.state.photos;
		currentPhotos.push(photoId);

		this.setState({
			photos: currentPhotos,
		});
	};

	/**
	 *
	 */
	addNewLink = (e) => {

		e.preventDefault();

		BackendRequest({
			endpoint: "product-link",
			method: "post",
			payload: {
				product: this.props.product.id
			},
			afterSuccess: (response) => {

				// console.log(response.data);
				//
				// this.setState({
				// 	links: response.data.product.links,
				// }, () => {
				// });
				//
				// this.forceUpdate();
			}
		});
	};

	addLink = (e) => {

		e.preventDefault();

		BackendRequest({
			endpoint: "product-link",
			method: "post",
			payload: {
				product: this.props.product.id,
				linkId: this.state.addLinkId,
			},
			afterSuccess: (response) => {

			}
		});

	};

	/**
	 * On Drop Action
	 * @param acceptedFiles
	 */
	onDrop = (acceptedFiles) => {

		acceptedFiles.forEach(file => {

			let filenameSpliced = file.name.split(".");
			let fileType = filenameSpliced[filenameSpliced.length - 1];

			/**
			 * Payload
			 * @type {{file: *, filename: *, section: number, type: (*|string)}}
			 */
			const data = {
				file: file,
				section: 1,
				filename: file.name,
				type: fileType,
				folder: this.state.id,
				product: this.state.id,
			};

			BackendRequest({
				method: "post",
				endpoint: "product/" + this.state.id + "/photo",
				payload: data,
				self: this,
				afterSuccess: (response) => {

					const data = response.data;

					this.updatePhotos(data.photo.id);

					if (this.state.image === null || this.state.image === 0) {
						this.setState({
							image: response.data.photo.id
						});
					}

				}
			});
		});

	};

	/**
	 * Fetch Essentials
	 */
	fetchEssentials = () => {
		//
		// BackendRequest({
		// 	endpoint: "products",
		// 	self: this,
		// });

		const { isLinksFetched } = this.props;

		if(!isLinksFetched){
			BackendRequest({
				endpoint: "editor/links",
				afterSuccess: () => {
					// Stop fetching for second time
					InstantAction.dispatch(setEditorLinksFetched(true));
				}
			});
		}
	};

	/**
	 * Handle Input Changes
	 * @param event
	 */
	handleInputChange = (event) => {

		const target = event.target;
		const value = target.type === "checkbox" ? target.checked : target.value;
		const name = target.name;

		let changeSubcategory = {};

		if (name === "category") {
			changeSubcategory = {
				subcategory: 0,
			};
		}

		this.setState({
			[name]: value,
			...changeSubcategory
		});

	};

	handleAddLinkChange = (event) => {
		const target = event.target;
		const value = target.type === "checkbox" ? target.checked : target.value;

		this.setState({
			addLinkId: value,
		});
	} ;

	/**
	 * Handle Submit
	 * @param e
	 */
	handleSubmit = (e) => {

		e.preventDefault();

		/**
		 * Payload
		 * @type {{image: null, name: string, title: string, content: string}}
		 */
		const payload = {
			...this.state,
			name: this.state.name,
			category: this.state.category === 0 ? null : this.state.category,
			subcategory: this.state.subcategory === 0 ? null : this.state.subcategory,
			section: this.state.section === 0 ? null : this.state.section,
			status: this.state.status,
			status_2: this.state.status_2,
			description: this.state.description,
			description_2: this.state.description_2,
			price_list_description: this.state.price_list_description,
			is_archived: this.state.is_archived,
			is_online: this.state.is_online,
			in_price_list: this.state.in_price_list,
			is_available: this.state.is_available,
			photos: JSON.stringify(this.state.photos),
			ordering: this.state.ordering,
			image: this.state.image,
			title_2: this.state.title_2,
			title_3: this.state.title_3,
		};

		let method = this.state.id === null ? "post" : "put";
		let endpoint = this.state.id === null ? "product" : "product/" + this.state.id;

		BackendRequest({
			endpoint: endpoint,
			method: method,
			payload: payload,
			self: this,
			afterSuccess: this.props.afterSuccess
		});
	};

	/**
	 * Handle Switch
	 * @param key
	 */
	handleSwitch = (key) => {

		this.setState({
			[key]: !this.state[key]
		});

	};

	/**
	 * Component will mount
	 */
	componentDidMount () {

		this.fetchEssentials();
	}

	/**
	 * Final Render
	 * @returns {*}
	 */
	render () {

		/**
		 * @info Translation function, className
		 */
		const {
			t,
			entities,
		} = this.props;

		const {
			expectingResponse
		} = this.state;

		/**
		 * @info Error variable
		 */
		let error = "";

		/**
		 * @info Handling Error Message
		 */
		if (this.state.errorMessage !== null) {
			error = <ErrorMessage>{this.state.errorMessage}</ErrorMessage>;
		}

		const session = MasterGetter.getSession(entities);
		const categories = session.ProductCategory.all().orderBy("name");
		const subcategories = session.ProductSubcategory.all().filter(item => item.category === parseInt(this.state.category, 10)).orderBy("name");
		const sections = session.ProductSubcategorySection.all().filter(item => item.subcategory === parseInt(this.state.subcategory, 10)).orderBy("name");
		const statuses = session.ProductStatus.all().orderBy("name");
		const statuses2 = session.ProductStatus2.all().orderBy("name");
		const image = session.Photo.withId(this.state.image);
		const links = session.ProductLink.filter(item => (item.product === this.state.id)).orderBy(["ordering"], ["asc"]);
		const availableLinks = session.Link.all().filter(item => !item.auto_redirect).orderBy(["name"], ["asc"]).toRefArray();

		/**
		 * Final Output
		 * @type {{}}
		 */
		return <form onSubmit={this.handleSubmit} className="form page__section--bottom-padding">
			{error}
			<FormGroup>
				<label htmlFor="name">{t("label.Name")}</label>
				<input onChange={this.handleInputChange}
					   value={this.state.name}
					   type="text"
					   name="name" id="name"
					   placeholder={t("label.Name")}
				/>
			</FormGroup>
			<FormGroup>
				<label htmlFor="title_2">Title 2</label>
				<input onChange={this.handleInputChange}
					   value={this.state.title_2}
					   type="text"
					   name="title_2"
					   id="title_2"
				/>
			</FormGroup>
			<FormGroup>
				<label htmlFor="title_3">Title 3</label>
				<input onChange={this.handleInputChange}
					   value={this.state.title_3}
					   type="text"
					   name="title_3"
					   id="title_3"
				/>
			</FormGroup>
			<Row>
				<Col xs={12} lg={6}>
					<FormGroup>
						<label htmlFor="category">{t("label.Category")} <Required/></label>
						<select onChange={this.handleInputChange}
								value={this.state.category}
								name="category"
								id="category"
						>
							<option value={0}>[Empty]</option>
							{categories.toModelArray().map(item => {
								return <option key={item.id} value={item.id}>{item.name}</option>;
							})}
						</select>
					</FormGroup>
					<FormGroup>
						<label htmlFor="subcategory">{t("label.Subcategory")} <Required/></label>
						<select onChange={this.handleInputChange}
								value={this.state.subcategory}
								name="subcategory"
								id="subcategory"
								disabled={(parseInt(this.state.category, 10) === 0) || (this.state.category === null) || (subcategories.count() === 0)}
						>
							<option value={0}>[Empty]</option>
							{subcategories.toModelArray().map(item => {
								return <option key={item.id} value={item.id}>{item.name}</option>;
							})}
						</select>
					</FormGroup>
					<FormGroup>
						<label htmlFor="section">{t("label.Section")}</label>
						<select onChange={this.handleInputChange}
								value={this.state.section}
								name="section"
								id="section"
								disabled={sections.count() === 0}
						>
							<option value={0}>[Empty]</option>
							{sections.toModelArray().map(item => {
								return <option key={item.id} value={item.id}>{item.name}</option>;
							})}
						</select>
					</FormGroup>
					<FormGroup>
						<label htmlFor="status">{t("label.Status")} <Required/></label>
						<select onChange={this.handleInputChange}
								value={this.state.status}
								name="status"
								id="status"
						>
							<option value={0}>[Empty]</option>
							{statuses.toModelArray().map(item => {
								return <option key={item.id} value={item.id}>{item.name}</option>;
							})}
						</select>
					</FormGroup>
					<FormGroup>
						<label htmlFor="status_2">{t("label.Status")} 2</label>
						<select onChange={this.handleInputChange}
								value={this.state.status_2}
								name="status_2"
								id="status_2"
						>
							<option value={0}>[Empty]</option>
							{statuses2.toModelArray().map(item => {
								return <option key={item.id} value={item.id}>{item.name}</option>;
							})}
						</select>
					</FormGroup>
					<FormGroup>
						<label htmlFor="ordering">Ordering</label>
						<input onChange={this.handleInputChange}
							   value={this.state.ordering}
							   type="text"
							   tabIndex={2}
							   name="ordering" id="ordering"
						/>
					</FormGroup>
				</Col>
				<Col xs={12} lg={6}>
					<Row>
						<Col xs={12}>
							<label>{t("label.MainPhoto")}</label>
							<br/>
							{this.state.image !== 0 && image !== null ? <div>
								<img className="image--preview" src={process.env.REACT_APP_BACKEND_SERVER + image.url}
									 alt={"Preview"}/>
							</div> : <div>[NO IMAGE]</div>}
							<br/>
						</Col>
						<Col xs={12}>
							<label>{t("label.PhotoGallery")}</label>
							<br/>
							<div className="thumbnail-photo-admin__wrapper">
								{this.state.photos.map(photoId => {
									let photo = session.Photo.withId(photoId);
									return <ThumbnailPhotoAdmin key={photoId} photo={photo} parent={this}
																product={this.state}/>;
								})}
								{this.state.photos.length === 0 ? "[NO IMAGES IN GALLERY]" : ""}
							</div>
						</Col>
						<Col xs={12}>
							<label>Photo upload</label>
							<br/>
							<MyDropZone onDrop={this.onDrop} accept={"image/*"}/>
						</Col>
					</Row>
					<Row>
						<Col xs={12}>
							<hr/>
						</Col>
					</Row>
					<Row>
						<Col xs={12} lg={3} className={"text--center"}>
							<FormGroup>
								<label>{t("label.IsOnline")}?</label>
								<br/>
								<SwitchButton checked={this.state.is_online} handleClick={this.handleSwitch}
											  name={"is_online"}/>
							</FormGroup>
						</Col>
						<Col xs={12} lg={3} className={"text--center"}>
							<FormGroup>
								<label>{t("label.IsArchived")}?</label>
								<br/>
								<SwitchButton checked={this.state.is_archived} handleClick={this.handleSwitch}
											  name={"is_archived"}/>
							</FormGroup>
						</Col>
						<Col xs={12} lg={3} className={"text--center"}>
							<FormGroup>
								<label>{t("label.IsInPricelist")}?</label>
								<br/>
								<SwitchButton checked={this.state.in_price_list} handleClick={this.handleSwitch}
											  name={"in_price_list"}/>
							</FormGroup>
						</Col>
						<Col xs={12} lg={3} className={"text--center"}>
							<FormGroup>
								<label>{t("label.IsAvailable")}?</label>
								<br/>
								<SwitchButton checked={this.state.is_available} handleClick={this.handleSwitch}
											  name={"is_available"}/>
							</FormGroup>
						</Col>
					</Row>
				</Col>
			</Row>
			<Row>
				<Col xs={12}>
					<hr/>
				</Col>
				<Col xs={12}>
					<h2>Product descriptions (Product page description)</h2>
				</Col>
			</Row>
			<Row>
				<Col xs={12} lg={6}>
					<FormGroup>
						<label htmlFor="description">First info point</label>
						<textarea
							onChange={this.handleInputChange}
							value={this.state.description}
							name="description"
							id="description"
						/>
						<em>(Use markdown - <a target="_blank"
											   href="https://github.com/adam-p/markdown-here/wiki/Markdown-Cheatsheet"
											   rel="noopener noreferrer">https://github.com/adam-p/markdown-here/wiki/Markdown-Cheatsheet</a>)</em>
					</FormGroup>
				</Col>
				<Col xs={12} lg={6}>
					<FormGroup>
						<label>{t("label.ContentPreview")}</label>
						<Markdown
							rehypePlugins={[rehypeRaw, rehypeSanitize]}
							remarkPlugins={[remarkGfm]}
							children={this.state.description}
						/>
					</FormGroup>
				</Col>
			</Row>
			<Row>
				<Col xs={12} lg={6}>
					<FormGroup>
						<label htmlFor="description_2">Second info point</label>
						<textarea
							onChange={this.handleInputChange}
							value={this.state.description_2}
							name="description_2"
							id="description_2"
						/>
					</FormGroup>
				</Col>
				<Col xs={12} lg={6}>
					<FormGroup>
						<label>{t("label.ContentPreview")}</label>
						<Markdown
							rehypePlugins={[rehypeRaw, rehypeSanitize]}
							remarkPlugins={[remarkGfm]}
							children={this.state.description_2}
						/>
					</FormGroup>
				</Col>
			</Row>
			<Row>
				<Col xs={12} lg={6}>
					<FormGroup>
						<label htmlFor="top_page_left"><h3>Top Page Left</h3></label>
						<textarea
							onChange={this.handleInputChange}
							value={this.state.top_page_left}
							name="top_page_left"
							id="top_page_left"
						/>
					</FormGroup>
				</Col>
				<Col xs={12} lg={6}>
					<FormGroup>
						<label>{t("label.ContentPreview")}</label>
						<Markdown
							rehypePlugins={[rehypeRaw, rehypeSanitize]}
							remarkPlugins={[remarkGfm]}
							children={this.state.top_page_left}
						/>
					</FormGroup>
				</Col>
			</Row>
			<Row>
				<Col xs={12} lg={6}>
					<FormGroup>
						<label htmlFor="top_page_bottom"><h3>Top Page Bottom</h3></label>
						<textarea
							onChange={this.handleInputChange}
							value={this.state.top_page_bottom}
							name="top_page_bottom"
							id="top_page_bottom"
						/>
					</FormGroup>
				</Col>
				<Col xs={12} lg={6}>
					<FormGroup>
						<label>{t("label.ContentPreview")}</label>
						<Markdown
							rehypePlugins={[rehypeRaw, rehypeSanitize]}
							remarkPlugins={[remarkGfm]}
							children={this.state.top_page_bottom}
						/>
					</FormGroup>
				</Col>
			</Row>
			<ProductFormOptionalBox parent={this} boxNumber={1}/>
			<ProductFormOptionalBox parent={this} boxNumber={2}/>
			<ProductFormOptionalBox parent={this} boxNumber={3}/>
			<ProductFormOptionalBox parent={this} boxNumber={4}/>
			<ProductFormOptionalBox parent={this} boxNumber={5}/>
			<ProductFormOptionalBox parent={this} boxNumber={6}/>
			<ProductFormOptionalBox parent={this} boxNumber={7}/>
			<Row>
				<Col xs={12}>
					<hr/>
					<br/>
					<h2>Links </h2>
				</Col>
				<Col xs={12}>
					<div style={{
						display: "flex"
					}}>
						<select name="add-link" onChange={this.handleAddLinkChange}>
							{availableLinks.map(item => {
								return <option key={item.id} value={item.id}>{item.name}  {(item.url && (item.url !== "null")) && "-- URL: "+item.url} {item.page && "(page: '"+item.page+"')"}</option>;
							})}
						</select>
						<button
							onClick={this.addLink}
							style={{
							flexGrow: 0,
							display: "flex",
							flexWrap: "no-wrap",
							minWidth: "150px",
							justifyContent: "center",

						}} className="btn" >Add this link</button>
					</div>
					<button className="btn" onClick={this.addNewLink}>+ Create New Link</button>
					<br/>
					<br/>
					{links.orderBy(["ordering"], ["asc"]).toModelArray().map((productLink, index) => {

						return <LinkItem productLink={productLink} link={productLink.link} key={index}
										 edit={productLink.link.edit} product={this.state}/>;

					})}
					{/*<SortableLinks links={linksList} product={this.state} />*/}
				</Col>
			</Row>
			<Row>
				<Col xs={12}>
					<hr/>
				</Col>
			</Row>
			<hr/>
			<FormGroup className={"text--center"}>
				{(expectingResponse) ? <LoadingComponent/> :
					<button type="submit" tabIndex={2}
							className="btn btn--animated">{this.state.id === null ? t("button.Submit") : t("button.Update")}</button>
				}
			</FormGroup>
		</form>;
	}
}

/**
 * State to Props
 * @param state
 * @return {{entities: {PhotoSection: (*|PhotoSection)}}}
 */
const mapStateToProps = state => (
	{
		entities: state.entities,
		isLinksFetched: state.app.editorLinksFetched,
	});

export default (connect(mapStateToProps)(withTranslation()(ProductForm)));
