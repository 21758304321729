// @flow
import React from "react";
import {withTranslation} from "react-i18next";
import {Col, Grid, Row} from "react-flexbox-grid";
import {WhyBox} from "../../Components/Landing";
import {CaseStudiesLoader} from "../../Components/CaseStudies";
import any_device from "../../App/images/content/Any-device_small.jpg";
import igrf_applicability from "../../App/images/content/iqrf-applicability.jpg";
import {Helmet} from "react-helmet";

// import TreeNavigation from "../../Components/Navigation/TreeNavigation/TreeNavigation";

/**
 * @class SupportScene
 */
class SupportScene extends React.Component {

    /**
     * Constructor
     * @param props
     */
    constructor(props) {
        super(props);

        this.state = {
            showSphereOfApp: false,
        };
    }

    /**
     * Final Render
     * @returns {*}
     */
    render() {

        const {t} = this.props;
        const {
            showSphereOfApp,
        } = this.state;

        return (<div className={"page"}>
                <Helmet>
                    <title>What is IQRF?</title>
                </Helmet>
                <Grid>
                    <Row>
                        <Col xs={12}>
                            {/*<TreeNavigation/>*/}
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} className="page__section--with-margin">
                            <h1>What is IQRF?</h1>

                            <p>IQRF <sup>®</sup> is a <strong>wireless <a href="https://en.wikipedia.org/wiki/Mesh_networking" target="_blank" rel="noreferrer noopener">Mesh</a> technology</strong> in <strong>sub-GHz <a href="https://en.wikipedia.org/wiki/ISM_band" target="_blank" rel="noreferrer noopener">ISM</a> </strong>
                                radio bands. It requires <strong>no infrastructure</strong> by external providers,
                                <strong> no license</strong> and no <strong>carrier fees</strong>.</p>

                            <p>The functionality of transceivers is completely <strong>generic</strong>, depending on
                                <strong> uploaded software</strong> - either a ready-to-use but extendable <strong>network plug-in </strong>
                                or a <strong>user application</strong> written in <strong>C</strong> language.</p>
                            <br/>

                        </Col>
                    </Row>
                       <Row>
                            <h2>Application fit</h2>
                       </Row>
                    <Row className="page__section--bottom-padding">
                        <Col xl={8} xs={12}>
                            <p>IQRF can make any device wireless.</p>

                            <p>Thanks to reliable Mesh networking, bidirectionality, security and True low power<sup>TM</sup>, IQRF
                                fits best to <strong>control</strong> systems, <strong>telemetry</strong> and
                                <strong> IoT</strong>. It excels in <strong>lighting</strong> applications, especially in
                                <strong> street lighting</strong>.</p>
                            <br/>
                            <div className={"link"} onClick={() => this.setState({showSphereOfApp: !showSphereOfApp})}>
                                » IQRF sphere of applicability within RF technologies
                                <br/>
                                <br/>
                            </div>
                        </Col>
                        <Col xl={4} xs={12}>
                            <div className="text--center">
                                <img src={any_device} alt={"Any device"}/>
                            </div>
                        </Col>
                    </Row>
                    <Row className="page__section--bottom-padding">
                        <Col xs={12}>
                            {showSphereOfApp && (
                                <div className="text--center">
                                    <img src={igrf_applicability} alt={"IQRF applicability"}/>
                                </div>
                            )}
                        </Col>
                    </Row>
                </Grid>
                <div className="page__section--with-theme reasons">
                    <Grid>
                        <Row>
                            <Col xs={12}>
                                <h2>{t("title.WhyIQRF")}</h2>
                            </Col>
                        </Row>
                        <WhyBox/>
                    </Grid>
                </div>
                <Grid>
                    <Row>
                        <Col xs={12} className={"page__section--with-margin"}>
                            <h2 id={"case-studies"}>{t("title.CaseStudies")}</h2>
                            <CaseStudiesLoader/>
                            <br />
                            See additional case studies at <a href="https://iqrfalliance.org/" rel="noopener noreferrer"
      target="_blank">IQRF Alliance</a>.
                        </Col>
                    </Row>
                </Grid>
            </div>
        );
    }

}

/**
 * Exporting part of the React.Component file
 */
export default withTranslation()(SupportScene);
