// @flow
import React from "react";
import {withTranslation} from "react-i18next";
import {Col, Grid, Row} from "react-flexbox-grid";
import BackendRequest from "../../Models/REST";
import LoadingComponent from "../../Components/LoadingComponent";
import MasterGetter from "../../Models/Utils/MasterGetter";
import {VideoItem} from "../../Components/Videos";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import {Helmet} from "react-helmet";
import YouTube from "react-youtube";

/**
 * @class VideosScene
 */

class VideosScene extends React.Component {

	/**
	 * State
	 * @type {{expectingResponse: boolean}}
	 */
	state = {
		expectingResponse: true,
	};

	/**
	 * Fetch Data
	 */
	fetchData = () => {
		BackendRequest({
			endpoint: "videos",
			self: this,
		});
	};

	/**
	 * Component Did Mount
	 */
	componentDidMount() {
		this.fetchData();
	}

	/**
	 * Final Render
	 * @returns {*}
	 */
	render() {

		const {t, entities} = this.props;
		const session = MasterGetter.getSession(entities);
		const videos = session.Video.all().orderBy(["ordering"], ["asc"]);
		const opts = {
			width: '600',
			height: '315',
		};

		return (
			<div className={"page"}>
				<Helmet>
					<title>{t("title.VideoTutorials")} - IQRF</title>
				</Helmet>
				<Grid>
					<Row>
						<Col xs={12}>
							<h1>{t("title.VideoTutorials")}</h1>
							<p>Everything you need to know about IQRF in a few minutes.</p>
							<hr/>
							<h2>IQRF DPA menu – 2023</h2>
						</Col>
					</Row>
					<Row className="page__section--bottom-padding">
						<Col xs={12}>
							<Row>
								<Col xs={12} lg={6} className="video-item" style={{ overflow: "hidden"}}>
									<YouTube
										videoId={"khca7QWMRGM"}
										containerClassName={"video-item__wrapper"}
										opts={opts}
									/>
									<p>For TR-7xG transceivers, a new concept of simple and uniform control of IQMESH Node devices is introduced.
						It is based on the DPA menu to control the device and indicate the results.<br/>
						The menu allows you to perform many common useful actions (bonding, unbonding, reset, standby, factory setting, etc.).
						Moreover, the menu can be customized (extended by adding user-defined actions). DPA Menu is available in any device state.</p>
								</Col>
							</Row>
						</Col>
					</Row>
					<Row>
						<Col xs={12}>
							<a href="https://www.youtube.com/playlist?list=PLEQy1I01En9--rYrRUcYgF6WE_q9jjDlV"
							   rel="noopener noreferrer"
							   target="_blank"><h2>IQRF Tutorials 2019 - overview</h2></a>
							<p>Available with <strong>English</strong> subtitles.</p>
						</Col>
					</Row>
					<Row className="page__section--bottom-padding">
						<Col xs={12}>
							<Row>
								{this.state.expectingResponse ? <LoadingComponent/> : videos.toModelArray().map(video => {
									return <VideoItem video={video}/>;
								})}
								{!this.state.expectingResponse && videos.count() === 0 ? <div>No videos available at this moment.</div> : ""}
									<p><a href="https://www.youtube.com/playlist?list=PLEQy1I01En98-Q68fH50l-yjtoeV48zKU"
												rel="noopener noreferrer"
												target="_blank">» IQRF Tutorials - Archive 2014 to 2018</a></p>
							</Row>
						</Col>
					</Row>
				</Grid>
				<div className="page__section--with-theme">
					<Grid>
						<Row>
							<Col xs={12}>
								<h2>IQRF OS</h2>
								<div className="table--wrapper">
									<table className="table--with-links">
										<tbody>
										<tr>
											<td>
												<a href="https://youtu.be/BMoUT_1_rDc?list=PLEQy1I01En98-Q68fH50l-yjtoeV48zKU"
												   rel="noopener noreferrer"
												   target="_blank"><i className="icon-youtube-logo"/> How to upgrade IQRF OS </a>
											</td>
										</tr>
										</tbody>
									</table>
								</div>
								<br/>
								<br/>
								<h2>IoT StarterKit-01</h2>
								<Link to={"/product-detail/up-iqrf-iot-starterkit-01"}>» IoT StarterKit-01 video tutorials</Link>
								<br/>
								<br/>
								<h2>IQRF IDE</h2>
								<div className="table--wrapper">
									<table className="table--with-links">
										<tbody>
										<tr>
											<td><a href="https://youtu.be/QhwZ4Fh71wI?list=PLEQy1I01En98tZzw740yN3ZcoFXXEjnuZ"
												   rel="noopener noreferrer"
												   target="_blank"><i className="icon-youtube-logo"/> How to install IQRF IDE</a>
											</td>
										</tr>
										</tbody>
									</table>
								</div>
							</Col>
						</Row>
					</Grid>
				</div>
			</div>
		);
	}
}

/**
 *
 * @param state
 * @return {{entities: {Video: *}}}
 */
const mapStateToProps = state => (
	{
		entities: {
			Video: state.entities.Video,
		},
	});

/**
 * Exporting part of the React.Component file
 */
export default connect(mapStateToProps)(withTranslation()(VideosScene));
