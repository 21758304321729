// @flow

// Necessary Evil
import React from "react";
import PropTypes from "prop-types";
import {withTranslation} from "react-i18next";
import {LinkForm} from "../../Forms";
import InstantAction from "../../Models/Utils/InstantAction";
import {UPDATE_LINK} from "../../Models/Models/Link";
// Import component CSS
// import "./LinkItem.scss";


/**
 * @class LinkItem
 */
class LinkItem extends React.Component {

    /**
     * PropTypes
     * @type {{children: *}}
     */
    static propTypes = {
        children: PropTypes.any,
        edit: PropTypes.bool,
        link: PropTypes.any,
        product: PropTypes.any,
        productLink: PropTypes.any,
    };

    /**
     * Default props
     * @type {{edit: boolean}}
     */
    static defaultProps = {
        edit: false,
        product: null,
        productLink: null,
    };

    /**
     * State
     * @type {{edit: boolean}}
     */
    state = {
        edit: (this.props.edit),
        page: null,
    };

    /**
     *
     */
    edit = () => {

        const {link} = this.props;

        let edit = !(link.edit);

        InstantAction.dispatch({
            type: UPDATE_LINK,
            payload: {...link._fields, edit: edit},
        });
    }
    ;

    /**
     * Final Render
     * @returns {*}
     */
    render() {

        const {
            link,
            productLink,
            product
        } = this.props;

        if (link.edit) {
            return <div className="link-item">
                <LinkForm
                    link={link}
                    product={product}
                    productLink={productLink}
                    closeForm={this.edit}
                />
            </div>;
        } else {
            return <div
                className="link-item"
                onClick={this.edit}
            >
                <div className="link-item__icon"><img src={process.env.PUBLIC_URL + "/icon/" + link.icon + ".png"}
                                                      alt={"icon"}/></div>
                {productLink ? productLink.ordering : link.ordering}. {link.page &&
            <span><strong>&nbsp;Page "{link.page}" -&nbsp;</strong></span>}{link.name} &nbsp;- {link.url}
            </div>;
        }

    }
}

export default withTranslation()(LinkItem);