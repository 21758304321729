// @flow

// Necessary Evil
import React from "react";
import {withTranslation} from "react-i18next";
import {Link, withRouter} from "react-router-dom";
// Import component CSS
import "./TreeNavigation.scss";
import MasterGetter from "../../../Models/Utils/MasterGetter";
import {connect} from "react-redux";


/**
 * @class TreeNavigation
 */
class TreeNavigation extends React.Component {

    capitalFirst(str) {
        return str !== "" ? str.charAt(0).toUpperCase() + str.substr(1) : str;
    }

    /**
     * Final Render
     * @returns {*}
     */
    render() {

        const {
            location,
            app
        } = this.props;

        /**
         * Location
         */
        if (location.pathname === "/") {
            return null;
        }

        let levels = location.pathname.split("/");
        let levelOne = null;
        let levelTwo = null;
        let levelThree = null;
        let levelFourth = null;
        let levelFifth = null;

        /**
         * Handle levels
         */
        if (levels[1] !== undefined) {
            /**
             * Prepare LEVEL ONE
             * @type {{}}
             */
            switch (levels[1]) {
                case "support":
                    levelOne = {
                        link: null,
                        title: "Support",
                    };
                    break;
                case "what-is-iqrf":
                    levelOne = {
                        link: "/what-is-iqrf",
                        title: "What is IQRF?",
                    };
                    break;
                case "technology":
                    levelOne = {
                        link: null,
                        title: "Technology",
                    };
                    break;
                case "products":
                    levelOne = {
                        link: null,
                        title: "Products",
                    };
                    break;
                case "how-to-start":
                    levelOne = {
                        link: null,
                        title: "How to start?",
                    };
                    break;
                case "login":
                    levelOne = {
                        link: "/login",
                        title: "Login"
                    };
                    break;
                case "sales":
                    levelOne = {
                        link: null,
                        title: "Sales",
                    };
                    break;
                case "terms-of-use":
                    levelOne = {
                        link: null,
                        title: "Terms of use"
                    };
                    break;
                case "privacy-policy":
                    levelOne = {
                        link: null,
                        title: "Privacy Policy",
                    };
                    break;
                case "patents":
                    levelOne = {
                        link: null,
                        title: "Patents",
                    };
                    break;
                case "career":
                    levelOne = {
                        link: null,
                        title: "Career",
                    };
                    break;
                case "trademarks":
                    levelOne = {
                        link: null,
                        title: "Trademarks",
                    };
                    break;
                case "news":
                    levelOne = {
                        link: null,
                        title: "News",
                    };
                    break;
                case "events":
                    levelOne = {
                        link: null,
                        title: "Events",
                    };
                    break;
                case "case-study":
                    levelOne = {
                        link: null,
                        title: "Case study"
                    };
                    break;
                case "product-detail":

                    if (!app.productsLoaded) {
                        return null;
                    }

                    const session = MasterGetter.getSession();
                    const product = session.ProductSeries.all().filter(item => item.friendly_url === levels[2]).first();

                    if (product === undefined) {
                        return null;
                    }

                    let categoryId = product.category === null ? null : product.category.id;
                    const category = session.ProductCategory.withId(categoryId);

                    let subcategoryId = product.subcategory === null ? null : product.subcategory.id;
                    const subcategory = session.ProductSubcategory.withId(subcategoryId);

                    levelOne = {
                        link: null,
                        title: "Products"
                    };

                    levelTwo = {
                        link: "/products/" + category.friendly_url(),
                        title: category.name,
                    };

                    if (subcategory !== null) {
                        levelThree = {
                            link: "/products/" + category.friendly_url() + "/" + (subcategory.friendly_url()),
                            title: subcategory.name,
                        };
                    }

                    if (product.is_archived) {

                        if (subcategory !== null) {
                            levelFourth = {
                                link: "/products/" + category.friendly_url() + "/" + (subcategory.friendly_url()) + "/archive",
                                title: "Archive",
                            };
                        }else{
							levelFourth = {
								link: "/products/" + category.friendly_url() + "/archive",
								title: "Archive",
							};
						}
                    }

                    levelFifth = {
                        link: null,
                        title: product.name,
                    };
                    break;
                default:
                    levelOne = null;
            }
        }

        if (levels[2] !== undefined) {
            /**
             * Products Case
             */
            if (levels[1] === "products") {
                // Get Category
                const category = levels[2];
                const categoryName = (category.charAt(0).toUpperCase() + category.slice(1)).replace("-", " ");

                levelTwo = {
                    // link: "/products/" + levels[2],
                    link: null,
                    title: categoryName
                };
            }

            if (levels[1] === "technology") {

                switch (levels[2]) {
                    case "rf":
                        levelTwo = {
                            link: null,
                            title: "RF",
                        };
                        break;
                    case "iqmesh":
                        levelTwo = {
                            link: null,
                            title: "IQMESH",
                        };
                        break;
                    case "frc":
                        levelTwo = {
                            link: null,
                            title: "FRC",
                        };
                        break;
                    case "os":
                        levelTwo = {
                            link: null,
                            title: "Operating system",
                        };
                        break;
                    case "dpa":
                        levelTwo = {
                            link: null,
                            title: "DPA",
                        };
                        break;
                    case "ide":
                        levelTwo = {
                            link: null,
                            title: "IDE",
                        };
                        break;
                    case "gw-daemon":
                        levelTwo = {
                            link: null,
                            title: "GW Daemon",
                        };
                        break;
                    case "sdk":
                        levelTwo = {
                            link: null,
                            title: "SDK",
                        };
                        break;
                    case "repository":
                        levelTwo = {
                            link: null,
                            title: "Repository",
                        };
                        break;
                    case "clouds":
                        levelTwo = {
                            link: null,
                            title: "Clouds",
                        };
                        break;
                    default:
                        levelTwo = null;
                }

            }

            if (levels[1] === "sales") {
                switch (levels[2]) {
                    case "contact":
                        levelTwo = {
                            link: null,
                            title: "Contact Sales"
                        };
                        break;
                    case "company":
                        levelTwo = {
                            link: null,
                            title: "Company"
                        };
                        break;
                    case "distributors":
                        levelTwo = {
                            link: null,
                            title: "Distributors"
                        };
                        break;
                    default:
                        levelTwo = null;
                }
            }

            if (levels[1] === "support") {
                switch (levels[2]) {
                    case "faq":
                        levelTwo = {
                            link: null,
                            title: "FAQ"
                        };
                        break;
                    case "videos":
                        levelTwo = {
                            link: null,
                            title: "Videos"
                        };
                        break;
                    case "contact-support":
                        levelTwo = {
                            link: null,
                            title: "Contact Support"
                        };
                        break;
                    case "fae":
                        levelTwo = {
                            link: null,
                            title: "FAE"
                        };
                        break;
                    case "technical-support":
                        levelTwo = {
                            link: null,
                            title: "Technical Support"
                        };
                        break;
                    case "downloads":
                        levelTwo = {
                            link: null,
                            title: "Downloads"
                        };
                        break;
                    case "webinars":
                        levelTwo = {
                            link: null,
                            title: "Webinars"
                        };
                        break;
                    case "training":
                        levelTwo = {
                            link: null,
                            title: "Training"
                        };
                        break;
                    case "application-notes":
                        levelTwo = {
                            link: null,
                            title: "Application notes"
                        };
                        break;
                    default:
                        levelTwo = null;
                }
            }

            if (levels[1] === "case-study") {

                const session = MasterGetter.getSession();
                const study = session.CaseStudy.filter(item => item.friendly_url === levels[2]).first();

                levelOne = {
                    link: "/what-is-iqrf",
                    title: "What is IQRF?",
                };

                levelTwo = {
                    link: "/what-is-iqrf#case-studies",
                    title: "Case studies",
                };

                if (study !== undefined) {
                    levelThree = {
                        link: null,
                        title: study.name
                    };
                }


            }

            if (levels[1] === "how-to-start") {
                levelOne = {
                    link: "/how-to-start",
                    title: "How to start?",
                };
                levelTwo = {
                    link: null,
                    title: "Startup package history",
                };
            }
        }

        if (levels[3] !== undefined) {
            /**
             * Products Case
             */
            if (levels[1] === "products") {
                // Get Subcategory
                const subcategory = levels[3];
                const subcategoryName = (subcategory.charAt(0).toUpperCase() + subcategory.slice(1)).replace("-", " ");
                const category = levels[2];
                const categoryName = (category.charAt(0).toUpperCase() + category.slice(1)).replace("-", " ");

                levelTwo = {
                    link: "/products/" + levels[2],
                    title: categoryName
                };

                levelThree = {
                    // link: "/products/" + levels[2] + "/" + levels[3],
                    link: null,
                    title: subcategoryName
                };
            }

            if (levels[3] === "history-os") {
                levelTwo = {
                    link: "/technology/os",
                    title: "Operating system ",
                };
                levelThree = {
                    link: null,
                    title: "IQRF OS history"
                };
            }

            if (levels[2] === "iqmesh") {
                levelTwo = {
                    link: "/technology/iqmesh",
                    title: "IQMESH",
                };
                levelThree = {
                    link: null,
                    title: (this.capitalFirst(levels[3])),
                };
            }

            if (levels[3] === "current-os") {
                levelTwo = {
                    link: "/technology/os",
                    title: "Operating system",
                };
                levelThree = {
                    link: null,
                    title: "Current OS versions for archive TRs"
                };
            }

            if (levels[3] === "os-functions") {
                levelTwo = {
                    link: "/technology/os",
                    title: "Operating system ",
                };
                levelThree = {
                    link: null,
                    title: "IQRF OS functions"
                };
            }

            if (levels[3] === "os-migration-notes") {
                levelTwo = {
                    link: "/technology/os",
                    title: "Operating system ",
                };
                levelThree = {
                    link: null,
                    title: "OS Migration notes"
                };
            }

            if (levels[3] === "mid-cloning") {
                levelTwo = {
                    link: "/technology/os",
                    title: "Operating system ",
                };
                levelThree = {
                    link: null,
                    title: "MID cloning"
                };
            }

            if (levels[3] === "historycommand") {
                levelTwo = {
                    link: "/technology/ide",
                    title: "IDE",
                };
                levelThree = {
                    link: null,
                    title: "IQRF IDE Command Release notes"
                };
            }

        }

        if (levels[4] !== undefined) {
            if (levels[4] === "archive") {

                const subcategory = levels[3];
                const subcategoryName = (subcategory.charAt(0).toUpperCase() + subcategory.slice(1)).replace("-", " ");

                levelThree = {
                    link: "/products/" + levels[2] + "/" + subcategory,
                    title: subcategoryName
                };

                levelFourth = {
                    link: null,
                    title: "Archive"
                };
            }
        }

        return <div className="tree-navigation">
            <Link to={"/"}>Home</Link>
            {(levelOne !== null) && <span> » {levelOne.link === null ? levelOne.title :
                <Link to={levelOne.link}>{levelOne.title}</Link>}</span>}
            {(levelTwo !== null) && <span> » {levelTwo.link === null ? levelTwo.title :
                <Link to={levelTwo.link}>{levelTwo.title}</Link>}</span>}
            {(levelThree !== null) && <span> » {levelThree.link === null ? levelThree.title :
                <Link to={levelThree.link}>{levelThree.title}</Link>}</span>}
            {(levelFourth !== null) && <span> » {levelFourth.link === null ? levelFourth.title :
                <Link to={levelFourth.link}>{levelFourth.title}</Link>}</span>}
            {(levelFifth !== null) && <span> » {levelFifth.link === null ? levelFifth.title :
                <Link to={levelFifth.link}>{levelFifth.title}</Link>}</span>}
        </div>;
    }
}

/**
 *
 * @param state
 * @return {{app: *, entities: *}}
 */
const mapStateToProps = state => (
    {
        app: state.app,
        entities: state.entities,
    });

/**
 * Exporting part of the React.Component file
 */
export default withRouter(connect(mapStateToProps)(withTranslation()(TreeNavigation)));
