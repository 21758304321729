// @flow
import React from "react";
import {withTranslation} from "react-i18next";
import {Col, Grid, Row} from "react-flexbox-grid";
import {Helmet} from "react-helmet";
import {Link} from "react-router-dom";

import pdf_ico from "../../App/extra-icons/pdf_ico.png";
import zip_ico from "../../App/extra-icons/zip_ico.png";

/**
 * @class ApplicationNotesScene
 */
class ApplicationNotesScene extends React.Component {

	/**
	 * Final Render
	 * @returns {*}
	 */
	render() {
		return (
			<Grid className={"page"}>
				<Helmet>
					<title>Application Notes - IQRF</title>
				</Helmet>
				<Row>
					<Col xs={12} className="page__section--bottom-padding">
						<h1>Application Notes</h1>
						<h3>Technical papers regarding IQRF</h3>
						<div className="table--wrapper">
							<table className="table--with-links">
								<tbody>
								<tr>
									<td>
										<Link to="/download/bf7c2c0d-9d6e-4450-97d9-1aac5a8ba67a">
											<img alt="icon" src={pdf_ico}/> AN015
										</Link> – <strong>IQRF HW design</strong> for TR-7xDx transceivers
									</td>
								</tr>
								<tr>
									<td>
										<Link to="/download/d0af3084-af00-40ba-9d94-6d7613265f13">
											<img src={pdf_ico} alt="pdf icon"/> AN014
										</Link> – <strong>RF range</strong> at TR-7xDx transceivers
									</td>
								</tr>
								<tr>
									<td>
										<Link to="/download/55a9c316-449c-47c5-a855-999e3a74f535">
											<img src={pdf_ico} alt="pdf icon"/> AN017
										</Link> – IQRF<strong> DPA menu</strong>
									</td>
								</tr>
								<tr>
									<td>
										<Link to="/download/f9dfe29d-2183-4a3c-a102-21a9ea0038a3">
											<img src={pdf_ico} alt="pdf icon"/> AN016
										</Link> – <strong>NFC</strong> in IQRF devices
									</td>
								</tr>
								<tr>
									<td>
										<Link to="/download/061485f8-b4af-42b3-b681-345f65874a7b">
											<img src={pdf_ico} alt="pdf icon"/> AN018
										</Link> – <strong>TR-7xG</strong> vs. <strong>TR-7xD</strong> comparison
									</td>
								</tr>
								<tr>
									<td>
										<Link to="/download/f8207d0b-a4e2-4b62-91e6-0b5caccf5d6b">
											<img src={pdf_ico} alt="pdf icon"/> IQRF SPI Technical guide for TR-7xG and TR-7xD
										</Link> – SPI specification at IQRF transceivers. Especially to create an SPI master connected to TR or to upload a content into TR memories.
									</td>
								</tr>
								<tr>
									<td>
										<Link to="/download/90a64eae-f92f-422e-a15f-0032b555a6e5">
											<img src={pdf_ico} alt="pdf icon"/> IQRF CDC Technical guide
										</Link> – CDC Implementation in IQRF USB devices, including upload a content into TR memories in programming mode.
									</td>
								</tr>
								<tr>
									<td>
										<Link to="/download/0f84cb67-515b-403c-8bf8-8e43bfc640b6">
											<img src={pdf_ico} alt="pdf icon"/> IQRF UDP Technical guide
										</Link> – IQRF UDP protocol specification
									</td>
								</tr>
								<tr>
									<td>
										<Link to="/download/24955f35-c3aa-4f9f-92ff-feea86217764">
											<img src={zip_ico} alt={"icon"}/> IQRF USB drivers
										</Link> – <strong>USB drivers</strong> and installation guide
									</td>
								</tr>
								</tbody>
							</table>
						</div>
					</Col>
				</Row>
			</Grid>
		);
	}

}

/**
 * Exporting part of the React.Component file
 */
export default withTranslation()(ApplicationNotesScene);